import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { onlyNumbers } from "../../../../../../Shared/Utils/data";

import ArrayEstadosCidades from "../../../../../../Shared/Utils/EstadosCidades/estados-cidades.json";
import { allNationalities } from "../../../../../../Shared/Utils/nationalities";
import {
  gendersOptions,
  nationalitiesOptions,
} from "../../../../../../Shared/Utils/constants";
import { Loading } from "../../../../../../Shared/Components/Loading/Loading";
import { AddressApi } from "../../../../../../Shared/Utils/Request";
import { PageTitle } from "./styles";

const PersonalInformationsItems = ({
  register,
  errors,
  driver,
  setValue,
  driverByCpf,
  driverInfo,
}) => {
  const [cidadesDoEstado, setCidadesDoEstado] = useState([]);
  const [hasChange, setHasChange] = useState(false);
  const [nacionality, setNacionality] = useState("");
  const [address, setAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAddress = useCallback(async (input) => {
    setIsLoading(true);
    try {
      const response = await AddressApi.get(`/${input}/json`);
      setIsLoading(false);
      setAddress(response?.data);
      return response?.data;
    } catch {
      setAddress(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleChangeNacionality = useCallback((event) => {
    setNacionality(event.target.value);
  }, []);

  const obterCidadesPorSigla = (sigla) => {
    const estado = ArrayEstadosCidades.estados.find(
      (estado) => estado.sigla === sigla
    );

    if (estado) {
      return estado?.cidades;
    } else {
      return [];
    }
  };

  const handleChangeCepInput = useCallback(
    async (event) => {
      setHasChange(false);
      const cep = onlyNumbers(event.target.value);

      if (cep.length > 7) {
        getAddress(cep);
        setHasChange(true);
      }
    },
    [getAddress]
  );

  useEffect(() => {
    if (driver) {
      const cidades = obterCidadesPorSigla(driver?.uf);
      setCidadesDoEstado(cidades);
      getAddress(driver?.cep);

      setHasChange(true);
    }

    if (driverByCpf && Object.keys(driverByCpf).length !== 0) {
      getAddress(driverByCpf?.cep);
      setHasChange(true);
    }
  }, [driver, driverByCpf, getAddress]);

  useEffect(() => {
    if (address?.localidade) {
      const cidades = obterCidadesPorSigla(address?.uf);
      setCidadesDoEstado(cidades);
      setValue("cidade", address?.localidade);
    } else {
      setCidadesDoEstado([]);
    }
  }, [address?.localidade, address?.uf, driverByCpf, setValue]);

  useEffect(() => {
    if (driverByCpf && Object.keys(driverByCpf).length !== 0) {
      setValue(
        "dt_nascimento",
        moment(driverByCpf?.datanascimento).format("YYYY-MM-DD")
      );

      setValue("nome_pai", driverByCpf?.nomepai);
      setValue("nome_mae", driverByCpf?.nomemae);
      setValue("cep", driverByCpf?.cep);
      setValue("numero", driverByCpf?.numero);
    }
  }, [driverByCpf, setValue]);

  useEffect(() => {
    if (hasChange === true && address !== null) {
      setValue("uf", address?.uf);
      setValue("bairro", address?.bairro);
      setValue("endereco", address?.logradouro);
      setValue("cidade", address?.localidade);
    }
  }, [hasChange, setValue, driverByCpf, address]);

  const defaultNationalityValue = useMemo(() => {
    if (driver?.cod_nacionalidade && nacionality === "") {
      return driver?.cod_nacionalidade;
    }

    if (nacionality !== "3") {
      const currentNationality = allNationalities.find(
        (nationality) => nationality.value === "010"
      );

      return currentNationality.value;
    }

    if (nacionality === "3") {
      return "";
    }
  }, [nacionality, driver?.cod_nacionalidade]);

  return (
    <>
      <Row xs={1} md={3} className="g-2 mb-4">
        <Form.Group as={Col} md={6} controlId="formRG">
          <FloatingLabel controlId="formRG" label="RG">
            <Form.Control
              {...register("rg_motorista", {
                required: "O RG é obrigatório",
                validate: (value) => {
                  value = value.replace(/\D/g, "");
                  if (!/^\d+$/.test(value)) {
                    return "RG inválido. Use apenas números.";
                  }
                  return true;
                },
              })}
              size="sm"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              data-testid="driver-rg-input"
              type="text"
              as={InputMask}
              mask="999999999999999"
              maskChar={null}
              className="is-required"
              placeholder="Digite o RG"
              defaultValue={
                driver ? driver?.rg_motorista : driverInfo ? driverInfo.rg : ""
              }
            />
            {errors?.rg_motorista && (
              <Form.Text className="text-danger">
                {errors?.rg_motorista?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formOrgaoRG">
          <FloatingLabel controlId="formOrgaoRG" label="Órgão expedidor">
            <Form.Control
              {...register("orgao_rg", {
                required: "O órgão de emissão do RG é obrigatório",
              })}
              data-testid="driver-orgao-rg-input"
              size="sm"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              type="text"
              className="is-required"
              placeholder="Digite o órgão expedidor do RG"
              defaultValue={driver && driver?.orgao_rg}
            />
            {errors?.orgao_rg && (
              <Form.Text className="text-danger">
                {errors?.orgao_rg?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formDtEmissaoRG">
          <FloatingLabel
            controlId="formDtEmissaoRG"
            label="Data de emissão do RG"
          >
            <Form.Control
              {...register("dt_emissao_rg", {
                required: "A data de emissão do RG é obrigatória",
              })}
              aria-label="RG Emission date"
              data-testid="driver-dt-rg-emission"
              size="sm"
              type="date"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              className="is-required"
              defaultValue={
                driver
                  ? moment.utc(driver?.dt_emissao_rg).format("YYYY-MM-DD")
                  : driverByCpf &&
                    moment.utc(driverByCpf?.dt_emissao_rg).format("YYYY-MM-DD")
              }
            />
            {errors?.dt_emissao_rg && (
              <Form.Text className="text-danger">
                {errors?.dt_emissao_rg?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formNacionalidade">
          <FloatingLabel controlId="formNacionalidade" label="Nacionalidade">
            <Form.Select
              {...register("nacionalidade", {
                required: "A nacionalidade é obrigatória",
              })}
              data-testid="driver-nacionality-input"
              size="sm"
              type="text"
              className="is-required"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              defaultValue={driver?.nacionalidade}
              onChange={handleChangeNacionality}
            >
              <option value="">Selecione a nacionalidade</option>
              {nationalitiesOptions.map((nacionality) => (
                <option value={nacionality.value} key={nacionality.id}>
                  {nacionality.label}
                </option>
              ))}
            </Form.Select>
            {errors?.nacionalidade && (
              <Form.Text className="text-danger">
                {errors?.nacionalidade?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        {nacionality !== "" && (
          <Form.Group as={Col} md={12} controlId="formCountry">
            <FloatingLabel controlId="formCountry" label="País">
              <Form.Select
                {...register("cod_nacionalidade")}
                data-testid="driver-country-input"
                size="sm"
                type="text"
                defaultValue={defaultNationalityValue}
              >
                <option value="">Selecione o país</option>
                {allNationalities.map((nacionality) => (
                  <option value={nacionality.value} key={nacionality.value}>
                    {nacionality.label}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        )}
        <Form.Group as={Col} md={6} controlId="formNascimento">
          <FloatingLabel controlId="formNascimento" label="Data de nascimento">
            <Form.Control
              {...register("dt_nascimento", {
                required: "A data de nascimento é obrigatória",
              })}
              data-testid="driver-dt-birth-input"
              size="sm"
              type="date"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              className="is-required"
              defaultValue={
                driver
                  ? moment.utc(driver?.dt_nascimento).format("YYYY-MM-DD")
                  : driverByCpf &&
                    moment.utc(driverByCpf?.datanascimento).format("YYYY-MM-DD")
              }
            />
            {errors?.dt_nascimento && (
              <Form.Text className="text-danger">
                {errors?.dt_nascimento?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formGender">
          <FloatingLabel controlId="formGender" label="Gênero">
            <Form.Select
              {...register("sexo", { required: "O gênero é obrigatório" })}
              data-testid="driver-gender-input"
              size="sm"
              type="text"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              className="is-required"
              defaultValue={driver?.sexo}
            >
              <option value="">Selecione o gênero</option>
              {gendersOptions.map((gender) => (
                <option value={gender.value} key={gender.id}>
                  {gender.label}
                </option>
              ))}
            </Form.Select>

            {errors?.sexo && (
              <Form.Text className="text-danger">
                {errors?.sexo?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={12} controlId="formNomePai">
          <FloatingLabel controlId="formNome" label="Nome do pai">
            <Form.Control
              {...register("nome_pai", {
                validate: (value) => {
                  if (value && !/^[a-zA-Z ]+$/.test(value)) {
                    return "Nome inválido. Use apenas letras sem acento.";
                  }
                  return true;
                },
              })}
              size="sm"
              data-testid="driver-father-name-input"
              type="text"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              placeholder="Digite o nome do pai"
              defaultValue={
                driver ? driver?.nome_pai : driverByCpf && driverByCpf?.nomepai
              }
            />
            {errors?.nome_pai && (
              <Form.Text className="text-danger">
                {errors?.nome_pai?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={12} controlId="formNomeMae">
          <FloatingLabel controlId="formNomeMae" label="Nome da mãe">
            <Form.Control
              {...register("nome_mae", {
                required: "O nome da mãe é obrigatório",
                validate: (value) => {
                  if (value && !/^[a-zA-Z ]+$/.test(value)) {
                    return "Nome inválido. Use apenas letras sem acento.";
                  }
                  return true;
                },
              })}
              data-testid="driver-mother-name-input"
              size="sm"
              type="text"
              className="is-required"
              placeholder="Digite o nome da mãe"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              defaultValue={
                driver ? driver?.nome_mae : driverByCpf && driverByCpf?.nomemae
              }
            />
            {errors?.nome_mae && (
              <Form.Text className="text-danger">
                {errors?.nome_mae?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>
      </Row>
      <PageTitle className="mb-4">Endereço</PageTitle>
      <Row xs={1} md={3} className="g-2 mb-4">
        <Form.Group as={Col} md={4} controlId="formCep">
          <FloatingLabel controlId="formCep" label="Cep">
            <Form.Control
              {...register("cep", {
                required: "O cep é obrigatório",
                validate: (value) => {
                  value = value.replace(/\D/g, "");
                  if (value.length !== 8) {
                    return "CEP inválido";
                  }
                  return true;
                },
              })}
              data-testid="driver-cep-input"
              size="sm"
              type="text"
              placeholder="Digite o cep"
              as={InputMask}
              mask="99.999-999"
              className="is-required"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              defaultValue={
                driver ? driver?.cep : driverByCpf && driverByCpf?.cep
              }
              onChange={handleChangeCepInput}
            />
            {errors?.cep && (
              <Form.Text className="text-danger">
                {errors?.cep?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Form.Group as={Col} md={4} controlId="formEstado">
              <FloatingLabel controlId="formEstado" label="Estado">
                <Form.Select
                  aria-label="State"
                  {...register("uf", { required: "O Estado é obrigatório" })}
                  className="is-required"
                  data-testid="driver-state-input"
                  size="sm"
                  disabled
                  type="text"
                  defaultValue={
                    driver ? driver?.uf : driverByCpf && address?.uf
                  }
                >
                  <option value="" disabled>
                    -- Selecione um Estado --
                  </option>
                  {ArrayEstadosCidades.estados
                    .map((estado) => estado.sigla)
                    .map((sigla) => (
                      <option key={sigla} value={sigla}>
                        {sigla}
                      </option>
                    ))}
                </Form.Select>
                {errors?.uf && (
                  <Form.Text className="text-danger">
                    {errors?.uf?.message}
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md={4} controlId="formCidade">
              <FloatingLabel controlId="formCidade" label="Cidade">
                <Form.Select
                  aria-label="City"
                  {...register("cidade")}
                  data-testid="driver-city-input"
                  size="sm"
                  disabled
                  className="is-required"
                  type="text"
                  placeholder="Digite a cidade"
                  value={address?.localidade}
                >
                  <option value="">-- Selecione uma cidade --</option>
                  {cidadesDoEstado.map((cidade) => {
                    return (
                      <option key={cidade} value={cidade}>
                        {cidade}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md={12} controlId="formEndereco">
              <FloatingLabel controlId="formEndereco" label="Endereço">
                <Form.Control
                  {...register(
                    "endereco",
                    !driver && { required: "O endereço é obrigatório" }
                  )}
                  data-testid="driver-address-input"
                  size="sm"
                  type="text"
                  disabled={driver}
                  className="is-required"
                  placeholder="Digite o endereço"
                  defaultValue={driver ? driver?.endereco : address?.logradouro}
                />
                {errors?.endereco && (
                  <Form.Text className="text-danger">
                    {errors?.endereco?.message}
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md={8} controlId="formBairro">
              <FloatingLabel controlId="formBairro" label="Bairro">
                <Form.Control
                  {...register("bairro", {
                    required: "O bairro é obrigatório",
                  })}
                  size="sm"
                  data-testid="driver-neighborhood-input"
                  type="text"
                  disabled={driver}
                  placeholder="Digite o bairro"
                  className="is-required"
                  defaultValue={
                    driver
                      ? driver?.bairro
                      : driverByCpf
                      ? driverByCpf?.bairro
                      : address && address?.bairro
                  }
                />
                {errors?.bairro && (
                  <Form.Text className="text-danger">
                    {errors?.bairro?.message}
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md={4} controlId="formNumber">
              <FloatingLabel controlId="formNumber" label="Número">
                <Form.Control
                  {...register("numero", {
                    required: "O número é obrigatório",
                  })}
                  size="sm"
                  data-testid="driver-number-input"
                  type="text"
                  as={InputMask}
                  mask="9999999"
                  maskChar={null}
                  className="is-required"
                  disabled={
                    driver?.status_cadastro === "2r" ||
                    driver?.status_cadastro === "7r"
                  }
                  placeholder="Digite o número"
                  defaultValue={
                    driver ? driver?.numero : driverByCpf && driverByCpf?.numero
                  }
                />
                {errors?.numero && (
                  <Form.Text className="text-danger">
                    {errors?.numero?.message}
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>
          </>
        )}
      </Row>
    </>
  );
};

export default PersonalInformationsItems;
