import { FaTrashAlt } from "react-icons/fa";
import {
  ActionButton,
  FormCheckLabel,
  ReasonForm,
  RegisterItemForm,
} from "./styles";
import { Form } from "react-bootstrap";
import { useState } from "react";

import { get } from "lodash";

export const MarkAsInconsistent = ({
  driver,
  inconsistencyMessage,
  handleReportInconsistency,
  handleCancel,
  item,
  register,
  inconsistencyValue,
  errors,
}) => {
  const [textareaValue, setTextareaValue] = useState("");

  return (
    <div className="d-flex gap-1 align-items-center w-100">
      {!inconsistencyMessage &&
        !inconsistencyValue &&
        driver?.status_cadastro !== "7r" && (
          <Form.Group>
            <Form.Check
              id={`mark-as-inconsistent-${driver?.id}`}
              className="d-flex aling-items-center gap-1"
            >
              <Form.Check.Input
                type="radio"
                onClick={handleReportInconsistency}
              />
              <FormCheckLabel className="text-start d-flex align-items-center">
                Marcar como inconsistente
              </FormCheckLabel>
            </Form.Check>
          </Form.Group>
        )}
      {inconsistencyMessage &&
        !inconsistencyValue &&
        driver?.status_cadastro !== "7r" && (
          <div className="w-100">
            <div className="w-100 d-flex align-items-center gap-1">
              <ReasonForm
                {...register(`campos_erros_cadastro.${item}`, {
                  required: "Informe o motivo da inconsistência",
                })}
                className="is-required w-100 d-flex rounded"
                placeholder="Informe a inconsistência"
                as="textarea"
                value={textareaValue}
                onChange={(e) => setTextareaValue(e.target.value)}
              />
              <ActionButton
                onClick={() => {
                  handleCancel();
                  setTextareaValue("");
                }}
                title="Cancelar"
              >
                <FaTrashAlt size={14} />
              </ActionButton>
            </div>
            <div>
              {get(errors, `campos_erros_cadastro.${item}`) && (
                <Form.Text
                  className="text-danger"
                  style={{ fontSize: "0.7rem" }}
                >
                  {get(errors, `campos_erros_cadastro.${item}`)?.message}
                </Form.Text>
              )}
            </div>
          </div>
        )}
      {inconsistencyValue && (
        <RegisterItemForm
          className="is-required"
          placeholder={inconsistencyValue}
          disabled
        />
      )}
    </div>
  );
};
