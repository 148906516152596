import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest from "../Shared/Utils/Request";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { verificaLetras } from "../Shared/Utils/data";

export const OwnerContext = createContext({
  owners: [],
  owner: null,
  totalPages: null,
  isLoading: false,
  isLoadingtOwnerByCpfCnpj: false,
  ownerByCpfCnpj: null,
  getOwners: () => {},
  getOwner: () => null,
  getAllOwners: () => {},
  addOwner: () => null,
  deleteOwner: () => null,
  updateOwner: () => null,
  getOwnerByCpfCnpj: () => null,
  setOwner: () => null,
});

export const useOwners = () => {
  const context = useContext(OwnerContext);

  if (!context) {
    throw new Error("useOwners must be within OwnerProvider");
  }

  return context;
};

export const OwnerProvider = ({ children }) => {
  const [owners, setOwners] = useState([]);
  const [allOwners, setAllOwners] = useState([]);
  const [owner, setOwner] = useState(null);
  const [ownerByCpfCnpj, setOwnerByCpfCnpj] = useState(null);
  const [isLoadingAllOwners, setIsLoadingAllOwners] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingtOwnerByCpfCnpj, setIsLoadingtOwnerByCpfCnpj] =
    useState(false);
  const [totalPages, setTotalPages] = useState();

  const getOwners = useCallback(
    async (currentPage, searchOwner, active, status) => {
      try {
        setIsLoading(true);

        const params =
          status && status === "9"
            ? {
                perPage: 9,
                page: currentPage,

                status: "9",
              }
            : active === true
            ? {
                perPage: 9,
                page: currentPage,
                ativo: active,

                status: "0",
              }
            : active === false
            ? {
                perPage: 9,
                page: currentPage,
                ativo: active,

                status: "0",
              }
            : {
                perPage: 9,
                page: currentPage,
              };

        if (searchOwner) {
          if (verificaLetras(searchOwner)) {
            params.nome = searchOwner;
          } else {
            params.cpf_cnpj = searchOwner;
          }
        }

        const response = await ApiRequest({
          path: "proprietarios",
          method: "GET",
          params,
        });

        setTotalPages(response?.data?.total_pages);

        setOwners(response?.data?.data);
      } catch (error) {
        setOwners([]);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const getAllOwners = useCallback(async () => {
    try {
      setIsLoadingAllOwners(true);

      const params = {
        perPage: 1000,
      };

      const response = await ApiRequest({
        path: "proprietarios",
        method: "GET",
        params,
      });

      setAllOwners(response?.data?.data);
    } catch (error) {
      setAllOwners([]);
    } finally {
      setIsLoadingAllOwners(false);
    }
  }, []);

  const getOwner = useCallback(async (id) => {
    if (id === null) {
      return setOwner(null);
    }

    try {
      setIsLoading(true);
      const response = await ApiRequest({
        path: `proprietarios/${id}`,
        method: "GET",
      });

      setOwner(response?.data);
      setIsLoading(false);

      return response?.data;
    } catch (error) {
      setOwner(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getOwnerByCpfCnpj = useCallback(async (cpfCnpj) => {
    try {
      setIsLoadingtOwnerByCpfCnpj(true);
      const response = await ApiRequest({
        path: `proprietarios/cpf_cnpj/${cpfCnpj}`,
        method: "GET",
      });

      setOwnerByCpfCnpj(response?.data);
    } catch (error) {
      setOwnerByCpfCnpj(null);
    } finally {
      setIsLoadingtOwnerByCpfCnpj(false);
    }
  }, []);

  const addOwner = useCallback(
    async (data, navigate) => {
      try {
        setIsLoading(true);

        await ApiRequest({
          path: "proprietarios",
          method: "POST",
          data,
        });

        window.scrollTo(0, 0);

        const response = await getOwners();

        navigate("/proprietarios-listar");

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Proprietário cadastrado com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsLoading(false);

        return response?.data;
      } catch (error) {
        window.scrollTo(0, 0);

        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `${
            error?.response?.data?.error
              ? error?.response?.data?.error
              : "Proprietário não pode ser criado"
          }`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [getOwners]
  );

  const deleteOwner = useCallback(
    async (id) => {
      try {
        setIsLoading(true);

        await ApiRequest({
          path: `proprietarios/${id}`,
          method: "DELETE",
        });

        window.scrollTo(0, 0);

        const response = await getOwners();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Proprietário excluído com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsLoading(false);

        return response?.data;
      } catch (error) {
        setIsLoading(false);

        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `${
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            "Esse proprietário não pode ser excluído!"
          }`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [getOwners]
  );

  const updateOwner = useCallback(
    async (id, data, navigate) => {
      try {
        setIsLoading(true);

        await ApiRequest({
          path: `proprietarios/${id}`,
          method: "PUT",
          data,
        });

        window.scrollTo(0, 0);

        await getOwners();

        if (navigate) {
          navigate("/proprietarios-listar");
        }

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Proprietário atualizado com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        window.scrollTo(0, 0);

        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Proprietário não pode ser atualizado",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [getOwners]
  );

  const providerValue = useMemo(
    () => ({
      owners,
      allOwners,
      owner,
      ownerByCpfCnpj,
      isLoading,
      isLoadingAllOwners,
      totalPages,
      isLoadingtOwnerByCpfCnpj,
      getOwners,
      getAllOwners,
      getOwner,
      addOwner,
      deleteOwner,
      updateOwner,
      getOwnerByCpfCnpj,
      setOwner,
    }),
    [
      owners,
      allOwners,
      owner,
      ownerByCpfCnpj,
      isLoading,
      isLoadingAllOwners,
      totalPages,
      isLoadingtOwnerByCpfCnpj,
      getOwners,
      getAllOwners,
      getOwner,
      addOwner,
      deleteOwner,
      updateOwner,
      getOwnerByCpfCnpj,
      setOwner,
    ]
  );

  return (
    <OwnerContext.Provider value={providerValue}>
      {children}
    </OwnerContext.Provider>
  );
};
