import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { SuccessCard } from "../SuccessCard";
import { formatNumber } from "../../../../Shared/Utils/data";

export const ProgressCard = ({
  progress,
  hashManifest,
  width = 200,
  height = 200,
}) => {
  const amount = hashManifest.filter(
    (manifest) => manifest.conhec_docs_anexados.length === 0
  ).length;

  return (
    <div className="d-flex p-2 flex-column justify-content-center gap-3">
      <div className="d-flex p-2  justify-content-center">
        <div style={{ width: width, height: height }}>
          <CircularProgressbar
            value={progress}
            text={`${formatNumber(progress)}%`}
            styles={{
              path: {
                stroke:
                  progress <= 30
                    ? "#b94a45"
                    : progress <= 50
                    ? "#ffc107"
                    : progress <= 70
                    ? "#64e69c"
                    : "#07b141",
                strokeLinecap: "butt",
                transformOrigin: "center center",
              },
              trail: {
                stroke: "#d6d6d6",
                strokeLinecap: "butt",
                transform: "rotate(0.25turn)",
                transformOrigin: "center center",
              },
              text: {
                fill:
                  progress <= 30
                    ? "#b94a45"
                    : progress <= 50
                    ? "#ffc107"
                    : progress <= 70
                    ? "#64e69c"
                    : "#07b141",
                fontSize: "16px",
              },
              background: {
                fill:
                  progress <= 30
                    ? "#b94a45"
                    : progress <= 50
                    ? "#ffc107"
                    : progress <= 70
                    ? "#64e69c"
                    : "#07b141",
              },
            }}
          />
        </div>
      </div>
      {amount > 0 && (
        <div className="text-center">
          <span>
            {amount <= 1 ? "Resta" : "Restam"} {amount} de {hashManifest.length}{" "}
            {amount <= 1 ? "Cte para ser fechada" : "Ctes para serem fechadas"}
          </span>
        </div>
      )}
      {amount === 0 && <SuccessCard label="Documentos anexados com sucesso!" />}
    </div>
  );
};
