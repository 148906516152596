import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";

import { BsPlus } from "react-icons/bs";
import { AiOutlineMessage } from "react-icons/ai";

import { OccurencesCard } from "./OccurrencesCard";
import { RealTimeLocationList } from "./RealTimeLocationList";

import {
  Column,
  PageTitle,
  RealTimeLocationRow,
  TotalOccurrences,
} from "./styled";

import { OriginDestinationCard } from "../DetailedLoadingOrder/OriginDestinationCard";
import { OriginDestinationStatusCard } from "../DetailedLoadingOrder/OriginDestinationStatusCard";
import { BasicInformationsCard } from "../DetailedLoadingOrder/components/BasicInformationsCard/BasicInformationsCard";

import Dashboard from "../../../../Shared/layout";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";

import {
  clearGeoLoactionsDuplicates,
  extractRealTimeData,
  sortByDateAscending,
} from "../../../../Shared/Utils/data";

import { useVehicles } from "../../../../hooks/VehicleProvider";
import { useTravels } from "../../../../hooks/TravelProvider";
import { useDrivers } from "../../../../hooks/DriverProvider";
import { useQuery } from "@tanstack/react-query";
import ApiRequest from "../../../../Shared/Utils/Request";
import { RealTimeTravelMap } from "../../../../Shared/Components/LeafletMaps/RealTimeTravelMap";

const RealTimeLocation = () => {
  const [occurrenceAmount, setOccurrenceAmount] = useState(3);
  const [originAddress, setOriginAddress] = useState(null);
  const [destinyAddress, setDestinyAddress] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [distanceTimePredictions, setDistanceTimePredictions] = useState({});

  const { token } = useParams();
  const { driver, getDriver, isLoading } = useDrivers();
  const { vehicle, getVehicle } = useVehicles();

  const {
    occurrences,
    isLoadingOccurrence,
    loadOrder,
    loadOrderRoute,
    loadOrderAddressLoading,
    getOccurrences,
    getLoadOrder,
    getLoadOrderRoutes,
    getLoadOrderAddress,
    getLoadOrderByGeometryAddress,
  } = useTravels();

  useEffect(() => {
    token && getLoadOrder(token);
  }, [getLoadOrder, token]);

  const { isRefetching, error, data } = useQuery({
    queryKey: ["repoData"],
    queryFn: () =>
      ApiRequest({
        path: `contra-senha/token/${token}`,
        method: "GET",
      }),
    refetchInterval: 30000,
  });

  useEffect(() => {
    loadOrder?.id && getOccurrences(loadOrder?.id);
  }, [getOccurrences, loadOrder?.id]);

  useEffect(() => {
    const interval = setInterval(() => {
      data?.data && getOccurrences(data?.data?.id);
    }, 20000);

    return () => clearInterval(interval);
  }, [getOccurrences, data?.data]);

  useEffect(() => {
    data?.data && getLoadOrderRoutes(data?.data?.id);
  }, [data?.data, getLoadOrderRoutes]);

  useEffect(() => {
    if (data?.data?.motorista_id) {
      getDriver(data?.data?.motorista_id);
    }
  }, [data?.data?.motorista_id, getDriver]);

  useEffect(() => {
    if (data?.data?.veiculo_id) {
      getVehicle(data?.data?.veiculo_id);
    }
  }, [data?.data?.veiculo_id, getVehicle]);

  const handleSeeMoreOccurrences = useCallback(() => {
    setOccurrenceAmount((state) => state + 5);
  }, []);

  const handleOriginAddress = useCallback(async () => {
    if (data?.data?.latitude_coleta && data?.data?.longitude_coleta) {
      setOriginAddress({
        lat: data?.data?.latitude_coleta,
        lng: data?.data?.longitude_coleta,
      });
      return;
    }

    if (data?.data?.nome_cidade_coleta) {
      const position = await getLoadOrderAddress(
        `${data?.data?.nome_cidade_coleta},${data?.data?.uf_coleta},Brasil`
      );

      setOriginAddress({
        lat: position.length > 0 ? position[0]?.lat : position?.lat,
        lng: position.length > 0 ? position[0]?.lon : position?.lon,
      });
    }
  }, [
    data?.data?.latitude_coleta,
    data?.data?.longitude_coleta,
    data?.data?.nome_cidade_coleta,
    data?.data?.uf_coleta,
    getLoadOrderAddress,
  ]);

  useEffect(() => {
    data?.data && handleOriginAddress();
  }, [data?.data, handleOriginAddress]);

  const handleDestinyAddress = useCallback(async () => {
    if (data?.data?.latitude_entrega && data?.data?.longitude_entrega) {
      setDestinyAddress({
        lat: data?.data?.latitude_entrega,
        lng: data?.data?.longitude_entrega,
      });
      return;
    }

    if (data?.data?.nome_cidade_entrega) {
      const position = await getLoadOrderAddress(
        `${data?.data?.nome_cidade_entrega},${data?.data?.uf_entrega},Brasil`
      );

      setDestinyAddress({
        lat: position.length > 0 ? position[0]?.lat : position?.lat,
        lng: position.length > 0 ? position[0]?.lon : position?.lon,
      });
    }
  }, [
    data?.data?.latitude_entrega,
    data?.data?.longitude_entrega,
    data?.data?.nome_cidade_entrega,
    data?.data?.uf_entrega,
    getLoadOrderAddress,
  ]);

  useEffect(() => {
    data?.data && handleDestinyAddress();
  }, [data?.data, handleDestinyAddress]);

  const findCurrentAddress = useCallback(async () => {
    if (data?.data?.localizacao_atual) {
      const currentLocation = JSON.parse(data?.data?.localizacao_atual);

      return setCurrentAddress({
        lat: currentLocation?.geo_localizacao?.latitude,
        lng: currentLocation?.geo_localizacao?.longitude,
        desvio_rota: data?.data?.desvio_rota ?? false,
      });
    }

    if (loadOrderRoute && loadOrderRoute.length > 0) {
      const currentLocation = loadOrderRoute[0];

      return setCurrentAddress(extractRealTimeData(currentLocation));
    }
  }, [data?.data?.desvio_rota, data?.data?.localizacao_atual, loadOrderRoute]);

  useEffect(() => {
    data?.data?.id && getLoadOrderRoutes(data?.data?.id);
    data?.data && findCurrentAddress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  const handleDistance = useCallback(async () => {
    if (originAddress && destinyAddress) {
      const address = {
        currentLocation: originAddress,
        destinyLocation: destinyAddress,
      };

      const response = await getLoadOrderByGeometryAddress(address);

      response?.routes[0]?.legs[0] &&
        setDistanceTimePredictions(response?.routes[0]?.legs[0]);
    }

    if (originAddress && originAddress) {
      const address = {
        currentLocation: originAddress,
        destinyLocation: destinyAddress,
      };

      const response = await getLoadOrderByGeometryAddress(address);

      response?.routes[0]?.legs[0] &&
        setDistanceTimePredictions(response?.routes[0]?.legs[0]);
    }
  }, [destinyAddress, getLoadOrderByGeometryAddress, originAddress]);

  useEffect(() => {
    handleDistance();
  }, [handleDistance]);

  const routesSegments = useMemo(() => {
    if (loadOrderRoute) {
      const newList = sortByDateAscending(loadOrderRoute);

      const list = clearGeoLoactionsDuplicates(newList);

      return list.map((objeto) => {
        const { latitude, longitude } = JSON.parse(objeto.geo_localizacao);
        return [latitude, longitude];
      });
    }
  }, [loadOrderRoute]);

  return (
    <Dashboard title="Localização em tempo real">
      {(isLoading || loadOrderAddressLoading || isLoadingOccurrence) && (
        <Loading />
      )}
      {!isLoading && !loadOrderAddressLoading && !isLoadingOccurrence && (
        <RealTimeLocationRow className="g-1 mt-2">
          <Column xl={8}>
            {(!originAddress || !destinyAddress) && (
              <div className="d-flex align-items-center justify-content-center h-100 gap-2">
                <Spinner variant="warning" />
                <span className="fst-italic">Calculando rota...</span>
              </div>
            )}
            {
              <Card className="d-flex min-h-100 w-100 p-3 gap-3">
                {originAddress && destinyAddress && data?.data && (
                  <RealTimeTravelMap
                    height="50vh"
                    origin={originAddress}
                    destiny={destinyAddress}
                    currentAddress={currentAddress}
                    route={data?.data?.rota_selecionada}
                    loadOrder={data?.data}
                    showMore
                    showSubtitle
                    routesSegments={routesSegments}
                    isFetching={isRefetching}
                  />
                )}
                {error && <EmptyTableMessage label="Ocorreu um erro no mapa" />}
                {Array.isArray(loadOrderRoute) && loadOrderRoute.length > 0 && (
                  <RealTimeLocationList
                    loadOrderRoute={loadOrderRoute}
                    token={data?.data?.token}
                  />
                )}
                {Array.isArray(loadOrderRoute) && loadOrderRoute === 0 && (
                  <EmptyTableMessage label="Percurso não informado" />
                )}
              </Card>
            }
          </Column>
          <Column xl={4}>
            <Card className="d-flex min-h-100 w-100 p-3 gap-2">
              <div className="d-flex aling-items-center justify-content-around mb-2">
                <BasicInformationsCard title="Motorista" itemList={driver} />
                <BasicInformationsCard title="Veículos" itemList={vehicle} />
              </div>

              <OriginDestinationCard
                loadOrder={data?.data}
                distanceTimePredictions={distanceTimePredictions}
              />
              {loadOrderRoute && loadOrderRoute.length > 0 && (
                <OriginDestinationStatusCard
                  loadOrder={data?.data}
                  loadOrderRoute={loadOrderRoute}
                />
              )}
              <div>
                <div className="d-flex align-items-end justify-content-between px-1 mb-2 mt-2">
                  <PageTitle>Ocorrências</PageTitle>
                  <TotalOccurrences>
                    Total ({occurrences ? occurrences.length : 0})
                  </TotalOccurrences>
                </div>
                {isLoadingOccurrence && <Loading />}
                {!isLoadingOccurrence &&
                  data?.data?.token &&
                  Array.isArray(occurrences) &&
                  occurrences.length === 0 && (
                    <EmptyTableMessage label="Sem ocorrências registradas" />
                  )}
                {!isLoadingOccurrence &&
                  data?.data?.token &&
                  Array.isArray(occurrences) &&
                  occurrences.length > 0 && (
                    <div className="d-flex gap-3 flex-column">
                      <OccurencesCard
                        occurrences={occurrences}
                        occurrenceAmount={occurrenceAmount}
                        loadOrder={data?.data}
                        heightSize="100"
                      />
                      {occurrenceAmount < occurrences.length && (
                        <div className="d-flex justify-content-end">
                          <ButtonLink
                            label="Ver mais"
                            secondIcon={<BsPlus />}
                            fontSize="0.7rem"
                            handleClick={handleSeeMoreOccurrences}
                            buttoncolor="primary"
                          />
                        </div>
                      )}
                    </div>
                  )}
              </div>
              {data?.data?.token && (
                <div className="d-flex flex-column gap-2">
                  {data?.data?.status !== "2" && (
                    <ButtonLink
                      fontSize="0.7rem"
                      link={`/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/enviar-ocorrencia/${loadOrder?.token}`}
                      label="Conversar com o motorista"
                      buttoncolor="success"
                      icon={<AiOutlineMessage />}
                    />
                  )}
                  <ButtonLink
                    fontSize="0.7rem"
                    link={`/gestao-viagens/ordem-de-carregamento/${data?.data?.token}`}
                    label="Fechar"
                  />
                </div>
              )}
            </Card>
          </Column>
        </RealTimeLocationRow>
      )}
    </Dashboard>
  );
};

export default memo(RealTimeLocation);
