import { useMemo } from "react";
import moment from "moment";

import { CiCalendarDate } from "react-icons/ci";
import { BiIdCard } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";

import {
  AlertLabel,
  InfoBorderCard,
  InfoItem,
  InfoTitle,
  TitleContainer,
} from "./styles";

import { RegistrationStatusCard } from "./RegistrationStatusCard";
import ButtonLink from "../../../../../../../Shared/Components/ButtonLink/ButtonLink";
import { ownerType } from "../../../../../../../Shared/Utils/constants";

import {
  cnpjCpfFormat,
  formatNameString,
} from "../../../../../../../Shared/Utils/data";

export const VehicleInformationCard = ({
  informationsVehicle,
  temporaryVehicleInfos,
  handleOpenVehicleTab,
  index,
  title,
  ownerInformations,
  vehicleAttachment,
  driverInformation,
  isloadingConsult,
}) => {
  const documentExpirationDate = useMemo(() => {
    const value =
      temporaryVehicleInfos?.dados_ocr?.data?.valor &&
      moment(temporaryVehicleInfos?.dados_ocr?.data?.valor, "DD/MM/YYYY")
        .add(1, "years")
        .toDate();

    return value;
  }, [temporaryVehicleInfos?.dados_ocr?.data?.valor]);

  const ownerName = useMemo(() => {
    if (
      vehicleAttachment?.cpf_cnpj &&
      vehicleAttachment?.cpf_cnpj !== "" &&
      vehicleAttachment?.cpf_cnpj !==
        temporaryVehicleInfos?.dados_ocr?.cpf_cnpj?.valor &&
      vehicleAttachment?.nome_proprietario &&
      vehicleAttachment?.nome_proprietario !== ""
    ) {
      return vehicleAttachment?.nome_proprietario;
    }

    if (
      vehicleAttachment?.cpf_cnpj === "" &&
      vehicleAttachment?.nome_proprietario === "" &&
      temporaryVehicleInfos?.dados_ocr?.nome?.valor
    ) {
      return temporaryVehicleInfos?.dados_ocr?.nome?.valor;
    } else {
      return "--";
    }
  }, [
    vehicleAttachment?.cpf_cnpj,
    vehicleAttachment?.nome_proprietario,
    temporaryVehicleInfos?.dados_ocr?.cpf_cnpj?.valor,
    temporaryVehicleInfos?.dados_ocr?.nome?.valor,
  ]);

  const cpfOwner = useMemo(() => {
    if (vehicleAttachment?.proprietario_arrendamento) {
      return vehicleAttachment?.proprietario_arrendamento;
    }

    if (vehicleAttachment?.cpf_cnpj && vehicleAttachment?.cpf_cnpj !== "") {
      return vehicleAttachment?.cpf_cnpj;
    }

    if (temporaryVehicleInfos?.dados_ocr?.cpf_cnpj?.valor) {
      return temporaryVehicleInfos?.dados_ocr?.cpf_cnpj?.valor;
    } else {
      return "--";
    }
  }, [
    vehicleAttachment?.cpf_cnpj,
    vehicleAttachment?.proprietario_arrendamento,
    temporaryVehicleInfos?.dados_ocr?.cpf_cnpj?.valor,
  ]);

  const isExpired =
    !documentExpirationDate ||
    (documentExpirationDate && new Date(documentExpirationDate) < new Date());

  return (
    <InfoBorderCard className="w-100 card d-flex flex-column h-100">
      <div>
        <TitleContainer className="mb-3 d-flex">
          <InfoTitle className="text-start">{title.toUpperCase()}</InfoTitle>
        </TitleContainer>
      </div>

      {temporaryVehicleInfos?.dados_ocr?.placa?.valor && (
        <div className="d-flex gap-1 mb-2 justify-content-between p-2">
          <InfoItem>Placa:</InfoItem>
          <InfoItem className="fw-bold" status="placa">
            {temporaryVehicleInfos?.dados_ocr?.placa?.valor}
          </InfoItem>
        </div>
      )}
      {index && index === "0" && (
        <div className="d-flex gap-1 mb-2 align-tems-center justify-content-between p-2">
          <InfoItem>Tipo:</InfoItem>
          <InfoItem className="fw-bold">
            {ownerInformations?.tipoProprietario &&
              ownerType(ownerInformations?.tipoProprietario)}
          </InfoItem>
          {ownerInformations?.proprietarioIgualMotorista === "s" && (
            <AlertLabel
              className={`align-self-center ${
                !ownerInformations?.proprietarioVeiculoIgualMotorista
                  ? "text-danger"
                  : "text-dark"
              }`}
            >
              (Motorista declarado como proprietário do veículo)
            </AlertLabel>
          )}
        </div>
      )}

      {cpfOwner && (
        <div className="d-flex flex-column gap-1 mb-2">
          <div className="p-2">
            <InfoItem titleStyle className="fw-bold">
              Proprietário arrendatário:
            </InfoItem>
          </div>
          {ownerName && ownerName !== "--" && (
            <div className="gap-1 d-flex align-items-center mt-1 justify-content-between p-2">
              <div className="d-flex align-items-center gap-1">
                <BsPerson size={15} />
                <InfoItem titleStyle="true">Nome:</InfoItem>
              </div>
              <InfoItem
                className="fw-bold d-flex justify-content-end"
                content="true"
                titleStyle
              >
                {formatNameString(ownerName)}
              </InfoItem>
            </div>
          )}
          <div className="d-flex gap-1 mt-1 d-flex align-items-center justify-content-between p-2">
            <div className="d-flex gap-1 align-items-center">
              <BiIdCard size={17} />
              <InfoItem>CPF/CNPJ arrendatário:</InfoItem>
            </div>
            <InfoItem className="fw-bold">
              {cpfOwner && cpfOwner !== "--" ? cnpjCpfFormat(cpfOwner) : "--"}
            </InfoItem>
          </div>
        </div>
      )}

      {temporaryVehicleInfos?.dados_ocr?.nome?.valor && (
        <div className="d-flex flex-column gap-1 mb-2">
          <div className="p-1">
            <InfoItem titleStyle className="fw-bold">
              Proprietário certificado:
            </InfoItem>
          </div>
          <div className="d-flex gap-1  justify-content-between p-2">
            <div className="d-flex align-items-center gap-1">
              <BsPerson size={15} />
              <InfoItem titleStyle="true">Nome:</InfoItem>
            </div>
            <InfoItem
              className="fw-bold d-flex justify-content-end"
              content="true"
              titleStyle
            >
              {formatNameString(temporaryVehicleInfos?.dados_ocr?.nome?.valor)}
            </InfoItem>
          </div>
          <div className="d-flex gap-1 justify-content-between p-2">
            <div className="d-flex align-items-center gap-1">
              <BiIdCard size={17} />
              <InfoItem>CPF/CNPJ certificado:</InfoItem>
            </div>
            <InfoItem className="fw-bold">
              {temporaryVehicleInfos?.dados_ocr?.cpf_cnpj?.valor
                ? cnpjCpfFormat(
                    temporaryVehicleInfos?.dados_ocr?.cpf_cnpj?.valor
                  )
                : "--"}
            </InfoItem>
          </div>
        </div>
      )}

      <div className="d-flex gap-1 mb-3 justify-content-between p-2">
        <div className="d-flex align-items-center gap-1">
          <CiCalendarDate size={17} />
          <InfoItem>Vencimento do documento:</InfoItem>
        </div>
        <InfoItem
          className={`fw-bold ${isExpired ? "text-danger" : "text-success"}`}
        >
          {documentExpirationDate
            ? moment.utc(documentExpirationDate).format("DD/MM/YYYY")
            : "--"}
        </InfoItem>
      </div>

      {!isloadingConsult &&
        driverInformation?.status_cadastro === "5r" &&
        driverInformation?.processos_cadastro_concluido &&
        informationsVehicle?.data &&
        informationsVehicle?.data.length > 0 && (
          <RegistrationStatusCard
            status={informationsVehicle?.data[0]?.situacao_cadastral}
          />
        )}

      <div className="d-flex justify-content-end mt-auto p-2">
        <ButtonLink
          secondIcon={<IoIosArrowForward />}
          label="Detalhes"
          handleClick={() => handleOpenVehicleTab(index)}
          fontSize="0.7rem"
          buttoncolor="whiteColor"
          color="#5f5f5f"
        />
      </div>
    </InfoBorderCard>
  );
};
