import { Card } from "react-bootstrap";
import styled from "styled-components";

export const DriverContainer = styled.div`
  max-height: 50vh;
  flex: 1;
  overflow-x: hidden;

  @media (min-width: 425px) {
    max-height: 60vh;
  }

  @media (min-width: 576px) {
    max-height: 65vh;
  }
  @media (min-width: 1024px) {
    max-height: 65vh;
  }

  @media (min-width: 1440px) {
    max-height: 68vh;
  }

  @media (min-width: 1640px) {
    max-height: 75vh;
  }
  @media (min-width: 2440px) {
    max-height: 80vh;
  }
`;

export const PageTitle = styled(Card.Title)`
color: var(--color-gray-12);
font-weight: normal;
font-size: 1rem;
margin-bottom: 1rem;
`

export const CardContainer = styled(Card)`

@media(min-width: 1200px){
  max-height: 70vh;
  overflow-y: auto;
}
  
`;


