import { Link } from "react-router-dom";
import React from "react";
import { ButtonLinkContainer } from "./styles";

const ButtonLink = ({
  label,
  link,
  icon,
  secondIcon,
  buttoncolor,
  handleClick,
  className,
  fontSize,
  title,
  disabled = false,
  dataTestId,
  bordercolor,
  color,
  target,
}) => {
  return (
    <ButtonLinkContainer
      color={color}
      buttoncolor={buttoncolor}
      fontSize={buttoncolor === "primary" && !fontSize ? "0.7rem" : fontSize}
      className={`align-items-center rounded justify-content-center d-flex fw-bold gap-1 ${
        !bordercolor && "shadow-sm"
      } ${className} ${buttoncolor && "text-white"}`}
      as={link && Link}
      to={link && link}
      onMouseOver={(e) => (e.target.style.transform = "scale(1.05)")}
      onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
      onClick={handleClick && handleClick}
      title={title}
      disabled={disabled}
      data-testid={dataTestId}
      target={target}
    >
      {icon && icon}
      {label}
      {secondIcon && secondIcon}
    </ButtonLinkContainer>
  );
};

export default ButtonLink;
