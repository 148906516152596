import Dashboard from "../../../../Shared/layout";
import { memo, useEffect, useState } from "react";
import { OccurrencesTypesList } from "./OccurrencesTypesList";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { BsPlus } from "react-icons/bs";

import Swal from "sweetalert2";
import { useOccurrences } from "../../../../hooks/OccurrenceTypeProvider";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import TablePagination from "../../../../Shared/Components/PaginationTable/PaginationTable";

const OccurrencesTypes = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    isLoadingOccurrencesTypes,
    occurrencesTypes,
    totalPages,
    getOccurrence,
    getOccurrencesTypes,
    deleteDriver,
  } = useOccurrences();

  useEffect(() => {
    getOccurrencesTypes(currentPage);
  }, [currentPage, getOccurrencesTypes]);

  const handleDeleteOccurenceType = (occurrenceType) => {
    Swal.fire({
      icon: "warning",
      title: "Você tem certeza absoluta?",
      html: `
      <div>
        <p>Essa ação não pode ser desfeita. Isso removerá permanentemente esse tipo de ocorrência.</p>
        <p>Digite <strong style="background-color: red; color: white">${occurrenceType?.nome}</strong> para confirmar.</p>
      </div>
    `,
      input: "text",
      inputLabel: occurrenceType?.nome,
      inputPlaceholder: "Digite o nome do tipo de ocorrência",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText:
        "Eu entendo as consequências, remover tipo de ocorrência",
      cancelButtonText: "Cancelar",
      focusCancel: true,
      preConfirm: (value) => {
        if (value === occurrenceType?.nome) {
          deleteDriver(occurrenceType?.id);
          return true;
        } else {
          Swal.showValidationMessage(
            "O nome do tipo de ocorrência não corresponde. Tente novamente."
          );
          return false;
        }
      },
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Dashboard title="Tipos de ocorrências">
      <div className="d-flex justify-content-end">
        <ButtonLink
          icon={<BsPlus />}
          label="Adicionar ocorrência"
          link="/gestao-viagens/tipos-de-ocorrencias/adicionar/ocorrencia"
        />
      </div>
      {isLoadingOccurrencesTypes && <Loading />}
      {!isLoadingOccurrencesTypes && occurrencesTypes && (
        <div className="d-flex flex-column gap-3">
          <OccurrencesTypesList
            occurrencesTypes={occurrencesTypes}
            onDelete={handleDeleteOccurenceType}
            getOccurrence={getOccurrence}
          />
          <div className="mt-auto">
            <TablePagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </Dashboard>
  );
};

export default memo(OccurrencesTypes);
