import { Button, Card, Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";

export const DriverRow = styled(Row)`
margin-top: 10px;
  max-height: 50vh;
  flex: 1;
  overflow-x: hidden;

  @media (min-width: 425px) {
    max-height: 60vh;
  }

  @media (min-width: 768px) {
    max-height: 65vh;
  }

  @media (min-width: 1024px) {
    max-height: 70vh;
  }

  @media (min-width: 1640px) {
    max-height: 75vh;
  }

  @media (min-width: 2440px) {
    max-height: 80vh;
  }
`;

export const Column = styled(Col)`
  /* @media (min-width: 1024px) {
    max-height: 65vh;
    overflow-x: hidden;
  }

  @media (min-width: 1440px) {
    max-height: 70vh;
  }

  @media (min-width: 1640px) {
    max-height: 73vh;
  }

  @media (min-width: 2440px) {
    max-height: 80vh;
  } */
`;

export const BorderCard = styled(Card)`
min-height: 100%;

`



export const PageTitle = styled(Card.Title)`
color: var(--color-gray-15);
font-weight: bold;
font-size: 1rem;
`



export const Item = styled.h1`
  font-size: 0.875rem;
  color: var(--color-gray-12);
  margin-bottom: 0;



  span{
    font-weight: bold;
  }
`

export const SatContainer = styled.div`
 background-color: var(--color-blue-30);
 flex:1;
 padding: 0.5rem;
 border-radius: 8px;
`

export const SatItem = styled.span`
color: ${props => props.sat ? "#969696" : "#FFF"};
font-size: 0.65rem;
font-weight: bold;
font-style:  ${({ info }) => info && "italic"}
`

export const MarkAsInconsistentButton = styled(Button)`
  background-color: var(--color-green-10);
  align-items: center;
  border: none;

  &:hover{
  background-color: var(--color-green-10);
  opacity:0.5;
  }
`

export const FormCheckLabel = styled(Form.Check.Label)`
  font-size: 0.75rem;
`

export const ReasonForm = styled(Form.Control)`
  height: 35px;
  border: 0.5px solid var(--color-gray-11);
  padding: 0.3rem;
  flex:1;
  font-size: 0.75rem;

  &::placeholder {
    font-size: 0.75rem;
    z-index:1;
  }
`

export const ActionButton = styled(Button)`

background-color: ${props => props.save ? "#55caf4" : "#ff0f0f"};
border: none;
border-radius: 3px;

transition: transform 300ms;

&:hover{
background-color: ${props => props.save ? "#55caf4" : "#ff0f0f"};
opacity:0.7;
  /* transform: scale(1.1); */
    z-index: 1;
}
`

export const CoverImage = styled.div`
  height: 6rem;
  width: 6rem;
  background-size: cover;
  background-position: center center;
  border-radius: 500px;
  margin-bottom: 1rem;

  background-image: ${props => props.currentDriverImage ? `url(${props.currentDriverImage})` : `url(${props.driverImage})`};
`

export const BorderBotton = styled.div`
border-bottom: 0.5px dotted var(--color-gray-10);
margin-bottom:10px
`
export const RegisterItemForm = styled(Form.Control)`
 font-size: 0.8rem;
 border: 0.5px solid var(--color-gray-11);
 padding: 0.5rem;
 flex: 1;
 `

export const InfomationItem = styled.span`
  color: ${(props) =>
    props.status === "placa"
      ? "#0071c7"
      : props.status === "ATIVO"
        ? "#198754"
        : props.status === "Requer atenção"
          ? "#C3A300" : props.status === "BLOQUEADO" ? "red"
            : "#4d4d4d"};
  font-weight: normal;
  font-size: 0.875rem;
  margin-bottom: 0;  
`;

export const InfoBorderCard = styled(Card)`
  border: dashed 0.6px var(--color-gray-11);
`;