import { Col, Row } from "react-bootstrap";

import { FaRegIdCard } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { BsCalendar3 } from "react-icons/bs";

import { Item } from "./styles";
import UploadCnhImageInput from "./UploadCnhImageInput";

const CNHValidationSection = ({ driver, handleShowModal, driverDocImage }) => {
  const isExpired =
    driver?.dt_validade_cnh && new Date(driver?.dt_validade_cnh) < new Date();

  return (
    <Row xl={2} className="d-flex p-2">
      <Col xl={7}>
        <div id="cnh-flex-consulta-validations">
          <Item className="gap-1 ">
            <FaRegIdCard size={15} color="#093c5e" />
            <h3>CNH:</h3>
            <h4>{driver?.cnh ?? "--"}</h4>
          </Item>

          <Item className="gap-1 mt-4">
            <FaRegIdCard size={15} color="#093c5e" />
            <h3>Número de registro da CNH:</h3>
            <h4>{driver?.numero_registro_cnh ?? "--"}</h4>
          </Item>

          <Item className="gap-1 mt-4">
            <BiCategory size={17} color="#093c5e" />
            <h3>Categoria CNH:</h3>
            <h4>{driver?.categoria_cnh_mot ?? "--"}</h4>
          </Item>
          <Item className="gap-1 mt-4">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Validade CNH:</h3>
            <h4
              className={`fw-bold ${
                isExpired ? "text-danger" : "text-success"
              }`}
            >
              {driver?.dt_validade_cnh ?? "--"}
            </h4>
          </Item>
          <Item className="gap-1 mt-4">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Emissão CNH:</h3>
            <h4>{driver?.dt_emissao_cnh ?? "--"}</h4>
          </Item>
          <Item className="gap-1 mt-4">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Primeira CNH:</h3>
            <h4>{driver?.dt_primeira_cnh ?? "--"}</h4>
          </Item>
        </div>
      </Col>
      <Col xl={5} className="py-4 pb-1 d-flex gap-1">
        <UploadCnhImageInput
          driverDocImage={driverDocImage}
          handleShowModal={handleShowModal}
        />
      </Col>
    </Row>
  );
};

export default CNHValidationSection;
