import { Item, PageTitle } from "./styles";
import NameSimilarityCard from "../../../../../Shared/Components/NameSimilarityCard/NameSimilarityCard";
import ValidationBoolean from "./ValidationBoolean";
import ValidationIcon from "./ValidationIcon";
import ButtonLink from "../../../../../Shared/Components/ButtonLink/ButtonLink";

export const ValidationsErrorList = ({ validationValue, id }) => {
  return (
    <div className="ms-md-3">
      <PageTitle className="d-flex justify-content-center  mb-3">
        Validações Flex
      </PageTitle>
      <div>
        {!validationValue?.categoria_cnh && (
          <Item className="gap-1">
            <h3>Categoria CNH:</h3>
            <ValidationBoolean
              biometricItem={validationValue?.categoria_cnh}
              cnhItem={validationValue?.categoria_cnh}
            />
          </Item>
        )}
        {!validationValue?.cnh_emitida && (
          <Item className="gap-1">
            <h3>CNH emitida:</h3>
            <ValidationBoolean
              biometricItem={validationValue?.cnh_emitida}
              cnhItem={validationValue?.cnh_emitida}
            />
          </Item>
        )}

        {!validationValue?.cpf_motorista_disponivel && (
          <Item className="gap-1">
            <h3>CPF motorista disponível:</h3>
            <ValidationBoolean
              biometricItem={validationValue?.cpf_motorista_disponivel}
              cnhItem={validationValue?.cpf_motorista_disponivel}
            />
          </Item>
        )}

        {!validationValue?.cpf_motorista_regular && (
          <Item className="gap-1">
            <h3>CPF motorista regular:</h3>
            <ValidationBoolean
              biometricItem={validationValue?.cpf_motorista_regular}
              cnhItem={validationValue?.cpf_motorista_regular}
            />
          </Item>
        )}

        {!validationValue?.dt_primeira_cnh && (
          <Item className="gap-1">
            <h3>Data primeira cnh:</h3>
            <ValidationBoolean
              biometricItem={validationValue?.dt_primeira_cnh}
              cnhItem={validationValue?.dt_primeira_cnh}
            />
          </Item>
        )}

        {!validationValue?.dt_ultima_emissao_cnh && (
          <Item className="gap-1">
            <h3>Data da última emissão da CNH:</h3>
            <ValidationBoolean
              biometricItem={validationValue?.dt_ultima_emissao_cnh}
              cnhItem={validationValue?.dt_ultima_emissao_cnh}
            />
          </Item>
        )}

        {!validationValue?.dt_validade_cnh && (
          <Item className="gap-1">
            <h3>Data de validade da CNH:</h3>
            <ValidationBoolean
              biometricItem={validationValue?.dt_validade_cnh}
              cnhItem={validationValue?.dt_validade_cnh}
            />
          </Item>
        )}

        {(!validationValue?.nome_motorista?.similaridade ||
          validationValue?.nome_motorista?.similaridade < 0.7) && (
          <Item className="gap-1">
            <h3>Nome motorista:</h3>
            <ValidationIcon
              biometricItem={validationValue?.nome_motorista?.similaridade}
              cnhItem={validationValue?.nome_motorista?.similaridade}
            />
            {validationValue?.nome_motorista?.similaridade && (
              <NameSimilarityCard
                label="% de similariade com o nome:"
                item={validationValue?.nome_motorista?.similaridade}
              />
            )}
          </Item>
        )}

        {validationValue?.nome_mae &&
          (Object.keys(validationValue?.nome_mae)?.length === 0 ||
            validationValue?.nome_mae?.similaridade < 0.7) && (
            <Item className="gap-1">
              <h3>Nome da mãe:</h3>
              <ValidationIcon
                biometricItem={validationValue?.nome_mae?.similaridade}
                cnhItem={validationValue?.nome_mae?.similaridade}
              />
              {!!validationValue?.nome_mae?.similaridade && (
                <NameSimilarityCard
                  label="% de similariade com o nome:"
                  item={validationValue?.nome_mae?.similaridade}
                />
              )}
            </Item>
          )}

        {validationValue?.nome_pai &&
          (Object.keys(validationValue?.nome_pai)?.length === 0 ||
            validationValue?.nome_pai?.similaridade < 0.7) && (
            <Item className="gap-1">
              <h3>Nome do pai:</h3>
              <ValidationIcon
                biometricItem={validationValue?.nome_pai?.similaridade}
                cnhItem={validationValue?.nome_pai?.similaridade}
              />
              {!!validationValue?.nome_pai?.similaridade && (
                <NameSimilarityCard
                  label="% de similariade com o nome:"
                  item={validationValue?.nome_pai?.similaridade}
                />
              )}
              {!validationValue?.nome_pai?.similaridade && (
                <NameSimilarityCard
                  label="% de similariade com o nome:"
                  item={validationValue?.nome_pai?.similaridade}
                />
              )}
            </Item>
          )}

        {!validationValue?.numero_cnh && (
          <Item className="gap-1">
            <h3>Número da CNH:</h3>
            <ValidationIcon
              biometricItem={validationValue?.numero_cnh}
              cnhItem={validationValue?.numero_cnh}
            />
          </Item>
        )}

        {!validationValue?.possui_impedimento_cnh && (
          <Item className="gap-1">
            <h3>CNH sem impedimento:</h3>
            <ValidationIcon
              biometricItem={validationValue?.possui_impedimento_cnh}
              cnhItem={validationValue?.possui_impedimento_cnh}
            />
          </Item>
        )}

        {!validationValue?.sexo_motorista && (
          <Item className="gap-1">
            <h3>Gênero:</h3>
            <ValidationIcon
              biometricItem={validationValue?.sexo_motorista}
              cnhItem={validationValue?.sexo_motorista}
            />
          </Item>
        )}

        {!validationValue?.nacionalidade_motorista && (
          <Item className="gap-1">
            <h3>Nacionalidade:</h3>
            <ValidationIcon
              biometricItem={validationValue?.nacionalidade_motorista}
              cnhItem={validationValue?.nacionalidade_motorista}
            />
          </Item>
        )}
      </div>
      <div>
        <ButtonLink
          label="Ver detalhes"
          link={`/motoristas-listar/validacao-flex/${id}`}
        />
      </div>
    </div>
  );
};
