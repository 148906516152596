import { Accordion, Card } from "react-bootstrap";
import styled from "styled-components";

export const PageTitle = styled(Card.Title)`
  color: var(--color-gray-12);
  font-weight: normal;
  font-size: 0.875rem;
`;

export const Subtitle = styled.h2`
  font-size: 0.9rem;
  font-weight: bold;
`;

export const LineBorder = styled.div`
  background-color: var(--color-blue-80);
  width: 3.6rem;
  height: 0.2rem;
  margin-bottom: 1rem;
`;



export const AccordionHeader = styled(Accordion.Header)`
  > .accordion-button:focus {
    box-shadow: none;
  }

  > button.accordion-button[aria-expanded="true"],
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-btn-color);
    background-color: var(--bs-accordion-btn-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
      var(--bs-accordion-border-color);
  }

  > .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
  }
`;

export const Border = styled.div`
border-top: 1px solid var(--color-gray-10);
margin-right: 30px;
`

