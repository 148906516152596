export const allNationalities = [
  { value: "170", label: "ABISSÍNIA" },
  { value: "171", label: "AÇORES" },
  { value: "172", label: "AFAR FRANCES" },
  { value: "241", label: "AFEGANISTÃO" },
  { value: "093", label: "ALBÂNIA" },
  { value: "030", label: "ALEMANHA" },
  { value: "174", label: "ALTO VOLTA" },
  { value: "094", label: "ANDORRA" },
  { value: "175", label: "ANGOLA" },
  { value: "334", label: "ANTÁRTICA FRANCESA" },
  { value: "337", label: "ANTÁRTICO ARGENTINO" },
  { value: "333", label: "ANTÁRTICO BRITÂNICO, TERRITÓRIO" },
  { value: "336", label: "ANTÁRTICO CHILENO" },
  { value: "338", label: "ANTÁRTICO NORUEGUES" },
  { value: "028", label: "ANTÍGUA E. DEP. BARBUDA" },
  { value: "029", label: "ANTILHAS HOLANDESAS" },
  { value: "339", label: "APATRIDA" },
  { value: "242", label: "ARÁBIA SAUDITA" },
  { value: "176", label: "ARGÉLIA" },
  { value: "021", label: "ARGENTINA" },
  { value: "347", label: "ARMÊNIA" },
  { value: "289", label: "ARQUIPÉLAGO DE BISMARK" },
  { value: "285", label: "ARQUIPÉLAGO MANAHIKI" },
  { value: "286", label: "ARQUIPÉLAGO MIDWAY" },
  { value: "033", label: "ARUBA" },
  { value: "198", label: "ASCENSÃO E TRISTÃO DA CUNHA,IS" },
  { value: "287", label: "ASHMORE E CARTIER" },
  { value: "288", label: "AUSTRÁLIA" },
  { value: "095", label: "ÁUSTRIA" },
  { value: "138", label: "AZERBAIJÃO" },
  { value: "243", label: "BAHREIN" },
  { value: "342", label: "BANGLADESH" },
  { value: "139", label: "BASHKISTA" },
  { value: "044", label: "BARBADOS" },
  { value: "177", label: "BECHUANALÂNDIA" },
  { value: "031", label: "BÉLGICA" },
  { value: "046", label: "BELIZE" },
  { value: "178", label: "BENIN" },
  { value: "083", label: "BERMUDAS" },
  { value: "246", label: "BHUTAN" },
  { value: "244", label: "BIRMÂNIA" },
  { value: "022", label: "BOLÍVIA" },
  { value: "134", label: "BÓSNIA HERZEGOVINA" },
  { value: "179", label: "BOTSUANA" },
  { value: "010", label: "BRASIL" },
  { value: "245", label: "BRUNEI" },
  { value: "096", label: "BULGÁRIA" },
  { value: "238", label: "BURKINA FASSO" },
  { value: "180", label: "BURUNDI" },
  { value: "141", label: "BURYAT" },
  { value: "343", label: "CABO VERDE" },
  { value: "181", label: "CAMARÕES" },
  { value: "034", label: "CANADÁ" },
  { value: "142", label: "CARELIA" },
  { value: "247", label: "CATAR" },
  { value: "143", label: "CAZAQUISTÃO" },
  { value: "248", label: "CEILÃO" },
  { value: "182", label: "CEUTA E MELILLA" },
  { value: "183", label: "CHADE" },
  { value: "144", label: "CHECHEN INGUSTH" },
  { value: "023", label: "CHILE" },
  { value: "042", label: "CHINA" },
  { value: "249", label: "CHINA (TAIWAN)" },
  { value: "097", label: "CHIPRE" },
  { value: "145", label: "CHUVASH" },
  { value: "275", label: "CINGAPURA" },
  { value: "026", label: "COLÔMBIA" },
  { value: "040", label: "COMUNIDADE DAS BAHAMAS" },
  { value: "054", label: "COMUNIDADE DOMINICANA" },
  { value: "185", label: "CONGO" },
  { value: "043", label: "CORÉIA" },
  { value: "186", label: "COSTA DO MARFIM" },
  { value: "051", label: "COSTA RICA" },
  { value: "250", label: "COVEITE" },
  { value: "130", label: "CROÁCIA" },
  { value: "052", label: "CUBA" },
  { value: "053", label: "CURAÇAO" },
  { value: "146", label: "DAGESTA" },
  { value: "187", label: "DAOMÉ" },
  { value: "340", label: "DEPENDÊNCIA DE ROSS" },
  { value: "098", label: "DINAMARCA" },
  { value: "188", label: "DJIBUTI" },
  { value: "099", label: "EIRE" },
  { value: "251", label: "EMIRADOS ÁRABES UNIDOS" },
  { value: "027", label: "EQUADOR" },
  { value: "100", label: "ESCÓCIA" },
  { value: "136", label: "ESLOVÁQUIA" },
  { value: "132", label: "ESLOVÊNIA" },
  { value: "035", label: "ESPANHA" },
  { value: "129", label: "ESTADO DA CIDADE DO VATICANO" },
  { value: "057", label: "ESTADOS ASSOC. DAS ANTILHAS" },
  { value: "036", label: "ESTADOS UNIDOS DA AMÉRICA (EUA)" },
  { value: "147", label: "ESTÔNIA" },
  { value: "190", label: "ETIÓPIA" },
  { value: "252", label: "FILIPINAS" },
  { value: "102", label: "FINLÂNDIA" },
  { value: "037", label: "FRANÇA" },
  { value: "192", label: "GÂMBIA" },
  { value: "193", label: "GANA" },
  { value: "194", label: "GAZA" },
  { value: "148", label: "GEÓRGIA" },
  { value: "103", label: "GIBRALTAR" },
  { value: "149", label: "GORNO ALTAI" },
  { value: "032", label: "GRÃ-BRETANHA" },
  { value: "059", label: "GRANADA" },
  { value: "104", label: "GRÉCIA" },
  { value: "084", label: "GROENLÂNDIA" },
  { value: "292", label: "GUAM" },
  { value: "061", label: "GUATEMALA" },
  { value: "087", label: "GUIANA FRANCESA" },
  { value: "195", label: "GUINÉ" },
  { value: "344", label: "GUINÉ BISSAU" },
  { value: "196", label: "GUINÉ EQUATORIAL" },
  { value: "105", label: "HOLANDA" },
  { value: "064", label: "HONDURAS" },
  { value: "063", label: "HONDURAS BRITÂNICAS" },
  { value: "253", label: "HONG-KONG" },
  { value: "106", label: "HUNGRIA" },
  { value: "254", label: "IEMEN" },
  { value: "345", label: "IEMEN DO SUL" },
  { value: "197", label: "IFNI" },
  { value: "300", label: "ILHA JOHNSTON E SAND" },
  { value: "069", label: "ILHA MILHOS" },
  { value: "293", label: "ILHAS BAKER" },
  { value: "107", label: "ILHAS BALEARES" },
  { value: "199", label: "ILHAS CANÁRIAS" },
  { value: "294", label: "ILHAS CANTÃO E ENDERBURG" },
  { value: "295", label: "ILHAS CAROLINAS" },
  { value: "297", label: "ILHAS CHRISTMAS" },
  { value: "184", label: "ILHAS COMORES" },
  { value: "290", label: "ILHAS COOK" },
  { value: "108", label: "ILHAS COSMOLEDO (LOMORES)" },
  { value: "117", label: "ILHAS DE MAN" },
  { value: "109", label: "ILHAS DO CANAL" },
  { value: "296", label: "ILHAS DO PACÍFICO" },
  { value: "058", label: "ILHAS FALKLANDS" },
  { value: "101", label: "ILHAS FAROES" },
  { value: "298", label: "ILHAS GILBERT" },
  { value: "060", label: "ILHAS GUADALUPE" },
  { value: "299", label: "ILHAS HOWLAND E JARVIS" },
  { value: "301", label: "ILHAS KINGMAN REEF" },
  { value: "313", label: "ILHAS LINHA" },
  { value: "305", label: "ILHAS MACDONAL E HEARD" },
  { value: "302", label: "ILHAS MACQUAIRE" },
  { value: "067", label: "ILHAS MALVINAS" },
  { value: "303", label: "ILHAS MARIANAS" },
  { value: "304", label: "ILHAS MARSHALL" },
  { value: "306", label: "ILHAS NIUE" },
  { value: "307", label: "ILHAS NORFOLK" },
  { value: "315", label: "ILHAS NOVA CALEDÔNIA" },
  { value: "318", label: "ILHAS NOVAS HEBRIDAS" },
  { value: "308", label: "ILHAS PALAU" },
  { value: "320", label: "ILHAS PÁSCOA" },
  { value: "321", label: "ILHAS PITCAIRIN" },
  { value: "309", label: "ILHAS SALOMmO" },
  { value: "326", label: "ILHAS SANTA CRUZ" },
  { value: "065", label: "ILHAS SERRANAS" },
  { value: "310", label: "ILHAS TOKELAU" },
  { value: "080", label: "ILHAS TURCA" },
  { value: "047", label: "ILHAS TURKS E CAICOS" },
  { value: "082", label: "ILHAS VIRGENS AMERICANAS" },
  { value: "081", label: "ILHAS VIRGENS BRITÂNICAS" },
  { value: "311", label: "ILHAS WAKE" },
  { value: "332", label: "ILHAS WALLIS E FUTUNA" },
  { value: "255", label: "ÍNDIA" },
  { value: "256", label: "INDONÉSIA" },
  { value: "110", label: "INGLATERRA" },
  { value: "257", label: "IRÃ" },
  { value: "258", label: "IRAQUE" },
  { value: "112", label: "IRLANDA" },
  { value: "111", label: "IRLANDA DO NORTE" },
  { value: "113", label: "ISLÂNDIA" },
  { value: "259", label: "ISRAEL" },
  { value: "039", label: "ITÁLIA" },
  { value: "114", label: "IUGOSLÁVIA" },
  { value: "066", label: "JAMAICA" },
  { value: "041", label: "JAPÃO" },
  { value: "260", label: "JORDÂNIA" },
  { value: "150", label: "KABARDINO BALKAR" },
  { value: "312", label: "KALIMATAN" },
  { value: "151", label: "KALMIR" },
  { value: "346", label: "KARA KALPAK" },
  { value: "152", label: "KARACHAEVOCHERKESS" },
  { value: "153", label: "KHAKASS" },
  { value: "261", label: "KMER/CAMBOJA" },
  { value: "154", label: "KOMI" },
  { value: "262", label: "KUWAIT" },
  { value: "263", label: "LAOS" },
  { value: "200", label: "LESOTO" },
  { value: "155", label: "LETÔNIA" },
  { value: "264", label: "LÍBANO" },
  { value: "201", label: "LIBÉRIA" },
  { value: "202", label: "LÍBIA" },
  { value: "115", label: "LIECHTENSTEIN" },
  { value: "156", label: "LITUÂNIA" },
  { value: "116", label: "LUXEMBURGO" },
  { value: "265", label: "MACAU" },
  { value: "205", label: "MADAGASCAR" },
  { value: "203", label: "MADEIRA" },
  { value: "266", label: "MALÁSIA" },
  { value: "204", label: "MALAWI" },
  { value: "267", label: "MALDIVAS,IS" },
  { value: "206", label: "MALI " },
  { value: "157", label: "MARI" },
  { value: "207", label: "MARROCOS" },
  { value: "068", label: "MARTINICA" },
  { value: "268", label: "MASCATE" },
  { value: "208", label: "MAURÍCIO" },
  { value: "209", label: "MAURITÂNIA" },
  { value: "085", label: "MÉXICO" },
  { value: "284", label: "MIANMA" },
  { value: "210", label: "MOÇAMBIQUE" },
  { value: "158", label: "MOLDÁVIA" },
  { value: "118", label: "MÔNACO" },
  { value: "269", label: "MONGÓLIA" },
  { value: "070", label: "MONTE SERRAT" },
  { value: "137", label: "MONTENEGRO" },
  { value: "240", label: "NAMÍBIA" },
  { value: "314", label: "NAURU" },
  { value: "270", label: "NEPAL" },
  { value: "211", label: "NGUANE" },
  { value: "071", label: "NICARÁGUA" },
  { value: "213", label: "NIGÉRIA" },
  { value: "119", label: "NORUEGA" },
  { value: "316", label: "NOVA GUINÉ" },
  { value: "317", label: "NOVA ZELÂNDIA" },
  { value: "271", label: "OMAN" },
  { value: "159", label: "OSSETIA SETENTRIONAL" },
  { value: "121", label: "PAÍS DE GALES" },
  { value: "122", label: "PAÍSES BAIXOS" },
  { value: "272", label: "PALESTINA" },
  { value: "072", label: "PANAMÁ" },
  { value: "073", label: "PANAMÁ – ZONA DO CANAL" },
  { value: "214", label: "PAPUA NOVA GUINÉ" },
  { value: "273", label: "PAQUISTÃO" },
  { value: "024", label: "PARAGUAI" },
  { value: "089", label: "PERU" },
  { value: "322", label: "POLINÉSIA FRANCESA" },
  { value: "123", label: "POLÔNIA" },
  { value: "074", label: "PORTO RICO" },
  { value: "045", label: "PORTUGAL" },
  { value: "215", label: "PRAÇAS NORTE AFRICANAS" },
  { value: "216", label: "PROTETOR DO SUDOESTE AFRICANO" },
  { value: "217", label: "QUÊNIA" },
  { value: "160", label: "QUIRGUISTÃO" },
  { value: "075", label: "QUITASUENO" },
  { value: "189", label: "REPÚBLICA ÁRABE DO EGITO" },
  { value: "218", label: "REPÚBLICA CENTRO AFRICANA" },
  { value: "173", label: "REPÚBLICA DA ÁFRICA DO SUL" },
  { value: "140", label: "REPÚBLICA DA BIELORRÚSSIA" },
  { value: "133", label: "REPÚBLICA DA MACEDÔNIA" },
  { value: "056", label: "REPÚBLICA DE EL SALVADOR" },
  { value: "291", label: "REPÚBLICA DE FIJI" },
  { value: "120", label: "REPÚBLICA DE MALTA" },
  { value: "191", label: "REPÚBLICA DO GABÃO" },
  { value: "062", label: "REPÚBLICA DO HAITI" },
  { value: "212", label: "REPÚBLICA DO NÍGER" },
  { value: "055", label: "REPÚBLICA DOMINICANA" },
  { value: "088", label: "REPÚBLICA GUIANA" },
  { value: "135", label: "REPÚBLICA TCHECA" },
  { value: "020", label: "RESERVADO" },
  { value: "048", label: "RESERVADO" },
  { value: "049", label: "RESERVADO" },
  { value: "050", label: "RESERVADO" },
  { value: "219", label: "REUNIÃO" },
  { value: "220", label: "RODÉSIA (ZIMBÁBWE)" },
  { value: "124", label: "ROMÊNIA" },
  { value: "076", label: "RONCADOR" },
  { value: "221", label: "RUANDA" },
  { value: "274", label: "RUIQUIU,IS" },
  { value: "348", label: "RÚSSIA" },
  { value: "222", label: "SAARA ESPANHOL" },
  { value: "323", label: "SABAH" },
  { value: "324", label: "SAMOA AMERICANA" },
  { value: "325", label: "SAMOA OCIDENTAL" },
  { value: "125", label: "SAN MARINO" },
  { value: "223", label: "SANTA HELENA" },
  { value: "077", label: "SANTA LÚCIA" },
  { value: "078", label: "SÃO CRISTÓVÃO" },
  { value: "224", label: "SÃO TOMÉ E PRÍNCIPE" },
  { value: "079", label: "SÃO VICENTE" },
  { value: "327", label: "SARAWAK" },
  { value: "349", label: "SENEGAL" },
  { value: "276", label: "SEQUIN" },
  { value: "226", label: "SERRA LEOA" },
  { value: "131", label: "SÉRVIA" },
  { value: "225", label: "SEYCHELLES" },
  { value: "277", label: "SÍRIA" },
  { value: "227", label: "SOMÁLIA, REPÚBLICA" },
  { value: "278", label: "SRI-LANKA" },
  { value: "086", label: "ST. PIERRE ET MIQUELON" },
  { value: "228", label: "SUAZILÂNDIA" },
  { value: "229", label: "SUDÃO" },
  { value: "126", label: "SUÉCIA" },
  { value: "038", label: "SUÍÇA" },
  { value: "090", label: "SURINAME" },
  { value: "127", label: "SVALBARD E JAN MAYER,IS" },
  { value: "161", label: "TADJIQUISTÃO" },
  { value: "279", label: "TAILÂNDIA" },
  { value: "230", label: "TANGANICA" },
  { value: "350", label: "TANZÂNIA" },
  { value: "162", label: "TARTARIA" },
  { value: "128", label: "TCHECOSLOVÁQUIA" },
  { value: "335", label: "TERR. ANTÁRTICO DA AUSTRÁLIA" },
  { value: "341", label: "TERRAS AUSTRAIS" },
  { value: "231", label: "TERRIT. BRITÂNICO DO OCEANO ÍNDICO" },
  { value: "328", label: "TERRITÓRIO DE COCOS" },
  { value: "319", label: "TERRITÓRIO DE PAPUA" },
  { value: "329", label: "TIMOR" },
  { value: "233", label: "TOGO" },
  { value: "330", label: "TONGA" },
  { value: "232", label: "TRANSKEI" },
  { value: "280", label: "TRÉGUA, ESTADO" },
  { value: "091", label: "TRINIDAD E TOBAGO" },
  { value: "234", label: "TUNÍSIA" },
  { value: "163", label: "TURCOMENISTÃO" },
  { value: "281", label: "TURQUIA" },
  { value: "331", label: "TUVALU" },
  { value: "164", label: "TUVIN" },
  { value: "165", label: "UCRÂNIA" },
  { value: "166", label: "UDMURT" },
  { value: "235", label: "UGANDA" },
  { value: "167", label: "UNIÃO SOVIÉTICA" },
  { value: "025", label: "URUGUAI" },
  { value: "168", label: "UZBEQUISTÃO" },
  { value: "092", label: "VENEZUELA" },
  { value: "282", label: "VIETNÃ DO NORTE" },
  { value: "283", label: "VIETNÃ DO SUL" },
  { value: "169", label: "YAKUT" },
  { value: "236", label: "ZAIRE" },
  { value: "237", label: "ZÂMBIA" },
  { value: "239", label: "ZIMBÁBWE" }
]