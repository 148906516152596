import { BorderCard, OriginDestinationCardContainer, Status } from "./styles";
import { BsArrowRight } from "react-icons/bs";
import { loaderOrderStatus } from "../../../../Shared/Utils/constants";

export const OriginDestinationCard = ({
  loadOrder,
  distanceTimePredictions,
}) => {
  return (
    <OriginDestinationCardContainer className="w-100  rounded-0 p-1">
      <div className="d-md-flex justify-content-around align-items-center mb-2">
        <div className="d-flex flex-column align-items-center gap-2 mb-2 justify-content-center">
          <span>Origem:</span>
          <h3>
            {loadOrder?.nome_cidade_coleta
              ? loadOrder?.nome_cidade_coleta
              : "--"}
            {loadOrder?.uf_coleta && `(${loadOrder?.uf_coleta})`}
          </h3>
        </div>
        <div className="d-none d-md-flex">
          <BsArrowRight />
        </div>
        <div className="d-flex flex-column align-items-center gap-2 mb-2 justify-content-center">
          <span>Destino:</span>
          <h3>
            {loadOrder?.nome_cidade_entrega
              ? loadOrder?.nome_cidade_entrega
              : "--"}
            {loadOrder?.uf_entrega && `(${loadOrder?.uf_entrega})`}
          </h3>
        </div>
      </div>
      <div className="mb-2 px-1">
        <div>
          <span className="fw-bold">Origem:</span>
          <div className="d-flex gap-2">
            <div className="d-flex gap-1 align-items-center mb-2">
              <span>Lat:</span>
              <h3>{`${loadOrder?.latitude_coleta ?? "--"}`}</h3>
            </div>
            <div className="d-flex gap-1 align-items-center mb-2">
              <span>Lng:</span>
              <h3>{`${loadOrder?.longitude_coleta ?? "--"}`}</h3>
            </div>
          </div>
        </div>
        <div className="mb-2">
          <span className="fw-bold">Destino:</span>
          <div className="d-flex gap-2">
            <div className="d-flex gap-1 align-items-center mb-2">
              <span>Lat:</span>
              <h3>{`${loadOrder?.latitude_entrega ?? "--"}`}</h3>
            </div>
            <div className="d-flex gap-1 align-items-center mb-2">
              <span>Lng:</span>
              <h3>{`${loadOrder?.longitude_entrega ?? "--"}`}</h3>
            </div>
          </div>
        </div>

        <BorderCard>
          <div className="d-flex gap-2 align-items-center mb-2 mt-2">
            <span>Distância:</span>
            <h3>
              {loadOrder?.distancia_horas_total
                ? loadOrder?.distancia_horas_total
                : distanceTimePredictions
                ? distanceTimePredictions?.duration?.text
                : "--"}
            </h3>
          </div>

          <div className="d-flex gap-2 align-items-center mb-2">
            <span>Previsão duração total:</span>
            <h3>
              {loadOrder?.distancia_total
                ? loadOrder?.distancia_total
                : distanceTimePredictions
                ? distanceTimePredictions?.distance?.text
                : "--"}
            </h3>
          </div>

          <div className="d-flex gap-1 align-items-center mb-3">
            <span>Status da viagem:</span>
            <Status status={loadOrder?.status}>
              {loadOrder?.status ? loaderOrderStatus(loadOrder?.status) : "--"}
            </Status>
          </div>
        </BorderCard>
      </div>
    </OriginDestinationCardContainer>
  );
};
