import { InfomationItem } from "./styles";

export const StatusSat = ({ driverSat }) => {
  return (
    <div className="d-flex gap-1">
      <InfomationItem>Status:</InfomationItem>
      <InfomationItem
        className={`fw-bold ${
          driverSat?.liberado === "N" ? "text-danger" : "text-success"
        }`}
      >
        {driverSat?.bloqueadoadm === "S"
          ? "Bloqueado ADM"
          : driverSat?.liberado === "S"
          ? "Liberado"
          : driverSat?.liberado === "N"
          ? "Inativo"
          : "--"}
      </InfomationItem>
    </div>
  );
};
