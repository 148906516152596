import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest from "../Shared/Utils/Request";

export const PermissionContext = createContext({
  permissionsByScope: [],
  perfilActions: [],
  isLoading: false,
  loadingPostPermition: false,
  loadingPerfilActions: false,
  loadingPermissionsByScope: false,
  getPermissionsByScope: () => {},
  postActionsToPerfil: () => null,
  postPermissions: () => null,
  getPerfilActions: () => {},
});

export const usePermissions = () => {
  const context = useContext(PermissionContext);

  if (!context) {
    throw new Error("usePermissions must be within PermissionProvider");
  }

  return context;
};

export const PermitionProvider = ({ children }) => {
  const [permissionsByScope, setPermissionsByScope] = useState([]);
  const [perfilActions, setPerfilActions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPostPermition, setLoadingPostPermition] = useState(false);
  const [loadingPerfilActions, setLoadingPerfilActions] = useState(false);
  const [loadingPermissionsByScope, setLoadingPermissionsByScope] =
    useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getPermissionsByScope = useCallback(async (scope) => {
    try {
      setLoadingPermissionsByScope(true);
      setIsSubmitting(false);
      const response = await ApiRequest({
        path: `permissoes/escopo/${scope}`,
        method: "GET",
      });

      setPermissionsByScope(response?.data);
      setIsSubmitting(false);
      setLoadingPermissionsByScope(false);
    } catch (error) {
      setPermissionsByScope([]);
    } finally {
      setLoadingPermissionsByScope(false);
    }
  }, []);

  const postPermissions = useCallback(async (data) => {
    try {
      setLoadingPostPermition(true);
      setIsSubmitting(false);
      const response = await ApiRequest({
        path: "acoes/perfil",
        method: "POST",
        data,
      });

      setPermissionsByScope(response?.data);
      setIsSubmitting(false);
      setLoadingPostPermition(false);

      return response?.data;
    } catch (error) {
      setPermissionsByScope([]);
      setLoadingPostPermition(false);
    } finally {
      setLoadingPostPermition(false);
    }
  }, []);

  const postActionsToPerfil = useCallback(async (data) => {
    try {
      setIsLoading(true);
      setIsSubmitting(false);

      const response = await ApiRequest({
        path: "acoes/perfil",
        method: "POST",
        data,
      });

      setIsSubmitting(false);

      return response.data;
    } catch (error) {
      console.error();
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getPerfilActions = useCallback(async (id) => {
    try {
      setLoadingPerfilActions(true);
      setIsSubmitting(false);

      const response = await ApiRequest({
        path: `acoes/perfil/${id}`,
        method: "GET",
      });

      setIsSubmitting(false);
      setLoadingPerfilActions(false);

      return setPerfilActions(response?.data);
    } catch (error) {
      setPerfilActions([]);
      console.error();
    } finally {
      setLoadingPerfilActions(false);
    }
  }, []);

  const providerValue = useMemo(
    () => ({
      permissionsByScope,
      perfilActions,
      isLoading,
      isSubmitting,
      loadingPostPermition,
      loadingPerfilActions,
      loadingPermissionsByScope,
      getPermissionsByScope,
      postActionsToPerfil,
      getPerfilActions,
      postPermissions,
    }),
    [
      permissionsByScope,
      perfilActions,
      isLoading,
      isSubmitting,
      loadingPostPermition,
      loadingPerfilActions,
      loadingPermissionsByScope,
      getPermissionsByScope,
      postActionsToPerfil,
      getPerfilActions,
      postPermissions,
    ]
  );

  return (
    <PermissionContext.Provider value={providerValue}>
      {children}
    </PermissionContext.Provider>
  );
};
