import { ButtonContainer } from "./styles";

export const Button = ({
  label,
  backgroundColor,
  color,
  border,
  leftIcon,
  rightIcon,
  ...props
}) => {
  return (
    <ButtonContainer
      backgroundColor={backgroundColor}
      border={border}
      color={color}
      {...props}
      className="d-flex align-items-center justify-content-center flex-grow-1 gap-1"
    >
      {leftIcon} {label} {rightIcon}
    </ButtonContainer>
  );
};
