import { CloseButton, FormControl, InputControl } from "./styles";
import { SlMagnifier } from "react-icons/sl";
import { IoCloseSharp } from "react-icons/io5";

const Search = ({
  searchValue,
  label,
  handleSearchChange,
  disabled = false,
}) => {
  return (
    <InputControl
      data-testid="search-container"
      className="customCursor shadow-none d-flex align-items-center btn btn-gray-background-dark"
    >
      {searchValue && (
        <CloseButton
          onClick={() => {
            handleSearchChange("");
          }}
          title="Limpar pesquisa"
        >
          <IoCloseSharp size={16} />
        </CloseButton>
      )}
      <FormControl
        data-testid="search-input"
        className="customCursor shadow-none btn btn-gray-background-dark"
        placeholder={`${label}`}
        aria-label="Username"
        aria-describedby="basic-addon1"
        value={searchValue}
        disabled={disabled}
        onChange={(event) => {
          handleSearchChange(event);
        }}
      />
      <CloseButton
        onClick={() => {
          handleSearchChange(searchValue);
        }}
        title="Pesquisar"
      >
        <SlMagnifier color="#565656" />
      </CloseButton>
    </InputControl>
  );
};

export default Search;
