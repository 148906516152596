import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { ValueItem } from "./styles";

const ValidationBoolean = ({ biometricItem, cnhItem }) => {
  return (
    <>
      {biometricItem && (
        <ValueItem item={biometricItem}>
          {biometricItem === true ? (
            <AiOutlineCheckCircle size={20} />
          ) : (
            <AiOutlineCloseCircle size={20} />
          )}
        </ValueItem>
      )}
      {!biometricItem && (
        <ValueItem item={cnhItem}>
          {cnhItem && cnhItem === true ? (
            <AiOutlineCheckCircle size={20} />
          ) : (
            <AiOutlineCloseCircle size={20} />
          )}
        </ValueItem>
      )}
    </>
  );
};

export default ValidationBoolean;
