import { Ratio } from "react-bootstrap";
import { BorderForm, CoverImage, ImageTitle, ShowModalButton } from "./styles";
import { MdOutlineFilterCenterFocus } from "react-icons/md";

const UploadOwnerDocImage = ({ image, handleShowModal, title }) => {
  return (
    <BorderForm className="align-self-stretch w-100 d-flex flex-column">
      <ImageTitle className="text-start mb-1">{title}</ImageTitle>

      {image.startsWith("data:application/pdf") ? (
        <div style={{ width: "100%", height: "0", paddingBottom: "100%" }}>
          <Ratio aspectRatio="1x1">
            <embed type="application/pdf" src={image} />
          </Ratio>
        </div>
      ) : (
        <CoverImage image={image} />
      )}
      <div className="align-text-end mt-auto">
        <ShowModalButton onClick={handleShowModal} className="p-1">
          <div className="d-flex align-items-center gap-1 px-0">
            <MdOutlineFilterCenterFocus />
            Visualizar documento
          </div>
        </ShowModalButton>
      </div>
    </BorderForm>
  );
};

export default UploadOwnerDocImage;
