import { Table } from "react-bootstrap";

import {
  ProcessListContainer,
  ProcessesTableTitle,
  TableTitle,
  Thead,
} from "./styles";

import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";
import { TableList } from "./TableList";

const ProcessesList = ({
  ongoingProcessesList,
  isLoading,
  getTempRegister,
}) => {
  return (
    <ProcessListContainer
      className="mt-4"
      data-testid="proccess-list-container"
    >
      <Table hover size="sm" className="border rounded mb-0">
        <Thead>
          <ProcessesTableTitle>
            <TableTitle className="ps-3">Nome</TableTitle>
            <TableTitle>CPF</TableTitle>
            <TableTitle>Status do cadastro</TableTitle>
            <TableTitle className="text-center px-2 pe-md-3">Ações</TableTitle>
          </ProcessesTableTitle>
        </Thead>
        <tbody>
          {isLoading && <Loading />}
          {!isLoading &&
            ongoingProcessesList?.length > 0 &&
            ongoingProcessesList.map((driver, index) => (
              <TableList
                driver={driver}
                getTempRegister={getTempRegister}
                key={index}
              />
            ))}
        </tbody>
      </Table>
      {!isLoading &&
        ongoingProcessesList &&
        ongoingProcessesList.length === 0 && <EmptyTableMessage />}
    </ProcessListContainer>
  );
};

export default ProcessesList;
