import { Card } from "react-bootstrap";

import EmptyImage from "../../../../../../Assets/Images/EmptyImage.png";
import { BorderCard, CoverImage } from "./styles";
import { formatLicensePlate } from "../../../../../../Shared/Utils/data";
import { StatusCard } from "../../../../../../Shared/Components/StatusCard";

const ControlVehicleCard = ({ vehicle }) => {
  return (
    <BorderCard className="text-center w-100 h-100 flex-1  d-flex flex-column">
      <Card.Body className="text-center pb-0 mb-4">
        <CoverImage
          height={0}
          width="100%"
          paddingBottom="100%"
          emptyImage={EmptyImage}
        />
        <div className="text-center d-flex flex-column mt-3 mb-4">
          <h3 className="text-center mb-1">{vehicle?.nome}</h3>
          <h4 className="text-center mb-3">
            {formatLicensePlate(vehicle?.placa)}
          </h4>
          {vehicle.status === "9" && <StatusCard label="Em análise" />}
          {vehicle.status !== "9" && (
            <h5 className="text-center">
              Status:
              <span
                className={`ms-1 mt-3 ${
                  vehicle?.ativo === true ? "color-active" : "color-red"
                } `}
              >
                {vehicle?.bloqueado_adm === "S"
                  ? "Bloqueado Adm "
                  : vehicle?.ativo === true
                  ? "Liberado"
                  : "Desativado"}
              </span>
            </h5>
          )}
        </div>
      </Card.Body>
    </BorderCard>
  );
};

export default ControlVehicleCard;
