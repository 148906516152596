import styled from "styled-components";

export const Thumbnail = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
`;

export const RemoveButton = styled.button`
  background-color: var(--color-soft-red);
  border: none;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  transition: transform 200ms;

  &:hover {
    transform: scale(1.01);
  }
`;

export const CardList = styled.div`
  background-color: #fffafa;
  border-radius: 4px;
  padding: 10px;
`;

export const BorderCard = styled.div`
  border: 2px solid var(--color-red-50);
  border-radius: 4px;
  padding: 15px;

  span {
    font-size: 1.3rem;
  }
`;
