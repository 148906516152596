import { memo, useCallback } from "react";
import Dashboard from "../../../../../Shared/layout";
import { OccurencesForm } from "../OccurencesForm/OccurencesForm";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useOccurrences } from "../../../../../hooks/OccurrenceTypeProvider";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";

const AddOccurrence = () => {
  const { isLoadingOccurrencesTypes, addOccurrence } = useOccurrences();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = useCallback(
    (data) => {
      addOccurrence(data, navigate);
    },
    [addOccurrence, navigate]
  );

  return (
    <Dashboard title="Adicionar ocorrência">
      {isLoadingOccurrencesTypes && <Loading />}
      {!isLoadingOccurrencesTypes && (
        <OccurencesForm
          register={register}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          errors={errors}
          isSubmitting={isSubmitting}
        />
      )}
    </Dashboard>
  );
};

export default memo(AddOccurrence);
