import { Form } from "react-bootstrap";
import styled from "styled-components";

export const PerfilTableTitle = styled.tr`
th {
  font-size: 0.85rem;
  font-weight: bold;
}
`

export const DescriptionFormControl = styled(Form.Control)`
  height: 100px;
  padding: 1rem;
  border-color: var(--color-gray-10);


 @media(min-width: 768px){
 height: 150px;
 }
 
 @media(min-width: 1024px){
 height: 200px;
 }

 @media(min-width: 1440px){
 height: 300px;
 }
  `

export const PerfilListContainer = styled.div`
 max-height: 15vh;
flex: 1;
overflow: auto; 

 @media(min-width: 425px){
  max-height:30vh;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }
 

 @media(min-width: 1440px){
 
  max-height: 80vh;
  }

  @media(min-width: 1640px){
 
  max-height: 75vh;
  }
  @media(min-width: 2440px){
 
  max-height: 80vh;
  }
  `
export const PerfilFormContainer = styled.div`
 max-height: 30vh;
flex: 1;
overflow-x: hidden;

 

 @media(min-width: 375px){
  max-height:50vh;
 }

 @media(min-width: 425px){
  max-height:55vh;
 }

 @media(min-width: 768px){
  max-height: 50vh;
 }

 @media(min-width: 992px){
  max-height: 60vh;
 }


 @media(min-width: 1440px){
 
  max-height: 70vh;
  }

  @media(min-width: 1640px){
 
  max-height: 75vh;
  }
  @media(min-width: 2440px){
 
  max-height: 80vh;
  }
  `