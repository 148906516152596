import { Spinner } from "react-bootstrap";
import { ContainerButton } from "./Styles";

export const SubmitButton = ({
  isSubmitting,
  labelButton,
  onClick,
  icon,
  buttoncolor = "primary",
  disabled,
  fontSize,
  dataTestId,
  className,
}) => {
  return (
    <ContainerButton
      size="sm"
      type="submit"
      disabled={isSubmitting || disabled}
      className={`py-2 px-4 d-flex align-items-center fw-bold gap-2 border-none justify-content-center ${className}`}
      buttoncolor={buttoncolor}
      onClick={onClick}
      fontSize={fontSize}
      data-testid={dataTestId}
    >
      {!isSubmitting ? (
        <span>
          {icon && icon}
          <span className={`${icon && "ms-1"}`}>{labelButton}</span>
        </span>
      ) : (
        <>
          <Spinner size="sm" animation="border" variant="light" />
          <span>Aguarde...</span>
        </>
      )}
    </ContainerButton>
  );
};
