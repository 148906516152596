import { Card } from "react-bootstrap";
import { BasicInformationsCard } from "./components/BasicInformationsCard/BasicInformationsCard";
import { BindingCard } from "./components/BindingCard/BindingCard";
import { cnpjCpfFormat } from "../../../../Shared/Utils/data";

export const DriverInformation = ({
  loadOrder,
  driver,
  owner,
  vehicle,
  drivers,
  owners,
  vehicles,
  getDriver,
  getOwner,
  getVehicle,
  handleSubmit,
  setValue,
  isSubmitting,
  relationshipBetweenAttributes,
  editLoadOrder,
  reset,
}) => {
  return (
    <Card className="w-100 py-3 px-1" style={{ minHeight: "100%" }}>
      <Card.Body className="gap-4 d-md-flex justify-content-md-between justify-content-xl-start flex-xl-column">
        {loadOrder?.motorista_id && (
          <BasicInformationsCard
            title="Motorista"
            itemList={driver}
            document={driver?.cpf && cnpjCpfFormat(driver?.cpf)}
            status={driver?.ativo}
            className="mb-4 mb-md-0 mb-xl-4"
          />
        )}
        {!loadOrder?.motorista_id && (
          <BindingCard
            titleCard="Motorista"
            list={drivers}
            itemList={driver}
            getItemList={getDriver}
            handleSubmit={handleSubmit}
            setValue={setValue}
            isSubmitting={isSubmitting}
            relationshipBetweenAttributes={relationshipBetweenAttributes}
            editLoadOrder={editLoadOrder}
            id={loadOrder?.id}
            reset={reset}
            loadOrder={loadOrder}
          />
        )}
        {loadOrder?.proprietario_id && (
          <BasicInformationsCard
            title="Proprietário"
            itemList={owner}
            className="mb-4 mb-md-0 mb-xl-4"
          />
        )}
        {!loadOrder?.proprietario_id && (
          <BindingCard
            titleCard="Proprietário"
            list={owners}
            itemList={owner}
            getItemList={getOwner}
            handleSubmit={handleSubmit}
            setValue={setValue}
            isSubmitting={isSubmitting}
            relationshipBetweenAttributes={relationshipBetweenAttributes}
            editLoadOrder={editLoadOrder}
            id={loadOrder?.id}
            reset={reset}
            loadOrder={loadOrder}
          />
        )}
        {loadOrder?.veiculo_id && (
          <BasicInformationsCard
            title="Veículos"
            itemList={vehicle}
            className="mb-4 mb-md-0 mb-xl-4"
          />
        )}
        {!loadOrder?.veiculo_id && (
          <BindingCard
            titleCard="Veículo"
            list={vehicles}
            itemList={vehicle}
            getItemList={getVehicle}
            handleSubmit={handleSubmit}
            setValue={setValue}
            isSubmitting={isSubmitting}
            relationshipBetweenAttributes={relationshipBetweenAttributes}
            editLoadOrder={editLoadOrder}
            id={loadOrder?.id}
            reset={reset}
            loadOrder={loadOrder}
          />
        )}
      </Card.Body>
    </Card>
  );
};
