import { Card } from "react-bootstrap";
import { CardBodyContainer, StateTitle, TotalDrivers } from "./styles";
import { MonitoringDriverList } from "../MonitoringDriverList/MonitoringDriverList";
import { useMemo } from "react";

export const TravelMonitoringByStateCard = ({
  activeLoadOrder,
  handleSelectDriver,
  handleSelectVehicle,
  listBy,
}) => {
  const stateCard = useMemo(() => {
    if (activeLoadOrder[0]?.localizacao_atual) {
      const currentAddress = JSON.parse(activeLoadOrder[0]?.localizacao_atual);

      return currentAddress?.uf;
    } else if (activeLoadOrder[0]?.uf_coleta) {
      return activeLoadOrder[0]?.uf_coleta;
    } else {
      return activeLoadOrder[0]?.uf_cidade_origem;
    }
  }, [activeLoadOrder]);

  const driversAmount = useMemo(() => {
    const nomes = activeLoadOrder.map((item) => item.nome_mot);
    const nomesUnicos = new Set(nomes.filter(Boolean));
    return nomesUnicos.size;
  }, [activeLoadOrder]);

  return (
    <Card className="mb-1  w-100">
      <Card.Body>
        <CardBodyContainer>
          <StateTitle className="text-start">{stateCard}</StateTitle>
          <div className="d-flex aling-items-center justify-content-center">
            <TotalDrivers>
              <span>{`Total de ${driversAmount} ${
                driversAmount > 1 ? "motoristas" : "motorista"
              }`}</span>
            </TotalDrivers>
          </div>
        </CardBodyContainer>
        <div className="mb-2">
          <MonitoringDriverList
            activeLoadOrder={activeLoadOrder}
            handleSelectDriver={handleSelectDriver}
            handleSelectVehicle={handleSelectVehicle}
            listBy={listBy}
          />
        </div>
      </Card.Body>
    </Card>
  );
};
