import { memo, useCallback, useEffect } from "react";
import Dashboard from "../../../../../Shared/layout";
import { useCompanies } from "../../../../../hooks/CompanyProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { Container } from "./styles";
import { CompanyForm } from "../components/CompanyForm/CompanyForm";

const UpdateCompany = () => {
  const { id } = useParams();

  const { company, isSubmitting, isLoading, updateCompany, getCompany } =
    useCompanies();

  useEffect(() => {
    getCompany(id);
  }, [getCompany, id]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = useCallback(
    (id, data) => {
      data.cep = data?.cep.replace(/\D+/g, "");
      updateCompany(id, data, navigate);
    },
    [navigate, updateCompany]
  );

  return (
    <Dashboard title="Editar empresa">
      {isLoading && <Loading />}

      {!isLoading && company && (
        <Container>
          <CompanyForm
            onSubmit={handleFormSubmit}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            isSubmitting={isSubmitting}
            company={company}
          />
        </Container>
      )}
    </Dashboard>
  );
};

export default memo(UpdateCompany);
