import React from "react";
import { User, HeaderContainer, LogoffButton, Perfil } from "./styles";

import { AvatarFallBack } from "../AvatarFallBack";
import { BsBoxArrowInRight } from "react-icons/bs";

export const Header = ({ nome, currentPerfilName, desconect }) => {
  const userName = nome && nome.split(" ");

  return (
    <HeaderContainer className="d-none d-lg-inline" id="header">
      <div className="d-flex align-items-center justify-content-end py-2">
        <div className="d-md-flex text-end align-items-center justify-content-end gap-3">
          <div>
            <User>Olá, {userName[0]}</User>
            <Perfil>{currentPerfilName}</Perfil>
          </div>
          <AvatarFallBack />
          <LogoffButton
            onClick={() => desconect()}
            className="btn-background-blue-dark rounded d-flex gap-1 align-items-center"
          >
            Sair
            <BsBoxArrowInRight size={16} />
          </LogoffButton>
        </div>
      </div>
    </HeaderContainer>
  );
};
