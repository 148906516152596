import { FaDotCircle } from "react-icons/fa";
import { Title } from "./styles";

export const PageTitle = ({ label }) => {
  return (
    <Title className="py-2 mb-2 d-flex align-items-center gap-1">
      <span>
        <FaDotCircle size={12} color="#07b141" /> {label}
      </span>
    </Title>
  );
};
