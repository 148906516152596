import moment from "moment";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { manifestStatus } from "../../../../../Shared/Utils/constants";
import { cpfCnpjFormat } from "../../../../../Shared/Utils/data";

export const ManifestInformations = ({ manifest }) => {
  return (
    <div>
      <Row xs={1} md={2} lg={3} xl={4} className="d-flex g-2 p-2">
        <Form.Group as={Col} controlId="code_manifest">
          <FloatingLabel controlId="code_manifest" label="Código externo">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={manifest?.manif_cod}
              disabled
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} controlId="oc_cte_date">
          <FloatingLabel controlId="oc_cte_date" label="Data MDF-e">
            <Form.Control
              size="sm"
              type="date"
              defaultValue={
                manifest?.manif_data &&
                moment.utc(manifest?.manif_data).format("YYYY-MM-DD")
              }
              disabled
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} controlId="create_date">
          <FloatingLabel controlId="create_date" label="Data criação">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={
                manifest?.manif_datadigitacao &&
                moment.utc(manifest?.manif_datadigitacao).format("DD/MM/YYYY")
              }
              disabled
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} controlId="update_date">
          <FloatingLabel
            controlId="update_date"
            label="Data última atualização"
          >
            <Form.Control
              size="sm"
              type="text"
              defaultValue={
                manifest?.manif_dataatual &&
                moment.utc(manifest.manif_dataatual).format("DD/MM/YYYY")
              }
              disabled
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} controlId="status_manifest">
          <FloatingLabel controlId="status_manifest" label="Status manifesto">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={manifestStatus(manifest?.manif_status_mdfe)}
              disabled
              className={`${
                manifest?.manif_status_mdfe === "2"
                  ? "bg-success bg-opacity-25 text-success"
                  : manifest?.manif_status_mdfe === "6"
                  ? ""
                  : "bg-danger bg-opacity-25 text-danger"
              }`}
              style={
                manifest?.manif_status_mdfe === "6"
                  ? {
                      backgroundColor: "#c8eaff",
                      color: "#0a6fad",
                    }
                  : {}
              }
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} controlId="manifest_vehicle_code">
          <FloatingLabel
            controlId="manifest_vehicle_code"
            label="Código veículo"
          >
            <Form.Control
              size="sm"
              type="text"
              defaultValue={manifest?.veic_codveiculo}
              disabled
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} controlId="manifest_vehicle_placa">
          <FloatingLabel controlId="manifest_vehicle_placa" label="Placa">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={manifest?.veic_placa}
              disabled
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} controlId="manifest_driver_code">
          <FloatingLabel
            controlId="manifest_driver_code"
            label="Código motorista"
          >
            <Form.Control
              size="sm"
              type="text"
              defaultValue={manifest?.mot_codmot}
              disabled
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} controlId="manifest_driver_cpf">
          <FloatingLabel controlId="manifest_driver_cpf" label="CPF motorista">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={
                manifest?.mot_cpf && cpfCnpjFormat(manifest?.mot_cpf)
              }
              disabled
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} controlId="manifest_driver_name">
          <FloatingLabel
            controlId="manifest_driver_name"
            label="Nome motorista"
          >
            <Form.Control
              size="sm"
              type="text"
              defaultValue={manifest?.mot_nome}
              disabled
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} controlId="manifest_origin">
          <FloatingLabel controlId="manifest_origin" label="Origem">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={`${manifest?.manif_nomecidadeorigem} - ${manifest?.manif_ufcidadeorigem}`}
              disabled
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} controlId="manifest_destiny">
          <FloatingLabel controlId="manifest_destiny" label="Destino">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={`${manifest?.manif_nomecidadedestino} - ${manifest?.manif_ufcidadedestino}`}
              disabled
            />
          </FloatingLabel>
        </Form.Group>
      </Row>
      <Row className="d-flex g-2 px-2">
        <Form.Group as={Col} xs={12} lg={6} controlId="cte_key">
          <FloatingLabel controlId="cte_key" label="Chave MDF-e">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={manifest?.manif_chave_mdfe}
              disabled
            />
          </FloatingLabel>
        </Form.Group>
      </Row>
    </div>
  );
};
