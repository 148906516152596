export const categories = [
  { id: 1, label: "A" },
  { id: 2, label: "B" },
  { id: 3, label: "C" },
  { id: 4, label: "D" },
  { id: 5, label: "E" },
  { id: 6, label: "AB" },
  { id: 7, label: "AC" },
  { id: 8, label: "AD" },
  { id: 9, label: "AE" },
];

export const activeOptions = [
  { id: 1, label: "Listar todos" },
  { id: 2, label: "Liberados" },
  { id: 3, label: "Desativados" },
  { id: 4, label: "Em análise" },
];
export const driverListOptions = [
  { id: 1, label: "Listar todos" },
  { id: 2, label: "Liberados" },
  { id: 3, label: "Desativados" },
];

export const nationalitiesOptions = [
  { id: 1, label: "Brasileiro (a)", value: "1" },
  { id: 2, label: "Brasileiro (a) naturalizado (a)", value: "2" },
  { id: 3, label: "Estrangeiro (a)", value: "3" },
  { id: 4, label: "Brasileiro (a) nascido (a) no exterior", value: "4" },
];

export const gendersOptions = [
  { id: 1, label: "Feminino", value: "F" },
  { id: 2, label: "Masculino", value: "M" },
];

export const civilStatus = [
  { id: 1, label: "Solteiro(a)", value: "1" },
  { id: 2, label: "Casado(a)", value: "2" },
  { id: 3, label: "Separado(a)", value: "3" },
  { id: 4, label: "Divorciado(a)", value: "4" },
  { id: 5, label: "Viúvo(a)", value: "5" },
  { id: 6, label: "União estável", value: "6" },
  { id: 7, label: "Concubinato", value: "7" },
];

export const statusRNTRC = [
  { id: 1, label: "Ativo", value: "V" },
  { id: 2, label: "Inativo", value: "I" },
  { id: 3, label: "Inválido", value: "N" },
  { id: 4, label: "Suspenso", value: "S" },
  { id: 4, label: "Não consta", value: "NAOCONSTA" },
];

export const taxationTypes = [
  { id: 1, label: "Normal", value: "0" },
  { id: 2, label: "Simples", value: "1" },
];

export const agregadoTypes = [
  { id: 1, label: "Próprio", value: "P" },
  { id: 2, label: "Terceiro", value: "N" },
  { id: 3, label: "Agregado", value: "S" },
];

export const anttTypes = [
  { id: 1, label: "Físico (TAC)", value: "1" },
  { id: 2, label: "Jurídico (ETC)", value: "2" },
  { id: 3, label: "Cooperativa (CTC)", value: "3" },
  { id: 4, label: "Equiparado (ETC/TAC)", value: "4" },
];

export const bloqueioSatStatus = (status) => {
  if (status === 0) {
    return "Solicitação biometria - Cadastro ativo SAT";
  }

  if (status === 1) {
    return "Solicitação biometria - Cadastro inativo SAT";
  }

  if (status === 2) {
    return "Solicitação biometria - Cadastro bloqueado";
  }

  return "Solicitação biometria";
};

export const loaderOrderStatus = (status) => {
  if (status === "0") return "Criado";
  if (status === "1") return "Enviado ao motorista";
  if (status === "2") return "Concluído";
  if (status === "3") return "Divergência";
  if (status === "4") return "Processo com erro";
  if (status === "5") return "Vencida";
  if (status === "6") return "Processo com pendência de informação";
  if (status === "7") return "Conflito";
  if (status === "8") return "Em curso";
  if (status === "9") return "Pendente de rota";
  if (status === "10") return "Pendente de finalização";
  if (status === "60") return "Em curso";

  return "--";
};

export const registrationStatus = (
  status,
  registrationProcessesCompleted,
  block
) => {
  if (status === "0r") return "Ativo";
  if (status === "1r" && block === 0)
    return "Pré-cadastro motorista - Cadastro ativo SAT";
  if (status === "1r" && block === 1)
    return "Pré-cadastro motorista - Cadastro inativo SAT";
  if (status === "1r" && block === 2)
    return "Pré-cadastro motorista - Cadastro bloqueado";
  if (status === "1r" && block === null) return "Pré-cadastro motorista";
  if (status === "2r") return "Em análise";
  if (status === "3r") return "Reprovado";
  if (status === "4r") return "Processo com erro";
  if (status === "5r" && registrationProcessesCompleted === true)
    return "Aguardando aprovação";
  if (status === "6r") return "Aprovado";
  if (status === "7r") return "Pré-cadastro com inconsistência";
  if (status === "10r") return "Erro: Não foi possível concluir a análise";

  return "--";
};

export const ownerType = (value) => {
  if (value === 1) return "Pessoa física";
  if (value === 2) return "Pessoa jurídica";
  if (value === 3) return "Cooperativa";
  if (value === 4) return "Equiparado";

  return "--";
};

export const centerPositions = {
  lat: -14.235,
  lng: -51.9253,
};

export const colourStyles = {
  option: (styles, { isSelected, isHovered }) => {
    return {
      ...styles,
      fontSize: "0.8rem",
      backgroundColor: isHovered ? "#EDEFF2" : "#FFF",
      color: "#4d4d4d",

      ":active": {
        ...styles[":active"],
        backgroundColor: isSelected ? "#EDEFF2" : "#f6f6f6",
      },

      ":hover": {
        ...styles[":hover"],
        backgroundColor: "#EDEFF2",
      },
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      fontSize: "0.8rem",
      color: "#4d4d4d",
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontSize: "0.8rem",
      color: "#4d4d4d",
    };
  },
};

export const colourSelectStyles = (showBorder) => ({
  option: (styles, { isSelected, isHovered }) => {
    return {
      ...styles,
      fontSize: "0.8rem",
      backgroundColor: isHovered ? "#EDEFF2" : "#FFF",
      color: "#4d4d4d",

      ":active": {
        ...styles[":active"],
        backgroundColor: isSelected ? "#EDEFF2" : "#f6f6f6",
      },

      ":hover": {
        ...styles[":hover"],
        backgroundColor: "#EDEFF2",
      },
    };
  },

  menu: (provided) => ({
    ...provided,
    position: "absolute",
    zIndex: 999,
  }),

  placeholder: (styles) => {
    return {
      ...styles,
      fontSize: "0.8rem",
      color: "#4d4d4d",
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontSize: "0.8rem",
      color: "#4d4d4d",
    };
  },
  control: (styles) => {
    return {
      ...styles,
      borderLeft: showBorder ? "1px solid red" : "none",
    };
  },
});

export const replaceSpecialChars = (str) => {
  str = str.toLowerCase();
  str = str.replace(/[áàâãä]/gi, "a");
  str = str.replace(/[éèêë]/gi, "e");
  str = str.replace(/[íìîï]/gi, "i");
  str = str.replace(/[óòôõö]/gi, "o");
  str = str.replace(/[úùûü]/gi, "u");

  return str;
};

export const categoriesOptions = [
  { id: 1, label: "A" },
  { id: 2, label: "B" },
  { id: 3, label: "C" },
  { id: 4, label: "D" },
  { id: 5, label: "E" },
  { id: 6, label: "AB" },
  { id: 7, label: "AC" },
  { id: 8, label: "AD" },
  { id: 9, label: "AE" },
];

export const driverInitialState = {
  nome: false,
  cpf: false,
  numero_celular: false,
  cnh: false,
  categoria_cnh_mot: false,
  dt_validade_cnh: false,
  dt_emissao_cnh: false,
  dt_primeira_cnh: false,
  foto_cnh: false,
  foto_motorista: false,
  rg_motorista: false,
  sexo: false,
  dt_nascimento: false,
  nome_pai: false,
  nome_mae: false,
  nacionalidade: false,
  cep: false,
  cidade: false,
  uf: false,
  bairro: false,
  endereco: false,
  orgao_rg: false,
  dt_emissao_rg: false,
  cod_nacionalidade: false,
  numero_registro_cnh: false,
};

export const manifestStatus = (status) => {
  if (status === "2") return "Autorizado";
  if (status === "4") return "Cancelado";
  if (status === "7") return "Encerrado";
  if (status === "8") return "Erro ao encerrar MDF-e, tentando novamente";

  return "Erro";
};
export const eventStatus = (status) => {
  if (status === "2") return "Autorizado";

  return "Erro";
};
export const eventTypes = (type) => {
  if (type === 1) return "Cancelamento";
  if (type === 3) return "Encerramento";
  if (type === 4) return "Troca de motorista";

  return "Erro";
};
