import { cnpjCpfFormat } from "../../Utils/data";
import { WarningCard } from "../WarningCard/WarningCard";
import { WarningLinkCard } from "../WarningLinkCard/WarningLinkCard";
import { Container, InfoItem, InfoTitle, TitleContainer } from "./styles";

const ItemCard = ({ item }) => {
  return (
    <div className="d-flex gap-1 mb-2 justify-content-between p-2">
      <InfoItem>{item?.label}:</InfoItem>
      <InfoItem className="fw-bold" status={item?.status}>
        {item?.value}
      </InfoItem>
    </div>
  );
};

export const DriverStatusAndInformationsCard = ({
  date,
  status,
  ownerStatus,
  cpf,
  driverConsult,
}) => {
  const itemsList = [
    {
      label: "CPF motorista",
      status: false,
      value: cpf && cnpjCpfFormat(cpf),
    },
    {
      label: "Data do cadastro",
      status: false,
      value: date ?? "--",
    },
    {
      label: `${
        status && status !== "Em análise"
          ? "Situação do CPF do motorista na Receita Federal"
          : "Situação do cadastro"
      }`,
      status: status ?? "Pré-cadastro",
      value: status ?? "Pré-cadastro",
    },
  ];

  const arrestWarrant =
    driverConsult &&
    driverConsult?.consulta_mandados_prisao &&
    JSON.parse(driverConsult?.consulta_mandados_prisao);

  return (
    <Container className="w-100 card d-flex flex-column h-100 gap-2">
      <TitleContainer className="mb-3 d-flex">
        <InfoTitle className="text-start">DETALHES DO CADASTRO</InfoTitle>
      </TitleContainer>

      {itemsList.map((item, index) => (
        <ItemCard item={item} key={index} />
      ))}
      {arrestWarrant && arrestWarrant?.code === 200 && (
        <WarningLinkCard
          label="Atenção! O motorista possui mandados de prisão"
          title="Ver detalhes dos mandados de prisão"
          id="arrest-warrant-warning-card"
        />
      )}
      {ownerStatus && (
        <div className="mt-auto">
          <WarningCard label="A declaração do motorista como proprietário não foi confirmada, pois o CPF fornecido não corresponde ao registrado no CRLV." />
        </div>
      )}
    </Container>
  );
};
