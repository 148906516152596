import styled from "styled-components";

export const Title = styled.div`
  span{
    font-size: 1.2rem;

    @media(min-width: 768px){
      font-size: 1.3rem;
    }
  }
`