import { Spinner } from "react-bootstrap";
import { AnaliticMessage } from "./styles";

export const TemporaryAnaliticMessage = ({
  driver,
  isloadingConsult,
  judicialProcess,
}) => {
  return (
    <>
      {((driver?.status_cadastro === "5r" &&
        driver?.processos_cadastro_concluido === false) ||
        driver?.status_cadastro === "2r" ||
        isloadingConsult) && (
        <div className="d-flex justify-content-end mb-2">
          <div className="d-flex aling-items-center gap-1">
            <Spinner size="sm" animation="border" variant="warning" />
            <AnaliticMessage>Analisando cadastro...</AnaliticMessage>
          </div>
        </div>
      )}
    </>
  );
};
