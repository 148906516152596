import { Form, Button, FloatingLabel } from "react-bootstrap";
import styled from "styled-components";

export const ContainerForm = styled(Form)`
  width: 70%;
`

export const PasswordButton = styled(Button)`
  background-color: transparent !important;
  border: none !important;
  color: var(--color-gray-12);
  font-size: 1.5rem;

  &:hover{
    background-color: transparent !important;
    border: none !important;
    color: var(--color-gray-15);  
  }

  &:focus{
    background-color: transparent !important;
    border: none !important;
    color: var(--color-gray-15); 
  }
 
`

export const PasswordInputContainer = styled(FloatingLabel)`
  background-color: transparent !important;
  border: 1px solid var(--color-gray-10);
  border-radius: 7px;
  border-left: 2px solid red;
`

export const PasswordFormControl = styled(Form.Control)`
  border: none;

  &:focus{
    outline: none;
    box-shadow: none;
  }
`