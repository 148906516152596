import { useIdleTimer } from "react-idle-timer";
import { useCallback, useEffect, useState } from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { onlogout } from "../../Utils/Auth";
import { ModalBody } from "./styles";

export const IdleTimerContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remaining, setRemaining] = useState(30000);

  const navigate = useNavigate();

  const onIdle = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 600_000,
    throttle: 500,
  });

  useEffect(() => {
    if (isModalOpen && remaining > 0) {
      const interval = setInterval(() => {
        setRemaining((prevRemaining) => prevRemaining - 1000);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleCancelLogout = useCallback(() => {
    setRemaining(30000);
    setIsModalOpen(false);
  }, []);

  const handleLogout = useCallback(() => {
    onlogout().then(() => navigate("/"));
  }, [navigate]);

  useEffect(() => {
    if (remaining === 0) {
      handleLogout();
    }
  }, [handleLogout, remaining]);

  return (
    <div>
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        centered
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton onClick={handleCancelLogout}>
          <Modal.Title>Alerta de inatividade</Modal.Title>
        </Modal.Header>
        <ModalBody className="d-flex flex-column">
          <h2 className="text-center mb-4">
            Você está inativo há muito tempo. Deseja permanecer conectado?
          </h2>
          <ProgressBar
            now={((30000 - remaining) / 30000) * 100}
            max={100}
            min={0}
            variant="warning"
            animated
          />

          <span className="text-end">
            Você será desconectado em: {remaining > 0 ? remaining / 1000 : 0}
          </span>
          <div className="mt-4 d-flex gap-2 justify-content-end">
            <Button onClick={handleLogout} variant="warning">
              Sair
            </Button>
            <Button onClick={handleCancelLogout}>Continuar</Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
