import { Col, Row } from "react-bootstrap";
import Dashboard from "../../../../../Shared/layout";
import { useDrivers } from "../../../../../hooks/DriverProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import StatisticCard from "../../../../../Shared/Components/StatisticCard/StatisticCard";
import Search from "../../../../../Shared/Components/Search/Search";
import { sortedNames } from "../../../../../Shared/Utils/data";
import TablePagination from "../../../../../Shared/Components/PaginationTable/PaginationTable";
import Select from "react-select";
import Loader from "../../../../../Shared/Components/Loader/Loader";
import { ListLoader } from "../../../../../Shared/Components/ListLoader/ListLoader";
import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";
import { driverListOptions } from "../../../../../Shared/Utils/constants";
import { DriverCardContainer, DriverListRow, LoaderContainer } from "./styles";
import DriversCard from "../components/DriversCard/DriversCard";

const DriversList = () => {
  const [searchDriver, setSearchDriver] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isTyping, setIsTyping] = useState(false);
  const [isActive, setIsActive] = useState(null);

  const {
    drivers,
    allDrivers,
    isLoading,
    loadingAllDrivers,
    totalPages,
    getDrivers,
    getAllDrivers,
    getDriver,
  } = useDrivers();

  useEffect(() => {
    getAllDrivers();
  }, [getAllDrivers]);

  useEffect(() => {
    if (isTyping) {
      setTimeout(() => {
        setIsTyping(false);
      }, 1000);
    }
  }, [isTyping]);

  const handleSelectActiveStatus = useCallback(
    (event) => {
      setCurrentPage(1);

      if (event?.label === "Liberados") {
        setIsActive(true);
        return getDrivers(currentPage, searchDriver, true);
      }

      if (event?.label === "Desativados" || event?.label === "Bloqueado ADM") {
        setIsActive(false);
        return getDrivers(currentPage, searchDriver, false);
      }

      if (event?.label === "Listar todos") {
        setIsActive(null);
        return getDrivers(currentPage, searchDriver, null);
      }
    },
    [currentPage, getDrivers, searchDriver]
  );

  const handleSearchChange = useCallback(
    (event) => {
      setSearchDriver(event && event !== "" ? event.target.value : "");
      setCurrentPage(1);
      setIsTyping(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSelectActiveStatus]
  );

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSelectActiveStatus]
  );

  const filteredDrivers = useMemo(() => {
    return (
      drivers &&
      drivers.filter((driver) => {
        const matchesSearch =
          driver?.nome.toLowerCase().includes(searchDriver.toLowerCase()) ||
          driver?.cpf.toLowerCase().includes(searchDriver.toLowerCase());
        return matchesSearch;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drivers, searchDriver, handleSelectActiveStatus]);

  useEffect(() => {
    if (!isTyping) {
      getDrivers(currentPage, searchDriver, isActive);
    }
  }, [currentPage, getDrivers, searchDriver, isTyping, isActive]);

  return (
    <Dashboard>
      <Row className="mb-3 d-flex justify-content-end align-items-center">
        <Col xsm={12} sm={7} lg={4}>
          <Search
            label="Pesquisar motorista por nome ou CPF"
            searchValue={searchDriver}
            handleSearchChange={handleSearchChange}
          />
        </Col>
      </Row>

      {
        <DriverListRow className="g-3 h-100">
          <Col md={9} lg={10} className="d-flex flex-column">
            <div className="d-flex">
              <Select
                className="react-select mb-3"
                options={driverListOptions}
                onChange={handleSelectActiveStatus}
                value={isActive}
                placeholder="-- Filtrar motorista --"
              />
            </div>

            {filteredDrivers &&
              !isTyping &&
              !isLoading &&
              filteredDrivers.length > 0 && (
                <DriverCardContainer xs={1} md={2} xl={3} className="g-3 mb-3">
                  {Array.isArray(drivers) &&
                    sortedNames(filteredDrivers).map((driver) => (
                      <Col className="d-flex" key={driver?.id}>
                        <DriversCard driver={driver} getDriver={getDriver} />
                      </Col>
                    ))}
                </DriverCardContainer>
              )}

            {(isLoading || isTyping) && <ListLoader />}
            {filteredDrivers.length === 0 && !isTyping && !isLoading && (
              <EmptyTableMessage />
            )}

            <div className="mt-auto d-flex align-items-end">
              {!isLoading &&
                drivers &&
                !isTyping &&
                filteredDrivers &&
                filteredDrivers.length > 0 &&
                Array.isArray(drivers) &&
                drivers.length > 0 && (
                  <TablePagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                  />
                )}
              {(isLoading || isTyping) && (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )}
            </div>
          </Col>
          <Col md={3} lg={2}>
            {loadingAllDrivers && <Loader />}
            {!loadingAllDrivers && (
              <StatisticCard items={allDrivers} label="motoristas" />
            )}
          </Col>
        </DriverListRow>
      }
    </Dashboard>
  );
};

export default DriversList;
