import { Row } from "react-bootstrap";
import { BsCapslock, BsPinMap } from "react-icons/bs";
import { CgReorder } from "react-icons/cg";
import styled from "styled-components";

export const LoadingListOrderIcon = styled(CgReorder)`
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 2rem;
`
export const TravelsIcon = styled(BsPinMap)`
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 1.7rem;
`
export const OccurrencesIcon = styled(BsCapslock)`
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 1.7rem;
`

export const TravelManagementRow = styled(Row)`
max-height: 35vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 375px){
  max-height: 40vh;
 }

 @media(min-width: 425px){
  max-height: 50vh;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }
 

 @media(min-width: 1440px){
  overflow-x: auto;
  max-height: 80vh;
  }

  @media(min-width: 1640px){
  overflow-x: auto;
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  overflow-x: auto;
  max-height: 80vh;
  }
`