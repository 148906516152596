import React, { useState, useRef, useCallback, useEffect } from "react";
import Webcam from "react-webcam";

import { FaArrowLeft, FaPlus, FaCamera } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { TbCameraPlus } from "react-icons/tb";

import {
  BtnsContainer,
  CloseCameraButton,
  FileUploadContainer,
  ModalButton,
  ModalContent,
  ModalWrapper,
  TakePictureButton,
  WebcamContainer,
} from "./styles";

export const WebcamCapture = ({ photos, setPhotos, setErrorValidateImage }) => {
  const webcamRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const videoConstraints = {
    facingMode: ["environment"],
    width: { ideal: 1280 },
    height: { ideal: 720 },
    frameRate: { ideal: 30 },
  };

  const handleCaptureImage = useCallback(() => {
    setErrorValidateImage(null);
    const imageSrc = webcamRef.current.getScreenshot();

    setPhotos([...photos, imageSrc]);
    setIsOpen(false);
  }, [setPhotos, photos]);

  const handleOpenCloseCamera = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-3 w-100">
      <div>
        <FileUploadContainer onClick={handleOpenCloseCamera} className="w-100">
          <label className="custom-file-upload text-center">
            <div className="d-flex justify-content-center">
              <TbCameraPlus size={64} />
            </div>
            Abrir câmera
          </label>
        </FileUploadContainer>

        {isOpen && (
          <ModalWrapper>
            <ModalContent>
              <WebcamContainer>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  forceScreenshotSourceSize
                  screenshotQuality={1}
                />

                <BtnsContainer>
                  <div className="d-flex align-items-end gap-2 justify-content-center gap-5">
                    <div className="d-flex flex-column align-items-center">
                      <TakePictureButton onClick={handleCaptureImage}>
                        <FaCamera />
                      </TakePictureButton>
                      <span className="text-white">Capturar</span>
                    </div>

                    <CloseCameraButton>
                      <button onClick={handleOpenCloseCamera}>
                        <IoClose />
                      </button>
                      <span className="text-white">Fechar</span>
                    </CloseCameraButton>
                  </div>
                </BtnsContainer>
              </WebcamContainer>
            </ModalContent>
          </ModalWrapper>
        )}
      </div>
    </div>
  );
};
