import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";

import { extractRealTimeData } from "../../../../Shared/Utils/data";

import { useTravels } from "../../../../hooks/TravelProvider";
import { useDrivers } from "../../../../hooks/DriverProvider";
import { RealTimeTravelMap } from "../../../../Shared/Components/LeafletMaps/RealTimeTravelMap";
import { useQuery } from "@tanstack/react-query";
import ApiRequest from "../../../../Shared/Utils/Request";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";

const RealTimeLocatioMap = () => {
  const [originAddress, setOriginAddress] = useState(null);
  const [destinyAddress, setDestinyAddress] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);

  const { token } = useParams();
  const { isLoading } = useDrivers();

  const {
    loadOrder,
    loadOrderRoute,
    loadOrderAddressLoading,
    getOccurrences,
    getLoadOrder,
    getLoadOrderRoutes,
    getLoadOrderAddress,
  } = useTravels();

  useEffect(() => {
    token && getLoadOrder(token);
  }, [getLoadOrder, token]);

  const { isFetching, error, data } = useQuery({
    queryKey: ["repoData"],
    queryFn: () =>
      ApiRequest({
        path: `contra-senha/token/${token}`,
        method: "GET",
      }),
    refetchInterval: 30000,
  });

  useEffect(() => {
    data?.data && getOccurrences(data?.data?.id);
  }, [getLoadOrder, getOccurrences, data?.data]);

  useEffect(() => {
    data?.data && getLoadOrderRoutes(data?.data?.id);
  }, [getLoadOrderRoutes, data?.data, data?.data?.id]);

  const handleOriginAddress = useCallback(async () => {
    if (data?.data?.latitude_coleta && data?.data?.longitude_coleta) {
      setOriginAddress({
        lat: data?.data?.latitude_coleta,
        lng: data?.data?.longitude_coleta,
        desvio_rota: data?.data?.desvio_rota ?? false,
      });
      return;
    }

    if (data?.data?.nome_cidade_coleta) {
      const position = await getLoadOrderAddress(
        `${data?.data?.nome_cidade_coleta},${data?.data?.uf_coleta},Brasil`
      );

      setOriginAddress({
        lat: position.length > 0 ? position[0]?.lat : position?.lat,
        lng: position.length > 0 ? position[0]?.lon : position?.lon,
        desvio_rota: loadOrder?.desvio_rota ?? false,
      });
    }
  }, [
    data?.data?.latitude_coleta,
    data?.data?.longitude_coleta,
    data?.data?.nome_cidade_coleta,
    data?.data?.desvio_rota,
    data?.data?.uf_coleta,
    getLoadOrderAddress,
    loadOrder?.desvio_rota,
  ]);

  useEffect(() => {
    data?.data && handleOriginAddress();
  }, [data?.data, handleOriginAddress]);

  const handleDestinyAddress = useCallback(async () => {
    if (data?.data?.latitude_entrega && data?.data?.longitude_entrega) {
      setDestinyAddress({
        lat: data?.data?.latitude_entrega,
        lng: data?.data?.longitude_entrega,
        desvio_rota: data?.data?.desvio_rota ?? false,
      });
      return;
    }

    if (data?.data?.nome_cidade_entrega) {
      const position = await getLoadOrderAddress(
        `${data?.data?.nome_cidade_entrega},${data?.data?.uf_entrega},Brasil`
      );

      setDestinyAddress({
        lat: position.length > 0 ? position[0]?.lat : position?.lat,
        lng: position.length > 0 ? position[0]?.lon : position?.lon,
        desvio_rota: data?.data?.desvio_rota ?? false,
      });
    }
  }, [
    data?.data?.latitude_entrega,
    data?.data?.longitude_entrega,
    data?.data?.nome_cidade_entrega,
    data?.data?.desvio_rota,
    data?.data?.uf_entrega,
    getLoadOrderAddress,
  ]);

  useEffect(() => {
    data?.data && handleDestinyAddress();
  }, [data?.data, handleDestinyAddress]);

  const findCurrentAddress = useCallback(async () => {
    if (data?.data?.localizacao_atual) {
      const currentLocation = JSON.parse(data?.data?.localizacao_atual);

      return setCurrentAddress({
        lat: currentLocation?.geo_localizacao?.latitude,
        lng: currentLocation?.geo_localizacao?.longitude,
        desvio_rota: data?.data?.desvio_rota ?? false,
      });
    }

    if (loadOrderRoute && loadOrderRoute.length > 0) {
      const currentLocation = loadOrderRoute[0];

      return setCurrentAddress(extractRealTimeData(currentLocation));
    }
  }, [data?.data?.desvio_rota, data?.data?.localizacao_atual, loadOrderRoute]);

  useEffect(() => {
    loadOrder?.id && getLoadOrderRoutes(loadOrder?.id);
    loadOrder && findCurrentAddress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOrder]);

  const routesSegments = useMemo(() => {
    if (loadOrderRoute) {
      return loadOrderRoute.map((objeto) => {
        const { latitude, longitude } = JSON.parse(objeto.geo_localizacao);
        return [latitude, longitude];
      });
    }
  }, [loadOrderRoute]);

  return (
    <>
      {(isLoading ||
        loadOrderAddressLoading ||
        !originAddress ||
        !destinyAddress) && (
        <div className="d-flex align-items-center justify-content-center vh-100 gap-2">
          <Spinner variant="warning" />
          <span className="fst-italic">Calculando rota...</span>
        </div>
      )}
      {!isLoading && data?.data && !loadOrderAddressLoading && !isFetching && (
        <Card className="d-flex min-h-100 w-100 p-3 gap-3">
          {originAddress && destinyAddress && data?.data && !isFetching && (
            <RealTimeTravelMap
              height="85vh"
              origin={originAddress}
              destiny={destinyAddress}
              currentAddress={currentAddress}
              route={loadOrder?.rota_selecionada}
              loadOrder={loadOrder}
              routesSegments={routesSegments}
              show
              showSubtitle
            />
          )}
          {error && <EmptyTableMessage label="Ocorreu um erro no mapa" />}
        </Card>
      )}
    </>
  );
};

export default memo(RealTimeLocatioMap);
