import { Col, Row } from "react-bootstrap";
import Loader from "../Loader/Loader";

export const ListLoader = () => (
  <Row xs={1} md={2} xl={3} className="g-3 mb-3">
    <Col className="d-flex">
      <Loader />
    </Col>
    <Col className="d-flex">
      <Loader />
    </Col>
    <Col className="d-flex">
      <Loader />
    </Col>
  </Row>
);
