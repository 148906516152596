// import { WarningCard } from "../WarningCard/WarningCard";
import { CardContainer, ItemContainer, ItemTitle, ItemValue } from "./styles";

const Item = ({ label, value }) => {
  return (
    <ItemContainer>
      <ItemTitle>{label}:</ItemTitle>
      <ItemValue>{value}</ItemValue>
    </ItemContainer>
  );
};

export const CriminalDetailsCard = ({ item }) => {
  return (
    <CardContainer className="p-2 gap-3 d-flex flex-column">
      <div className="d-lg-flex justify-content-between">
        <Item label="Processo n°" value={item?.numero} />
      </div>
      <div className="d-flex flex-column flex-lg-row gap-3">
        <Item label="Emissão" value={item?.emissao_datahora} />
        <Item label="Data validade" value={item?.validade_data} />
      </div>
      <Item label="Mensagem" value={item?.mensagem} />
      {/* <WarningCard label="Processo requer atenção" /> */}
    </CardContainer>
  );
};
