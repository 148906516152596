import { Row } from "react-bootstrap";
import { HiPlus, HiViewList } from "react-icons/hi";
import styled from "styled-components";

export const VehicleListIcon = styled(HiViewList)`
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 2rem;
`
export const AddVehicleIcon = styled(HiPlus)`
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 2rem;
`
export const VehicleRow = styled(Row)`
max-height: 45vh;
flex: 1;
overflow-x: auto;



 @media(min-width: 425px){
  max-height: 50vh;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }
 

 @media(min-width: 1440px){
  overflow-x: auto;
  max-height: 80vh;
  }

  @media(min-width: 1640px){
  overflow-x: auto;
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  overflow-x: auto;
  max-height: 80vh;
  }
`