import { Card } from "react-bootstrap";
import styled from "styled-components";

export const MainContainer = styled.div`
  max-height: 70vh;
  flex: 1;
  overflow-x: hidden;

  @media (max-width: 576px) {
    max-height: 50vh;
  } 
  @media (min-width: 1200px) {
    max-height: 70vh;
  } 
  @media (min-width: 2000px) {
    max-height: 75vh;
  } 
  @media (min-width: 3000px) {
    max-height: 85vh;
  } 
`

export const Container = styled(Card)`
  background-color: #FFFAFA;
  border: none;


`

export const Title = styled.span`
  font-size: 1.3rem;
  font-weight: bold;
  
  @media(min-width: 992px){
    font-size: 1.7rem;
  }
`

export const ItemLabel = styled.span`
  font-size: 1.1rem;
`

export const InformationsTitle = styled.h1`
  font-weight: bold;
  font-size: 1.3rem;
  color: var(--color-blue-90);
`

export const PartiesTitle = styled.h3`
  font-size: 1rem;
`

export const TypePart = styled.span`
  font-size: 0.875rem;
  color: var(--color-gray-15);
`

export const Border = styled.div`
  border-top: 0.5px dashed var(--color-gray-11);
  padding-top: 16px;
`

export const BorderContainer = styled.div`
background-color: var(--color-gray-2);
padding: 10px;
margin-bottom: 1rem;
`

export const ProcessListContainer = styled.div`
  overflow: auto;
  max-height: 40vh;

 @media(min-width: 425px){
  max-height: 45vh;
 }

 @media(min-width: 576px){
  max-height: 50vh;
 }

 @media(min-width: 1640px){
  max-height: 60vh;
 }


`

export const ProcessesTableTitle = styled.tr`

th {
  font-size: 0.875rem;
  font-weight: bold;
  
}
`
export const TableTitle = styled.th`
font-size: 0.65rem;
color: var(--color-gray-15);
font-weight: 700;
`


export const Thead = styled.thead`
position: sticky;
 top: 0;
 z-index: 1;
 background-color: white;
`

export const TableItem = styled.td`
font-size: 0.75rem;
font-weight: ${props => props.bold ? "bold" : "normal"};

color: ${props => (props?.status === 1 || props?.status === "10r")
    ? "#ff0f0f"
    : props?.status === 2
      ? "#7e0000"
      : "#4d4d4d"}
`;
