import { useCallback } from "react";
import {
  formatNameString,
  sortedFantasyNames,
} from "../../../../Shared/Utils/data";
import Select from "react-select";

const CompanySelectInput = ({
  companies,
  getUnitsByIdCompany,
  setSelectedCompany,
  getUnits,
}) => {
  const handleCompanySelect = useCallback(
    (e) => {
      const companyId = parseInt(e.value);
      if (isNaN(companyId)) {
        getUnits();
      }

      if (!isNaN(companyId)) {
        if (companyId === 0) {
          getUnits();
        } else {
          getUnitsByIdCompany(companyId);
          setSelectedCompany(companyId);
        }
      }
    },
    [getUnits, getUnitsByIdCompany, setSelectedCompany]
  );

  const options = [
    { value: null, label: "-- Todas as empresas --" },
    ...sortedFantasyNames(companies).map((company) => ({
      value: company?.id,
      label: formatNameString(company?.nome_fantasia),
    })),
  ];

  return (
    <Select
      options={options}
      onChange={handleCompanySelect}
      placeholder="-- Selecionar empresa --"
      size="sm"
    />
  );
};

export default CompanySelectInput;
