import { Card } from "react-bootstrap";
import {
  StatisticDriverItem,
  StatisticDriverValue,
} from "../../../Pages/Dashboard/RegistrationManagement/styles";

const Item = ({ content, value }) => {
  return (
    <div>
      <StatisticDriverItem>{content}</StatisticDriverItem>
      <StatisticDriverValue>{value}</StatisticDriverValue>
    </div>
  );
};

const StatisticCard = ({ items, label }) => {
  const actives = items.filter(
    (item) => item?.ativo && item.status !== "9"
  ).length;

  const disabled = items.filter(
    (item) => !item?.ativo && item.status !== "9"
  ).length;

  const inProgress = items.filter((item) => item.status === "9").length;

  return (
    <Card className="h-100">
      <Card.Body>
        <div className="text-center p-1">
          <StatisticDriverItem>Total de {label}</StatisticDriverItem>
          <StatisticDriverValue>{items.length}</StatisticDriverValue>
          <Item content={`Liberados`} value={actives} />
          <Item content={`Desativados`} value={disabled} />
          {(label === "veículos" || label === "proprietários") && (
            <Item content={`Em análise`} value={inProgress} />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default StatisticCard;
