import { Card, Col, Row } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { BsPersonLinesFill } from "react-icons/bs";
import EmptyImage from "../../../../../../Assets/Images/EmptyImage.png";
import {
  BorderCard,
  CardBody,
  CoverImage,
  IconButton,
  IconLink,
} from "./styles";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { StatusCard } from "../../../../../../Shared/Components/StatusCard";

const VehicleCard = ({ vehicle, getVehicle }) => {
  const navigate = useNavigate();

  const handleEditDriverClick = useCallback(async () => {
    await getVehicle(vehicle?.id);

    navigate(`/veiculos-editar/${vehicle.id}`);
  }, [getVehicle, navigate, vehicle.id]);

  return (
    <BorderCard className="d-flex justify-content-center text-center w-100 h-100 card flex-column">
      <CardBody className="gap-1">
        <Row xs={2}>
          <Col xs={3} className="h-100 px-1">
            <CoverImage
              emptyImage={EmptyImage}
              height={0}
              width="100%"
              paddingBottom="100%"
            />
          </Col>
          <Col xs={9} className="px-2">
            <h3 className="text-start mb-0">{vehicle?.placa}</h3>
            <h4 className="text-start mb-2">{vehicle?.marca}</h4>
            {vehicle.status !== "9" && (
              <h5 className="text-start">
                Status:
                <span
                  className={`ms-1 ${
                    vehicle?.bloqueado_adm === "S"
                      ? "color-red"
                      : vehicle?.ativo
                      ? "color-active"
                      : "color-red"
                  }`}
                >
                  {vehicle?.bloqueado_adm === "S"
                    ? "Bloqueado ADM"
                    : vehicle?.ativo
                    ? "Liberado"
                    : "Desativado"}
                </span>
              </h5>
            )}
            {vehicle.status === "9" && <StatusCard label="Em análise" />}
          </Col>
        </Row>
      </CardBody>
      <Card.Body className="mt-auto d-flex justify-content-start gap-1">
        <IconLink
          to={`/veiculos-informacoes/${vehicle.id}`}
          className="d-flex align-items-center"
          title="Informações do veículo"
        >
          <BsPersonLinesFill size={20} color="white" />
        </IconLink>
        <IconButton
          onClick={handleEditDriverClick}
          className="d-flex align-items-center"
          title="Editar o cadastro do veículo"
          disabled={vehicle?.status === "9"}
        >
          <BiEdit size={20} color="white" />
        </IconButton>
      </Card.Body>
    </BorderCard>
  );
};

export default VehicleCard;
