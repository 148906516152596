import styled from "styled-components"
import { Button } from "react-bootstrap"

export const WarningCardBorder = styled.div`
  border: 1px solid red;
  background-color: transparent !important;
`

export const WarningLabel = styled.span`
  color: red;
  font-size: 0.875rem;
`
export const SeeMoreButton = styled(Button)`
border: none;
border-radius: 10px;
color: red;
background-color: transparent;
font-size: 0.75rem;

&:hover{
  border: none;
border-radius: 10px;
color: red;
background-color: transparent;
font-size: 0.75rem;
}

&:active {
    border: none;
    border-radius: 10px;
    color: red;
    background-color: transparent !important;
    font-size: 0.75rem;
  }



`