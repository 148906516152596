import { Card, Form } from "react-bootstrap";
import { SubmitButton } from "../../../../../../Shared/Components/SubmitButton/SubmitButton";
import { BorderCard, PageTitle } from "./styles";
import PersonalInformationsItems from "./PersonalInformationsItems";
import ActiveForm from "./ActiveForm";
import ButtonLink from "../../../../../../Shared/Components/ButtonLink/ButtonLink";

const PersonalInformationForm = ({
  register,
  errors,
  driver,
  setValue,
  isSubmitting,
  cancelRoute,
  driverByCpf,
  driverInfo,
}) => {
  return (
    <BorderCard className="text-center w-100 d-flex flex-column">
      <Card.Body className="text-start">
        <PageTitle className="mb-4">Informações pessoais</PageTitle>
        <PersonalInformationsItems
          register={register}
          errors={errors}
          driver={driver}
          setValue={setValue}
          driverByCpf={driverByCpf}
          driverInfo={driverInfo}
        />
        {driver && (
          <ActiveForm
            driver={driver}
            register={register}
            errors={errors}
            setValue={setValue}
          />
        )}

        <Form.Group className="mt-4">
          {Object.keys(errors).length > 0 && (
            <Form.Text className="text-danger">
              Preencha todos os campos
            </Form.Text>
          )}
          <div className="d-flex gap-3 mt-1">
            <SubmitButton
              dataTestId="driver-form-submit-button"
              labelButton="Salvar"
              fontSize="0.875rem"
              isSubmitting={isSubmitting}
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
            />
            <ButtonLink
              label="Cancelar"
              link={cancelRoute}
              fonstSize="0.875rem"
            />
          </div>
        </Form.Group>
      </Card.Body>
    </BorderCard>
  );
};

export default PersonalInformationForm;
