import { InfoItem } from "./styles";

export const RegistrationStatusCard = ({ status }) => {
  return (
    <div className="d-flex gap-1 mb-3 justify-content-between p-2">
      <InfoItem>Situação do cadastro:</InfoItem>
      <InfoItem
        status={status ? status.toUpperCase() : "Requer atenção"}
        className="fw-bold"
      >
        {status ?? "Requer atenção"}
      </InfoItem>
    </div>
  );
};
