import { Link } from "react-router-dom";
import styled from "styled-components";

export const ListContainer = styled.div`
  overflow: auto;
  max-height: 50vh;

  @media (min-width: 1440px) {
    max-height: 57vh;
  }

  @media (min-width: 2440px) {
    max-height: 65vh;
  }
`;

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
`;

export const TableTitle = styled.tr`
  th {
    font-size: 0.875rem;
    font-weight: bold;
  }
`;

export const Title = styled.th`
  font-size: 0.65rem;
  color: var(--color-gray-15);
  font-weight: 700;

  @media (min-width: 1440px) {
    font-size: 1rem !important;
  }
`;

export const SeeMoreButton = styled(Link)`
  background-color: var(--color-blue-80);
  text-decoration: none;
  padding: 3px;
  border: 1px solid var(--color-blue-80);
  border-radius: 4px;

  span {
    color: white;
    font-size: 0.7rem;
    font-weight: bold;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--color-blue-80);
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;

  cursor: pointer;
`;

export const FilterContent = styled.div`
  transition: all 0.5s ease;
`;

export const Circle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ status }) =>
    status === "2" ? "green" : status === "6" ? "dodgerblue" : "red"};
  display: inline-block;
  margin-right: 5px;
`;

export const WhatsAppIconButton = styled.button`
  background-color: #25d366;
  border: 1px solid #25d366;
  color: white;
  border-radius: 4px;
  font-weight: bold;
`;
