import Dashboard from "../../../Shared/layout";
import { memo, useCallback, useEffect, useState } from "react";

import { HiOutlineArrowsExpand } from "react-icons/hi";
import { MainDetailsCard } from "./components/MainDetailsCard";
import ButtonLink from "../../../Shared/Components/ButtonLink/ButtonLink";
import { MainDetailsContainer } from "./styles";
import { useTravels } from "../../../hooks/TravelProvider";
import { centerPositions } from "../../../Shared/Utils/constants";
import { ListHomeCardLoader } from "../../../Shared/Components/ListHomeCardLoader";
import { TravelsListMap } from "../../../Shared/Components/LeafletMaps/TravelsListMap";

const Home = () => {
  const [currentActivesPositions, setCurrentActivePositions] = useState([]);

  const {
    incompleteLoadOrders,
    activeLoadOrder,
    activeLoadOrderLoading,
    offRoute,
    getIncompleteLoadOrders,
    getActivesLoadOrder,
    getLoadOrderAddress,
    getOffRouteLoadOrders,
  } = useTravels();

  useEffect(() => {
    getActivesLoadOrder();
    getIncompleteLoadOrders();
    getOffRouteLoadOrders();
  }, [getActivesLoadOrder, getIncompleteLoadOrders, getOffRouteLoadOrders]);

  const findActivesPositions = useCallback(async () => {
    const positionsPromises = activeLoadOrder.map(async (loadOrder) => {
      if (loadOrder?.localizacao_atual === null) {
        if (loadOrder?.latitude_coleta && loadOrder?.longitude_coleta) {
          return {
            lat: loadOrder?.latitude_coleta,
            lng: loadOrder?.longitude_coleta,
            desvio_rota: loadOrder?.desvio_rota ?? false,
          };
        }
        const position = await getLoadOrderAddress(
          `${loadOrder?.nome_cidade_coleta},${loadOrder?.uf_coleta},Brasil`
        );

        return {
          lat: position.length > 0 ? position[0]?.lat : position?.lat,
          lng: position.length > 0 ? position[0]?.lon : position?.lon,
          desvio_rota: loadOrder?.desvio_rota ?? false,
        };
      } else {
        const position = JSON.parse(loadOrder?.localizacao_atual);
        return {
          lat: position?.geo_localizacao?.latitude,
          lng: position?.geo_localizacao?.longitude,
          desvio_rota: loadOrder?.desvio_rota ?? false,
        };
      }
    });

    const resolvedPositions = await Promise.all(positionsPromises);

    setCurrentActivePositions(resolvedPositions);
  }, [activeLoadOrder, getLoadOrderAddress]);

  useEffect(() => {
    findActivesPositions();
  }, [findActivesPositions, activeLoadOrder]);

  return (
    <Dashboard title="Monitoramento dos veículos">
      {activeLoadOrderLoading && <ListHomeCardLoader />}

      <div className="text-center w-100 h-100 d-flex flex-column">
        {!activeLoadOrderLoading && (
          <div className="d-lg-flex justify-content-end justify-content-md-between gap-1">
            <MainDetailsContainer className="d-none d-lg-flex flex-column flex-lg-row  justify-content-start gap-1">
              <MainDetailsCard
                title="OCs em curso"
                value={activeLoadOrder ? activeLoadOrder.length : 0}
                route="/gestao-viagens/viagens"
              />
              <MainDetailsCard
                title="OCs incompletas"
                value={incompleteLoadOrders ? incompleteLoadOrders.length : 0}
                route="/gestao-viagens/ordem-de-carregamento"
              />
              <MainDetailsCard
                title="OCs fora de rotas"
                value={offRoute ? offRoute.length : 0}
                route="/gestao-viagens/ordem-de-carregamento"
              />
            </MainDetailsContainer>
            <div className="d-flex justify-content-end align-items-center">
              <ButtonLink
                link="/gestao-viagens/viagens"
                icon={<HiOutlineArrowsExpand size={20} />}
                title="Detalhar viagens"
              />
            </div>
          </div>
        )}

        <TravelsListMap
          height="97%"
          zoom={4}
          positions={currentActivesPositions}
          centerPositions={centerPositions}
        />
      </div>
    </Dashboard>
  );
};

export default memo(Home);
