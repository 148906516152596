import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tab } from "react-bootstrap";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

import { BorderCard, Column, DriverRow, StyledTabs } from "./styles";

import ControlDriverCard from "../components/ControlDriverCard/ControlDriverCard";

import Dashboard from "../../../../../Shared/layout";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";

import { useDrivers } from "../../../../../hooks/DriverProvider";
import { useDriversValidation } from "../../../../../hooks/DriverValidationProvider";
import { RegistrationInformationAndValidationsDriverTab } from "./Tabs/Information/RegistrationInformationAndValidationsDriverTab";

import { DriverInformationsTab } from "./Tabs/Driver/DriverInformationsTab";
import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";
import { VehicleInformationsTab } from "./Tabs/Vehicle/VehicleInformationsTab";
import { HistoryCard } from "../../../../../Shared/Components/HistoryCard/HistoryCard";
import { InfosSat } from "../../../../../Shared/Components/InfosSat/InfosSat";

const Driver = () => {
  const [tab, setTab] = useState("info");
  const [defaultActiveKey, setDefaultActiveKey] = useState("0");
  const [loadingCnhValidation, setLoadingCnhValidation] = useState(false);
  const [loadingBiometricValidation, setLoadingBiometricValidation] =
    useState(false);

  const { id } = useParams();

  const {
    driver,
    driverSat,
    isLoading,
    driverConsults,
    loadingSatDriver,
    loadingFederalInformations,
    getDriver,
    getDriverSat,
    getTempRegister,
    getDriverConsults,
  } = useDrivers();

  const {
    driverValidation,
    loadingDriverValidation,
    judicialProcess,
    judicialProcessLoading,
    postCnhValidation,
    getDriverValidation,
    postBiometricValidation,
    postJudicialProcessConsult,
    getJudicialProcessConsult,
  } = useDriversValidation();

  useEffect(() => {
    getDriverValidation(id);
    getDriverConsults(undefined, id);
    getJudicialProcessConsult(id);
  }, [id, getDriverValidation, getDriverConsults, getJudicialProcessConsult]);

  const handleOpenVehicleTab = (index) => {
    setTab("vehicle");
    setDefaultActiveKey(index);
  };

  useEffect(() => {
    getDriver(id);
    getDriverSat(id);
  }, [getDriver, getDriverSat, getTempRegister, id]);

  const getLoadingValidationCNH = useCallback(async () => {
    setLoadingCnhValidation(true);
    const response = await getDriverValidation(id);

    const status = response?.validacao_cnh?.concluido;

    if (status === true) {
      setLoadingCnhValidation(false);
    }
  }, [getDriverValidation, id]);

  const getLoadingBiometric = useCallback(async () => {
    setLoadingBiometricValidation(true);
    const response = await getDriverValidation(id);

    const status = response?.validacao_biometria?.concluido;

    if (status === true) {
      setLoadingBiometricValidation(false);
    }
  }, [getDriverValidation, id]);

  const handleBiometricValidation = useCallback(async () => {
    Swal.fire({
      icon: "info",
      title: "Informação!",
      text: "Aguardando validação da biometria. Pode demorar alguns minutos!",
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      showClass: {
        popup: "swal2-noanimation",
        backdrop: "swal2-noanimation",
      },
      hideClass: {
        popup: "",
        backdrop: "",
      },
    });
    getLoadingBiometric();

    await postBiometricValidation(driver?.id);
    setLoadingBiometricValidation(false);
  }, [driver?.id, getLoadingBiometric, postBiometricValidation]);

  const handleNewProcessConsult = useCallback(async () => {
    Swal.fire({
      icon: "info",
      title: "Informação!",
      text: "Realizando consulta judicial",
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      showClass: {
        popup: "swal2-noanimation",
        backdrop: "swal2-noanimation",
      },
      hideClass: {
        popup: "",
        backdrop: "",
      },
    });

    await postJudicialProcessConsult(driver?.id);
    await getJudicialProcessConsult(id);
  }, [driver?.id, getJudicialProcessConsult, id, postJudicialProcessConsult]);

  useEffect(() => {
    if (driverValidation && judicialProcess?.concluido === false) {
      const interval = setInterval(() => {
        getJudicialProcessConsult(id);
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [judicialProcess, driverValidation, getJudicialProcessConsult, id]);

  const handleCnhValidation = useCallback(async () => {
    Swal.fire({
      icon: "info",
      title: "Informação!",
      text: "Aguardando validação da CNH. Pode demorar alguns minutos!",
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      showClass: {
        popup: "swal2-noanimation",
        backdrop: "swal2-noanimation",
      },
      hideClass: {
        popup: "",
        backdrop: "",
      },
    });

    getLoadingValidationCNH();

    await postCnhValidation(driver?.id);
    setLoadingCnhValidation(false);
  }, [driver?.id, postCnhValidation, getLoadingValidationCNH]);

  useEffect(() => {
    if (
      driverValidation &&
      driverValidation?.validacao_cnh?.concluido === false
    ) {
      const interval = setInterval(() => {
        getLoadingValidationCNH();
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    driverValidation,
    driverValidation?.validacao_cnh?.concluido,
    getLoadingValidationCNH,
    handleCnhValidation,
  ]);

  useEffect(() => {
    if (
      driverValidation &&
      driverValidation?.validacao_biometria?.concluido === false
    ) {
      const interval = setInterval(() => {
        getLoadingBiometric();
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    driverValidation,
    driverValidation?.validacao_biometria?.concluido,
    getLoadingBiometric,
    handleBiometricValidation,
  ]);

  return (
    <Dashboard>
      {(isLoading || loadingSatDriver) && <Loading />}

      {!isLoading && !loadingSatDriver && driver && (
        <DriverRow
          xs={1}
          md={2}
          xl={3}
          className="d-flex justify-content-between g-2 mt-1"
        >
          <Column md={4} xl={2}>
            <ControlDriverCard
              driver={driver}
              showValidationButtons={true}
              handleCnhValidation={handleCnhValidation}
              handleBiometricValidation={handleBiometricValidation}
              showActionSelect={true}
              handleNewProcessConsult={handleNewProcessConsult}
              showConsultBtn={true}
              inProcess={loadingCnhValidation || loadingBiometricValidation}
            />
          </Column>

          <Column md={8} xl={10}>
            <BorderCard className="w-100 card d-flex flex-column p-3">
              <StyledTabs activeKey={tab} onSelect={(key) => setTab(key)}>
                <Tab eventKey="info" title="Informações">
                  <RegistrationInformationAndValidationsDriverTab
                    driver={driver}
                    handleOpenVehicleTab={handleOpenVehicleTab}
                    driverConsults={driverConsults}
                    driverValidation={driverValidation}
                    loadingCnhValidation={loadingCnhValidation}
                    loadingDriverValidation={loadingDriverValidation}
                    loadingBiometricValidation={loadingBiometricValidation}
                    loadingFederalInformations={loadingFederalInformations}
                    judicialProcess={judicialProcess}
                    judicialProcessLoading={judicialProcessLoading}
                  />
                  <div className="px-3 mt-3 gap-3 d-flex flex-column">
                    <InfosSat driver={driver} driverSat={driverSat} />
                    <HistoryCard driver={driver} />
                  </div>
                </Tab>
                <Tab eventKey="driver" title="Motorista">
                  <DriverInformationsTab
                    driver={driver}
                    isLoading={isLoading}
                    driverConsults={driverConsults}
                  />
                </Tab>
                <Tab eventKey="vehicle" title="Veículo">
                  {driver?.veiculo ? (
                    <VehicleInformationsTab
                      vehicle={driver?.veiculo}
                      defaultActiveKey={defaultActiveKey}
                      setDefaultActiveKey={setDefaultActiveKey}
                      driverConsults={driverConsults}
                    />
                  ) : (
                    <div className="mt-5">
                      <EmptyTableMessage label="Não há veículo cadastrado" />
                    </div>
                  )}
                </Tab>
              </StyledTabs>
            </BorderCard>
          </Column>
        </DriverRow>
      )}
    </Dashboard>
  );
};

export default memo(Driver);
