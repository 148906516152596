import { Col } from "react-bootstrap";
import Dashboard from "../../../Shared/layout";
import LinkCard from "../../../Shared/Components/LinkCard";
import { memo } from "react";
import {
  DriverIcon,
  OwnerIcon,
  RegistrationManagementRow,
  VehicleIcon,
} from "./styles";

const list = [
  {
    route: "/motoristas",
    title: "Motoristas",
    icon: <DriverIcon />,
  },
  { route: "/proprietarios", title: "Proprietários", icon: <OwnerIcon /> },
  {
    route: "/veiculos",
    title: "Veículos",
    icon: <VehicleIcon />,
  },
];

const RegistrationManagement = () => {
  return (
    <Dashboard title="Gestão de cadastros">
      <RegistrationManagementRow
        xs={1}
        md={2}
        lg={4}
        xl={6}
        id="container-cards-conf-sistema"
        className="d-flex mt-4 g-3"
      >
        {list.map((item) => (
          <Col key={item.route}>
            <LinkCard route={item.route} title={item.title} icon={item.icon} />
          </Col>
        ))}
      </RegistrationManagementRow>
    </Dashboard>
  );
};

export default memo(RegistrationManagement);
