import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const BorderCard = styled(Card)`


  h3 {
    font-size: 1rem;
    font-weight: normal;
  }

  h4 {
    font-size: 0.7rem;
    color: var(--color-gray-11);
  }

  h5 {
    font-size: 0.7rem;
    color: var(--color-gray-12);

    span {
      font-size: 0.7rem;
    }
  }

  h6 {
    font-size: 0.7rem;
    color: var(--color-gray-11);

    span {
      font-size: 0.7rem;
    }
  }
`;

export const CardBody = styled(Card.Body)`
  border-bottom: 1px solid var(--color-gray-10);
`;

export const CoverImage = styled.div`
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  background-image: ${(props) =>
    props?.currentDriverImage
      ? `url(${props?.currentDriverImage})`
      : `url(${props.driverImage})`};
  background-size: cover;
  background-position: center center;
  border-radius: 1000px;
`;

export const CoverLogo = styled.div`
  background-size: cover;
  background-position: center center;
`;

export const IconLink = styled(Link)`
  background-color: var(--color-blue-70);
  border-radius: 3px;
  padding: 3px;
  transition: transform 300ms;
  &:hover {
    transform: scale(1.1);
    z-index: 1;
  }
`;

export const IconButton = styled(Button)`
  background-color: var(--color-blue-70);
  border-radius: 3px;
  padding: 1px;
  transition: transform 300ms;
  &:hover {
    background-color: var(--color-blue-70);
    transform: scale(1.1);
    z-index: 1;
  }
`;

export const StatisticDriverItem = styled.h3`
  color: var(--color-blue-80);
  font-size: 1rem;
`;

export const StatisticDriverValue = styled.h1`
  color: var(--color-gray-11);
  font-size: 2rem;
`;

export const PageTitle = styled(Card.Title)`
  color: var(--color-gray-12);
  font-weight: normal;
  font-size: 1rem;
`;

export const FormLabel = styled(Form.Label)`
  color: var(--color-gray-15);
  font-weight: normal;
  font-size: 0.7rem;
`;
