import styled from "styled-components";

import { Link } from "react-router-dom";

export const LinkButton = styled(Link)`
text-decoration: none;
color: ${({ disabled }) => (disabled ? '#999999' : '#3A3A3A')};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

span {
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.5px;
  color: ${({ disabled }) => (disabled ? '#999999' : '#3A3A3A')};
}
`

export const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  padding: 0 7px; 
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  
  span{
    color: ${({ disabled }) => (disabled ? '#999999' : "#3A3A3A")};
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    /* white-space: nowrap;  */
  }


  &::before {
    content: "";
    position: absolute;
    top: 15%;
    bottom: 15%;
    right: 0;
    width: 1px; 
    background-color: #b0b0b0; 
    display: ${({ disabled }) => (disabled ? 'none' : 'block')};
  }
`

export const Title = styled.h1`
font-weight: 400;
font-size: 1.5rem;
line-height: 40px;
font-family: 'Poppins';
font-style: normal;
color: '#093C5E' !important;
margin-bottom: 0 !important;
`

export const ComeBackButton = styled.span`
font-size: 0.7rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.5px;
  color: '#3A3A3A';`