import { Row } from "react-bootstrap";
import styled from "styled-components";

export const DriverListRow = styled(Row)`
 max-height: 30vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 425px){
  max-height: 60vh;
 }
 @media(min-width: 992px){
  max-height: 65vh;
 }

  @media(min-width: 1640px){
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  max-height: 80vh;
  }
  
`
export const DriverCardContainer = styled(Row)`
 @media(min-width: 768px){
  max-height: 45vh;
  overflow-x: hidden;
 }

 @media(min-width: 992px){
  max-height: 45vh;
 }

  @media(min-width: 1640px){
  max-height: 60vh;
  }

  @media(min-width: 2000px){
  max-height: 65vh;
  }

  @media(min-width: 2440px){
  max-height: 70vh;
  }
  
`
export const LoaderContainer = styled.div`
height: 40px;
 width: 150px;
`



