import ButtonLink from "../ButtonLink/ButtonLink";
import { Container, SubtitleIcon, Title } from "./styles";
import { IoWarningOutline } from "react-icons/io5";

export const ErrorMessageCard = ({
  message,
  handleNewConsult,
  consult,
  title,
}) => {
  return (
    <div>
      <Title className="mb-3">
        {title ?? "Consulta CNPJ / CPF Proprietário"}
      </Title>
      <Container>
        <div className="d-flex gap-2 align-items-center justify-content-center">
          <IoWarningOutline size={22} color="red" />
          <SubtitleIcon>Erro: {message}</SubtitleIcon>
        </div>
      </Container>
      <div className="d-flex justify-content-center mt-3">
        <ButtonLink
          label="Realizar nova consulta"
          buttoncolor="primary"
          handleClick={() => handleNewConsult(consult?.posicao)}
        />
      </div>
    </div>
  );
};
