import { Col, Row } from "react-bootstrap";
import {
  BorderBotton,
  Item,
  // MarkAsInconsistentButton,
  SatContainer,
  SatItem,
} from "./styles";
import { MarkAsInconsistent } from "./MarkAsInconsistent";
// import moment from "moment";
// import { FaRedo } from "react-icons/fa";
import { useCallback, useMemo, useState } from "react";

export const DriverInformationComparisonCard = ({
  label,
  driverItem,
  satItem,
  driver,
  item,
  onUpdateDriverData,
  register,
  handleInputChange,
  setValue,
  errors,
  setHasInconsistencies,
}) => {
  const [inconsistencyMessage, setInconsistencyMessage] = useState(false);

  const handleReportInconsistency = useCallback(() => {
    setInconsistencyMessage(true);
    setHasInconsistencies(true);
    handleInputChange(item);
  }, [handleInputChange, item, setHasInconsistencies]);

  const handleCancel = useCallback(() => {
    setInconsistencyMessage(false);
    handleInputChange(item);
  }, [handleInputChange, item]);

  const inconsistencies = useMemo(() => {
    if (driver?.campos_erros_cadastro) {
      return JSON.parse(driver?.campos_erros_cadastro);
    } else {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver?.campos_erros_cadastro, driver?.id]);

  return (
    <Col>
      <Row className="g-1">
        <Col xl={4} className="d-flex align-items-center">
          <Item>
            <span>{label} </span> {driverItem ? driverItem : "--"}
          </Item>
        </Col>
        <Col xl={4}>
          <div className="d-flex gap-1">
            <SatContainer className="d-flex gap-1 align-items-center justify-content-start">
              <SatItem sat>SAT:</SatItem>
              <SatItem info={satItem === driver?.nome_pai}>
                {satItem ? satItem : "--"}
              </SatItem>
            </SatContainer>
          </div>
        </Col>
        <Col xl={4} className="d-flex align-items-center gap-1">
          {/* {driverItem !== satItem &&
            !inconsistencyMessage &&
            !inconsistencies[item] &&
            driver?.status_cadastro !== "7r" && (
              <MarkAsInconsistentButton
                title="Editar"
                onClick={handleMarkAsInconsistentClick}
              >
                <FaRedo />
              </MarkAsInconsistentButton>
            )} */}
          <MarkAsInconsistent
            driver={driver}
            handleReportInconsistency={handleReportInconsistency}
            inconsistencyMessage={inconsistencyMessage}
            handleCancel={handleCancel}
            item={item}
            register={register}
            inconsistencyValue={inconsistencies[item]}
            errors={errors}
          />
        </Col>
        <BorderBotton className="d-xl-none" />
      </Row>
    </Col>
  );
};
