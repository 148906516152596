import { Form, InputGroup, Ratio, Spinner } from "react-bootstrap";
import {
  AlertIcon,
  CoverImage,
  DeleteCnhButton,
  DeleteIconButton,
  ErrorMessage,
  FormControlInput,
  FormGroupBorder,
  ImageTitle,
  PdfLoadingContainer,
  ShowModalButton,
  UploadImageButton,
} from "./styles";

import { useCallback, useMemo, useState } from "react";
import { pdfConverter } from "../../../../../../../Shared/Utils/pdfConverter";
import EmptyImage from "../../../../../../../Assets/Images/EmptyImage.png";
import { MdOutlineFilterCenterFocus } from "react-icons/md";

const UploadOwnerVehicleDocImage = ({
  errors,
  vehicle,
  className,
  width,
  height,
  paddingBottom,
  setValue,
  hideTitle,
  hideInput,
  handleShowModal,
  align,
  register,
  isEdit,
}) => {
  const [uploadImage, setUploadImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [imageModal, setImageModal] = useState(null);

  const [uploadLogoImage, setUploadLogoImage] = useState(
    vehicle?.documentos_arrendamento ?? vehicle?.foto_documento
  );

  const [isChange, setIsChange] = useState(false);

  const deleteImage = () => {
    setUploadImage(null);
    setUploadLogoImage(null);
  };

  const handleFileChange = useCallback(
    (event) => {
      setErrorMessage("");
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const arrayBuffer = e.target.result;

          const base64 = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );

          setUploadImage(file);

          if (file.type === "application/pdf") {
            setValue(
              "documentos_arrendamento",
              "data:application/pdf;base64," + base64
            );

            pdfConverter(file, setUploadLogoImage, setIsLoading);
          } else if (file.type === "image/jpeg" || file.type === "image/png") {
            setUploadLogoImage(URL.createObjectURL(file));

            setValue(
              "documentos_arrendamento",
              "data:" + file.type + ";base64," + base64
            );
          } else {
            setErrorMessage("Arquivo não suportado");
          }
        };

        setIsChange(true);

        reader.readAsArrayBuffer(file);
      }
    },
    [setValue]
  );

  const coverImage = useMemo(() => {
    if (uploadLogoImage && !isChange) {
      setImageModal(uploadLogoImage);
      return uploadLogoImage;
    }

    if (uploadLogoImage && isChange) {
      setIsChange(false);
      setImageModal(uploadLogoImage);
      return uploadLogoImage;
    }

    if (!vehicle?.documentos_arrendamento?.url && !uploadLogoImage) {
      setIsChange(false);
      setImageModal(EmptyImage);

      return EmptyImage;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle?.documentos_arrendamento, uploadLogoImage]);

  return (
    <div className="d-flex h-100">
      <FormGroupBorder
        className={`d-flex flex-column ${className} ${
          uploadImage && "justify-content-start gap-3"
        }`}
      >
        {!hideTitle && (
          <ImageTitle className="text-start mb-1">
            Documento arrendatário
          </ImageTitle>
        )}
        {isLoading ? (
          <PdfLoadingContainer width={width} height={height}>
            <Spinner animation="border" role="status" variant="light" />
          </PdfLoadingContainer>
        ) : (
          <>
            {coverImage?.type === "application/pdf" ? (
              <div style={{ width: 166, height: 166 }}>
                <Ratio aspectRatio="1x1">
                  <embed type="application/pdf" src={coverImage?.url} />
                </Ratio>
              </div>
            ) : (
              <CoverImage
                width={width}
                height={height}
                paddingBottom={paddingBottom}
                image={coverImage?.url ?? coverImage}
              />
            )}
          </>
        )}
        {handleShowModal && coverImage !== EmptyImage && (
          <div className={`mt-auto ${align ? "text-start" : "text-end"}`}>
            <ShowModalButton
              onClick={() => handleShowModal(imageModal)}
              className="px-0"
            >
              <div className="d-flex align-items-center gap-1">
                <MdOutlineFilterCenterFocus />
                Visualizar documento
              </div>
            </ShowModalButton>
          </div>
        )}
        {errorMessage === "Arquivo não suportado" && (
          <div className="d-flex align-items-center gap-1 mt-1">
            <AlertIcon size={10} />
            <ErrorMessage>Arquivo não suportado</ErrorMessage>
          </div>
        )}
        {uploadImage && (
          <div className="d-flex align-items-center">
            <DeleteCnhButton
              type="button"
              className="d-flex align-items-center p-0 gap-1"
              onClick={deleteImage}
            >
              <label className="mt-2">{uploadImage?.name}</label>
              <DeleteIconButton />
            </DeleteCnhButton>
          </div>
        )}
        {!uploadImage && !hideInput && (
          <InputGroup className=" mt-1">
            <UploadImageButton
              className={`rounded d-flex justify-content-center align-items-center ${
                vehicle && !isEdit && "d-none"
              }`}
              width={width}
            >
              <label
                htmlFor="documentos_arrendamento"
                className="custom-file-upload"
              >
                Selecionar documento
              </label>
            </UploadImageButton>
            <FormControlInput
              {...register("documentos_arrendamento", {
                required: vehicle ? false : "A foto da CNH é obrigatória",
              })}
              id="documentos_arrendamento"
              type="file"
              disabled={vehicle && !isEdit}
              onChange={handleFileChange}
              className="d-none"
            />
          </InputGroup>
        )}
        {errors?.documentos_arrendamento?.type === "required" && (
          <Form.Text className="text-danger">
            O documento é obrigatório
          </Form.Text>
        )}
      </FormGroupBorder>
    </div>
  );
};

export default UploadOwnerVehicleDocImage;
