
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";



export const Item = styled.div`
padding: 0;
margin-bottom: 1rem;
display: flex;
align-items: center;

h4 {
  font-size: 1rem;
  color: black;
  display: flex;
  margin-left: 0.2rem;
  margin-bottom:0;
  text-align: left;
    color: var(--color-gray-15);
}

h3 {
  font-size: 1rem;
  display: flex;
  margin-left: 0.3rem;
  margin-bottom:0;
  color: #093c5e;
  text-align: left;
}
`

export const Cover = styled.div`
  background-size: cover;
  background-position: center center;
  width: 100%;
  padding-bottom: 100%;
  height: 100px;
`;

export const ContainerWrapper = styled.div`
position: relative;
`;

export const InputWrapper = styled.div`
  flex: 1;
`;


export const DeleteText = styled.span`
color: var(--color-gray-11);
position: relative;
display: block;
width: 100%;
height: 100%;
font-size: 0.7rem;
`;

export const DeleteButton = styled(Button)`
background-color: transparent;
border: none;
position: absolute;
top: -17px;
right: 0px;
width: 60px;
height: 20px;
background-color: transparent;
border: none;
cursor: pointer;

&:hover {
  background-color: transparent;
  border: none;
  transform: scale(1.1);
  z-index: 1;
}
`


export const CancelButton = styled(Button)`
transition: transform 300ms;
background-color: var(--color-gray-11);
font-size: 0.9rem;
border: none;

&:hover {
  background-color: var(--color-gray-11);
  transform: scale(1.1);
  z-index: 1;
}
`
export const Container = styled.div`
  position: relative;
`

export const BorderCard = styled(Card)`
 min-height: 100%;

  h5{
    font-size: 1rem;
    color:var(--color-gray-11);
    margin-bottom: 0;

  }

  span {
      font-size: 1rem;
      color: var(--color-gray-15);
      font-weight: bold;
    }

`

export const PageTitle = styled(Card.Title)`
color: var(--color-gray-12);
font-weight: normal;
font-size: 1rem;
`

export const VehicleItem = styled.div`
border-bottom: ridge 0.1px var(--color-gray-1);
padding-top: 1rem;
padding-bottom: 1rem;


&:last-child {
    border-bottom: none;
  }
`


export const ReportedInconsistencyContainer = styled.div`
  overflow-x: hidden;
  max-height: 60vh;
`
export const RegisterItemForm = styled(Form.Control)`
 height: 50px;
 width: 100%;
 font-size: 0.8rem;
 border: 0.5px solid var(--color-gray-11);
 padding: 0.5rem;
 `

export const ChangingItemForm = styled(Form.Control)`
 height: 50px;
 `

export const VehiclesAssociatedWithTheDriverRow = styled(Row)`
overflow-x: hidden;
max-height: 95vh;
`
export const LabelTitle = styled(Form.Label)`
  font-size: 0.8rem;
  color: var(--color-gray-11);
`

export const Column = styled(Col)`

  @media(min-width: 992px){
    overflow-x: hidden;
  max-height: 70vh;
  }

  @media(min-width: 2400px){
  max-height: 73vh;
  }
`

export const DriversInformationsRow = styled(Row)`
max-height: 50vh;
flex: 1;
overflow-x: hidden;

@media(min-width: 576px){
  max-height: 70vh;
}
@media(min-width: 992px){
  max-height: 80vh;
}
 
`

export const ItalicH4 = styled.h4`
  font-style: ${({ notIncluded }) => notIncluded && "italic"};
`;

export const SectionContainer = styled(Card)`
background-color: #FFFAFA;
  border: none;
`

export const TitleContainer = styled.div`
  background-color: var(--color-gray-2);
  padding: 10px;
`

export const InfoTitle = styled.span`
  color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;