import { Ratio } from "react-bootstrap";
import { BorderForm, CoverImage, ImageTitle, ShowModalButton } from "./styles";
import { MdOutlineFilterCenterFocus } from "react-icons/md";

const UploadCnhImageInput = ({ driverDocImage, handleShowModal }) => {
  return (
    <BorderForm>
      <ImageTitle className="text-start mb-1">Foto CNH</ImageTitle>

      {driverDocImage && (
        <>
          {driverDocImage.startsWith("data:application/pdf") ? (
            <div style={{ width: 166, height: "0", paddingBottom: "100%" }}>
              <Ratio aspectRatio="1x1">
                <embed type="application/pdf" src={driverDocImage} />
              </Ratio>
            </div>
          ) : (
            <CoverImage image={driverDocImage} />
          )}
        </>
      )}
      <div className="align-text-start mt-auto">
        <ShowModalButton onClick={handleShowModal} className="px-0">
          <div className="d-flex align-items-center gap-1">
            <MdOutlineFilterCenterFocus />
            Visualizar CNH
          </div>
        </ShowModalButton>
      </div>
    </BorderForm>
  );
};

export default UploadCnhImageInput;
