import moment from "moment";
import { IoIosArrowForward } from "react-icons/io";

import {
  CardContainer,
  InfomationItem,
  PageTitle,
  TitleContainer,
} from "./styles";
import ButtonLink from "../ButtonLink/ButtonLink";
import { AlertMessage } from "../AlertMessage";
import { StatusSat } from "./StatusSat";

export const InfosSat = ({ driver, driverSat }) => {
  return (
    <CardContainer className=" w-100 card d-flex flex-column p-1">
      <TitleContainer>
        <PageTitle className="d-flex justify-content-start mb-0">
          INFORMAÇÕES SAT
        </PageTitle>
      </TitleContainer>
      {driverSat && Object.keys(driverSat).length > 0 && (
        <div className="mb-3">
          <div className="p-2 d-flex flex-column gap-2">
            <StatusSat driverSat={driverSat} />
            <div className="d-flex gap-1">
              <InfomationItem>Data da criação / atualização:</InfomationItem>
              <InfomationItem className="fw-bold">
                {driverSat?.dataatual
                  ? moment.utc(driverSat?.dataatual).format("DD/MM/YYYY")
                  : "--"}
              </InfomationItem>
            </div>
            <div className="d-flex justify-content-end  mb-2">
              <ButtonLink
                id="more-details"
                label="Detalhes"
                link={`/motoristas-listar/informacoes-sat/${driver?.id}`}
                fontSize="0.7rem"
                secondIcon={<IoIosArrowForward />}
              />
            </div>
          </div>
        </div>
      )}

      {driverSat && Object.keys(driverSat).length === 0 && (
        <div className="mt-2">
          <AlertMessage
            label="Motorista não cadastrado no SAT"
            backgroundColor="#f6f6f6"
            color="#093c5e"
          />
        </div>
      )}
    </CardContainer>
  );
};
