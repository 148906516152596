import { BiIdCard } from "react-icons/bi";
import { MdInvertColors } from "react-icons/md";
import { FaCity } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import UploadOwnerDocImage from "./UploadOwnerDocImage";
import { FiGitCommit, FiTruck } from "react-icons/fi";
import { BsArrowRepeat, BsCalendar3, BsPerson } from "react-icons/bs";
import { useOwners } from "../../../../../../hooks/OwnerProvider";
import { cnpjCpfFormat } from "../../../../../../Shared/Utils/data";
import { Container, InfoTitle, Item, ItemBorderCard } from "./styles";

const VehicleInformationsCard = ({ vehicle, handleShowModal }) => {
  const [tenantOwner, setTenantOwner] = useState();
  const [ownerInformations, setOwnerInformations] = useState();

  const { getOwner } = useOwners();

  const getVehicleOwners = useCallback(async () => {
    if (vehicle?.proprietario_id) {
      const response = await getOwner(vehicle?.proprietario_id);
      setTenantOwner(response);
    }
    if (vehicle?.proprietario_arrendatario_id) {
      const response = await getOwner(vehicle?.proprietario_arrendatario_id);
      setOwnerInformations(response);
    }
  }, [
    getOwner,
    vehicle?.proprietario_id,
    vehicle?.proprietario_arrendatario_id,
  ]);

  useEffect(() => {
    getVehicleOwners();
  }, [getVehicleOwners]);

  const isExpired =
    vehicle?.dt_vencimento_documento &&
    new Date(vehicle?.dt_vencimento_documento) < new Date();

  return (
    <ItemBorderCard>
      <Container className="mb-3 d-flex">
        <InfoTitle className="text-start">INFORMAÇÕES</InfoTitle>
      </Container>
      <Row xs={1} xl={2} className="p-2">
        <Col className="gap-3  h-100 d-flex flex-column gap-1">
          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>Placa:</h3>
            <h4>{vehicle?.placa ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>Renavam:</h3>
            <h4>{vehicle?.renavam ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>Chassi:</h3>
            <h4>{vehicle?.chassi ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <FiGitCommit size={15} color="#093c5e" />
            <h3>Quantidade de eixos:</h3>
            <h4>{vehicle?.qtd_eixo ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <FaCity size={15} color="#093c5e" />
            <h3 titlestatus="true">Estado de emplacamento:</h3>
            <h4>{vehicle?.estado_emplacamento ?? "--"}</h4>
          </Item>
          <Item className="gap-1">
            <FaCity size={15} color="#093c5e" />
            <h3 titlestatus="true">Cidade de emplacamento:</h3>
            <h4>{vehicle?.cidade_emplacamento ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <MdInvertColors size={15} color="#093c5e" />
            <h3>Cor:</h3>
            <h4>{vehicle?.cor ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <FiTruck size={15} color="#093c5e" />
            <h3>Modelo:</h3>
            <h4>{vehicle?.modelo ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Ano de fabricação:</h3>
            <h4>{vehicle?.ano_fabricacao ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Ano do modelo:</h3>
            <h4>{vehicle?.ano_modelo ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>RNTRC / ANTT:</h3>
            <h4>{vehicle?.antt ?? "Não consta"}</h4>
          </Item>

          <Item className="gap-1">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Validade ANTT:</h3>
            <h4>
              {vehicle?.dt_validade_antt
                ? moment.utc(vehicle?.dt_validade_antt).format("DD/MM/YYYY")
                : "**"}
            </h4>
          </Item>

          <Item className="gap-1">
            <BsArrowRepeat size={15} color="#093c5e" />
            <h3>Status RNTRC:</h3>
            <h4>{vehicle?.status_rntrc ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Documento:</h3>
            <h4>{vehicle?.documento ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Vencimento do documento:</h3>
            <h4
              className={`fw-bold ${
                isExpired ? "text-danger" : "text-success"
              }`}
            >
              {vehicle?.dt_vencimento_documento
                ? moment
                    .utc(vehicle?.dt_vencimento_documento)
                    .format("DD/MM/YYYY")
                : "--"}
            </h4>
          </Item>

          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>Tipo:</h3>
            <h4>
              {vehicle?.tipo_veiculo && vehicle?.tipo_veiculo !== ""
                ? vehicle?.tipo_veiculo
                : "**"}
            </h4>
          </Item>

          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>Marca:</h3>
            <h4>{vehicle?.marca ?? "--"}</h4>
          </Item>

          {vehicle?.proprietario_arrendatario_id && (
            <>
              <Item className="gap-1">
                <h3 className="text-dark fw-bold">
                  Proprietário arrendatário:
                </h3>
              </Item>
              <Item className="gap-1">
                <BsPerson size={15} color="#093c5e" />
                <h3 titlestatus="true">Nome:</h3>
                <h4>{ownerInformations?.nome ?? "--"}</h4>
              </Item>
              <Item className="gap-1">
                <BsPerson size={15} color="#093c5e" />
                <h3 titlestatus="true">CPF/CNPJ:</h3>
                <h4>
                  {ownerInformations?.cpf_cnpj
                    ? cnpjCpfFormat(ownerInformations?.cpf_cnpj)
                    : "--"}
                </h4>
              </Item>
            </>
          )}

          {vehicle?.proprietario_id && (
            <>
              <Item className="gap-1 mb-4">
                <h3 className="text-dark fw-bold">Proprietário certificado:</h3>
              </Item>
              <Item className="gap-1 mb-4">
                <BsPerson size={15} color="#093c5e" />
                <h3 titlestatus="true">Nome:</h3>
                <h4>{tenantOwner?.nome ?? "--"}</h4>
              </Item>
              <Item className="gap-1 mb-4">
                <BiIdCard size={17} />
                <h3 titlestatus="true">CPF / CNPJ:</h3>
                <h4>
                  {tenantOwner?.cpf_cnpj
                    ? cnpjCpfFormat(tenantOwner?.cpf_cnpj)
                    : "--"}
                </h4>
              </Item>
            </>
          )}
        </Col>

        <Col className="h-100">
          <Row xs={2} className="g-3">
            {vehicle?.foto_documento && (
              <Col className="d-flex">
                <UploadOwnerDocImage
                  title="CRLV"
                  image={vehicle?.foto_documento}
                  handleShowModal={() =>
                    handleShowModal(vehicle?.foto_documento, "CRLV")
                  }
                />
              </Col>
            )}
            {tenantOwner?.foto_comprovante_endereco && (
              <Col className="d-flex">
                <UploadOwnerDocImage
                  title="Comprovante de residência"
                  image={tenantOwner?.foto_comprovante_endereco}
                  handleShowModal={() =>
                    handleShowModal(
                      tenantOwner?.foto_comprovante_endereco,
                      "Comprovante de residência"
                    )
                  }
                />
              </Col>
            )}
            {vehicle?.documentos_arrendamento && (
              <Col className="d-flex">
                <UploadOwnerDocImage
                  title="Documento arrendamento"
                  image={vehicle?.documentos_arrendamento}
                  handleShowModal={() =>
                    handleShowModal(
                      vehicle?.documentos_arrendamento,
                      "Documento arrendamento"
                    )
                  }
                />
              </Col>
            )}
            {tenantOwner?.foto_comprovante_endereco && (
              <Col className="d-flex">
                <UploadOwnerDocImage
                  title="Comprovante de residência"
                  image={tenantOwner?.foto_comprovante_endereco}
                  handleShowModal={() =>
                    handleShowModal(
                      tenantOwner?.foto_comprovante_endereco,
                      "Comprovante de residência"
                    )
                  }
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </ItemBorderCard>
  );
};

export default VehicleInformationsCard;
