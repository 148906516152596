import styled from "styled-components";

export const ButtonContainer = styled.button`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => `1px solid ${border}`};
  border-radius: 4px;
  color: ${({ color }) => color};
  padding: 10px;
  font-weight: bold;

  &:disabled{
    filter: brightness(2.5);
  }
`