import { Spinner } from "react-bootstrap";
import { useTravels } from "../../../../../hooks/TravelProvider";
import { SearchLoaderOrder } from "../SearchLoaderOrder/SearchLoaderOrder";
import TravelLoadOrderList from "../TravelLoadOrderList";
import { sortByDateDescending } from "../../../../../Shared/Utils/data";

import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";
import { useEffect, useState } from "react";
import TablePagination from "../../../../../Shared/Components/PaginationTable/PaginationTable";
import { TabContainer } from "./styles";

export const LoadOrderPendingCompletion = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    loadOrderPendingCompletion,
    completeLoadOrdersTotalPages,
    isLoading,
    getLoadOrder,
    getLoadOrdersPendingCompletion,
  } = useTravels();

  const handlePageChange = (event) => {
    setCurrentPage(event);
  };

  useEffect(() => {
    getLoadOrdersPendingCompletion(currentPage);
  }, [currentPage, getLoadOrdersPendingCompletion]);

  return (
    <div className="d-flex flex-column h-100">
      <TabContainer>
        <SearchLoaderOrder getList={getLoadOrdersPendingCompletion} />
        {isLoading && (
          <div className="d-flex justify-content-center">
            <Spinner size="sm" animation="border" variant="primary" />
          </div>
        )}
        {!isLoading &&
          Array.isArray(loadOrderPendingCompletion) &&
          loadOrderPendingCompletion.length > 0 && (
            <>
              <TravelLoadOrderList
                getLoadOrder={getLoadOrder}
                travelLoadOrderList={sortByDateDescending(
                  loadOrderPendingCompletion
                )}
              />
            </>
          )}
        {(!loadOrderPendingCompletion ||
          loadOrderPendingCompletion.length === 0) &&
          !isLoading && (
            <EmptyTableMessage label="Sem listagem de ordens de carregamentos pendentes de finalização" />
          )}
      </TabContainer>
      {!isLoading &&
        Array.isArray(loadOrderPendingCompletion) &&
        loadOrderPendingCompletion.length > 0 && (
          <div className="mt-2 ps-2">
            <TablePagination
              currentPage={currentPage}
              totalPages={completeLoadOrdersTotalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
    </div>
  );
};
