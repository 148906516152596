import { Link } from "react-router-dom";
import moment from "moment";
import { InfomationItem, Label, TravelsHistoryCardBody } from "./styles";

export const TravelsHistoryCard = ({ travels, driver }) => {
  return (
    <TravelsHistoryCardBody className="w-100 h-100 rounded p-3">
      <Link
        to={`/motoristas-listar/historico-viagens/${driver?.id}/${travels?.nomefantasia}`}
      >
        <div className="d-flex justify-content-between mb-1 align-items-center">
          <Label>{travels?.nomefantasia}</Label>
        </div>
        <div className="text-start d-flex flex-column gap-2">
          <div>
            <InfomationItem className="text-start">
              Quantidade viagens:
            </InfomationItem>
            <InfomationItem className="ms-1 fw-bold">
              {travels?.quantidade_viagens}
            </InfomationItem>
          </div>
          <div>
            <InfomationItem className="text-start">
              Última viagem:
            </InfomationItem>
            <InfomationItem className="ms-1 fw-bold">
              {travels?.dt_ultima_viagem === "Sem registro"
                ? "Sem registro"
                : moment.utc(travels?.dt_ultima_viagem).format("DD/MM/YYYY")}
            </InfomationItem>
          </div>
          <div>
            <InfomationItem className="text-start">Token:</InfomationItem>
            <InfomationItem className="ms-1 fw-bold text-dark">
              {travels?.status_motorista ?? "--"}
            </InfomationItem>
          </div>
        </div>
      </Link>
    </TravelsHistoryCardBody>
  );
};
