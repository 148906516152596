import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Form, FloatingLabel } from "react-bootstrap";
import "./index.css";
import { AlertComponent as Alert } from "../../Shared/Components/Alerts";
import { useUsers } from "../../hooks/UserProvider";
import { LogoImage } from "../../Shared/Components/Logo/Logo";
import { SubmitButton } from "../../Shared/Components/SubmitButton/SubmitButton";
import { BsDot } from "react-icons/bs";
import { ContainerForm } from "./styles";
import LoginBreadcrumb from "../../Shared/Components/BreadCrumb/LoginBreadcrumb";

export default function ChangePwd() {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm();

  const { resetPassword, isSubmitting, showAlert, variantAlert, msgErro } =
    useUsers();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get("token");
  const fields = {
    email: {
      required: true,
      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    },
    password: {
      required: true,
      validate: (value) => {
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}$/;
        return regex.test(value);
      },
    },
    repeatPassword: {
      required: true,
      validate: (value) => {
        let result = value === getValues("senha");
        return result;
      },
    },
  };

  const onSubmit = async (data) => {
    resetPassword(token, data, navigate);
  };

  const [labelNewPwd, setLabelNewPwd] = useState("Digite a sua nova senha");
  const [labelRepeatPwd, setLabelRepeatPwd] = useState(
    "Repita a sua nova senha"
  );

  return (
    <Container fluid className="h-100">
      {showAlert && (
        <Alert
          message={msgErro}
          variant={variantAlert}
          showErrorMsg={submitCount}
        />
      )}
      {errors?.senha?.type === "validate" && (
        <Alert
          message="Senha inválida"
          variant="danger"
          showErrorMsg={submitCount}
        />
      )}
      <Row className="h-100">
        <Col md={7} className="p-md-5">
          <ContainerForm
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto py-5"
          >
            <LogoImage route="/" />
            <LoginBreadcrumb title="Redefinir senha" />

            <Form.Group className="mb-3" controlId="formEmail">
              <FloatingLabel controlId="formEmail" label="Digite o seu e-mail">
                <Form.Control
                  type="email"
                  placeholder="Digite o seu e-mail"
                  className="is-required"
                  disabled={isSubmitting}
                  {...register("email", fields.email)}
                />
                {errors?.email?.type === "required" && (
                  <Form.Text className="text-danger">
                    O e-mail é obrigatório.
                  </Form.Text>
                )}
                {errors?.email?.type === "pattern" && (
                  <Form.Text className="text-danger">E-mail inválido</Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formNewPwd">
              <FloatingLabel
                controlId="formNewPwd"
                label={labelNewPwd}
                className="d-flex"
              >
                <Form.Control
                  type="password"
                  className="is-required input-password"
                  {...register("senha", fields.password)}
                  placeholder="Digite a sua nova senha"
                  disabled={isSubmitting}
                  onFocus={() => setLabelNewPwd("Nova senha")}
                  onBlur={(e) => {
                    if (e.target.value === "")
                      setLabelNewPwd("Digite a sua senha");
                  }}
                />
              </FloatingLabel>
              {errors?.senha?.type === "required" && (
                <Form.Text className="text-danger">
                  A senha é obrigatória.
                </Form.Text>
              )}
              {errors?.senha?.type === "validate" && (
                <Form.Text className="text-danger">
                  <span>
                    A senha precisa ter pelo menos:
                    <br />
                    <BsDot />1 letra maiúscula <br />
                    <BsDot />1 letra minúscula <br />
                    <BsDot />1 caracter especial <br />
                    <BsDot />
                    Pelo menos 1 número <br />
                    <BsDot />
                    Ser maior do que 6 dígitos.
                  </span>
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-4" controlId="formRepeatPwd">
              <FloatingLabel controlId="formRepeatPwd" label={labelRepeatPwd}>
                <Form.Control
                  type="password"
                  className="input-password is-required"
                  {...register("confirma_senha", fields.repeatPassword)}
                  placeholder="Repita a sua senha"
                  disabled={isSubmitting}
                  onFocus={() => setLabelRepeatPwd("Repitir senha")}
                  onBlur={(e) => {
                    if (e.target.value === "")
                      setLabelRepeatPwd("Repita a sua senha");
                  }}
                />
                {errors?.confirma_senha?.type === "required" && (
                  <Form.Text className="text-danger">
                    A senha é obrigatória.
                  </Form.Text>
                )}
                {errors?.confirma_senha?.type === "validate" && (
                  <Form.Text className="text-danger">
                    As senhas digitadas não coincidem.
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>

            <SubmitButton
              isSubmitting={isSubmitting}
              labelButton="Redefinir senha"
            />
          </ContainerForm>
        </Col>
        <Col className="bg-change-pwd d-none d-md-flex" md={5} />
      </Row>
    </Container>
  );
}
