import {
  Button,
  Card,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import styled from "styled-components";
import { FiAlertTriangle } from "react-icons/fi";
import { FaTrashAlt } from "react-icons/fa";

export const FormControlInput = styled(FormControl)`
  font-size: 0.7rem;
  display: flex;
`;


export const DeleteCnhButton = styled(Button)`
  transition: transform 300ms;
  background-color: transparent;
  border: none;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
    background-color: transparent;
    border: none;
  }

  label {
    color: var(--color-gray-15);
    font-size: 0.9rem;
  }
`;

export const DeleteIconButton = styled(FaTrashAlt)`
  color: var(--color-gray-15);
  font-size: 0.9rem;
  margin-top: 4px;
`;

export const FormLabelText = styled(Form.Label)`
  color: var(--color-gray-12);
`;
export const CoverImage = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding-bottom: ${(props) => props.paddingBottom};

  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center center;
`;

export const PdfLoadingContainer = styled.div`
  background-color: var(--color-gray-11);
  color: var(--color-gray-10);
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageTitle = styled.p`
  font-size: 0.8rem;
  color: var(--color-gray-11);
`;

export const BorderCard = styled(Card)`
  &:hover {
    z-index: 1;
    box-shadow: 0 0 5px;
  }

  h3 {
    font-size: 1rem;
    font-weight: normal;
  }

  h4 {
    font-size: 0.7rem;
    color: var(--color-gray-11);
  }

  h5 {
    font-size: 0.7rem;
    color: var(--color-gray-12);

    span {
      font-size: 0.7rem;
    }
  }

  h6 {
    font-size: 0.7rem;
    color: var(--color-gray-11);

    span {
      font-size: 0.7rem;
    }
  }
`;

export const PageTitle = styled(Card.Title)`
  color: var(--color-gray-12);
  font-weight: normal;
  font-size: 1rem;
`;

export const ShowModalButton = styled(Button)`
  border: none;
  background-color: transparent;
  color: var(--color-gray-15);
  font-size: 0.8rem;
  transition: transform 300ms;

  &:hover {
    border: none;
    background-color: transparent;
    color: var(--color-gray-15);
    transform: scale(1.05);
    z-index: 1;
  }

  &:active,
  &:focus {
    border: none;
    background-color: transparent !important;
    color: var(--color-gray-15);
  }
`;

export const UploadImageButton = styled.div`
  background-color: transparent !important;
  width: ${(props) => (props.width ? props.width : "7rem")};
  border-left: solid 2px red;
  border-top: solid 1px var(--color-gray-11);
  border-right: solid 1px var(--color-gray-11);
  border-bottom: solid 1px var(--color-gray-11);
  padding: 3px;
  color: var(--color-gray-13);
  font-size: 0.8rem;
  transition: transform 300ms;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    color: black;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 0.7rem;
  color: red;
`;

export const AlertIcon = styled(FiAlertTriangle)`
  color: red;
`;
export const BlockReasonForm = styled(Form.Control)`
  height: 60px;
  border: 0.5px solid var(--color-gray-11);
  padding: 0.3rem;
`;

export const TitleLabel = styled(Form.Label)`
  font-size: 0.9rem;
  color: var(--color-gray-11);
`;

export const DriverFormRow = styled(Row)`
  max-height: 50vh;
  flex: 1;
  overflow-x: hidden;

  @media (min-width: 425px) {
    max-height: 60vh;
  }

  @media (min-width: 576px) {
    max-height: 65vh;
  }
  @media (min-width: 1024px) {
    max-height: 65vh;
  }

  @media (min-width: 1440px) {
    max-height: 73vh;
  }

  @media (min-width: 1640px) {
    max-height: 75vh;
  }
  @media (min-width: 2440px) {
    max-height: 80vh;
  }
`;

export const FormGroupBorder = styled(Form.Group)`
  border: 0.5px solid var(--color-gray-10);
  border-radius: 8px;
  padding: 10px;
`