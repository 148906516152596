import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { getToken, onlogin, onlogout } from "../Shared/Utils/Auth";
import ApiRequest from "../Shared/Utils/Request";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export const LoginContext = createContext({
  userConnected: null,
  token: null,
  isLoading: false,
  isSubmitting: false,
  currentPerfilName: null,
  postLogin: () => null,
  desconect: () => null,
  sendEmail: () => {},
});

export const useLogins = () => {
  const context = useContext(LoginContext);

  if (!context) {
    throw new Error("useLogins must be within LoginProvider");
  }

  return context;
};

export const LoginProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [userConnected, setUserConnected] = useState(
    localStorage.getItem("userConnected") || ""
  );
  const [userIdConnected, setUserIdConnected] = useState(
    localStorage.getItem("userIdConnected") || ""
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [token, setToken] = useState();

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("currentUser") || ""
  );

  const [currentPerfil, setCurrentPerfil] = useState();

  const [currentPerfilName, setCurrentPerfilName] = useState(
    localStorage.getItem("currentPerfilName") || ""
  );

  const getPermissionPerfilAction = useCallback(async (id, navigate) => {
    try {
      const responsePermissionPerfilAction = await ApiRequest({
        path: `acoes/perfil/${id}`,
        method: "GET",
      });

      const permissionPerfilAction = responsePermissionPerfilAction?.data;

      const permissionManagement = (id) =>
        permissionPerfilAction.find(
          (permission) => permission.id_permissao === id
        );

      const crudPermission = (permission, crud) =>
        permissionManagement(permission)?.acoes
          ? JSON.parse(permissionManagement(permission)?.acoes.includes(crud))
          : false;

      localStorage.setItem(
        "companyManagementPermissionToRead",
        crudPermission(1, "R") === false ? false : true
      );

      localStorage.setItem(
        "unitManagementPermissionToRead",
        crudPermission(2, "R") === false ? false : true
      );

      localStorage.setItem(
        "perfilManagementPermissionToRead",
        crudPermission(3, "R") === false ? false : true
      );

      localStorage.setItem(
        "userManagementPermissionToRead",
        crudPermission(4, "R") === false ? false : true
      );

      localStorage.setItem(
        "accessManagementPermissionToRead",
        crudPermission(5, "R") === false ? false : true
      );

      localStorage.setItem(
        "companyManagementPermissionToCreate",
        crudPermission(1, "C") === false ? false : true
      );

      localStorage.setItem(
        "unitManagementPermissionToCreate",
        crudPermission(2, "C") === false ? false : true
      );

      localStorage.setItem(
        "perfilManagementPermissionToCreate",
        crudPermission(3, "C") === false ? false : true
      );

      localStorage.setItem(
        "userManagementPermissionToCreate",
        crudPermission(4, "C") === false ? false : true
      );

      localStorage.setItem(
        "accessManagementPermissionToCreate",
        crudPermission(5, "C") === false ? false : true
      );

      localStorage.setItem(
        "companyManagementPermissionToUpdate",
        crudPermission(1, "U") === false ? false : true
      );

      localStorage.setItem(
        "unitManagementPermissionToUpdate",
        crudPermission(2, "U") === false ? false : true
      );

      localStorage.setItem(
        "perfilManagementPermissionToUpdate",
        crudPermission(3, "U") === false ? false : true
      );

      localStorage.setItem(
        "userManagementPermissionToUpdate",
        crudPermission(4, "U") === false ? false : true
      );

      localStorage.setItem(
        "accessManagementPermissionToUpdate",
        crudPermission(5, "U") === false ? false : true
      );

      localStorage.setItem(
        "companyManagementPermissionToDelete",
        crudPermission(1, "D") === false ? false : true
      );

      localStorage.setItem(
        "unitManagementPermissionToDelete",
        crudPermission(2, "D") === false ? false : true
      );

      localStorage.setItem(
        "perfilManagementPermissionToDelete",
        crudPermission(3, "D") === false ? false : true
      );

      localStorage.setItem(
        "userManagementPermissionToDelete",
        crudPermission(4, "D") === false ? false : true
      );

      localStorage.setItem(
        "accessManagementPermissionToDelete",
        crudPermission(5, "D") === "false" || crudPermission(5, "D") === false
          ? false
          : true
      );

      setTimeout(() => {
        setIsSubmitting(false);
        setIsLoading(false);
        navigate("/inicio");
      }, 250);
    } catch (error) {
      navigate("/");

      setIsLoading(false);
      setIsSubmitting(false);

      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: `${
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Ocorreu um erro ao logar"
        }`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    } finally {
      // setIsSubmitting(false);
      // setIsLoading(false);
    }
  }, []);

  const postLogin = useCallback(
    async (data, navigate) => {
      try {
        setIsLoading(true);
        setIsSubmitting(true);

        const response = await ApiRequest({
          path: "login",
          method: "POST",
          data: data,
        });

        const { data: responseData } = response;

        await onlogin(responseData);

        setCurrentUser(responseData?.nome);
        setUserIdConnected(responseData?.id);
        setCurrentPerfil(responseData?.perfil);
        setCurrentPerfilName(responseData?.perfil?.nome);

        const currentToken = getToken();

        if (currentToken) {
          setToken(currentToken);
        }

        localStorage.setItem("userConnected", responseData?.email);
        localStorage.setItem("currentUser", responseData?.nome);
        localStorage.setItem("userIdConnected", responseData?.id);
        localStorage.setItem("currentPerfil", responseData?.perfil);
        localStorage.setItem("currentPerfilName", responseData?.perfil?.nome);

        if (responseData?.perfil?.id) {
          await getPermissionPerfilAction(responseData?.perfil?.id, navigate);
        }

        localStorage.setItem("lastIsCloseValue", true);
      } catch (error) {
        setIsLoading(false);
        setIsSubmitting(false);

        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `${
            error?.response?.data?.error
              ? error?.response?.data?.error
              : "Ocorreu um erro ao logar"
          }`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        // setIsSubmitting(false);
      }
    },
    [getPermissionPerfilAction]
  );

  const desconect = useCallback(async (navigate) => {
    try {
      await onlogout().then(() => {
        setUserConnected(null);

        navigate("/");
      });
    } catch (error) {
      console.error();
    }
  }, []);

  const sendEmail = useCallback(async (data, navigate) => {
    try {
      setIsSubmitting(true);

      await ApiRequest({
        path: "sendemail/resetpassword",
        method: "POST",
        data: data,
      }).then(async () => {
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "E-mail enviado com sucesso",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);

        navigate("/");
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: `${
          error?.response?.data
            ? error?.response?.data
            : "Erro ao enviar o e-mail"
        }`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });

      setUserConnected(null);

      setIsSubmitting(false);
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  }, []);

  const providerValue = useMemo(
    () => ({
      userConnected,
      token,
      isLoading,
      isSubmitting,
      currentUser,
      userIdConnected,
      currentPerfil,
      currentPerfilName,
      postLogin,
      desconect,
      sendEmail,
    }),
    [
      userConnected,
      token,
      isLoading,
      isSubmitting,
      currentUser,
      userIdConnected,
      currentPerfil,
      currentPerfilName,
      postLogin,
      desconect,
      sendEmail,
    ]
  );

  return (
    <LoginContext.Provider value={providerValue}>
      {children}
    </LoginContext.Provider>
  );
};
