import Navbar from "react-bootstrap/Navbar";
import { Collapse, ListGroup, Image } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";

import {
  BsBoxArrowInRight,
  BsBuilding,
  BsCapslock,
  BsClipboardCheck,
  BsClipboardData,
  BsGear,
  BsGlobe2,
  BsHouse,
  BsKey,
  BsPinMap,
} from "react-icons/bs";
import { FaRegBuilding } from "react-icons/fa";
import { CgProfile, CgReorder } from "react-icons/cg";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { FiUsers } from "react-icons/fi";
import { useState } from "react";

import {
  HiOutlineTruck,
  HiOutlineUserGroup,
  HiUserGroup,
} from "react-icons/hi";

import Logo from "../../Assets/Images/Logo.png";

import {
  ImageContainer,
  LogoffButton,
  User,
} from "../Components/Header/styles";

import { CollapseButton, NavbarScroll, Perfil } from "./styles";
import { AvatarFallBack } from "../Components/AvatarFallBack";

export const NavbarComponent = ({ nome, currentPerfilName, desconect }) => {
  const location = useLocation();
  const isLinkActive = (path) => {
    return location.pathname === path;
  };

  const { id, token } = useParams();

  const panelConfig =
    isLinkActive("/empresas") ||
    isLinkActive("/empresas-adicionar") ||
    isLinkActive(`/empresas-atualizar/${id}`) ||
    isLinkActive("/unidades") ||
    isLinkActive("/unidades-adicionar") ||
    isLinkActive(`/unidades-atualizar/${id}`) ||
    isLinkActive("/perfis") ||
    isLinkActive("/perfis-adicionar") ||
    isLinkActive(`/perfis-atualizar/${id}`) ||
    isLinkActive("/usuarios") ||
    isLinkActive("/usuarios-adicionar") ||
    isLinkActive(`/usuarios-atualizar/${id}`) ||
    isLinkActive("/configuracoes-acesso");

  const driversConfig =
    isLinkActive("/motoristas") ||
    isLinkActive("/motoristas-listar") ||
    isLinkActive(`/motoristas-listar/informacoes-sat/${id}`) ||
    isLinkActive("/motoristas/add") ||
    isLinkActive(`/motoristas-listar/${id}`) ||
    isLinkActive(`/motoristas-listar/temporario/${id}`) ||
    isLinkActive(`/motoristas/update/${id}`) ||
    isLinkActive(`/motoristas-informacoes/${id}`) ||
    isLinkActive(`/motoristas-editar/${id}`) ||
    isLinkActive("/motoristas-adicionar") ||
    isLinkActive(`/motoristas/processos-judiciais/${id}/${token}`) ||
    isLinkActive("/motoristas/processos-em-curso");

  const ownersConfig =
    isLinkActive("/proprietarios") ||
    isLinkActive("/proprietarios-adicionar") ||
    isLinkActive("/proprietarios-listar") ||
    isLinkActive(`/proprietarios-listar/${id}`) ||
    isLinkActive(`/proprietarios-editar/${id}`) ||
    isLinkActive(`/proprietarios-informacoes/${id}`);

  const vehiclesConfig =
    isLinkActive("/veiculos") ||
    isLinkActive("/veiculos-adicionar") ||
    isLinkActive("/veiculos-listar") ||
    isLinkActive(`/veiculos-listar/${id}`) ||
    isLinkActive(`/veiculos-editar/${id}`) ||
    isLinkActive(`/veiculos-informacoes/${id}`);

  const travelsConfig =
    isLinkActive("/gestao-viagens/") ||
    isLinkActive("/gestao-viagens/ordem-de-carregamento") ||
    isLinkActive(`/gestao-viagens/ordem-de-carregamento/${token}`) ||
    isLinkActive("/gestao-viagens/viagens") ||
    isLinkActive(
      `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/${token}`
    ) ||
    isLinkActive(`/gestao-viagens/ordem-de-carregamento/log-viagem/${token}`) ||
    isLinkActive(
      `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/${token}`
    ) ||
    isLinkActive(
      `/gestao-viagens/ordem-de-carregamento/escolher-rota/${token}`
    ) ||
    isLinkActive(
      `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/enviar-ocorrencia/${token}`
    ) ||
    isLinkActive("/gestao-viagens/tipos-de-ocorrencias") ||
    isLinkActive("/gestao-viagens/tipos-de-ocorrencias/adicionar/ocorrencia") ||
    isLinkActive(
      `/gestao-viagens/tipos-de-ocorrencias/editar/ocorrencia/${id}`
    );

  const mdfeConfig =
    isLinkActive("/gestao-regularizacao-servico-transporte") ||
    isLinkActive("/gestao-regularizacao-servico-transporte/lista") ||
    isLinkActive(`/gestao-regularizacao-servico-transporte/detalhes/${id}`);

  const registerConfig = driversConfig || ownersConfig || vehiclesConfig;

  const [open, setOpen] = useState(panelConfig ? true : false);
  const [openTravels, setOpenTravels] = useState(travelsConfig ? true : false);
  const [openMDFe, setOpenMDFe] = useState(mdfeConfig ? true : false);

  const [openRegister, setOpenRegister] = useState(
    registerConfig ? true : false
  );

  const userName = nome && nome.split(" ");

  return (
    <Navbar expand="lg" className="d-lg-none" id="navbar-component">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <ImageContainer className="d-flex align-items-center gap-3">
        <div>
          <User>Olá, {userName[0]}</User>
          <Perfil>{currentPerfilName}</Perfil>
        </div>
        <AvatarFallBack />
        <Link to="/inicio">
          <Image src={Logo} width="108rem" />
        </Link>
      </ImageContainer>

      <Navbar.Collapse id="basic-navbar-nav" className="mt-4">
        <NavbarScroll className="me-auto">
          <ListGroup variant="flush" id="list-menu-default">
            <ListGroup.Item
              as={Link}
              to={"/inicio"}
              active={isLinkActive("/inicio")}
              action
              onClick={() => setOpen(false)}
              className="px-3"
            >
              <BsHouse />
              <span className="ms-1">Início</span>
            </ListGroup.Item>

            <ListGroup.Item
              as={Link}
              to={"/gestao-cadastros"}
              action
              active={isLinkActive("/gestao-cadastros")}
              className={`px-3 ${registerConfig && "selected-sidebar-item"}`}
            >
              <CollapseButton
                size="sm"
                variant=""
                onClick={() => setOpenRegister(!openRegister)}
                aria-expanded={openRegister}
                className="px-0"
              >
                <BsClipboardData />
                <span className="ms-1">Gestão de cadastros</span>
                {(!openRegister && <IoMdArrowDropdown />) || (
                  <IoMdArrowDropup />
                )}
              </CollapseButton>
            </ListGroup.Item>

            <Collapse in={openRegister}>
              <ListGroup variant="flush" className="mb-2">
                <ListGroup.Item
                  as={Link}
                  to={"/motoristas"}
                  active={driversConfig}
                  onClick={() => setOpenRegister(true)}
                >
                  <HiOutlineUserGroup />
                  <span className="ms-1">Motoristas</span>
                </ListGroup.Item>

                <ListGroup.Item
                  title={"Proprietarios"}
                  as={Link}
                  to={"/proprietarios"}
                  active={ownersConfig}
                  onClick={() => setOpenRegister(true)}
                >
                  <HiUserGroup size={15} />
                  <span className="ms-1">Proprietários</span>
                </ListGroup.Item>

                <ListGroup.Item
                  title={"Veículos"}
                  as={Link}
                  to={"/veiculos"}
                  active={vehiclesConfig}
                  onClick={() => setOpenRegister(true)}
                >
                  <HiOutlineTruck size={15} />
                  <span className="ms-1">Veículos</span>
                </ListGroup.Item>
              </ListGroup>
            </Collapse>

            <ListGroup.Item
              as={Link}
              to={"/gestao-viagens"}
              action
              active={isLinkActive("/gestao-viagens")}
              className={`px-3 ${travelsConfig && "selected-sidebar-item"} `}
              title="Gestão de viagens"
            >
              <CollapseButton
                size="sm"
                id="gestao-viagens"
                variant=""
                onClick={() => setOpenTravels(!openTravels)}
                aria-expanded={openTravels}
                className="px-0"
              >
                <div className="d-flex gap-2 text-start align-items-center">
                  <BsGlobe2 size={13} />
                  <span className="ms-1">Gestão de viagens</span>
                  <div className="d-flex text-end justify-content-end">
                    {(!openTravels && <IoMdArrowDropdown />) || (
                      <IoMdArrowDropup />
                    )}
                  </div>
                </div>
              </CollapseButton>
            </ListGroup.Item>

            <Collapse in={openTravels}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  title="Ordens de Carregamento"
                  as={Link}
                  to={"/gestao-viagens/ordem-de-carregamento"}
                  active={
                    isLinkActive("/gestao-viagens/ordem-de-carregamento") ||
                    isLinkActive(
                      `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/${token}`
                    ) ||
                    isLinkActive(
                      `/gestao-viagens/ordem-de-carregamento/log-viagem/${token}`
                    ) ||
                    isLinkActive(
                      `/gestao-viagens/ordem-de-carregamento/${token}`
                    ) ||
                    isLinkActive(
                      `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/${token}`
                    ) ||
                    isLinkActive(
                      `/gestao-viagens/ordem-de-carregamento/escolher-rota/${token}`
                    ) ||
                    isLinkActive("/gestao-viagens/tipos-de-ocorrencias") ||
                    isLinkActive(
                      `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/enviar-ocorrencia/${token}`
                    )
                  }
                  onClick={() => setOpenTravels(true)}
                >
                  <div className="ms-1 d-flex gap-2 text-start align-items-center">
                    <CgReorder size={18} />
                    <span className="ms-1">Ordens de Carregamento</span>
                  </div>
                </ListGroup.Item>

                <ListGroup.Item
                  title="Viagens"
                  as={Link}
                  to={"/gestao-viagens/viagens"}
                  active={isLinkActive("/gestao-viagens/viagens")}
                  onClick={() => setOpenTravels(true)}
                >
                  <div className="ms-1 d-flex gap-2 text-start align-items-center">
                    <BsPinMap size={14} />
                    <span className="ms-1">Viagens</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  title="Tipos de ocorrências"
                  as={Link}
                  to={"/gestao-viagens/tipos-de-ocorrencias"}
                  active={
                    isLinkActive("/gestao-viagens/tipos-de-ocorrencias") ||
                    isLinkActive(
                      "/gestao-viagens/tipos-de-ocorrencias/adicionar/ocorrencia"
                    ) ||
                    isLinkActive(
                      `/gestao-viagens/tipos-de-ocorrencias/editar/ocorrencia/${id}`
                    )
                  }
                  onClick={() => setOpenTravels(true)}
                >
                  <div className="ms-1 d-flex gap-2 text-start align-items-center">
                    <BsCapslock size={14} />
                    <span className="ms-1">Tipos de ocorrências</span>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Collapse>

            <ListGroup.Item
              as={Link}
              to={"/gestao-regularizacao-servico-transporte"}
              action
              active={mdfeConfig}
              className={`justify-content-start text-start ${
                mdfeConfig && "selected-sidebar-item"
              }`}
              title="Gestão de MDF-e"
            >
              <CollapseButton
                size="sm"
                id="gestao-mdfe"
                variant=""
                onClick={() => setOpenMDFe(!openMDFe)}
                aria-expanded={openMDFe}
                className="px-0 text-center w-100"
              >
                <div className="d-flex gap-2 text-start align-items-center">
                  <BsClipboardCheck size={14} />
                  <span className="ms-1">Gestão de MDF-es</span>
                  <div className="d-flex text-end justify-content-end">
                    {(!openMDFe && <IoMdArrowDropdown />) || (
                      <IoMdArrowDropup />
                    )}
                  </div>
                </div>
              </CollapseButton>
            </ListGroup.Item>

            {localStorage.getItem("accessManagementPermissionToRead") &&
              localStorage.getItem("accessManagementPermissionToRead") !==
                "false" && (
                <>
                  <ListGroup.Item
                    as={Link}
                    to={"/painel-configuracao"}
                    action
                    active={isLinkActive("/painel-configuracao")}
                    className={`p-1 px-3 justify-content-start mb-1 ${
                      panelConfig && "selected-sidebar-item"
                    }`}
                  >
                    <CollapseButton
                      size="sm"
                      variant=""
                      onClick={() => setOpen(!open)}
                      aria-expanded={open}
                      className="px-0"
                    >
                      <BsGear />
                      <span className="ms-1">Configurações do sistema</span>
                      {(!open && <IoMdArrowDropdown />) || <IoMdArrowDropup />}
                    </CollapseButton>
                  </ListGroup.Item>
                  <Collapse in={open}>
                    <ListGroup variant="flush" className="mb-2">
                      {localStorage.getItem(
                        "companyManagementPermissionToRead"
                      ) &&
                        localStorage.getItem(
                          "companyManagementPermissionToRead"
                        ) !== "false" && (
                          <ListGroup.Item
                            as={Link}
                            className="px-3"
                            to={"/empresas"}
                            active={
                              isLinkActive("/empresas") ||
                              isLinkActive("/empresas-adicionar") ||
                              isLinkActive(`/empresas-atualizar/${id}`)
                            }
                            onClick={() => setOpen(true)}
                          >
                            <BsBuilding />
                            <span className="ms-1">Empresas</span>
                          </ListGroup.Item>
                        )}

                      {localStorage.getItem("unitManagementPermissionToRead") &&
                        localStorage.getItem(
                          "unitManagementPermissionToRead"
                        ) !== "false" && (
                          <ListGroup.Item
                            as={Link}
                            to={"/unidades"}
                            active={
                              isLinkActive("/unidades") ||
                              isLinkActive("/unidades-adicionar") ||
                              isLinkActive(`/unidades-atualizar/${id}`)
                            }
                            onClick={() => setOpen(true)}
                          >
                            <FaRegBuilding />
                            <span className="ms-1">Unidades</span>
                          </ListGroup.Item>
                        )}

                      {localStorage.getItem(
                        "perfilManagementPermissionToRead"
                      ) &&
                        localStorage.getItem(
                          "perfilManagementPermissionToRead"
                        ) !== "false" && (
                          <ListGroup.Item
                            as={Link}
                            to={"/perfis"}
                            active={
                              isLinkActive("/perfis") ||
                              isLinkActive("/perfis-adicionar") ||
                              isLinkActive(`/perfis-atualizar/${id}`)
                            }
                            onClick={() => setOpen(true)}
                          >
                            <CgProfile />
                            <span className="ms-1">Perfis</span>
                          </ListGroup.Item>
                        )}

                      {localStorage.getItem("userManagementPermissionToRead") &&
                        localStorage.getItem(
                          "userManagementPermissionToRead"
                        ) !== "false" && (
                          <ListGroup.Item
                            as={Link}
                            to={"/usuarios"}
                            active={
                              isLinkActive("/usuarios") ||
                              isLinkActive("/usuarios-adicionar") ||
                              isLinkActive(`/usuarios-atualizar/${id}`)
                            }
                            onClick={() => setOpen(true)}
                          >
                            <FiUsers />
                            <span className="ms-1">Usuários</span>
                          </ListGroup.Item>
                        )}

                      {localStorage.getItem(
                        "accessManagementPermissionToRead"
                      ) &&
                        localStorage.getItem(
                          "accessManagementPermissionToRead"
                        ) !== "false" && (
                          <ListGroup.Item
                            as={Link}
                            to={"/configuracoes-acesso"}
                            active={isLinkActive("/configuracoes-acesso")}
                            onClick={() => setOpen(true)}
                          >
                            <BsKey />
                            <span className="ms-1">
                              Configurações de acesso
                            </span>
                          </ListGroup.Item>
                        )}
                    </ListGroup>
                  </Collapse>
                </>
              )}

            <ListGroup.Item className="d-flex justify-content-start px-2 mt-4">
              <LogoffButton
                onClick={() => desconect()}
                className="btn-background-blue-dark rounded d-flex gap-1 align-items-center"
              >
                Sair
                <BsBoxArrowInRight size={16} />
              </LogoffButton>
            </ListGroup.Item>
          </ListGroup>
        </NavbarScroll>
      </Navbar.Collapse>
    </Navbar>
  );
};
