import { Button } from "react-bootstrap";
import styled from "styled-components";

export const User = styled.h1`
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  color: var(--color-blue-90);
  font-weight: bold;
  margin-bottom: -0.4rem;
`;

export const Perfil = styled.span`
  color: var(--color-gray-12);
  font-size: 0.8rem;
`;

export const HeaderContainer = styled.header`
  box-shadow: 0 0 3px "#565656";
  z-index: 1;
`;

export const ImageContainer = styled.div`
  padding-top: 0.1rem;
`;

export const LogoffButton = styled(Button)`
  transition: transform 300ms;
  font-size: 0.875rem;
  font-weight: bold;
  color: white;

  &:hover {
    transform: scale(1.01);
    filter: brightness(1.1);
    z-index: 1;
  }
`;
