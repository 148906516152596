import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { get } from "lodash";
import ReactInputMask from "react-input-mask";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";

import { PageTitle, TitleContainer } from "./styles";

import ArrayEstadosCidades from "../../../../../../Shared/Utils/EstadosCidades/estados-cidades.json";
import ModalOCRImport from "../../../../../../Shared/Components/ModalOCRImport/ModalOCRImport";

const VehicleForm = ({
  defaultTruck,
  errors,
  isEdit,
  optional,
  register,
  setValue,
  title,
  truck,
  vehicle,
  setOCRImage,
}) => {
  const [cidadesDoEstado, setCidadesDoEstado] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const currentState = useMemo(() => {
    if (defaultTruck) {
      return defaultTruck?.estado_emplacamento;
    }

    if (vehicle) {
      return vehicle?.estado_emplacamento;
    }
  }, [defaultTruck, vehicle]);

  const currentCity = useMemo(() => {
    if (defaultTruck) {
      return defaultTruck?.cidade_emplacamento;
    }

    if (vehicle) {
      return vehicle?.cidade_emplacamento;
    }
  }, [defaultTruck, vehicle]);

  useEffect(() => {
    setValue(
      truck ? `${truck}.estado_emplacamento` : "estado_emplacamento",
      currentState
    );

    setSelectedState(currentState);
  }, [currentState, setValue, truck]);

  useEffect(() => {
    setValue(
      truck ? `${truck}.cidade_emplacamento` : "cidade_emplacamento",
      currentCity
    );

    setSelectedCity(currentCity);
  }, [currentCity, setValue, truck]);

  const handleKeyUp = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.toUpperCase();
  };

  const validateYear = useCallback((value) => {
    const currentYear = new Date().getFullYear();
    const minYear = 1900;
    if (!value) {
      return "O ano de fabricação é obrigatório";
    } else if (value < minYear || value > currentYear) {
      return `O ano deve estar entre ${minYear} e ${currentYear}`;
    }
    return true;
  }, []);

  const siglas = ArrayEstadosCidades.estados.map((estado) => estado.sigla);

  const obterCidadesPorSigla = (sigla) => {
    const estado = ArrayEstadosCidades.estados.find(
      (estado) => estado.sigla === sigla
    );

    if (estado) {
      return estado.cidades;
    } else {
      return [];
    }
  };

  const handleChangeState = useCallback((event) => {
    setSelectedState(event.target.value);
  }, []);

  const handleChangeCity = useCallback((event) => {
    setSelectedCity(event.target.value);
  }, []);

  useEffect(() => {
    if (selectedState) {
      const currentCities = obterCidadesPorSigla(selectedState);
      setCidadesDoEstado(currentCities);
    }
  }, [selectedState, vehicle?.cidade_emplacamento]);

  return (
    <>
      <TitleContainer>
        {title && <PageTitle>{title}</PageTitle>}
        {!vehicle && !defaultTruck && (
          <ModalOCRImport
            setValue={setValue}
            setSelectedCity={setSelectedCity}
            setSelectedState={setSelectedState}
            obterCidadesPorSigla={obterCidadesPorSigla}
            setOCRImage={setOCRImage}
            type="vehicle"
            setImageCNHType={setOCRImage}
            truck={truck}
          />
        )}
      </TitleContainer>

      <Row className="g-2 mb-2">
        <Form.Group as={Col} md={6} lg={4} controlId="formPlate">
          <FloatingLabel controlId="formPlate" label="Placa">
            <Form.Control
              {...register(truck ? `${truck}.placa` : "placa", {
                required:
                  optional || defaultTruck || vehicle || vehicle
                    ? false
                    : "A placa é obrigatória",
              })}
              as={ReactInputMask}
              maskChar={null}
              mask="AAAAAAAAA"
              formatChars={{ A: "[A-Za-z0-9]" }}
              autoComplete="on"
              size="sm"
              type="text"
              disabled={defaultTruck || vehicle}
              className="is-required"
              onChange={handleKeyUp}
              defaultValue={defaultTruck ? defaultTruck?.placa : vehicle?.placa}
            />
            {get(errors, truck ? `${truck}.placa` : "placa") && (
              <Form.Text className="text-danger">
                {get(errors, truck ? `${truck}.placa` : "placa")?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formRenavam">
          <FloatingLabel controlId="formRenavam" label="Renavam">
            <Form.Control
              {...register(truck ? `${truck}.renavam` : "renavam", {
                required:
                  optional || defaultTruck || vehicle || vehicle
                    ? false
                    : "O renavam é obrigatório",
                pattern: {
                  value: /^\d{11}$/,
                  message: "Digite um renavam válido com 11 dígitos",
                },
              })}
              autoComplete="on"
              as={ReactInputMask}
              mask="99999999999"
              maskChar={null}
              size="sm"
              type="text"
              className="is-required"
              disabled={defaultTruck || vehicle}
              defaultValue={
                defaultTruck ? defaultTruck?.renavam : vehicle?.renavam
              }
            />
            {get(errors, truck ? `${truck}.renavam` : "renavam") && (
              <Form.Text className="text-danger">
                {get(errors, truck ? `${truck}.renavam` : "renavam")?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formChassi">
          <FloatingLabel controlId="formChassi" label="Chassi">
            <Form.Control
              {...register(truck ? `${truck}.chassi` : "chassi", {
                required:
                  optional || defaultTruck || vehicle
                    ? false
                    : "O chassi é obrigatório",
                minLength: {
                  value: 17,
                  message: "O chassi deve ter no mínimo 17 caracteres",
                },
              })}
              autoComplete="on"
              size="sm"
              type="text"
              disabled={(vehicle || defaultTruck) && !isEdit}
              className="is-required"
              defaultValue={
                defaultTruck ? defaultTruck?.chassi : vehicle?.chassi
              }
            />
            {get(errors, truck ? `${truck}.chassi` : "chassi") && (
              <Form.Text className="text-danger">
                {get(errors, truck ? `${truck}.chassi` : "chassi")?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formQuantidadeEixos">
          <FloatingLabel
            controlId="formQuantidadeEixos"
            label="Quantidade de eixos"
          >
            <Form.Control
              {...register(truck ? `${truck}.qtd_eixo` : "qtd_eixo", {
                required:
                  optional || defaultTruck || vehicle
                    ? false
                    : "A quantidade de eixos é obrigatória",
                validate: (value) =>
                  parseInt(value) <= 30 || "Valor máximo: 30",
              })}
              as={ReactInputMask}
              mask="99"
              maskChar={null}
              autoComplete="on"
              size="sm"
              type="text"
              disabled={(defaultTruck || vehicle) && !isEdit}
              className="is-required"
              defaultValue={
                defaultTruck ? defaultTruck?.qtd_eixo : vehicle?.qtd_eixo
              }
            />
            {get(errors, truck ? `${truck}.qtd_eixo` : "qtd_eixo") && (
              <Form.Text className="text-danger">
                {get(errors, truck ? `${truck}.qtd_eixo` : "qtd_eixo")?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formEstado">
          <FloatingLabel controlId="formEstado" label="Estado">
            <Form.Select
              aria-label="Default select example"
              {...register(
                truck ? `${truck}.estado_emplacamento` : "estado_emplacamento",
                {
                  required:
                    optional || (defaultTruck && !isEdit)
                      ? false
                      : "O estado é obrigatório",
                  validate: (value) =>
                    value !== "" || "Selecione o estado de emplacamento",
                }
              )}
              type="text"
              size="sm"
              className="is-required"
              disabled={(defaultTruck || vehicle) && !isEdit}
              onChange={handleChangeState}
              value={selectedState}
            >
              <option value="" disabled>
                -- Selecione um Estado --
              </option>
              {siglas
                .filter((sigla) => sigla !== "Selecione o Estado")
                .map((sigla) => (
                  <option key={sigla} value={sigla}>
                    {sigla}
                  </option>
                ))}
            </Form.Select>
            {get(
              errors,
              truck ? `${truck}.estado_emplacamento` : "estado_emplacamento"
            ) && (
              <Form.Text className="text-danger">
                {
                  get(
                    errors,
                    truck
                      ? `${truck}.estado_emplacamento`
                      : "estado_emplacamento"
                  )?.message
                }
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formCidadeEmplacamento">
          <FloatingLabel
            controlId="formCidadeEmplacamento"
            label="Cidade de emplacamento"
          >
            <Form.Select
              aria-label="Default select example"
              {...register(
                truck ? `${truck}.cidade_emplacamento` : "cidade_emplacamento",
                {
                  required:
                    optional || (defaultTruck && !isEdit)
                      ? false
                      : "A cidade é obrigatória",
                  validate: (value) =>
                    value !== "" || "Selecione a cidade de emplacamento",
                }
              )}
              className="is-required"
              type="text"
              size="sm"
              disabled={(defaultTruck || vehicle) && !isEdit}
              value={selectedCity}
              onChange={handleChangeCity}
            >
              <option value="" disabled>
                -- Selecione a cidade --
              </option>
              {cidadesDoEstado.map((sigla) => (
                <option key={sigla} value={sigla}>
                  {sigla.toUpperCase()}
                </option>
              ))}
            </Form.Select>
            {get(
              errors,
              truck ? `${truck}.cidade_emplacamento` : "cidade_emplacamento"
            ) && (
              <Form.Text className="text-danger">
                {
                  get(
                    errors,
                    truck
                      ? `${truck}.cidade_emplacamento`
                      : "cidade_emplacamento"
                  )?.message
                }
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formAnoFabricacao">
          <FloatingLabel
            controlId="formAnoFabricacao"
            label="Ano de fabricação"
          >
            <Form.Control
              {...register(
                truck ? `${truck}.ano_fabricacao` : "ano_fabricacao",
                {
                  required:
                    optional || (defaultTruck && !isEdit)
                      ? false
                      : "O ano de fabricação é obrigatório",
                  minLength: {
                    value: 4,
                    message: "Insira o ano no formato: XXXX",
                  },
                  validate: !optional && validateYear,
                }
              )}
              as={ReactInputMask}
              maskChar={null}
              mask="9999"
              autoComplete="on"
              size="sm"
              type="text"
              disabled={(defaultTruck || vehicle) && !isEdit}
              className="is-required"
              defaultValue={
                defaultTruck
                  ? defaultTruck?.ano_fabricacao
                  : vehicle?.ano_fabricacao
              }
            />
            {get(
              errors,
              truck ? `${truck}.ano_fabricacao` : "ano_fabricacao"
            ) && (
              <Form.Text className="text-danger">
                {
                  get(
                    errors,
                    truck ? `${truck}.ano_fabricacao` : "ano_fabricacao"
                  )?.message
                }
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formModel">
          <FloatingLabel controlId="formModel" label="Modelo">
            <Form.Control
              {...register(truck ? `${truck}.modelo` : "modelo", {
                required:
                  optional || defaultTruck || vehicle
                    ? false
                    : "O modelo é obrigatório",
                validate: optional
                  ? true
                  : (value) => value !== "" || "Selecione o modelo",
              })}
              size="sm"
              type="text"
              disabled={(defaultTruck || vehicle) && !isEdit}
              className="is-required"
              defaultValue={
                defaultTruck ? defaultTruck?.modelo : vehicle?.modelo
              }
            />

            {get(errors, truck ? `${truck}.modelo` : "modelo") && (
              <Form.Text className="text-danger">
                {get(errors, truck ? `${truck}.modelo` : "modelo")?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formAnoModelo">
          <FloatingLabel controlId="formAnoModelo" label="Ano do modelo">
            <Form.Control
              {...register(truck ? `${truck}.ano_modelo` : "ano_modelo", {
                required:
                  optional || (defaultTruck && !isEdit)
                    ? false
                    : "O ano do modelo é obrigatório",
                minLength: {
                  value: 4,
                  message: "Insira o ano no formato: XXXX",
                },
                validate: !optional && validateYear,
              })}
              as={ReactInputMask}
              maskChar={null}
              mask="9999"
              autoComplete="on"
              size="sm"
              type="text"
              disabled={(defaultTruck || vehicle) && !isEdit}
              className="is-required"
              defaultValue={
                defaultTruck ? defaultTruck?.ano_modelo : vehicle?.ano_modelo
              }
            />
            {get(errors, truck ? `${truck}.ano_modelo` : "ano_modelo") && (
              <Form.Text className="text-danger">
                {
                  get(errors, truck ? `${truck}.ano_modelo` : "ano_modelo")
                    ?.message
                }
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formColor">
          <FloatingLabel
            controlId="formColor"
            label={truck ? "Cor da carreta" : "Cor do cavalo"}
          >
            <Form.Control
              {...register(truck ? `${truck}.cor` : "cor", {
                required:
                  optional || defaultTruck || vehicle
                    ? false
                    : "A cor é obrigatória",
                validate: optional
                  ? true
                  : (value) => value !== "" || "Selecione a cor",
              })}
              size="sm"
              type="text"
              disabled={(defaultTruck || vehicle) && !isEdit}
              className="is-required"
              defaultValue={defaultTruck ? defaultTruck?.cor : vehicle?.cor}
            />

            {get(errors, truck ? `${truck}.cor` : "cor") && (
              <Form.Text className="text-danger">
                {get(errors, truck ? `${truck}.cor` : "cor")?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formDoc">
          <FloatingLabel controlId="formDoc" label="Documento">
            <Form.Control
              {...register(truck ? `${truck}.documento` : "documento", {
                required:
                  optional || defaultTruck || vehicle
                    ? false
                    : "O documento é obrigatório",
              })}
              size="sm"
              type="text"
              disabled={(defaultTruck || vehicle) && !isEdit}
              className="is-required"
              defaultValue={
                defaultTruck?.documento
                  ? defaultTruck?.documento
                  : vehicle?.documento
              }
            />

            {get(errors, truck ? `${truck}.documento` : "documento") && (
              <Form.Text className="text-danger">
                {
                  get(errors, truck ? `${truck}.documento` : "documento")
                    ?.message
                }
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formStartDoc">
          <FloatingLabel controlId="formStartDoc" label="Emissão documento">
            <Form.Control
              {...register(
                truck
                  ? `${truck}.dt_emissao_documento`
                  : "dt_emissao_documento",
                {
                  required:
                    optional || defaultTruck || vehicle
                      ? false
                      : "A data de emissão do documento é obrigatória",
                }
              )}
              aria-label="date"
              size="sm"
              type="date"
              disabled={(defaultTruck || vehicle) && !isEdit}
              className="is-required"
              defaultValue={
                defaultTruck
                  ? moment
                      .utc(defaultTruck?.dt_emissao_documento)
                      .format("YYYY-MM-DD")
                  : vehicle?.dt_emissao_documento &&
                    moment
                      .utc(vehicle?.dt_emissao_documento)
                      .format("YYYY-MM-DD")
              }
            />
            {get(
              errors,
              truck ? `${truck}.dt_emissao_documento` : "dt_emissao_documento"
            ) && (
              <Form.Text className="text-danger">
                {
                  get(
                    errors,
                    truck
                      ? `${truck}.dt_emissao_documento`
                      : "dt_emissao_documento"
                  )?.message
                }
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formType">
          <FloatingLabel
            controlId="formType"
            label={truck ? "Tipo da carreta" : "Tipo do veículo"}
          >
            <Form.Control
              {...register(truck ? `${truck}.tipo_veiculo` : "tipo_veiculo", {
                required:
                  optional || defaultTruck || vehicle
                    ? false
                    : "O tipo de veículo é obrigatório",
                validate: optional
                  ? true
                  : (value) => value !== "" || "Selecione o tipo",
              })}
              size="sm"
              type="text"
              className="is-required"
              disabled={(defaultTruck || vehicle) && !isEdit}
              defaultValue={
                defaultTruck
                  ? defaultTruck?.tipo_veiculo
                  : vehicle?.tipo_veiculo
              }
            ></Form.Control>
            {get(errors, truck ? `${truck}.tipo_veiculo` : "tipo_veiculo") && (
              <Form.Text className="text-danger">
                {
                  get(errors, truck ? `${truck}.tipo_veiculo` : "tipo_veiculo")
                    ?.message
                }
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} lg={4} controlId="formBrand">
          <FloatingLabel
            controlId="formBrand"
            label={truck ? "Marca da carreta" : "Marca do veículo"}
          >
            <Form.Control
              {...register(truck ? `${truck}.marca` : "marca", {
                required:
                  optional || defaultTruck || vehicle
                    ? false
                    : "A marca é obrigatória",
                validate: optional
                  ? true
                  : (value) => value !== "" || "Selecione a marca",
              })}
              size="sm"
              type="text"
              className="is-required"
              disabled={(defaultTruck || vehicle) && !isEdit}
              defaultValue={defaultTruck ? defaultTruck?.marca : vehicle?.marca}
            />
            {get(errors, truck ? `${truck}.marca` : "marca") && (
              <Form.Text className="text-danger">
                {get(errors, truck ? `${truck}.marca` : "marca")?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        {(vehicle || defaultTruck) && (
          <>
            <Form.Group as={Col} md={6} lg={4} controlId="formAntt">
              <FloatingLabel controlId="formAntt" label="RNTRC / ANTT">
                <Form.Control
                  {...register(truck ? `${truck}.antt` : "antt", {
                    required:
                      optional || defaultTruck || vehicle
                        ? false
                        : "A antt é obrigatório",
                  })}
                  autoComplete="on"
                  size="sm"
                  type="text"
                  disabled={(defaultTruck || vehicle) && !isEdit}
                  className="is-required"
                  defaultValue={
                    defaultTruck ? defaultTruck?.antt : vehicle?.antt
                  }
                />
                {get(errors, truck ? `${truck}.antt` : "antt") && (
                  <Form.Text className="text-danger">
                    {get(errors, truck ? `${truck}.antt` : "antt")?.message}
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={6} lg={4} controlId="formStatusAntt">
              <FloatingLabel
                controlId="formStatusAntt"
                label="Status RNTRC / ANTT"
              >
                <Form.Control
                  {...register(
                    truck
                      ? `${truck}.status_rntrc
                  `
                      : "status_rntrc",
                    {
                      required:
                        optional || defaultTruck || vehicle
                          ? false
                          : "O status é obrigatório",
                    }
                  )}
                  autoComplete="on"
                  size="sm"
                  type="text"
                  disabled={(defaultTruck || vehicle) && !isEdit}
                  className="is-required"
                  defaultValue={
                    defaultTruck
                      ? defaultTruck?.status_rntrc
                      : vehicle?.status_rntrc
                  }
                />
                {get(
                  errors,
                  truck
                    ? `${truck}.status_rntrc
`
                    : "status_rntrc"
                ) && (
                  <Form.Text className="text-danger">
                    {
                      get(
                        errors,
                        truck
                          ? `${truck}.status_rntrc
`
                          : "status_rntrc"
                      )?.message
                    }
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md={6} lg={4} controlId="formAnttValidate">
              <FloatingLabel
                controlId="formAnttValidade"
                label="Validade RNTRC / ANTT"
              >
                <Form.Control
                  {...register(
                    truck ? `${truck}.dt_validade_antt` : "dt_validade_antt",
                    {
                      required:
                        optional || defaultTruck || vehicle
                          ? false
                          : "O dt_validade_antt é obrigatório",
                    }
                  )}
                  autoComplete="on"
                  size="sm"
                  type="date"
                  disabled={(defaultTruck || vehicle) && !isEdit}
                  className="is-required"
                  defaultValue={
                    defaultTruck
                      ? moment
                          .utc(defaultTruck?.dt_validade_antt)
                          .format("YYYY-MM-DD")
                      : vehicle?.dt_validade_antt &&
                        moment
                          .utc(vehicle?.dt_validade_antt)
                          .format("YYYY-MM-DD")
                  }
                />
                {get(
                  errors,
                  truck ? `${truck}.dt_validade_antt` : "dt_validade_antt"
                ) && (
                  <Form.Text className="text-danger">
                    {
                      get(
                        errors,
                        truck ? `${truck}.dt_validade_antt` : "dt_validade_antt"
                      )?.message
                    }
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>
          </>
        )}
      </Row>
    </>
  );
};

export default VehicleForm;
