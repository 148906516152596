import { Card } from "react-bootstrap";
import styled from "styled-components";

export const CardContainer = styled(Card)`
  background-color: #FFFAFA;
  border: 1px solid var(--color-gray-2);
`

export const TitleContainer = styled.div`
  background-color: var(--color-gray-2);
  padding: 10px;
`


export const PageTitle = styled(Card.Title)`
 color: var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const ItemTitle = styled.span`
  color: var(--color-blue-90);
  font-size: 1rem;
`
export const ItemValue = styled.span`
  font-weight: bold;
  color: ${({ statusColor }) => statusColor === "danger" && "#b94a45"}
`

export const ItemSubtitle = styled.h4`
   font-size: 1rem !important;
    color: var(--color-black) !important;
    white-space: wrap;
    margin-left: 0.2rem;
    margin-bottom: 0;
    text-align: left;
`