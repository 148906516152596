import { Card } from "react-bootstrap";
import { InfoTitle, SectionContainer, TitleContainer } from "./styles";
import PersonalInformationsSection from "./PersonalInformationsSection";
import DocumentsSection from "./DocumentsSection";
import CarrierInformationSection from "./CarrierInformationSection";
import { isCpfOrCnpf } from "../../../../../Shared/Utils/data";

const OwnerInformationsCard = ({
  owner,
  handleShowOwnerImageModal,
  handleShowOwnerProofOfAddressModal,
}) => {
  return (
    <Card className="w-100 h-100 card d-flex flex-column flex-1 p-3">
      <Card.Body className="text-center">
        <SectionContainer className="text-start p-0 mb-3">
          <TitleContainer>
            <InfoTitle className="mb-1">INFORMAÇÕES PESSOAIS</InfoTitle>
          </TitleContainer>
          <PersonalInformationsSection owner={owner} />
        </SectionContainer>
        {owner?.cpf_cnpj && isCpfOrCnpf(owner?.cpf_cnpj) && (
          <SectionContainer className="text-start p-0 mb-3">
            <TitleContainer>
              <InfoTitle className="mb-1">DOCUMENTOS</InfoTitle>
            </TitleContainer>
            <DocumentsSection
              owner={owner}
              handleShowOwnerImageModal={handleShowOwnerImageModal}
              handleShowOwnerProofOfAddressModal={
                handleShowOwnerProofOfAddressModal
              }
            />
          </SectionContainer>
        )}
        <SectionContainer className="text-start p-0 mb-3">
          <TitleContainer>
            <InfoTitle className="mb-1">
              INFORMAÇÕES DA TRANSPORTADORA
            </InfoTitle>
          </TitleContainer>
          <CarrierInformationSection owner={owner} />
        </SectionContainer>
      </Card.Body>
    </Card>
  );
};

export default OwnerInformationsCard;
