import Dashboard from "../../../../../Shared/layout";
import { useDrivers } from "../../../../../hooks/DriverProvider";
import { memo, useCallback, useEffect, useState } from "react";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import AboutDriver from "./AboutDriver";
import { useForm } from "react-hook-form";
import ImageModal from "../../../../../Shared/Components/ImageModal/ImageModal";
import ControlDriverCard from "../components/ControlDriverCard/ControlDriverCard";
import { Column, DriversInformationsRow } from "./styles";
import { driverInitialState } from "../../../../../Shared/Utils/constants";
import { useDriversValidation } from "../../../../../hooks/DriverValidationProvider";
import { Form } from "react-bootstrap";

const fields = {
  isRequired: {
    required: true,
  },
};

const DriverInformations = () => {
  const [reportInconsistency, setReportInconsistency] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const navigate = useNavigate();

  const { driver, isLoading, getDriver, updateDriver } = useDrivers();

  const { driverValidation, getDriverValidation } = useDriversValidation();

  const { id } = useParams();

  const { register, handleSubmit, setValue } = useForm();

  const [changing, setChanging] = useState(driverInitialState);

  const handleInputChange = (fieldName) => {
    setChanging((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const handleReportInconsistencyClick = useCallback(() => {
    setReportInconsistency(!reportInconsistency);
    setChanging(driverInitialState);
  }, [reportInconsistency]);

  useEffect(() => {
    getDriver(id);
    // eslint-disable-next-line no-use-before-define
  }, [getDriver, handleReportInconsistencyClick, id]);

  useEffect(() => {
    getDriverValidation(id, true);
  }, [getDriverValidation, id]);

  const onSubmit = useCallback(
    async (id, data) => {
      const keys = Object.keys(data.campos_erros_cadastro);
      const filteredData = keys.filter((key) => changing[key]);

      const newData = {
        campos_erros_cadastro: {},
      };

      filteredData.forEach((key) => {
        newData.campos_erros_cadastro[key] = data.campos_erros_cadastro[key];
      });

      newData.status_cadastro = "7r";

      updateDriver(id, newData);
      setReportInconsistency(false);
      setChanging(driverInitialState);

      navigate("/motoristas/processos-em-curso");
    },
    [updateDriver, navigate, changing]
  );

  const handleShowModal = useCallback(() => {
    setShowImageModal(true);
  }, []);

  const allFalse = Object.values(changing).every((value) => value === false);

  return (
    <Dashboard title="Informações do motorista">
      {isLoading && <Loading />}
      {!isLoading && driver && (
        <Form onSubmit={handleSubmit((data) => onSubmit(driver?.id, data))}>
          <DriversInformationsRow xs={1} md={2} className="d-flex g-1 mt-1">
            <Column md={3} xl={2}>
              <ControlDriverCard
                driver={driver}
                handleClick={handleReportInconsistencyClick}
                handleInputChange={handleInputChange}
                changing={changing}
                fields={fields}
                reportInconsistency={reportInconsistency}
                reportInconsistencies
                isInformation
                driverValidation={driverValidation}
                handleCancelClick={() => setReportInconsistency(false)}
                allFalse={allFalse}
              />
            </Column>
            <Column md={9} xl={10}>
              <AboutDriver
                driver={driver}
                reportInconsistency={reportInconsistency}
                register={register}
                handleSubmit={handleSubmit}
                handleCancelClick={() => setReportInconsistency(false)}
                onSubmit={onSubmit}
                setValue={setValue}
                handleInputChange={handleInputChange}
                changing={changing}
                fields={fields}
                handleShowModal={handleShowModal}
                allFalse={allFalse}
              />
            </Column>
          </DriversInformationsRow>
        </Form>
      )}
      <ImageModal
        show={showImageModal}
        handleClose={() => setShowImageModal(false)}
        image={driver?.foto_cnh}
        title="CNH"
      />
    </Dashboard>
  );
};

export default memo(DriverInformations);
