import { useMemo } from "react";

import { Spinner } from "react-bootstrap";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { BiError } from "react-icons/bi";

import {
  CardContainer,
  ImageProbabilityTitle,
  PageTitle,
  ProbabilityValue,
  ReactSpeedmeterContainer,
  Speedmeter,
  TitleContainer,
} from "./styles";

import ValidationErrorMessage from "../ValidationErrorMessage/ValidationErrorMessage";
import { convertPercentage } from "../../Utils/data";

const CNHValidationCard = ({
  cnhValidation,
  loadingCnhValidation,
  driverValidation,
}) => {
  const hasError =
    driverValidation &&
    driverValidation?.validacao_cnh?.concluido === true &&
    (driverValidation?.validacao_cnh?.status === "2" ||
      driverValidation?.validacao_cnh?.status === null ||
      driverValidation?.validacao_cnh?.resposta?.message);

  const errorMessage = useMemo(() => {
    if (loadingCnhValidation) {
      return "Realizando a validação da CNH";
    }

    if (hasError && driverValidation?.validacao_cnh?.resposta) {
      if (
        driverValidation?.validacao_cnh?.resposta?.message &&
        driverValidation?.validacao_cnh?.resposta?.message ===
          "Unexpected token E in JSON at position 0"
      ) {
        return "Serviço de DENATRAN indisponível";
      }
      if (
        driverValidation?.validacao_cnh?.resposta?.message &&
        driverValidation?.validacao_cnh?.resposta?.message !==
          "Unexpected token E in JSON at position 0"
      ) {
        return driverValidation?.validacao_cnh?.resposta?.message;
      } else {
        return "Erro na validação da CNH";
      }
    }
  }, [
    driverValidation?.validacao_cnh?.resposta,
    hasError,
    loadingCnhValidation,
  ]);

  return (
    <CardContainer className="h-100 pb-4">
      <TitleContainer>
        <PageTitle className="d-flex justify-content-start mb-0">
          VALIDAÇÃO CNH
        </PageTitle>
      </TitleContainer>
      {cnhValidation &&
        !loadingCnhValidation &&
        cnhValidation?.foto_motorista && (
          <>
            <ReactSpeedmeterContainer className="d-none d-md-flex justify-content-center">
              <Speedmeter
                customSegmentStops={[0, 32, 85, 93, 100]}
                segmentColors={["#b94a45", "#ff0f0f", "#6dd786", "#198754"]}
                currentValueText={`${
                  cnhValidation?.foto_motorista?.similaridade
                    ? convertPercentage(
                        cnhValidation?.foto_motorista?.similaridade
                      )
                    : 0
                }%`}
                value={
                  cnhValidation?.foto_motorista?.similaridade
                    ? convertPercentage(
                        cnhValidation?.foto_motorista?.similaridade
                      )
                    : 0
                }
                maxValue={100}
                labelFontSize="0.5rem"
                needleHeightRatio={0.5}
              />
            </ReactSpeedmeterContainer>
            <ImageProbabilityTitle className="text-center">
              Probabilidade da foto:
              <ProbabilityValue
                className="ms-1"
                value={cnhValidation?.foto_motorista?.similaridade}
              >
                {cnhValidation?.foto_motorista?.similaridade
                  ? convertPercentage(
                      cnhValidation?.foto_motorista?.similaridade
                    )
                  : 0}
                %
              </ProbabilityValue>
            </ImageProbabilityTitle>
            <ImageProbabilityTitle className="text-center">
              <ProbabilityValue
                value={cnhValidation?.foto_motorista?.similaridade}
              >
                {cnhValidation?.foto_motorista?.probabilidade}
              </ProbabilityValue>
            </ImageProbabilityTitle>
          </>
        )}
      {(!cnhValidation ||
        loadingCnhValidation ||
        !cnhValidation?.foto_motorista) && (
        <ValidationErrorMessage
          icon={
            loadingCnhValidation ? (
              <Spinner animation="border" variant="warning" size="sm" />
            ) : hasError ? (
              <BiError size={25} />
            ) : (
              <AiOutlineFolderOpen size={25} />
            )
          }
          label={errorMessage}
          hasError={hasError && !loadingCnhValidation}
          text={
            hasError && "Ocorreu um erro na validação da CNH na Flex consulta!"
          }
        />
      )}
    </CardContainer>
  );
};

export default CNHValidationCard;
