import { LinkCard } from "./styles";

export const ContactCard = ({ icon, target, route }) => {
  return (
    <>
      {route && (
        <LinkCard
          className="d-flex align-items-center justify-content-center"
          to={route}
          target={target}
          title="Enviar whatsapp"
        >
          {icon}
        </LinkCard>
      )}
    </>
  );
};
