import moment from "moment";
import { BsCalendar3 } from "react-icons/bs";
import { Item } from "./styles";

export const ErrorCard = ({ documentExpirationDate }) => {
  return (
    <div>
      <Item className="gap-1">
        <BsCalendar3 size={15} color="#093c5e" />
        <h3>Vencimento do documento:</h3>
        <h4 className="text-danger fw-bold">
          {moment.utc(documentExpirationDate).format("DD/MM/YYYY")}
        </h4>
      </Item>
    </div>
  );
};
