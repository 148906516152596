import { TailSpin } from "react-loader-spinner";
import { IsLoadingCarousel } from "./styles";

export const Loading = () => {
  return (
    <IsLoadingCarousel className="d-flex justify-content-center align-items-center w-100">
      <TailSpin ariaLabel="loading-indicator" color="#093c5e" />
    </IsLoadingCarousel>
  );
};
