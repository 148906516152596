import { Table } from "react-bootstrap";
import { TableContainer, TableTitle, Thead, Title } from "./styles";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import moment from "moment";
import { formatarFrase } from "../../../../Shared/Utils/data";
import { eventStatus, eventTypes } from "../../../../Shared/Utils/constants";

const titleList = [
  { id: 1, label: "Data" },
  { id: 2, label: "Código evento" },
  { id: 3, label: "Código manifesto" },
  { id: 4, label: "Status" },
  { id: 5, label: "Tipo" },
  { id: 6, label: "Observação" },
];

export const Events = ({ events }) => {
  return (
    <TableContainer>
      <Table hover size="sm" className="border rounded mb-0">
        <Thead>
          <TableTitle>
            {titleList.map((item) => (
              <Title
                key={item.id}
                className={`${
                  item.id === titleList[0].id
                    ? "ps-3"
                    : item.id === titleList[titleList.length - 1].id &&
                      "text-center px-2 pe-md-3"
                }`}
              >
                {item.label}
              </Title>
            ))}
          </TableTitle>
        </Thead>
        {Array.isArray(events) && events.length > 0 && (
          <tbody>
            {events.map((event) => (
              <tr key={event.id}>
                <td className="ps-3">
                  {event?.even_dataatual &&
                    moment
                      .utc(event?.even_dataatual)
                      .format("DD/MM/YYYY HH:mm")}
                </td>
                <td>{event?.even_cod}</td>
                <td>{event?.even_codmanif}</td>
                <td>{eventStatus(event?.even_status)}</td>
                <td>{eventTypes(event?.even_codtipoev)}</td>
                <td className="text-center px-2 pe-md-3">
                  {event?.even_obs && formatarFrase(event?.even_obs)}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {(!events || (Array.isArray(events) && events.length === 0)) && (
        <EmptyTableMessage />
      )}
    </TableContainer>
  );
};
