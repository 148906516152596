import React, { useEffect } from "react";
import Dashboard from "../../../../Shared/layout";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormUser from "./FormUser";
import { useUsers } from "../../../../hooks/UserProvider";
import { usePerfis } from "../../../../hooks/PerfilProvider";
import { useCompanies } from "../../../../hooks/CompanyProvider";
import { sortedFantasyNames, sortedNames } from "../../../../Shared/Utils/data";
import { useUnits } from "../../../../hooks/UnitProvider";
import { useScopes } from "../../../../hooks/ScopeProvider";

const fields = {
  nome: {
    required: true,
  },
  email: {
    required: true,
    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  },
  id_perfil: {
    required: true,
    pattern: /^[0-9]+$/,
    validate: (value) => {
      const parsedValue = parseInt(value);
      return (
        parsedValue > 0 ||
        "O campo id_perfil deve conter um número inteiro positivo."
      );
    },
  },
};

export default function AddUsuarios() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { addUser, isSubmitting } = useUsers();
  const { perfis, getPerfis } = usePerfis();
  const { companies, getCompanies } = useCompanies();

  const { unitByIdCompany, getUnitsCompany } = useUnits();
  const { scopes, getScopes } = useScopes();

  useEffect(() => {
    getScopes();
  }, [getScopes]);

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    data.id_perfil = parseInt(data.id_perfil);

    if (
      !data?.id_unidade ||
      !isNaN(data?.id_unidade) ||
      data.id_unidade === "Selecione a Unidade"
    ) {
      addUser(
        {
          ativo: data.ativo,
          email: data.email,
          nome: data.nome,
          id_perfil: data.id_perfil,
        },
        navigate
      );
    } else {
      data.id_unidade = parseInt(data?.id_unidade);

      addUser(data, navigate);
    }
  };

  useEffect(() => {
    getPerfis();
  }, [getPerfis]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  return (
    <Dashboard title="Adicionar novo usuário">
      <FormUser
        handleSubmit={handleSubmit}
        register={register}
        fields={fields}
        errors={errors}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        perfis={sortedNames(perfis)}
        companies={sortedFantasyNames(companies)}
        scopes={scopes}
        units={unitByIdCompany}
        getUnitsByIdCompany={getUnitsCompany}
      />
    </Dashboard>
  );
}
