import { Card } from "react-bootstrap";
import styled from "styled-components";

export const WarningCard = styled.div`
  border: 1px solid red;
  padding: 3px;
  color: red;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const TitleContainer = styled.div`
  background-color: var(--color-gray-2);
  padding: 10px;
`

export const InfoTitle = styled.span`
  color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;

export const CardContainer = styled.div`
  padding: 6px;
  border: 1px solid var(--color-gray-10);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const Container = styled(Card)`
  background-color: #FFFAFA;
  border: ${({ hasborder }) => !hasborder && "none"};
`
