import { HiPlus, HiViewList } from "react-icons/hi";
import { MdOutlineChecklistRtl } from "react-icons/md";
import styled from "styled-components";

export const DriverListIcon = styled(HiViewList)`
margin-bottom: 1rem;
margin-left: auto;
font-size: 2rem
`
export const AddDriverIcon = styled(HiPlus)`
margin-bottom: 1rem;
margin-left: auto;
font-size: 2rem
`
export const OngoingProcessesIcon = styled(MdOutlineChecklistRtl)`
margin-bottom: 1rem;
margin-left: auto;
font-size: 2.3rem
`
