import { Table } from "react-bootstrap";

import { EmptyTableMessage } from "../EmptyTableMessage";

import {
  TableContainer,
  TableTitle,
  Thead,
  TitleItem,
  VehicleConsultTitle,
} from "./styles";
import { formatarFrase } from "../../Utils/data";

export const VehicleAutuacaoTable = ({ detranConsult }) => {
  return (
    <div className="d-flex flex-column gap-1">
      <div className="mb-2 justify-content-center">
        <VehicleConsultTitle className="justify-content-center">
          <span>Infrações em autuação</span>
        </VehicleConsultTitle>
      </div>
      {detranConsult?.data[0]?.infracoes_em_autuacao.length === 0 && (
        <EmptyTableMessage label="Não há registro de restrições" />
      )}
      {Array.isArray(detranConsult?.data[0]?.infracoes_em_autuacao) &&
        detranConsult?.data[0]?.infracoes_em_autuacao.length > 0 && (
          <div>
            <TableContainer>
              <Table hover size="sm" className="border rounded mb-0">
                <Thead>
                  <TableTitle>
                    <TitleItem>Data</TitleItem>
                    <TitleItem>Local</TitleItem>
                    <TitleItem>Descrição</TitleItem>
                  </TableTitle>
                </Thead>
                <tbody>
                  {detranConsult?.data[0]?.infracoes_em_autuacao.map(
                    (event, index) => (
                      <tr key={index}>
                        <td className="ps-3">{event?.normalizado_data}</td>
                        <td>{event?.local_complemento}</td>
                        <td>
                          {event?.descricao
                            ? formatarFrase(event?.descricao)
                            : "--"}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </TableContainer>
          </div>
        )}
    </div>
  );
};
