import { Table } from "react-bootstrap";
import { TableContainer, TableTitle, Thead, Title } from "./styles";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import moment from "moment";
import {
  cnpjCpfFormat,
  formatNameString,
  formatarFrase,
} from "../../../../Shared/Utils/data";

const titleList = [
  { id: 1, label: "Data" },
  { id: 2, label: "Código motorista" },
  { id: 3, label: "CPF" },
  { id: 4, label: "Nome" },
  { id: 5, label: "Observação" },
];

export const Logs = ({ logs }) => {
  return (
    <TableContainer>
      <Table hover size="sm" className="border rounded mb-0">
        <Thead>
          <TableTitle>
            {titleList.map((item) => (
              <Title
                key={item.id}
                className={`${
                  item.id === titleList[0].id
                    ? "ps-3"
                    : item.id === titleList[titleList.length - 1].id &&
                      "text-center px-2 pe-md-3"
                }`}
              >
                {item.label}
              </Title>
            ))}
          </TableTitle>
        </Thead>
        {Array.isArray(logs) && logs.length > 0 && (
          <tbody>
            {logs.map((event) => (
              <tr key={event.id}>
                <td className="ps-3">
                  {event?.created &&
                    moment.utc(event?.created).format("DD/MM/YYYY HH:mm")}
                </td>
                <td>{event?.mot_cod}</td>
                <td>{event?.mot_cpf ? cnpjCpfFormat(event?.mot_cpf) : "--"}</td>
                <td>
                  {event?.mot_nome ? formatNameString(event?.mot_nome) : "--"}
                </td>
                <td className="text-center px-2 pe-md-3">
                  {event?.obs ? formatarFrase(event?.obs) : "--"}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {(!logs || (Array.isArray(logs) && logs.length === 0)) && (
        <EmptyTableMessage />
      )}
    </TableContainer>
  );
};
