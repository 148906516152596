import React, { useEffect, useState, useCallback } from "react";
import Dashboard from "../../../../Shared/layout";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { useUnits } from "../../../../hooks/UnitProvider";
import { UnitList } from "./UnitList";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { useCompanies } from "../../../../hooks/CompanyProvider";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import CompanySelectInput from "./CompanySelectInput";
import Search from "../../../../Shared/Components/Search/Search";
import { Col, Row } from "react-bootstrap";
import { sortedNames } from "../../../../Shared/Utils/data";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import { AiOutlineCloseCircle, AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function Units() {
  const [searchUnit, setSearchUnit] = useState("");
  const [selectedCompany, setSelectedCompany] = useState();

  const navigateTo = useNavigate();

  const {
    units,
    isLoading,
    getUnits,
    getUnit,
    deleteUnit,
    updateUnit,
    getUnitsByIdCompany,
  } = useUnits();

  const { getCompanies, companies } = useCompanies();

  useEffect(() => {
    getUnits();
  }, [getUnits]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const handleSearchChange = (event) => {
    setSearchUnit(event && event !== "" ? event.target.value : "");
  };

  const filteredUnits = units.filter((unit) => {
    return unit.nome.toLowerCase().includes(searchUnit.toLowerCase());
  });

  const handleDeleteUnit = useCallback(
    (unit) => {
      Swal.fire({
        icon: "warning",
        title: "Você tem certeza absoluta?",
        html: `
      <div>
        <p>Essa ação não pode ser desfeita.</p>
        <p>Digite <strong style="background-color: red; color: white">${unit?.nome}</strong> para confirmar.</p>
      </div>
    `,
        input: "text",
        inputLabel: unit?.nome,
        inputPlaceholder: "Digite o nome da unidade",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Eu entendo as consequências, remover unidade",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        focusCancel: true,
        preConfirm: (value) => {
          if (value === unit?.nome) {
            deleteUnit(unit?.id);
            getUnits();
            return true;
          } else {
            Swal.showValidationMessage(
              "O nome da unidade não corresponde. Tente novamente."
            );
            return false;
          }
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
    [deleteUnit, getUnits]
  );

  const handleEditUnit = useCallback(
    async (id) => {
      await getUnit(id);
      navigateTo(`/unidades-atualizar/${id}`);
    },
    [getUnit, navigateTo]
  );

  return (
    <Dashboard title="Unidades">
      <Row className="d-lg-flex aling-items-center justify-content-start mb-1 mt-3">
        <Col md={5} xl={3} className="d-flex">
          <CompanySelectInput
            companies={companies}
            getUnitsByIdCompany={getUnitsByIdCompany}
            getUnits={getUnits}
            setSelectedCompany={setSelectedCompany}
          />
        </Col>
      </Row>
      <Row
        xs={1}
        md={2}
        className="d-lg-flex aling-items-center justify-content-between mb-4 gap-4"
      >
        {localStorage.getItem("unitManagementPermissionToCreate") &&
          localStorage.getItem("unitManagementPermissionToCreate") !==
            "false" && (
            <Col
              md={5}
              lg={4}
              className="d-flex align-items-end justify-content-md-start"
            >
              <ButtonLink
                label="Adicionar unidade"
                link="/unidades-adicionar"
                icon={<AiOutlinePlus />}
              />
            </Col>
          )}

        <Col md={5} lg={4} xl={3}>
          <Search
            label="Pesquisar unidade"
            searchValue={searchUnit}
            handleSearchChange={handleSearchChange}
          />
        </Col>
      </Row>
      {isLoading && <Loading />}
      {!isLoading &&
        localStorage.getItem("unitManagementPermissionToRead") &&
        localStorage.getItem("unitManagementPermissionToRead") !== "false" && (
          <UnitList
            units={sortedNames(filteredUnits)}
            handleDeleteUnit={handleDeleteUnit}
            updateUnit={updateUnit}
            unitManagementPermissionToUpdate={localStorage.getItem(
              "unitManagementPermissionToUpdate"
            )}
            unitManagementPermissionToDelete={localStorage.getItem(
              "unitManagementPermissionToDelete"
            )}
            companies={companies}
            selectedCompany={selectedCompany}
            handleEditUnit={handleEditUnit}
          />
        )}
      {(!localStorage.getItem("unitManagementPermissionToRead") ||
        localStorage.getItem("unitManagementPermissionToRead") === "false") && (
        <EmptyTableMessage
          label="Listagem de unidades não disponível para o seu usuário"
          icon={<AiOutlineCloseCircle />}
        />
      )}
    </Dashboard>
  );
}
