import styled from "styled-components";

export const BorderCard = styled.div`
  border: 1px solid var(--color-gray-10);
  border-radius: 4px;
  padding: 10px;

  gap: 10px;
`

export const ImageTitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
`

export const ButtonImage = styled.button`
  background: linear-gradient(to right, #093c5e, #0071c7);  
  border: none;
  color: white;
  border-radius: 4px;
`

export const StrongItem = styled.strong`

  @media(max-width: 425px){
    max-width: 100px !important;
    word-wrap: break-word;
  }

  @media(max-width: 620px){
    max-width: 250px !important;
  word-wrap: break-word;
  }
  @media(max-width: 720px){
    max-width: 350px !important;
  word-wrap: break-word;
  }
`