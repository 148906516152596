import { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { useMap } from "react-leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";

const LeafletRoutingMachine = ({
  origin,
  destiny,
  currentAddress,
  RealTimeLocationIcon,
  setSelectedDirection,
  setIsChangeRoute,
}) => {
  const [isSelected, setIsSelected] = useState(null);

  const map = useMap();

  useEffect(() => {
    const realPosition = currentAddress ? currentAddress : origin;

    L.marker([realPosition?.lat, realPosition?.lng], {
      icon: RealTimeLocationIcon,
    }).addTo(map);

    const waypoints = [
      L.latLng(origin.lat, origin.lng),
      L.latLng(destiny.lat, destiny.lng),
    ];

    const routesLine = L.Routing.control({
      waypoints,
      serviceUrl: `${process.env.REACT_APP_OSRM}${origin.lat},${origin.lng};${destiny.lat},${destiny.lng}?steps=true`,
      alternatives: true,
      routingOptions: {
        alternatives: true,
        steps: true,
        geometries: "polyline",
        formatInstructions: true,
      },

      router: L.Routing.osrmv1({
        language: "pt-BR",
        profile: "car",
        timeout: 30 * 1000,
        alternatives: true,
        showAlternatives: true,
        routingOptions: {
          alternatives: true,
          steps: true,
        },
      }),
      lineOptions: {
        styles: [
          {
            color:
              isSelected === null
                ? "gray"
                : isSelected === true
                ? "blue"
                : "gray",
            weight: 4,
            opacity: 0.7,
          },
        ],
      },
      routeLine: function (route) {
        var line = L.Routing.line(route, {
          addWaypoints: false,
          useZoomParameter: false,
          draggableWaypoints: false,
          styles: [
            {
              color:
                isSelected === null
                  ? "gray"
                  : isSelected === true
                  ? "blue"
                  : "gray",
              weight: 4,
              opacity: 0.7,
            },
          ],
        });
        line.eachLayer(function (l) {
          l.on("click", function (e) {
            setSelectedDirection(route);
            setIsSelected(true);
            setIsChangeRoute(true);
          });
        });

        return line;
      },
      routeWhileDragging: false,
      geocoder: L.Control.Geocoder.nominatim(),
      addWaypoints: false,
      marker: false,
      createMarker: function () {
        return null;
      },
      draggableWaypoints: false,
      // showAlternatives: true,
      instructions: false,
      show: true,
    });

    routesLine
      .on("routesfound", function (e) {
        const routes = e.routes;

        routes.forEach((route, index) => {
          const line = L.Routing.line(route, {
            addWaypoints: false,
            extendToWaypoints: false,
            routeWhileDragging: false,
            autoRoute: true,
            useZoomParameter: false,
            draggableWaypoints: false,
            styles: [
              {
                color:
                  isSelected === null
                    ? "gray"
                    : isSelected === true
                    ? "blue"
                    : "gray",
                weight: 4,
                opacity: 1,
              },
            ],
          }).addTo(map);

          line.eachLayer(function (l) {
            l.on("click", function (e) {
              setSelectedDirection(route);
              setIsSelected(false);
              setIsChangeRoute(true);
            });
          });
        });
      })
      .addTo(map);
  }, [
    RealTimeLocationIcon,
    map,
    origin,
    destiny,
    currentAddress,
    setSelectedDirection,
    isSelected,
    setIsChangeRoute,
  ]);

  return null;
};
export default LeafletRoutingMachine;
