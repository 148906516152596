import styled from "styled-components";

export const CoverIcon = styled.div`
  background-image: ${props => `url(${props.icon})`};
  background-size: cover;
  background-position: center center;
  width: ${props => props.size ? props.size : "15px"};
  height: ${props => props.size ? props.size : "15px"}
`

export const SubtitleLabel = styled.span`
  font-size: 0.85rem;
  color: var(--color-gray-15);
`

export const TitleContainer = styled.div`
background-color: ${({ statuscolor }) => statuscolor === "secondary" ?
    "#dadadb" : statuscolor === "danger" ?
      "#cb1313" : statuscolor === "primary" ? "#0071c7" : statuscolor === "success" && "#07b141"};
    
padding: 5px;
border-radius: 2px;
margin-bottom: 2px;

  h1{
    font-size: 0.875rem;
    margin-bottom: 0;
    font-weight: bold;

    color:  ${({ statuscolor }) => statuscolor === "secondary" ?
    "#000" : "white"};
 
  }
`