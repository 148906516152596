import { Row } from "react-bootstrap";
import { AddressField } from "./AddressField";
import { PersonalInformations } from "./PersonalInformations";
import { DocumentForm } from "./DocumentsForm";
import UploadOwnerImage from "./UploadOwnerImage";
import UploadOwnerProodOfAddress from "./UploadOwnerProodOfAddress";
import UploadImageGuideCard from "../../../../../../Shared/Components/UploadImageGuideCard";
import { PageTitle } from "./PageTitle";

const OwnersPersonalInformations = ({
  owner,
  ownerByCpfCnpj,
  getOwnerByCpfCnpj,
  register,
  setValue,
  isSubmitting,
  errors,
  isCpf,
  trigger,
  setIsSat,
  isSAT,
  handleShowOwnerImageModal,
  handleShowOwnerProofOfAddressModal,
  setCurrentShowOwnerImage,
  setCurrentOwnerProofOfAddress,
}) => {
  return (
    <>
      <Row xs={1} className="g-2">
        <PersonalInformations
          ownerByCpfCnpj={ownerByCpfCnpj}
          getOwnerByCpfCnpj={getOwnerByCpfCnpj}
          register={register}
          errors={errors}
          owner={owner}
          isCpf={isCpf}
          setValue={setValue}
          trigger={trigger}
          setIsSat={setIsSat}
          isSAT={isSAT}
        />
        {isCpf && (
          <div className="d-flex flex-column gap-2 mb-2">
            <div className="mt-2">
              <PageTitle label="Documentos" />
            </div>
            <DocumentForm
              register={register}
              ownerByCpfCnpj={ownerByCpfCnpj}
              isSubmitting={isSubmitting}
              errors={errors}
              owner={owner}
              setValue={setValue}
              isCpf={isCpf}
              isSAT={isSAT}
            />
            <div className="d-flex flex-column flex-md-row gap-3">
              <div className="d-flex">
                <UploadOwnerImage
                  setValue={setValue}
                  register={register}
                  errors={errors}
                  width="10.4rem"
                  height="10.4rem"
                  align="start"
                  owner={owner}
                  isCpf={isCpf}
                  setCurrentImage={setCurrentShowOwnerImage}
                  handleShowModal={handleShowOwnerImageModal}
                />
              </div>
              <div className="d-flex">
                <UploadOwnerProodOfAddress
                  setValue={setValue}
                  register={register}
                  errors={errors}
                  width="10.4rem"
                  height="10.4rem"
                  align="start"
                  owner={owner}
                  isCpf={isCpf}
                  setCurrentImage={setCurrentOwnerProofOfAddress}
                  handleShowModal={handleShowOwnerProofOfAddressModal}
                />
              </div>
              <div className="w-100">
                <UploadImageGuideCard formats="Formatos aceitos .jpeg , .png ou .pdf" />
              </div>
            </div>
          </div>
        )}
        <AddressField
          setValue={setValue}
          ownerByCpfCnpj={ownerByCpfCnpj}
          register={register}
          errors={errors}
          owner={owner}
          isSAT={isSAT}
        />
      </Row>
    </>
  );
};

export default OwnersPersonalInformations;
