import { Modal } from "react-bootstrap";
import ButtonLink from "../ButtonLink/ButtonLink";
import { Title } from "./styles";

export const ErrorModal = ({ show, handleClose, error }) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Title>Análise do Cadastro: Avisos e Observações</Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        {error?.label}
      </Modal.Body>
      <div className="d-flex mb-4 mt-4 aling-items-center justify-content-center text-center">
        {error?.children}
      </div>
      <Modal.Footer>
        {/* {error?.route ? (
          <ButtonLink
            link={error?.route}
            label="Detalhes"
            buttoncolor="primary"
          />
        ) : (
          <ButtonLink
            label="Detalhes"
            buttoncolor="primary"
            handleClick={error?.handleClick}
          />
        )} */}
        <ButtonLink
          handleClick={handleClose}
          label="Fechar"
          buttoncolor="gray"
          fontSize="0.7rem"
        />
      </Modal.Footer>
    </Modal>
  );
};
