import { Button, FormControl, Row } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import styled from "styled-components";

export const ModalRow = styled(Row)`
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const DocumentImage = styled.div`
  width: 10.4rem;
  height: 10.4rem;
  background-color: var(--color-gray-11);
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UploadImageButton = styled.div`
  background-color: transparent !important;
  width: ${(props) => (props.width ? props.width : "7rem")};
  padding: 3px;
  border-left: 2px solid red;
  border-top: 1px solid var(--color-gray-15);
  border-right: 1px solid var(--color-gray-15);
  border-bottom: 1px solid var(--color-gray-15);

  font-size: 0.8rem;
  transition: transform 300ms;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    color: black;
  }
`;

export const FormControlInput = styled(FormControl)`
  font-size: 0.7rem;
  display: flex;
`;

export const DeleteIconButton = styled(FaTrashAlt)`
  color: var(--color-gray-15);
  font-size: 0.9rem;
  margin-top: 4px;
`;

export const DeleteCnhButton = styled(Button)`
  transition: transform 300ms;
  background-color: transparent;
  border: none;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
    background-color: transparent;
    border: none;
  }

  &:disabled {
    background-color: transparent;
    border: none;
  }

  label {
    color: var(--color-gray-15);
    font-size: 0.9rem;

    max-width: 145px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const PdfIcon = styled.div`
  color: var(--color-gray-10);
  width: 100%;
  height: 100%;
  padding: 48.2px;
`;
