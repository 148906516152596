import { useCallback, useEffect, useState } from "react";

import moment from "moment";
import { IoIosArrowForward } from "react-icons/io";
import { BsPerson } from "react-icons/bs";
import { BiIdCard } from "react-icons/bi";

import { Container, InfoItem, InfoTitle, TitleContainer } from "./styles";

import { useOwners } from "../../../../../../../hooks/OwnerProvider";

import ButtonLink from "../../../../../../../Shared/Components/ButtonLink/ButtonLink";
import {
  cnpjCpfFormat,
  formatNameString,
} from "../../../../../../../Shared/Utils/data";

export const VehicleInformationCard = ({
  vehicle,
  handleOpenVehicleTab,
  consult,
  title,
  index,
}) => {
  const [tenantOwner, setTenantOwner] = useState();
  const [ownerInformations, setOwnerInformations] = useState();

  const { getOwner } = useOwners();

  const getVehicleOwners = useCallback(async () => {
    if (vehicle?.proprietario_id) {
      const response = await getOwner(vehicle?.proprietario_id);
      setTenantOwner(response);
    }
    if (vehicle?.proprietario_arrendatario_id) {
      const response = await getOwner(vehicle?.proprietario_arrendatario_id);
      setOwnerInformations(response);
    }
  }, [
    getOwner,
    vehicle?.proprietario_id,
    vehicle?.proprietario_arrendatario_id,
  ]);

  useEffect(() => {
    getVehicleOwners();
  }, [getVehicleOwners]);

  const isExpired =
    vehicle?.dt_vencimento_documento &&
    new Date(vehicle?.dt_vencimento_documento) < new Date();

  return (
    <Container className="w-100 d-flex flex-column h-100 rounded">
      <div>
        <TitleContainer className="mb-3 d-flex">
          <InfoTitle className="text-start">{title.toUpperCase()}</InfoTitle>
        </TitleContainer>
      </div>

      <div className="d-flex gap-1 mb-2 justify-content-between p-2">
        <InfoItem>Placa:</InfoItem>
        <InfoItem className="fw-bold" status="placa">
          {vehicle?.placa}
        </InfoItem>
      </div>

      {vehicle?.proprietario_arrendatario_id && (
        <>
          <div className="p-2">
            <InfoItem className="fw-bold">Proprietário arrendatário:</InfoItem>
          </div>
          <div className="d-flex flex-column gap-2 ">
            {ownerInformations?.nome && (
              <div className="gap-1 d-flex align-items-center mt-2 justify-content-between p-2">
                <div className="d-flex align-items-center gap-1">
                  <BsPerson size={15} />
                  <InfoItem titlestatus>Nome:</InfoItem>
                </div>
                <InfoItem
                  className="fw-bold d-flex justify-content-end"
                  lassName="fw-bold"
                  content="true"
                  status
                >
                  formatNameString(ownerInformations?.nome)
                </InfoItem>
              </div>
            )}
            <div className="gap-1 d-flex align-items-center justify-content-between mb-2 p-2">
              <div className="d-flex align-items-center gap-1">
                <BiIdCard size={17} color="#093c5e" />
                <InfoItem titlestatus>CPF/CNPJ:</InfoItem>
              </div>
              <InfoItem className="fw-bold d-flex justify-content-end">
                {ownerInformations?.cpf_cnpj
                  ? cnpjCpfFormat(ownerInformations?.cpf_cnpj)
                  : "--"}
              </InfoItem>
            </div>
          </div>
        </>
      )}

      {vehicle?.proprietario_id && (
        <>
          <div className="p-2">
            <InfoItem className="fw-bold">Proprietário certificado</InfoItem>
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-1 mb-2 justify-content-between p-2">
              <div className="d-flex align-items-center gap-1">
                <BsPerson size={15} />
                <InfoItem titlestatus>Nome:</InfoItem>
              </div>
              <InfoItem className="fw-bold d-flex justify-content-end" status>
                {tenantOwner?.nome ? formatNameString(tenantOwner?.nome) : "--"}
              </InfoItem>
            </div>
            <div className="gap-1  d-flex align-items-center mb-2 justify-content-between p-2">
              <div className="d-flex align-items-center gap-1">
                <BiIdCard size={17} color="#093c5e" />
                <InfoItem titlestatus>CPF / CNPJ:</InfoItem>
              </div>
              <InfoItem className="fw-bold" status>
                {tenantOwner?.cpf_cnpj
                  ? cnpjCpfFormat(tenantOwner?.cpf_cnpj)
                  : "--"}
              </InfoItem>
            </div>
          </div>
        </>
      )}

      <div className="d-flex gap-1 mb-2 justify-content-between p-2">
        <InfoItem>Vencimento do documento:</InfoItem>
        <InfoItem
          className={`fw-bold ${isExpired ? "text-danger" : "text-success"}`}
        >
          {vehicle?.dt_vencimento_documento
            ? moment.utc(vehicle?.dt_vencimento_documento).format("DD/MM/YYYY")
            : "--"}
        </InfoItem>
      </div>

      <div className="d-flex gap-1 mb-2 justify-content-between p-2">
        <InfoItem>Situação do cadastro:</InfoItem>
        <InfoItem
          status={
            consult?.data && consult?.data.length > 0
              ? consult?.data[0]?.situacao_cadastral
              : "Requer atenção"
          }
          className="fw-bold text-end"
        >
          {consult?.data && consult?.data.length > 0
            ? consult?.data[0]?.situacao_cadastral
            : "Requer atenção"}
        </InfoItem>
      </div>

      <div className="d-flex justify-content-end mt-auto p-2">
        <ButtonLink
          secondIcon={<IoIosArrowForward />}
          label="Detalhes"
          handleClick={() => handleOpenVehicleTab(index)}
          fontSize="0.7rem"
          buttoncolor="whiteColor"
          color="#5f5f5f"
        />
      </div>
    </Container>
  );
};
