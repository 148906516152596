import styled from "styled-components";

export const MainDetailsContainer = styled.div`
/* 
@media (max-width: 768px){
  overflow-x: hidden;
  max-height: 50vh;
} */
  
`



