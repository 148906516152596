import { useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";

import { BiBuilding, BiIdCard, BiLandscape } from "react-icons/bi";
import { BsCalendar3, BsGenderAmbiguous } from "react-icons/bs";
import { FaCity, FaFemale, FaMale, FaMapMarkerAlt } from "react-icons/fa";
import { AiOutlineNumber } from "react-icons/ai";

import {
  MdLandscape,
  MdMapsHomeWork,
  MdOutlineRealEstateAgent,
} from "react-icons/md";

import { formatCEP, formatRG } from "../../../../../../../Shared/Utils/data";
import { nationalitiesOptions } from "../../../../../../../Shared/Utils/constants";
import { allNationalities } from "../../../../../../../Shared/Utils/nationalities";

import { Item } from "./styles";
import { ItalicH4 } from "../../../DriverInformations/styles";

const PersonalInformationSection = ({ driver }) => {
  const nationality = useCallback(
    (value) =>
      nationalitiesOptions.find((n) => n.value === value.toString())?.label,
    []
  );

  const codNacionality = useMemo(() => {
    let codNacionalityValue =
      String(driver?.cod_nacionalidade).length === 2
        ? "0" + driver?.cod_nacionalidade
        : driver?.cod_nacionalidade;

    const value = allNationalities.find(
      (item) => item?.value === codNacionalityValue
    );

    return value;
  }, [driver?.cod_nacionalidade]);

  return (
    <Row xl={2} className="d-flex p-2">
      <Col xl={7}>
        <Item className="gap-1 ">
          <BiIdCard size={17} color="#093c5e" />
          <h3>RG:</h3>
          <h4>
            {driver?.rg_motorista && formatRG(driver?.rg_motorista) !== ""
              ? driver?.rg_motorista
              : "--"}
          </h4>
        </Item>

        <Item className="gap-1 mt-4">
          <BiBuilding size={17} color="#093c5e" />
          <h3>Órgão expedidor do RG:</h3>
          <h4>{driver?.orgao_rg ? driver?.orgao_rg : "--"}</h4>
        </Item>

        <Item className="gap-1 mt-4">
          <BsCalendar3 size={14} color="#093c5e" />
          <h3>Data de emissão da RG:</h3>
          <h4>{driver?.dt_emissao_rg ? driver?.dt_emissao_rg : "--"}</h4>
        </Item>

        <Item className="gap-1 mt-4">
          <BsGenderAmbiguous size={17} color="#093c5e" />
          <h3>Gênero:</h3>
          <h4>{driver?.sexo === "M" ? "Masculino" : "Feminino"}</h4>
        </Item>

        <Item className="gap-1 mt-4">
          <BsCalendar3 size={14} color="#093c5e" />
          <h3>Nascimento:</h3>
          <h4>{driver?.dt_nascimento ? driver?.dt_nascimento : "--"}</h4>
        </Item>

        <Item className="gap-1 mt-4">
          <FaMale size={15} color="#093c5e" />
          <h3>Nome do pai:</h3>
          <ItalicH4 notIncluded={driver?.nome_pai ? false : true}>
            {driver?.nome_pai && driver?.nome_pai !== ""
              ? driver?.nome_pai
              : "(Não consta)"}
          </ItalicH4>
        </Item>

        <Item className="gap-1 mt-4">
          <FaFemale size={15} color="#093c5e" />
          <h3>Nome mãe:</h3>
          <h4>
            {driver?.nome_mae && driver?.nome_mae !== ""
              ? driver?.nome_mae
              : "--"}
          </h4>
        </Item>
      </Col>
      <Col xl={5}>
        <Item className="gap-1 mt-4">
          <BiLandscape size={15} color="#093c5e" />
          <h3>Nacionalidade:</h3>
          <h4>
            {driver?.nacionalidade ? nationality(driver?.nacionalidade) : "--"}
          </h4>
        </Item>
        <Item className="gap-1 mt-4">
          <MdLandscape size={15} color="#093c5e" />
          <h3>País de origem:</h3>
          <h4>{driver?.cod_nacionalidade ? codNacionality.label : "--"}</h4>
        </Item>

        <Item className="gap-1 mt-4">
          <AiOutlineNumber size={15} color="#093c5e" />
          <h3>CEP:</h3>
          <h4>{driver?.cep ? formatCEP(driver?.cep) : "--"}</h4>
        </Item>

        <Item className="gap-1 mt-4">
          <FaCity size={15} color="#093c5e" />
          <h3>Cidade:</h3>
          <h4>{driver?.cidade ? driver?.cidade : "--"}</h4>
        </Item>

        <Item className="gap-1 mt-4">
          <MdOutlineRealEstateAgent size={15} color="#093c5e" />
          <h3>Estado:</h3>
          <h4>{driver?.uf ? driver?.uf : "--"}</h4>
        </Item>

        <Item className="gap-1 mt-4">
          <MdMapsHomeWork size={15} color="#093c5e" />
          <h3>Bairro:</h3>
          <h4>{driver?.bairro ? driver?.bairro : "--"}</h4>
        </Item>

        <Item className="gap-1 mt-4">
          <FaMapMarkerAlt size={15} color="#093c5e" />
          <h3>Endereço:</h3>
          <h4>{`${driver?.endereco}, ${driver?.numero}`}</h4>
        </Item>
      </Col>
    </Row>
  );
};

export default PersonalInformationSection;
