import moment from "moment";
import { Table } from "react-bootstrap";
import { BiIdCard } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import {
  FaRegDotCircleIcon,
  ProcessesTableTitle,
  TableItem,
  TableTitle,
} from "./styles";

import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { loaderOrderStatus } from "../../../../Shared/Utils/constants";

const TravelLoadOrderList = ({ travelLoadOrderList, getLoadOrder }) => {
  const navigate = useNavigate();

  const handleNavigate = async (token) => {
    await getLoadOrder(token);
    navigate(`/gestao-viagens/ordem-de-carregamento/${token}`);
  };

  return (
    <div className="mt-2">
      <Table hover size="sm" className="border rounded mb-0">
        <thead>
          <ProcessesTableTitle>
            <TableTitle className="ps-3">Data abertura</TableTitle>
            <TableTitle className="ps-3">Status</TableTitle>
            <TableTitle className="text-start">
              N° ordem carregamento
            </TableTitle>
            <TableTitle className="text-start">Nome do motorista</TableTitle>
            <TableTitle className="text-center">CPF do motorista</TableTitle>
            <TableTitle className="text-center px-2 pe-md-3">Ações</TableTitle>
          </ProcessesTableTitle>
        </thead>
        <tbody>
          {travelLoadOrderList.map((travelLoadOrder) => (
            <tr key={travelLoadOrder?.id}>
              <TableItem className="px-3">
                {moment.utc(travelLoadOrder?.data).format("DD/MM/YYYY HH:mm")}
              </TableItem>
              <TableItem className="px-3">
                <div className="d-flex justify-content-start align-items-center gap-1">
                  <FaRegDotCircleIcon status={travelLoadOrder?.status} />

                  {loaderOrderStatus(travelLoadOrder?.status)}
                </div>
              </TableItem>
              <TableItem className="px-3 text-start">
                {travelLoadOrder?.id_ordem_carregamento}
              </TableItem>
              <TableItem className="px-3 text-start">
                {travelLoadOrder?.nome_mot}
              </TableItem>
              <TableItem className="px-3 text-center">
                {travelLoadOrder?.cpf_mot}
              </TableItem>
              <TableItem className="px-3 text-center">
                <div className="d-flex justify-content-center">
                  <ButtonLink
                    icon={<BiIdCard size={20} color="#FFF" />}
                    handleClick={() => handleNavigate(travelLoadOrder.token)}
                    buttoncolor="primary"
                    className="p-1"
                    title="OC detalhada"
                  />
                </div>
              </TableItem>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TravelLoadOrderList;
