import React, { useMemo } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-routing-machine";
import LeafletRoutingMachine from "./LeafletRoutingMachine";
import { Subtitle } from "../Subtitle";
import newIcon from "../../../../Assets/Images/iconTruck.png";
import locationPin from "../../../../Assets/Images/locationPin.svg";
import originPinLocation from "../../../../Assets/Images/originPinLocation.png";

const OriginIcon = L.icon({
  iconUrl: originPinLocation,
  iconSize: [15, 15],
  iconAnchor: [7, 15],
  popupAnchor: [0, -15],
});

const DestinyIcon = L.icon({
  iconUrl: locationPin,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
  popupAnchor: [0, -30],
});

const RealTimeLocationIcon = L.icon({
  iconUrl: newIcon,
  iconSize: [20, 20],
  iconAnchor: [10, 20],
  popupAnchor: [0, -20],
});

export const ChoosenRouteMap = ({
  origin,
  destiny,
  currentAddress,
  setSelectedDirection,
  setIsChangeRoute,
  isChangeRoute,
}) => {
  const defaultProps = useMemo(() => {
    return {
      center: currentAddress
        ? currentAddress
        : origin
        ? origin
        : {
            lat: -15.616498333333334,
            lng: -56.078129999999994,
          },
    };
  }, [origin, currentAddress]);

  return (
    <div>
      <MapContainer
        center={defaultProps?.center}
        style={{ height: "55vh", width: "100%" }}
        zoom={7}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={origin} icon={OriginIcon} />
        <Marker position={destiny} icon={DestinyIcon} />
        <LeafletRoutingMachine
          origin={origin}
          destiny={destiny}
          currentAddress={currentAddress}
          RealTimeLocationIcon={RealTimeLocationIcon}
          setSelectedDirection={setSelectedDirection}
          setIsChangeRoute={setIsChangeRoute}
          isChangeRoute={isChangeRoute}
        />
      </MapContainer>
      <div className="d-flex gap-2">
        <Subtitle label="Coleta" icon={originPinLocation} size="10px" />
        <Subtitle label="Destino" icon={locationPin} size="18px" />
        <Subtitle label="Localização atual" icon={newIcon} size="17px" />
      </div>
    </div>
  );
};
