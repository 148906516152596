import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { PathCard } from "./PathCard";
import { Column, LoadOrderRow } from "./styles";
import { DriverInformation } from "./DriverInformation";
import { TravelsInformations } from "./TravelsInformations";
import Dashboard from "../../../../Shared/layout";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import { useDrivers } from "../../../../hooks/DriverProvider";
import { useTravels } from "../../../../hooks/TravelProvider";
import { useOwners } from "../../../../hooks/OwnerProvider";
import { useVehicles } from "../../../../hooks/VehicleProvider";

const DetailedLoadingOrder = () => {
  const [originAddress, setOriginAddress] = useState({});
  const [destinyAddress, setDestinationAddress] = useState({});
  const [distanceTimePredictions, setDistanceTimePredictions] = useState({});

  const { token } = useParams();

  const { driver, drivers, getDriver, getDrivers } = useDrivers();
  const { owner, owners, getOwner, getOwners } = useOwners();
  const { vehicle, vehicles, getVehicle, getVehicles } = useVehicles();

  const {
    loadOrder,
    isLoading,
    loadOrderAddressLoading,
    loadOrderRoute,
    loadOrderRouteLoading,
    loadOrderByGeometryAddressLoading,
    relationshipBetweenAttributes,
    isLoadingRelationshipBetweenAttributes,
    getLoadOrderAddress,
    getLoadOrder,
    editLoadOrder,
    getLoadOrderRoutes,
    getLoadOrderByGeometryAddress,
    getRelationshipBetweenAttributes,
  } = useTravels();

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    getLoadOrder(token);
  }, [getLoadOrder, token]);

  useEffect(() => {
    loadOrder && getRelationshipBetweenAttributes(loadOrder?.id);
  }, [getRelationshipBetweenAttributes, loadOrder]);

  useEffect(() => {
    loadOrder && getLoadOrderRoutes(loadOrder?.id);
  }, [getLoadOrderRoutes, loadOrder]);

  useEffect(() => {
    getDriver(loadOrder?.motorista_id);
    getOwner(loadOrder?.proprietario_id);
    getVehicle(loadOrder?.veiculo_id);
  }, [
    getDriver,
    getOwner,
    getVehicle,
    loadOrder?.motorista_id,
    loadOrder?.proprietario_id,
    loadOrder?.veiculo_id,
  ]);

  useEffect(() => {
    getDrivers();
    getOwners();
    getVehicles();
  }, [getDrivers, getOwners, getVehicles]);

  const handleOriginAddress = useCallback(async () => {
    if (loadOrder?.latitude_coleta && loadOrder?.longitude_coleta) {
      setOriginAddress({
        lat: loadOrder?.latitude_coleta,
        lng: loadOrder?.longitude_coleta,
      });
      return;
    }

    if (loadOrder?.nome_cidade_coleta) {
      const position = await getLoadOrderAddress(
        `${loadOrder?.nome_cidade_coleta},${loadOrder?.uf_coleta},Brasil`
      );

      setOriginAddress({
        lat: position.length > 0 ? position[0]?.lat : position?.lat,
        lng: position.length > 0 ? position[0]?.lon : position?.lon,
      });
    } else {
      setOriginAddress(null);
    }
  }, [getLoadOrderAddress, loadOrder]);

  useEffect(() => {
    handleOriginAddress();
  }, [handleOriginAddress, loadOrder]);

  const handleDestinyAddress = useCallback(async () => {
    if (loadOrder?.latitude_entrega && loadOrder?.longitude_entrega) {
      setDestinationAddress({
        lat: loadOrder?.latitude_entrega,
        lng: loadOrder?.longitude_entrega,
      });
      return;
    }

    if (loadOrder?.nome_cidade_entrega) {
      const position = await getLoadOrderAddress(
        `${loadOrder?.nome_cidade_entrega},${loadOrder?.uf_entrega},Brasil`
      );

      setDestinationAddress({
        lat: position.length > 0 ? position[0]?.lat : position?.lat,
        lng: position.length > 0 ? position[0]?.lon : position?.lon,
      });
    } else {
      setDestinationAddress(null);
    }
  }, [getLoadOrderAddress, loadOrder]);

  useEffect(() => {
    handleDestinyAddress();
  }, [handleDestinyAddress, loadOrder]);

  const handleDistance = useCallback(async () => {
    if (originAddress && destinyAddress) {
      const address = {
        currentLocation: originAddress,
        destinyLocation: destinyAddress,
      };

      const response = await getLoadOrderByGeometryAddress(address);

      response?.routes[0]?.legs[0] &&
        setDistanceTimePredictions(response?.routes[0]?.legs[0]);
    }
    if (
      originAddress?.lng &&
      originAddress?.lat &&
      destinyAddress?.lat &&
      destinyAddress?.lng
    ) {
      const address = {
        currentLocation: originAddress,
        destinyLocation: destinyAddress,
      };

      const response = await getLoadOrderByGeometryAddress(address);

      response?.routes[0]?.legs[0] &&
        setDistanceTimePredictions(response?.routes[0]?.legs[0]);
    }
  }, [destinyAddress, getLoadOrderByGeometryAddress, originAddress]);

  useEffect(() => {
    handleDistance();
  }, [handleDistance]);

  return (
    <Dashboard title="Ordem de carregamento detalhada">
      {(isLoading || isLoadingRelationshipBetweenAttributes) && <Loading />}
      {!isLoading && loadOrder && !isLoadingRelationshipBetweenAttributes && (
        <LoadOrderRow
          className="g-1 mt-1"
          data-testid="detailed-loading-order-container"
        >
          <Column
            xl={
              loadOrder.status === "0" ||
              loadOrder.status === "1" ||
              loadOrder.status === "2" ||
              loadOrder.status === "9" ||
              loadOrder.status === "60" ||
              loadOrder.status === "8"
                ? 6
                : 9
            }
          >
            <TravelsInformations
              loadOrder={loadOrder}
              editLoadOrder={editLoadOrder}
            />
          </Column>
          <Column
            xl={
              loadOrder.status === "0" ||
              loadOrder.status === "1" ||
              loadOrder.status === "2" ||
              loadOrder.status === "9" ||
              loadOrder.status === "60" ||
              loadOrder.status === "8"
                ? 2
                : 3
            }
          >
            <DriverInformation
              loadOrder={loadOrder}
              driver={driver}
              owner={owner}
              vehicle={vehicle}
              drivers={drivers}
              owners={owners}
              vehicles={vehicles}
              getOwner={getOwner}
              getDriver={getDriver}
              getVehicle={getVehicle}
              handleSubmit={handleSubmit}
              setValue={setValue}
              isSubmitting={isSubmitting}
              relationshipBetweenAttributes={relationshipBetweenAttributes}
              editLoadOrder={editLoadOrder}
              reset={reset}
            />
          </Column>
          {(loadOrder.status === "0" ||
            loadOrder.status === "1" ||
            loadOrder.status === "2" ||
            loadOrder.status === "9" ||
            loadOrder.status === "60" ||
            loadOrder.status === "8") && (
            <Column xl={4}>
              <PathCard
                loadOrder={loadOrder}
                originAddress={originAddress}
                destinyAddress={destinyAddress}
                loadOrderAddressLoading={loadOrderAddressLoading}
                loadOrderByGeometryAddressLoading={
                  loadOrderByGeometryAddressLoading
                }
                loadOrderRoute={loadOrderRoute}
                loadOrderRouteLoading={loadOrderRouteLoading}
                distanceTimePredictions={distanceTimePredictions}
              />
            </Column>
          )}
        </LoadOrderRow>
      )}
    </Dashboard>
  );
};

export default memo(DetailedLoadingOrder);
