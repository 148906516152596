import styled from "styled-components";
import EmptyImage from "../../../../../../Assets/Images/user.png"
import { Button } from "react-bootstrap";

export const MonitoringDriverThead = styled.thead`
border-bottom: 1px solid black;

th{
  font-size: 0.8rem;
  color: black;
}
`

const BaseTableItem = styled.td`
   font-size: 0.7rem;
`

export const TableItem = styled(BaseTableItem)`
  font-size: 0.7rem;
`


const getColorBasedOnStatus = (status) => {
  switch (status) {
    case "0":
      return "#535353";
    case "1":
      return "#2778c4";
    case "2":
      return "#212529";
    case "3":
      return "#f27474a6";
    case "4":
      return "#cb1313";
    case "5":
      return "#ffc107";
    case "6":
      return "#f27474";
    case "7":
      return "#f27474";
    case "8":
      return "#198754";
    default:
      return "#000000";
  }
};

export const StatusTableItem = styled(BaseTableItem)`
  color: ${(props) => getColorBasedOnStatus(props.status)};
`;

export const DriverImageCover = styled.div`
  background-size: cover;
  background-position: center center;
  width: 1rem;
  height: 1rem;
  background-image: ${props => props.coverImage ? `url(${props?.coverImage})` : `url(${EmptyImage})`}
`;

export const TableContainer = styled.div`
  overflow-y: auto;
`

export const SelectLoadOrderByDriverButton = styled(Button)`
  background-color: transparent;
  cursor: pointer;
  border: none;
  font-size: 0.7rem;
  color: var(--color-gray-15);
  padding: 0;

  &:hover{
    background-color: transparent;
  cursor: pointer;
  border: none;
  color: var(--color-gray-15);
  padding: 0;
  }
`