import { useCallback, useMemo, useState } from "react";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
import { Form, InputGroup, Ratio, Spinner } from "react-bootstrap";

import {
  AlertIcon,
  CoverImage,
  DeleteCnhButton,
  DeleteIconButton,
  ErrorMessage,
  FormControlInput,
  FormGroupBorder,
  ImageTitle,
  PdfLoadingContainer,
  ShowModalButton,
  UploadImageButton,
} from "./styles";

import EmptyImage from "../../../../../../../Assets/Images/EmptyImage.png";
import { pdfConverter } from "../../../../../../../Shared/Utils/pdfConverter";

const UploadVehicleDocImage = ({
  errors,
  vehicle,
  className,
  width,
  height,
  paddingBottom,
  setValue,
  hideTitle,
  hideInput,
  handleShowModal,
  align,
  register,
  OCRImage,
  isEdit,
}) => {
  const [uploadImage, setUploadImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [imageModal, setImageModal] = useState(null);

  const [uploadLogoImage, setUploadLogoImage] = useState(
    vehicle ? vehicle?.foto_documento : null
  );

  const [isChange, setIsChange] = useState(false);

  const deleteImage = () => {
    setUploadImage(null);
    setUploadLogoImage(null);
  };

  const handleFileChange = useCallback(
    (event) => {
      setErrorMessage("");
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const arrayBuffer = e.target.result;

          const base64 = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );

          setUploadImage(file);

          if (file.type === "application/pdf") {
            setValue("foto_documento", "data:application/pdf;base64," + base64);

            pdfConverter(file, setUploadLogoImage, setIsLoading);
          } else if (file.type === "image/jpeg" || file.type === "image/png") {
            setUploadLogoImage(URL.createObjectURL(file));

            setValue(
              "foto_documento",
              "data:" + file.type + ";base64," + base64
            );
          } else {
            setErrorMessage("Arquivo não suportado");
          }
        };

        setIsChange(true);

        reader.readAsArrayBuffer(file);
      }
    },
    [setValue]
  );

  const coverImage = useMemo(() => {
    if (OCRImage && !isChange) {
      return OCRImage.src;
    }

    if (vehicle?.foto_documento?.url && !isChange) {
      setImageModal(vehicle?.foto_documento);
      return vehicle?.foto_documento?.url;
    }

    if (uploadLogoImage && isChange) {
      setIsChange(false);
      setImageModal(uploadLogoImage);
      vehicle?.foto_documento(uploadLogoImage);
      return uploadLogoImage;
    }

    if (!vehicle?.foto_documento?.url && !uploadLogoImage) {
      setIsChange(false);
      setImageModal(EmptyImage);
      return EmptyImage;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle?.foto_documento, uploadLogoImage, OCRImage]);

  return (
    <div className="d-flex h-100">
      <FormGroupBorder
        className={`d-flex flex-column ${className} ${
          uploadImage && "justify-content-start gap-3"
        }`}
      >
        {!hideTitle && (
          <ImageTitle className="text-start mb-1">Foto CRLV</ImageTitle>
        )}
        {isLoading ? (
          <PdfLoadingContainer width={width} height={height}>
            <Spinner animation="border" role="status" variant="light" />
          </PdfLoadingContainer>
        ) : (
          <>
            {coverImage === vehicle?.foto_documento?.url &&
            vehicle?.foto_documento?.type === "application/pdf" ? (
              <div
                style={{ width: 166, height: 166 }}
                data-testid="achando-essa-div"
              >
                <Ratio aspectRatio="1x1">
                  <embed
                    type="application/pdf"
                    src={vehicle?.foto_documento?.url}
                  />
                </Ratio>
              </div>
            ) : (
              <CoverImage
                width={width}
                height={height}
                paddingBottom={paddingBottom}
                image={coverImage}
              />
            )}
          </>
        )}
        {handleShowModal && coverImage !== EmptyImage && (
          <div className={`mt-auto ${align ? "text-start" : "text-end"}`}>
            <ShowModalButton
              onClick={() => handleShowModal(imageModal)}
              className="px-0"
            >
              <div className="d-flex align-items-center gap-1">
                <MdOutlineFilterCenterFocus />
                Visualizar documento
              </div>
            </ShowModalButton>
          </div>
        )}
        {errorMessage === "Arquivo não suportado" && (
          <div className="d-flex align-items-center gap-1 mt-1">
            <AlertIcon size={10} />
            <ErrorMessage>Arquivo não suportado</ErrorMessage>
          </div>
        )}
        {(uploadImage || OCRImage) && (
          <div className="d-flex align-items-center">
            <DeleteCnhButton
              type="button"
              className="d-flex align-items-center p-0 gap-1"
              onClick={deleteImage}
            >
              <label className="mt-1">
                {OCRImage ? OCRImage?.name : uploadImage?.name}
              </label>
              {!OCRImage && <DeleteIconButton />}
            </DeleteCnhButton>
          </div>
        )}
        {!uploadImage && !hideInput && !OCRImage && (
          <InputGroup className=" mt-1">
            <UploadImageButton
              className={`rounded d-flex justify-content-center align-items-center ${
                vehicle && !isEdit && "d-none"
              }`}
              width={width}
            >
              <label htmlFor="foto_documento" className="custom-file-upload">
                Selecionar documento
              </label>
            </UploadImageButton>
            <FormControlInput
              {...register("foto_documento", {
                required: vehicle ? false : "A foto da CNH é obrigatória",
              })}
              id="foto_documento"
              type="file"
              disabled={vehicle && !isEdit}
              onChange={handleFileChange}
              className="d-none"
            />
          </InputGroup>
        )}
        {errors?.foto_documento?.type === "required" && (
          <Form.Text className="text-danger">
            O documento é obrigatório
          </Form.Text>
        )}
      </FormGroupBorder>
    </div>
  );
};

export default UploadVehicleDocImage;
