import styled from "styled-components";

export const Container = styled.div`
max-height: 35vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 375px){
  max-height: 50vh;
 }

 @media(min-width: 425px){
  max-height: 55vh;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }
  
 
 @media(min-width: 1440px){
  max-height: 65vh;
 }
  
`;