import React, { useCallback, useEffect, useMemo } from "react";

import { IoIosArrowBack } from "react-icons/io";
import { BreadcrumbLink, ComeBackButton, LinkButton, Title } from "./styles";
import { useLocation, useParams } from "react-router-dom";
import { useRoutes } from "../../../hooks/RoutesProvider";
import { routesPaths } from "../../Utils/routesPaths";

const BreadcrumbComponent = ({ backLabel }) => {
  const location = useLocation();
  const { id, token, company } = useParams();
  const { routes, getRoutes } = useRoutes();

  const replaceVariablesInPath = useCallback(
    (path) => {
      let newPath = path;

      if (id) {
        newPath = newPath.replace(":id", id);
      }

      if (token) {
        newPath = newPath.replace(":token", token);
      }

      if (company) {
        newPath = newPath.replace(":company", company);
      }

      return newPath;
    },
    [company, id, token]
  );

  const breadcrumbTitle = useMemo(() => {
    const matchedRoute = routesPaths.find(
      (route) => replaceVariablesInPath(route.path) === location.pathname
    );
    return matchedRoute ? matchedRoute.title : "";
  }, [location.pathname, replaceVariablesInPath]);

  useEffect(() => {
    getRoutes(location?.pathname, id, token, company);
  }, [location, getRoutes, id, token, company]);

  return (
    <div className="px-1">
      {routes && routes.length > 1 && (
        <div className="d-flex">
          <LinkButton
            to={routes[routes.length - 2]?.path}
            size="sm"
            className="d-flex align-items-center gap-1"
          >
            <IoIosArrowBack />
            <ComeBackButton>{backLabel ? backLabel : "Voltar"}</ComeBackButton>
          </LinkButton>
        </div>
      )}
      {
        <Title className="color-blue-dark">
          {breadcrumbTitle === "Início"
            ? "Monitoramento dos veículos"
            : breadcrumbTitle}
        </Title>
      }

      {routes && routes.length > 1 && (
        <div className="mb-0 gap-1 d-md-flex">
          {routes.map((route, index, arr) => (
            <BreadcrumbLink
              key={route?.path}
              to={route.path}
              disabled={arr.length - 1 === index}
              className="mb-0 pb-0"
            >
              <span>{route.title}</span>
            </BreadcrumbLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default BreadcrumbComponent;
