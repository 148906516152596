import styled from "styled-components";
import { FaRegFolderOpen, FaClipboard } from "react-icons/fa";
import { Button, Card, Form, Modal } from "react-bootstrap";

export const Container = styled(Card)`
  background-color: #FFFAFA;
  border: ${({ hasborder }) => hasborder && "none"}
`;

export const Item = styled.div`
  padding: 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const TitleContainer = styled.div`
  background-color: var(--color-gray-2);
  padding: 10px;
`

export const InfoTitle = styled.span`
  color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;

export const ItemTitle = styled.h3`
   font-size: 1rem !important;
    color: ${({ theme }) => theme ? "#093c5e" : "#093c5e"};
    white-space: wrap;
    margin-bottom: 0;
`

export const ItemSubtitle = styled.h4`
   font-size: 1rem !important;
    color: var(--color-black) !important;
    white-space: wrap;
    margin-left: 0.2rem;
    margin-bottom: 0;
    text-align: left;
`

export const FolderIcon = styled(FaRegFolderOpen)`
  color: var(--color-gray-11);
`;
export const ClipboardIcon = styled(FaClipboard)`
  color: var(--color-gray-11);
`;
export const SubtitleIcon = styled.span`
  font-size: 1rem;
  color: red;
`;

export const Title = styled.h1`
  font-size: 1rem;
  color: var(--color-blue-90);
  font-weight: bold;
`;

export const PageTitle = styled(Card.Title)`
  color: var(--color-gray-12);
  font-size: 1rem;
`;

export const CoverImage = styled.div`
  width: 166px;
  height: 166px;
  padding-bottom: 10px;

  > img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ImageTitle = styled.p`
  font-size: 0.8rem;
  color: var(--color-gray-11);
`;

export const ShowModalButton = styled(Button)`
  border: none;
  background-color: transparent;
  color: var(--color-gray-15);
  font-size: 0.8rem;
  transition: transform 300ms;

  &:hover {
    border: none;
    background-color: transparent;
    color: var(--color-gray-15);
    transform: scale(1.05);
    z-index: 1;
  }

  &:active,
  &:focus {
    border: none;
    background-color: transparent !important;
    color: var(--color-gray-15);
  }
`;

export const H4Status = styled.h4`
margin-bottom:0;
font-size: 1rem !important;

  color: ${({ status }) =>
    status === "REGULAR" || status === "ATIVA" || status === "ATIVO" || status === "Regular" || status === "Ativa" || status === "Ativo"
      ? "#07b141"
      : "red"} !important;
`;

export const BorderForm = styled(Form.Group)`
  border: 0.875px solid var(--color-gray-10);
  border-radius: 6px;
  padding: 10px;
`;

export const TableContainer = styled.div`
  overflow: auto;
  max-height: 20vh;

 @media(min-width: 576px){
  max-height: 25vh;
 }

 @media(min-width: 1440px){
  max-height: 30vh;
 }`
  ;
export const Thead = styled.thead`
position: sticky;
 top: 0;
 z-index: 1;
 background-color: white;
`;

export const TableTitle = styled.tr`

th {
  font-size: 0.875rem;
  font-weight: bold;
  
}
`;

export const TitleItem = styled.th`
font-size: 0.65rem;
color: var(--color-gray-15);
font-weight: 700;

@media(min-width: 1440px){
  font-size: 1rem !important;
}
`;
export const VehicleConsultTitle = styled.div`
  background-color: var(--color-blue-90);
  border-radius: 4px;
  padding: 5px;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
`;


export const DetranStrongItem = styled.strong`
  font-size: 1rem;
`;

export const ModalTitle = styled(Modal.Title)`
  font-size: 1.2rem;
`;

export const TableModalContainer = styled.div`
  overflow: auto;
  max-height: 25vh;

`;

export const TheadModal = styled.thead`
position: sticky;
 top: 0;
 z-index: 1;
 background-color: white;
`;

export const TableModalTitle = styled.tr`

th {
  font-size: 0.875rem;
  font-weight: bold;
  
}`;

export const TitleModal = styled.th`
font-size: 0.65rem;
color: var(--color-gray-15);
font-weight: 700;

@media(min-width: 1440px){
  font-size: 1rem !important;
}
`
