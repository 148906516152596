import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { AddressApi } from "../Shared/Utils/Request";

export const AddressContext = createContext({
  addresses: [],
  isLoading: false,
  getAddresses: () => {},
});

export const useAddresses = () => {
  const context = useContext(AddressContext);

  if (!context) {
    throw new Error("useAddresses must be within AddressProvider");
  }

  return context;
};

export const AddressProvider = ({ children }) => {
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAddresses = useCallback(async (input) => {
    setIsLoading(true);
    try {
      const response = await AddressApi.get(`/${input}/json`);
      setIsLoading(false);
      setAddresses(response?.data);
      return response?.data;
    } catch {
      setAddresses(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const providerValue = useMemo(
    () => ({
      addresses,
      isLoading,
      getAddresses,
    }),
    [addresses, isLoading, getAddresses]
  );

  return (
    <AddressContext.Provider value={providerValue}>
      {children}
    </AddressContext.Provider>
  );
};
