import React, { useEffect } from "react";
import Dashboard from "../../../../Shared/layout";
import { useForm } from "react-hook-form";
import FormPerfil from "./FormPerfil";
import { useScopes } from "../../../../hooks/ScopeProvider";
import { usePerfis } from "../../../../hooks/PerfilProvider";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../../../Shared/Components/Loading/Loading";

const UpdatePerfil = () => {
  const { id } = useParams();

  const { perfil, isLoading, updatePerfil, getPerfil, isSubmitting } =
    usePerfis();

  const { scopes, getScopes } = useScopes();

  const isEditing = true;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getPerfil(id);
  }, [getPerfil, id]);

  useEffect(() => {
    getScopes();
  }, [getScopes]);

  const navigate = useNavigate();

  const onSubmit = async (id, data) => {
    data.escopo = parseInt(data.escopo);

    updatePerfil(id, data, navigate);
  };

  return (
    <>
      <Dashboard title="Atualizar perfil">
        {isLoading && <Loading />}
        {!isLoading && perfil && (
          <FormPerfil
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            isEditing={isEditing}
            scopes={scopes}
            perfil={perfil}
          />
        )}
      </Dashboard>
    </>
  );
};

export default UpdatePerfil;
