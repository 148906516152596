import styled from "styled-components";

export const Item = styled.div`
 position: relative;

h2{
  font-size: 1rem;
  color: var(--color-gray-12);
  margin-bottom: 0;
  white-space: nowrap;
}

span{
  font-weight: bold;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  flex:  1;
  white-space:wrap;

text-transform:capitalize;
}



`