import { Fragment } from "react";
import { Line, Step, Steps } from "./styles";

export const MultiSteps = ({ size, currentStep = 1 }) => {
  return (
    <div>
      <Steps>
        {Array.from({ length: size }, (_, i) => i + 1).map((step, index) => {
          return (
            <Fragment key={step}>
              <div className="d-flex align-items-center">
                <Step
                  activeStep={currentStep >= step}
                  isRotete={currentStep === step}
                >
                  {step}
                </Step>
              </div>
              {index !== size - 1 && <Line />}
            </Fragment>
          );
        })}
      </Steps>
    </div>
  );
};
