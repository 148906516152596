import { Spinner } from "react-bootstrap";
import { useTravels } from "../../../../../hooks/TravelProvider";
import { SearchLoaderOrder } from "../SearchLoaderOrder/SearchLoaderOrder";
import TravelLoadOrderList from "../TravelLoadOrderList";
import { sortByDateDescending } from "../../../../../Shared/Utils/data";

import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";
import { useEffect, useState } from "react";
import TablePagination from "../../../../../Shared/Components/PaginationTable/PaginationTable";
import { TabContainer } from "./styles";

export const CompleteLoadOrders = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    completeLoadOrders,
    completeLoadOrdersTotalPages,
    isLoading,
    getLoadOrder,
    getCompleteLoadOrders,
  } = useTravels();

  const handlePageChange = (event) => {
    setCurrentPage(event);
  };

  useEffect(() => {
    getCompleteLoadOrders(currentPage);
  }, [currentPage, getCompleteLoadOrders]);

  return (
    <div className="d-flex flex-column h-100">
      <TabContainer>
        <SearchLoaderOrder getList={getCompleteLoadOrders} />
        {isLoading && (
          <div className="d-flex justify-content-center">
            <Spinner size="sm" animation="border" variant="primary" />
          </div>
        )}
        {!isLoading &&
          Array.isArray(completeLoadOrders) &&
          completeLoadOrders.length > 0 && (
            <>
              <TravelLoadOrderList
                getLoadOrder={getLoadOrder}
                travelLoadOrderList={sortByDateDescending(completeLoadOrders)}
              />
            </>
          )}
        {(!completeLoadOrders || completeLoadOrders.length === 0) &&
          !isLoading && (
            <EmptyTableMessage label="Não há registro de ordens de carregamentos liberadas" />
          )}
      </TabContainer>
      {!isLoading &&
        Array.isArray(completeLoadOrders) &&
        completeLoadOrders.length > 0 && (
          <div className="mt-2 ps-2">
            <TablePagination
              currentPage={currentPage}
              totalPages={completeLoadOrdersTotalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
    </div>
  );
};
