import { memo, useCallback, useEffect } from "react";
import Dashboard from "../../../../../Shared/layout";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { OccurencesForm } from "../OccurencesForm/OccurencesForm";
import { useOccurrences } from "../../../../../hooks/OccurrenceTypeProvider";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";

const EditOccurrence = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    occurrence,
    isLoadingOccurrencesTypes,
    getOccurrence,
    editOccurrence,
  } = useOccurrences();

  useEffect(() => {
    getOccurrence(id);
  }, [getOccurrence, id]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = useCallback(
    (data) => {
      data.nome = data.nome === "" ? occurrence?.nome : data.nome;

      editOccurrence(occurrence?.id, data, navigate);
    },
    [editOccurrence, navigate, occurrence]
  );

  return (
    <Dashboard title="Editar tipo de ocorrência">
      {isLoadingOccurrencesTypes && <Loading />}
      {!isLoadingOccurrencesTypes && occurrence && (
        <OccurencesForm
          register={register}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          errors={errors}
          isSubmitting={isSubmitting}
          currentOccurence={occurrence}
        />
      )}
    </Dashboard>
  );
};

export default memo(EditOccurrence);
