import { useForm } from "react-hook-form";
import Dashboard from "../../../../../Shared/layout";
import { memo, useCallback, useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import OwnersPersonalInformations from "../components/OwnerForms/OwnersPersonalInformation";
import { CarrierRegistration } from "../components/OwnerForms/CarrierRegistration";
import { useNavigate, useParams } from "react-router-dom";
import { useOwners } from "../../../../../hooks/OwnerProvider";

import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { removerCaracteresNaoAlfabeticos } from "../../../../../Shared/Utils/data";
import OwnerTypes from "../components/OwnerForms/OwnersTypes";
import ImageModal from "../../../../../Shared/Components/ImageModal/ImageModal";
import ControlOwnerCard from "../components/ControlOwnerCard/ControlOwnerCard";
import { Column, UpdateOwnerRow } from "./styles";
import { PageTitle } from "../components/OwnerForms/PageTitle";

const UpdateOwner = () => {
  const { id } = useParams();
  const [isCpf, setIsCpf] = useState(true);
  const [showOwnerImageModal, setShowOwnerImageModal] = useState(false);

  const [showOwnerProofOfAddressModal, setShowOwnerProofOfAddressModal] =
    useState(false);

  const { owner, isLoading, getOwner, updateOwner } = useOwners();

  const navigate = useNavigate();

  useEffect(() => {
    getOwner(id);
  }, [getOwner, id]);

  useEffect(() => {
    if (owner) {
      if (owner?.tipo_antt === "1") {
        setIsCpf(true);
      } else {
        setIsCpf(false);
      }
    }
  }, [owner]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data?.foto_proprietario && data?.foto_proprietario.length === 0) {
      delete data.foto_proprietario;
    }

    if (
      data?.foto_comprovante_endereco &&
      data?.foto_comprovante_endereco.length === 0
    ) {
      delete data.foto_comprovante_endereco;
    }

    data.numero_celular =
      data.numero_celular && data.numero_celular.replace(/[^0-9]/g, "");

    data.cep = data.cep && data.cep.replace(/\D+/g, "");
    data.cpf_cnpj = data.cpf_cnpj && data.cpf_cnpj.replace(/\D/g, "");
    data.bloqueado_adm = data?.bloqueado_adm === true ? "S" : "N";

    data.nome = data?.nome && removerCaracteresNaoAlfabeticos(data?.nome);

    for (const key in data) {
      if (data[key] === "" || data[key] === undefined) {
        delete data[key];
      }
    }

    updateOwner(id, data, navigate);
  };

  const handleShowOwnerImageModal = useCallback(() => {
    setShowOwnerImageModal(true);
  }, []);

  const handleShowOwnerProofOfAddressModal = useCallback(() => {
    setShowOwnerProofOfAddressModal(true);
  }, []);

  const [currentShowOwnerImage, setCurrentShowOwnerImage] = useState(
    owner?.foto_proprietario
  );

  const [currentOwnerProofOfAddress, setCurrentOwnerProofOfAddress] = useState(
    `url(${owner?.foto_comprovante_endereco})`
  );

  return (
    <Dashboard title="Atualizar proprietário">
      {isLoading && <Loading />}
      {!isLoading && owner && (
        <Form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="owner-update-form"
          className="mt-2"
        >
          <UpdateOwnerRow
            xs={1}
            sm={2}
            lg={3}
            className="d-flex justify-content-between g-1"
          >
            <Column sm={4} lg={3} xl={2}>
              <ControlOwnerCard owner={owner} />
            </Column>
            <Column sm={8} lg={9} xl={10}>
              <Card className="text-center w-100 min-h-100">
                <Card.Body className="text-start">
                  <PageTitle label="Informações cadastrais" />
                  <OwnerTypes
                    register={register}
                    errors={errors}
                    setIsCpf={setIsCpf}
                    owner={owner}
                  />
                  <OwnersPersonalInformations
                    register={register}
                    setValue={setValue}
                    owner={owner}
                    errors={errors}
                    isCpf={isCpf}
                    handleShowOwnerImageModal={handleShowOwnerImageModal}
                    handleShowOwnerProofOfAddressModal={
                      handleShowOwnerProofOfAddressModal
                    }
                    setCurrentShowOwnerImage={setCurrentShowOwnerImage}
                    setCurrentOwnerProofOfAddress={
                      setCurrentOwnerProofOfAddress
                    }
                  />
                </Card.Body>
                <Card.Body className="text-start">
                  <CarrierRegistration
                    register={register}
                    setValue={setValue}
                    isSubmitting={isLoading}
                    owner={owner}
                    isCpf={isCpf}
                    errors={errors}
                  />
                </Card.Body>
              </Card>
            </Column>
          </UpdateOwnerRow>
        </Form>
      )}
      <ImageModal
        show={showOwnerImageModal}
        handleClose={() => setShowOwnerImageModal(false)}
        ownerImage={owner}
        title="CNH"
        image={currentShowOwnerImage}
      />
      <ImageModal
        show={showOwnerProofOfAddressModal}
        handleClose={() => setShowOwnerProofOfAddressModal(false)}
        ownerProofOfAddress={owner}
        title="Comprovante de residência"
        image={currentOwnerProofOfAddress}
      />
    </Dashboard>
  );
};

export default memo(UpdateOwner);
