import { useMemo } from "react";
import ReportInconsistencyInput from "./ReportInconsistencyInput";
import { formatString } from "../../../../../Shared/Utils/data";
import { ReportedInconsistencyContainer } from "./styles";

const ReportedInconsistencies = ({ driver }) => {
  const inconsistencies = useMemo(() => {
    if (driver?.campos_erros_cadastro) {
      return JSON.parse(driver?.campos_erros_cadastro);
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver?.campos_erros_cadastro, driver?.id]);

  const inconsistenciesKeys = inconsistencies && Object.keys(inconsistencies);

  return (
    <ReportedInconsistencyContainer className="w-100">
      {inconsistenciesKeys.map(
        (key) =>
          inconsistencies[key] !== "" && (
            <ReportInconsistencyInput
              key={key}
              label={formatString(key)}
              value={inconsistencies[key]}
              registerItem={`campos_erros_cadastro.${key}`}
            />
          )
      )}
    </ReportedInconsistencyContainer>
  );
};

export default ReportedInconsistencies;
