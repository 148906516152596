import styled from "styled-components";

export const NameSimilarity = styled.div`
background-color: transparent;
border: 1px solid var(--color-blue-60);

padding: 5px;


border-radius: 1000px;

span {

font-size: 0.7rem;

}

`

export const NameSimilarityItem = styled.span`
  font-size: 0.7rem;
  color: ${props => props.item && props.item > 0.7 ? "#6dd786" : "#cc0000"} !important;
`