import { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

import { TableItem } from "./styles";
import ActionsOptions from "./ActionsOptions";

import { registrationStatus } from "../../../../../Shared/Utils/constants";
import {
  formatCPF,
  formatNameString,
  percentageDriverStatus,
} from "../../../../../Shared/Utils/data";

import { useDrivers } from "../../../../../hooks/DriverProvider";
import { useDriversValidation } from "../../../../../hooks/DriverValidationProvider";

export const TableList = ({ driver }) => {
  const [driverConsult, setDriverConsult] = useState([]);

  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();

  const {
    tempRegister,
    getDriverConsults,
    deleteDriver,
    addVehiclesAndOwners,
    deleteDriverFromTemporaryTable,
    updateDriversByStatus,
    postDriverConsults,
    getTempRegister,
  } = useDrivers();

  const {
    getDriverValidation,
    judicialProcess,
    getJudicialProcessConsult,
    postJudicialProcessConsult,
  } = useDriversValidation();

  const onDelete = useCallback(
    (id) => {
      deleteDriver(id, navigate, true);
      deleteDriverFromTemporaryTable(id);
    },
    [deleteDriver, deleteDriverFromTemporaryTable, navigate]
  );

  const handleConsultCPFBtn = useCallback(
    async (id) => {
      try {
        Swal.fire({
          icon: "info",
          title: "Atenção!",
          text: "Aguardando validação da biometria, CNH e consulta à Receita Federal. Pode demorar alguns minutos",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        const result = await getTempRegister(id);

        Promise.all([postDriverConsults(result?.token)])
          .then(() => {
            getDriverConsults(undefined, id);
            getDriverValidation(id);
            postJudicialProcessConsult(id);
          })
          .catch((error) => console.error(`Erro nas promises ${error}`));
      } catch (error) {
        console.error({ error });
      }
    },
    [
      getDriverConsults,
      getDriverValidation,
      getTempRegister,
      postDriverConsults,
      postJudicialProcessConsult,
    ]
  );

  const handleChangeStatus = useCallback(
    async (driver, stateValue) => {
      const temporary = await getTempRegister(driver?.id);
      if (stateValue === "") {
        return Swal.fire({
          icon: "warning",
          title: "Atenção!",
          text: "Escolha uma ação",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      }

      if (stateValue === "delete") {
        return Swal.fire({
          icon: "warning",
          title: "Remover pré-cadastro",
          text: "Deseja mesmo remover esse pré-cadastro do motorista? Atenção, essa ação é irreversível",
          showCancelButton: true,
          confirmButtonColor: "#dc3545",
          cancelButtonColor: "#6c757d",
          confirmButtonText: "Sim, confirmo",
          cancelButtonText: "Cancelar",
          focusCancel: true,
          preConfirm: () => {
            onDelete(driver.id);
          },
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading(),
        });
      }

      if (stateValue === "2r") {
        handleConsultCPFBtn(driver?.id);
      }

      const data = {
        ativo:
          driver?.status_cadastro === "5r" || driver?.status_cadastro === "6r"
            ? true
            : false,
        status_cadastro: stateValue,
      };

      if (temporary?.token && (stateValue === "5r" || stateValue === "6r")) {
        await addVehiclesAndOwners(temporary, data, navigate, driver);
        if (tempRegister?.id_proprietario) {
          await updateDriversByStatus(driver.id, data, navigate, true, 1);
        }
      } else {
        updateDriversByStatus(driver.id, data, navigate, true, 1);
      }
    },
    [
      addVehiclesAndOwners,
      getTempRegister,
      handleConsultCPFBtn,
      navigate,
      onDelete,
      tempRegister,
      updateDriversByStatus,
    ]
  );

  const handleGetDriverConsult = useCallback(async () => {
    const consults = await getDriverConsults(undefined, driver?.id);
    setDriverConsult(consults);
  }, [driver?.id, getDriverConsults]);

  useEffect(() => {
    handleGetDriverConsult();
  }, [handleGetDriverConsult]);

  const isloadingConsult = useMemo(() => {
    if (driverConsult && driverConsult?.length > 0) {
      if (
        driverConsult.filter(
          (consult) =>
            !consult?.concluido ||
            consult.processamento_antecedentes_criminais ||
            consult.processamento_mandados_prisao
        ).length > 0
      ) {
        return true;
      }
      return false;
    }
    return false;
  }, [driverConsult]);

  useEffect(() => {
    if (
      isloadingConsult ||
      driver?.status_cadastro === "2r" ||
      (driver?.status_cadastro === "5r" &&
        driver?.processos_cadastro_concluido === false)
    ) {
      const interval = setInterval(() => {
        handleGetDriverConsult();
        getDriverValidation(driver?.id);
        getDriverConsults(tempRegister?.id);
        getJudicialProcessConsult(driver?.id);
      }, 20000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    driver?.id,
    driver?.processos_cadastro_concluido,
    driver?.status_cadastro,
    driverConsult,
    getDriverConsults,
    getDriverValidation,
    getJudicialProcessConsult,
    handleGetDriverConsult,
    isloadingConsult,
    tempRegister?.id,
  ]);

  return (
    <tr>
      <TableItem className="px-3">
        {driver?.nome && formatNameString(driver?.nome)}
      </TableItem>
      <TableItem>{formatCPF(driver?.cpf)}</TableItem>
      <TableItem
        status={
          driver?.status_cadastro === "1r"
            ? driver?.bloqueio_sat
            : driver?.status_cadastro
        }
        bold={driver?.processos_cadastro_concluido}
        title={
          driver?.status_cadastro === "7r"
            ? "Informar inconsistências"
            : `${registrationStatus(
                driver?.status_cadastro,
                driver?.processos_cadastro_concluido,
                driver?.bloqueio_sat
              )}`
        }
        className={`${
          (driver?.status_cadastro !== "1r" &&
            driverConsult &&
            driverConsult.length > 0 &&
            driverConsult.find(
              (consult) => consult.id === driver?.id && consult.status === false
            )) ||
          ((driver?.status_cadastro === "2r" ||
            (driver?.status_cadastro === "5r" &&
              driver?.processos_cadastro_concluido === false)) &&
            "gap-1 align-items-center fw-bold text-body-secondary")
        }`}
      >
        {driver?.status_cadastro === "2r" ||
        (driver?.status_cadastro === "5r" &&
          driver?.processos_cadastro_concluido === false) ||
        isloadingConsult ? (
          <div className="d-flex align-items-center gap-1">
            <Spinner animation="border" variant="warning" size="sm" />
            {` Em análise - ${percentageDriverStatus(
              {
                biometria: driver?.status_biometria,
                cnh: driver?.status_cnh,
                viagem: driver?.status_viagens,
              },
              driver?.processos_cadastro_concluido,
              driverConsult,
              judicialProcess?.concluido
            )}`}
          </div>
        ) : (
          <>
            {registrationStatus(
              driver?.status_cadastro,
              driver?.processos_cadastro_concluido,
              driver?.bloqueio_sat
            )}
          </>
        )}
      </TableItem>

      <ActionsOptions
        driver={driver}
        handleSubmit={handleSubmit}
        register={register}
        handleChangeStatus={handleChangeStatus}
        listStatusConsult={driverConsult}
        isloadingConsult={isloadingConsult}
      />
    </tr>
  );
};
