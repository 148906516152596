import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import moment from "moment";
import { ErrorCard } from "../Pages/Dashboard/RegistrationManagement/Drivers/TemporaryDriver/Tabs/Information/ErrorCard";
import { ErrorMessageCard } from "../Shared/Components/VehicleFederalDetails/ErrorMessageCard";
import { isCpfOrCnpf } from "../Shared/Utils/data";
import { DivergentNamesCard } from "../Shared/Components/DivergentNamesCard";
import { DivergentStatesCard } from "../Shared/Components/DivergentStatesCard";
import { ErrorCodeMessageCard } from "../Shared/Components/VehicleFederalDetails/ErrorCodeMessageCard";
import BiometricValidationCard from "../Shared/Components/BiometricValidationCard/BiometricValidationCard";
import CNHValidationCard from "../Shared/Components/CNHValidationCard/CNHValidationCard";
import { StatusSat } from "../Shared/Components/InfosSat/StatusSat";
import { RegistrationStatusCard } from "../Pages/Dashboard/RegistrationManagement/Drivers/TemporaryDriver/Tabs/Information/RegistrationStatusCard";
import { ValidationsErrorList } from "../Pages/Dashboard/RegistrationManagement/Drivers/FlexValidation/ValidationsErrosList";
import { AlertMessage } from "../Shared/Components/AlertMessage";
import { DriverTypeAlertCard } from "../Shared/Components/DriverTypeAlertCard";

export const ErrosPrivider = createContext({
  errors: [],
  isLoading: false,
  getErrors: () => {},
});

export const useErrors = () => {
  const context = useContext(ErrosPrivider);

  if (!context) {
    throw new Error("useErrors must be within ErrorsProvider");
  }

  return context;
};

export const ErrorsProvider = ({ children }) => {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getErrors = useCallback(
    async (
      tempRegister,
      driverConsults,
      driver,
      handleNewConsult,
      biometricValidation,
      cnhValidation,
      driverValidation,
      loadingConsult,
      driverSat,
      handleSelectTabAndCloseModal,
      flexValidation,
      historyErros
    ) => {
      setIsLoading(true);

      // Erro da data de validade do documento do veículo

      const temporaryVehicle =
        tempRegister?.dados_formulario_veiculo &&
        JSON.parse(tempRegister?.dados_formulario_veiculo);

      const documentVehicleExpirationDate =
        temporaryVehicle?.dados_ocr?.data?.valor &&
        moment(temporaryVehicle?.dados_ocr?.data?.valor, "DD/MM/YYYY")
          .add(1, "years")
          .toDate();

      const isVehicleExpired =
        !documentVehicleExpirationDate ||
        (documentVehicleExpirationDate &&
          new Date(documentVehicleExpirationDate) < new Date());

      // Erro anexos proprietário

      const ownerInformations =
        tempRegister?.anexos_proprietario &&
        JSON.parse(tempRegister?.anexos_proprietario);

      const ownerTypeError =
        ownerInformations?.tipoProprietario &&
        ownerInformations?.tipoProprietario === 1 &&
        !isCpfOrCnpf(temporaryVehicle?.dados_ocr?.cpf_cnpj?.valor);

      // Erro de validade do documento das carretas

      let expiredTrucksArray = [];
      let trucksErros = [];
      let truckCnpjError = [];
      let truckcpfCnpjConsultError = [];
      let truckAnttConsultErrorList = [];
      let ownerTruckErrorList = [];
      let truckSituation = [];

      const trucksConsult =
        driverConsults &&
        driverConsults.filter(
          (consult) =>
            consult.posicao === "CARRETA1" ||
            consult.posicao === "CARRETA2" ||
            consult.posicao === "CARRETA3" ||
            consult.posicao === "CARRETA4"
        );

      const temporaryTrucks =
        tempRegister?.dados_formulario_carretas &&
        JSON.parse(tempRegister?.dados_formulario_carretas);

      temporaryTrucks &&
        Object.keys(temporaryTrucks).forEach((truck, index) => {
          const consultItem = trucksConsult.find(
            (consultItem) => consultItem?.posicao === `CARRETA${index + 1}`
          );

          const documentTruckExpirationDate =
            temporaryTrucks[truck]?.dados_ocr?.data?.valor &&
            moment(temporaryTrucks[truck]?.dados_ocr?.data?.valor, "DD/MM/YYYY")
              .add(1, "years")
              .toDate();

          const isTruckExpired =
            temporaryTrucks[truck]?.dados_ocr &&
            (!documentTruckExpirationDate ||
              (documentTruckExpirationDate &&
                new Date(documentTruckExpirationDate) < new Date()));

          if (isTruckExpired) {
            const truckExpiredError = {
              status: isTruckExpired,
              children: (
                <ErrorCard
                  documentExpirationDate={documentTruckExpirationDate}
                />
              ),

              label: `A data de validade do documento da ${
                consultItem && consultItem?.posicao
              } requer atenção!`,
            };

            expiredTrucksArray.push(truckExpiredError);
          }

          //Erro consulta carretas - antt

          const ownerTruckAnttConsult =
            consultItem &&
            consultItem?.consulta_antt_transportador &&
            JSON.parse(consultItem?.consulta_antt_transportador);

          const truckAnttConsult =
            consultItem &&
            consultItem?.consulta_antt_veiculo &&
            JSON.parse(consultItem?.consulta_antt_veiculo);

          const anttTruckError =
            ownerTruckAnttConsult &&
            ownerTruckAnttConsult?.data &&
            ownerTruckAnttConsult?.data.length > 0 &&
            truckAnttConsult &&
            truckAnttConsult?.data &&
            truckAnttConsult?.data.length > 0 &&
            ownerTruckAnttConsult?.data[0]?.rntrc !==
              truckAnttConsult?.data[0]?.rntrc;

          const ownerTruckAnttConsultError = {
            status: anttTruckError,
            label: `A ANTT do proprietário é divergente da ANTT da ${consultItem?.posicao.toLowerCase()}`,
            index,
            children: (
              <DivergentStatesCard
                ownerANTT={
                  ownerTruckAnttConsult?.data &&
                  ownerTruckAnttConsult?.data.lenght > 0 &&
                  ownerTruckAnttConsult?.data[0]?.rntrc
                }
                vehicleANTT={
                  truckAnttConsult?.data &&
                  truckAnttConsult?.data.lenght > 0 &&
                  truckAnttConsult?.data[0]?.rntrc
                }
              />
            ),
          };

          consultItem && trucksErros.push(ownerTruckAnttConsultError);

          // Erro consulta carreta cnpj / cpf

          const cnpjTruckInformations =
            consultItem &&
            consultItem?.consulta_cnpj &&
            consultItem?.consulta_cnpj !== "null"
              ? JSON.parse(consultItem?.consulta_cnpj)
              : consultItem &&
                consultItem?.consulta_cpf &&
                consultItem?.consulta_cpf !== "null" &&
                JSON.parse(consultItem?.consulta_cpf);

          const cnpjTruckInformationsError = {
            status:
              cnpjTruckInformations &&
              cnpjTruckInformations?.data?.length === 0 &&
              cnpjTruckInformations?.errors?.length === 0 &&
              cnpjTruckInformations?.code_message,
            label: `Erro consulta ${consultItem?.posicao.toLowerCase()}: ${
              cnpjTruckInformations?.code_message
            }`,
            children: (
              <ErrorCodeMessageCard
                label={`Erro consulta ${consultItem?.posicao.toLowerCase()}: ${
                  cnpjTruckInformations?.code_message
                }`}
                consult={consultItem}
                handleNewConsult={handleNewConsult}
              />
            ),
            index: `${index}`,
          };

          consultItem && truckCnpjError.push(cnpjTruckInformationsError);

          const truckcpfCnpjConsult = {
            status:
              cnpjTruckInformations &&
              cnpjTruckInformations?.data?.length === 0 &&
              cnpjTruckInformations?.errors?.length > 0,
            label: `Erro na consulta do CPF / CNPJ da ${consultItem?.posicao.toLowerCase()}`,
            children: (
              <ErrorMessageCard
                message={
                  cnpjTruckInformations?.errors &&
                  cnpjTruckInformations?.errors.length > 0
                    ? cnpjTruckInformations?.errors[0]
                    : ""
                }
                consult={consultItem}
                handleNewConsult={handleNewConsult}
              />
            ),
            index: `${index}`,
          };

          consultItem && truckcpfCnpjConsultError.push(truckcpfCnpjConsult);

          const truckAnttConsultError = {
            status: truckAnttConsult?.code !== 200,
            label: `Erro consta ${consultItem?.posicao.toLowerCase()} : ${
              truckAnttConsult?.code_message
            }`,
            children: (
              <ErrorCodeMessageCard
                label={`Erro consta ${consultItem?.posicao.toLowerCase()} : ${
                  truckAnttConsult?.code_message
                }`}
                consult={consultItem}
                handleNewConsult={handleNewConsult}
              />
            ),
            index: `${index}`,
          };

          consultItem && truckAnttConsultErrorList.push(truckAnttConsultError);

          const ownerTruckError = {
            status: cnpjTruckInformations?.err,
            label: `Erro consulta proprietário ${consultItem?.posicao.toLowerCase()}: ${
              cnpjTruckInformations?.message
            }`,
            children: (
              <ErrorCodeMessageCard
                label={`Erro consulta proprietário ${consultItem?.posicao.toLowerCase()}: ${
                  cnpjTruckInformations?.message
                }`}
                consult={consultItem}
                handleNewConsult={handleNewConsult}
              />
            ),
            index: `${index}`,
          };

          consultItem && ownerTruckErrorList.push(ownerTruckError);

          const truckStatusError = {
            status:
              (truck && !cnpjTruckInformations) ||
              (truck &&
                cnpjTruckInformations?.data &&
                cnpjTruckInformations?.data.length > 0 &&
                cnpjTruckInformations?.data[0]?.situacao_cadastral !==
                  "REGULAR" &&
                cnpjTruckInformations?.data[0]?.situacao_cadastral !==
                  "Regular" &&
                cnpjTruckInformations?.data[0]?.situacao_cadastral !==
                  "Ativa" &&
                cnpjTruckInformations?.data[0]?.situacao_cadastral !== "ATIVA"),

            label: `A situação do cadastro da carreta ${
              index + 1
            } requer atenção!`,
            children: (
              <RegistrationStatusCard
                status={
                  cnpjTruckInformations?.data &&
                  cnpjTruckInformations?.data.length > 0 &&
                  cnpjTruckInformations?.data[0]?.situacao_cadastral
                }
              />
            ),
            handleClick: () => handleSelectTabAndCloseModal(`${index + 1}`),
          };

          consultItem && truckSituation.push(truckStatusError);
        });

      //Erro entre os proprietários do veículo e carretas

      const anexoTrucks =
        tempRegister &&
        tempRegister?.anexos_carretas &&
        JSON.parse(tempRegister?.anexos_carretas);

      const vehicleAttachment =
        tempRegister &&
        tempRegister?.anexos_veiculo &&
        JSON.parse(tempRegister?.anexos_veiculo);

      const trucksOwnersNames =
        temporaryTrucks &&
        Object.keys(temporaryTrucks).length > 0 &&
        Object.keys(temporaryTrucks).map((truck) => {
          if (
            anexoTrucks[truck]?.cpf_cnpj &&
            anexoTrucks[truck]?.cpf_cnpj !== "" &&
            anexoTrucks[truck]?.cpf_cnpj !==
              truck?.dados_ocr?.cpf_cnpj?.valor &&
            anexoTrucks[truck]?.nome_proprietario &&
            anexoTrucks[truck]?.nome_proprietario !== ""
          ) {
            return anexoTrucks[truck]?.nome_proprietario;
          }

          if (anexoTrucks[truck]?.dados_ocr?.nome?.valor) {
            return anexoTrucks[truck]?.dados_ocr?.nome?.valor;
          } else {
            return "";
          }
        });

      const ownerName =
        vehicleAttachment?.cpf_cnpj &&
        vehicleAttachment?.cpf_cnpj !== "" &&
        vehicleAttachment?.cpf_cnpj !==
          temporaryVehicle?.dados_ocr?.cpf_cnpj?.valor &&
        vehicleAttachment?.nome_proprietario &&
        vehicleAttachment?.nome_proprietario !== ""
          ? vehicleAttachment?.nome_proprietario
          : temporaryVehicle?.dados_ocr?.nome?.valor
          ? temporaryVehicle?.dados_ocr?.nome?.valor
          : "--";

      const cpfOwner = vehicleAttachment?.proprietario_arrendamento
        ? vehicleAttachment?.proprietario_arrendamento
        : vehicleAttachment?.cpf_cnpj && vehicleAttachment?.cpf_cnpj !== ""
        ? vehicleAttachment?.cpf_cnpj
        : temporaryVehicle?.dados_ocr?.cpf_cnpj?.valor
        ? temporaryVehicle?.dados_ocr?.cpf_cnpj?.valor
        : "--";

      const ownerNamesErrors =
        cpfOwner &&
        !isCpfOrCnpf(cpfOwner) &&
        trucksOwnersNames &&
        trucksOwnersNames !== "" &&
        trucksOwnersNames.find((name) => name !== ownerName);

      // Erro Unidade Federativa das carretas e cavalo

      let trucksDivergentErros = [];

      temporaryTrucks &&
        Object.keys(temporaryTrucks).length > 0 &&
        Object.keys(temporaryTrucks).forEach((truck, index) => {
          const truckError = {
            status:
              temporaryTrucks[truck]?.dados_ocr &&
              temporaryTrucks[truck]?.dados_ocr?.estado_emplacamento?.valor !==
                temporaryVehicle?.dados_ocr?.estado_emplacamento?.valor,
            label: `A unidade federativa da carreta ${
              index + 1
            } é divergente da informada no cavalo `,
            children: (
              <DivergentStatesCard
                key={index}
                index={index}
                vehicleState={
                  temporaryVehicle?.dados_ocr?.estado_emplacamento?.valor
                }
                truckState={
                  temporaryTrucks[truck]?.dados_ocr?.estado_emplacamento?.valor
                }
              />
            ),
            index: `${index + 1}`,
          };

          temporaryTrucks[truck]?.dados_ocr &&
            trucksDivergentErros.push(truckError);
        });

      // Erro consulta veículo

      const vehicleConsult =
        driverConsults &&
        driverConsults.find((consult) => consult.posicao === "CAVALO");

      const vehicleAnttConsult =
        vehicleConsult &&
        vehicleConsult?.consulta_antt_veiculo &&
        JSON.parse(vehicleConsult?.consulta_antt_veiculo);

      const vehicleCnpjInformations =
        vehicleConsult &&
        vehicleConsult?.consulta_cnpj &&
        vehicleConsult?.consulta_cnpj !== "null"
          ? JSON.parse(vehicleConsult?.consulta_cnpj)
          : vehicleConsult &&
            vehicleConsult?.consulta_cpf &&
            vehicleConsult?.consulta_cpf !== "null" &&
            JSON.parse(vehicleConsult?.consulta_cpf);

      const ownerAnttConsult =
        vehicleConsult &&
        vehicleConsult?.consulta_antt_transportador &&
        JSON.parse(vehicleConsult?.consulta_antt_transportador);

      const anttVehicleError =
        ownerAnttConsult &&
        ownerAnttConsult?.data &&
        ownerAnttConsult?.data.length > 0 &&
        vehicleAnttConsult &&
        vehicleAnttConsult?.data &&
        vehicleAnttConsult?.data.length > 0 &&
        ownerAnttConsult?.data[0]?.rntrc !== vehicleAnttConsult?.data[0]?.rntrc;

      //Erros de consulta ao cadastro do motorista

      const temporaryDriver =
        tempRegister?.dados_formulario_motorista &&
        JSON.parse(tempRegister?.dados_formulario_motorista);

      const driverConsult =
        driverConsults &&
        driverConsults.length > 0 &&
        driverConsults.find((driver) => driver?.posicao === "MOTORISTA");

      const driverFederalInformations =
        driverConsult &&
        driverConsult?.consulta_cpf &&
        driverConsult?.consulta_cpf !== "null"
          ? JSON.parse(driverConsult?.consulta_cpf)
          : driverConsult?.consulta_cnpj &&
            driverConsult?.consulta_cnpj !== "null" &&
            JSON.parse(driverConsult?.consulta_cnpj);

      //Criação do array de erros

      setErrors([
        {
          status: isVehicleExpired,
          children: (
            <ErrorCard documentExpirationDate={documentVehicleExpirationDate} />
          ),
          label: "A data de validade do documento do cavalo requer atenção!",
        },
        ...expiredTrucksArray,
        ...trucksErros,
        ...truckCnpjError,
        ...truckcpfCnpjConsultError,
        ...truckAnttConsultErrorList,
        ...ownerTruckErrorList,
        ...truckSituation,
        {
          status:
            driver?.dt_validade_cnh &&
            new Date(driver?.dt_validade_cnh) < new Date(),
          label:
            "A validade da Carteira Nacional de Habilitação (CNH) do motorista expirou.",
          children: (
            <ErrorCard documentExpirationDate={driver?.dt_validade_cnh} />
          ),
        },
        {
          status: !!(
            driverFederalInformations &&
            driverFederalInformations?.data?.length === 0 &&
            driverFederalInformations?.errors?.length > 0
          ),

          label: "Erro na consulta do CPF motorista requer atenção!",
          children: (
            <ErrorMessageCard
              message={
                driverFederalInformations?.errors &&
                driverFederalInformations?.errors?.length > 0
                  ? driverFederalInformations?.errors[0]
                  : ""
              }
              consult={driverConsult}
              title="Consulta CNPJ motorista"
              handleNewConsult={handleNewConsult}
            />
          ),
        },
        {
          status: !!driverFederalInformations?.err,
          label: "Erro consulta motorista",
          children: (
            <ErrorMessageCard
              message={`Erro consulta motorista: ${driverFederalInformations?.message}`}
              consult={driverConsult}
              handleNewConsult={handleNewConsult}
            />
          ),
        },
        {
          status: !!ownerNamesErrors,
          label:
            "Há divergência entre o proprietário do cavalo e os das carretas",
          children: (
            <DivergentNamesCard
              trucksOwnersNames={trucksOwnersNames}
              ownerName={ownerName}
            />
          ),
          index: "0",
        },
        ...trucksDivergentErros,
        {
          status: !!anttVehicleError,
          label: `A ANTT do proprietário é divergente da ANTT do cavalo`,
          index: "0",
          children: (
            <DivergentStatesCard
              ownerANTT={
                ownerAnttConsult &&
                ownerAnttConsult?.data &&
                ownerAnttConsult?.data?.length > 0 &&
                ownerAnttConsult?.data[0]?.rntrc
              }
              vehicleANTT={
                vehicleAnttConsult &&
                vehicleAnttConsult?.data &&
                vehicleAnttConsult?.data?.lenght > 0 &&
                vehicleAnttConsult?.data[0]?.rntrc
              }
            />
          ),
        },
        {
          status: !!(
            vehicleCnpjInformations &&
            vehicleCnpjInformations?.data?.length === 0 &&
            vehicleCnpjInformations?.errors?.length === 0 &&
            vehicleCnpjInformations?.code_message
          ),
          label: `Erro consulta cavalo: ${vehicleCnpjInformations?.code_message}`,
          children: (
            <ErrorCodeMessageCard
              label={`Erro consulta cavalo: ${vehicleCnpjInformations?.code_message}`}
              consult={vehicleConsult}
              handleNewConsult={handleNewConsult}
            />
          ),
          index: "0",
        },
        {
          status: !!(
            vehicleCnpjInformations &&
            vehicleCnpjInformations?.data?.length === 0 &&
            vehicleCnpjInformations?.errors?.length > 0
          ),
          label: "Erro na consulta do CPF / CNPJ do proprietário do cavalo",
          children: (
            <ErrorMessageCard
              message={
                vehicleCnpjInformations?.errors &&
                vehicleCnpjInformations?.errors.length > 0
                  ? vehicleCnpjInformations?.errors[0]
                  : ""
              }
              consult={vehicleConsult}
              handleNewConsult={handleNewConsult}
            />
          ),
          index: "0",
        },
        {
          status: vehicleAnttConsult?.code !== 200,
          label: vehicleAnttConsult?.code_message
            ? `Erro consulta cavalo: ${vehicleAnttConsult?.code_message}`
            : "Erro consulta cavalo: A consulta não retornou dados no site ou aplicativo de origem no qual a automação foi executada.",
          children: (
            <ErrorCodeMessageCard
              label={`Erro consulta cavalo: ${vehicleAnttConsult?.code_message}`}
              consult={vehicleConsult}
              handleNewConsult={handleNewConsult}
            />
          ),
          index: "0",
        },
        {
          status: !!vehicleCnpjInformations?.err,
          label: `Erro consulta proprietário cavalo: ${vehicleCnpjInformations?.message}`,
          children: (
            <ErrorCodeMessageCard
              label={`Erro consulta proprietário cavalo: ${vehicleCnpjInformations?.message}`}
              consult={vehicleConsult}
              handleNewConsult={handleNewConsult}
            />
          ),
          index: "0",
        },
        {
          status:
            driverValidation &&
            ((biometricValidation &&
              ((biometricValidation?.foto_motorista?.similaridade &&
                biometricValidation?.foto_motorista?.similaridade < 0.5) ||
                !!biometricValidation?.message)) ||
              !biometricValidation),
          label: "A validação da biometria requer atenção!",
          children: (
            <div className="d-flex p-2">
              <BiometricValidationCard
                biometricValidation={biometricValidation}
                loadingBiometricValidation={loadingConsult}
                driverValidation={driverValidation}
              />
            </div>
          ),
          route: `/motoristas-listar/validacao-flex/${tempRegister?.id_motorista}`,
        },
        {
          status:
            driverValidation &&
            ((cnhValidation &&
              ((cnhValidation?.foto_motorista?.similaridade &&
                cnhValidation?.foto_motorista?.similaridade < 0.5) ||
                !!cnhValidation?.message)) ||
              !cnhValidation),
          label: "A validação da CNH requer atenção!",
          children: (
            <div className="d-flex p-2">
              <CNHValidationCard
                cnhValidation={cnhValidation}
                loadingCnhValidation={loadingConsult}
                driverValidation={driverValidation}
              />
            </div>
          ),
          route: `/motoristas-listar/validacao-flex/${tempRegister?.id_motorista}`,
        },
        {
          status:
            driverSat &&
            (driverSat?.bloqueadoadm === "S" || driverSat?.liberado === "N"),
          label: "O cadastro SAT requer atenção!",
          children: <StatusSat driverSat={driverSat} />,
          route: `/motoristas-listar/informacoes-sat/${tempRegister?.id_motorista}`,
        },
        {
          status:
            driver &&
            (driver?.bloqueio_sat === 1 || driver?.bloqueio_sat === 2),
          label: `O cadastro do motorista está ${
            driver?.bloqueio_sat === 1
              ? "inativo"
              : driver?.bloqueio_sat === 2 && "bloqueado"
          } no SAT!`,
          children: <StatusSat driverSat={driverSat} />,
          route: `/motoristas-listar/informacoes-sat/${tempRegister?.id_motorista}`,
        },
        {
          status:
            !vehicleCnpjInformations ||
            (vehicleCnpjInformations &&
              vehicleCnpjInformations?.data &&
              vehicleCnpjInformations?.data.length > 0 &&
              vehicleCnpjInformations?.data[0]?.situacao_cadastral !==
                "REGULAR" &&
              vehicleCnpjInformations?.data[0]?.situacao_cadastral !==
                "Regular" &&
              vehicleCnpjInformations?.data[0]?.situacao_cadastral !==
                "ATIVA" &&
              vehicleCnpjInformations?.data[0]?.situacao_cadastral !== "Ativa"),
          label:
            "A situação cadastral do proprietário do cavalo requer atenção!",
          children: (
            <RegistrationStatusCard
              status={
                vehicleCnpjInformations?.data &&
                vehicleCnpjInformations?.data.length > 0 &&
                vehicleCnpjInformations?.data[0]?.situacao_cadastral
              }
            />
          ),
          handleClick: () => handleSelectTabAndCloseModal("0"),
        },
        {
          status: temporaryVehicle?.ufCnhMotoristaIgualUfEmplacamentoVeiculo,
          children: (
            <DivergentStatesCard
              driverState={temporaryVehicle?.uf_cnh}
              vehicleState={
                temporaryVehicle?.dados_ocr?.estado_emplacamento?.valor
              }
            />
          ),
          label:
            "O Estado da CNH do motorista é divergente do Estado do emplacamento da CRLV do cavalo!",
          handleClick: () => handleSelectTabAndCloseModal("0"),
        },
        {
          status: flexValidation || (driverConsults && flexValidation === null),
          label:
            "O resultado da validação Flex Consulta - Denatran requer atenção!",
          route: `/motoristas-listar/validacao-flex/${tempRegister?.id_motorista}`,
          children: (
            <ValidationsErrorList
              validationValue={cnhValidation ?? biometricValidation}
              id={driver?.id}
            />
          ),
        },
        {
          status: historyErros,
          label: "Histórico de viagens requer atenção!",
          children: (
            <AlertMessage label="Há registro de bloqueio para o motorista" />
          ),
          route: `/motoristas-listar/historico-viagens/${tempRegister?.id_motorista}`,
        },
        {
          status: ownerTypeError,
          children: (
            <DriverTypeAlertCard
              ownerInformations={ownerInformations}
              driver={temporaryDriver?.nome}
              ownerName={ownerName}
            />
          ),
          label: "O tipo de proprietário do cavalo precisa de atenção!",
          handleClick: () => handleSelectTabAndCloseModal("0"),
        },
      ]);
    },
    []
  );

  const providerValue = useMemo(
    () => ({
      errors,
      isLoading,
      getErrors,
    }),
    [errors, isLoading, getErrors]
  );

  return (
    <ErrosPrivider.Provider value={providerValue}>
      {children}
    </ErrosPrivider.Provider>
  );
};
