import { Button, Modal, Table } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import ButtonLink from "../ButtonLink/ButtonLink";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DetranStrongItem,
  ModalTitle,
  TableModalContainer,
  TableModalTitle,
  TheadModal,
  TitleModal,
} from "./styles";
import moment from "moment";
import { useOwners } from "../../../hooks/OwnerProvider";
import { WarningCard } from "../WarningCard/WarningCard";

const TabelItem = ({ item, vehicleValue, detranValue }) => {
  return (
    <tr>
      <td className="ps-1">
        <strong>{item}</strong>
      </td>
      {vehicleValue && <td>{vehicleValue}</td>}
      <td>{detranValue}</td>
    </tr>
  );
};

export const DetranDetailsModal = ({ consult, vehicle, vehicleAttachment }) => {
  const [show, setShow] = useState(false);
  const [tenantOwner, setTenantOwner] = useState();
  const [ownerInformations, setOwnerInformations] = useState();

  const { getOwner } = useOwners();

  const getVehicleOwners = useCallback(async () => {
    if (vehicle?.proprietario_id) {
      const response = await getOwner(vehicle?.proprietario_id);
      setTenantOwner(response);
    }
    if (vehicle?.proprietario_arrendatario_id) {
      const response = await getOwner(vehicle?.proprietario_arrendatario_id);
      setOwnerInformations(response);
    }
  }, [
    getOwner,
    vehicle?.proprietario_id,
    vehicle?.proprietario_arrendatario_id,
  ]);

  useEffect(() => {
    getVehicleOwners();
  }, [getVehicleOwners]);

  const handleOpenAndCloeseModal = () => {
    setShow(!show);
  };

  const ownerName = useMemo(() => {
    if (
      vehicleAttachment?.cpf_cnpj &&
      vehicleAttachment?.cpf_cnpj !== "" &&
      vehicleAttachment?.cpf_cnpj !== vehicle?.dados_ocr?.cpf_cnpj?.valor &&
      vehicleAttachment?.nome_proprietario &&
      vehicleAttachment?.nome_proprietario !== ""
    ) {
      return vehicleAttachment?.nome_proprietario;
    }

    if (
      vehicle?.dados_ocr?.nome?.valor &&
      vehicleAttachment?.cpf_cnpj === "" &&
      vehicleAttachment?.nome_proprietario === ""
    ) {
      return vehicle?.dados_ocr?.nome?.valor;
    } else {
      return "--";
    }
  }, [
    vehicleAttachment?.cpf_cnpj,
    vehicleAttachment?.nome_proprietario,
    vehicle?.dados_ocr?.cpf_cnpj?.valor,
    vehicle?.dados_ocr?.nome?.valor,
  ]);

  const list = [
    {
      item: "Renavam",
      vehicleValue:
        vehicle?.dados_ocr?.renavam?.valor ?? vehicle?.renavam ?? "--",

      detranValue:
        consult?.data && consult?.data.length > 0
          ? consult?.data[0]?.dados_veiculo?.renavam
          : "--",
    },
    {
      item: "Cor",
      vehicleValue:
        vehicle?.dados_ocr?.cor_predominante?.valor ?? vehicle?.cor ?? "--",
      detranValue:
        consult?.data && consult?.data.length > 0
          ? consult?.data[0]?.dados_veiculo?.cor
          : "--",
    },
    {
      item: "Marca/Modelo",
      vehicleValue:
        vehicle?.dados_ocr?.marca_modelo_versao?.valor ??
        vehicle?.marca ??
        "--",
      detranValue:
        consult?.data && consult?.data.length > 0
          ? consult?.data[0]?.dados_veiculo?.marca_modelo
          : "--",
    },
    {
      item: "Fabricação",
      vehicleValue:
        vehicle?.dados_ocr?.ano_fabricacao?.valor ??
        vehicle?.ano_fabricacao ??
        "--",

      detranValue:
        consult?.data && consult?.data.length > 0
          ? consult?.data[0]?.dados_veiculo?.ano_fabricacao
          : "--",
    },
    {
      item: "Emissão",
      vehicleValue: vehicle?.dados_ocr?.data?.valor
        ? vehicle?.dados_ocr?.data?.valor
        : vehicle?.dt_emissao_documento
        ? moment.utc(vehicle?.dt_emissao_documento).format("DD/MM/YYYY")
        : "--",

      detranValue:
        consult?.data && consult?.data.length > 0
          ? consult?.data[0]?.dados_veiculo?.data_aquisicao
          : "--",
    },
    {
      item: "Tipo",
      vehicleValue:
        vehicle?.dados_ocr?.especie_tipo?.valor ??
        vehicle?.tipo_veiculo ??
        "--",

      detranValue:
        consult?.data && consult?.data.length > 0
          ? consult?.data[0]?.dados_veiculo?.tipo
          : "--",
    },
    {
      item: "Proprietário",
      vehicleValue: vehicle?.dados_ocr
        ? ownerName
        : tenantOwner?.nome ?? ownerInformations?.nome ?? "--",

      detranValue:
        consult?.data && consult?.data.length > 0
          ? consult?.data[0]?.dados_veiculo?.nome_proprietario
          : "--",
    },
  ];

  const checkErros = () => {
    return list.some((data) => data.vehicleValue !== data.detranValue);
  };

  return (
    <div>
      {checkErros() && (
        <WarningCard label="Há inconsistência entre os dados registrados neste sistema e os dados fornecidos pelo Detran" />
      )}
      <div className="d-flex justify-content-end">
        <ButtonLink
          label="Detalhes Detran"
          fontSize="0.7rem"
          buttonColor="primary"
          handleClick={handleOpenAndCloeseModal}
          secondIcon={<IoIosArrowForward />}
        />
      </div>
      <Modal
        show={show}
        onHide={handleOpenAndCloeseModal}
        dialogClassName="custom-modal-90w"
        animation
        fullscreen
        centered
      >
        <Modal.Header closeButton>
          <ModalTitle>
            CONFORMIDADE: Dados Cadastrados vs. Registros do DETRAN
          </ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <TableModalContainer className="mb-2">
            <Table hover size="sm" className="border rounded mb-0">
              <TheadModal>
                <TableModalTitle>
                  <TitleModal className="ps-1">Item</TitleModal>
                  <TitleModal>Cadastro</TitleModal>
                  <TitleModal>Detran</TitleModal>
                </TableModalTitle>
              </TheadModal>
              <tbody>
                {list.map((listItem, index) => (
                  <TabelItem
                    key={listItem?.item}
                    item={listItem?.item}
                    vehicleValue={listItem?.vehicleValue}
                    detranValue={listItem?.detranValue}
                  />
                ))}
              </tbody>
            </Table>
          </TableModalContainer>
          <DetranStrongItem>Observações Detran</DetranStrongItem>
          <TableModalContainer className="mt-1">
            <Table hover size="sm" className="border rounded mb-0">
              <TheadModal>
                <TableModalTitle>
                  <TitleModal className="ps-1">Item</TitleModal>
                  <TitleModal>Detran</TitleModal>
                </TableModalTitle>
              </TheadModal>
              <tbody>
                <TabelItem
                  item="Dívida ativa"
                  detranValue={
                    consult?.data && consult?.data.length > 0
                      ? consult?.data[0]?.dados_veiculo
                          ?.divida_ativa_licenciamento
                      : "--"
                  }
                />
                <TabelItem
                  item="Impedimento"
                  detranValue={
                    consult?.data && consult?.data.length > 0
                      ? consult?.data[0]?.dados_veiculo?.impedimentos
                      : "--"
                  }
                />
                <TabelItem
                  item="Informações pendentes"
                  detranValue={
                    consult?.data && consult?.data.length > 0
                      ? consult?.data[0]?.dados_veiculo?.infomacoes_pendentes
                      : "--"
                  }
                />
                <TabelItem
                  item="Restrição venda"
                  detranValue={
                    consult?.data && consult?.data.length > 0
                      ? consult?.data[0]?.dados_veiculo?.restricao_venda
                      : "--"
                  }
                />
                <TabelItem
                  item="Situação"
                  detranValue={
                    consult?.data && consult?.data.length > 0
                      ? consult?.data[0]?.dados_veiculo?.situacao
                      : "--"
                  }
                />
              </tbody>
            </Table>
          </TableModalContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleOpenAndCloeseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
