import { useCallback, useEffect } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useScopes } from "../../../../hooks/ScopeProvider";
import { formatNameString } from "../../../../Shared/Utils/data";

const PermissionList = ({
  perfis,
  setCurrentScope,
  setCurrentPerfilId,
  isDisabled,
}) => {
  const { scopes, getScopes } = useScopes();

  useEffect(() => {
    getScopes();
  }, [getScopes]);

  const handleChange = useCallback(
    (event) => {
      const perfil = perfis.find(
        (perfil) => perfil?.id === parseInt(event.value)
      );

      setCurrentScope(perfil?.Escopo?.nome);
      setCurrentPerfilId(perfil?.id);
    },
    [perfis, setCurrentPerfilId, setCurrentScope]
  );

  const options = scopes.reduce((options, scope) => {
    const scopePerfis = perfis.filter(
      (perfil) => perfil?.id_escopo === scope?.id
    );

    const scopeOptions = scopePerfis.map((perfil) => ({
      value: perfil?.id,
      label: perfil?.nome,
    }));

    if (scopeOptions.length > 0) {
      options.push({ label: scope?.nome, options: scopeOptions });
    }

    return options;
  }, []);

  return (
    <Form>
      <Select
        options={options}
        onChange={handleChange}
        placeholder="-- Selecionar perfil de usuário --"
        getOptionLabel={(option) =>
          `${option?.label && formatNameString(option.label)}`
        }
        getOptionValue={(option) => option.value}
        isDisabled={isDisabled}
      />
    </Form>
  );
};

export default PermissionList;
