import { useCallback } from "react";
import { Card, Col, Row, Image as ImageLogo } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { BsPersonLinesFill } from "react-icons/bs";
import FlexLogo from "../../../../../../Assets/Images/flex-logo.png";
import DriverImage from "../../../../../../Assets/Images/driver-image.svg";
import {
  BorderCard,
  CardBody,
  CoverImage,
  IconButton,
  IconLink,
} from "./styles";
import { useNavigate } from "react-router-dom";
import {
  cnpjCpfFormat,
  formatNameString,
} from "../../../../../../Shared/Utils/data";

const DriversCard = ({ driver, getDriver }) => {
  const navigate = useNavigate();

  const handleEditDriverClick = useCallback(() => {
    getDriver(driver?.id);

    navigate(`/motoristas-editar/${driver?.id}`);
  }, [driver?.id, getDriver, navigate]);

  return (
    <BorderCard className=" d-flex justify-content-center text-center w-100 h-100 card flex-column">
      <CardBody className="gap-1">
        <Row xs={2}>
          <Col xs={3} className="h-100 px-1">
            <CoverImage
              driverImage={DriverImage}
              currentDriverImage={driver?.foto_motorista?.url}
            />
          </Col>
          <Col xs={9} className="px-2">
            <h3 className="text-start mb-0">
              {formatNameString(driver?.nome)}
            </h3>
            <h4 className="text-start mb-2">{cnpjCpfFormat(driver?.cpf)}</h4>
            <h5 className="text-start">
              Status:
              <span
                className={`ms-1 ${
                  driver?.bloqueado_adm === "S"
                    ? "color-red"
                    : driver?.ativo
                    ? "color-active"
                    : "color-red"
                }`}
              >
                {driver?.bloqueado_adm === "S"
                  ? "Bloqueado ADM"
                  : driver?.ativo
                  ? "Liberado"
                  : "Desativado"}
              </span>
            </h5>
          </Col>
        </Row>
      </CardBody>
      <Card.Body
        className="mt-auto d-flex justify-content-start gap-1"
        style={{ maxHeight: "58px" }}
      >
        <IconLink
          to={`/motoristas-informacoes/${driver?.id}`}
          className="d-flex align-items-center"
          title="Informações do motorista"
        >
          <BsPersonLinesFill size={20} color="white" />
        </IconLink>
        <IconButton
          onClick={handleEditDriverClick}
          className="d-flex align-items-center"
          title="Editar cadastro"
          disabled={
            driver?.status_cadastro === "2r" || driver?.status_cadastro === "7r"
          }
        >
          <BiEdit size={20} color="white" />
        </IconButton>
        <IconLink
          to={`/motoristas-listar/${driver?.id}`}
          className="d-flex align-items-center"
          title="Informações flex"
        >
          <ImageLogo src={FlexLogo} width={20} height={20} />
        </IconLink>
      </Card.Body>
    </BorderCard>
  );
};

export default DriversCard;
