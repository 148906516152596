import { useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { Card, Col, Image as ImageLogo, Row } from "react-bootstrap";

import FlexLogo from "../../../../../Assets/Images/flex-logo.png";

import {
  CardContainer,
  InformationIcon,
  Item,
  PageTitle,
  SimilarityCardContainer,
  TitleContainer,
} from "./styles";
import NameSimilarityCard from "../../../../../Shared/Components/NameSimilarityCard/NameSimilarityCard";
import { AlertMessage } from "../../../../../Shared/Components/AlertMessage";
import ButtonLink from "../../../../../Shared/Components/ButtonLink/ButtonLink";
import BiometricValidationCard from "../../../../../Shared/Components/BiometricValidationCard/BiometricValidationCard";
import CNHValidationCard from "../../../../../Shared/Components/CNHValidationCard/CNHValidationCard";

const SimilarityCard = ({
  driver,
  biometricValidation,
  cnhValidation,
  loadingConsult,
  driverValidations,
}) => {
  const { id } = useParams();

  const cnhCategoryValidation =
    biometricValidation?.categoria_cnh || cnhValidation?.categoria_cnh;

  const cnhEmitida =
    biometricValidation?.cnh_emitida || cnhValidation?.cnh_emitida;

  const cpfDriver =
    biometricValidation?.cpf_motorista_disponivel ||
    cnhValidation?.cpf_motorista_disponivel;

  const regularCpfDriver =
    biometricValidation?.cpf_motorista_regular ||
    cnhValidation?.cpf_motorista_regular;

  const firstCnh =
    biometricValidation?.dt_primeira_cnh || cnhValidation?.dt_primeira_cnh;

  const lastCnh =
    biometricValidation?.dt_ultima_emissao_cnh ||
    cnhValidation?.dt_ultima_emissao_cnh;

  const driverValidation =
    biometricValidation?.dt_validade_cnh || cnhValidation?.dt_validade_cnh;

  const driverName =
    biometricValidation?.nome_motorista?.similaridade ||
    cnhValidation?.nome_motorista?.similaridade;

  const motherName =
    !!biometricValidation?.nome_mae?.similaridade ||
    !!cnhValidation?.nome_mae?.similaridade;

  const fatherName =
    biometricValidation?.nome_pai?.similaridade ||
    cnhValidation?.nome_pai?.similaridade;

  const cnhNumber =
    biometricValidation?.numero_cnh || cnhValidation?.numero_cnh;

  const issue =
    biometricValidation?.possui_impedimento_cnh ||
    cnhValidation?.possui_impedimento_cnh;

  const gender =
    biometricValidation?.sexo_motorista || cnhValidation?.sexo_motorista;

  return (
    <CardContainer className="w-100">
      <TitleContainer className="d-flex align-items-center">
        <ImageLogo src={FlexLogo} width={30} height={30} />
        <PageTitle className="d-flex justify-content-start mb-0">
          RESULTADO VALIDAÇÃO FLEX CONSULTA - DENATRAN{" "}
        </PageTitle>
      </TitleContainer>
      <Card.Body className="pb-0">
        <Row className="d-flex g-3 mb-3">
          <Col xl={6}>
            <BiometricValidationCard
              biometricValidation={biometricValidation}
              loadingBiometricValidation={loadingConsult}
              driverValidation={driverValidations}
            />
          </Col>
          <Col xl={6}>
            <CNHValidationCard
              cnhValidation={cnhValidation}
              loadingCnhValidation={loadingConsult}
              driverValidation={driverValidations}
            />
          </Col>
        </Row>
        <SimilarityCardContainer>
          <Item className="gap-1 mb-3 d-flex">
            <h3 className="d-flex align-items-center">
              % de similariade com o nome:{" "}
            </h3>
            <div className="d-flex align-items-center">
              <InformationIcon className="d-none d-md-flex" />
              <NameSimilarityCard
                item={
                  biometricValidation?.nome_motorista?.similaridade
                    ? biometricValidation?.nome_motorista?.similaridade
                    : cnhValidation?.nome_motorista?.similaridade
                    ? cnhValidation?.nome_motorista?.similaridade
                    : "--"
                }
                label="% de similariade com o nome:"
              />
            </div>
          </Item>
          <Item className="d-flex gap-1 mb-3 w-100">
            <h3>Registro da CNH:</h3>
            <InformationIcon className="d-none d-md-flex" />
            <h4>{driver?.cnh && driver?.cnh}</h4>
          </Item>
          <Item className="gap-1 mb-3">
            <h3>CPF motorista:</h3>
            <InformationIcon className="d-none d-md-flex" />

            <h4>{driver?.cpf && driver?.cpf}</h4>
          </Item>
          <Item className="gap-0 gap-md-1 mb-0">
            <h3>Categoria da CNH:</h3>
            <InformationIcon className="d-none d-md-flex" />

            <h4>{driver?.categoria_cnh_mot && driver?.categoria_cnh_mot}</h4>
          </Item>

          <div className="d-flex justify-content-end mb-2">
            <ButtonLink
              fontSize="0.7rem"
              link={`/motoristas-listar/validacao-flex/${id}`}
              label="Mais detalhes"
              secondIcon={<IoIosArrowForward />}
            />
          </div>

          {(driver?.status_cadastro === "0r" ||
            driver?.status_cadastro === "5r" ||
            driver?.status_cadastro === "6r") &&
            (cnhValidation || biometricValidation) &&
            (cnhValidation?.foto_motorista?.similaridade < 0.5 ||
              biometricValidation?.foto_motorista?.similaridade < 0.5 ||
              !cnhCategoryValidation ||
              !cnhEmitida ||
              !cpfDriver ||
              !regularCpfDriver ||
              !firstCnh ||
              !lastCnh ||
              !driverValidation ||
              !driverName ||
              !motherName ||
              !fatherName ||
              !cnhNumber ||
              !issue ||
              !gender) && (
              <AlertMessage label="Atenção: Cadastro com inconsistências de dados. Clique em Mais detalhes" />
            )}
        </SimilarityCardContainer>
      </Card.Body>
    </CardContainer>
  );
};

export default SimilarityCard;
