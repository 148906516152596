import { BiError } from "react-icons/bi";
import { AlertMessageContainer } from "./styles";

export const AlertMessage = ({
  label,
  backgroundColor = "#b94a45",
  color = "#FFF",
}) => (
  <AlertMessageContainer
    className="d-flex justify-content-center align-items-center rounded gap-1 p-2"
    backgroundColor={backgroundColor}
    color={color}
  >
    <div className="d-flex align-items-center">
      <BiError color={color ? color : "white"} size={16} />
    </div>
    <span style={{ color: color }}>{label}</span>
  </AlertMessageContainer>
);
