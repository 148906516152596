import { memo, useCallback, useEffect, useState } from "react";
import Dashboard from "../../../../../Shared/layout";
import { useCompanies } from "../../../../../hooks/CompanyProvider";
import ButtonLink from "../../../../../Shared/Components/ButtonLink/ButtonLink";
import { AiOutlineCloseCircle, AiOutlinePlus } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
import Search from "../../../../../Shared/Components/Search/Search";
import { sortedFantasyNames } from "../../../../../Shared/Utils/data";
import { CompanyTable } from "../components/CompanyTable/CompanyTable";
import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { useNavigate } from "react-router-dom";

const CompanyList = () => {
  const navigate = useNavigate();

  const {
    companies,
    isLoading,
    getCompanies,
    getCompany,
    deleteCompany,
    updateCompany,
  } = useCompanies();

  const [searchCompany, setSearchCompany] = useState("");

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const handleSearchChange = (event) => {
    setSearchCompany(event && event !== "" ? event.target.value : "");
  };

  const filteredCompanies = companies.filter((company) => {
    return company?.nome_fantasia
      .toLowerCase()
      .includes(searchCompany.toLowerCase());
  });

  const handleDeleteCompany = useCallback(
    (company) => {
      Swal.fire({
        icon: "warning",
        title: "Você tem certeza absoluta?",
        html: `
      <div>
        <p>Essa ação não pode ser desfeita. Isso removerá permanentemente os dados da empresa, unidades e usuários associados.</p>
        <p>Digite <strong style="background-color: red; color: white">${company?.nome_fantasia}</strong> para confirmar.</p>
      </div>
    `,
        input: "text",
        inputPlaceholder: "Digite o nome da empresa",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Eu entendo as consequências, remover empresa",
        cancelButtonText: "Cancelar",
        focusCancel: true,
        preConfirm: (value) => {
          if (value === company?.nome_fantasia) {
            deleteCompany(company?.id);
            return true;
          } else {
            Swal.showValidationMessage(
              "O nome da empresa não corresponde. Tente novamente."
            );
            return false;
          }
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
    [deleteCompany]
  );

  const handleEditCompany = async (id) => {
    await getCompany(id);
    navigate(`/empresas-atualizar/${id}`);
  };

  return (
    <Dashboard title="Empresas">
      {localStorage.getItem("companyManagementPermissionToCreate") &&
        localStorage.getItem("companyManagementPermissionToCreate") !==
          "false" && (
          <Row className="d-flex align-items-end justify-content-between mb-3 mt-2">
            <Col lg={4}>
              <div className="d-flex justify-content-start align-items-end">
                <ButtonLink
                  label="Adicionar empresa"
                  link="/empresas-adicionar"
                  icon={<AiOutlinePlus />}
                />
              </div>
            </Col>
            <Col lg={4}>
              <Search
                label="Pesquisar empresas"
                searchValue={searchCompany}
                handleSearchChange={handleSearchChange}
              />
            </Col>
          </Row>
        )}
      {localStorage.getItem("companyManagementPermissionToRead") &&
        localStorage.getItem("companyManagementPermissionToRead") !==
          "false" && (
          <>
            {isLoading && <Loading />}
            {!isLoading &&
              (!localStorage.getItem("companyManagementPermissionToRead") ||
                localStorage.getItem("companyManagementPermissionToRead") ===
                  "false") && (
                <EmptyTableMessage
                  label="Listagem de empresas não disponível para o seu usuário"
                  icon={<AiOutlineCloseCircle />}
                />
              )}

            {!isLoading &&
              (localStorage.getItem("companyManagementPermissionToRead") ||
                localStorage.getItem("companyManagementPermissionToRead") !==
                  "false") && (
                <CompanyTable
                  companyManagementPermissionToUpdate={localStorage.getItem(
                    "companyManagementPermissionToUpdate"
                  )}
                  companyManagementPermissionToDelete={localStorage.getItem(
                    "companyManagementPermissionToDelete"
                  )}
                  companies={sortedFantasyNames(filteredCompanies)}
                  handleDeleteCompany={handleDeleteCompany}
                  updateCompany={updateCompany}
                  handleEditCompany={handleEditCompany}
                />
              )}
          </>
        )}
    </Dashboard>
  );
};

export default memo(CompanyList);
