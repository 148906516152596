import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ContainerButton = styled(Button)`
 transition: transform 200ms;
border: transparent !important;
border-radius: 8px;
font-size: ${({ fontSize }) => fontSize ?? "0.7rem"};


background-color: ${props => props.buttoncolor === "primary"
    ? "#2185D0"
    : props.buttoncolor === "danger"
      ? "#b94a45"
      : props.buttoncolor === "secondary" && "#b0b0b0"};
       

&:hover {
  transform: scale(1.01);
  z-index: 1;
  filter: brightness(1.2);

  background-color: ${props => props.buttoncolor === "primary"
    ? "#2185D0"
    : props.buttoncolor === "danger"
      ? "#b94a45"
      : props.buttoncolor === "secondary" && "#b0b0b0"};}


&:focus {
  transform: scale(1.01);
  z-index: 1;

background-color: ${props => props.buttoncolor === "primary"
    ? "#2185D0"
    : props.buttoncolor === "danger"
      ? "#b94a45"
      : props.buttoncolor === "secondary" && "#b0b0b0"};
}

`