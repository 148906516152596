import axios from "axios";
import { getToken } from "./Auth";

function logoutUser() {
  localStorage.removeItem("AUTH-REQUEST-PAYLOAD-APPROD");
  localStorage.removeItem("AUTH-REQUEST-TOKEN-APPROD");
  window.location.href = "/";
}

export default async function ApiRequest({ path, method, data, params }) {
  const { REACT_APP_BASE_URL } = process.env;

  const accessToken = path === "login" ? null : getToken();

  axios.interceptors.request.use(
    (config) => {
      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (
        response.status === 304 ||
        response.status === 400 ||
        response.status === 401
      ) {
        logoutUser();
      }
      return response;
    },
    (error) => {
      if (error.response.status === 403 || error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  return await axios({
    method,
    url: `${REACT_APP_BASE_URL}/${path}`,
    data,
    params,
    headers: { "Content-Type": "application/json" },
  });
}

export const AddressApi = axios.create({
  baseURL: process.env.REACT_APP_API_CEP_BASE_URL ?? "",
});

export const ApiGeoCode = axios.create({
  baseURL: process.env.REACT_APP_GEO_CODE_DIRECTIONS ?? "",
});

export const DriverCpfApiRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL ?? "",
});
