import React, { useCallback } from "react";
import Dashboard from "../../../../Shared/layout";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useUnits } from "../../../../hooks/UnitProvider";
import FormAddUnit from "./FormAddUnit";
import { UnitContainer } from "./styles";

const fields = {
  nome: {
    required: true,
  },
  id_empresa: {
    required: true,
    pattern: /^[0-9]+$/,
  },
  cep: {
    required: true,
  },
};

const AddUnit = () => {
  const { isSubmitting, createNewUnit } = useUnits();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = useCallback(
    (data) => {
      data.id_empresa = parseInt(data.id_empresa);
      data.cep = data.cep.replace(/\D+/g, "");

      createNewUnit(data, navigate);
    },
    [createNewUnit, navigate]
  );

  return (
    <Dashboard title="Adicionar unidade">
      <UnitContainer className="mt-2">
        <FormAddUnit
          handleSubmit={handleSubmit}
          register={register}
          fields={fields}
          errors={errors}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          setValue={setValue}
        />
      </UnitContainer>
    </Dashboard>
  );
};

export default AddUnit;
