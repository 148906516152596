import { BiIdCard } from "react-icons/bi";
import { Border, Item } from "./styles";
import { cnpjCpfFormat, formatCEP } from "../../../../../Shared/Utils/data";
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineNumber } from "react-icons/ai";
import { MdMapsHomeWork, MdOutlineRealEstateAgent } from "react-icons/md";
import { FaCity, FaMapMarkerAlt } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import { useCallback } from "react";
import { anttTypes } from "../../../../../Shared/Utils/constants";

const PersonalInformationsSection = ({ owner }) => {
  const currentValue = useCallback(
    (value, constant) =>
      value ? constant.find((status) => status.value === value)?.label : "--",
    []
  );

  return (
    <Border className="p-3">
      <Row xs={1} xl={2}>
        <Col className="gap-3  h-100">
          {owner?.tipo_antt && currentValue(owner?.tipo_antt, anttTypes) && (
            <Item className="gap-1">
              <BiIdCard size={17} color="#093c5e" />
              <h3>Tipo de proprietário:</h3>
              <h4>
                {owner?.tipo_antt
                  ? currentValue(owner?.tipo_antt, anttTypes)
                  : "--"}
              </h4>
            </Item>
          )}
          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>{owner?.cpf_cnpj?.length === 11 ? "CPF:" : "CNPJ:"}</h3>
            <h4>{owner?.cpf_cnpj ? cnpjCpfFormat(owner?.cpf_cnpj) : "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BsFillPersonFill size={15} color="#093c5e" />
            <h3>Nome:</h3>
            <h4>{owner?.nome ? owner?.nome : "--"}</h4>
          </Item>

          <Item className="gap-1">
            <AiOutlineNumber size={15} color="#093c5e" />
            <h3>CEP:</h3>
            <h4>{owner?.cep ? formatCEP(owner?.cep) : "--"}</h4>
          </Item>
        </Col>
        <Col className="gap-3  h-100">
          <Item className="gap-1">
            <MdOutlineRealEstateAgent size={15} color="#093c5e" />
            <h3>Estado:</h3>
            <h4>{owner?.uf ? owner?.uf : "--"}</h4>
          </Item>

          <Item className="gap-1">
            <FaCity size={15} color="#093c5e" />
            <h3>Cidade:</h3>
            <h4>{owner?.cidade ? owner?.cidade : "--"}</h4>
          </Item>

          <Item className="gap-1">
            <MdMapsHomeWork size={15} color="#093c5e" />
            <h3>Bairro:</h3>
            <h4>{owner?.bairro ? owner?.bairro : "--"}</h4>
          </Item>

          <Item className="gap-1">
            <FaMapMarkerAlt size={15} color="#093c5e" />
            <h3>Endereço:</h3>
            <h4>{`${
              owner?.endereco ? `${owner?.endereco}, ${owner?.numero}` : "--"
            }`}</h4>
          </Item>
        </Col>
      </Row>
    </Border>
  );
};

export default PersonalInformationsSection;
