import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { isAuthenticated } from "./Shared/Utils/Auth";

import Login from "./Pages/Login";
import PasswordRecovery from "./Pages/Login/PasswordRecovery";
import ChangePassword from "./Pages/Login/ChangePassword";

import PainelConfiguracoes from "./Pages/Dashboard/SystemSettings";

import Units from "./Pages/Dashboard/SystemSettings/Units";
import AddUnit from "./Pages/Dashboard/SystemSettings/Units/AddUnit";
import UpdateUnit from "./Pages/Dashboard/SystemSettings/Units/UpdateUnit";

import Perfis from "./Pages/Dashboard/SystemSettings/Perfis";
import AddPerfil from "./Pages/Dashboard/SystemSettings/Perfis/AddPerfil";

import Usuarios from "./Pages/Dashboard/SystemSettings/Users";
import AddUsuarios from "./Pages/Dashboard/SystemSettings/Users/AddUsuarios";
import UpdateUser from "./Pages/Dashboard/SystemSettings/Users/UpdateUser";

import AccessSettings from "./Pages/Dashboard/SystemSettings/AccessSettings";

import Drivers from "./Pages/Dashboard/RegistrationManagement/Drivers/Drivers/Drivers";
import DriversList from "./Pages/Dashboard/RegistrationManagement/Drivers/DriversList/DriversList";
import Driver from "./Pages/Dashboard/RegistrationManagement/Drivers/Driver/Driver";
import DriverInformations from "./Pages/Dashboard/RegistrationManagement/Drivers/DriverInformations";
import UpdateDrivers from "./Pages/Dashboard/RegistrationManagement/Drivers/UpdateDriver/UpdateDrivers";
import AddDriver from "./Pages/Dashboard/RegistrationManagement/Drivers/AddDriver";

import RegistrationManagement from "./Pages/Dashboard/RegistrationManagement/RegistrationManagement";

import OngoingProcesses from "./Pages/Dashboard/RegistrationManagement/Drivers/OngoingProcesses/OngoingProcesses";
import AddCompany from "./Pages/Dashboard/SystemSettings/Companies/AddCompany/AddCompany";

import CompanyList from "./Pages/Dashboard/SystemSettings/Companies/CompanyList/CompanyList";
import UpdateCompany from "./Pages/Dashboard/SystemSettings/Companies/UpdateCompany/UpdateCompany";
import Owners from "./Pages/Dashboard/RegistrationManagement/Owners/Owners/Owners";
import OwnersList from "./Pages/Dashboard/RegistrationManagement/Owners/OwnersList/OwnersList";
import AddOwner from "./Pages/Dashboard/RegistrationManagement/Owners/AddOwner/AddOwner";
import OwnerInformations from "./Pages/Dashboard/RegistrationManagement/Owners/OwnerInformations/OwnerInformations";
import UpdateOwner from "./Pages/Dashboard/RegistrationManagement/Owners/UpdateOwner/UpdateOwner";
import TravelHistory from "./Pages/Dashboard/RegistrationManagement/Drivers/TravelHistory/TravelHistory";
import FlexValidation from "./Pages/Dashboard/RegistrationManagement/Drivers/FlexValidation/FlexValidation";
import TravelHistoryByCompany from "./Pages/Dashboard/RegistrationManagement/Drivers/TravelHistory/TravelHistoryByCompany";
import Vehicles from "./Pages/Dashboard/RegistrationManagement/Vehicles/Vehicles/Vehicles";
import VehiclesList from "./Pages/Dashboard/RegistrationManagement/Vehicles/VeciclesList/VehiclesList";
import AddVehicle from "./Pages/Dashboard/RegistrationManagement/Vehicles/AddVehicle/AddVehicle";
import VehicleInformations from "./Pages/Dashboard/RegistrationManagement/Vehicles/VehicleInformations/VehicleInformations";
import UpdateVehicle from "./Pages/Dashboard/RegistrationManagement/Vehicles/UpdateVehicle/UpdateVehicle";
import UpdatePerfil from "./Pages/Dashboard/SystemSettings/Perfis/UpdatePerfil";

import TravelLoadOrder from "./Pages/Dashboard/TravelsManagement/TravelLoadOrder/TravelLoadOrder";
import TravelsList from "./Pages/Dashboard/TravelsManagement/TravelsList/TravelsList";
import DetailedLoadingOrder from "./Pages/Dashboard/TravelsManagement/DetailedLoadingOrder/DetailedLoadingOrder";
import RealTimeLocation from "./Pages/Dashboard/TravelsManagement/RealTimeLocation/RealTimeLocation";
import Home from "./Pages/Dashboard/Home/Home";
import TravelManagement from "./Pages/Dashboard/TravelsManagement/ TravelManagement";
import ListOfLoadingOrderStages from "./Pages/Dashboard/TravelsManagement/ListOfLoadingOrderStages/ListOfLoadingOrderStages";
import OccurrencesTypes from "./Pages/Dashboard/TravelsManagement/OccurrencesTypes/OccurrencesTypes";
import AddOccurrence from "./Pages/Dashboard/TravelsManagement/OccurrencesTypes/AddOccurrence/AddOccurrence";
import EditOccurrence from "./Pages/Dashboard/TravelsManagement/OccurrencesTypes/EditOccurrence/EditOccurrence";
import SendOccurrences from "./Pages/Dashboard/TravelsManagement/SendOccurrences";
import DriverSATInformations from "./Pages/Dashboard/RegistrationManagement/Drivers/DriverSATInformations";
import ChooseRoute from "./Pages/Dashboard/TravelsManagement/ChooseRoute/ChooseRoute";
import TemporaryDriver from "./Pages/Dashboard/RegistrationManagement/Drivers/TemporaryDriver/TemporaryDriver";
import RealTimeLocatioMap from "./Pages/Dashboard/TravelsManagement/RealTimeLocation/RealTimeLocatioMap";
import { CompletedTravelHistory } from "./Pages/Dashboard/TravelsManagement/RealTimeLocation/CompletedTravelHistory";
import JudicialProcessDetails from "./Pages/Dashboard/RegistrationManagement/Drivers/JudicialProcessDetails/JudicialProcessDetails";

import Manifest from "./Pages/Dashboard/Manifest";
import MDFeList from "./Pages/Dashboard/Manifest/MDFeList";
import MDFeDetails from "./Pages/Dashboard/Manifest/MDFeDetails/MDFeDetails";
import BarcodeScanner from "./Pages/BarcodeScanner";

const PrivateRoute = () => {
  const auth = isAuthenticated();
  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default function App() {
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/recuperarsenha" element={<PasswordRecovery />} />
          <Route exact path="/trocarsenha" element={<ChangePassword />} />
          <Route
            exact
            path="/digitalizar-barcode-cte"
            element={<BarcodeScanner />}
          />
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/inicio" element={<Home />} />

            <Route
              path="/painel-configuracao"
              element={<PainelConfiguracoes />}
            />

            <Route path="/empresas" element={<CompanyList />} />
            <Route path="/empresas-adicionar" element={<AddCompany />} />
            <Route path="/empresas-atualizar/:id" element={<UpdateCompany />} />

            <Route path="/unidades" element={<Units />} />
            <Route path="/unidades-adicionar" element={<AddUnit />} />
            <Route path="/unidades-atualizar/:id" element={<UpdateUnit />} />

            <Route path="/perfis" element={<Perfis />} />
            <Route path="/perfis-adicionar" element={<AddPerfil />} />
            <Route path="/perfis-atualizar/:id" element={<UpdatePerfil />} />

            <Route path="/usuarios" element={<Usuarios />} />
            <Route path="/usuarios-adicionar" element={<AddUsuarios />} />
            <Route path="/usuarios-atualizar/:id" element={<UpdateUser />} />

            <Route path="/configuracoes-acesso" element={<AccessSettings />} />

            <Route
              path="/gestao-cadastros"
              element={<RegistrationManagement />}
            />

            <Route path="/motoristas" element={<Drivers />} />
            <Route path="/motoristas-listar" element={<DriversList />} />
            <Route path="/motoristas-listar/:id" element={<Driver />} />
            <Route
              path="/motoristas/processos-judiciais/:id/:token"
              element={<JudicialProcessDetails />}
            />

            <Route
              path="/motoristas-listar/temporario/:id"
              element={<TemporaryDriver />}
            />

            <Route
              path="/motoristas-listar/informacoes-sat/:id"
              element={<DriverSATInformations />}
            />
            <Route
              path="/motoristas-informacoes/:id"
              element={<DriverInformations />}
            />
            <Route path="/motoristas-editar/:id" element={<UpdateDrivers />} />

            <Route
              path="/motoristas-listar/historico-viagens/:id"
              element={<TravelHistory />}
            />

            <Route
              path="/motoristas-listar/historico-viagens/:id/:company"
              element={<TravelHistoryByCompany />}
            />

            <Route
              path="motoristas-listar/validacao-flex/:id"
              element={<FlexValidation />}
            />

            <Route path="/motoristas-adicionar" element={<AddDriver />} />

            <Route
              path="/motoristas/processos-em-curso"
              element={<OngoingProcesses />}
            />

            <Route path="/proprietarios" element={<Owners />} />
            <Route path="/proprietarios-listar" element={<OwnersList />} />
            <Route path="/proprietarios-adicionar" element={<AddOwner />} />
            <Route
              path="/proprietarios-informacoes/:id"
              element={<OwnerInformations />}
            />
            <Route path="/proprietarios-editar/:id" element={<UpdateOwner />} />

            <Route path="/veiculos" element={<Vehicles />} />
            <Route path="/veiculos-listar" element={<VehiclesList />} />
            <Route path="/veiculos-adicionar" element={<AddVehicle />} />
            <Route
              path="/veiculos-informacoes/:id"
              element={<VehicleInformations />}
            />
            <Route path="/veiculos-editar/:id" element={<UpdateVehicle />} />

            <Route path="/gestao-viagens" element={<TravelManagement />} />

            <Route
              path="/gestao-viagens/ordem-de-carregamento/:token"
              element={<DetailedLoadingOrder />}
            />
            <Route path="/gestao-viagens/viagens" element={<TravelsList />} />
            <Route
              path="/gestao-viagens/ordem-de-carregamento"
              element={<TravelLoadOrder />}
            />
            <Route
              path="/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/:token"
              element={<RealTimeLocation />}
            />
            <Route
              path="/gestao-viagens/ordem-de-carregamento/log-viagem/:token"
              element={<CompletedTravelHistory />}
            />
            <Route
              path="/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/map/:token"
              element={<RealTimeLocatioMap />}
            />
            <Route
              path="/gestao-viagens/ordem-de-carregamento/escolher-rota/:token"
              element={<ChooseRoute />}
            />
            <Route
              path="/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/:token"
              element={<ListOfLoadingOrderStages />}
            />

            <Route
              path="/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/enviar-ocorrencia/:token"
              element={<SendOccurrences />}
            />

            <Route
              path="/gestao-viagens/tipos-de-ocorrencias"
              element={<OccurrencesTypes />}
            />
            <Route
              path="/gestao-viagens/tipos-de-ocorrencias/adicionar/ocorrencia"
              element={<AddOccurrence />}
            />
            <Route
              path="/gestao-viagens/tipos-de-ocorrencias/editar/ocorrencia/:id"
              element={<EditOccurrence />}
            />

            <Route
              path="/gestao-regularizacao-servico-transporte"
              element={<Manifest />}
            />
            <Route
              path="/gestao-regularizacao-servico-transporte/lista"
              element={<MDFeList />}
            />
            <Route
              path="/gestao-regularizacao-servico-transporte/detalhes/:id"
              element={<MDFeDetails />}
            />
          </Route>
        </Routes>
      </Fragment>
    </Router>
  );
}
