import ReportInconsistencyInput from "./ReportInconsistencyInput";
import { ReportedInconsistencyContainer } from "./styles";

const ReportInconsistency = ({
  changing,
  register,
  fields,
  handleInputChange,
}) => {
  return (
    <ReportedInconsistencyContainer className="p-2">
      {changing?.foto_motorista && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.foto_motorista}
          register={register}
          fields={fields}
          item="foto_motorista"
          label="Foto do motorista"
          registerItem="campos_erros_cadastro.foto_motorista"
        />
      )}
      {changing?.nome && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.nome}
          register={register}
          fields={fields}
          item="nome"
          label="Nome do motorista"
          registerItem="campos_erros_cadastro.nome"
        />
      )}
      {changing?.cpf && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.cpf}
          register={register}
          fields={fields}
          item="cpf"
          label="CPF do motorista"
          registerItem="campos_erros_cadastro.cpf"
        />
      )}

      {changing?.numero_celular && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.numero_celular}
          register={register}
          fields={fields}
          item="numero_celular"
          label="Celular do motorista"
          registerItem="campos_erros_cadastro.numero_celular"
        />
      )}

      {changing?.cnh && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.cnh}
          register={register}
          fields={fields}
          item="cnh"
          label="CNH do motorista"
          registerItem="campos_erros_cadastro.cnh"
        />
      )}

      {changing?.numero_registro_cnh && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.numero_registro_cnh}
          register={register}
          fields={fields}
          item="numero_registro_cnh"
          label="Número de registro da CNH"
          registerItem="campos_erros_cadastro.numero_registro_cnh"
        />
      )}

      {changing?.categoria_cnh_mot && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.categoria_cnh_mot}
          register={register}
          fields={fields}
          item="categoria_cnh_mot"
          label="Categoria da CNH do motorista"
          registerItem="campos_erros_cadastro.categoria_cnh_mot"
        />
      )}

      {changing?.dt_validade_cnh && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.dt_validade_cnh}
          register={register}
          fields={fields}
          item="dt_validade_cnh"
          label="Validade da CNH do motorista"
          registerItem="campos_erros_cadastro.dt_validade_cnh"
        />
      )}

      {changing?.dt_emissao_cnh && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.dt_emissao_cnh}
          register={register}
          fields={fields}
          item="dt_emissao_cnh"
          label="Emissão da CNH do motorista"
          registerItem="campos_erros_cadastro.dt_emissao_cnh"
        />
      )}

      {changing?.dt_primeira_cnh && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.dt_primeira_cnh}
          register={register}
          fields={fields}
          item="dt_primeira_cnh"
          label="Primeira CNH do motorista"
          registerItem="campos_erros_cadastro.dt_primeira_cnh"
        />
      )}

      {changing?.foto_cnh && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.foto_cnh}
          register={register}
          fields={fields}
          item="foto_cnh"
          label="Foto da CNH do motorista"
          registerItem="campos_erros_cadastro.foto_cnh"
        />
      )}

      {changing?.rg_motorista && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.rg_motorista}
          register={register}
          fields={fields}
          item="rg_motorista"
          label="RG do motorista"
          registerItem="campos_erros_cadastro.rg_motorista"
        />
      )}

      {changing?.orgao_rg && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.orgao_rg}
          register={register}
          fields={fields}
          item="orgao_rg"
          label="Órgão expedidor do RG"
          registerItem="campos_erros_cadastro.orgao_rg"
        />
      )}

      {changing?.dt_emissao_rg && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.dt_emissao_rg}
          register={register}
          fields={fields}
          item="dt_emissao_rg"
          label="Data de emissão do RG"
          registerItem="campos_erros_cadastro.dt_emissao_rg"
        />
      )}

      {changing?.sexo && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.sexo}
          register={register}
          fields={fields}
          item="sexo"
          label="Gênero do motorista"
          registerItem="campos_erros_cadastro.sexo"
        />
      )}

      {changing?.dt_nascimento && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.dt_nascimento}
          register={register}
          fields={fields}
          item="dt_nascimento"
          label="Data de nascimento do motorista"
          registerItem="campos_erros_cadastro.dt_nascimento"
        />
      )}

      {changing?.nome_pai && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.nome_pai}
          register={register}
          fields={fields}
          item="nome_pai"
          label="Nome do pai do motorista"
          registerItem="campos_erros_cadastro.nome_pai"
        />
      )}

      {changing?.nome_mae && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.nome_mae}
          register={register}
          fields={fields}
          item="nome_mae"
          label="Nome da mãe do motorista"
          registerItem="campos_erros_cadastro.nome_mae"
        />
      )}

      {changing?.nacionalidade && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.nacionalidade}
          register={register}
          fields={fields}
          item="nacionalidade"
          label="Nacionalidade do motorista"
          registerItem="campos_erros_cadastro.nacionalidade"
        />
      )}

      {changing?.cod_nacionalidade && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.cod_nacionalidade}
          register={register}
          fields={fields}
          item="país de origem"
          label="País de origem"
          registerItem="campos_erros_cadastro.cod_nacionalidade"
        />
      )}

      {changing?.cep && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.cep}
          register={register}
          fields={fields}
          item="cep"
          label="Cep do motorista"
          registerItem="campos_erros_cadastro.cep"
        />
      )}

      {changing?.cidade && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.cidade}
          register={register}
          fields={fields}
          item="cidade"
          label="Cidade do motorista"
          registerItem="campos_erros_cadastro.cidade"
        />
      )}

      {changing?.uf && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.uf}
          register={register}
          fields={fields}
          item="uf"
          label="Estado do motorista"
          registerItem="campos_erros_cadastro.uf"
        />
      )}

      {changing?.bairro && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.bairro}
          register={register}
          fields={fields}
          item="bairro"
          label="Bairro do motorista"
          registerItem="campos_erros_cadastro.bairro"
        />
      )}

      {changing?.endereco && (
        <ReportInconsistencyInput
          handleInputChange={handleInputChange}
          changingItem={changing?.endereco}
          register={register}
          fields={fields}
          item="endereco"
          label="Endereço do motorista"
          registerItem="campos_erros_cadastro.endereco"
        />
      )}
    </ReportedInconsistencyContainer>
  );
};

export default ReportInconsistency;
