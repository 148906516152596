import { IoIosArrowForward } from "react-icons/io";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import {
  LocationIcon,
  LocationItem,
  PageTitle,
  RealTimeLocationCard,
  RealTimeLocationCardContainer,
  StyledDivWithBorder,
} from "./styled";
import { Fragment, useEffect, useMemo, useState } from "react";
import { sortByDateDescending } from "../../../../Shared/Utils/data";
import moment from "moment";

export const RealTimeLocationList = ({ loadOrderRoute, token }) => {
  const [loadOrderRoutes, setLoadOrdersRoutes] = useState([]);

  const lastrouteTaken = useMemo(
    () =>
      loadOrderRoute && sortByDateDescending(loadOrderRoute).slice(0, 10000),
    [loadOrderRoute]
  );

  useEffect(() => {
    if (loadOrderRoute) {
      const cityMap = {};

      lastrouteTaken.forEach((item) => {
        const city = item.cidade;

        if (cityMap[city]) {
          cityMap[city].qtde_repeticoes++;
        } else {
          cityMap[city] = {
            ...item,
            qtde_repeticoes: 1,
          };
        }
      });

      const resultArray = Object.values(cityMap);
      setLoadOrdersRoutes(resultArray);
    }
  }, [lastrouteTaken, loadOrderRoute]);

  return (
    <RealTimeLocationCard className="d-flex flex-column p-2 gap-3">
      <RealTimeLocationCardContainer>
        <PageTitle className="mb-1">Registros das etapas do percurso</PageTitle>
        {loadOrderRoutes.map((lastLocation, index) => (
          <Fragment key={index}>
            {lastLocation?.cidade && (
              <div className="d-md-flex align-items-center justify-content-md-between mb-2 gap-1">
                <div className="d-flex align-items-center gap-1">
                  <LocationIcon index={index} />
                  <LocationItem index={index}>
                    {moment(lastLocation?.data).format("DD/MM/YYYY HH:mm")}
                  </LocationItem>
                  <LocationItem index={index}>
                    {lastLocation?.cidade
                      ? `${lastLocation?.cidade} (${lastLocation?.uf})`
                      : "--"}
                  </LocationItem>
                  <span className="badge text-bg-secondary">
                    {lastLocation?.qtde_repeticoes.toLocaleString("pt-BR")}
                  </span>
                </div>
                {index === 0 && <StyledDivWithBorder />}
                {index === 0 && (
                  <LocationItem index={index}>
                    Última etapa registrada
                  </LocationItem>
                )}
              </div>
            )}
          </Fragment>
        ))}
        <div className="d-flex justify-content-end">
          <ButtonLink
            fontSize="0.7rem"
            link={`/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/${token}`}
            label="Ver mais"
            secondIcon={<IoIosArrowForward />}
          />
        </div>
      </RealTimeLocationCardContainer>
    </RealTimeLocationCard>
  );
};
