export const routesPaths = [
  { path: "/inicio", title: "Início" },
  { path: "/painel-configuracao", title: "Painel de configuração" },
  { path: "/empresas", title: "Empresas" },
  { path: "/empresas-adicionar", title: "Adicionar empresas" },
  { path: "/empresas-atualizar/:id", title: "Atualizar empresas" },
  { path: "/perfis", title: "Perfis" },
  { path: "/perfis-adicionar", title: "Adicionar perfis" },
  { path: "/perfis-atualizar/:id", title: "Atualizar perfil" },
  { path: "/unidades", title: "Unidades" },
  { path: "/unidades-adicionar", title: "Adicionar unidade" },
  { path: "/unidades-atualizar/:id", title: "Atualizar unidade" },
  { path: "/usuarios", title: "Usuários" },
  { path: "/usuarios-adicionar", title: "Adicionar usuários" },
  { path: "/usuarios-atualizar/:id", title: "Atualizar usuários" },
  { path: "/configuracoes-acesso", title: "Configurações de acesso" },
  { path: "/gestao-cadastros", title: "Gestão de cadastros" },
  { path: "/motoristas", title: "Motoristas" },
  { path: "/motoristas-listar", title: "Motoristas cadastrados" },
  { path: "/motoristas-editar/:id", title: "Editar motorista" },
  { path: "/motoristas-listar/:id", title: "Motorista" },
  { path: "/motoristas/processos-judiciais/:id/:token", title: "Processo judicial" },
  { path: "/motoristas-listar/temporario/:id", title: "Motorista" },
  { path: "/motoristas-listar/informacoes-sat/:id", title: "Informações SAT" },
  { path: "/motoristas-informacoes/:id", title: "Informações do motorista" },
  { path: "/motoristas-listar/historico-viagens/:id", title: "Histórico de viagens" },
  { path: "/motoristas-listar/historico-viagens/:id/:company", title: "Histórico de viagens" },
  { path: "/motoristas-listar/validacao-flex/:id", title: "Análise da validação Flex" },
  { path: "/motoristas-adicionar", title: "Adicionar motorista" },
  { path: "/motoristas/processos-em-curso", title: "Processos em curso" },
  { path: "/proprietarios", title: "Proprietários" },
  { path: "/proprietarios-listar", title: "Proprietários cadastrados" },
  { path: "/proprietarios-adicionar", title: "Adicionar proprietário" },
  { path: "/proprietarios-informacoes/:id", title: "Informações do proprietário" },
  { path: "/proprietarios-editar/:id", title: "Editar proprietário" },
  { path: "/veiculos", title: "Veículos" },
  { path: "/veiculos-listar", title: "Veículos cadastrados" },
  { path: "/veiculos-adicionar", title: "Adicionar veículos" },
  { path: "/veiculos-informacoes/:id", title: "Informações do veículo" },
  { path: "/veiculos-informacoes/:id", title: "Informações do veículo" },
  { path: "/veiculos-editar/:id", title: "Editar veículo" },
  { path: "/gestao-viagens", title: "Gestão de viagens" },
  { path: "/gestao-viagens/ordem-de-carregamento/:token", title: "Ordem de carregamento detalhada" },
  { path: "/gestao-viagens/viagens", title: "Monitoramento dos motoristas" },
  { path: "/gestao-viagens/ordem-de-carregamento", title: "Ordens de Carregamento" },
  { path: "/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/:token", title: "Localização em tempo real" },
  { path: "/gestao-viagens/ordem-de-carregamento/log-viagem/:token", title: "Log Ordens de Carregamento" },
  { path: "/gestao-viagens/ordem-de-carregamento/escolher-rota/:token", title: "Escolha de rota" },
  { path: "/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/:token", title: "Registros das etapas do percurso" },
  { path: "/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/enviar-ocorrencia/:token", title: "Enviar ocorrência" },
  { path: "/gestao-viagens/tipos-de-ocorrencias", title: "Tipos de ocorrências" },
  { path: "/gestao-viagens/tipos-de-ocorrencias/adicionar/ocorrencia", title: "Adicionar ocorrência" },
  { path: "/gestao-viagens/tipos-de-ocorrencias/editar/ocorrencia/:id", title: "Editar tipo de ocorrência" },
  { path: "/gestao-regularizacao-servico-transporte", title: "Gestão MDF-e" },
  { path: "/gestao-regularizacao-servico-transporte/lista", title: "Painel MDF-e" },
  { path: "/gestao-regularizacao-servico-transporte/detalhes/:id", title: "Detalhes MDF-e" },

]