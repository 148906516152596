import { Col, FloatingLabel, Form, Row } from "react-bootstrap";

import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  civilStatus,
  gendersOptions,
} from "../../../../../../Shared/Utils/constants";
import { allNationalities } from "../../../../../../Shared/Utils/nationalities";

export const DocumentForm = ({
  owner,
  ownerByCpfCnpj,
  isCpf,
  register,
  errors,
  setValue,
  isSAT,
}) => {
  const [ownerRg, setOwnerRg] = useState("");
  const [ownerOrgaoRg, setOwnerOrgaoRg] = useState("");
  const [ownerEmissaoRg, setOwnerEmissaoRg] = useState("");
  const [ownerGender, setOwnerGender] = useState("");
  const [ownerNacionality, setOwnerNacionality] = useState("");
  const [ownerCivilState, setOwnerCivilState] = useState("");

  const currentOwnerRg = useMemo(
    () =>
      owner ? owner?.rg : ownerByCpfCnpj?.rg && isSAT && ownerByCpfCnpj?.rg,
    [isSAT, owner, ownerByCpfCnpj?.rg]
  );

  const currentOwnerOrgaoRg = useMemo(
    () =>
      owner
        ? owner?.orgao_rg
        : ownerByCpfCnpj?.orgao_rg && isSAT && ownerByCpfCnpj?.orgao_rg,
    [isSAT, owner, ownerByCpfCnpj?.orgao_rg]
  );

  const currentOwnerEmissaoRg = useMemo(
    () =>
      owner?.dt_emissao_rg
        ? moment.utc(owner?.dt_emissao_rg).format("YYYY-MM-DD")
        : ownerByCpfCnpj?.dataemissaorg &&
          isSAT &&
          moment.utc(ownerByCpfCnpj?.dataemissaorg).format("YYYY-MM-DD"),
    [isSAT, owner?.dt_emissao_rg, ownerByCpfCnpj?.dataemissaorg]
  );

  const currentOwnerGender = useMemo(
    () => (owner ? owner?.sexo : isSAT && ownerByCpfCnpj?.sexo),
    [isSAT, owner, ownerByCpfCnpj?.sexo]
  );

  const currentOwnerNacionality = useMemo(
    () =>
      owner
        ? owner?.nacionalidade
        : ownerByCpfCnpj?.nacionalidade &&
          isSAT &&
          ownerByCpfCnpj?.nacionalidade,
    [isSAT, owner, ownerByCpfCnpj?.nacionalidade]
  );

  const currentOwnerCivilState = useMemo(
    () => owner && owner?.estado_civil,
    [owner]
  );

  useEffect(() => setOwnerRg(currentOwnerRg), [currentOwnerRg]);

  useEffect(() => setOwnerOrgaoRg(currentOwnerOrgaoRg), [currentOwnerOrgaoRg]);

  useEffect(
    () => setOwnerEmissaoRg(currentOwnerEmissaoRg),
    [currentOwnerEmissaoRg]
  );

  useEffect(() => setOwnerGender(currentOwnerGender), [currentOwnerGender]);

  useEffect(
    () => setOwnerNacionality(currentOwnerNacionality),
    [currentOwnerNacionality]
  );

  useEffect(
    () => setOwnerCivilState(currentOwnerCivilState),
    [currentOwnerCivilState]
  );

  const handleChangeOwnerRg = useCallback(
    (event) => setOwnerRg(event.target.value),
    []
  );

  const handleChangeOwnerOrgaoRg = useCallback(
    (event) => setOwnerOrgaoRg(event.target.value),
    []
  );

  const handleChangeOwnerEmissaoRg = useCallback(
    (event) => setOwnerEmissaoRg(event.target.value),
    []
  );

  const handleChangeOwnerGender = useCallback(
    (event) => setOwnerGender(event.target.value),
    []
  );

  const handleChangeOwnerNacionality = useCallback(
    (event) => setOwnerNacionality(event.target.value),
    []
  );

  const handleChangeOwnerCivilState = useCallback(
    (event) => setOwnerCivilState(event.target.value),
    []
  );

  useEffect(() => {
    if ((isSAT && ownerByCpfCnpj) || owner) {
      ownerRg && setValue("rg", ownerRg);
      ownerOrgaoRg && setValue("orgao_rg", ownerOrgaoRg);
      ownerEmissaoRg && setValue("dt_emissao_rg", ownerEmissaoRg);
      ownerGender && setValue("sexo", ownerGender);
      ownerNacionality && setValue("nacionalidade", ownerNacionality);
      ownerCivilState && setValue("estado_civil", ownerCivilState);
    }
  }, [
    isSAT,
    owner,
    ownerByCpfCnpj,
    ownerCivilState,
    ownerEmissaoRg,
    ownerGender,
    ownerNacionality,
    ownerOrgaoRg,
    ownerRg,
    setValue,
  ]);

  return (
    <Row xs={1} className="g-2 mb-4">
      <Form.Group as={Col} md={6} controlId="formRG">
        <FloatingLabel controlId="formRG" label="RG">
          <Form.Control
            {...register("rg", {
              required: isCpf && "O RG é obrigatório",
            })}
            size="sm"
            type="text"
            maxLength={15}
            onChange={handleChangeOwnerRg}
            className={`${"is-required"}`}
            placeholder="Digite o RG"
            value={ownerRg}
          />
          {errors?.rg && (
            <Form.Text className="text-danger">{errors?.rg?.message}</Form.Text>
          )}
        </FloatingLabel>
      </Form.Group>

      <Form.Group as={Col} md={6} controlId="formOrgaoRG">
        <FloatingLabel controlId="formOrgaoRG" label="Órgão expedidor">
          <Form.Control
            {...register("orgao_rg", {
              required: isCpf && "O órgão expedidor é obrigatório",
            })}
            size="sm"
            type="text"
            className={`${"is-required"}`}
            onChange={handleChangeOwnerOrgaoRg}
            placeholder="Digite o órgão expedidor do RG"
            value={ownerOrgaoRg}
          />
          {errors?.orgao_rg && (
            <Form.Text className="text-danger">
              {errors?.orgao_rg?.message}
            </Form.Text>
          )}
        </FloatingLabel>
      </Form.Group>

      <Form.Group as={Col} md={6} controlId="formEmissaoRG">
        <FloatingLabel controlId="formEmissaoRG" label="Data de emissão do RG">
          <Form.Control
            {...register("dt_emissao_rg", {
              required: isCpf && "A data de emissão é obrigatória",
            })}
            size="sm"
            type="date"
            className={`${"is-required"}`}
            onChange={handleChangeOwnerEmissaoRg}
            value={ownerEmissaoRg}
          />
          {errors?.dt_emissao_rg && (
            <Form.Text className="text-danger">
              {errors?.dt_emissao_rg?.message}
            </Form.Text>
          )}
        </FloatingLabel>
      </Form.Group>

      <Form.Group as={Col} md={6} controlId="formGender">
        <FloatingLabel controlId="formGender" label="Gênero">
          <Form.Select
            {...register("sexo", {
              required: isCpf && "O gênero é obrigatório",
            })}
            size="sm"
            type="text"
            className={`${"is-required"}`}
            onChange={handleChangeOwnerGender}
            value={ownerGender}
          >
            <option value="">Selecione o gênero</option>
            {gendersOptions.map((gender) => (
              <option value={gender.value} key={gender.id}>
                {gender.label}
              </option>
            ))}
          </Form.Select>
          {errors?.sexo && (
            <Form.Text className="text-danger">
              {errors?.sexo?.message}
            </Form.Text>
          )}
        </FloatingLabel>
      </Form.Group>

      <Form.Group as={Col} md={6} controlId="formNacionalidade">
        <FloatingLabel controlId="formNacionalidade" label="Nacionalidade">
          <Form.Select
            {...register("nacionalidade", {
              required: isCpf && "A nacionalidade é obrigatória",
            })}
            size="sm"
            type="text"
            className={`${"is-required"}`}
            onChange={handleChangeOwnerNacionality}
            value={ownerNacionality}
          >
            <option value="">Selecione a nacionalidade</option>
            {allNationalities.map((nacionality) => (
              <option value={nacionality.value} key={nacionality.id}>
                {nacionality.label}
              </option>
            ))}
          </Form.Select>
          {errors?.nacionalidade && (
            <Form.Text className="text-danger">
              {errors?.nacionalidade?.message}
            </Form.Text>
          )}
        </FloatingLabel>
      </Form.Group>

      <Form.Group as={Col} md={6} controlId="formEstadoCivil">
        <FloatingLabel controlId="formEstadoCivil" label="Estado civil">
          <Form.Select
            {...register("estado_civil", {
              required: isCpf && "O estado civil é obrigatório",
            })}
            size="sm"
            type="text"
            className={`${"is-required"}`}
            onChange={handleChangeOwnerCivilState}
            value={ownerCivilState}
          >
            <option value="">Selecione o Estado Civil</option>
            {civilStatus.map((civilStatus) => (
              <option value={civilStatus.label} key={civilStatus.id}>
                {civilStatus.label}
              </option>
            ))}
          </Form.Select>
          {errors?.estado_civil && (
            <Form.Text className="text-danger">
              {errors?.estado_civil?.message}
            </Form.Text>
          )}
        </FloatingLabel>
      </Form.Group>
    </Row>
  );
};
