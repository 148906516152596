import { Col } from "react-bootstrap";
import Dashboard from "../../../../../Shared/layout";
import LinkCard from "../../../../../Shared/Components/LinkCard";
import { memo } from "react";
import { AddOwnerIcon, OwnerListIcon, OwnerRow } from "./styles";

const list = [
  {
    route: "/proprietarios-listar",
    title: "Listar proprietarios",
    icon: <OwnerListIcon />,
  },
  {
    route: "/proprietarios-adicionar",
    title: "Adicionar proprietário",
    icon: <AddOwnerIcon />,
  },
];

const Owners = () => {
  return (
    <Dashboard title="Proprietários">
      <OwnerRow
        xs={1}
        md={2}
        lg={4}
        xl={6}
        id="container-cards-conf-sistema"
        className="d-flex mt-4 g-2"
      >
        {list.map((item) => (
          <Col key={item.route}>
            <LinkCard route={item.route} title={item.title} icon={item.icon} />
          </Col>
        ))}
      </OwnerRow>
    </Dashboard>
  );
};

export default memo(Owners);
