import React, { Fragment, useMemo, useState } from "react";
import { Row, Col, Form, FloatingLabel } from "react-bootstrap";

import { SubmitButton } from "../../../../Shared/Components/SubmitButton/SubmitButton";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { formatString, sortedNames } from "../../../../Shared/Utils/data";
import { FormUserContainer } from "./styles";

const FormUser = ({
  handleSubmit,
  register,
  units,
  perfis,
  errors,
  onSubmit,
  isSubmitting,
  companies,
  user,
  scopes,
}) => {
  const [ativo, setAtivo] = useState(user?.ativo || false);

  const perfisWithScopes = (scope) =>
    perfis.filter((perfil) => perfil?.Escopo?.nome === scope);

  const unit = units.find((unit) => unit.id === parseInt(user.id_unidade));

  const company =
    unit &&
    companies.find((company) => company?.id === parseInt(unit?.id_empresa));

  const handleAtivoChange = () => {
    setAtivo(!ativo);
  };

  const handleFormSubmit = (data) => {
    setAtivo(data?.ativo);
    onSubmit(user.id, data);
  };

  const currentPerfil = useMemo(() => {
    return user?.id_perfil;
  }, [user?.id_perfil]);

  return (
    <FormUserContainer className="mt-2">
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row>
          <Form.Group as={Col} md={12} className="mb-4" controlId="formNome">
            <FloatingLabel controlId="formNome" label="Nome">
              <Form.Control
                type="nome"
                placeholder="Escreva o nome"
                className="is-required"
                {...register("nome", { required: "O nome é obrigatório" })}
                defaultValue={user?.nome}
              />
              {errors?.nome && (
                <Form.Text className="text-danger">
                  {errors?.nome?.message}
                </Form.Text>
              )}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-4" controlId="formEmail">
            <FloatingLabel controlId="formEmail" label="Email">
              <Form.Control
                type="email"
                placeholder="Enter email"
                disabled
                value={user?.email}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md={4} className="mb-4" controlId="formPerfil">
            <FloatingLabel controlId="formPerfil" label="Perfil">
              <Form.Select
                aria-label="Default select example"
                className="is-required"
                {...register("id_perfil", {
                  required: "O perfil é obrigatório",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Selecione um perfil para o usuário",
                  },
                  validate: (value) => {
                    const parsedValue = parseInt(value);
                    return (
                      parsedValue > 0 ||
                      "O campo id_perfil deve conter um número inteiro positivo."
                    );
                  },
                })}
                defaultValue={currentPerfil}
              >
                <option value={null}>Selecione o Perfil</option>
                {sortedNames(scopes).map((scope) => {
                  return (
                    <Fragment key={scope?.id}>
                      <option disabled>{formatString(scope?.nome)}</option>
                      {sortedNames(perfisWithScopes(scope?.nome)).map(
                        (perfil) => (
                          <option key={perfil.id} value={perfil.id}>
                            {perfil?.nome}
                          </option>
                        )
                      )}
                    </Fragment>
                  );
                })}
              </Form.Select>
              {errors?.id_perfil && (
                <Form.Text className="text-danger">
                  {errors?.id_perfil?.message}
                </Form.Text>
              )}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md={4} className="mb-4" controlId="formCompany">
            <FloatingLabel controlId="formCompany" label="Empresas">
              <Form.Select
                aria-label="Default select example"
                disabled
                defaultValue={company?.id ? company?.id : ""}
              >
                <option value={null}>Selecione uma empresa</option>
                {companies.map((companies) => (
                  <option key={companies.id} value={companies.id}>
                    {companies?.nome_fantasia}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md={4} className="mb-4" controlId="formUnit">
            <FloatingLabel controlId="formUnit" label="Unidade">
              <Form.Select
                disabled
                aria-label="id_unit"
                defaultValue={user?.id_unidade}
              >
                <option value={null}>Selecione a unidade</option>
                {sortedNames(units).map((unit) => (
                  <option key={unit?.id} value={unit?.id}>
                    {unit?.nome}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          <Form.Group
            as={Col}
            md={6}
            className="mb-4 d-flex align-items-center gap-2"
            controlId="formAtivo"
          >
            <Form.Label className="mb-0">Usuário ativo</Form.Label>
            <Form.Check
              type="switch"
              id="custom-switch"
              defaultChecked={ativo}
              onChange={handleAtivoChange}
              {...register("ativo")}
            />
          </Form.Group>

          <Form.Group className="d-flex gap-2">
            <SubmitButton
              labelButton="Editar usuário"
              isSubmitting={isSubmitting}
              onClick={handleSubmit(handleFormSubmit)}
            />
            <ButtonLink label="Cancelar" link="/usuarios" fontSize="0.7rem" />
          </Form.Group>
        </Row>
      </Form>
    </FormUserContainer>
  );
};

export default FormUser;
