import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Form, FloatingLabel } from "react-bootstrap";
import "./index.css";
import { LogoImage } from "../../Shared/Components/Logo/Logo";
import { useLogins } from "../../hooks/LoginProvider";
import { SubmitButton } from "../../Shared/Components/SubmitButton/SubmitButton";
import { ContainerForm } from "./styles";
import LoginBreadcrumb from "../../Shared/Components/BreadCrumb/LoginBreadcrumb";

const fields = {
  email: {
    required: true,
    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  },
};

export default function PwdRecovery() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { sendEmail, isSubmitting } = useLogins();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    sendEmail(data, navigate);
  };

  return (
    <>
      <Container fluid className="h-100">
        <Row className="h-100">
          <Col className="bg-pwd-recovery d-none d-md-flex" md={5} />
          <Col md={7} className="p-md-5 p-1">
            <ContainerForm
              onSubmit={handleSubmit(onSubmit)}
              className="mx-auto py-5"
            >
              <LogoImage route="/" width="150rem" />
              <LoginBreadcrumb title="Recuperar senha" />
              <Form.Group className="mb-4" controlId="formEmail">
                <FloatingLabel
                  controlId="formEmail"
                  label="Digite o seu e-mail"
                >
                  <Form.Control
                    type="text"
                    className="input-email is-required"
                    {...register("email", fields.email)}
                    placeholder="Digite seu e-mail"
                    disabled={isSubmitting}
                  />
                  {errors?.email?.type === "required" && (
                    <Form.Text className="text-danger">
                      O e-mail é obrigatório.
                    </Form.Text>
                  )}
                  {errors?.email?.type === "pattern" && (
                    <Form.Text className="text-danger">
                      O formato do e-mail está incorreto!
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <SubmitButton
                isSubmitting={isSubmitting}
                labelButton="Enviar e-mail"
              />
            </ContainerForm>
          </Col>
        </Row>
      </Container>
    </>
  );
}
