import {
  MonitoringDriverThead,
  SelectLoadOrderByDriverButton,
  StatusTableItem,
  TableContainer,
  TableItem,
} from "./styles";
import { loaderOrderStatus } from "../../../../../../Shared/Utils/constants";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsEye } from "react-icons/bs";
import { useMemo } from "react";
import {
  formatNameString,
  sortedDrivers,
  sortedVehicle,
} from "../../../../../../Shared/Utils/data";

export const MonitoringDriverList = ({
  activeLoadOrder,
  handleSelectDriver,
  handleSelectVehicle,
  listBy,
}) => {
  const handleClick = (loadOrder) => {
    handleSelectDriver({
      id: loadOrder?.id,
      label: loadOrder?.nome_mot,
    });

    handleSelectVehicle({
      id: loadOrder?.id,
      label: loadOrder?.placa_cavalo,
    });
  };

  const currentActiveLoadOrderList = useMemo(() => {
    if (listBy === "drivers") {
      const list = sortedDrivers(activeLoadOrder);
      return list;
    } else {
      return sortedVehicle(activeLoadOrder);
    }
  }, [activeLoadOrder, listBy]);

  return (
    <TableContainer>
      <Table hover size="sm" className="border-none rounded mb-0">
        <MonitoringDriverThead>
          <tr>
            <th className="text-start fw-bold">
              {listBy === "drivers" ? "Motoristas" : "Placa"}
            </th>
            <th className="text-center fw-bold">Status</th>
            <th></th>
          </tr>
        </MonitoringDriverThead>
        <tbody>
          {currentActiveLoadOrderList.map((loadOrder, index) => (
            <tr key={index}>
              <TableItem className="text-start">
                <div className="d-flex aling-items-center gap-1">
                  <SelectLoadOrderByDriverButton
                    onClick={() => handleClick(loadOrder)}
                  >
                    {listBy === "drivers"
                      ? formatNameString(loadOrder?.nome_mot)
                      : loadOrder?.placa_cavalo
                      ? loadOrder?.placa_cavalo
                      : "Não informado"}
                  </SelectLoadOrderByDriverButton>
                </div>
              </TableItem>
              <StatusTableItem
                status={loadOrder?.status}
                className="text-center"
              >
                {loaderOrderStatus(loadOrder?.status)}
              </StatusTableItem>
              <td>
                <Link
                  to={`/gestao-viagens/ordem-de-carregamento/${loadOrder?.token}`}
                  title="Detalhes OC"
                >
                  <BsEye />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};
