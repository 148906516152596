import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Accordion, Form } from "react-bootstrap";

import VehicleForm from "../components/VehicleForms/VehicleForm";
import OwnerAssociationForm from "../components/VehicleForms/OwnerAssociationForm";
import ActiveAndBlockForm from "../components/VehicleForms/ActiveAndBlockForm";
import TruckUploadImage from "../components/VehicleForms/TruckUploadImage";
import TruckOwnerUpload from "../components/VehicleForms/TruckOwnerUpload";
import { ActionsTruck } from "../components/VehicleForms/ActionsTruck";

const TruckForm = ({
  drivers,
  handleActiveTruckClick,
  handleDeleteData,
  index,
  owners,
  showTruck,
  vehicle,
  setIsPendentingForm,
  handleShowModal,
  onSubmitTruck,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [OCRImage, setOCRImage] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const handleEnableTruckRegistration = useCallback(() => {
    setIsEdit(!isEdit);
    setIsPendentingForm(!isEdit);
  }, [isEdit, setIsPendentingForm]);

  const handleSaveData = useCallback(() => {
    setIsEdit(!isEdit);
    setIsPendentingForm(!isEdit);
  }, [isEdit, setIsPendentingForm]);

  const handleDelete = useCallback(() => {
    handleDeleteData(index, vehicle?.carretas[index]?.id);
  }, [handleDeleteData, index, vehicle?.carretas]);

  useEffect(() => vehicle?.carretas && setValue("carretas", vehicle?.carretas));

  useEffect(
    () =>
      vehicle?.carretas &&
      setValue(`carreta${index + 1}.id`, vehicle?.carretas[index]?.id)
  );

  const defaultTruck = useMemo(() => {
    return vehicle?.carretas && vehicle?.carretas[index];
  }, [index, vehicle?.carretas]);

  return (
    <Form onSubmit={handleSubmit((data) => onSubmitTruck(data, setIsEdit))}>
      <Accordion.Header onClick={() => handleActiveTruckClick(index)}>
        Carreta
      </Accordion.Header>
      <Accordion.Body className="d-flex flex-column gap-2">
        <VehicleForm
          register={register}
          errors={errors}
          truck={`carreta${index + 1}`}
          optional={!showTruck}
          defaultTruck={defaultTruck}
          setValue={setValue}
          isEdit={isEdit}
          setOCRImage={setOCRImage}
        />
        <OwnerAssociationForm
          truck={`carreta${index + 1}`}
          optional={!showTruck}
          owners={owners}
          drivers={drivers}
          errors={errors}
          setValue={setValue}
          control={control}
          defaultTruck={defaultTruck}
          isEdit={isEdit}
        />
        <ActiveAndBlockForm
          register={register}
          setValue={setValue}
          truck={`carreta${index + 1}`}
          defaultTruck={defaultTruck}
          isEdit={isEdit}
        />
        <div className="d-flex flex-column flex-md-row gap-2 g-md-4">
          <Form.Group>
            <TruckUploadImage
              setValue={setValue}
              errors={errors}
              width="10.4rem"
              height="10.4rem"
              align="start"
              position={index + 1}
              optional={!showTruck}
              truck={`carreta${index + 1}`}
              register={register}
              defaultTruck={defaultTruck}
              isEdit={isEdit}
              index={index}
              OCRImage={OCRImage}
              handleShowModal={handleShowModal}
            />
          </Form.Group>
          <Form.Group>
            <TruckOwnerUpload
              setValue={setValue}
              errors={errors}
              width="10.4rem"
              height="10.4rem"
              align="start"
              position={index + 1}
              optional={!showTruck}
              truck={`carreta${index + 1}`}
              register={register}
              defaultTruck={defaultTruck}
              isEdit={isEdit}
              index={index}
              handleShowModal={handleShowModal}
            />
          </Form.Group>
        </div>
        <ActionsTruck
          truck={`carreta${index + 1}`}
          handleDeleteData={handleDelete}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setIsPendentingForm={setIsPendentingForm}
          handleEnableTruckRegistration={handleEnableTruckRegistration}
          handleSaveData={handleSaveData}
          defaultTruck={defaultTruck}
          vehicle={vehicle}
          isUpdate
        />
      </Accordion.Body>
    </Form>
  );
};

export default TruckForm;
