import { Card } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled(Card)`
  background-color: #FFFAFA;
  border: none;
`

export const TitleContainer = styled.div`
  background-color: var(--color-gray-2);
  padding: 10px;
`

export const InfoTitle = styled.span`
  color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;

export const InfoItem = styled.span`
  color: ${(props) =>
    props.status === "placa"
      ? "#0071c7"
      : (props.status === "ATIVA" || props.status === "Ativa" || props.status === "REGULAR" || props.status === "Regular")
        ? "#198754"
        : props.status === "Requer atenção"
          ? "#C3A300" : props.status === "Pré-cadastro" ? "#b0b0b0"
            : "#093c5e"};
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 0;

  text-transform: ${(props) =>
    props.status === "ATIVO" ? "capitalize" : "initial"};

`;