import { Fragment } from "react";
import { Item } from "./styles";

export const DivergentNamesCard = ({ ownerName, trucksOwnersNames }) => {
  return (
    <div className="pt-3 pb-4 ms-2 me-2">
      <Item className="d-flex flex-column justify-content-center gap-1 mb-3">
        <h2>Proprietário cavalo:</h2>
        <span>{ownerName}</span>
      </Item>
      {trucksOwnersNames &&
        trucksOwnersNames.length > 0 &&
        trucksOwnersNames.map((name, index) => (
          <Fragment key={index}>
            {name !== "" && (
              <Item
                className="d-flex flex-column justify-content-center gap-1"
                key={index}
              >
                <h2>Proprietário carreta {index + 1}:</h2>
                <span>{name}</span>
              </Item>
            )}
          </Fragment>
        ))}
    </div>
  );
};
