import { Form } from "react-bootstrap";
import styled from "styled-components";

export const RemoveButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--color-blue-80);

  &:hover {
    color: red;
  }
`;

export const FileUploadContainer = styled.button`
  background-color: var(--color-blue-80);
  border: 1px solid var(--color-blue-80);
  padding: 25px;
  border-radius: 4px;
  color: white;

  label {
    font-size: 1.2rem;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 5px;
  height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  gap: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalButton = styled.button`
  background-color: var(--color-gray-3);
  color: var(--color-blue-80);
  border: none;
  border-radius: 10px;
  padding: 12px;
`;

export const TextFormArea = styled(Form.Control)`
  /* height: 150px; */
  width: 100%;
  border-radius: 4px;
  font-size: 1.1rem;
`;

export const ClearButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--color-blue-90);
  border-radius: 4px;
  color: var(--color-blue-90);
  font-weight: bold;
`;

export const TakePictureButton = styled.button`
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  color: white;
  background-color: var(--color-red-50);
  font-size: 40px;
  width: 80px;
  height: 80px;
`;

export const CloseCameraButton = styled.div`
  position: absolute;
  right: 30px;
  bottom: 5px;

  > button {
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    color: var(--color-blue-90);
    font-size: 35px;
    width: 50px;
    height: 50px;
  }

  @media (min-width: 500px) {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
