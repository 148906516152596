import { Table } from "react-bootstrap";
import styled from "styled-components";

export const TableBorder = styled(Table)`
border: 1px solid;
border-radius: 300px;
`


export const TableTitle = styled.tr`
th {
  font-size: 0.85rem;
  font-weight: bold;
}
`

export const AccessListContainer = styled.div`
  max-height: 25vh;
flex: 1;
overflow: auto;

 @media(min-width: 425px){
  max-height:40vh ;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }
 

 @media(min-width: 1440px){
 
  max-height: 80vh;
  }

  @media(min-width: 1640px){
 
  max-height: 75vh;
  }
  @media(min-width: 2440px){
 
  max-height: 80vh;
  }
`