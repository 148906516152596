import styled from "styled-components";

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--color-blue-80);
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;

  cursor: pointer;
`

export const FilterContent = styled.div`
  transition: all 0.5s ease;
`