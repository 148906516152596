import { FaMapMarkerAlt } from "react-icons/fa";
import styled from "styled-components";



export const LocationIcon = styled(FaMapMarkerAlt)`
  color: ${props => props.index === 0 ? "#07b141" : "#5f5f5f"};
`

export const LocationItem = styled.span`
  color: ${props => props.index === 0 ? "#07b141" : "#5f5f5f"};
  font-size: 0.875rem;
  display: flex;
  margin-top: 1rem;

  @media(min-width: 768px){
    margin-top: 0;
  }
`

export const StyledDivWithBorder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 0.7px solid var(--color-green); 
  width: 50%;
  display: none;

  @media (min-width: 767px){
    display: flex;
    width: 20%;
  }
  @media (min-width: 1440px){
    display: flex;
    width: 50%;
  }
`;

export const ListOfLoadingOrderCard = styled.div`
padding: 10px;

max-height: 30vh;
flex: 1;
overflow: auto;

 @media(min-width: 375px){
  max-height: 40vh;
 }

 @media(min-width: 576px){
  max-height: 60vh;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }
 

 @media(min-width: 1440px){
  overflow-x: auto;
  max-height: 80vh;
  }

  @media(min-width: 1640px){
  overflow-x: auto;
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  overflow-x: auto;
  max-height: 80vh;
  }
`

export const RealTimeLocationCardContainer = styled.div`  

  margin-bottom: 1rem;

  overflow: auto;
  max-height: 20vh;


  @media(min-width: 375px){
  overflow-x: auto;
  max-height: 50vh;
  }

  @media(min-width: 1440px){
  overflow-x: auto;
  max-height: 60vh;
  }

  @media(min-width: 1640px){
  overflow-x: auto;
  max-height: 80vh;
  }
`