import styled from "styled-components";

export const UnitTableTitle = styled.tr`
th {
  font-size: 0.85rem;
  font-weight: bold;
}
`

export const UnitListContainer = styled.div`
max-height: 35vh;
flex: 1;
overflow: auto;

 @media(min-width: 375px){
  max-height: 50vh;
 }

 @media(min-width: 425px){
  max-height: 55vh;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }
 

 @media(min-width: 1440px){
  overflow-x: auto;
  max-height: 80vh;
  }

  @media(min-width: 1640px){
  overflow-x: auto;
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  overflow-x: auto;
  max-height: 80vh;
  }
`
export const UnitContainer = styled.div`
max-height: 35vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 375px){
  max-height: 50vh;
 }

 @media(min-width: 425px){
  max-height: 55vh;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }
 

 @media(min-width: 1440px){
 
  max-height: 80vh;
  }

  @media(min-width: 1640px){
 
  max-height: 75vh;
  }
  @media(min-width: 2440px){
 
  max-height: 80vh;
  }
`