import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest from "../Shared/Utils/Request";
import { isCpfValid } from "../Shared/Utils/data";
import { convertDate } from "../Shared/Helpers/convertDate";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export const DriverContext = createContext({
  drivers: [],
  allDrivers: [],
  driverSat: null,
  loadingSatDriver: false,
  driversByStatus: [],
  driver: null,
  isLoading: false,
  isSubmitting: false,
  loadingAllDrivers: false,
  informationsDriver: null,
  driverConsults: [],
  ownerConsults: [],
  tempRegister: null,
  federalInformations: null,
  loadingFederalInformations: false,
  getTempRegister: () => null,
  getDrivers: () => {},
  getDriver: () => null,
  getDriversByStatus: () => null,
  addDriver: () => null,
  addTemporaryDriver: () => null,
  deleteDriver: () => null,
  updateDriver: () => null,
  updateDriversByStatus: () => null,
  getAllDrivers: () => null,
  getInformationsDriver: () => null,
  getDriverSat: () => null,
  getDriverConsults: () => null,
  postDriverConsults: () => null,
  deleteDriverFromTemporaryTable: () => null,
  addVehiclesAndOwners: () => null,
  updateTemporaryDriver: () => null,
});

export const useDrivers = () => {
  const context = useContext(DriverContext);

  if (!context) {
    throw new Error("useDrivers must be within DriverProvider");
  }

  return context;
};

export const DriverProvider = ({ children }) => {
  const [drivers, setDrivers] = useState([]);
  const [allDrivers, setAllDrivers] = useState([]);
  const [driverSat, setDriverSat] = useState(null);
  const [loadingSatDriver, setLoadingSatDriver] = useState(false);
  const [driversByStatus, setDriversByStatus] = useState([]);
  const [driver, setDriver] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTemporaryDriver, setIsLoadingTemporaryDriver] =
    useState(false);

  const [loadingAllDrivers, setLoadingAllDrivers] = useState(false);

  const [isSubmitting, setIsSubmiting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [tempRegister, setTempRegister] = useState(null);

  const [informationsDriver, setInformationsDriver] = useState(null);

  const [driverConsults, setDriverConsults] = useState([]);
  const [loadingFederalInformations, setLoadingInformations] = useState(false);
  const [loadingPostDriverConsult, setLoadingPostDriverConsult] =
    useState(false);

  const getDrivers = useCallback(async (currentPage, searchDriver, active) => {
    try {
      setIsLoading(true);

      const params = {
        perPage: 9,
        page: currentPage,
      };

      if (searchDriver) {
        if (isCpfValid(searchDriver)) {
          params.cpf = searchDriver;
        } else {
          params.nome = searchDriver;
        }
      }

      let currentData = {
        status: ["0r", "6r"],
      };

      if (active !== null) {
        currentData = {
          status: ["0r", "6r"],
          ativo: active,
        };
      }

      const response = await ApiRequest({
        path: "motoristas/status",
        method: "POST",
        params,
        data: currentData,
      });

      const driversData = response?.data?.data;

      setTotalPages(response?.data?.total_pages);
      setDrivers(driversData);
    } catch (error) {
      setDrivers([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getAllDrivers = useCallback(async () => {
    try {
      setLoadingAllDrivers(true);

      const params = {
        perPage: 10000000,
      };

      const response = await ApiRequest({
        path: "motoristas/status",
        method: "POST",
        params,
        data: { status: ["0r", "6r"] },
      });

      setAllDrivers(response?.data?.data);
    } catch (error) {
      setAllDrivers([]);
    } finally {
      setLoadingAllDrivers(false);
    }
  }, []);

  const getDriverSat = useCallback(async (id) => {
    try {
      setLoadingSatDriver(true);

      const response = await ApiRequest({
        path: `motoristas/sat/${id}`,
        method: "GET",
      });

      setDriverSat(response?.data);
    } catch (error) {
      setDriverSat(null);
    } finally {
      setLoadingSatDriver(false);
    }
  }, []);

  const getDriversByStatus = useCallback(async (currentPage, searchDriver) => {
    try {
      setIsLoading(true);

      const params = {
        perPage: 15,
        page: currentPage,
      };

      const status = ["1r", "2r", "5r", "7r", "10r"];

      let data = { status };

      if (searchDriver) {
        if (isCpfValid(searchDriver)) {
          data = {
            status,
            cpf: searchDriver,
          };
        } else {
          data = {
            status,
            nome: searchDriver,
          };
        }
      }

      const response = await ApiRequest({
        path: "motoristas/status",
        method: "POST",
        data: data,
        params,
      });

      const driversData = response?.data?.data || response?.data;

      setTotalPages(response?.data?.total_pages);
      setDriversByStatus(driversData);
    } catch (error) {
      setDriversByStatus([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getDriver = useCallback(async (id) => {
    if (id === null) {
      return setDriver(null);
    }

    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: `motoristas/id/${id}`,
        method: "GET",
      });
      window.scrollTo(0, 0);

      setDriver(response?.data);
    } catch (error) {
      setDriver(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getTempRegister = useCallback(async (id) => {
    try {
      setIsLoadingTemporaryDriver(true);

      const response = await ApiRequest({
        path: `cadastro-temp/id/${id}`,
        method: "GET",
      });

      setTempRegister(response?.data);

      return response?.data;
    } catch (error) {
      setTempRegister(null);
    } finally {
      setIsLoadingTemporaryDriver(false);
    }
  }, []);

  const getDriverConsults = useCallback(async (id, id_motorista) => {
    try {
      setLoadingInformations(true);

      const data = id
        ? { id_cadastro_temporario: id }
        : { id_motorista: id_motorista };

      const params = data;

      const response = await ApiRequest({
        path: "consultas/geral",
        method: "GET",
        params,
      });

      setDriverConsults(response?.data);
      setLoadingInformations(false);

      return response?.data;
    } catch (error) {
      setDriverConsults([]);
    } finally {
      setLoadingInformations(false);
    }
  }, []);

  const postDriverConsults = useCallback(async (token, posicao) => {
    try {
      setLoadingPostDriverConsult(true);

      const data = posicao ? { token, posicao } : { token };

      await ApiRequest({
        path: "consultas/geral",
        data,
        method: "POST",
      });

      Swal.fire({
        icon: "info",
        title: "Informação!",
        text: "Aguardando consulta. Pode demorar alguns minutos",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    } catch (error) {
      console.error({ error });
    } finally {
      setLoadingPostDriverConsult(false);
    }
  }, []);

  const updateDriver = useCallback(
    async (id, data, navigate) => {
      try {
        setIsSubmiting(true);

        const response = await ApiRequest({
          path: `motoristas/id/${id}`,
          method: "PUT",
          data,
        });

        await getDrivers();
        await getDriversByStatus();

        window.scrollTo(0, 0);

        if (navigate) {
          setDriver(null);
          navigate("/motoristas-listar");
        }

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "O motorista foi atualizado com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Ocorreu um erro ao atualizar o motorista.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } finally {
        setIsLoading(false);
        setIsSubmiting(false);
      }
    },
    [getDrivers, getDriversByStatus]
  );

  const updateTemporaryDriver = useCallback(
    async (
      id,
      token,
      data,
      anexoDriver,
      updateInformationsDriver,
      setIsEditingDriver
    ) => {
      try {
        setIsSubmiting(true);

        await ApiRequest({
          path: `motoristas/id/${id}`,
          method: "PUT",
          data: updateInformationsDriver,
        });

        await ApiRequest({
          path: `cadastro-temp/token/${token}`,
          method: "PUT",
          data: {
            anexos_motorista: anexoDriver,
            dados_formulario_motorista: data,
          },
        });

        await getDrivers();
        await getTempRegister(id);

        setIsEditingDriver(false);

        window.scrollTo(0, 0);

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "O motorista foi atualizado com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Ocorreu um erro ao atualizar o motorista.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setIsSubmiting(false);
      }
    },
    [getDrivers, getTempRegister]
  );

  const updateDriversByStatus = useCallback(
    async (id, data, navigate, route, currentPage) => {
      try {
        setIsLoading(true);

        await ApiRequest({
          path: `motoristas/id/${id}`,
          method: "PUT",
          data,
        });

        await getDriversByStatus(currentPage);

        window.scrollTo(0, 0);

        if (!route) {
          navigate("/motoristas-listar");
        }

        Swal.fire({
          icon: `${data?.status_cadastro === "7r" ? "warning" : "success"}`,
          title: "Atenção!",
          text:
            data?.status_cadastro === "0r"
              ? "Cadastro aprovado"
              : data?.status_cadastro === "1r"
              ? "Solicitação biometria"
              : data?.status_cadastro === "2r"
              ? "Em análise"
              : data?.status_cadastro === "3r"
              ? "Cadastro recusado"
              : data?.status_cadastro === "4r"
              ? "Processo com erro"
              : data?.status_cadastro === "5r"
              ? "Aguardando aprovação"
              : data?.status_cadastro === "6r"
              ? "Cadastro aprovado"
              : data?.status_cadastro === "7r"
              ? "Informe as inconsistências do cadastro"
              : (data?.status_cadastro === undefined ||
                  data?.status_cadastro === null ||
                  data?.status_cadastro === "") &&
                "Escolha uma ação",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        if (data?.status_cadastro === "7r") {
          setTimeout(() => navigate(`/motoristas-informacoes/${id}`), 250);
        }

        if (data?.status_cadastro === "0r" || data?.status_cadastro === "6r") {
          setTimeout(() => navigate("/motoristas-listar"), 250);
        }

        if (data?.status_cadastro === "3r") {
          navigate("/motoristas/processos-em-curso");
        }

        setIsLoading(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Cadastro do motorista não foi atualizado",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [getDriversByStatus]
  );

  const addVehiclesAndOwners = useCallback(
    async (tempRegister, data, navigate, driver) => {
      try {
        setIsLoading(true);

        await ApiRequest({
          path: `cadastro-temp-cadastro/token/${tempRegister?.token}`,
          method: "POST",
        });

        await updateDriversByStatus(driver?.id, data, navigate, true);

        setIsLoading(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Não foi possível cadastrar os veículos e o proprietário para este cadastro do motorista",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [updateDriversByStatus]
  );

  const deleteDriver = useCallback(
    async (id, navigate, processes) => {
      try {
        setIsLoading(true);

        await ApiRequest({
          path: `motoristas/id/${id}`,
          method: "DELETE",
        });

        window.scrollTo(0, 0);

        if (!processes) {
          await navigate("/motoristas-listar");
        }

        await getDriversByStatus(1);

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "O motorista foi excluído com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsLoading(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `${
            error?.response?.data?.error
              ? error?.response?.data?.error
              : "Não foi possível remover o cadastro do motorista"
          }`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [getDriversByStatus]
  );

  const deleteDriverFromTemporaryTable = useCallback(async (id) => {
    try {
      await ApiRequest({
        path: `/cadastro-temp/id/${id}`,
        method: "DELETE",
      });
    } catch (error) {
      console.error({ error });
    }
  }, []);

  const addDriver = useCallback(
    async (data, navigation) => {
      try {
        setShowAlert(false);
        setIsSubmiting(true);

        await ApiRequest({
          path: "motoristas",
          method: "POST",
          data: data,
        });

        window.scrollTo(0, 0);

        const response = await getDrivers();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "O motorista foi criado com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);

        navigation("/motoristas/processos-em-curso");

        return response?.data;
      } catch (error) {
        window.scrollTo(0, 0);

        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Cadastro do motorista não foi criado",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } finally {
        setIsLoading(false);
        setIsSubmiting(false);
      }
    },
    [getDrivers]
  );

  const addTemporaryDriver = useCallback(
    async (data, cnhType, navigation) => {
      try {
        setShowAlert(false);
        setIsSubmiting(true);

        await ApiRequest({
          path: "cadastro-temp",
          method: "POST",
          data: {
            cpf: data.cpf,
            anexos_motorista: {
              ...data,
              type: cnhType,
              blockNamePai: data.nome_pai.length ? false : true,
              dt_emissao_rg: convertDate(data.dt_emissao_rg),
              dt_emissao_cnh: convertDate(data.dt_emissao_cnh),
              dt_nascimento: convertDate(data.dt_nascimento),
              dt_primeira_cnh: convertDate(data.dt_primeira_cnh),
              dt_validade_cnh: convertDate(data.dt_validade_cnh),
            },
          },
        });

        window.scrollTo(0, 0);

        const response = await getDrivers();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "O motorista foi criado com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);

        navigation("/motoristas/processos-em-curso");

        return response?.data;
      } catch (error) {
        window.scrollTo(0, 0);

        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Cadastro do motorista não foi criado",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } finally {
        setIsLoading(false);
        setIsSubmiting(false);
      }
    },
    [getDrivers]
  );

  const getInformationsDriver = useCallback(async (id) => {
    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: `consulta_cnpj/motorista/${id}`,
        method: "GET",
      });

      setInformationsDriver(response?.data);
    } catch (error) {
      setInformationsDriver([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const providerValue = useMemo(
    () => ({
      drivers,
      allDrivers,
      driversByStatus,
      driver,
      isLoading,
      isLoadingTemporaryDriver,
      isSubmitting,
      showAlert,
      totalPages,
      loadingAllDrivers,
      informationsDriver,
      driverSat,
      loadingSatDriver,
      driverConsults,
      tempRegister,
      loadingFederalInformations,
      loadingPostDriverConsult,

      getDrivers,
      getAllDrivers,
      getDriver,
      addDriver,
      addTemporaryDriver,
      deleteDriver,
      updateDriver,
      getDriversByStatus,
      updateDriversByStatus,
      getInformationsDriver,
      getDriverSat,
      getDriverConsults,
      postDriverConsults,
      getTempRegister,
      deleteDriverFromTemporaryTable,
      addVehiclesAndOwners,
      updateTemporaryDriver,
    }),
    [
      drivers,
      allDrivers,
      driversByStatus,
      driver,
      isLoading,
      isLoadingTemporaryDriver,
      isSubmitting,
      showAlert,
      totalPages,
      loadingAllDrivers,
      informationsDriver,
      driverSat,
      loadingSatDriver,
      driverConsults,
      tempRegister,
      loadingFederalInformations,
      loadingPostDriverConsult,
      getDrivers,
      getAllDrivers,
      getDriver,
      addDriver,
      addTemporaryDriver,
      deleteDriver,
      updateDriver,
      getDriversByStatus,
      updateDriversByStatus,
      getInformationsDriver,
      getDriverSat,
      getDriverConsults,
      postDriverConsults,
      getTempRegister,
      deleteDriverFromTemporaryTable,
      addVehiclesAndOwners,
      updateTemporaryDriver,
    ]
  );

  return (
    <DriverContext.Provider value={providerValue}>
      {children}
    </DriverContext.Provider>
  );
};
