import { Card } from "react-bootstrap";
import VehicleInformationsCard from "../components/VehicleForms/VehicleInformationsCard";

import { Border, PageTitle } from "../components/VehicleForms/styles";
import { LineBorder, Subtitle } from "./styles";

const TruckInformationsCard = ({ vehicle, handleShowModal }) => {
  return (
    <Card className="w-100 h-100 card d-flex flex-column p-3">
      <Card.Body className="text-center">
        <section className="text-start p-0 mb-3">
          <PageTitle className="mb-1">Informações do cavalo</PageTitle>
          <Border>
            <VehicleInformationsCard
              vehicle={vehicle}
              handleShowModal={handleShowModal}
            />
          </Border>
        </section>
        {vehicle?.carretas.length > 0 && (
          <section className="text-start p-0 mb-1">
            <PageTitle className="mb-1">
              {vehicle?.carretas.length > 0
                ? "Informações das carretas"
                : "Informações da carreta"}
            </PageTitle>
            <Border>
              {vehicle?.carretas.map((carreta, index) => (
                <div key={index}>
                  <Subtitle className="fw-bold mb-0">
                    Carreta {index + 1}
                  </Subtitle>
                  <LineBorder />
                  <VehicleInformationsCard
                    vehicle={carreta}
                    handleShowModal={handleShowModal}
                  />
                </div>
              ))}
            </Border>
          </section>
        )}
      </Card.Body>
    </Card>
  );
};

export default TruckInformationsCard;
