import { Col, Row } from "react-bootstrap";
import Dashboard from "../../../../../Shared/layout";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import StatisticCard from "../../../../../Shared/Components/StatisticCard/StatisticCard";
import Search from "../../../../../Shared/Components/Search/Search";
import { sortedNames } from "../../../../../Shared/Utils/data";
import TablePagination from "../../../../../Shared/Components/PaginationTable/PaginationTable";
import Select from "react-select";
import OwnerCard from "../components/OwnerCard/OwnerCard";
import { useOwners } from "../../../../../hooks/OwnerProvider";
import { activeOptions } from "../../../../../Shared/Utils/constants";
import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";
import Loader from "../../../../../Shared/Components/Loader/Loader";
import { LoadingContainer, OwnerListContainer, OwnerListRow } from "./styles";
import { ListLoader } from "../../../../../Shared/Components/ListLoader/ListLoader";

const OwnersList = () => {
  const [searchOwner, setSearchOwner] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isActive, setIsActive] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  const {
    owners,
    allOwners,
    totalPages,
    isLoading,
    isLoadingAllOwners,
    getOwners,
    getOwner,
    getAllOwners,
  } = useOwners();

  useEffect(() => {
    getAllOwners();
  }, [getAllOwners]);

  useEffect(() => {
    if (isTyping) {
      setTimeout(() => {
        setIsTyping(false);
      }, 1000);
    }
  }, [isTyping]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchOwner(event && event !== "" ? event.target.value : "");
    setCurrentPage(1);
    setIsTyping(true);
  };

  const handleSelectActiveStatus = useCallback(
    (event) => {
      setCurrentPage(1);

      if (event?.label === "Em análise") {
        return getOwners(currentPage, searchOwner, true, "9");
      }

      if (event?.label === "Liberados") {
        setIsActive(true);
        return getOwners(currentPage, searchOwner, true);
      }

      if (event?.label === "Desativados" || event?.label === "Bloqueado ADM") {
        setIsActive(false);
        return getOwners(currentPage, searchOwner, false);
      }

      if (event?.label === "Listar todos") {
        setIsActive(null);
        return getOwners(currentPage, searchOwner);
      }
    },
    [currentPage, getOwners, searchOwner]
  );

  const filteresOwners = useMemo(() => {
    return (
      owners &&
      owners.filter((driver) => {
        const matchesSearch =
          driver?.nome.toLowerCase().includes(searchOwner.toLowerCase()) ||
          driver?.cpf_cnpj.toLowerCase().includes(searchOwner.toLowerCase());
        return matchesSearch;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owners, searchOwner, handleSelectActiveStatus]);

  useEffect(() => {
    if (!isTyping) {
      getOwners(currentPage, searchOwner, isActive);
    }
  }, [currentPage, getOwners, isActive, isTyping, searchOwner]);

  return (
    <Dashboard title="Proprietários cadastrados">
      <Row className="mb-2 d-flex justify-content-end mt-1">
        <Col md={5} xl={3}>
          <Search
            label="Pesquisar proprietário por nome ou CPF"
            searchValue={searchOwner}
            handleSearchChange={handleSearchChange}
          />
        </Col>
      </Row>
      <OwnerListContainer xs={1} md={2} className="g-3 h-100">
        <Col md={9} lg={10} className="d-flex flex-column">
          <div className="d-flex">
            <Select
              className="react-select mb-3"
              options={activeOptions}
              onChange={handleSelectActiveStatus}
              value={isActive}
              placeholder="-- Filtrar proprietário --"
            />
          </div>
          {filteresOwners.length === 0 && !isLoading && !isTyping && (
            <EmptyTableMessage />
          )}
          {(isLoading || isTyping) && <ListLoader />}
          <OwnerListRow xs={1} md={2} xl={3} className="g-3 mb-2">
            {filteresOwners &&
              owners &&
              !isTyping &&
              !isLoading &&
              Array.isArray(owners) &&
              sortedNames(filteresOwners).map((owner) => (
                <Col className="d-flex" key={owner?.id}>
                  <OwnerCard owner={owner} getOwner={getOwner} />
                </Col>
              ))}
          </OwnerListRow>

          <div className="mt-auto d-flex align-items-end">
            {!isLoading &&
              owners &&
              Array.isArray(owners) &&
              owners.length > 0 && (
                <TablePagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              )}
            {isLoading && (
              <LoadingContainer>
                <Loader />
              </LoadingContainer>
            )}
          </div>
        </Col>

        <Col md={3} lg={2}>
          {isLoadingAllOwners && <Loader />}
          {!isLoadingAllOwners && (
            <StatisticCard items={allOwners} label="proprietários" />
          )}
        </Col>
      </OwnerListContainer>
    </Dashboard>
  );
};

export default memo(OwnersList);
