import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { routesPaths } from "../Shared/Utils/routesPaths";
import { replaceVariablesInPath } from "../Shared/Utils/data";

export const RoutesContext = createContext({
  routes: [],
  getRoutes: () => {},
});

export const useRoutes = () => {
  const context = useContext(RoutesContext);

  if (!context) {
    throw new Error("useRoutes must be within RoutesProvider");
  }

  return context;
};

export const RoutesProvider = ({ children }) => {
  const [routes, setRoutes] = useState(() => {
    const storedRoutes = localStorage.getItem("routes");
    return storedRoutes
      ? JSON.parse(storedRoutes)
      : [{ path: "/inicio", title: "Início" }];
  });

  const getRoutes = useCallback((location, id, token, company) => {
    const newRoutes = routesPaths.map((route) => ({
      path: replaceVariablesInPath(route.path, id, token, company),
      title: route.title,
    }));

    const currentTitle = newRoutes.find(
      (route) => route.path === location
    )?.title;

    const newRoute = { path: location, title: currentTitle };

    setRoutes((prevRoutes) => {
      if (location === "/inicio") {
        return [{ path: "/inicio", title: "Início" }];
      }

      if (prevRoutes.length > 0) {
        const index = prevRoutes.findIndex((route) => route.path === location);
        if (index !== -1) {
          return prevRoutes.slice(0, index + 1);
        }
      }

      if (
        prevRoutes.length > 0 &&
        prevRoutes[prevRoutes.length - 1].path === location
      ) {
        return prevRoutes;
      }

      if (
        location === "/gestao-viagens" ||
        location === "/gestao-cadastros" ||
        location === "/painel-configuracao"
      ) {
        return [{ path: "/inicio", title: "Início" }, newRoute];
      }

      if (
        location === "/motoristas" ||
        location === "/proprietarios" ||
        location === "/veiculos"
      ) {
        return [
          { path: "/inicio", title: "Início" },
          { path: "/gestao-cadastros", title: "Gestão de cadastros" },
          newRoute,
        ];
      }

      if (
        location === "/empresas" ||
        location === "/unidades" ||
        location === "/perfis" ||
        location === "/usuarios" ||
        location === "/configuracoes-acesso"
      ) {
        return [
          { path: "/inicio", title: "Início" },
          { path: "/painel-configuracao", title: "Painel de configuração" },
          newRoute,
        ];
      }

      return [...prevRoutes, newRoute];
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("routes", JSON.stringify(routes));
  }, [routes]);

  const providerValue = useMemo(
    () => ({
      routes,
      getRoutes,
    }),
    [routes, getRoutes]
  );

  return (
    <RoutesContext.Provider value={providerValue}>
      {children}
    </RoutesContext.Provider>
  );
};
