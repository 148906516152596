import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Dashboard from "../../../../Shared/layout";
import { useParams } from "react-router-dom";
import { useTravels } from "../../../../hooks/TravelProvider";
import { OccurencesCard } from "../RealTimeLocation/OccurrencesCard";
import { BsPlus } from "react-icons/bs";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import {
  Container,
  FontInputTitle,
  OccurrenceContainer,
  TextArea,
} from "./styles";
import Select from "react-select";
import { colourSelectStyles } from "../../../../Shared/Utils/constants";
import { useOccurrences } from "../../../../hooks/OccurrenceTypeProvider";
import { Controller, useForm } from "react-hook-form";
import { get } from "lodash";
import { SubmitButton } from "../../../../Shared/Components/SubmitButton/SubmitButton";
import { Form } from "react-bootstrap";
import { useLogins } from "../../../../hooks/LoginProvider";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import { sortedLabels } from "../../../../Shared/Utils/data";

const initialOccurrenceTypeSelected = {
  id: "Selecione o tipo de ocorrência",
  label: "-- Selecione o tipo de ocorrência --",
};

const SendOccurrences = () => {
  const [occurrenceAmount, setOccurrenceAmount] = useState(3);
  const [selectedOccurrenceType, setSelectedOccurrenceType] = useState(
    initialOccurrenceTypeSelected
  );

  const { token } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const {
    loadOrder,
    occurrences,
    isLoadingOccurrence,
    getOccurrences,
    getLoadOrder,
    addOccurences,
  } = useTravels();

  const { userIdConnected } = useLogins();

  const { isLoadingOccurrencesTypes, occurrencesTypes, getOccurrencesTypes } =
    useOccurrences();

  useEffect(() => {
    getOccurrencesTypes();
  }, [getOccurrencesTypes]);

  useEffect(() => {
    getLoadOrder(token);
  }, [getLoadOrder, token]);

  useEffect(() => {
    loadOrder && getOccurrences(loadOrder?.id);
  }, [getLoadOrder, getOccurrences, loadOrder]);

  useEffect(() => {
    const eventSource = new EventSource(`/sse/ocorrencia/${loadOrder?.id}`);

    eventSource.onmessage = (event) => {
      getOccurrences(loadOrder?.id);
    };

    eventSource.onerror = (error) => {
      console.error("Erro de SSE:", error);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [loadOrder, getOccurrences]);

  const handleSeeMoreOccurrences = useCallback(() => {
    setOccurrenceAmount((state) => state + 10);
    timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
  }, []);

  const handleSelectOccurrenceType = useCallback(
    (event) => {
      setSelectedOccurrenceType(event);
      setValue("tipo_ocorrencia_id", event.id);
      setValue("categoria", event.label);
    },
    [setValue]
  );

  const occurrencesTypesList = useMemo(() => {
    const driverOptions =
      occurrencesTypes &&
      occurrencesTypes.map((occurrenceType) => ({
        id: occurrenceType?.id,
        label: occurrenceType?.nome,
      }));

    return driverOptions
      ? [initialOccurrenceTypeSelected, ...driverOptions]
      : [initialOccurrenceTypeSelected];
  }, [occurrencesTypes]);

  const onSubmitOccurrence = useCallback(
    (data) => {
      data.usuario_id = parseInt(userIdConnected);

      addOccurences(loadOrder?.id, data);
      reset();
    },
    [userIdConnected, addOccurences, loadOrder?.id, reset]
  );

  const timelineRef = useRef();

  return (
    <Dashboard title="Enviar ocorrência">
      {isLoadingOccurrence && <Loading />}

      {!isLoadingOccurrence && loadOrder?.token && occurrences.length === 0 && (
        <div className="mb-2 mt-4">
          <EmptyTableMessage label="Sem ocorrência registrada" />
        </div>
      )}

      <Container>
        {!isLoadingOccurrence && loadOrder?.token && occurrences.length > 0 && (
          <div className="d-flex gap-3 flex-column mb-2 mt-2">
            <OccurrenceContainer ref={timelineRef}>
              <OccurencesCard
                occurrences={occurrences}
                occurrenceAmount={occurrenceAmount}
                userIdConnected={userIdConnected}
                loadOrder={loadOrder}
                heightSize="350"
              />
            </OccurrenceContainer>
            {occurrenceAmount < occurrences.length && (
              <div className="d-flex justify-content-end">
                <ButtonLink
                  label="Ver mais"
                  secondIcon={<BsPlus />}
                  fontSize="0.7rem"
                  handleClick={handleSeeMoreOccurrences}
                  buttoncolor="primary"
                />
              </div>
            )}
          </div>
        )}

        {!isLoadingOccurrence && !isLoadingOccurrencesTypes && (
          <Form onSubmit={handleSubmit(onSubmitOccurrence)}>
            <div className="d-flex justify-content-start">
              <Form.Group controlId="formOwner">
                <FontInputTitle className="d-flex align-items-center">
                  Tipo de ocorrência
                </FontInputTitle>
                <Controller
                  name="tipo_ocorrencia_id"
                  control={control}
                  rules={{
                    required:
                      selectedOccurrenceType.id ===
                        "Selecione o tipo de ocorrência" &&
                      "O tipo de ocorrência é obrigatório",
                  }}
                  render={() => (
                    <Select
                      placeholder="--Selecione o tipo de ocorrência --"
                      options={sortedLabels(occurrencesTypesList)}
                      styles={colourSelectStyles(true)}
                      onChange={handleSelectOccurrenceType}
                      defaultValue={selectedOccurrenceType.id}
                    />
                  )}
                />
                {get(errors, "tipo_ocorrencia_id") && (
                  <Form.Text className="text-danger">
                    {get(errors, "tipo_ocorrencia_id")?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </div>
            <Form.Group>
              <FontInputTitle className="w-100 mt-2">Mensagem</FontInputTitle>
              <TextArea
                className="is-required w-100 rounded"
                label="Insira uma nova ocorrência"
                as="textarea"
                {...register("mensagem", {
                  required: "A mensagem é obrigatória",
                })}
              />
              {errors?.mensagem && (
                <Form.Text className="text-danger">
                  {errors?.mensagem?.message}
                </Form.Text>
              )}
            </Form.Group>
            <div className="d-flex gap-2 mt-1 justify-content-end py-2">
              <SubmitButton labelButton="Salvar" />
              <ButtonLink
                link={`/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/${token}`}
                label="Cancelar"
                fontSize="0.7rem"
              />
            </div>
          </Form>
        )}
      </Container>
    </Dashboard>
  );
};

export default memo(SendOccurrences);
