import { Card, Col, Row } from "react-bootstrap";
import moment from "moment";
import {
  InformationsRow,
  InformationsSection,
  Item,
  LoaderOrderStatus,
  TravelsInformationsCard,
} from "./styles";
import { BiIdCard } from "react-icons/bi";
import { AiOutlineFieldNumber, AiOutlineNumber } from "react-icons/ai";
import { BsArrowRepeat, BsCalendar3, BsPerson, BsPin } from "react-icons/bs";

import {
  MdOutlineDescription,
  MdOutlinePlace,
  MdPassword,
} from "react-icons/md";
import { loaderOrderStatus } from "../../../../Shared/Utils/constants";
import {
  cnpjCpfFormat,
  formatCEP,
  formatNameString,
} from "../../../../Shared/Utils/data";
import { useMemo, useState } from "react";
import ImageModal from "../../../../Shared/Components/ImageModal/ImageModal";
import { ValidateLoadingOrderClosure } from "./ValidateLoadingOrderClosure";
import { WarningMessageCard } from "./WarningMessageCard";

export const TravelsInformations = ({ loadOrder, editLoadOrder }) => {
  const [showModal, setShowModal] = useState(false);
  const currentLocation = useMemo(() => {
    if (loadOrder?.localizacao_atual) {
      const geoLocation = JSON.parse(loadOrder?.localizacao_atual);

      return geoLocation;
    }
  }, [loadOrder?.localizacao_atual]);

  const handleCallModal = () => setShowModal(!showModal);

  return (
    <TravelsInformationsCard className="w-100  card d-flex flex-column p-3">
      {loadOrder?.status === "6" && (
        <WarningMessageCard loadOrder={loadOrder} />
      )}
      <Card.Body>
        {(loadOrder.status === "10" || loadOrder.status === "2") && (
          <ValidateLoadingOrderClosure
            loadOrder={loadOrder}
            handleCallModal={handleCallModal}
            editLoadOrder={editLoadOrder}
          />
        )}
        <Row xs={1} md={2}>
          <Col className="d-flex  flex-column gap-2">
            {loadOrder?.localizacao_atual &&
              loadOrder?.status !== "2" &&
              loadOrder?.status !== "9" && (
                <Item className="gap-1">
                  <BsPin size={15} color="#093c5e" />
                  <h3>Localização atual:</h3>
                  <h4>
                    {loadOrder?.localizacao_atual
                      ? `${currentLocation?.cidade} - ${currentLocation?.uf}`
                      : "--"}
                  </h4>
                </Item>
              )}
            <Item className="gap-1">
              <BsCalendar3 size={13} color="#093c5e" />
              <h3>Data do processo:</h3>
              <h4>
                {loadOrder?.data_processo
                  ? moment.utc(loadOrder?._processo).format("DD/MM/YYYY")
                  : "--"}
              </h4>
            </Item>

            <Item className="gap-1">
              <BsCalendar3 size={13} color="#093c5e" />
              <h3>Data de emissão OC:</h3>
              <h4>
                {loadOrder?.createdAt
                  ? moment.utc(loadOrder?.createdAt).format("DD/MM/YYYY")
                  : "--"}
              </h4>
            </Item>

            <Item className="gap-1">
              <AiOutlineFieldNumber size={17} color="#093c5e" />
              <h3>Número OC:</h3>
              <h4>
                {loadOrder?.id_ordem_carregamento
                  ? loadOrder?.id_ordem_carregamento
                  : "--"}
              </h4>
            </Item>
          </Col>

          <Col className="d-flex flex-column gap-2">
            <Item className="gap-1">
              <BsArrowRepeat size={15} color="#093c5e" />
              <h3>Status:</h3>
              <LoaderOrderStatus status={loadOrder?.status}>
                {loaderOrderStatus(loadOrder?.status)}
              </LoaderOrderStatus>
            </Item>

            <Item className="gap-1">
              <MdPassword size={15} color="#093c5e" />
              <h3>Senha OC:</h3>
              <h4>{loadOrder?.contra_senha ?? "--"}</h4>
            </Item>

            <Item className="gap-1">
              <MdPassword size={15} color="#093c5e" />
              <h3>Resposta senha OC:</h3>
              <h4>{loadOrder?.senha_resposta ?? "--"}</h4>
            </Item>
          </Col>
        </Row>
        <InformationsSection>
          <div>
            <h2>Cliente</h2>
            <Item className="gap-1">
              <BsPerson size={15} color="#093c5e" />
              <h3>Nome:</h3>
              <h4>
                {loadOrder?.nome_cliente
                  ? formatNameString(loadOrder?.nome_cliente)
                  : "--"}
              </h4>
            </Item>
            <Item className="gap-1">
              <BiIdCard size={17} color="#093c5e" />
              <h3>CPF/CNPJ:</h3>
              <h4>
                {loadOrder?.cpf_cnpj_cliente
                  ? cnpjCpfFormat(loadOrder?.cpf_cnpj_cliente)
                  : "--"}
              </h4>
            </Item>
          </div>

          <div>
            <h2>Remetente</h2>
            <Item className="gap-1">
              <BsPerson size={15} color="#093c5e" />
              <h3>Nome:</h3>
              <h4>
                {loadOrder?.nome_remetente
                  ? formatNameString(loadOrder?.nome_remetente)
                  : "--"}
              </h4>
            </Item>
            <Item className="gap-1">
              <BiIdCard size={17} color="#093c5e" />
              <h3>CPF/CNPJ:</h3>
              <h4>
                {loadOrder?.cpf_cnpj_remetente
                  ? cnpjCpfFormat(loadOrder?.cpf_cnpj_remetente)
                  : "--"}
              </h4>
            </Item>
          </div>

          <div>
            <h2>Destinatário</h2>
            <Item className="gap-1">
              <BsPerson size={15} color="#093c5e" />
              <h3>Nome:</h3>
              <h4>
                {loadOrder?.nome_destinatario
                  ? formatNameString(loadOrder?.nome_destinatario)
                  : "--"}
              </h4>
            </Item>
            <Item className="gap-1">
              <BiIdCard size={17} color="#093c5e" />
              <h3>CPF/CNPJ:</h3>
              <h4>
                {loadOrder?.cpf_cnpj_destinatario
                  ? cnpjCpfFormat(loadOrder?.cpf_cnpj_destinatario)
                  : "--"}
              </h4>
            </Item>
          </div>
        </InformationsSection>
        <InformationsSection>
          <div>
            <h2>Coleta</h2>
            <InformationsRow md={2}>
              <Col>
                <Item className="gap-1">
                  <MdPassword size={15} color="#093c5e" />
                  <h3>Código:</h3>
                  <h4>{loadOrder?.numero_coleta ?? "--"}</h4>
                </Item>
                <Item className="gap-1">
                  <BsPin size={15} color="#093c5e" />
                  <h3>Origem:</h3>
                  <h4>
                    {loadOrder?.nome_cidade_coleta
                      ? `${formatNameString(loadOrder?.nome_cidade_coleta)} (${
                          loadOrder?.uf_coleta &&
                          formatNameString(loadOrder?.uf_coleta)
                        })`
                      : "--"}
                  </h4>
                </Item>

                <Item className="gap-1">
                  <MdOutlinePlace size={15} color="#093c5e" />
                  <h3>Endereço:</h3>
                  <h4>
                    {loadOrder?.endereco_coleta
                      ? `${formatNameString(loadOrder?.endereco_coleta)} ${
                          loadOrder?.bairro_coleta
                            ? formatNameString(loadOrder?.bairro_coleta)
                            : "--"
                        }`
                      : "--"}
                  </h4>
                </Item>
                <Item className="gap-1">
                  <AiOutlineNumber size={15} color="#093c5e" />
                  <h3>CEP:</h3>
                  <h4>
                    {loadOrder?.cep_coleta
                      ? formatCEP(loadOrder?.cep_coleta)
                      : "--"}
                  </h4>
                </Item>
              </Col>
              <Col>
                <Item className="gap-1">
                  <MdOutlinePlace size={15} color="#093c5e" />
                  <h3>Latitude:</h3>
                  <h4>{loadOrder?.latitude_coleta ?? "--"}</h4>
                </Item>
                <Item className="gap-1">
                  <MdOutlinePlace size={15} color="#093c5e" />
                  <h3>Longitude:</h3>
                  <h4>{loadOrder?.longitude_coleta ?? "--"}</h4>
                </Item>

                <Item className="gap-1">
                  <MdOutlineDescription size={15} color="#093c5e" />
                  <h3>Descrição:</h3>
                  <h4>
                    {loadOrder?.descricao_coleta
                      ? loadOrder?.descricao_coleta
                      : "--"}
                  </h4>
                </Item>
                <Item className="gap-1">
                  <BiIdCard size={17} color="#093c5e" />
                  <h3>CPF/CNPJ:</h3>
                  <h4>
                    {loadOrder?.cnpj_cpf_coleta
                      ? cnpjCpfFormat(loadOrder?.cnpj_cpf_coleta)
                      : "--"}
                  </h4>
                </Item>
              </Col>
            </InformationsRow>
          </div>

          <div>
            <h2>Entrega</h2>
            <InformationsRow md={2}>
              <Col>
                <Item className="gap-1">
                  <MdPassword size={15} color="#093c5e" />
                  <h3>Código:</h3>
                  <h4>
                    {loadOrder?.numero_entrega
                      ? loadOrder?.numero_entrega
                      : "--"}
                  </h4>
                </Item>
                <Item className="gap-1">
                  <BsPin size={15} color="#093c5e" />
                  <h3>Destino:</h3>
                  <h4>
                    {loadOrder?.nome_cidade_destino
                      ? `${formatNameString(
                          loadOrder?.nome_cidade_destino
                        )} (${formatNameString(loadOrder?.uf_cidade_destino)})`
                      : "--"}
                  </h4>
                </Item>
                <Item className="gap-1">
                  <MdOutlinePlace size={15} color="#093c5e" />
                  <h3>Endereço:</h3>
                  <h4>
                    {loadOrder?.endereco_entrega
                      ? `${formatNameString(loadOrder?.endereco_entrega)} ${
                          loadOrder?.bairro_entrega
                            ? formatNameString(loadOrder?.bairro_entrega)
                            : ""
                        }`
                      : "--"}
                  </h4>
                </Item>
                <Item className="gap-1">
                  <AiOutlineNumber size={15} color="#093c5e" />
                  <h3>CEP:</h3>
                  <h4>
                    {loadOrder?.cep_entrega
                      ? formatCEP(loadOrder?.cep_entrega)
                      : "--"}
                  </h4>
                </Item>
              </Col>
              <Col>
                <Item className="gap-1">
                  <MdOutlinePlace size={15} color="#093c5e" />
                  <h3>Latitude:</h3>
                  <h4>{loadOrder?.latitude_entrega ?? "--"}</h4>
                </Item>
                <Item className="gap-1">
                  <MdOutlinePlace size={15} color="#093c5e" />
                  <h3>Longitude:</h3>
                  <h4>{loadOrder?.longitude_entrega ?? "--"}</h4>
                </Item>
                <Item className="gap-1">
                  <MdOutlineDescription size={15} color="#093c5e" />
                  <h3>Descrição:</h3>
                  <h4>
                    {loadOrder?.descricao_entrega
                      ? loadOrder?.descricao_entrega
                      : "--"}
                  </h4>
                </Item>
                <Item className="gap-1">
                  <BiIdCard size={17} color="#093c5e" />
                  <h3>CPF/CNPJ:</h3>
                  <h4>
                    {loadOrder?.cnpj_cpf_entrega
                      ? cnpjCpfFormat(loadOrder?.cnpj_cpf_entrega)
                      : "--"}
                  </h4>
                </Item>
              </Col>
            </InformationsRow>
          </div>
        </InformationsSection>
      </Card.Body>
      <ImageModal
        show={showModal}
        handleClose={handleCallModal}
        image={
          loadOrder?.documentos_finalizacao &&
          loadOrder?.documentos_finalizacao.length > 0 &&
          loadOrder?.documentos_finalizacao[0]
        }
      />
    </TravelsInformationsCard>
  );
};
