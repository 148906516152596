import { useForm } from "react-hook-form";
import Dashboard from "../../../../../Shared/layout";

import { memo, useCallback, useEffect, useState } from "react";
import { Card, Col, Form } from "react-bootstrap";
import { AccortionTitle } from "../components/VehicleForms/AccordionTitle";
import TrucksInformations from "../components/VehicleForms/TrucksInformations";
import ActiveAndBlockForm from "../components/VehicleForms/ActiveAndBlockForm";

import { SubmitButton } from "../../../../../Shared/Components/SubmitButton/SubmitButton";
import ButtonLink from "../../../../../Shared/Components/ButtonLink/ButtonLink";
import { useNavigate } from "react-router-dom";
import { useDrivers } from "../../../../../hooks/DriverProvider";
import { useVehicles } from "../../../../../hooks/VehicleProvider";
import { useOwners } from "../../../../../hooks/OwnerProvider";
import OwnerAssociationForm from "../components/VehicleForms/OwnerAssociationForm";
import VehicleForm from "../components/VehicleForms/VehicleForm";
import { DriverFormRow } from "../../Drivers/components/DriversForms/styles";
import UploadVehicleDocImage from "../components/VehicleForms/UploadVehicleDocImage";
import UploadOwnerVehicleDocImage from "../components/VehicleForms/UploadOwnerVehicleDocImage";

const AddVehicle = () => {
  const [showTruck, setShowTruck] = useState(0);
  const [activeKey, setActiveKey] = useState("0");
  const [nextIndex, setNextIndex] = useState(0);

  const [visibleTrucks, setVisibleTrucks] = useState(
    Array.from({ length: showTruck }, (_, i) => i)
  );

  const [OCRImage, setOCRImage] = useState(null);

  const navigate = useNavigate();

  const { isLoading, addVehicle } = useVehicles();

  const { owners, getOwners } = useOwners();
  const { drivers, getDrivers } = useDrivers();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getOwners();
    getDrivers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActiveTruckClick = useCallback(
    (eventKey) => {
      setActiveKey(eventKey === activeKey ? null : eventKey);
    },
    [activeKey]
  );

  const handleAddTruck = useCallback(() => {
    const newVisibleTrucks = [...visibleTrucks, nextIndex];
    setVisibleTrucks(newVisibleTrucks); // OK
    setShowTruck((prevState) => prevState + 1);

    setActiveKey(newVisibleTrucks[newVisibleTrucks.length - 1]);
    setNextIndex((prevState) => prevState + 1);
  }, [nextIndex, visibleTrucks]);

  const handleDeleteData = useCallback(
    (index, _) => {
      const updatedVisibleTrucks = visibleTrucks.filter((i) => i !== index);
      setVisibleTrucks(updatedVisibleTrucks);
      setShowTruck((prevState) => prevState - 1);
    },
    [visibleTrucks]
  );

  const onSubmit = (data) => {
    data.status = "9";
    data.documento = data?.documento && parseFloat(data?.documento);

    data.placa = data.placa.replace(/[^A-Za-z0-9]/g, "").toUpperCase();

    data.bloqueado_adm = data?.bloqueado_adm === true ? "S" : "N";
    data.documento = data.documento && data.documento.toString();

    data.proprietario_id = data?.proprietario_arrendatario_id;
    data.antt = "000";
    data.dt_validade_antt = "000";
    data.dt_vencimento_documento = "000";
    data.status_rntrc = "NAOCONSTA";

    if (data.motorista_id === "Sem motorista" || data.motorista_id === null) {
      delete data.motorista_id;
    }

    const carretas = Object.keys(data).filter(
      (key) => key.startsWith("carreta") ?? []
    );
    const indexes = [];

    let filteredData = data;

    carretas &&
      carretas.length > 0 &&
      carretas.forEach((carreta, index) => {
        if (!visibleTrucks.includes(index)) {
          const newData = Object.entries(data).filter(
            ([key, value]) => data[carreta] !== value
          );

          filteredData = Object.fromEntries(newData);

          delete data[carreta];
        } else {
          indexes.push(index);
        }
      });

    indexes.length > 0 &&
      indexes.forEach((index, newIndex) => {
        const carreta = `carreta${index + 1}`;

        filteredData[`carreta${newIndex + 1}`] = filteredData[carreta];
        filteredData[`carreta${newIndex + 1}`].posicao = (
          newIndex + 1
        ).toString();

        filteredData[`carreta${newIndex + 1}`].proprietario_id =
          filteredData[`carreta${newIndex + 1}`].proprietario_arrendatario_id;

        filteredData[`carreta${newIndex + 1}`].antt = "000";
        filteredData[`carreta${newIndex + 1}`].dt_validade_antt = "000";
        filteredData[`carreta${newIndex + 1}`].dt_vencimento_documento = "000";
        filteredData[`carreta${newIndex + 1}`].status_rntrc = "NAOCONSTA";
      });

    addVehicle(data, navigate);
  };

  return (
    <Dashboard>
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
        <DriverFormRow xs={1} className="g-3">
          <Col className="h-100 d-flex">
            <Card className="w-100 h-100 p-2">
              <Card.Body className="text-start d-flex flex-column gap-3">
                <VehicleForm
                  register={register}
                  errors={errors}
                  title="Cadastro do Cavalo"
                  setValue={setValue}
                  setOCRImage={setOCRImage}
                />
                <OwnerAssociationForm
                  errors={errors}
                  owners={owners}
                  drivers={drivers}
                  setValue={setValue}
                  control={control}
                />
                <ActiveAndBlockForm register={register} setValue={setValue} />

                <div className="d-flex flex-column flex-md-row gap-2 g-md-4">
                  <Form.Group>
                    <UploadVehicleDocImage
                      setValue={setValue}
                      errors={errors}
                      width="10.4rem"
                      height="10.4rem"
                      align="start"
                      register={register}
                      OCRImage={OCRImage}
                    />
                  </Form.Group>
                  <Form.Group>
                    <UploadOwnerVehicleDocImage
                      setValue={setValue}
                      errors={errors}
                      width="10.4rem"
                      height="10.4rem"
                      align="start"
                      register={register}
                    />
                  </Form.Group>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col id="truck-informations-col">
            <Card className=" w-100 h-100 d-flex flex-column  flex-grow-1 p-2">
              <AccortionTitle
                handleAddTruck={handleAddTruck}
                setShowTruck={setShowTruck}
                visibleTrucks={visibleTrucks}
              />
              <Card.Body className="text-start">
                <TrucksInformations
                  activeKey={activeKey}
                  control={control}
                  drivers={drivers}
                  errors={errors}
                  handleActiveTruckClick={handleActiveTruckClick}
                  handleDeleteData={handleDeleteData}
                  owners={owners}
                  register={register}
                  setValue={setValue}
                  showTruck={showTruck}
                  visibleTrucks={visibleTrucks}
                />
              </Card.Body>
            </Card>
            <div className="d-flex  justify-content-center">
              <Form.Group className="p-3 mt-3 ">
                {Object.keys(errors).length > 0 && (
                  <Form.Text className="text-danger">
                    Preencha todos os campos
                  </Form.Text>
                )}
                <div className="d-flex gap-3 mt-3">
                  <SubmitButton
                    labelButton="Salvar"
                    isSubmitting={isLoading}
                    fontSize="0.85rem"
                  />
                  <ButtonLink
                    label="Cancelar"
                    link="/veiculos"
                    fontSize="0.85rem"
                    buttoncolor="secondary"
                  />
                </div>
              </Form.Group>
            </div>
          </Col>
        </DriverFormRow>
      </Form>
    </Dashboard>
  );
};

export default memo(AddVehicle);
