import { Table } from "react-bootstrap";
import { FiEdit2 } from "react-icons/fi";
import { ActiveInput } from "../../../../../../Shared/Components/ActiveInput/ActiveInput";
import {
  formatString,
  sortedFantasyNames,
} from "../../../../../../Shared/Utils/data";
import { CoverImage } from "../../../../../../Shared/Components/CoverImage/CoverImage";
import { CompanyTableContainer, CompanyTableTitle } from "./styles";
import { EmptyTableMessage } from "../../../../../../Shared/Components/EmptyTableMessage";
import { FaTrashAlt } from "react-icons/fa";
import { ActionIconButton } from "../../../../../../Shared/Components/ActionIconButton";

export const CompanyTable = ({
  companyManagementPermissionToUpdate,
  companyManagementPermissionToDelete,
  companies,
  updateCompany,
  handleDeleteCompany,
  handleEditCompany,
}) => {
  return (
    <CompanyTableContainer className="mt-2">
      <Table responsive size="sm" className="border rounded mb-0">
        <thead>
          <CompanyTableTitle>
            <th className="text-center">Foto</th>
            <th>Nome</th>
            <th>Cidade</th>
            <th className="text-center">Inativo / Ativo</th>
            {((companyManagementPermissionToUpdate &&
              companyManagementPermissionToUpdate !== "false") ||
              (companyManagementPermissionToDelete &&
                companyManagementPermissionToDelete !== "false")) && (
              <th className="text-center">Ações</th>
            )}
          </CompanyTableTitle>
        </thead>
        {companies && companies.length > 0 && (
          <tbody>
            {companies &&
              sortedFantasyNames(companies).map((company) => {
                return (
                  <tr key={company?.id}>
                    <td className="text-center">
                      <CoverImage value={company} width="3rem" height="3rem" />
                    </td>
                    <td>{formatString(company?.nome_fantasia)}</td>
                    <td>{company?.cidade}</td>
                    <td className="text-center">
                      <ActiveInput
                        title="Ativar"
                        value={company}
                        updateValue={updateCompany}
                        disabledInput={
                          !companyManagementPermissionToUpdate ||
                          companyManagementPermissionToUpdate === "false"
                        }
                      />
                    </td>
                    <td>
                      <div className="d-flex justify-content-center gap-1">
                        {companyManagementPermissionToUpdate &&
                          companyManagementPermissionToUpdate !== "false" && (
                            <ActionIconButton
                              title="Editar"
                              handleClick={() => handleEditCompany(company?.id)}
                              icon={<FiEdit2 size={15} color="#5f5f5f" />}
                            />
                          )}
                        {companyManagementPermissionToDelete &&
                          companyManagementPermissionToDelete !== "false" && (
                            <ActionIconButton
                              title="Deletar"
                              handleClick={() => handleDeleteCompany(company)}
                              icon={<FaTrashAlt size={13} color="#cb1313" />}
                            />
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        )}
      </Table>
      {companies.length === 0 && <EmptyTableMessage />}
    </CompanyTableContainer>
  );
};
