import { Card, Form } from "react-bootstrap";

import { InfoTitle, SectionContainer, TitleContainer } from "./styles";
import ReportInconsistency from "./ReportInconsistency";
import CNHValidationSection from "./CNHValidationSection";
import ReportedInconsistencies from "./ReportedInconsistencies";
import AboutInformationSection from "./AboutInformationSection";
import PersonalInformationSection from "./PersonalInformationSection";
import VehiclesAssociatedWithTheDriverForm from "./VehiclesAssociatedWithTheDriverForm";
import { WarningCard } from "../../../../../Shared/Components/WarningCard/WarningCard";
import { SubmitButton } from "../../../../../Shared/Components/SubmitButton/SubmitButton";
import { FaSave } from "react-icons/fa";

const AboutDriver = ({
  driver,
  reportInconsistency,
  register,
  handleInputChange,
  changing,
  fields,
  handleShowModal,
  handleCancelClick,
  allFalse,
}) => {
  return (
    <Card className="w-100 card d-flex flex-column p-3">
      <Card.Body className="text-center">
        {driver &&
          driver?.status_cadastro === "7r" &&
          driver?.campos_erros_cadastro &&
          driver?.campos_erros_cadastro !== "{}" && (
            <WarningCard label="As inconsistências do cadastro já foram enviadas ao motorista. Aguarde a correção!" />
          )}
        <SectionContainer className="text-start p-0 mb-4">
          <TitleContainer className="mb-3 d-flex">
            <InfoTitle className="text-start">
              INFORMAÇÕES DO MOTORISTA
            </InfoTitle>
          </TitleContainer>
          <AboutInformationSection
            reportInconsistency={reportInconsistency}
            handleInputChange={handleInputChange}
            driver={driver}
            changing={changing}
          />
        </SectionContainer>

        <SectionContainer className="text-start p-0 mb-3">
          <TitleContainer className="mb-3 d-flex">
            <InfoTitle className="text-start">
              VALIDAÇÃO CNH FLEX CONSULTA
            </InfoTitle>
          </TitleContainer>
          <CNHValidationSection
            driver={driver}
            reportInconsistency={reportInconsistency}
            handleInputChange={handleInputChange}
            changing={changing}
            handleShowModal={handleShowModal}
          />
        </SectionContainer>

        <SectionContainer className="text-start p-0 mb-5">
          <TitleContainer className="mb-3 d-flex">
            <InfoTitle className="text-start">
              INFORMAÇÕES COMPLEMENTARES
            </InfoTitle>
          </TitleContainer>
          <PersonalInformationSection
            driver={driver}
            reportInconsistency={reportInconsistency}
            handleInputChange={handleInputChange}
            changing={changing}
          />
        </SectionContainer>

        {driver?.campos_erros_cadastro &&
          driver?.status_cadastro === "7r" &&
          driver?.campos_erros_cadastro !== "{}" && (
            <SectionContainer className="text-start p-0 mb-5">
              <TitleContainer className="mb-3 d-flex">
                <InfoTitle className="text-start">
                  INCONSISTÊNCIAS INFORMADAS AO MOTORISTA
                </InfoTitle>
              </TitleContainer>
              <ReportedInconsistencies driver={driver} />
            </SectionContainer>
          )}

        {reportInconsistency && !allFalse && (
          <SectionContainer className="text-start p-0 mb-5">
            <TitleContainer className="mb-1 d-flex">
              <InfoTitle className="text-start">
                INFORMAR INCONSISTÊNCIAS
              </InfoTitle>
            </TitleContainer>

            <ReportInconsistency
              changing={changing}
              register={register}
              fields={fields}
              handleInputChange={handleInputChange}
            />
          </SectionContainer>
        )}
        {reportInconsistency && (
          <div>
            <Form.Group className="d-flex    gap-2 mt-1 flex align-items-center justify-content-end">
              <SubmitButton
                icon={<FaSave color="white" />}
                labelButton="Salvar inconsistências"
                disabled={allFalse}
              />

              <SubmitButton
                labelButton="Cancelar"
                onClick={handleCancelClick}
                buttoncolor="secondary"
              />
            </Form.Group>
          </div>
        )}

        {driver?.veiculo && (
          <VehiclesAssociatedWithTheDriverForm driver={driver} />
        )}
      </Card.Body>
    </Card>
  );
};

export default AboutDriver;
