import { Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const PageTitle = styled(Card.Title)`
color: var(--color-gray-12);
font-weight: normal;
font-size: 1rem;
margin-bottom: 1rem;
`

export const UpdateOwnerRow = styled(Row)`
max-height: 50vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 425px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 60vh;
 }

 @media(min-width: 1440px){
  max-height: 75vh;
  }

 
  
`

export const Column = styled(Col)`
 @media(min-width: 1440px){
  overflow-x: hidden;
  max-height: 69vh;
  }

  @media(min-width: 1640px){
  max-height: 74vh;
  }
 
`