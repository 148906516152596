import { useEffect, useMemo } from "react";

import { Card } from "react-bootstrap";
import moment from "moment";

import {
  OccurrenceDate,
  OccurrenceItem,
  OccurrenceItemIcon,
  OccurrencesCardContainer,
} from "./styled";

import { sortByDateDescending } from "../../../../Shared/Utils/data";
import { useUsers } from "../../../../hooks/UserProvider";

export const OccurencesCard = ({
  occurrences,
  userIdConnected,
  occurrenceAmount,
  loadOrder,
  heightSize = "100",
}) => {
  const { users, getUsers } = useUsers();

  const currentOccurences = useMemo(() => {
    return sortByDateDescending(occurrences).slice(0, occurrenceAmount);
  }, [occurrences, occurrenceAmount]);

  const maxOccurrenceId = Math.max(
    ...currentOccurences.map((occurrence) => occurrence.id)
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const getMessageUser = (id) => {
    return users.find((item) => item.id === id);
  };

  return (
    <OccurrencesCardContainer
      data-testid="occurrences-card-container"
      heightSize={heightSize}
    >
      <Card.Body className="gap-2 d-flex flex-column">
        {currentOccurences &&
          currentOccurences.length > 0 &&
          currentOccurences.reverse().map((occurrence) => {
            return (
              <div
                key={occurrence.id}
                className={`d-flex flex-column ${
                  parseInt(userIdConnected) === occurrence.usuario_id
                    ? "text-end"
                    : "justify-content-start"
                }`}
              >
                <div>
                  <div>
                    <OccurrenceItemIcon
                      index={
                        occurrence.id === maxOccurrenceId ? "special" : "normal"
                      }
                    />
                    <OccurrenceItem
                      index={
                        occurrence.id === maxOccurrenceId ? "special" : "normal"
                      }
                    >
                      {occurrence?.mensagem}
                    </OccurrenceItem>
                  </div>
                  <div>
                    <OccurrenceDate>
                      {occurrence?.usuario_id === null && loadOrder?.nome_mot
                        ? loadOrder?.nome_mot.split(" ")[0]
                        : occurrence.usuario_id
                        ? getMessageUser(occurrence.usuario_id)?.nome.split(
                            " "
                          )[0]
                        : "Desconhecido"}
                    </OccurrenceDate>
                    <OccurrenceDate>
                      {moment.utc(occurrence?.data).format("DD/MM/YYYY HH:mm")}
                    </OccurrenceDate>
                  </div>
                </div>
              </div>
            );
          })}
      </Card.Body>
    </OccurrencesCardContainer>
  );
};
