import React from "react";
import { FileUploadContainer } from "./styles";
import { CiImageOn } from "react-icons/ci";

const FileUpload = ({ handleFileChange }) => {
  return (
    <div className="w-100">
      <div>
        <FileUploadContainer className="w-100 text-center">
          <label
            htmlFor="file-upload-input"
            className="custom-file-upload text-center"
          >
            <div className="d-flex justify-content-center">
              <CiImageOn size={64} />
            </div>
            Anexar imagens
          </label>
        </FileUploadContainer>
      </div>
      <input
        id="file-upload-input"
        type="file"
        accept=".pdf,.jpeg,.png"
        onChange={handleFileChange}
        multiple
        className="d-none"
      />
    </div>
  );
};

export default FileUpload;
