import { BorderCard, VehicleItem } from "./styles";

export const VehicleCard = ({ title, value, modelValue }) => {
  return (
    <BorderCard className="w-100 h-100 rounded">
      <VehicleItem className="px-3 gap-1">
        <div>
          <div className=" gap-1 mb-1">
            <h5>{title}:</h5>
            <span>{value}</span>
          </div>
          <div className=" gap-1 mb-1">
            <h5>Modelo:</h5>
            <span>{modelValue}</span>
          </div>
        </div>
      </VehicleItem>
    </BorderCard>
  );
};
