import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, FloatingLabel, Form } from "react-bootstrap";
import ReactInputMask from "react-input-mask";
import { cnpj, cpf as validateCpf } from "cpf-cnpj-validator";

export const PersonalInformations = ({
  register,
  errors,
  owner,
  ownerByCpfCnpj,
  getOwnerByCpfCnpj,
  isCpf,
  setValue,
  trigger,
  setIsSat,
  isSAT,
}) => {
  const [name, setName] = useState(owner?.nome);
  const [currentCpfCnpj, setCurrentCpfCnpj] = useState();

  const [stateRegistration, setStateRegistration] = useState("");

  const currentCPFCNPJ = useMemo(() => {
    if (owner?.cpf_cnpj) {
      return `${owner?.cpf_cnpj}******`;
    }
  }, [owner?.cpf_cnpj]);

  useEffect(() => {
    currentCPFCNPJ && setCurrentCpfCnpj(currentCPFCNPJ);
  }, [currentCPFCNPJ]);

  const statRegistrationValue = useMemo(() => {
    if (owner?.inscricao_estadual) {
      return owner?.inscricao_estadual;
    }

    if (isCpf) {
      return "ISENTO";
    }
  }, [isCpf, owner?.inscricao_estadual]);

  useEffect(() => {
    setStateRegistration(statRegistrationValue);
  }, [statRegistrationValue]);

  const handleInputChange = useCallback(
    (event) => {
      const value = event.target.value;
      const digits = value.replace(/\D/g, "");
      setCurrentCpfCnpj(event.target.value);

      if (getOwnerByCpfCnpj && (digits.length === 11 || digits.length === 14)) {
        getOwnerByCpfCnpj(digits);
        setIsSat(true);
      }
    },
    [getOwnerByCpfCnpj, setIsSat]
  );

  useEffect(() => {
    if ((ownerByCpfCnpj && isSAT) || owner) {
      owner && setValue("cpf_cnpj", owner?.cpf_cnpj);
      name && setValue("nome", name);
      isCpf && setValue("inscricao_estadual", "ISENTO");
    }
  }, [isCpf, isSAT, name, owner, ownerByCpfCnpj, setValue]);

  const handleStateRegistrationChange = useCallback(
    (event) => {
      setStateRegistration(event.target.value);
      setValue("inscricao_estadual", event.target.value);
    },
    [setValue]
  );

  useEffect(() => {
    if (
      !owner &&
      errors &&
      errors.cpf_cnpj &&
      errors.cpf_cnpj.type === "required"
    ) {
      trigger("cpf_cnpj");
    }
  }, [errors, owner, trigger]);

  useEffect(() => {
    if (owner?.nome) {
      setName(owner?.nome);
    }

    if (ownerByCpfCnpj?.nome && isSAT) {
      setName(ownerByCpfCnpj?.nome);
    }
  }, [isSAT, owner?.nome, ownerByCpfCnpj?.nome]);

  return (
    <>
      <Form.Group as={Col} md={6} xl={4} controlId="formCpf">
        <FloatingLabel controlId="formCpf" label={`${isCpf ? "CPF" : "CNPJ"}`}>
          <Form.Control
            {...register("cpf_cnpj", {
              required: owner
                ? false
                : isCpf
                ? "O CPF é obrigatório"
                : "O CNPJ é obrigatório",
              validate: !owner && {
                validLength: function (value) {
                  if (owner) {
                    return true;
                  } else {
                    return isCpf
                      ? value.length !== 14 || "O número digitado não é um CPF"
                      : value.length < 18 || "O número digitado não é um CNPJ";
                  }
                },
                validCpfCnpj: function (value) {
                  if (owner) {
                    return true;
                  } else {
                    return isCpf
                      ? validateCpf.isValid(value) || "CPF inválido"
                      : cnpj.isValid(value) || "CNPJ inválido";
                  }
                },
              },
            })}
            autoComplete="on"
            size="sm"
            as={ReactInputMask}
            mask={
              owner ? null : isCpf ? "999.999.999-99" : "99.999.999/9999-99"
            }
            onChange={handleInputChange}
            type="text"
            maskChar={null}
            className="is-required"
            placeholder="Digite o CPF"
            value={currentCpfCnpj}
            disabled={owner}
          />
          {!owner && (
            <>
              {errors?.cpf_cnpj && (
                <Form.Text className="text-danger">
                  {errors?.cpf_cnpj?.message}
                </Form.Text>
              )}
            </>
          )}
        </FloatingLabel>
      </Form.Group>

      <Form.Group as={Col} md={6} xl={4} controlId="formStateRegistration">
        <FloatingLabel
          controlId="formStateRegistration"
          label="Inscrição estadual"
        >
          <Form.Control
            {...register("inscricao_estadual", {
              required:
                isCpf || owner ? false : "A inscrição estadual é obrigatória",
              validate: (value) => {
                if (!isCpf) {
                  if (/^\d+$/.test(value)) {
                    return (
                      value.length >= 9 ||
                      " Número de Inscrição Estadual inválido"
                    );
                  } else {
                    return (
                      /^isento$|^ISENTO$|^Isento$/.test(value) ||
                      "Inscrição Estadual inválida"
                    );
                  }
                } else {
                  return true;
                }
              },
            })}
            size="sm"
            type="text"
            placeholder="Digite a inscrição estadual"
            maxLength={25}
            className="is-required"
            disabled={isCpf}
            onChange={handleStateRegistrationChange}
            value={
              isCpf
                ? "ISENTO"
                : owner?.inscricao_estadual
                ? owner?.inscricao_estadual
                : stateRegistration
            }
          />
          {errors?.inscricao_estadual && (
            <Form.Text className="text-danger">
              {errors?.inscricao_estadual?.message}
            </Form.Text>
          )}
        </FloatingLabel>
      </Form.Group>

      <Form.Group as={Col} md={12} xl={4} controlId="formNome">
        <FloatingLabel controlId="formNome" label="Nome completo">
          <Form.Control
            {...register("nome", {
              required: owner ? false : "O nome é obrigatório",
              pattern: {
                value: !owner && /^[A-Za-zÀ-ÖØ-öø-ÿ ]+$/,
                message: "O nome deve conter apenas letras",
              },
            })}
            size="sm"
            type="text"
            placeholder="Digite o nome completo"
            className="is-required"
            onChange={(event) => {
              setName(event.target.value);
              setValue("nome", event.target.value);
            }}
            value={name}
            autoComplete="on"
            disabled={owner}
          />
          {errors?.nome && (
            <Form.Text className="text-danger">
              {errors?.nome?.message}
            </Form.Text>
          )}
        </FloatingLabel>
      </Form.Group>
    </>
  );
};
