import { memo } from "react";
import Dashboard from "../../../Shared/layout";
import { ListIcon, RowContainer } from "./styles";
import { Col } from "react-bootstrap";
import LinkCard from "../../../Shared/Components/LinkCard";

const list = [
  {
    route: "/gestao-regularizacao-servico-transporte/lista",
    title: "Painel MDF-e",
    icon: <ListIcon />,
  },
];

const Manifest = () => {
  return (
    <Dashboard>
      <RowContainer
        xs={1}
        md={2}
        lg={4}
        xl={6}
        className="d-flex mt-4 g-2"
        id="container-cards-conf-sistema"
      >
        {list.map((item) => (
          <Col key={item.route}>
            <LinkCard route={item.route} title={item.title} icon={item.icon} />
          </Col>
        ))}
      </RowContainer>
    </Dashboard>
  );
};

export default memo(Manifest);
