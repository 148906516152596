import { CoverIcon, SubtitleLabel } from "./styles";

export const Subtitle = ({ icon, reactIcon, label, size }) => {
  return (
    <div className="d-flex align-items-center justify-content-start mt-2 gap-1">
      {reactIcon ? reactIcon : <CoverIcon icon={icon} size={size} />}
      <SubtitleLabel>{label}</SubtitleLabel>
    </div>
  );
};
