import React, { useCallback } from "react";
import { Table } from "react-bootstrap";
import { FiEdit2 } from "react-icons/fi";

import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import { UnitListContainer, UnitTableTitle } from "./styles";
import { ActiveInput } from "../../../../Shared/Components/ActiveInput/ActiveInput";
import { ActionIconButton } from "../../../../Shared/Components/ActionIconButton";
import { FaTrashAlt } from "react-icons/fa";
import { formatString } from "../../../../Shared/Utils/data";

export const UnitList = ({
  units,
  handleDeleteUnit,
  unitManagementPermissionToUpdate,
  unitManagementPermissionToDelete,
  companies,
  selectedCompany,
  updateUnit,
  handleEditUnit,
}) => {
  const currentCompany = useCallback(
    (id) => companies.find((company) => company.id === id)?.nome_fantasia,
    [companies]
  );
  return (
    <UnitListContainer>
      <Table hover size="sm" className="border rounded mb-0">
        <thead>
          <UnitTableTitle>
            <th className="px-3">Código</th>
            <th>Nome</th>
            <th>Empresa</th>
            <th>Cidade</th>
            <th className="text-center">Inativo / Ativo</th>
            {((unitManagementPermissionToUpdate &&
              unitManagementPermissionToUpdate !== "false") ||
              (unitManagementPermissionToDelete &&
                unitManagementPermissionToDelete !== "false")) && (
              <th className="text-center">Ações</th>
            )}
          </UnitTableTitle>
        </thead>
        <tbody>
          {units.length > 0 &&
            units.map((unit) => {
              return (
                <tr key={unit?.id}>
                  <td>
                    <span className="px-3">{unit?.id}</span>
                  </td>
                  <td>{unit?.nome && formatString(unit?.nome)}</td>
                  <td>
                    {currentCompany(unit?.id_empresa)
                      ? formatString(currentCompany(unit?.id_empresa))
                      : formatString(currentCompany(selectedCompany))}
                  </td>
                  <td>
                    {unit?.cidade} - {unit?.estado}
                  </td>
                  <td className="text-center">
                    <ActiveInput
                      value={unit}
                      updateValue={updateUnit}
                      disabledInput={
                        !unitManagementPermissionToUpdate ||
                        unitManagementPermissionToUpdate === "false"
                      }
                    />
                  </td>
                  <td>
                    <div className="d-flex justify-content-center gap-1">
                      {unitManagementPermissionToUpdate &&
                        unitManagementPermissionToUpdate !== "false" && (
                          <ActionIconButton
                            title="Editar"
                            handleClick={() => handleEditUnit(unit?.id)}
                            icon={<FiEdit2 size={15} color="#5f5f5f" />}
                          />
                        )}
                      {unitManagementPermissionToDelete &&
                        unitManagementPermissionToDelete !== "false" && (
                          <ActionIconButton
                            title="Deletar"
                            handleClick={() => handleDeleteUnit(unit)}
                            icon={<FaTrashAlt size={13} color="#cb1313" />}
                          />
                        )}
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {units.length === 0 && <EmptyTableMessage />}
    </UnitListContainer>
  );
};
