import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  span {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--color-blue-90);
  }

  /* @media (min-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: 1028px) {
    flex-direction: row;
  } */
`


export const MainDetailsBorderCard = styled(Card)`
  h1 {
    font-size: 1rem;
    color: var(--color-blue-90);
    margin-bottom: 0;
  }

`

export const SeemoreButton = styled(Link)`
  background-color: var(--color-blue-70);
  border: none;
  border-radius: 4px;
  text-decoration: none;
  color: white !important;
  font-weight: bold;
  padding: 7px;
  font-size: 0.7rem;
`