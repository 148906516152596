import moment from "moment";
import { Col, Row, Spinner } from "react-bootstrap";
import { FaDotCircle } from "react-icons/fa";

import { JudicialProcessCard } from "./JudicialProcessCard";
import { EmptyTableMessage } from "../EmptyTableMessage";

import {
  CardContainer,
  ContainerList,
  ItemSubtitle,
  PageTitle,
  TitleContainer,
} from "./styles";

export const JudicialProcess = ({
  judicialProcess,
  judicialProcessLoading,
}) => {
  const data =
    judicialProcess &&
    judicialProcess?.dt_atualizacao &&
    moment(judicialProcess?.dt_atualizacao).format("DD/MM/YYYY HH:mm");

  return (
    <CardContainer>
      <TitleContainer className="mb-3 d-flex">
        <PageTitle className="text-start">PROCESSOS JUDICIAIS</PageTitle>
      </TitleContainer>

      {judicialProcess &&
        Object.keys(judicialProcess).length > 0 &&
        (judicialProcessLoading || !judicialProcess?.concluido) && (
          <div className="d-flex gap-1 align-items-center justify-content-center mt-4 mb-4">
            <Spinner animation="border" variant="warning" size="sm" />
            <ItemSubtitle theme>Realizando consulta judicial...</ItemSubtitle>
          </div>
        )}

      {!judicialProcessLoading &&
        judicialProcess?.concluido === true &&
        Array.isArray(judicialProcess?.resposta) &&
        judicialProcess?.resposta.length > 0 && (
          <ContainerList>
            <div className="d-flex gap-1 px-2 align-items-center mb-2">
              <FaDotCircle size={12} />
              <span>Quantidade total de processos:</span>
              <strong>{judicialProcess?.resposta.length}</strong>
            </div>
            <Row xs={1} className="d-flex flex-column g-2 p-2">
              {judicialProcess?.resposta.map((process, index) => (
                <Col key={index}>
                  <JudicialProcessCard process={process} data={data} />
                </Col>
              ))}
            </Row>
          </ContainerList>
        )}
      {!judicialProcessLoading &&
        judicialProcess &&
        judicialProcess?.concluido &&
        !Array.isArray(judicialProcess?.resposta) &&
        judicialProcess?.resposta?.err &&
        judicialProcess?.resposta?.msg && (
          <EmptyTableMessage label={judicialProcess?.resposta?.msg} />
        )}
      {!judicialProcessLoading &&
        ((judicialProcess &&
          judicialProcess?.concluido &&
          (judicialProcess?.resposta?.length === 0 ||
            !judicialProcess?.resposta)) ||
          !judicialProcess) && (
          <EmptyTableMessage label="Este cadastro não possui registro de processos judiciais" />
        )}
    </CardContainer>
  );
};
