import { User } from "phosphor-react";
import { AvatarBorder, AvatarContainer } from "./styles";

export const AvatarFallBack = () => {
  return (
    <AvatarContainer>
      <AvatarBorder>
        <User size={25} />
      </AvatarBorder>
    </AvatarContainer>
  );
};
