import { useMemo, useState } from "react";
import VehicleInformationsCard from "../../../../Vehicles/components/VehicleForms/VehicleInformationsCard";

import ImageModal from "../../../../../../../Shared/Components/ImageModal/ImageModal";
import { Accordion, Card } from "react-bootstrap";
import { VehicleFederalDetails } from "../../../../../../../Shared/Components/VehicleFederalDetails/VehicleFederalDetails";

export const VehicleInformationsTab = ({
  vehicle,
  driverConsults,
  defaultActiveKey,
  setDefaultActiveKey,
}) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");

  const handleShowModal = (imageTruck, title) => {
    setShowImageModal(true);
    setImage(imageTruck);
    setTitle(title);
  };

  const vehicleConsult =
    driverConsults &&
    driverConsults.find((vehicle) => vehicle?.posicao === "CAVALO");

  const trucksConsult = useMemo(
    () =>
      driverConsults &&
      driverConsults.filter(
        (consult) =>
          consult.posicao === "CARRETA1" ||
          consult.posicao === "CARRETA2" ||
          consult.posicao === "CARRETA3" ||
          consult.posicao === "CARRETA4"
      ),
    [driverConsults]
  );

  return (
    <>
      <Card className="mt-4">
        <Accordion activeKey={defaultActiveKey}>
          {vehicle && (
            <Accordion.Item eventKey="0">
              <Accordion.Header
                onClick={() =>
                  setDefaultActiveKey((prevState) =>
                    prevState !== "0" ? "0" : null
                  )
                }
              >
                Cavalo
              </Accordion.Header>
              <Accordion.Body>
                <VehicleInformationsCard
                  vehicle={vehicle}
                  handleShowModal={handleShowModal}
                />
                <section className="mt-3 d-flex flex-column gap-1">
                  <VehicleFederalDetails
                    consult={vehicleConsult}
                    vehicle={vehicle}
                    label="Cavalo"
                  />
                </section>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {vehicle?.carretas &&
            vehicle.carretas.length > 0 &&
            vehicle.carretas.map((truck, index) => {
              const consultItem = trucksConsult.find(
                (consultItem) => consultItem.posicao === `CARRETA${index + 1}`
              );

              return (
                <Accordion.Item eventKey={`${index + 1}`} key={index}>
                  <Accordion.Header
                    onClick={() =>
                      setDefaultActiveKey((prevState) =>
                        prevState !== `${index + 1}` ? `${index + 1}` : null
                      )
                    }
                  >
                    Carreta {index + 1}
                  </Accordion.Header>
                  <Accordion.Body>
                    <VehicleInformationsCard
                      vehicle={truck}
                      handleShowModal={handleShowModal}
                    />
                    <section className="mt-3 d-flex flex-column gap-1">
                      <VehicleFederalDetails
                        consult={consultItem}
                        vehicle={truck}
                        label={`Carreta ${index + 1}`}
                      />
                    </section>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
      </Card>
      <ImageModal
        show={showImageModal}
        handleClose={() => setShowImageModal(false)}
        image={image}
        title={title}
      />
    </>
  );
};
