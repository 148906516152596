import { useCallback, useState } from "react";
import { Collapse, ListGroup, Image } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { ArrowCircleLeft, ArrowCircleRight } from "phosphor-react";

import { FiUsers } from "react-icons/fi";
import { BiBuildings } from "react-icons/bi";
import { CgProfile, CgReorder } from "react-icons/cg";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

import {
  BsBuilding,
  BsCapslock,
  BsClipboardCheck,
  BsClipboardData,
  BsGear,
  BsGlobe2,
  BsHouse,
  BsKey,
  BsPinMap,
} from "react-icons/bs";

import {
  HiOutlineUserGroup,
  HiOutlineTruck,
  HiUserGroup,
} from "react-icons/hi";

import Logo from "../../Assets/Images/Logo.png";

import {
  CloseButton,
  CollapseButton,
  SidebarContainer,
  SidebarScroll,
  Version,
} from "./styles";

export const Sidebar = ({ isClose, onClick }) => {
  const location = useLocation();

  const isLinkActive = useCallback(
    (path) => {
      return location.pathname === path;
    },
    [location.pathname]
  );

  const { id, token } = useParams();

  const panelConfig =
    isLinkActive("/empresas") ||
    isLinkActive("/empresas-adicionar") ||
    isLinkActive(`/empresas-atualizar/${id}`) ||
    isLinkActive("/unidades") ||
    isLinkActive("/unidades-adicionar") ||
    isLinkActive(`/unidades-atualizar/${id}`) ||
    isLinkActive("/perfis") ||
    isLinkActive("/perfis-adicionar") ||
    isLinkActive(`/perfis-atualizar/${id}`) ||
    isLinkActive("/usuarios") ||
    isLinkActive("/usuarios-adicionar") ||
    isLinkActive(`/usuarios-atualizar/${id}`) ||
    isLinkActive("/configuracoes-acesso");

  const driversConfig =
    isLinkActive("/motoristas") ||
    isLinkActive("/motoristas-listar") ||
    isLinkActive(`/motoristas-listar/${id}`) ||
    isLinkActive(`/motoristas-listar/temporario/${id}`) ||
    isLinkActive(`/motoristas-listar/informacoes-sat/${id}`) ||
    isLinkActive("/motoristas/add") ||
    isLinkActive(`/motoristas/update/${id}`) ||
    isLinkActive(`/motoristas-informacoes/${id}`) ||
    isLinkActive(`/motoristas-editar/${id}`) ||
    isLinkActive("/motoristas-adicionar") ||
    isLinkActive(`/motoristas/processos-judiciais/${id}/${token}`) ||
    isLinkActive("/motoristas/processos-em-curso");

  const ownersConfig =
    isLinkActive("/proprietarios") ||
    isLinkActive("/proprietarios-adicionar") ||
    isLinkActive("/proprietarios-listar") ||
    isLinkActive(`/proprietarios-listar/${id}`) ||
    isLinkActive(`/proprietarios-editar/${id}`) ||
    isLinkActive(`/proprietarios-informacoes/${id}`);

  const vehiclesConfig =
    isLinkActive("/veiculos") ||
    isLinkActive("/veiculos-adicionar") ||
    isLinkActive("/veiculos-listar") ||
    isLinkActive(`/veiculos-listar/${id}`) ||
    isLinkActive(`/veiculos-editar/${id}`) ||
    isLinkActive(`/veiculos-informacoes/${id}`);

  const travelsConfig =
    isLinkActive("/gestao-viagens/") ||
    isLinkActive("/gestao-viagens/ordem-de-carregamento") ||
    isLinkActive(`/gestao-viagens/ordem-de-carregamento/${token}`) ||
    isLinkActive("/gestao-viagens/viagens") ||
    isLinkActive(
      `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/${token}`
    ) ||
    isLinkActive(`/gestao-viagens/ordem-de-carregamento/log-viagem/${token}`) ||
    isLinkActive(
      `/gestao-viagens/ordem-de-carregamento/escolher-rota/${token}`
    ) ||
    isLinkActive(
      `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/${token}`
    ) ||
    isLinkActive(
      `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/enviar-ocorrencia/${token}`
    ) ||
    isLinkActive("/gestao-viagens/tipos-de-ocorrencias") ||
    isLinkActive("/gestao-viagens/tipos-de-ocorrencias/adicionar/ocorrencia") ||
    isLinkActive(
      `/gestao-viagens/tipos-de-ocorrencias/editar/ocorrencia/${id}`
    );

  const mdfeConfig =
    isLinkActive("/gestao-regularizacao-servico-transporte") ||
    isLinkActive("/gestao-regularizacao-servico-transporte/lista") ||
    isLinkActive(`/gestao-regularizacao-servico-transporte/detalhes/${id}`);

  const registerConfig = driversConfig || ownersConfig || vehiclesConfig;

  const [openRegister, setOpenRegister] = useState(
    registerConfig ? true : false
  );

  const [openConfigPanel, setOpenConfigPanel] = useState(
    panelConfig ? true : false
  );

  const [openTravels, setOpenTravels] = useState(travelsConfig ? true : false);
  const [openMDFe, setOpenMDFe] = useState(mdfeConfig ? true : false);

  return (
    <SidebarContainer
      className="d-none d-lg-flex flex-column"
      isClosed={isClose}
    >
      <Link to="/inicio" className="d-flex align-items-center mb-4 ms-2">
        <Image src={Logo} width="115rem" />
      </Link>
      <SidebarScroll className="d-none d-lg-flex flex-column">
        <ListGroup variant="flush" id="list-menu-default">
          <ListGroup.Item
            as={Link}
            to={"/inicio"}
            active={isLinkActive("/inicio")}
            action
            className={`${
              isClose && "text-center d-flex justify-content-center"
            }`}
            title={"Início"}
          >
            {isClose ? (
              <BsHouse size={25} />
            ) : (
              <div className="d-flex gap-2 text-start align-items-center">
                <span>
                  <BsHouse size={16} /> Início
                </span>
              </div>
            )}
          </ListGroup.Item>

          <ListGroup.Item
            title={"Gestão de cadastros"}
            as={Link}
            to={"/gestao-cadastros"}
            action
            active={isLinkActive("/gestao-cadastros")}
            className={`text-start justify-content-start ${
              registerConfig && "selected-sidebar-item"
            } ${isClose && "text-center justify-content-center"}`}
          >
            <CollapseButton
              size="sm"
              variant=""
              onClick={() => setOpenRegister(!openRegister)}
              aria-expanded={openRegister}
              className="px-0 text-center w-100"
            >
              {isClose === true ? (
                <BsClipboardData size={25} />
              ) : (
                <div className="d-flex justify-content-between text-start align-items-center">
                  <div className="d-flex align-items-center gap-1">
                    <span>
                      <BsClipboardData size={16} /> Gestão de cadastros
                    </span>
                  </div>
                  <div className="d-flex text-end justify-content-end">
                    {(!openRegister && <IoMdArrowDropdown />) || (
                      <IoMdArrowDropup />
                    )}
                  </div>
                </div>
              )}
            </CollapseButton>
          </ListGroup.Item>

          <Collapse in={openRegister}>
            <ListGroup variant="flush">
              <ListGroup.Item
                title={"Motoristas"}
                className={`${
                  isClose && "text-center d-flex justify-content-center"
                }`}
                as={Link}
                to={"/motoristas"}
                active={driversConfig}
                onClick={() => setOpenRegister(true)}
              >
                {isClose ? (
                  <HiOutlineUserGroup size={25} />
                ) : (
                  <div className="d-flex gap-2 text-start align-items-center">
                    <span>
                      <HiOutlineUserGroup size={16} /> Motoristas
                    </span>
                  </div>
                )}
              </ListGroup.Item>

              <ListGroup.Item
                title={"Proprietarios"}
                className={`${
                  isClose && "text-center d-flex justify-content-center"
                }`}
                as={Link}
                to={"/proprietarios"}
                active={ownersConfig}
                onClick={() => setOpenRegister(true)}
              >
                {isClose ? (
                  <HiUserGroup size={25} />
                ) : (
                  <div className="d-flex gap-2 text-start align-items-center">
                    <span>
                      <HiUserGroup size={16} /> Proprietários
                    </span>
                  </div>
                )}
              </ListGroup.Item>

              <ListGroup.Item
                title={"Veículos"}
                className={`${
                  isClose && "text-center  d-flex justify-content-center"
                }`}
                as={Link}
                to={"/veiculos"}
                active={vehiclesConfig}
                onClick={() => setOpenRegister(true)}
              >
                {isClose ? (
                  <HiOutlineTruck size={25} />
                ) : (
                  <div className="d-flex gap-2 text-start align-items-center">
                    <span>
                      <HiOutlineTruck size={18} /> Veículos
                    </span>
                  </div>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Collapse>

          <ListGroup.Item
            as={Link}
            to={"/gestao-viagens"}
            action
            active={isLinkActive("/gestao-viagens")}
            className={`justify-content-start text-start ${
              travelsConfig && "selected-sidebar-item"
            } ${isClose && "text-center d-flex justify-content-center"}`}
            title="Gestão de viagens"
          >
            <CollapseButton
              size="sm"
              id="gestao-viagens"
              variant=""
              onClick={() => setOpenTravels(!openTravels)}
              aria-expanded={openTravels}
              className="px-0 text-center w-100"
            >
              {isClose === true ? (
                <BsGlobe2 size={23} />
              ) : (
                <div className="d-flex justify-content-between gap-2 text-start align-items-center">
                  <div className="d-flex gap-1 align-items-center">
                    <span>
                      <BsGlobe2 size={14} /> Gestão de viagens
                    </span>
                  </div>
                  <div className="d-flex text-end justify-content-end">
                    {(!openTravels && <IoMdArrowDropdown />) || (
                      <IoMdArrowDropup />
                    )}
                  </div>
                </div>
              )}
            </CollapseButton>
          </ListGroup.Item>

          <Collapse in={openTravels}>
            <ListGroup variant="flush">
              <ListGroup.Item
                title="Ordens de Carregamento"
                className={`${
                  isClose && "text-center  d-flex justify-content-center"
                }`}
                as={Link}
                to={"/gestao-viagens/ordem-de-carregamento"}
                active={
                  isLinkActive("/gestao-viagens/ordem-de-carregamento") ||
                  isLinkActive(
                    `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/${token}`
                  ) ||
                  isLinkActive(
                    `/gestao-viagens/ordem-de-carregamento/log-viagem/${token}`
                  ) ||
                  isLinkActive(
                    `/gestao-viagens/ordem-de-carregamento/${token}`
                  ) ||
                  isLinkActive(
                    `/gestao-viagens/ordem-de-carregamento/escolher-rota/${token}`
                  ) ||
                  isLinkActive(
                    `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/${token}`
                  ) ||
                  isLinkActive(
                    `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/enviar-ocorrencia/${token}`
                  )
                }
                onClick={() => setOpenTravels(true)}
              >
                {isClose ? (
                  <CgReorder size={25} />
                ) : (
                  <div className="ms-1 d-flex gap-2 text-start align-items-center">
                    <span>
                      <CgReorder size={18} /> Ordens de Carregamento
                    </span>
                  </div>
                )}
              </ListGroup.Item>

              <ListGroup.Item
                title="Viagens"
                className={`${
                  isClose && "text-center  d-flex justify-content-center"
                }`}
                as={Link}
                to={"/gestao-viagens/viagens"}
                active={isLinkActive("/gestao-viagens/viagens")}
                onClick={() => setOpenTravels(true)}
              >
                {isClose ? (
                  <BsPinMap size={23} />
                ) : (
                  <div className="ms-1 d-flex gap-2 text-start align-items-center">
                    <span>
                      <BsPinMap size={14} /> Viagens
                    </span>
                  </div>
                )}
              </ListGroup.Item>
              <ListGroup.Item
                title="Tipos de ocorrências"
                className={`${
                  isClose && "text-center  d-flex justify-content-center"
                }`}
                as={Link}
                to={"/gestao-viagens/tipos-de-ocorrencias"}
                active={
                  isLinkActive("/gestao-viagens/tipos-de-ocorrencias") ||
                  isLinkActive(
                    "/gestao-viagens/tipos-de-ocorrencias/adicionar/ocorrencia"
                  ) ||
                  isLinkActive(
                    `/gestao-viagens/tipos-de-ocorrencias/editar/ocorrencia/${id}`
                  )
                }
                onClick={() => setOpenTravels(true)}
              >
                {isClose ? (
                  <BsCapslock size={23} />
                ) : (
                  <div className="ms-1 d-flex gap-2 text-start align-items-center">
                    <span>
                      <BsCapslock size={14} /> Tipos de ocorrências
                    </span>
                  </div>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Collapse>

          <ListGroup.Item
            as={Link}
            to={"/gestao-regularizacao-servico-transporte"}
            action
            active={mdfeConfig}
            className={`justify-content-start text-start ${
              mdfeConfig && "selected-sidebar-item"
            } ${isClose && "text-center d-flex justify-content-center"}`}
            title="Gestão de MDF-e"
          >
            <CollapseButton
              size="sm"
              id="gestao-mdfe"
              variant=""
              onClick={() => setOpenMDFe(!openMDFe)}
              aria-expanded={openMDFe}
              className="px-0 text-center w-100"
            >
              {isClose === true ? (
                <BsClipboardCheck size={23} />
              ) : (
                <div className="d-flex justify-content-between gap-2 text-start align-items-center">
                  <div className="d-flex gap-1 align-items-center">
                    <span>
                      <BsClipboardCheck size={14} /> Gestão de MDF-e
                    </span>
                  </div>
                  <div className="d-flex text-end justify-content-end">
                    {(!openMDFe && <IoMdArrowDropdown />) || (
                      <IoMdArrowDropup />
                    )}
                  </div>
                </div>
              )}
            </CollapseButton>
          </ListGroup.Item>

          {localStorage.getItem("accessManagementPermissionToRead") &&
            localStorage.getItem("accessManagementPermissionToRead") !==
              "false" && (
              <>
                <ListGroup.Item
                  as={Link}
                  to={"/painel-configuracao"}
                  action
                  active={isLinkActive("/painel-configuracao")}
                  className={`justify-content-start text-start ${
                    panelConfig && "selected-sidebar-item"
                  } ${isClose && "text-center d-flex justify-content-center"}`}
                  title={"Configurações do sistema"}
                >
                  <CollapseButton
                    size="sm"
                    id="configuracoes-sistema"
                    variant=""
                    onClick={() => setOpenConfigPanel(!openConfigPanel)}
                    aria-expanded={openConfigPanel}
                    className="px-0 text-center w-100"
                  >
                    {isClose === true ? (
                      <BsGear size={24} />
                    ) : (
                      <div className="d-flex justify-content-between text-start align-items-center">
                        <div className="d-flex gap-1 align-items-center">
                          <span>
                            <BsGear size={16} /> Configurações do sistema
                          </span>
                        </div>
                        <div className="d-flex text-end justify-content-end">
                          {!openConfigPanel ? (
                            <IoMdArrowDropdown />
                          ) : (
                            <IoMdArrowDropup />
                          )}
                        </div>
                      </div>
                    )}
                  </CollapseButton>
                </ListGroup.Item>

                <Collapse in={openConfigPanel}>
                  <ListGroup variant="flush">
                    {localStorage.getItem(
                      "companyManagementPermissionToRead"
                    ) &&
                      localStorage.getItem(
                        "companyManagementPermissionToRead"
                      ) !== "false" && (
                        <ListGroup.Item
                          title={"Empresas"}
                          className={`gap-2 ${
                            isClose &&
                            "text-center d-flex justify-content-center"
                          }`}
                          as={Link}
                          to={"/empresas"}
                          active={
                            isLinkActive("/empresas") ||
                            isLinkActive("/empresas-adicionar") ||
                            isLinkActive(`/empresas-atualizar/${id}`)
                          }
                          onClick={() => setOpenConfigPanel(true)}
                        >
                          {isClose ? (
                            <BsBuilding size={25} />
                          ) : (
                            <div className="d-flex gap-2 text-start align-items-center">
                              <span>
                                <BsBuilding size={16} /> Empresas
                              </span>
                            </div>
                          )}
                        </ListGroup.Item>
                      )}

                    {localStorage.getItem("unitManagementPermissionToRead") &&
                      localStorage.getItem("unitManagementPermissionToRead") !==
                        "false" && (
                        <ListGroup.Item
                          title={"Unidades"}
                          className={`${
                            isClose &&
                            "text-center d-flex justify-content-center"
                          }`}
                          as={Link}
                          to={"/unidades"}
                          active={
                            isLinkActive("/unidades") ||
                            isLinkActive("/unidades-adicionar") ||
                            isLinkActive(`/unidades-atualizar/${id}`)
                          }
                          onClick={() => setOpenConfigPanel(true)}
                        >
                          {isClose ? (
                            <BiBuildings size={25} />
                          ) : (
                            <div className="d-flex gap-2 text-start align-items-center">
                              <span>
                                <BiBuildings size={16} /> Unidades
                              </span>
                            </div>
                          )}
                        </ListGroup.Item>
                      )}

                    {localStorage.getItem("perfilManagementPermissionToRead") &&
                      localStorage.getItem(
                        "perfilManagementPermissionToRead"
                      ) !== "false" && (
                        <ListGroup.Item
                          title={"Perfis"}
                          className={`${
                            isClose &&
                            "text-center d-flex justify-content-center"
                          }`}
                          as={Link}
                          to={"/perfis"}
                          active={
                            isLinkActive("/perfis") ||
                            isLinkActive("/perfis-adicionar") ||
                            isLinkActive(`/perfis-atualizar/${id}`)
                          }
                          onClick={() => setOpenConfigPanel(true)}
                        >
                          {isClose ? (
                            <CgProfile size={25} />
                          ) : (
                            <div className="d-flex gap-2 text-start align-items-center">
                              <span>
                                <CgProfile size={16} /> Perfis
                              </span>
                            </div>
                          )}
                        </ListGroup.Item>
                      )}

                    {localStorage.getItem("userManagementPermissionToRead") &&
                      localStorage.getItem("userManagementPermissionToRead") !==
                        "false" && (
                        <ListGroup.Item
                          title="Usuários"
                          className={`${
                            isClose &&
                            "text-center d-flex justify-content-center"
                          }`}
                          as={Link}
                          to={"/usuarios"}
                          active={
                            isLinkActive("/usuarios") ||
                            isLinkActive("/usuarios-adicionar") ||
                            isLinkActive(`/usuarios-atualizar/${id}`)
                          }
                          onClick={() => setOpenConfigPanel(true)}
                        >
                          {isClose ? (
                            <FiUsers size={25} />
                          ) : (
                            <div className="d-flex gap-2 text-start align-items-center">
                              <span>
                                <FiUsers size={16} /> Usuários
                              </span>
                            </div>
                          )}
                        </ListGroup.Item>
                      )}

                    {localStorage.getItem("accessManagementPermissionToRead") &&
                      localStorage.getItem(
                        "accessManagementPermissionToRead"
                      ) !== "false" && (
                        <ListGroup.Item
                          title={"Configurações de acesso"}
                          className={`${
                            isClose &&
                            "text-center d-flex justify-content-center"
                          }`}
                          as={Link}
                          to={"/configuracoes-acesso"}
                          active={isLinkActive("/configuracoes-acesso")}
                          onClick={() => setOpenConfigPanel(true)}
                        >
                          {isClose ? (
                            <BsKey size={25} />
                          ) : (
                            <div className="d-flex gap-2 text-start align-items-center">
                              <span>
                                <BsKey size={16} /> Configurações de acesso
                              </span>
                            </div>
                          )}
                        </ListGroup.Item>
                      )}
                  </ListGroup>
                </Collapse>
              </>
            )}
        </ListGroup>
        <CloseButton
          className={`mt-auto mb-4 ${isClose ? "text-start" : "text-start"}`}
          onClick={onClick}
        >
          {isClose === false ? (
            <ArrowCircleLeft size={32} />
          ) : (
            <ArrowCircleRight size={32} />
          )}
        </CloseButton>
        <div className="d-flex justify-content-center align-itens-center">
          <Version className="mb-1">
            {process.env.REACT_APP_VERSION_APP}
          </Version>
        </div>
      </SidebarScroll>
    </SidebarContainer>
  );
};
