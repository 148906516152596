import styled from "styled-components";

import * as Avatar from "@radix-ui/react-avatar";

export const AvatarContainer = styled(Avatar.Root)`
  border-radius: 500px;
  display: inline-block,;
  width: 45px;
  height: 45px;
  overflow: hidden; 
  `


export const AvatarBorder = styled(Avatar.Fallback)`
 width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  var(--color-gray-11);
  color:var(--color-gray-15);

  svg: {
    width: 45px;
    height: 45px;
  }
  `