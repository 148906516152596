import { Card, Row } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled(Card)`
  background-color: #FFFAFA;
  border: none;
`

export const PageTitle = styled(Card.Title)`
  color: var(--color-blue-90);
  font-weight: normal;
  font-size: 1rem;
`;

export const BorderCard = styled(Card)`
  min-height: 100%;

  &:hover {
    box-shadow: 0 0 5px;
  }

  h3 {
    font-size: 1rem;
    font-weight: normal;
  }

  h4 {
    font-size: 0.7rem;
    color: var(--color-gray-11);
  }

  h5 {
    font-size: 0.7rem;
    color: var(--color-gray-12);

    span {
      font-size: 0.7rem;
    }
  }

  h6 {
    font-size: 0.6rem;
    color: var(--color-gray-11);

    span {
      font-size: 0.6rem;
    }
  }
`;


export const TitleContainer = styled.div`
  background-color: var(--color-gray-2);
  padding: 10px;
`

export const InfomationItem = styled.span`
  color: ${(props) =>
    props.titlestatus === "placa"
      ? "#0071c7"
      : props.titlestatus === "ATIVO"
        ? "#198754"
        : props.titlestatus === "Requer atenção"
          ? "#C3A300" : props.titlestatus === "BLOQUEADO" ? "red"
            : "#093c5e"};
  font-weight: normal;
  font-size: 0.875rem;
  margin-bottom: 0;  
`;

export const TravelsHistoryRow = styled(Row)`
  overflow-x: hidden;
  max-height: 95vh;
`;

export const TravelsHistoryCardBody = styled(Card.Body)`

  border: dashed 0.6px var(--color-gray-11);

  &:hover {
    z-index: 1;
    box-shadow: 0 0 5px var(--color-gray);
  }

  border-bottom: 1px solid var(--color-gray-10);

  a {
    text-decoration: none;
  }
`;


export const Label = styled.h1`
  font-size: 0.875rem;
  color: var(--color-gray-15);
  font-weight: 700;
`;

export const InfoTitle = styled.span`
  color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;

export const InfoItem = styled.span`
  color: ${(props) =>
    props.status === "placa"
      ? "#0071c7"
      : (props.status === "ATIVA" || props.status === "Ativa" || props.status === "REGULAR" || props.status === "Regular")
        ? "#198754"
        : props.status === "Requer atenção"
          ? "#C3A300" : props.status === "Pré-cadastro" ? "#b0b0b0"
            : "#093c5e"};
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 0;

  white-space: ${({ titlestatus }) => titlestatus && "nowrap"};

    
    text-transform: ${(props) =>
    props.status === "ATIVO" ? "capitalize" : "initial"};
    
  overflow: ${({ content }) => (content ? "hidden" : "visible")};
  text-overflow: ${({ content }) => (content ? "ellipsis" : "clip")};
  flex: ${({ content }) => (content ? "1" : "0 0 auto")};

`;