import styled from "styled-components";

export const Cover = styled.div`
  background-size: cover;
  background-position: center center;
  border-radius: 500px;

  width: ${(props) => props.width};
  height: ${(props) => props.height};

  background-image: ${(props) =>
    props.logo ? `url(${props?.logo})` : `url(${props?.emptyImageLogo})`};
`;
