import { Button, Form, FormControl } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import styled from "styled-components";

export const AlertIcon = styled(FiAlertTriangle)`
color: red;
`

export const CoverImage = styled.div`
  width: ${props => props.width ? props.width : "100%"};
  height: ${props => props.height};
  padding-bottom: ${props => props.paddingBottom};
  background-image: ${props => props.coverImage};
  background-size: cover;
  background-position: center center;
  border-radius: 8px;
`

export const DeleteCnhButton = styled(Button)`
  transition: transform 300ms;
  background-color: transparent;
  border: none;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
    background-color: transparent;
    border: none;
  }

  label {
    color: var(--color-gray-15);
    font-size: 0.9rem;
  }
`

export const DeleteIconButton = styled(FaTrashAlt)`
color: var(--color-gray-15);
font-size: 0.9rem;
margin-top: 4px;
`

export const ErrorMessage = styled.span`
font-size: 0.7rem;
color: red;
`

export const FormControlInput = styled(FormControl)`
font-size: 0.7rem;
display: flex;
`


export const ImageTitle = styled.p`
font-size: 0.8rem;
color: var(--color-gray-11);

`

export const ShowModalButton = styled(Button)`
border: none;
background-color: transparent;
color: var(--color-gray-15);
font-size: 0.8rem;
transition: transform 300ms;

&:hover {
border: none;
background-color: transparent;
color: var(--color-gray-13);
transform: scale(1.05);
z-index: 1;
}
`

export const UploadImageButton = styled.div`
background-color: transparent !important;
width: ${props => props.width ? props.width : "7rem"};
padding: 3px;
border-left: 2px solid red;
border-top: solid 1px var(--color-gray-11);
  border-right: solid 1px var(--color-gray-11);
  border-bottom: solid 1px var(--color-gray-11);

font-size: 0.8rem;
transition: transform 300ms;
cursor: pointer;

&:hover {
transform: scale(1.05);
z-index: 1;
cursor: pointer;
}

label {
  cursor: pointer;
  color:  black;
}

`

export const PdfLoadingContainer = styled.div`
  background-color: var(--color-gray-11);
  color: var(--color-gray-10);
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormGroupBorder = styled(Form.Group)`
  border: 0.5px solid var(--color-gray-10);
  border-radius: 8px;
  padding: 10px;
`