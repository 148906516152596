import styled from "styled-components";
import { FaRegFolderOpen, FaClipboard } from "react-icons/fa";
import { Button, Card, Form } from "react-bootstrap";


export const ItemBorderCard = styled(Card)`
    background-color: #FFFAFA;
  border: none;
`

export const InfoTitle = styled.span`
  color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;

export const ContainerTitle = styled.div`
background-color: var(--color-gray-2);
  padding: 10px;
`

export const Container = styled.div`
  padding: 1rem;
  border: 1px dashed var(--color-gray-10);
`;

export const Item = styled.div`
  padding: 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  
  span{
    font-size: 0.75rem;
  }

  h4 {
    font-size: 1rem;
    color: black;
    display: flex;
    margin-left: 0.2rem;
    margin-bottom: 0;
    text-align: left;
  }

  h3 {
    font-size: 1rem;
    display: flex;
    margin-left: 0.3rem;
    margin-bottom: 0;
    color: var(--color-blue-90);
    text-align: left;
    white-space: nowrap;
  }
`;

export const FolderIcon = styled(FaRegFolderOpen)`
  color: var(--color-gray-11);
`;
export const ClipboardIcon = styled(FaClipboard)`
  color: var(--color-gray-11);
`;
export const SubtitleIcon = styled.span`
  font-size: 1rem;
  color: red;
`;

export const Title = styled.h1`
  font-size: 1rem;
  color: var(--color-gray-15);
  font-weight: bold;
  margin-left: 0.2rem;
  margin-bottom: 10px;
`;

export const PageTitle = styled(Card.Title)`
  color: var(--color-gray-12);
  font-weight: normal;
  font-size: 1rem;
`;

export const CoverImage = styled.div`
  width: 100%;
  height:0;
  padding-bottom: 100%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center center;
`;

export const ImageTitle = styled.p`
  font-size: 0.8rem;
  color: var(--color-gray-11);
`;

export const ShowModalButton = styled(Button)`
  border: none;
  background-color: transparent;
  color: var(--color-gray-15);
  font-size: 0.8rem;
  transition: transform 300ms;

  &:hover {
    border: none;
    background-color: transparent;
    color: var(--color-gray-15);
    transform: scale(1.05);
    z-index: 1;
  }

  &:active,
  &:focus {
    border: none;
    background-color: transparent !important;
    color: var(--color-gray-15);
  }
`;

export const H4Status = styled.h4`
  color: ${({ status }) =>
    status === "REGULAR" || status === "ATIVA" || status === "ATIVO"
      ? "#07b141"
      : "red"}!important;
`;

export const BorderForm = styled(Form.Group)`
  border: 0.875px solid var(--color-gray-10);
  border-radius: 6px;
  padding: 10px;
`