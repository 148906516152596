import { FaEdit, FaSave, FaTrashAlt } from "react-icons/fa";

import ButtonLink from "../../../../../../Shared/Components/ButtonLink/ButtonLink";
import { SubmitButton } from "../../../../../../Shared/Components/SubmitButton/SubmitButton";

export const ActionsTruck = ({
  handleDeleteData,
  isEdit,
  handleEnableTruckRegistration,
  handleSaveData,
  defaultTruck,
  setIsEdit,
  setIsPendentingForm,
  isUpdate,
}) => {
  return (
    <div className="d-flex align-items-end justify-content-end">
      <div className="d-flex justify-content-end mt-3 gap-1">
        {defaultTruck && !isEdit && (
          <ButtonLink
            icon={<FaEdit size={14} />}
            label={"Habilitar edição da carreta"}
            buttoncolor="primary"
            fontSize="0.85rem"
            handleClick={handleEnableTruckRegistration}
          />
        )}
        {defaultTruck && isEdit && (
          <SubmitButton
            labelButton={`${isEdit ? "Editar" : "Salvar"} carreta`}
            fontSize="0.85rem"
            icon={isEdit ? <FaSave size={14} /> : <FaEdit size={14} />}
          />
        )}
        {!defaultTruck && isUpdate && (
          <SubmitButton
            labelButton="Salvar carreta"
            fontSize="0.85rem"
            icon={<FaSave size={14} />}
          />
        )}
        <ButtonLink
          icon={<FaTrashAlt size={14} />}
          label="Remover carreta"
          buttoncolor="secondary"
          fontSize="0.85rem"
          handleClick={handleDeleteData}
        />
        {isEdit && defaultTruck && (
          <ButtonLink
            label="Cancelar"
            handleClick={() => {
              setIsEdit(false);
              setIsPendentingForm(false);
            }}
            fontSize="0.85rem"
            buttoncolor="gray"
          />
        )}
      </div>
    </div>
  );
};
