import { DriverInformationComparisonCard } from "./DriverInformationComparisonCard";
import moment from "moment";
import {
  gendersOptions,
  nationalitiesOptions,
} from "../../../../../Shared/Utils/constants";
import { Row } from "react-bootstrap";

export const DriverInformationComparison = ({
  driver,
  driverSat,
  updateDriver,
  register,
  handleInputChange,
  setValue,
  errors,
  setHasInconsistencies,
}) => {
  return (
    <Row className="g-3" xs={1}>
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Nome completo:"
        driverItem={driver?.nome}
        satItem={driverSat?.nome}
        driver={driver}
        item="nome"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Celular motorista:"
        driverItem={driver?.numero_celular}
        satItem={driverSat?.celular}
        driver={driver}
        item="numero_celular"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="RG:"
        driverItem={driver?.rg_motorista}
        satItem={driverSat?.rg}
        driver={driver}
        item="rg_motorista"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Emissão RG:"
        driverItem={
          driver?.dt_emissao_rg &&
          moment.utc(driver?.dt_emissao_rg).format("DD-MM-YYYY")
        }
        satItem={
          driverSat?.dataemissaorg &&
          moment.utc(driverSat?.dataemissaorg).format("DD-MM-YYYY")
        }
        driver={driver}
        item="dt_emissao_rg"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Órgão de emissão RG:"
        driverItem={driver?.orgao_rg}
        satItem={driverSat?.orgaorg}
        driver={driver}
        item="orgao_rg"
        onUpdateDriverData={updateDriver}
        register={register}
      />

      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Número da CNH:"
        driverItem={driver?.cnh}
        satItem={driverSat?.cnh}
        driver={driver}
        item="cnh"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Validade da CNH:"
        driverItem={
          driver?.dt_validade_cnh &&
          moment.utc(driver?.dt_validade_cnh).format("DD-MM-YYYY")
        }
        satItem={
          driverSat?.validadecarteira &&
          moment.utc(driverSat?.validadecarteira).format("DD-MM-YYYY")
        }
        driver={driver}
        item="dt_validade_cnh"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Emissão da CNH:"
        driverItem={
          driver?.dt_emissao_cnh &&
          moment.utc(driver?.dt_emissao_cnh).format("DD-MM-YYYY")
        }
        satItem={
          driverSat?.dataemissaocnh &&
          moment.utc(driverSat?.dataemissaocnh).format("DD-MM-YYYY")
        }
        driver={driver}
        item="dt_emissao_cnh"
        onUpdateDriverData={updateDriver}
        register={register}
      />

      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Primeira CNH:"
        driverItem={
          driver?.dt_primeira_cnh &&
          moment.utc(driver?.dt_primeira_cnh).format("DD-MM-YYYY")
        }
        satItem={
          driverSat?.dataprimeiracnh &&
          moment.utc(driverSat?.dataprimeiracnh).format("DD-MM-YYYY")
        }
        driver={driver}
        item="dt_primeira_cnh"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Nascimento:"
        driverItem={
          driver?.dt_nascimento &&
          moment.utc(driver?.dt_nascimento).format("DD-MM-YYYY")
        }
        satItem={
          driverSat?.datanascimento &&
          moment.utc(driverSat?.datanascimento).format("DD-MM-YYYY")
        }
        driver={driver}
        item="dt_nascimento"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Nome do pai:"
        driverItem={driver?.nome_pai}
        satItem={driverSat?.nomepai}
        driver={driver}
        item="nome_pai"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Nome da mãe:"
        driverItem={driver?.nome_mae}
        satItem={driverSat?.nomemae}
        driver={driver}
        item="nome_mae"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="País de origem:"
        driverItem={
          driver?.nacionalidade &&
          nationalitiesOptions.find(
            (nat) => nat.value === driver?.nacionalidade
          )?.label
        }
        satItem={
          driverSat?.nacionalidade &&
          nationalitiesOptions.find(
            (nat) => nat.value === driverSat?.nacionalidade
          )?.label
        }
        driver={driver}
        item="nacionalidade"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Gênero:"
        driverItem={
          driver?.sexo &&
          gendersOptions.find((gender) => gender.value === driver?.sexo).label
        }
        satItem={
          driverSat?.sexo &&
          gendersOptions.find((gender) => gender.value === driverSat?.sexo)
            .label
        }
        driver={driver}
        item="sexo"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="CEP:"
        driverItem={driver?.cep}
        satItem={driverSat?.cep}
        driver={driver}
        item="cep"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Estado:"
        driverItem={driver?.uf}
        satItem={driverSat?.cidade[0]?.uf}
        driver={driver}
        item="uf"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Cidade:"
        driverItem={driver?.cidade}
        satItem={driverSat?.cidade[0]?.nome}
        driver={driver}
        item="cidade"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        setValue={setValue}
        handleInputChange={handleInputChange}
        label="Endereço:"
        driverItem={driver?.endereco}
        satItem={driverSat?.endereco}
        driver={driver}
        item="endereco"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        handleInputChange={handleInputChange}
        label="Bairro:"
        driverItem={driver?.bairro}
        satItem={driverSat?.bairro}
        driver={driver}
        item="bairro"
        onUpdateDriverData={updateDriver}
        register={register}
      />
      <DriverInformationComparisonCard
        setHasInconsistencies={setHasInconsistencies}
        errors={errors}
        handleInputChange={handleInputChange}
        label="Número:"
        driverItem={driver?.numero}
        satItem={driverSat?.numero}
        driver={driver}
        item="numero"
        onUpdateDriverData={updateDriver}
        register={register}
      />
    </Row>
  );
};
