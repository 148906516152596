import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest from "../Shared/Utils/Request";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export const PerfilContext = createContext({
  perfis: [],
  perfil: null,
  isLoading: false,
  isLoadingPerfis: false,
  isSubmitting: false,
  permissionPerfilAction: [],
  getPerfis: () => {},
  getPerfil: () => null,
  addPerfil: () => null,
  deletePerfil: () => null,
  updatePerfil: () => null,
  getPerfisByScope: () => null,
  getPermissionPerfilAction: () => null,
});

export const usePerfis = () => {
  const context = useContext(PerfilContext);

  if (!context) {
    throw new Error("usePerfis must be within PerfilProvider");
  }

  return context;
};

export const PerfilProvider = ({ children }) => {
  const [perfis, setPerfis] = useState([]);
  const [perfil, setPerfil] = useState();
  const [permissionPerfilAction, setPermissionPerfilAction] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPerfis, setIsLoadingPerfis] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getPerfis = useCallback(async () => {
    try {
      setIsLoadingPerfis(true);

      const response = await ApiRequest({
        path: "perfis",
        method: "GET",
      });

      setIsLoadingPerfis(false);

      setPerfis(response?.data);
    } catch (error) {
      setPerfis([]);
    } finally {
      setIsLoadingPerfis(false);
    }
  }, []);

  const getPerfisByScope = useCallback(async (scope) => {
    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: `perfis/escopo/${scope}`,
        method: "GET",
      });

      setIsLoading(false);

      setPerfis(response?.data);
    } catch (error) {
      setPerfis([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getPermissionPerfilAction = useCallback(async (id) => {
    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: `acoes/perfil/${id}`,
        method: "GET",
      });

      setIsLoading(false);

      setPermissionPerfilAction(response?.data);
    } catch (error) {
      setPermissionPerfilAction([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getPerfil = useCallback(async (id) => {
    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: `perfis/id/${id}`,
        method: "GET",
      });

      setPerfil(response?.data);
      setIsLoading(false);
    } catch (error) {
      setPerfis(null);
    }

    setIsLoading(false);
  }, []);

  const updatePerfil = useCallback(
    async (id, data, navigate) => {
      try {
        setIsSubmitting(true);

        await ApiRequest({
          path: `perfis/id/${id}`,
          method: "PUT",
          data,
        });

        navigate("/perfis");

        const response = await getPerfis();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Perfil atualizado com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Perfil não pode ser atualizado.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);
      } finally {
        setIsLoading(false);
        setIsSubmitting(false);
      }
    },
    [getPerfis]
  );

  const deletePerfil = useCallback(
    async (id) => {
      try {
        setIsSubmitting(true);

        await ApiRequest({
          path: `perfis/id/${id}`,
          method: "DELETE",
        });

        const response = await getPerfis();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Perfil excluído com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: error?.response?.data?.error
            ? error?.response?.data?.error
            : "Perfil não pode ser excluído.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    },
    [getPerfis]
  );

  const addPerfil = useCallback(
    async (newAction, navigate) => {
      try {
        setIsSubmitting(true);

        await ApiRequest({
          path: "perfis",
          method: "POST",
          data: newAction,
        });

        navigate("/perfis");

        const response = await getPerfis();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Perfil criado com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `${
            error?.response?.data?.error
              ? error?.response?.data?.error
              : "Perfil não pode ser criado"
          }`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);
      } finally {
        setIsLoading(false);
        setIsSubmitting(false);
      }
    },
    [getPerfis]
  );

  const providerValue = useMemo(
    () => ({
      perfis,
      perfil,
      isLoading,
      isSubmitting,
      permissionPerfilAction,
      isLoadingPerfis,
      getPerfis,
      getPerfil,
      addPerfil,
      deletePerfil,
      updatePerfil,
      getPerfisByScope,
      getPermissionPerfilAction,
    }),
    [
      perfis,
      perfil,
      isLoading,
      isSubmitting,
      permissionPerfilAction,
      isLoadingPerfis,
      getPerfis,
      getPerfil,
      addPerfil,
      deletePerfil,
      updatePerfil,
      getPerfisByScope,
      getPermissionPerfilAction,
    ]
  );

  return (
    <PerfilContext.Provider value={providerValue}>
      {children}
    </PerfilContext.Provider>
  );
};
