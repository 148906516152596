import { useState } from "react";
import { FilterContent, SearchContainer } from "./styles";
import { IoFilterSharp } from "react-icons/io5";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { SubmitButton } from "../../../../../Shared/Components/SubmitButton/SubmitButton";
import ButtonLink from "../../../../../Shared/Components/ButtonLink/ButtonLink";
import { IoMdClose } from "react-icons/io";
import { FaCheck } from "react-icons/fa";

export const SearchLoaderOrder = ({ getList }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const handleClearFilter = () => {
    reset();
    setIsOpen(false);
    getList();
  };

  const onSubmit = (data) => {
    const newData = Object.keys(data).reduce((acc, key) => {
      if (data[key] !== "") {
        if (key === "nome_mot") {
          acc[key] = data[key].toUpperCase();
        } else {
          acc[key] = data[key];
        }
      }
      return acc;
    }, {});

    Object.keys(newData).length > 0 ? getList(1, newData) : getList();
  };

  return (
    <div className="mb-1">
      <div className="d-flex justify-content-end mb-1">
        <SearchContainer
          onClick={() => setIsOpen(!isOpen)}
          title="Abrir filtro das OCs"
        >
          <span>Filtrar</span>
          <IoFilterSharp color="#FFF" />
        </SearchContainer>
      </div>
      {isOpen && (
        <FilterContent>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="d-flex g-1">
              <Form.Group as={Col} xs={12} md={3} controlId="formStartDate">
                <FloatingLabel controlId="formStartDate" label="Início">
                  <Form.Control
                    {...register("data_inicio")}
                    size="sm"
                    type="date"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={3} controlId="formEndDate">
                <FloatingLabel controlId="formEndDate" label="Fim">
                  <Form.Control
                    {...register("data_fim")}
                    size="sm"
                    type="date"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md={6} controlId="formNome">
                <FloatingLabel controlId="formNome" label="Nome motorista">
                  <Form.Control
                    {...register("nome_mot")}
                    size="sm"
                    type="text"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md={6} controlId="cpf-driver">
                <FloatingLabel controlId="cpf-driver" label="CPF motorista">
                  <Form.Control
                    {...register("cpf_mot")}
                    size="sm"
                    as={ReactInputMask}
                    mask={"999.999.999-99"}
                    maskChar={null}
                    type="text"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md={6} controlId="OCNumber">
                <FloatingLabel controlId="OCNumber" label="Número da OC">
                  <Form.Control
                    {...register("id_ordem_carregamento")}
                    size="sm"
                    type="text"
                    as={ReactInputMask}
                    mask="999999999"
                    maskChar={null}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <div className="d-flex align-items-center justify-content-end gap-1 mt-2">
              <SubmitButton
                labelButton="Aplicar filtro"
                icon={<FaCheck size="0.7rem" />}
              />
              <ButtonLink
                label="Limpar filtro"
                fontSize="0.7rem"
                buttoncolor="gray"
                handleClick={handleClearFilter}
                icon={<IoMdClose size="0.7rem" />}
              />
            </div>
          </Form>
        </FilterContent>
      )}
    </div>
  );
};
