import styled from "styled-components";

export const InfoItem = styled.span`
  color: ${(props) =>
    props.status === "placa"
      ? "#0071c7"
      : (props.status === "ATIVA" || props.status === "Ativa" || props.status === "REGULAR" || props.status === "Regular")
        ? "#198754"
        : props.status === "Requer atenção"
          ? "#C3A300" : props.status === "Pré-cadastro" ? "#b0b0b0"
            : "#4d4d4d"};
  font-weight: normal;
  font-size: 0.875rem;
  margin-bottom: 0;
  white-space: ${({ titleStyle }) => titleStyle && "nowrap"};

  text-transform: ${(props) =>
    props.status === "ATIVO" ? "capitalize" : "initial"};
    
  overflow: ${({ content }) => (content ? "hidden" : "visible")};
  text-overflow: ${({ content }) => (content ? "ellipsis" : "clip")};
  flex: ${({ content }) => (content ? "1" : "0 0 auto")};
    

`;

export const AlertLabel = styled.span`
font-size: 0.7rem;
`