import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./style.css";
import { Header } from "../Components/Header/Header";
import { useLogins } from "../../hooks/LoginProvider";
import { Sidebar } from "./Sidebar";
import { CardContainer, CardContent, HomeSection } from "./styles";
import { NavbarComponent } from "./Navbar";
import { useMediaQuery } from "@material-ui/core";
import { Loading } from "../../Shared/Components/Loading/Loading";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { onlogout } from "../Utils/Auth";
import { IdleTimerContainer } from "../Components/IdleTimerContainer";
import BreadcrumbComponent from "../Components/BreadCrumb/Breadcrumbs";

export default function Dashboard(props) {
  const [isClose, setIsClose] = useState(
    localStorage.getItem("lastIsCloseValue")
      ? JSON.parse(localStorage.getItem("lastIsCloseValue"))
      : false
  );

  const { currentUser, currentPerfilName, isLoading } = useLogins();

  const navigate = useNavigate();

  const handleDesconect = useCallback(() => {
    Swal.fire({
      icon: "warning",
      title: "Deseja sair do sistema?",
      showCancelButton: true,
      confirmButtonColor: "#093c5e",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Sair",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onlogout().then(() => navigate("/"));
      }
    });
  }, [navigate]);

  const handleSidebarClick = () => {
    setIsClose(!isClose);
  };

  useEffect(() => {
    localStorage.setItem("lastIsCloseValue", isClose);
  }, [isClose]);

  const isScreenSmall = useMediaQuery("(max-width: 991px)");

  return (
    <>
      <IdleTimerContainer />

      <Container
        fluid
        id="container-app-default"
        style={{ minHeight: "100vh" }}
      >
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Sidebar isClose={isClose} onClick={handleSidebarClick} />
            <HomeSection
              isScreenSmall={isScreenSmall}
              isClose={isClose}
              className="d-flex flex-column flex-grow-1"
            >
              <NavbarComponent
                desconect={handleDesconect}
                nome={currentUser}
                currentPerfilName={currentPerfilName}
              />
              <Header
                desconect={handleDesconect}
                nome={currentUser}
                currentPerfilName={currentPerfilName}
              />

              <CardContainer
                className="p-3 h-100 d-flex flex-column flex-grow-1"
                id="card-container"
                isScreenSmall={isScreenSmall}
                isClose={isClose}
              >
                <CardContent
                  className="p-4 border-0 d-flex flex-column flex-grow-1"
                  id="card-content"
                >
                  <div
                    className="d-flex flex-column flex-grow-1 min-h-100"
                    style={{ flex: 1 }}
                  >
                    <BreadcrumbComponent title={props?.title} />
                    {props.children}
                  </div>
                </CardContent>
              </CardContainer>
            </HomeSection>
          </>
        )}
      </Container>
    </>
  );
}
