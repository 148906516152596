import { ItalicH4, Item, PageTitle } from "./styles";
import NameSimilarityCard from "../../../../../Shared/Components/NameSimilarityCard/NameSimilarityCard";
import ValidationBoolean from "./ValidationBoolean";
import ValidationIcon from "./ValidationIcon";

const ValidationsList = ({ biometric, cnhValidation }) => {
  return (
    <div className="ms-md-3">
      <PageTitle className="d-flex justify-content-center  mb-3">
        Validações Flex
      </PageTitle>
      <div>
        <Item className="gap-1">
          <h3>Categoria CNH:</h3>
          {biometric || cnhValidation ? (
            <ValidationBoolean
              biometricItem={biometric?.categoria_cnh}
              cnhItem={cnhValidation?.categoria_cnh}
            />
          ) : (
            <span>--</span>
          )}
        </Item>

        <Item className="gap-1">
          <h3>CNH emitida:</h3>
          {biometric || cnhValidation ? (
            <ValidationBoolean
              biometricItem={biometric?.cnh_emitida}
              cnhItem={cnhValidation?.cnh_emitida}
            />
          ) : (
            <span>--</span>
          )}
        </Item>

        <Item className="gap-1">
          <h3>CPF motorista disponível:</h3>
          {biometric || cnhValidation ? (
            <ValidationBoolean
              biometricItem={biometric?.cpf_motorista_disponivel}
              cnhItem={cnhValidation?.cpf_motorista_disponivel}
            />
          ) : (
            <span>--</span>
          )}
        </Item>

        <Item className="gap-1">
          <h3>CPF motorista regular:</h3>
          {biometric || cnhValidation ? (
            <ValidationBoolean
              biometricItem={biometric?.cpf_motorista_regular}
              cnhItem={cnhValidation?.cpf_motorista_regular}
            />
          ) : (
            <span>--</span>
          )}
        </Item>

        <Item className="gap-1">
          <h3>Data primeira cnh:</h3>
          {biometric || cnhValidation ? (
            <ValidationBoolean
              biometricItem={biometric?.dt_primeira_cnh}
              cnhItem={cnhValidation?.dt_primeira_cnh}
            />
          ) : (
            <span>--</span>
          )}
        </Item>

        <Item className="gap-1">
          <h3>Data da última emissão da CNH:</h3>
          {biometric || cnhValidation ? (
            <ValidationBoolean
              biometricItem={biometric?.dt_ultima_emissao_cnh}
              cnhItem={cnhValidation?.dt_ultima_emissao_cnh}
            />
          ) : (
            <span>--</span>
          )}
        </Item>

        <Item className="gap-1">
          <h3>Data de validade da CNH:</h3>
          {biometric || cnhValidation ? (
            <ValidationBoolean
              biometricItem={biometric?.dt_validade_cnh}
              cnhItem={cnhValidation?.dt_validade_cnh}
            />
          ) : (
            <span>--</span>
          )}
        </Item>
        <Item className="gap-1">
          <h3>Nome motorista:</h3>
          {biometric || cnhValidation ? (
            <>
              <ValidationIcon
                biometricItem={biometric?.nome_motorista?.similaridade}
                cnhItem={cnhValidation?.nome_motorista?.similaridade}
              />
              {biometric?.nome_motorista?.similaridade && (
                <NameSimilarityCard
                  label="% de similariade com o nome:"
                  item={biometric?.nome_motorista?.similaridade}
                />
              )}
              {!biometric?.nome_motorista?.similaridade && (
                <NameSimilarityCard
                  label="% de similariade com o nome:"
                  item={cnhValidation?.nome_motorista?.similaridade}
                />
              )}
            </>
          ) : (
            <span>--</span>
          )}
        </Item>

        <Item className="gap-1">
          <h3>Nome da mãe:</h3>
          {biometric || cnhValidation ? (
            <>
              <ValidationIcon
                biometricItem={biometric?.nome_mae?.similaridade}
                cnhItem={cnhValidation?.nome_mae?.similaridade}
              />
              {biometric?.nome_mae?.similaridade && (
                <NameSimilarityCard
                  label="% de similariade com o nome:"
                  item={biometric?.nome_mae?.similaridade}
                />
              )}
              {!biometric?.nome_mae?.similaridade && (
                <NameSimilarityCard
                  label="% de similariade com o nome:"
                  item={cnhValidation?.nome_mae?.similaridade}
                />
              )}
            </>
          ) : (
            <span>--</span>
          )}
        </Item>

        <Item className="gap-1">
          <h3>Nome do pai:</h3>
          {biometric || cnhValidation ? (
            <>
              <ValidationIcon
                biometricItem={biometric?.nome_pai?.similaridade}
                cnhItem={cnhValidation?.nome_pai?.similaridade}
              />
              {biometric?.nome_pai?.similaridade && (
                <NameSimilarityCard
                  label="% de similariade com o nome:"
                  item={biometric?.nome_pai?.similaridade}
                />
              )}
              {!biometric?.nome_pai?.similaridade && (
                <NameSimilarityCard
                  label="% de similariade com o nome:"
                  item={cnhValidation?.nome_pai?.similaridade}
                />
              )}
            </>
          ) : (
            <ItalicH4>Não consta</ItalicH4>
          )}
        </Item>

        <Item className="gap-1">
          <h3>Número da CNH:</h3>
          {biometric || cnhValidation ? (
            <ValidationIcon
              biometricItem={biometric?.numero_cnh}
              cnhItem={cnhValidation?.numero_cnh}
            />
          ) : (
            <span>--</span>
          )}
        </Item>

        <Item className="gap-1">
          <h3>CNH sem impedimento:</h3>
          {biometric || cnhValidation ? (
            <ValidationIcon
              biometricItem={biometric?.possui_impedimento_cnh}
              cnhItem={cnhValidation?.possui_impedimento_cnh}
            />
          ) : (
            <span>--</span>
          )}
        </Item>

        <Item className="gap-1">
          <h3>Gênero:</h3>
          {biometric || cnhValidation ? (
            <ValidationIcon
              biometricItem={biometric?.sexo_motorista}
              cnhItem={cnhValidation?.sexo_motorista}
            />
          ) : (
            <span>--</span>
          )}
        </Item>
        <Item className="gap-1">
          <h3>Nacionalidade:</h3>
          {biometric || cnhValidation ? (
            <ValidationIcon
              biometricItem={biometric?.nacionalidade_motorista}
              cnhItem={cnhValidation?.nacionalidade_motorista}
            />
          ) : (
            <span>--</span>
          )}
        </Item>
        {/* <Item className="gap-1">
          <h3>Registro nacional estrangeiro - CNH:</h3>
          {biometric || cnhValidation ? (
            <ValidationIcon
              biometricItem={biometric?.registro_nacional_estrangeiro_cnh}
              cnhItem={cnhValidation?.registro_nacional_estrangeiro_cnh}
            />
          ) : (
            <span>--</span>
          )}
        </Item> */}
      </div>
    </div>
  );
};

export default ValidationsList;
