import { IoIosCloseCircle } from "react-icons/io";

import { ErrorTitle, WarningCard } from "./styles";

export const EmptyArea = () => {
  return (
    <WarningCard>
      <div className="d-flex flex-column gap-2 justify-content-center mb-3 text-center">
        <div className="d-flex justify-content-center">
          <IoIosCloseCircle color="red" size={62} />
        </div>
        <ErrorTitle>Erro</ErrorTitle>
      </div>
      <div className="d-flex flex-column gap-3">
        <span>Desculpe, não há CT-e listado para esta área no momento.</span>
        <span>
          Por favor, verifique novamente mais tarde ou entre em contato conosco
          para obter assistência.
        </span>
      </div>
    </WarningCard>
  );
};
