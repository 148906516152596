import { Table } from "react-bootstrap";

import { EmptyTableMessage } from "../EmptyTableMessage";

import {
  TableContainer,
  TableTitle,
  Thead,
  TitleItem,
  VehicleConsultTitle,
} from "./styles";

export const VehicleRestrictionTable = ({ detranConsult }) => {
  return (
    <div className="d-flex flex-column gap-2">
      {Array.isArray(detranConsult?.data?.restricoes) &&
        detranConsult?.data?.restricoes.length > 0 && (
          <div>
            <div className="mb-2 justify-content-center">
              <VehicleConsultTitle className="justify-content-center">
                <span>Restrições</span>
              </VehicleConsultTitle>
            </div>
            <TableContainer>
              <Table hover size="sm" className="border rounded mb-0">
                <Thead>
                  <TableTitle>
                    <TitleItem>Código</TitleItem>
                    <TitleItem>Descrição</TitleItem>
                  </TableTitle>
                </Thead>
                <tbody>
                  {detranConsult?.data?.restricoes.map((event, index) => (
                    <tr key={index}>
                      <td className="ps-3">{event?.codigo}</td>
                      <td>{event?.restricao}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {detranConsult?.data?.restricoes.length === 0 && (
                <EmptyTableMessage label="Não há registro de restrições" />
              )}
            </TableContainer>
          </div>
        )}
    </div>
  );
};
