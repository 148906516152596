import { Table } from "react-bootstrap";
import { FiEdit2 } from "react-icons/fi";
import { PerfilListContainer, PerfilTableTitle } from "./styles";
import { useCallback } from "react";
import { ActionIconButton } from "../../../../Shared/Components/ActionIconButton";
import { FaTrashAlt } from "react-icons/fa";
import { firstUppercase } from "../../../../Shared/Utils/data";

export const PerfilList = ({
  perfis,
  onDelete,
  navigate,
  perfilManagementPermissionToUpdate,
  perfilManagementPermissionToDelete,
  getPerfil,
}) => {
  const handleEdit = useCallback(
    (perfil) => {
      getPerfil(perfil.id);
      navigate(`/perfis-atualizar/${perfil.id}`);
    },
    [getPerfil, navigate]
  );

  return (
    <PerfilListContainer>
      <Table hover size="sm" className="border rounded mb-0">
        <thead>
          <PerfilTableTitle>
            <th className="ps-3">Nome</th>
            <th className="ps-4">Descrição</th>
            {((perfilManagementPermissionToUpdate &&
              perfilManagementPermissionToUpdate !== "false") ||
              (perfilManagementPermissionToDelete &&
                perfilManagementPermissionToDelete !== "false")) && (
              <th className="justify-content-end px-2 ">Ações</th>
            )}
          </PerfilTableTitle>
        </thead>
        <tbody>
          {perfis.length > 0 &&
            perfis.map((perfil, key) => (
              <tr key={key}>
                <td className="ps-3">
                  {perfil?.nome ? firstUppercase(perfil?.nome) : ""}
                </td>
                <td className="ps-4">
                  {perfil?.descricao ? firstUppercase(perfil?.descricao) : ""}
                </td>
                <td>
                  <div className="d-flex gap-1">
                    {perfilManagementPermissionToUpdate &&
                      perfilManagementPermissionToUpdate !== "false" && (
                        <ActionIconButton
                          title="Editar"
                          handleClick={() => handleEdit(perfil)}
                          icon={<FiEdit2 size={15} color="#5f5f5f" />}
                        />
                      )}
                    {perfilManagementPermissionToDelete &&
                      perfilManagementPermissionToDelete !== "false" && (
                        <ActionIconButton
                          title="Deletar"
                          handleClick={() => onDelete(perfil)}
                          icon={<FaTrashAlt size={13} color="#cb1313" />}
                        />
                      )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </PerfilListContainer>
  );
};
