import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Tab } from "react-bootstrap";

import { BorderCard, Column, DriverRow, StyledTabs } from "./styles";
import { DriverTab } from "./Tabs/Driver/DriverTab";
import VehicleInformationTab from "./Tabs/Vehicle/VehicleInformationTab";
import { RegistrationInformationAndValidationsTab } from "./Tabs/Information/RegistrationInformationAndValidationsTab";

import ControlTemporaryDriverCard from "../components/ControlTemporaryDriverCard/ControlTemporaryDriverCard";

import Dashboard from "../../../../../Shared/layout";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { AlertMessage } from "../../../../../Shared/Components/AlertMessage";
import { InfosSat } from "../../../../../Shared/Components/InfosSat/InfosSat";
import ImageModal from "../../../../../Shared/Components/ImageModal/ImageModal";
import { AlertQuestionComponent } from "../../../../../Shared/Components/Alerts";
import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";
import { HistoryCard } from "../../../../../Shared/Components/HistoryCard/HistoryCard";

import { useOwners } from "../../../../../hooks/OwnerProvider";
import { useErrors } from "../../../../../hooks/ErrorsProvider";
import { useDrivers } from "../../../../../hooks/DriverProvider";
import { useVehicles } from "../../../../../hooks/VehicleProvider";
import { useDriversValidation } from "../../../../../hooks/DriverValidationProvider";

const TemporaryDriver = () => {
  const [showModal, setShowModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [tab, setTab] = useState("info");
  const [defaultActiveKey, setDefaultActiveKey] = useState("0");
  const [loadingConsult, setLoadingConsult] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const { updateVehicle } = useVehicles();
  const { updateOwner } = useOwners();
  const { errors, getErrors } = useErrors();

  const {
    driver,
    driverSat,
    isLoading,
    isLoadingTemporaryDriver,
    tempRegister,
    driverConsults,
    loadingSatDriver,
    loadingFederalInformations,
    getDriver,
    getDriverSat,
    deleteDriver,
    getTempRegister,
    getDriverConsults,
    postDriverConsults,
    updateDriversByStatus,
    deleteDriverFromTemporaryTable,
    addVehiclesAndOwners,
  } = useDrivers();

  const {
    driverValidation,
    judicialProcess,
    judicialProcessLoading,
    getDriverValidation,
    getJudicialProcessConsult,
    postJudicialProcessConsult,
  } = useDriversValidation();

  useEffect(() => {
    getDriver(id);
    getTempRegister(id);
  }, [getDriver, getTempRegister, id]);

  const onDelete = useCallback(() => {
    deleteDriver(id, navigate);
    deleteDriverFromTemporaryTable(id);
    setShowModal(false);
    navigate("/motoristas/processos-em-curso");
  }, [deleteDriver, deleteDriverFromTemporaryTable, id, navigate]);

  const handleNewConsult = useCallback(
    async (position) => {
      setShowErrorModal(false);
      await postDriverConsults(tempRegister?.token, position);
      await getDriverConsults(tempRegister?.id);
    },
    [
      getDriverConsults,
      postDriverConsults,
      tempRegister?.id,
      tempRegister?.token,
    ]
  );

  const handleConsultCPFBtn = useCallback(async () => {
    setLoadingConsult(true);
    try {
      Promise.all([postDriverConsults(tempRegister?.token)])
        .then(() => {
          getDriver(id);
          getDriverValidation(id);
          getDriverConsults(tempRegister?.id);
          postJudicialProcessConsult(id);
        })
        .catch((error) => console.error(`Erro nas promises ${error}`));
    } catch (error) {
      console.error({ error });
    } finally {
      getDriverValidation(id);
      getDriverConsults(tempRegister?.id);
      setLoadingConsult(false);
    }
  }, [
    postDriverConsults,
    tempRegister?.token,
    tempRegister?.id,
    getDriver,
    id,
    getDriverValidation,
    getDriverConsults,
    postJudicialProcessConsult,
  ]);

  const handleOpenVehicleTab = (index) => {
    setTab("vehicle");
    setDefaultActiveKey(index);
  };

  useEffect(() => {
    driver?.status_cadastro !== "1r" && getDriverValidation(id);
  }, [id, getDriverValidation, driver?.status_cadastro]);

  const travels = useMemo(() => {
    if (driver?.viagens) {
      return JSON.parse(driver?.viagens);
    } else {
      return null;
    }
  }, [driver?.viagens]);

  useEffect(() => {
    if (tempRegister) {
      getDriverConsults(tempRegister?.id);
    }
  }, [getDriverConsults, tempRegister, handleNewConsult]);

  useEffect(() => {
    driver?.id && driver?.status_cadastro !== "0r" && getDriverSat(driver?.id);
  }, [driver?.id, driver?.status_cadastro, getDriverSat]);

  const driverConsult = useMemo(
    () =>
      driverConsults &&
      driverConsults.length > 0 &&
      driverConsults.find((driver) => driver?.posicao === "MOTORISTA"),
    [driverConsults]
  );

  useEffect(() => {
    getDriver(id);
    getTempRegister(id);
    getJudicialProcessConsult(id);
  }, [
    getDriver,
    getTempRegister,
    id,
    driver?.status_cadastro,
    getJudicialProcessConsult,
  ]);

  useEffect(() => {
    if (
      (
        driverConsults &&
        driverConsults?.length > 0 &&
        driverConsults.filter(
          (consult) =>
            !consult?.concluido ||
            consult.processamento_antecedentes_criminais ||
            consult.processamento_mandados_prisao
        )
      ).length > 0 ||
      driver?.status_cadastro === "2r" ||
      (driver?.status_cadastro === "5r" &&
        driver?.processos_cadastro_concluido === false)
    ) {
      const interval = setInterval(() => {
        getDriverValidation(id);
        getDriver(id);
        getDriverConsults(tempRegister?.id);
      }, 20000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    driver?.processos_cadastro_concluido,
    driver?.status_cadastro,
    driverConsult,
    driverConsults,
    getDriver,
    getDriverConsults,
    getDriverValidation,
    id,
    tempRegister?.id,
    handleNewConsult,
  ]);

  useEffect(() => {
    if (
      (
        driverConsults &&
        driverConsults?.length > 0 &&
        driverConsults.filter(
          (consult) =>
            !consult?.concluido ||
            consult.processamento_antecedentes_criminais ||
            consult.processamento_mandados_prisao
        )
      ).length > 0
    ) {
      const interval = setInterval(() => {
        getDriverConsults(tempRegister?.id);
        getJudicialProcessConsult(id);
      }, 20000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    driverConsults,
    getDriverConsults,
    getJudicialProcessConsult,
    id,
    tempRegister?.id,
  ]);

  const handleSelectTabAndCloseModal = useCallback((index) => {
    handleOpenVehicleTab(index);
    setShowErrorModal(false);
  }, []);

  const [biometricValidation, setBiometricValidation] = useState(
    driverValidation?.validacao_biometria?.status === "1" &&
      driverValidation?.validacao_biometria?.resposta
  );

  const [cnhValidation, setCnhValidation] = useState(
    driverValidation?.validacao_cnh?.status === "1" &&
      driverValidation?.validacao_cnh?.resposta
  );

  useEffect(() => {
    if (
      driverValidation?.validacao_biometria?.status === "1" &&
      driverValidation?.validacao_biometria?.resposta
    ) {
      setBiometricValidation(driverValidation?.validacao_biometria?.resposta);
    } else {
      setBiometricValidation(null);
    }
  }, [
    driverValidation,
    driverValidation?.validacao_biometria?.resposta,
    driverValidation?.validacao_biometria?.status,
  ]);

  useEffect(() => {
    if (
      driverValidation?.validacao_cnh?.status === "1" &&
      driverValidation?.validacao_cnh?.resposta
    ) {
      setCnhValidation(driverValidation?.validacao_cnh?.resposta);
    } else {
      setCnhValidation(null);
    }
  }, [
    driverValidation,
    driverValidation?.validacao_cnh?.resposta,
    driverValidation?.validacao_cnh?.status,
  ]);

  const validationValue = cnhValidation ?? biometricValidation;
  const flexValidation = useMemo(
    () =>
      validationValue &&
      Object.keys(validationValue).some((key) => {
        const value = validationValue[key];

        return value === false || value?.similaridade < 0.7;
      }),
    [validationValue]
  );

  const historyErros =
    travels &&
    !travels?.message &&
    travels.length > 0 &&
    travels.find((travel) => travel?.status_motorista === 2);

  useEffect(() => {
    if (
      driverConsults &&
      driverConsults?.length > 0 &&
      driverConsults.filter(
        (consult) =>
          !consult?.concluido ||
          consult.processamento_antecedentes_criminais ||
          consult.processamento_mandados_prisao
      ).length === 0 &&
      driver?.status_cadastro === "5r" &&
      driver?.processos_cadastro_concluido === true
    ) {
      getJudicialProcessConsult(id);

      getErrors(
        tempRegister,
        driverConsults,
        driver,
        handleNewConsult,
        biometricValidation,
        cnhValidation,
        driverValidation,
        loadingConsult,
        driverSat,
        handleSelectTabAndCloseModal,
        flexValidation,
        historyErros
      );
    }
  }, [
    driver,
    driverConsults,
    getErrors,
    tempRegister,
    handleNewConsult,
    biometricValidation,
    cnhValidation,
    driverValidation,
    loadingConsult,
    driverSat,
    handleSelectTabAndCloseModal,
    flexValidation,
    historyErros,
    getJudicialProcessConsult,
    id,
  ]);
  const isloadingConsult = useMemo(() => {
    if (driverConsults && driverConsults?.length > 0) {
      if (
        driverConsults.filter(
          (consult) =>
            !consult?.concluido ||
            consult.processamento_antecedentes_criminais ||
            consult.processamento_mandados_prisao
        ).length > 0
      ) {
        return true;
      }
      return false;
    }
    return false;
  }, [driverConsults]);

  return (
    <Dashboard>
      {(isLoading || loadingSatDriver || isLoadingTemporaryDriver) && (
        <Loading />
      )}
      {!isLoading &&
        !loadingSatDriver &&
        !isLoadingTemporaryDriver &&
        driver &&
        tempRegister && (
          <DriverRow
            xs={1}
            md={2}
            xl={3}
            className={"d-flex justify-content-between g-2 mt-1"}
          >
            <Column md={4} xl={2}>
              <ControlTemporaryDriverCard
                driver={driver}
                onDelete={onDelete}
                getDriver={getDriver}
                driverSat={driverSat}
                updateOwner={updateOwner}
                tempRegister={tempRegister}
                updateVehicle={updateVehicle}
                driverConsults={driverConsults}
                judicialProcess={judicialProcess}
                isloadingConsult={isloadingConsult}
                handleConsultBtn={handleConsultCPFBtn}
                addVehiclesAndOwners={addVehiclesAndOwners}
                updateDriversByStatus={updateDriversByStatus}
              />
            </Column>

            <Column md={8} xl={10}>
              <BorderCard className="w-100 card d-flex flex-column p-3">
                <StyledTabs activeKey={tab} onSelect={(key) => setTab(key)}>
                  <Tab eventKey="info" title="Informações">
                    <RegistrationInformationAndValidationsTab
                      errors={errors}
                      driverInformation={driver}
                      tempRegister={tempRegister}
                      cnhValidation={cnhValidation}
                      showErrorModal={showErrorModal}
                      driverConsults={driverConsults}
                      loadingConsult={loadingConsult}
                      judicialProcess={judicialProcess}
                      isloadingConsult={isloadingConsult}
                      driverValidation={driverValidation}
                      setShowErrorModal={setShowErrorModal}
                      biometricValidation={biometricValidation}
                      handleOpenVehicleTab={handleOpenVehicleTab}
                      judicialProcessLoading={judicialProcessLoading}
                    />
                    {loadingConsult && <Loading />}
                    {!loadingConsult && (
                      <div className="px-3 mt-3 gap-3 d-flex flex-column">
                        {driverSat && (
                          <InfosSat driver={driver} driverSat={driverSat} />
                        )}
                        {driver?.viagens && <HistoryCard driver={driver} />}
                      </div>
                    )}
                  </Tab>

                  <Tab eventKey="driver" title="Motorista">
                    {tempRegister && Object.keys(tempRegister).length > 0 ? (
                      <DriverTab
                        tempRegister={tempRegister}
                        driver={driver}
                        judicialProcess={judicialProcess}
                        isLoading={loadingFederalInformations}
                        driverConsults={driverConsult}
                        isloadingConsult={isloadingConsult}
                      />
                    ) : (
                      <div className="mt-4">
                        <EmptyTableMessage label="Não há registro na tabela temporária deste motorista" />
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey="vehicle" title="Veículo">
                    <VehicleInformationTab
                      driver={driver}
                      tempRegister={tempRegister}
                      driverConsults={driverConsults}
                      judicialProcess={judicialProcess}
                      defaultActiveKey={defaultActiveKey}
                      setDefaultActiveKey={setDefaultActiveKey}
                      handleNewConsult={handleNewConsult}
                      isloadingConsult={isloadingConsult}
                    />
                  </Tab>
                </StyledTabs>
              </BorderCard>
            </Column>
          </DriverRow>
        )}
      {!isLoading &&
        !loadingSatDriver &&
        !isLoadingTemporaryDriver &&
        driver === null &&
        tempRegister === null && (
          <div className="mt-4">
            <AlertMessage label="Não foram encontrados informações para este motorista" />
          </div>
        )}
      <AlertQuestionComponent
        callback={() => onDelete()}
        showAlert={showModal}
        cancel={() => setShowModal(false)}
        title="Deseja mesmo remover o motorista?"
        subtitle="Essa ação não pode ser desfeita!"
      />
      <ImageModal
        show={showImageModal}
        handleClose={() => setShowImageModal(false)}
        image={driver?.foto_cnh}
        title="CNH"
      />
    </Dashboard>
  );
};

export default memo(TemporaryDriver);
