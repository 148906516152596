import React, { useMemo } from "react";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-routing-machine";
import LeafletRoutingMachine from "./LeafletRoutingMachine";
import { Subtitle } from "./Subtitle";
import newIcon from "../../../Assets/Images/iconTruck.png";
import locationPin from "../../../Assets/Images/locationPin.svg";
import originPinLocation from "../../../Assets/Images/originPinLocation.png";
import ButtonLink from "../ButtonLink/ButtonLink";
import { HiOutlineArrowsExpand } from "react-icons/hi";
import { MdHorizontalRule } from "react-icons/md";
import { TitleContainer } from "./styles";

const OriginIcon = L.icon({
  iconUrl: originPinLocation,
  iconSize: [15, 15],
  iconAnchor: [7, 15],
  popupAnchor: [0, -15],
});

const DestinyIcon = L.icon({
  iconUrl: locationPin,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
  popupAnchor: [0, -30],
});

const RealTimeLocationIcon = L.icon({
  iconUrl: newIcon,
  iconSize: [20, 20],
  iconAnchor: [10, 20],
  popupAnchor: [0, -20],
});

export const RealTimeTravelMap = ({
  height,
  origin,
  destiny,
  currentAddress,
  loadOrder,
  showMore,
  show,
  routesSegments,
  route,
  showSubtitle = false,
  isFetching,
}) => {
  const routes = useMemo(() => {
    return route && JSON.parse(route);
  }, [route]);

  const defaultProps = {
    center: currentAddress ??
      origin ?? {
        lat: -15.616498333333334,
        lng: -56.078129999999994,
      },
  };

  const segments =
    routes &&
    routes.coordinates &&
    routes.coordinates.map((route) => [route.lat, route.lng]);

  const colorOption = { color: "#2185D0" };

  const title = useMemo(() => {
    if (
      loadOrder?.em_coleta &&
      !loadOrder?.em_trajeto &&
      !loadOrder?.em_descarga
    ) {
      return { label: "Em coleta", color: "primary" };
    }

    if (
      loadOrder?.em_coleta &&
      loadOrder?.em_trajeto &&
      currentAddress?.desvio_rota &&
      !loadOrder?.em_descarga
    ) {
      return { label: "Fora de rota", color: "danger" };
    }

    if (
      loadOrder?.em_coleta &&
      loadOrder?.em_trajeto &&
      !currentAddress?.desvio_rota &&
      !loadOrder?.em_descarga
    ) {
      return { label: "Em rota", color: "success" };
    }

    if (
      loadOrder?.em_coleta &&
      loadOrder?.em_trajeto &&
      loadOrder?.em_descarga
    ) {
      return { label: "Em descarga", color: "primary" };
    }
  }, [
    currentAddress?.desvio_rota,
    loadOrder?.em_coleta,
    loadOrder?.em_descarga,
    loadOrder?.em_trajeto,
  ]);

  return (
    <div>
      {(loadOrder?.em_coleta ||
        loadOrder?.em_descarga ||
        loadOrder?.em_trajeto) && (
        <TitleContainer statuscolor={title?.color}>
          <h1>{title?.label}</h1>
        </TitleContainer>
      )}
      <MapContainer
        center={defaultProps?.center}
        zoom={12}
        style={{ height: height, width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <Marker position={origin} icon={OriginIcon} />
        <Marker position={destiny} icon={DestinyIcon} />
        {(loadOrder?.em_coleta ||
          (!loadOrder?.em_descarga && !loadOrder?.em_trajeto)) &&
          currentAddress && (
            <Marker position={currentAddress} icon={RealTimeLocationIcon} />
          )}

        {segments && segments.length > 0 && (
          <Polyline positions={segments} pathOptions={colorOption} />
        )}

        {/* {(loadOrder?.em_descarga || loadOrder?.em_trajeto) &&
          currentAddress && ( */}
        <LeafletRoutingMachine
          origin={origin}
          destiny={destiny}
          currentAddress={currentAddress}
          RealTimeLocationIcon={RealTimeLocationIcon}
          show={show}
          segments={segments}
          routesSegments={routesSegments}
          isFetching={isFetching}
        />
        {/* )} */}
      </MapContainer>

      <div className="d-flex justify-content-between align-items-center">
        <div className="d-xl-flex gap-2">
          <div className="d-flex gap-2">
            <Subtitle label="Coleta" icon={originPinLocation} size="10px" />
            <Subtitle label="Destino" icon={locationPin} size="18px" />
            {currentAddress && (
              <Subtitle label="Localização atual" icon={newIcon} size="17px" />
            )}
          </div>
          {showSubtitle && (
            <div className="d-flex align-items-center gap-1">
              {(loadOrder?.em_descarga || loadOrder?.em_trajeto) && (
                <Subtitle
                  label="Rota percorrida"
                  reactIcon={<MdHorizontalRule color="#55caf4" size="2rem" />}
                  size="10px"
                />
              )}
              <Subtitle
                label="Rota selecionada"
                reactIcon={<MdHorizontalRule color="#2185D0" size="2rem" />}
                size="10px"
              />
            </div>
          )}
        </div>
        {showMore && (
          <div className="d-flex p-1">
            <ButtonLink
              label="Expandir mapa"
              fontSize="0.7rem"
              buttoncolor="primary"
              link={`/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/map/${loadOrder?.token}`}
              icon={<HiOutlineArrowsExpand size={14} />}
              target="_blank"
            />
          </div>
        )}
      </div>
    </div>
  );
};
