import { Card } from "react-bootstrap";
import styled from "styled-components";

export const BorderCard = styled(Card)`
  min-height: 100%;

  h1 {
    font-size: 0.75rem;
    color: var(--color-gray-11);
    margin-bottom: 10px;
    font-weight: bold;
  }

  h3 {
    font-size: 1rem;
  }

  h4, h5 {
    font-size: 0.7rem;
    color: var(--color-gray-11);
  }
`;

export const CardBody = styled(Card.Body)`
  height: 100%;
`;

export const CoverImage = styled.div`
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 500px;

  background-image: ${(props) =>
    props.currentDriverImage
      ? `url(${props.currentDriverImage})`
      : `url(${props.driverImage})`};
`;

export const StatusItem = styled.span`
font-size: 0.75rem;
font-weight: ${props => props.bold ? "bold" : "normal"};

color: ${props => (props?.status === 1 || props?.status === "10r")
    ? "#ff0f0f"
    : props?.status === 2
      ? "#7e0000"
      : "#4d4d4d"}
`;

export const StatusContent = styled.span`
  font-size: 0.75rem;
`

export const ApprovalAnalysisLabel = styled.span`
font-size: 0.75rem;
`
export const ApprovalAnalysisTitle = styled.span`
font-size: 0.875rem;
`