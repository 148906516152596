import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { PageTitle } from "../styles";
import ButtonLink from "../../../../../../../Shared/Components/ButtonLink/ButtonLink";
import { SubmitButton } from "../../../../../../../Shared/Components/SubmitButton/SubmitButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { onlyNumbers } from "../../../../../../../Shared/Utils/data";
import ReactInputMask from "react-input-mask";
import { Border } from "./styles";
import {
  categoriesOptions,
  gendersOptions,
  nationalitiesOptions,
} from "../../../../../../../Shared/Utils/constants";
import moment from "moment";
import ArrayEstadosCidades from "../../../../../../../Shared/Utils/EstadosCidades/estados-cidades.json";
import { allNationalities } from "../../../../../../../Shared/Utils/nationalities";
import { AddressApi } from "../../../../../../../Shared/Utils/Request";
import { Loading } from "../../../../../../../Shared/Components/Loading/Loading";
import { useDrivers } from "../../../../../../../hooks/DriverProvider";

export const DriverEditTab = ({
  tempRegister,
  permanetDriver,
  handleUpdateDriver,
  setIsEditingDriver,
}) => {
  const [cidadesDoEstado, setCidadesDoEstado] = useState([]);
  const [address, setAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const driver = useMemo(() => {
    return JSON.parse(tempRegister.dados_formulario_motorista);
  }, [tempRegister.dados_formulario_motorista]);

  const { updateTemporaryDriver, isSubmitting } = useDrivers();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const getAddress = useCallback(async (input) => {
    setIsLoading(true);
    try {
      const response = await AddressApi.get(`/${input}/json`);
      setIsLoading(false);
      setAddress(response?.data);
      return response?.data;
    } catch {
      setAddress(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleChangeCepInput = useCallback(
    async (event) => {
      const cep = onlyNumbers(event.target.value);

      if (cep.length > 7) {
        getAddress(cep);
      }
    },
    [getAddress]
  );

  const obterCidadesPorSigla = (sigla) => {
    const estado = ArrayEstadosCidades.estados.find(
      (estado) => estado.sigla === sigla
    );

    if (estado) {
      return estado?.cidades;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (driver) {
      const cidades = obterCidadesPorSigla(driver?.uf);
      setCidadesDoEstado(cidades);
      getAddress(driver?.cep);
    }
  }, [driver, getAddress]);

  useEffect(() => {
    if (address?.localidade) {
      const cidades = obterCidadesPorSigla(address?.uf);
      setCidadesDoEstado(cidades);
      setValue("cidade", address?.localidade);
    } else {
      setCidadesDoEstado([]);
    }
  }, [address?.localidade, address?.uf, setValue]);

  const driverAnexo = useMemo(
    () =>
      tempRegister &&
      tempRegister?.anexos_motorista &&
      JSON.parse(tempRegister?.anexos_motorista),
    [tempRegister]
  );

  const onSubmit = (data) => {
    const updatedData = { ...driver, ...data };
    const anexoDriver = { ...driverAnexo, ...data };
    const updateInformationsDriver = { ...permanetDriver, ...data };

    for (const key in driver) {
      updatedData[key] =
        data[key] === undefined || data[key] === null || data[key] === ""
          ? driver[key]
          : data[key];
    }

    for (const key in driverAnexo) {
      anexoDriver[key] =
        data[key] === undefined || data[key] === null || data[key] === ""
          ? driverAnexo[key]
          : data[key];
    }

    for (const key in permanetDriver) {
      if (data[key] === undefined && data[key] === null && data[key] === "") {
        delete data[key];
      } else {
        updateInformationsDriver[key] = data[key];
      }
    }

    updatedData.cpf = tempRegister?.cpf;
    anexoDriver.cpf = tempRegister?.cpf;
    updateInformationsDriver.cpf = tempRegister?.cpf;

    updatedData.foto =
      tempRegister?.anexos_motorista &&
      JSON.parse(tempRegister?.anexos_motorista).foto_cnh;

    anexoDriver.foto =
      tempRegister?.anexos_motorista &&
      JSON.parse(tempRegister?.anexos_motorista).foto_cnh;

    updateTemporaryDriver(
      tempRegister?.id_motorista,
      tempRegister?.token,
      updatedData,
      anexoDriver,
      updateInformationsDriver,
      setIsEditingDriver
    );
  };

  return (
    <Card.Body>
      <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="mb-3">
          <PageTitle className="mb-4">Informações pessoais</PageTitle>

          <Form.Group controlId="formNome" className="mb-2">
            <FloatingLabel controlId="formNome" label="Nome completo">
              <Form.Control
                {...register("nome", {
                  required: driver ? false : "O nome é obrigatório",
                  validate: (value) => {
                    if (!/^[a-zA-Z ]+$/.test(value)) {
                      return "Nome inválido. Use apenas letras sem acento.";
                    }
                    return true;
                  },
                })}
                size="sm"
                type="text"
                placeholder="Digite o nome completo"
                className="is-required"
                defaultValue={driver?.nome}
              />
              {errors?.nome && (
                <Form.Text className="text-danger">
                  {errors?.nome?.message}
                </Form.Text>
              )}
            </FloatingLabel>
          </Form.Group>

          <Row className="g-2">
            <Form.Group as={Col} md={6} controlId="change-driver-cpf-input">
              <FloatingLabel controlId="change-driver-cpf-input" label="CPF">
                <Form.Control
                  disabled
                  size="sm"
                  as={ReactInputMask}
                  mask={null}
                  maskChar={null}
                  type="text"
                  defaultValue={driver?.cpf}
                />
                {errors?.cpf && (
                  <Form.Text className="text-danger">
                    {errors.cpf.message}
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md={6} controlId="formTelefone">
              <FloatingLabel controlId="formTelefone" label="Celular">
                <Form.Control
                  {...register("numero_celular", {
                    required: "O número de celular é obrigatório",
                    validate: (value) => {
                      value = value.replace(/\D/g, "");
                      if (value.length !== 11) {
                        return "Número de celular inválido";
                      }
                      return true;
                    },
                  })}
                  size="sm"
                  type="text"
                  as={ReactInputMask}
                  mask="(99)99999-9999"
                  className="is-required"
                  placeholder="Digite o celular"
                  defaultValue={driver?.numero_celular}
                />
                {errors?.numero_celular && (
                  <Form.Text className="text-danger">
                    {errors?.numero_celular?.message}
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>
          </Row>
        </div>

        <div className="mb-3">
          <PageTitle className="mb-1">Informações da CNH</PageTitle>
          <Border>
            <Row className="mt-2 g-2">
              <Form.Group as={Col} xs={6} controlId="formCNH">
                <FloatingLabel controlId="formCNH" label="CNH">
                  <Form.Control
                    {...register("cnh", {
                      required: "A CNH é obrigatória",
                      validate: (value) => {
                        value = value.replace(/\D/g, "");
                        if (value.length !== 11) {
                          return "Número de CNH inválido";
                        }
                        return true;
                      },
                    })}
                    as={ReactInputMask}
                    mask="99999999999"
                    maskChar={null}
                    size="sm"
                    type="text"
                    placeholder="Escreva a CNH"
                    className="is-required"
                    defaultValue={driver?.cnh}
                  />
                  {errors?.cnh && (
                    <Form.Text className="text-danger">
                      {errors?.cnh?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} xs={6} controlId="formRegistroCNH">
                <FloatingLabel
                  controlId="formRegistroCNH"
                  label="Número de registro da CNH"
                >
                  <Form.Control
                    {...register("numero_registro_cnh", {
                      required: "O número de registro é obrigatório",
                    })}
                    as={ReactInputMask}
                    mask="99999999999"
                    maskChar={null}
                    size="sm"
                    type="text"
                    placeholder="Escreva o número de registro da CNH"
                    className="is-required"
                    defaultValue={driver?.numero_registro_cnh}
                  />
                  {errors?.numero_registro_cnh && (
                    <Form.Text className="text-danger">
                      {errors?.numero_registro_cnh?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group
                as={Col}
                xs={12}
                md={6}
                controlId="formLicenceCategory"
              >
                <FloatingLabel
                  controlId="formLicenceCategory"
                  label="Categoria CNH"
                >
                  <Form.Select
                    {...register("categoria_cnh_mot", {
                      required: "A categoria é obrigatória",
                      validate: (value) =>
                        value !== "Selecione a categoria" ||
                        "Selecione uma categoria",
                    })}
                    size="sm"
                    type="text"
                    className="is-required"
                    defaultValue={driver?.categoria_cnh_mot}
                  >
                    <option>Selecione a categoria</option>
                    {categoriesOptions.map((category) => (
                      <option value={category?.label} key={category?.id}>
                        {category?.label}
                      </option>
                    ))}
                  </Form.Select>
                  {errors?.categoria_cnh_mot && (
                    <Form.Text className="text-danger">
                      {errors?.categoria_cnh_mot?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="formValidateCNH">
                <FloatingLabel controlId="formValidateCNH" label="Validade CNH">
                  <Form.Control
                    {...register("dt_validade_cnh", {
                      required: "A data de validade é obrigatória",
                    })}
                    size="sm"
                    type="date"
                    className="is-required"
                    defaultValue={
                      driver?.dt_validade_cnh &&
                      moment.utc(driver?.dt_validade_cnh).format("YYYY-MM-DD")
                    }
                  />
                  {errors?.dt_validade_cnh && (
                    <Form.Text className="text-danger">
                      {errors?.dt_validade_cnh?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="formEmissaoCNH">
                <FloatingLabel controlId="formEmissaoCNH" label="Emissão CNH">
                  <Form.Control
                    {...register("dt_emissao_cnh", {
                      required: "A data de emissão é obrigatória",
                    })}
                    size="sm"
                    type="date"
                    className="is-required"
                    defaultValue={
                      driver?.dt_emissao_cnh &&
                      moment.utc(driver?.dt_emissao_cnh).format("YYYY-MM-DD")
                    }
                  />
                  {errors?.dt_emissao_cnh && (
                    <Form.Text className="text-danger">
                      {errors?.dt_emissao_cnh?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="formFirstCNH">
                <FloatingLabel controlId="formFirstCNH" label="Primeira CNH">
                  <Form.Control
                    {...register("dt_primeira_cnh", {
                      required: "A data da primeira CNH é obrigatória",
                    })}
                    size="sm"
                    type="date"
                    className="is-required"
                    defaultValue={
                      driver?.dt_primeira_cnh &&
                      moment.utc(driver?.dt_primeira_cnh).format("YYYY-MM-DD")
                    }
                  />
                  {errors?.dt_primeira_cnh && (
                    <Form.Text className="text-danger">
                      {errors?.dt_primeira_cnh?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>
            </Row>
          </Border>
        </div>
        <div className="mb-3">
          <PageTitle className="mb-1">Informações complementares</PageTitle>
          <Border>
            <Row className="mt-2 g-2">
              <Form.Group as={Col} md={6} controlId="formRG">
                <FloatingLabel controlId="formRG" label="RG">
                  <Form.Control
                    {...register("rg_motorista", {
                      required: "O RG é obrigatório",
                      validate: (value) => {
                        value = value.replace(/\D/g, "");
                        if (!/^\d+$/.test(value)) {
                          return "RG inválido. Use apenas números.";
                        }
                        return true;
                      },
                    })}
                    size="sm"
                    type="text"
                    as={ReactInputMask}
                    mask="999999999999999"
                    maskChar={null}
                    className="is-required"
                    placeholder="Digite o RG"
                    defaultValue={driver?.rg_motorista}
                  />
                  {errors?.rg_motorista && (
                    <Form.Text className="text-danger">
                      {errors?.rg_motorista?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={6} controlId="formOrgaoRG">
                <FloatingLabel controlId="formOrgaoRG" label="Órgão expedidor">
                  <Form.Control
                    {...register("orgao_rg", {
                      required: "O órgão de emissão do RG é obrigatório",
                    })}
                    size="sm"
                    type="text"
                    className="is-required"
                    placeholder="Digite o órgão expedidor do RG"
                    defaultValue={driver?.orgao_rg}
                  />
                  {errors?.orgao_rg && (
                    <Form.Text className="text-danger">
                      {errors?.orgao_rg?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={6} controlId="formDtEmissaoRG">
                <FloatingLabel
                  controlId="formDtEmissaoRG"
                  label="Data de emissão do RG"
                >
                  <Form.Control
                    {...register("dt_emissao_rg", {
                      required: "A data de emissão do RG é obrigatória",
                    })}
                    aria-label="RG Emission date"
                    size="sm"
                    type="date"
                    className="is-required"
                    defaultValue={
                      driver?.dt_emissao_rg &&
                      moment.utc(driver?.dt_emissao_rg).format("YYYY-MM-DD")
                    }
                  />
                  {errors?.dt_emissao_rg && (
                    <Form.Text className="text-danger">
                      {errors?.dt_emissao_rg?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={6} controlId="formGender">
                <FloatingLabel controlId="formGender" label="Gênero">
                  <Form.Select
                    {...register("sexo", {
                      required: "O gênero é obrigatório",
                    })}
                    size="sm"
                    type="text"
                    className="is-required"
                    defaultValue={driver?.sexo}
                  >
                    <option value="">Selecione o gênero</option>
                    {gendersOptions.map((gender) => (
                      <option value={gender.value} key={gender.id}>
                        {gender.label}
                      </option>
                    ))}
                  </Form.Select>

                  {errors?.sexo && (
                    <Form.Text className="text-danger">
                      {errors?.sexo?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={6} controlId="formNascimento">
                <FloatingLabel
                  controlId="formNascimento"
                  label="Data de nascimento"
                >
                  <Form.Control
                    {...register("dt_nascimento", {
                      required: "A data de nascimento é obrigatória",
                    })}
                    size="sm"
                    type="date"
                    className="is-required"
                    defaultValue={
                      driver?.dt_nascimento &&
                      moment(driver.dt_nascimento).format("YYYY-MM-DD")
                    }
                  />
                  {errors?.dt_nascimento && (
                    <Form.Text className="text-danger">
                      {errors?.dt_nascimento?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={12} controlId="formNomePai">
                <FloatingLabel controlId="formNome" label="Nome do pai">
                  <Form.Control
                    {...register("nome_pai", {
                      validate: (value) => {
                        if (value && !/^[a-zA-Z ]+$/.test(value)) {
                          return "Nome inválido. Use apenas letras sem acento.";
                        }
                        return true;
                      },
                    })}
                    size="sm"
                    type="text"
                    placeholder="Digite o nome do pai"
                    defaultValue={driver?.nome_pai}
                  />
                  {errors?.nome_pai && (
                    <Form.Text className="text-danger">
                      {errors?.nome_pai?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={12} controlId="formNomeMae">
                <FloatingLabel controlId="formNomeMae" label="Nome da mãe">
                  <Form.Control
                    {...register("nome_mae", {
                      required: "O nome da mãe é obrigatório",
                      validate: (value) => {
                        if (value && !/^[a-zA-Z ]+$/.test(value)) {
                          return "Nome inválido. Use apenas letras sem acento.";
                        }
                        return true;
                      },
                    })}
                    size="sm"
                    type="text"
                    className="is-required"
                    placeholder="Digite o nome da mãe"
                    defaultValue={driver?.nome_mae}
                  />
                  {errors?.nome_mae && (
                    <Form.Text className="text-danger">
                      {errors?.nome_mae?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={6} controlId="formNacionalidade">
                <FloatingLabel
                  controlId="formNacionalidade"
                  label="Nacionalidade"
                >
                  <Form.Select
                    {...register("nacionalidade", {
                      required: "A nacionalidade é obrigatória",
                    })}
                    size="sm"
                    type="text"
                    className="is-required"
                    defaultValue={driver?.nacionalidade}
                  >
                    <option value="" disabled>
                      Selecione a nacionalidade
                    </option>
                    {nationalitiesOptions.map((nacionality) => (
                      <option value={nacionality.value} key={nacionality.id}>
                        {nacionality.label}
                      </option>
                    ))}
                  </Form.Select>
                  {errors?.nacionalidade && (
                    <Form.Text className="text-danger">
                      {errors?.nacionalidade?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={6} controlId="formCountry">
                <FloatingLabel controlId="formCountry" label="País">
                  <Form.Select
                    {...register("cod_nacionalidade")}
                    size="sm"
                    type="text"
                    defaultValue={driver?.cod_nacionalidade}
                  >
                    <option value="" disabled>
                      Selecione o país
                    </option>
                    {allNationalities.map((nacionality) => (
                      <option value={nacionality.value} key={nacionality.value}>
                        {nacionality.label}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={4} controlId="formCep">
                <FloatingLabel controlId="formCep" label="Cep">
                  <Form.Control
                    {...register("cep", {
                      required: "O cep é obrigatório",
                      validate: (value) => {
                        value = value.replace(/\D/g, "");
                        if (value.length !== 8) {
                          return "CEP inválido";
                        }
                        return true;
                      },
                    })}
                    data-testid="driver-cep-input"
                    size="sm"
                    type="text"
                    placeholder="Digite o cep"
                    as={ReactInputMask}
                    mask="99.999-999"
                    className="is-required"
                    defaultValue={driver?.cep}
                    onChange={handleChangeCepInput}
                  />
                  {errors?.cep && (
                    <Form.Text className="text-danger">
                      {errors?.cep?.message}
                    </Form.Text>
                  )}
                </FloatingLabel>
              </Form.Group>
              {isLoading && <Loading />}
              {!isLoading && (
                <>
                  <Form.Group as={Col} md={4} controlId="formEstado">
                    <FloatingLabel controlId="formEstado" label="Estado">
                      <Form.Select
                        aria-label="State"
                        {...register("uf")}
                        size="sm"
                        disabled
                        type="text"
                        defaultValue={driver?.uf}
                      >
                        <option value="" disabled>
                          -- Selecione um Estado --
                        </option>
                        {ArrayEstadosCidades.estados
                          .map((estado) => estado.sigla)
                          .map((sigla) => (
                            <option key={sigla} value={sigla}>
                              {sigla}
                            </option>
                          ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} md={4} controlId="formCidade">
                    <FloatingLabel controlId="formCidade" label="Cidade">
                      <Form.Select
                        aria-label="City"
                        {...register("cidade")}
                        size="sm"
                        disabled
                        className="is-required"
                        type="text"
                        placeholder="Digite a cidade"
                        value={address?.localidade}
                      >
                        <option value="">-- Selecione uma cidade --</option>
                        {cidadesDoEstado.map((cidade) => {
                          return (
                            <option key={cidade} value={cidade}>
                              {cidade}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} md={12} controlId="formEndereco">
                    <FloatingLabel controlId="formEndereco" label="Endereço">
                      <Form.Control
                        {...register("endereco")}
                        data-testid="driver-address-input"
                        size="sm"
                        type="text"
                        disabled
                        placeholder="Digite o endereço"
                        defaultValue={driver?.endereco}
                      />
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} md={8} controlId="formBairro">
                    <FloatingLabel controlId="formBairro" label="Bairro">
                      <Form.Control
                        {...register("bairro")}
                        size="sm"
                        type="text"
                        disabled
                        placeholder="Digite o bairro"
                        className="is-required"
                        defaultValue={driver?.bairro}
                      />
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} md={4} controlId="formNumber">
                    <FloatingLabel controlId="formNumber" label="Número">
                      <Form.Control
                        {...register("numero", {
                          required: "O número é obrigatório",
                        })}
                        size="sm"
                        data-testid="driver-number-input"
                        type="text"
                        as={ReactInputMask}
                        mask="9999999"
                        maskChar={null}
                        className="is-required"
                        placeholder="Digite o número"
                        defaultValue={driver?.numero}
                      />
                      {errors?.numero && (
                        <Form.Text className="text-danger">
                          {errors?.numero?.message}
                        </Form.Text>
                      )}
                    </FloatingLabel>
                  </Form.Group>
                </>
              )}
            </Row>
          </Border>
        </div>

        <div className="d-flex justify-content-end gap-2">
          <SubmitButton
            labelButton="Salvar alterações"
            isSubmitting={isSubmitting}
          />

          <ButtonLink
            label="Cancelar"
            fontSize="0.7rem"
            buttoncolor="gray"
            handleClick={handleUpdateDriver}
          />
        </div>
      </Form>
    </Card.Body>
  );
};
