import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import InputMask from "react-input-mask";

import ArrayEstadosCidades from "../../../../Shared/Utils/EstadosCidades/estados-cidades.json";
import { SubmitButton } from "../../../../Shared/Components/SubmitButton/SubmitButton";
import { useAddresses } from "../../../../hooks/AddressProvider";
import { onlyNumbers } from "../../../../Shared/Utils/data";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";

export default function FormEditUnit({
  unit,
  handleSubmit,
  register,
  fields,
  errors,
  isSubmitting,
  onSubmit,
  companies,
  setValue,
}) {
  const [cidadesDoEstado, setCidadesDoEstado] = useState([]);
  const [hasChange, setHasChange] = useState(false);

  const [currentCep, setCurrentCep] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState(unit?.cidade);

  const { addresses, isLoading, getAddresses } = useAddresses();

  useEffect(() => {
    if (currentCep) {
      getAddresses(currentCep);
      setSelectedState();
    }
  }, [getAddresses, currentCep]);

  const handleFormSubmit = (data) => {
    onSubmit(unit.id, data);
  };

  const obterCidadesPorSigla = (sigla) => {
    const estado = ArrayEstadosCidades.estados.find(
      (estado) => estado.sigla === sigla
    );

    if (estado) {
      return estado.cidades;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (currentCep && addresses?.uf) {
      const cidades = obterCidadesPorSigla(addresses?.uf);
      setCidadesDoEstado(cidades);
    } else {
      setCidadesDoEstado([]);
    }

    if (selectedState) {
      const cidades = obterCidadesPorSigla(selectedState);
      setCidadesDoEstado(cidades);
    }
  }, [addresses?.uf, currentCep, selectedState]);

  const handleChangeCepInput = useCallback(async (event) => {
    setHasChange(false);
    const cep = onlyNumbers(event.target.value);

    if (cep.length > 7) {
      setCurrentCep(cep);
      setHasChange(true);
    }
  }, []);

  useEffect(() => {
    if (hasChange === true) {
      setValue("cidade", addresses?.localidade);
      setValue("estado", addresses?.uf);
      setValue("bairro", addresses?.bairro);
      setValue("endereco", addresses?.logradouro);
    }
  }, [
    hasChange,
    addresses?.bairro,
    addresses?.localidade,
    addresses?.logradouro,
    addresses?.uf,
    setValue,
  ]);

  return (
    <Form onSubmit={handleSubmit(handleFormSubmit)}>
      <Row>
        <Form.Group as={Col} md={2} className="mb-4" controlId="formCode">
          <FloatingLabel controlId="formCode" label="Código">
            <Form.Control
              type="text"
              placeholder="#"
              disabled
              defaultValue={unit?.id}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={10} className="mb-3" controlId="formNome">
          <FloatingLabel controlId="formNome" label="Nome">
            <Form.Control
              {...register("nome", fields.nome)}
              type="text"
              className="is-required"
              placeholder="Digite o nome"
              defaultValue={unit?.nome}
            />
            {errors?.nome?.type === "required" && (
              <Form.Text className="text-danger">
                O nome é obrigatório.
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={9} className="mb-3" controlId="formIdEmpresa">
          <FloatingLabel controlId="formIdEmpresa" label="Empresa">
            <Form.Select
              {...register("id_empresa", fields.id_empresa)}
              type="number"
              inputMode="numeric"
              className="is-required"
              placeholder="Digite o id da empresa"
              defaultValue={unit?.id_empresa}
            >
              <option value={null}>Empresa</option>
              {companies.map((company) => (
                <option key={company?.id} value={company?.id}>
                  {company?.nome_fantasia}
                </option>
              ))}
            </Form.Select>
            {errors?.id_empresa?.type === "pattern" && (
              <Form.Text className="text-danger">
                A empresa é obrigatória
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group
          as={Col}
          md={3}
          className="mb-4 d-flex align-items-center gap-2"
          controlId="formAtivo"
        >
          <Form.Label className="mb-0">Inativo / Ativo</Form.Label>
          <Form.Check
            type="switch"
            id="custom-switch"
            defaultChecked={unit?.ativo}
            {...register("ativo")}
          />
        </Form.Group>

        <Form.Group as={Col} md={4} className="mb-3" controlId="formCep">
          <FloatingLabel controlId="formCep" label="Cep">
            <Form.Control
              {...register("cep", {
                required: "O cep é obrigatório",
                validate: (value) => {
                  value = value.replace(/\D/g, "");
                  if (value.length !== 8) {
                    return "CEP inválido";
                  }
                  return true;
                },
              })}
              type="text"
              placeholder="Digite o cep"
              as={InputMask}
              mask="99999-999"
              className="is-required"
              defaultValue={unit?.cep}
              onChange={handleChangeCepInput}
            />
            {errors?.cep && (
              <Form.Text className="text-danger">
                {errors?.cep?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        {isLoading && <Loading />}

        {!isLoading && (
          <>
            <Form.Group as={Col} md={4} className="mb-4" controlId="formEstado">
              <FloatingLabel controlId="formEstado" label="Estado">
                <Form.Select
                  aria-label="Default select example"
                  {...register("estado")}
                  type="text"
                  defaultValue={unit?.estado}
                  disabled
                  onChange={(event) => {
                    setSelectedState(event.target.value);
                    setValue("cidade", "");
                  }}
                >
                  <option value="" disabled>
                    -- Selecione um Estado --
                  </option>
                  {ArrayEstadosCidades.estados
                    .map((estado) => estado.sigla)
                    .map((sigla) => (
                      <option key={sigla} value={sigla}>
                        {sigla}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4} className="mb-4" controlId="formCidade">
              <FloatingLabel controlId="formCidade" label="Cidade">
                <Form.Select
                  aria-label="Default select example"
                  {...register("cidade")}
                  type="text"
                  disabled
                  placeholder="Digite a cidade"
                  value={
                    selectedState
                      ? selectedCity
                      : addresses
                      ? addresses?.localidade
                      : unit?.localidade
                  }
                  onChange={(event) => {
                    setSelectedCity(event.target.value);
                    setValue("cidade", event.target.value);
                  }}
                >
                  <option>{unit?.cidade}</option>
                  {cidadesDoEstado.map((cidade) => (
                    <option
                      key={cidade}
                      value={cidade}
                      defaultValue={unit.cidade}
                    >
                      {cidade}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlId="formBairro">
              <FloatingLabel controlId="formBairro" label="Bairro">
                <Form.Control
                  {...register("bairro")}
                  type="text"
                  disabled
                  placeholder="Digite o bairro"
                  defaultValue={unit?.bairro}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group
              as={Col}
              md={8}
              className="mb-3"
              controlId="formEndereco"
            >
              <FloatingLabel controlId="formEndereco" label="Endereço">
                <Form.Control
                  {...register("endereco")}
                  type="text"
                  disabled
                  placeholder="Digite o endereço"
                  defaultValue={unit?.endereco}
                />
              </FloatingLabel>
            </Form.Group>
          </>
        )}

        <Form.Group className="d-flex gap-2">
          <SubmitButton
            labelButton="Editar unidade"
            isSubmitting={isSubmitting}
          />
          <ButtonLink label="Cancelar" link="/unidades" fontSize="0.7rem" />
        </Form.Group>
      </Row>
    </Form>
  );
}
