import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

const ValidationIcon = ({ biometricItem, cnhItem }) => {
  return (
    <>
      {!!biometricItem && (
        <>
          {biometricItem && biometricItem > 0.7 ? (
            <AiOutlineCheckCircle size={20} color="#6dd786" />
          ) : (
            <AiOutlineCloseCircle size={20} color="#ff0f0f" />
          )}
        </>
      )}
      {!biometricItem && (
        <>
          {cnhItem && cnhItem > 0.7 ? (
            <AiOutlineCheckCircle size={20} color="#6dd786" />
          ) : (
            <AiOutlineCloseCircle size={20} color="#ff0f0f" />
          )}
        </>
      )}
    </>
  );
};

export default ValidationIcon;
