import { useMemo, useState } from "react";

import { Border } from "../styles";
import ReportedInconsistencies from "../../../DriverInformations/ReportedInconsistencies";

import { AboutInformationSection } from "./AboutInformationSection";
import PersonalInformationSection from "./PersonalInformationSection";
import CNHValidationSection from "./CNHValidationSection";
import ImageBase64Modal from "../../../../../../../Shared/Components/ImageModal/ImageBase64Modal";
import { DriverFederalDetails } from "../../../../../../../Shared/Components/DriverFederalDetails/DriverFederalDetails";

import { TemporaryAnaliticMessage } from "../../../../../../../Shared/Components/TemporaryAnaliticMessage/TemporaryAnaliticMessage";

import ButtonLink from "../../../../../../../Shared/Components/ButtonLink/ButtonLink";
import { InfoTitle, SectionContainer, TitleContainer } from "./styles";

const DriverInformationTab = ({
  driver,
  isLoading,
  tempRegister,
  driverConsults,
  isloadingConsult,
  handleUpdateDriver,
  judicialProcess,
}) => {
  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowModal = () => {
    setShowImageModal(true);
  };

  const tempDriver = useMemo(
    () =>
      tempRegister &&
      tempRegister?.dados_formulario_motorista &&
      JSON.parse(tempRegister?.dados_formulario_motorista),
    [tempRegister]
  );

  const driverDocImage = useMemo(
    () =>
      tempRegister &&
      tempRegister?.anexos_motorista &&
      JSON.parse(tempRegister?.anexos_motorista),
    [tempRegister]
  );

  return (
    <div>
      <div className="p-2">
        {!(
          (driver?.status_cadastro === "5r" &&
            driver?.processos_cadastro_concluido === false) ||
          driver?.status_cadastro === "2r" ||
          isloadingConsult
        ) && (
          <div className="d-flex justify-content-end mb-2">
            <ButtonLink
              label="Editar cadastro"
              fontSize="0.7rem"
              buttoncolor="primary"
              handleClick={handleUpdateDriver}
            />
          </div>
        )}
        <SectionContainer className="text-start p-0 mb-4">
          <TemporaryAnaliticMessage
            driver={driver}
            judicialProcess={judicialProcess}
            driverConsults={driverConsults}
          />
          <TitleContainer className="mb-3 d-flex">
            <InfoTitle className="text-start">INFORMAÇÕES PESSOAIS</InfoTitle>
          </TitleContainer>

          <AboutInformationSection driver={tempDriver} />
        </SectionContainer>
        <SectionContainer className="text-start p-0 mb-3">
          <TitleContainer className="mb-3 d-flex">
            <InfoTitle className="text-start">INFORMAÇÕES DA CNH</InfoTitle>
          </TitleContainer>
          <CNHValidationSection
            driver={tempDriver}
            driverDocImage={driverDocImage?.foto_cnh}
            handleShowModal={handleShowModal}
          />
        </SectionContainer>
        <SectionContainer className="text-start p-0 mb-5">
          <TitleContainer className="mb-3 d-flex">
            <InfoTitle className="text-start">
              INFORMAÇÕES COMPLEMENTARES
            </InfoTitle>
          </TitleContainer>
          <PersonalInformationSection driver={tempDriver} />
        </SectionContainer>
        {driver?.campos_erros_cadastro &&
          driver?.status_cadastro === "7r" &&
          driver?.campos_erros_cadastro !== "{}" && (
            <SectionContainer className="text-start p-0 mb-5">
              <TitleContainer className="mb-3 d-flex">
                <InfoTitle className="text-start">
                  INCONSISTÊNCIAS INFORMADAS
                </InfoTitle>
              </TitleContainer>
              <Border>
                <ReportedInconsistencies driver={driver} />
              </Border>
            </SectionContainer>
          )}
        {!isLoading &&
          !isloadingConsult &&
          driverConsults &&
          driver?.status_cadastro === "5r" &&
          driver?.processos_cadastro_concluido && (
            <SectionContainer className="text-start p-0 mb-5">
              <TitleContainer className="mb-3 d-flex">
                <InfoTitle className="text-start">CONSULTAS</InfoTitle>
              </TitleContainer>

              <DriverFederalDetails consult={driverConsults} />
            </SectionContainer>
          )}
      </div>

      <ImageBase64Modal
        show={showImageModal}
        handleClose={() => setShowImageModal(false)}
        image={driverDocImage?.foto_cnh}
        title="CNH"
      />
    </div>
  );
};

export default DriverInformationTab;
