import styled from "styled-components";
import { FaRegFolderOpen, FaClipboard } from "react-icons/fa";
import { Button, Form } from "react-bootstrap";

export const Container = styled.div`
  padding: 1rem;
`

export const Item = styled.div`
  padding: 5px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ItemTitle = styled.h3`
   font-size: 1rem !important;
    color: #093c5e !important;
    white-space: nowrap;
    margin-bottom: 0;
`

export const ItemSubtitle = styled.h4`
   font-size: 1rem !important;
    color: var(--color-black) !important;
    display: flex;
    margin-left: 0.2rem;
    margin-bottom: 0;
    text-align: left;
`

export const FolderIcon = styled(FaRegFolderOpen)`
 color: var(--color-gray-11);
`
export const ClipboardIcon = styled(FaClipboard)`
 color: var(--color-gray-11);
`
export const SubtitleIcon = styled.span`
  font-size: 1rem;
  color: red;
`

export const Title = styled.h1`
  font-size: 1rem;
   color: var(--color-gray-15);
  font-weight: bold;
  margin-left: 0.2rem;
  margin-bottom: 10px;
`

export const CoverImage = styled.div`
  width:  166px;
  height:  166px;
  padding-bottom: 10px;

  > img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ImageTitle = styled.p`
  font-size: 1rem;
  color: var(--color-gray-11);
`;

export const ShowModalButton = styled(Button)`
  border: none;
  background-color: transparent;
  color: var(--color-gray-15);
  font-size: 0.8rem;
  transition: transform 300ms;

  &:hover {
    border: none;
    background-color: transparent;
    color: var(--color-gray-15);
    transform: scale(1.05);
    z-index: 1;
  }

  &:active,
  &:focus {
    border: none;
    background-color: transparent !important;
    color: var(--color-gray-15);
  }
`;

export const H4Status = styled.h4`
 margin-bottom: 0;
 font-size: 1rem !important;
  color: ${({ status }) => (status === "REGULAR" || status === "Regular" || status === "Ativa" || status === "ATIVA") ? "#07b141" : "red"} !important;
`

export const BorderForm = styled(Form.Group)`
  border: 0.875px solid var(--color-gray-10);
  border-radius: 6px;
  padding: 10px;
`