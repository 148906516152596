import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest from "../Shared/Utils/Request";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export const VehicleContext = createContext({
  vehicles: [],
  vehicle: null,
  truck: null,
  totalPages: null,
  isLoading: false,
  informationsVehicle: null,
  isLoadingTruck: false,
  getVehicles: () => {},
  getVehicle: () => null,
  getAllVehicles: () => {},
  addVehicle: () => null,
  deleteVehicle: () => null,
  updateVehicle: () => null,
  updateTruck: () => {},
  deleteTruck: () => {},
  addTruck: () => {},
  setVehicle: () => null,
  setInformationsVehicle: () => null,
  getInformationsVehicle: () => null,
});

export const useVehicles = () => {
  const context = useContext(VehicleContext);

  if (!context) {
    throw new Error("useVehicles must be within VehicleProvider");
  }

  return context;
};

export const VehicleProvider = ({ children }) => {
  const [vehicles, setVehicles] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [vehicle, setVehicle] = useState(null);
  const [truck, setTruck] = useState(null);
  const [isLoadingAllVehicles, setIsLoadingAllVehicles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTruck, setIsLoadingTruck] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [informationsVehicle, setInformationsVehicle] = useState(null);

  const getVehicles = useCallback(
    async (currentPage, searchVehicle, active, status) => {
      try {
        setIsLoading(true);

        const params =
          status && status === "9"
            ? {
                perPage: 9,
                page: currentPage,
                placa: searchVehicle,
                status: "9",
              }
            : active === true
            ? {
                perPage: 9,
                page: currentPage,
                ativo: active,
                placa: searchVehicle,
                status: "0",
              }
            : active === false
            ? {
                perPage: 9,
                page: currentPage,
                ativo: active,
                placa: searchVehicle,
                status: "0",
              }
            : {
                perPage: 9,
                page: currentPage,
                placa: searchVehicle,
              };

        const response = await ApiRequest({
          path: "veiculos",
          method: "GET",
          params,
        });

        setTotalPages(response?.data?.total_pages);
        setIsLoading(false);

        setVehicles(response?.data?.data);
      } catch (error) {
        setVehicles([]);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const getAllVehicles = useCallback(async () => {
    try {
      setIsLoadingAllVehicles(true);

      const params = {
        perPage: 10000000,
      };

      const response = await ApiRequest({
        path: "veiculos",
        method: "GET",
        params,
      });

      setAllVehicles(response?.data?.data);
    } catch (error) {
      setAllVehicles([]);
    } finally {
      setIsLoadingAllVehicles(false);
    }
  }, []);

  const getVehicle = useCallback(async (id) => {
    if (id === null) {
      return setVehicle(null);
    }

    try {
      setIsLoading(true);
      setIsLoadingTruck(false);

      const response = await ApiRequest({
        path: `veiculos/${id}`,
        method: "GET",
      });

      setVehicle(response?.data);
    } catch (error) {
      setVehicle(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getInformationsVehicle = useCallback(async (id) => {
    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: `consulta_antt_veiculo/veiculo/${id}`,
        method: "GET",
      });

      setInformationsVehicle(response?.data);
    } catch (error) {
      setInformationsVehicle([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const addVehicle = useCallback(async (data, navigate) => {
    try {
      setIsLoading(true);

      await ApiRequest({
        path: "veiculos",
        method: "POST",
        data,
      });

      window.scrollTo(0, 0);

      navigate("/veiculos-listar");

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Veículo cadastrado com sucesso.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    } catch (error) {
      window.scrollTo(0, 0);

      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: `${
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Veículo não pode ser criado"
        }`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteVehicle = useCallback(
    async (id) => {
      try {
        setIsLoading(true);

        await ApiRequest({
          path: `veiculos/${id}`,
          method: "DELETE",
        });

        window.scrollTo(0, 0);

        const response = await getVehicles();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Veículo excluído com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsLoading(false);

        return response?.data;
      } catch (error) {
        setIsLoading(false);

        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `${
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            "Esse veículo não pode ser excluído!"
          }`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [getVehicles]
  );

  const updateVehicle = useCallback(
    async (id, data) => {
      try {
        setIsLoading(true);

        await ApiRequest({
          path: `veiculos/${id}`,
          method: "PUT",
          data,
        });

        window.scrollTo(0, 0);

        const response = await getVehicles();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Veículo atualizado com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsLoadingTruck(false);

        setIsLoading(false);
        return response?.data;
      } catch (error) {
        window.scrollTo(0, 0);

        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: error?.response?.data?.error
            ? error?.response?.data?.error
            : "Veículo não pode ser atualizado",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [getVehicles]
  );

  const addTruck = useCallback(
    async (data, id) => {
      try {
        setIsLoadingTruck(true);

        const response = await ApiRequest({
          path: "carretas",
          method: "POST",
          data,
        });

        await getVehicle(id);

        window.scrollTo(0, 0);

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Carreta criada com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsLoadingTruck(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `${
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            "Erro ao criar a carreta!"
          }`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
        setIsLoadingTruck(false);
        window.scrollTo(0, 0);
      } finally {
        setIsLoadingTruck(false);
      }
    },
    [getVehicle]
  );

  const updateTruck = useCallback(
    async (data, id) => {
      try {
        setIsLoadingTruck(true);

        const response = await ApiRequest({
          path: `carretas/${data.id}`,
          method: "PUT",
          data,
        });

        window.scrollTo(0, 0);

        setTruck(response?.data);
        await getVehicle(id);

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Carreta editada com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsLoadingTruck(false);
      } catch (error) {
        setIsLoadingTruck(false);
        window.scrollTo(0, 0);

        setTruck(null);
      } finally {
        setIsLoadingTruck(false);
      }
    },
    [getVehicle]
  );

  const deleteTruck = useCallback(async (id, vehicleId) => {
    try {
      setIsLoadingTruck(true);

      await ApiRequest({
        path: `carretas/${id}`,
        method: "DELETE",
      });

      window.scrollTo(0, 0);

      // getVehicle(vehicleId);

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Carreta excluída com sucesso.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: `${
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          "Essa carreta não pode ser excluída!"
        }`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    } finally {
      setIsLoadingTruck(false);
    }
  }, []);

  const providerValue = useMemo(
    () => ({
      vehicles,
      allVehicles,
      vehicle,
      truck,
      isLoadingTruck,
      isLoading,
      isLoadingAllVehicles,
      totalPages,
      getVehicles,
      getAllVehicles,
      getVehicle,
      addVehicle,
      deleteVehicle,
      updateVehicle,
      updateTruck,
      deleteTruck,
      addTruck,
      setVehicle,
      setInformationsVehicle,
      informationsVehicle,
      getInformationsVehicle,
    }),
    [
      vehicles,
      allVehicles,
      vehicle,
      truck,
      isLoadingTruck,
      isLoading,
      isLoadingAllVehicles,
      totalPages,
      getVehicles,
      getAllVehicles,
      getVehicle,
      addVehicle,
      deleteVehicle,
      updateVehicle,
      updateTruck,
      deleteTruck,
      addTruck,
      setVehicle,
      setInformationsVehicle,
      informationsVehicle,
      getInformationsVehicle,
    ]
  );

  return (
    <VehicleContext.Provider value={providerValue}>
      {children}
    </VehicleContext.Provider>
  );
};
