import { Row } from "react-bootstrap";
import { BiBuildings } from "react-icons/bi";
import { BsBuilding, BsKey } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FiUsers } from "react-icons/fi";
import styled from "styled-components";

export const CompanyIcon = styled(BsBuilding)`
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 2rem;
`
export const UnitIcon = styled(BiBuildings)`
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 2rem;
`
export const PefilIcon = styled(CgProfile)`
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 2rem;
`
export const UserIcon = styled(FiUsers)`
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 2rem;
`
export const ConfigIcon = styled(BsKey)`
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 2rem;
`

export const SystemSettingsRow = styled(Row)`
max-height: 35vh;
flex: 1;
overflow-x: auto;

 @media(min-width: 375px){
  max-height: 40vh;
 }

 @media(min-width: 425px){
  max-height: 50vh;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }
 

 @media(min-width: 1440px){
  overflow-x: auto;
  max-height: 80vh;
  }

  @media(min-width: 1640px){
  overflow-x: auto;
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  overflow-x: auto;
  max-height: 80vh;
  }
`