import { Form } from "react-bootstrap";
import styled from "styled-components";

export const ScannerContainer = styled.div`
  width: 400px;
  height: 300px;
`

export const TextFormArea = styled(Form.Control)`
  height:150px;
  width: 100%;
  border-left: 2px solid red;
  border-radius: 4px;
`

export const ClearButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--color-blue-90);
  border-radius: 4px;
  color: var(--color-blue-90);
  font-weight: bold;
`