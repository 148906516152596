import React from "react";
import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { SubmitButton } from "../../../../Shared/Components/SubmitButton/SubmitButton";
import { formatString } from "../../../../Shared/Utils/data";
import { DescriptionFormControl, PerfilFormContainer } from "./styles";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";

export default function FormPerfil({
  handleSubmit,
  register,
  errors,
  isSubmitting,
  onSubmit,
  isEditing,
  scopes,
  perfil,
}) {
  const handleFormSubmit = (data) => {
    if (isEditing) {
      onSubmit(perfil.id, data);
    } else {
      onSubmit(data);
    }
  };

  return (
    <PerfilFormContainer className="mt-2">
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row>
          <Form.Group as={Col} md={6} className="mb-3" controlId="formNome">
            <FloatingLabel controlId="formNome" label="Nome">
              <Form.Control
                {...register("nome", { required: "O nome é obrigatório" })}
                type="text"
                className="is-required"
                placeholder="Digite o nome do perfil"
                defaultValue={perfil?.nome}
              />
              {errors?.nome && (
                <Form.Text className="text-danger">
                  {errors?.nome?.message}
                </Form.Text>
              )}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md={6} className="mb-3" controlId="formScope">
            <FloatingLabel controlId="formScope" label="Escopos">
              <Form.Select
                {...register("escopo", {
                  required: "O escopo é obrigatório",
                  validate: (value) =>
                    value !== "-- Selecione um escopo --" ||
                    "Por favor, selecione um escopo válido",
                })}
                type="number"
                className="is-required"
                defaultValue={perfil?.id_escopo}
              >
                <option>-- Selecione um escopo --</option>
                {scopes.length > 0 &&
                  scopes.map((scope) => (
                    <option key={scope?.id} value={scope?.id}>
                      {formatString(scope?.nome)}
                    </option>
                  ))}
              </Form.Select>
              {errors?.escopo && (
                <Form.Text className="text-danger">
                  {errors?.escopo?.message}
                </Form.Text>
              )}
            </FloatingLabel>
          </Form.Group>

          <Form.Group
            as={Col}
            sm={12}
            className="mb-3 w-100"
            controlId="formDescricao"
          >
            <FloatingLabel controlId="formDescricao">
              <DescriptionFormControl
                {...register("descricao")}
                as="textarea"
                placeholder="Descreva o perfil..."
                defaultValue={perfil?.descricao}
                className="w-100 rounded"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="d-flex gap-2">
            <SubmitButton
              labelButton={!isEditing ? "Salvar novo perfil" : "Editar perfil"}
              isSubmitting={isSubmitting}
            />
            <ButtonLink label="Cancelar" fontSize="0.7rem" link="/perfis" />
          </Form.Group>
        </Row>
      </Form>
    </PerfilFormContainer>
  );
}
