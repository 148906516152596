import Dashboard from "../../../../Shared/layout";
import Select from "react-select";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import ArrayEstadosCidades from "../../../../Shared/Utils/EstadosCidades/estados-cidades.json";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import { colourSelectStyles } from "../../../../Shared/Utils/constants";
import {
  ColumnCard,
  FormLabelText,
  TravelListRow,
  TravelMonitoringByStateContainer,
} from "./styles";
import { TravelMonitoringByStateCard } from "./components/ TravelMonitoringByStateCard/ TravelMonitoringByStateCard";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import { useTravels } from "../../../../hooks/TravelProvider";
import { sortedDrivers, sortedVehicle } from "../../../../Shared/Utils/data";
import { TravelsListMap } from "../../../../Shared/Components/LeafletMaps/TravelsListMap";

const initialSelectState = {
  id: "Selecione o Estado",
  label: "-- Selecione o Estado... --",
};

const initialSelectDriver = {
  id: "Selecione o motorista",
  label: "-- Selecione o motorista... --",
};

const initialSelectVehicle = {
  id: "Selecione a placa",
  label: "-- Selecione a placa... --",
};

const TravelsList = () => {
  const [currentActivesPositions, setCurrentActivePositions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupedOrders, setGroupedOrders] = useState([]);
  const [listBy, setListBy] = useState("drivers");
  const [selectedState, setSelectedState] = useState(initialSelectState);
  const [selectedDriver, setSelectedDriver] = useState(initialSelectDriver);
  const [selectedVehicle, setSelectedVehicle] = useState(initialSelectVehicle);

  const [selectByDriver, setSelectByDriver] = useState(true);
  const [selectByVehicle, setSelectByVehicle] = useState(false);

  const {
    activeLoadOrder,
    activeLoadOrderLoading,
    getActivesLoadOrder,
    getLoadOrderAddress,
  } = useTravels();

  useEffect(() => {
    getActivesLoadOrder();
  }, [getActivesLoadOrder]);

  useEffect(() => {
    const updatedGroupedOrders = ArrayEstadosCidades.estados.map((estado) => {
      const state = estado.sigla;

      const filteredOrders =
        activeLoadOrder &&
        activeLoadOrder.filter((activeLoadOrder) => {
          return (
            (activeLoadOrder?.localizacao_atual === null &&
              activeLoadOrder?.uf_coleta === state) ||
            (activeLoadOrder?.localizacao_atual &&
              JSON.parse(activeLoadOrder?.localizacao_atual)?.uf === state)
          );
        });

      return filteredOrders;
    });

    setGroupedOrders(updatedGroupedOrders);
  }, [activeLoadOrder]);

  const updateGroupedOrder = useCallback(
    (selectedState) => {
      let updatedGroupedOrders;

      if (selectedState?.id === "Selecione o Estado") {
        updatedGroupedOrders = ArrayEstadosCidades.estados.map((estado) => {
          const state = estado.sigla;
          const filteredOrders = activeLoadOrder.filter((activeLoadOrder) => {
            return (
              (activeLoadOrder?.localizacao_atual === null &&
                activeLoadOrder?.uf_coleta === state) ||
              (activeLoadOrder?.localizacao_atual &&
                JSON.parse(activeLoadOrder?.localizacao_atual)?.uf === state)
            );
          });

          setSelectedDriver({
            id: "Selecione o motorista",
            label: "Selecione o motorista",
          });

          setSelectedVehicle({
            id: "Selecione a placa",
            label: "Selecione a placa",
          });

          return filteredOrders;
        });
      } else {
        updatedGroupedOrders = ArrayEstadosCidades.estados.map((estado) => {
          let filteredOrders;
          if (estado?.sigla === selectedState?.id) {
            filteredOrders = activeLoadOrder.filter((activeLoadOrder) => {
              return (
                (activeLoadOrder?.localizacao_atual === null &&
                  activeLoadOrder?.uf_coleta === selectedState?.id) ||
                (activeLoadOrder?.localizacao_atual &&
                  JSON.parse(activeLoadOrder?.localizacao_atual)?.uf ===
                    selectedState?.id)
              );
            });
            return filteredOrders;
          }
          return [];
        });
      }

      setGroupedOrders(updatedGroupedOrders);
    },
    [activeLoadOrder]
  );

  const updateList = useCallback(
    (event, initialValue) => {
      let updatedGroupedOrders;

      if (event.id === initialValue?.id) {
        initialValue === initialSelectDriver
          ? setSelectedVehicle(initialSelectVehicle)
          : setSelectedDriver(initialSelectDriver);

        setSelectedState(initialSelectState);

        updatedGroupedOrders = ArrayEstadosCidades.estados.map((estado) => {
          const state = estado.sigla;
          const filteredOrders = activeLoadOrder.filter((activeLoadOrder) => {
            return (
              (activeLoadOrder?.localizacao_atual === null &&
                activeLoadOrder?.uf_coleta === state) ||
              (activeLoadOrder?.localizacao_atual &&
                JSON.parse(activeLoadOrder?.localizacao_atual)?.uf === state)
            );
          });

          return filteredOrders;
        });
      } else {
        updatedGroupedOrders = ArrayEstadosCidades.estados.map((estado) => {
          const filteredOrders = activeLoadOrder.filter((activeLoadOrder) => {
            return (
              (activeLoadOrder?.localizacao_atual === null &&
                activeLoadOrder?.uf_coleta === estado.sigla &&
                activeLoadOrder.id === event.id) ||
              (activeLoadOrder?.localizacao_atual &&
                JSON.parse(activeLoadOrder?.localizacao_atual)?.uf ===
                  estado.sigla &&
                activeLoadOrder.id === event.id)
            );
          });

          return filteredOrders;
        });

        const currentLoadOrder = updatedGroupedOrders.find(
          (obj) => obj && obj.length > 0 && obj[0].id
        );

        if (currentLoadOrder && currentLoadOrder.length > 0) {
          setSelectedVehicle({
            id: currentLoadOrder[0]?.id,
            label: currentLoadOrder[0]?.placa_cavalo,
          });

          setSelectedDriver({
            id: currentLoadOrder[0]?.id,
            label: currentLoadOrder[0]?.nome_mot,
          });
        }

        if (
          currentLoadOrder &&
          currentLoadOrder.length > 0 &&
          currentLoadOrder[0]?.localizacao_atual
        ) {
          const currentState = JSON.parse(
            currentLoadOrder[0]?.localizacao_atual
          );

          const state = ArrayEstadosCidades.estados.find(
            (state) => state.sigla === currentState?.uf && state.nome
          );

          setSelectedState({
            id: state?.sigla,
            label: state?.nome,
          });
        }
      }

      setGroupedOrders(updatedGroupedOrders);
    },
    [activeLoadOrder]
  );

  const handleSelectState = useCallback(
    (event) => {
      setSelectedState(event);
      updateGroupedOrder(event);

      setSelectedDriver(initialSelectDriver);

      setSelectedVehicle(initialSelectVehicle);
    },
    [updateGroupedOrder]
  );

  const handleSelectDriver = useCallback(
    (event) => {
      setSelectedDriver(event);
      updateList(event, initialSelectDriver);
    },
    [updateList]
  );

  const handleSelectVehicle = useCallback(
    (event) => {
      setSelectedVehicle(event);
      updateList(event, initialSelectVehicle);
    },
    [updateList]
  );

  const findActivesPositions = useCallback(async () => {
    const positionsPromises =
      groupedOrders &&
      groupedOrders.flat().map(async (activeLoadOrder) => {
        if (activeLoadOrder?.localizacao_atual === null) {
          const position = await getLoadOrderAddress(
            `${activeLoadOrder?.nome_cidade_coleta},${activeLoadOrder?.uf_coleta},Brasil`
          );

          return {
            lat: position.length > 0 ? position[0]?.lat : position?.lat,
            lng: position.length > 0 ? position[0]?.lon : position?.lon,
            desvio_rota: activeLoadOrder?.desvio_rota ?? false,
          };
        } else {
          const position =
            activeLoadOrder?.localizacao_atual &&
            JSON.parse(activeLoadOrder?.localizacao_atual);

          return {
            lat: position?.geo_localizacao?.latitude,
            lng: position?.geo_localizacao?.longitude,
            desvio_rota: activeLoadOrder?.desvio_rota ?? false,
          };
        }
      });

    const resolvedPositions = await Promise.all(positionsPromises);
    setLoading(false);

    setCurrentActivePositions(resolvedPositions);
  }, [getLoadOrderAddress, groupedOrders]);

  useEffect(() => {
    setLoading(true);
    findActivesPositions();
  }, [
    findActivesPositions,
    updateGroupedOrder,
    getActivesLoadOrder,
    groupedOrders,
    activeLoadOrder,
    selectedState,
  ]);

  const statesList = ArrayEstadosCidades.estados
    .map((estado) => estado)
    .map((sigla) => ({
      id: sigla.sigla,
      label: sigla.nome,
    }));

  const driversList = useMemo(() => {
    const driverOptions =
      activeLoadOrder &&
      sortedDrivers(activeLoadOrder).map((activeLoadOrder) => ({
        id: activeLoadOrder?.id,
        label: activeLoadOrder?.nome_mot,
      }));

    return driverOptions
      ? [initialSelectDriver, ...driverOptions]
      : [initialSelectDriver];
  }, [activeLoadOrder]);

  const vehiclesList = useMemo(() => {
    const vehicleOptions =
      activeLoadOrder &&
      sortedVehicle(activeLoadOrder).map((activeLoadOrder) => ({
        id: activeLoadOrder?.id,
        label: activeLoadOrder?.placa_cavalo,
      }));

    return vehicleOptions
      ? [initialSelectVehicle, ...vehicleOptions]
      : [initialSelectVehicle];
  }, [activeLoadOrder]);

  const handleListByDriver = useCallback(() => {
    setListBy("drivers");
    setSelectByDriver(true);
    setSelectByVehicle(false);
  }, []);

  const handleListByVehicle = useCallback(() => {
    setListBy("vehicle");
    setSelectByDriver(false);
    setSelectByVehicle(true);
  }, []);

  return (
    <Dashboard>
      {(activeLoadOrderLoading || loading) && <Loading />}
      {!activeLoadOrderLoading && !loading && (
        <TravelListRow className="g-1 mt-1">
          <ColumnCard xl={8}>
            <Card className="text-center w-100 h-100 d-flex flex-column p-3">
              <Row
                className="d-flex align-items-center justify-content-start g-3 mb-4 mt-2"
                md={3}
              >
                {selectByVehicle && (
                  <Select
                    as={Col}
                    className="react-select mt-0 text-start"
                    options={vehiclesList}
                    onChange={handleSelectVehicle}
                    value={selectedVehicle}
                    placeholder="Monitoramento por veículo"
                    styles={colourSelectStyles(true)}
                    menuPosition={"fixed"}
                    menuZIndex={999}
                  />
                )}
                {selectByDriver && (
                  <Select
                    as={Col}
                    className="react-select mt-0 text-start"
                    options={driversList}
                    onChange={handleSelectDriver}
                    value={selectedDriver}
                    placeholder="Monitoramento por motorista"
                    styles={colourSelectStyles(true)}
                    menuPosition={"fixed"}
                    menuZIndex={999}
                  />
                )}
                <div className="d-flex gap-1 align-items-center justify-content-between mt-2 mt-md-0">
                  <Form.Group
                    as={Col}
                    md={3}
                    className="d-flex align-items-center gap-1"
                    controlId="filter-travels-by-driver"
                  >
                    <Form.Check
                      type="radio"
                      id="filter-travels-by-driver"
                      checked={selectByDriver}
                      onChange={handleListByDriver}
                    />
                    <FormLabelText className="mb-0">Motoristas</FormLabelText>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    className="d-flex align-items-center gap-2"
                    controlId="filter-travels-by-vehicle"
                  >
                    <Form.Check
                      type="radio"
                      id="filter-travels-by-vehicle"
                      checked={selectByVehicle}
                      onChange={handleListByVehicle}
                    />
                    <FormLabelText className="mb-0">Veículos</FormLabelText>
                  </Form.Group>
                </div>
              </Row>
              <TravelsListMap
                height="95%"
                positions={currentActivesPositions}
                zoom={4}
              />
            </Card>
          </ColumnCard>
          <Col xl={4}>
            <Card className="text-center w-100 h-100 d-flex flex-column p-3">
              <Select
                className="react-select mb-3 text-start"
                options={statesList}
                onChange={handleSelectState}
                value={selectedState}
                placeholder="Monitoramento por Estados"
                styles={colourSelectStyles(true)}
              />

              <TravelMonitoringByStateContainer>
                <Row className="g-2" xs={1}>
                  {groupedOrders &&
                    Array.isArray(groupedOrders) &&
                    groupedOrders?.length > 0 &&
                    groupedOrders.map((selectedLoadOrder, index) => (
                      <Fragment key={index}>
                        {selectedLoadOrder.length > 0 && (
                          <Col className="w-100">
                            <TravelMonitoringByStateCard
                              activeLoadOrder={selectedLoadOrder}
                              handleSelectDriver={handleSelectDriver}
                              handleSelectVehicle={handleSelectVehicle}
                              listBy={listBy}
                            />
                          </Col>
                        )}
                      </Fragment>
                    ))}
                </Row>
                {Array.isArray(groupedOrders) &&
                  groupedOrders.every((subarray) => subarray.length === 0) && (
                    <EmptyTableMessage label="Estado sem viagem monitorada" />
                  )}
              </TravelMonitoringByStateContainer>
            </Card>
          </Col>
        </TravelListRow>
      )}
    </Dashboard>
  );
};

export default memo(TravelsList);
