import { Spinner } from "react-bootstrap";
import { useTravels } from "../../../../../hooks/TravelProvider";
import { SearchLoaderOrder } from "../SearchLoaderOrder/SearchLoaderOrder";
import TravelLoadOrderList from "../TravelLoadOrderList";
import { sortByDateDescending } from "../../../../../Shared/Utils/data";

import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";
import { useEffect, useState } from "react";
import TablePagination from "../../../../../Shared/Components/PaginationTable/PaginationTable";
import { TabContainer } from "./styles";

export const ClosedLoadOrders = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    closedLoadOrders,
    closeLoadOrderTotalPage,
    isLoading,
    getLoadOrder,
    getClosedLoadOrders,
  } = useTravels();

  const handlePageChange = (event) => {
    setCurrentPage(event);
  };

  useEffect(() => {
    getClosedLoadOrders(currentPage);
  }, [currentPage, getClosedLoadOrders]);

  return (
    <div className="d-flex flex-column h-100">
      <TabContainer>
        <SearchLoaderOrder getList={getClosedLoadOrders} />
        {isLoading && (
          <div className="d-flex justify-content-center">
            <Spinner size="sm" animation="border" variant="primary" />
          </div>
        )}
        {!isLoading &&
          Array.isArray(closedLoadOrders) &&
          closedLoadOrders.length > 0 && (
            <>
              <TravelLoadOrderList
                getLoadOrder={getLoadOrder}
                travelLoadOrderList={sortByDateDescending(closedLoadOrders)}
              />
            </>
          )}
        {(!closedLoadOrders || closedLoadOrders.length === 0) && !isLoading && (
          <EmptyTableMessage label="Sem ordens de carregamentos finalizadas no mês" />
        )}
      </TabContainer>
      {!isLoading &&
        Array.isArray(closedLoadOrders) &&
        closedLoadOrders.length > 0 && (
          <div className="mt-2 ps-2">
            <TablePagination
              currentPage={currentPage}
              totalPages={closeLoadOrderTotalPage}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
    </div>
  );
};
