import { Col } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";

import { VehicleCard } from "./VehicleCard";
import { PageTitle, VehiclesAssociatedWithTheDriverRow } from "./styles";

import { AlertMessage } from "../../../../../Shared/Components/AlertMessage";
import ButtonLink from "../../../../../Shared/Components/ButtonLink/ButtonLink";

const VehiclesAssociatedWithTheDriverForm = ({ driver }) => {
  return (
    <section className="text-start p-0 mb-5">
      <PageTitle className="text-start">Veículo associado</PageTitle>
      <div className="text-center p-0">
        {!driver?.veiculo && (
          <div className="px-2 pb-3">
            <AlertMessage
              label="Nenhum veículo associado"
              backgroundColor="#2185D0"
              color="#FFF"
            />
          </div>
        )}
        {driver?.veiculo && (
          <>
            <VehiclesAssociatedWithTheDriverRow
              xs={1}
              md={2}
              className="g-1 mb-3 mt-3"
            >
              <Col className="px-1">
                <VehicleCard
                  title="Veículo"
                  value={driver?.veiculo?.placa}
                  modelValue={driver?.veiculo?.modelo}
                />
              </Col>
              {Array.isArray(driver?.veiculo?.carretas) &&
                driver?.veiculo?.carretas.length > 0 &&
                driver?.veiculo?.carretas.map((carreta, index) => (
                  <Col key={index} className="px-1">
                    <VehicleCard
                      title={`Carreta ${index + 1}`}
                      value={carreta?.placa}
                      modelValue={carreta?.modelo}
                    />
                  </Col>
                ))}
            </VehiclesAssociatedWithTheDriverRow>
            <>
              {driver?.veiculo && (
                <div className="d-flex justify-content-end mb-2 px-2">
                  <ButtonLink
                    fontSize="0.7rem"
                    link={`/veiculos-informacoes/${driver?.veiculo?.id}`}
                    label="Consultar veículo"
                    secondIcon={<IoIosArrowForward />}
                    buttoncolor="primary"
                  />
                </div>
              )}
            </>
          </>
        )}
      </div>
    </section>
  );
};

export default VehiclesAssociatedWithTheDriverForm;
