import React, { useMemo } from "react";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-routing-machine";
import { Subtitle } from "./Subtitle";
import locationPin from "../../../Assets/Images/locationPin.svg";
import originPinLocation from "../../../Assets/Images/originPinLocation.png";
import ButtonLink from "../ButtonLink/ButtonLink";
import { HiOutlineArrowsExpand } from "react-icons/hi";
import { MdHorizontalRule } from "react-icons/md";
import LeafletRoutingMachineHistory from "./LeafletRoutingMachineHistory";

const OriginIcon = L.icon({
  iconUrl: originPinLocation,
  iconSize: [15, 15],
  iconAnchor: [7, 15],
  popupAnchor: [0, -15],
});

const DestinyIcon = L.icon({
  iconUrl: locationPin,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
  popupAnchor: [0, -30],
});

export const HistoryTravelMap = ({
  height,
  origin,
  destiny,
  loadOrder,
  showMore,
  show,
  route,
  routesSegments,
  showSubtitle = false,
}) => {
  const defaultProps = useMemo(() => {
    return {
      center: origin ?? {
        lat: -15.616498333333334,
        lng: -56.078129999999994,
      },
    };
  }, [origin]);

  const routes = useMemo(() => {
    return route && JSON.parse(route);
  }, [route]);

  const segments =
    routes &&
    routes.coordinates &&
    routes.coordinates.map((route) => [route.lat, route.lng]);

  const colorOption = { color: "#2185D0" };

  return (
    <div>
      <MapContainer
        center={defaultProps?.center}
        zoom={12}
        style={{ height: height, width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={origin} icon={OriginIcon} />
        <Marker position={destiny} icon={DestinyIcon} />

        {segments && segments.length > 0 && (
          <Polyline positions={segments} pathOptions={colorOption} />
        )}

        <LeafletRoutingMachineHistory
          origin={origin}
          destiny={destiny}
          RealTimeLocationIcon={OriginIcon}
          show={show}
          routesSegments={routesSegments}
        />
      </MapContainer>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-lg-flex gap-1">
          <div className="d-flex gap-2">
            <Subtitle label="Coleta" icon={originPinLocation} size="10px" />
            <Subtitle label="Destino" icon={locationPin} size="18px" />
          </div>
          {showSubtitle && (
            <div className="d-flex align-items-center gap-1">
              <Subtitle
                label="Rota percorrida"
                reactIcon={<MdHorizontalRule color="#55caf4" size="2rem" />}
                size="10px"
              />
              <Subtitle
                label="Rota selecionada"
                reactIcon={<MdHorizontalRule color="#2185D0" size="2rem" />}
                size="10px"
              />
            </div>
          )}
        </div>
        {showMore && loadOrder?.status !== "2" && (
          <div className="d-flex p-1">
            <ButtonLink
              label="Expandir mapa"
              fontSize="0.7rem"
              buttoncolor="primary"
              link={`/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/map/${loadOrder?.token}`}
              icon={<HiOutlineArrowsExpand size={14} />}
              target="_blank"
            />
          </div>
        )}
      </div>
    </div>
  );
};
