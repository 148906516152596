import { useState } from "react";
import { useForm } from "react-hook-form";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";

import { IoFilterSharp } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import { FilterContent, SearchContainer } from "./styles";
import ButtonLink from "../ButtonLink/ButtonLink";
import { SubmitButton } from "../SubmitButton/SubmitButton";

export const SearchLoaderOrder = ({ handleGetList, hasCompany }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const handleClearFilter = () => {
    reset();
    setIsOpen(false);
    handleGetList();
  };

  const onSubmit = (data) => {
    handleGetList(data);
  };

  return (
    <div className="mb-1">
      <div className="d-flex justify-content-end mb-1">
        <SearchContainer
          onClick={() => setIsOpen(!isOpen)}
          title="Abrir filtro"
        >
          <span>Filtrar</span>
          <IoFilterSharp color="#FFF" />
        </SearchContainer>
      </div>
      {isOpen && (
        <FilterContent>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="d-flex g-1">
              <Form.Group as={Col} xs={12} md={3} controlId="formStartDate">
                <FloatingLabel controlId="formStartDate" label="Início">
                  <Form.Control
                    {...register("data_inicio")}
                    size="sm"
                    type="date"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={3} controlId="formEndDate">
                <FloatingLabel controlId="formEndDate" label="Fim">
                  <Form.Control
                    {...register("data_fim")}
                    size="sm"
                    type="date"
                  />
                </FloatingLabel>
              </Form.Group>
              {!hasCompany && (
                <Form.Group as={Col} md={3} controlId="company">
                  <FloatingLabel controlId="company" label="Empresa">
                    <Form.Control
                      {...register("nomefantasia")}
                      size="sm"
                      type="text"
                    />
                  </FloatingLabel>
                </Form.Group>
              )}
              <Form.Group
                as={Col}
                md={!hasCompany ? 3 : 6}
                controlId="cpf-cnpj"
              >
                <FloatingLabel
                  controlId="cpf-cnpj"
                  label="CPF/CNPJ Proprietário"
                >
                  <Form.Control
                    {...register("cpf_cnpj_prop")}
                    size="sm"
                    type="text"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md={3} controlId="Origin">
                <FloatingLabel controlId="Origin" label="Origem">
                  <Form.Control
                    {...register("cidade_origem")}
                    size="sm"
                    type="text"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md={3} controlId="Destiny">
                <FloatingLabel controlId="Destiny" label="Destino">
                  <Form.Control
                    {...register("cidade_destino")}
                    size="sm"
                    type="text"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md={3} controlId="Cargo">
                <FloatingLabel controlId="Cargo" label="Mercadoria">
                  <Form.Control
                    {...register("mercadoria")}
                    size="sm"
                    type="text"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md={3} controlId="Plate">
                <FloatingLabel controlId="Plate" label="Placa">
                  <Form.Control {...register("placa")} size="sm" type="text" />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <div className="d-flex align-items-center justify-content-end gap-1 mt-2">
              <SubmitButton
                labelButton="Aplicar filtro"
                icon={<FaCheck size="0.7rem" />}
              />
              <ButtonLink
                label="Limpar filtro"
                fontSize="0.7rem"
                buttoncolor="gray"
                handleClick={handleClearFilter}
                icon={<IoMdClose size="0.7rem" />}
              />
            </div>
          </Form>
        </FilterContent>
      )}
    </div>
  );
};
