import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { FaDotCircle } from "react-icons/fa";

import TravelHistoryTable from "./TravelHistoryTable";
import { Item, TravelHistoryContainer } from "./styles";
import Dashboard from "../../../../../Shared/layout";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { formatNameString } from "../../../../../Shared/Utils/data";
import { SearchLoaderOrder } from "../../../../../Shared/Components/TravelHistorySearch/TravelHistorySearch";
import { useDrivers } from "../../../../../hooks/DriverProvider";

const TravelHistoryByCompany = () => {
  const [list, setList] = useState(null);
  const [filterDate, setFilterDate] = useState(null);

  const { id, company } = useParams();
  const { driver, isLoading, getDriver } = useDrivers();

  useEffect(() => {
    getDriver(id);
  }, [getDriver, id]);

  const travelHistoryList = useMemo(() => {
    if (driver?.viagens && driver?.viagens !== '{"message":"no data"}') {
      return JSON.parse(driver?.viagens);
    } else {
      return null;
    }
  }, [driver?.viagens]);

  const handleGetList = (data) => {
    if (data) {
      setFilterDate({
        data_inicio: data?.data_inicio
          ? new Date(data?.data_inicio).toISOString()
          : null,
        data_fim: data?.data_fim
          ? new Date(data?.data_fim).toISOString()
          : null,
      });

      const filterItems = Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== "" && key !== "data_inicio" && key !== "data_fim") {
          acc[key] = value;
        }
        return acc;
      }, {});

      setList(filterItems);
    } else {
      setList(null);
      setFilterDate(null);
    }
  };

  const filteredList = list
    ? travelHistoryList.filter((item) => {
        return (
          Object.entries(list).every(([key, value]) => {
            return item[key].toLowerCase().includes(value.toLowerCase());
          }) &&
          (!filterDate?.data_inicio ||
            new Date(item.dt_viagem) >= new Date(filterDate?.data_inicio)) &&
          (!filterDate?.data_fim ||
            new Date(item.dt_viagem) <= new Date(filterDate?.data_fim))
        );
      })
    : filterDate
    ? travelHistoryList.filter((item) => {
        return (
          (!filterDate?.data_inicio ||
            new Date(item.dt_viagem) >= new Date(filterDate?.data_inicio)) &&
          (!filterDate?.data_fim ||
            new Date(item.dt_viagem) <= new Date(filterDate?.data_fim))
        );
      })
    : travelHistoryList;

  const currentTravelHistory =
    company && travelHistoryList
      ? travelHistoryList.filter((travel) => travel?.nomefantasia === company)
      : travelHistoryList;

  return (
    <Dashboard title="Histórico de viagens sdsdd">
      {isLoading && <Loading />}

      {!isLoading && driver && (
        <TravelHistoryContainer className="mt-3">
          <div className="px-2">
            <SearchLoaderOrder
              handleGetList={handleGetList}
              hasCompany={!!company}
            />
          </div>

          <div className="d-flex flex-column gap-2">
            <Item className="gap-1">
              <FaDotCircle size={10} />
              <h3>Motorista:</h3>
              <span>{formatNameString(driver?.nome)}</span>
            </Item>
            <div className="d-flex flex-column flex-md-row gap-2">
              <Item className="gap-1">
                <FaDotCircle size={10} />
                <h3>Empresa:</h3>
                <span>
                  {currentTravelHistory &&
                    currentTravelHistory[0]?.nomefantasia}
                </span>
              </Item>
              <Item className="gap-1">
                <FaDotCircle size={10} />
                <h3>CNPJ /CPF:</h3>
                <span>
                  {currentTravelHistory &&
                    currentTravelHistory[0]?.cpf_cnpj_prop}
                </span>
              </Item>
            </div>
          </div>

          <TravelHistoryTable
            company={company}
            isLoading={isLoading}
            travelHistoryList={filteredList}
          />
        </TravelHistoryContainer>
      )}
    </Dashboard>
  );
};

export default TravelHistoryByCompany;
