import { Row } from "react-bootstrap";
import styled from "styled-components";

export const OwnerListContainer = styled(Row)`
max-height: 30vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 425px){
  max-height: 60vh;
 }
 @media(min-width: 992px){
  max-height: 65vh;
 }

  @media(min-width: 1640px){
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  max-height: 80vh;
  }
  
`;

export const LoadingContainer = styled.div`
  height: 40px;
  width: 150px;
`
export const OwnerListRow = styled(Row)`

 @media(min-width: 768px){
  max-height: 44vh;
  overflow-x: hidden;
 }
 @media(min-width: 992px){
  max-height: 50vh;
 }
  @media(min-width: 1640px){
  max-height: 60vh;
  }

  @media(min-width: 2000px){
  max-height: 65vh;
  }

  @media(min-width: 2440px){
  max-height: 70vh;
  }
  
`