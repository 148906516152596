import { Table } from "react-bootstrap";
import {
  TableContainer,
  TableTitle,
  Thead,
  TitleItem,
  VehicleConsultTitle,
} from "./styles";
import { EmptyTableMessage } from "../EmptyTableMessage";

export const VehicleFines = ({ detranConsult }) => {
  return (
    <div className="d-flex flex-column gap-1">
      <div className="justify-content-center">
        <VehicleConsultTitle className="justify-content-center">
          <span>Multas</span>
        </VehicleConsultTitle>
      </div>
      {detranConsult?.data[0]?.multas.length === 0 && (
        <EmptyTableMessage label="Não há registro de multas para o veículo" />
      )}
      {Array.isArray(detranConsult?.data?.multas) &&
        detranConsult?.data?.multas.length > 0 &&
        detranConsult?.data?.multas?.data.map((vehcileFines, index) => (
          <div key={index}>
            <TableContainer>
              <Table hover size="sm" className="border rounded mb-0">
                <Thead>
                  <TableTitle>
                    <TitleItem>Data</TitleItem>
                    <TitleItem>Descrição</TitleItem>
                    <TitleItem>Local</TitleItem>
                  </TableTitle>
                </Thead>
                <tbody>
                  {vehcileFines.map((event, index) => (
                    <tr key={index}>
                      <td>{event?.data_infracao} </td>
                      <td>
                        {event?.codigo_infracao} - {event?.descricao}
                      </td>
                      <td>{event?.local_infracao}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          </div>
        ))}
    </div>
  );
};
