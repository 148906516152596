import { useCallback, useEffect, useMemo, useState } from "react";
import { useAddresses } from "../../../../../../hooks/AddressProvider";

import ArrayEstadosCidades from "../../../../../../Shared/Utils/EstadosCidades/estados-cidades.json";
import { onlyNumbers } from "../../../../../../Shared/Utils/data";
import { Col, FloatingLabel, Form } from "react-bootstrap";
import { Loading } from "../../../../../../Shared/Components/Loading/Loading";
import InputMask from "react-input-mask";
import { PageTitle } from "./PageTitle";

export const AddressField = ({
  owner,
  setValue,
  register,
  errors,
  ownerByCpfCnpj,
  isSAT,
}) => {
  const [cidadesDoEstado, setCidadesDoEstado] = useState([]);
  const [newAddress, setNewAddress] = useState();
  const [hasChange, setHasChange] = useState(false);
  const [cepValue, setCepValue] = useState();

  const { addresses, isLoading, getAddresses } = useAddresses();

  const currentCepValue = useMemo(() => {
    if (owner?.cep) {
      return owner?.cep;
    }
    if (ownerByCpfCnpj?.cep && isSAT) {
      return ownerByCpfCnpj?.cep;
    }
  }, [isSAT, owner?.cep, ownerByCpfCnpj?.cep]);

  useEffect(() => {
    setCepValue(currentCepValue);
  }, [currentCepValue]);

  const obterCidadesPorSigla = (sigla) => {
    const estado = ArrayEstadosCidades.estados.find(
      (estado) => estado.sigla === sigla
    );

    if (estado) {
      return estado?.cidades;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (owner) {
      const cidades = obterCidadesPorSigla(owner?.uf);
      setCidadesDoEstado(cidades);
      getAddresses(owner?.cep);

      setHasChange(true);
    }
  }, [owner, getAddresses]);

  useEffect(() => {
    if (isSAT && ownerByCpfCnpj && Object.keys(ownerByCpfCnpj).length !== 0) {
      getAddresses(ownerByCpfCnpj?.cep);
      setHasChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerByCpfCnpj]);

  useEffect(() => {
    setNewAddress(addresses);
  }, [addresses]);

  useEffect(() => {
    if (addresses?.localidade) {
      const cidades = obterCidadesPorSigla(addresses?.uf);
      setCidadesDoEstado(cidades);
    } else {
      setCidadesDoEstado([]);
    }
  }, [addresses?.localidade, addresses?.uf, ownerByCpfCnpj]);

  useEffect(() => {
    if (hasChange === true && newAddress !== null) {
      setValue("cidade", newAddress?.localidade);
      setValue("bairro", newAddress?.bairro);
      setValue("endereco", newAddress?.logradouro);
      setValue("uf", newAddress?.uf);
    }
  }, [hasChange, newAddress, setValue]);

  const handleChangeCepInput = useCallback(
    async (event) => {
      setHasChange(false);
      const cep = onlyNumbers(event.target.value);

      if (cep.length > 7) {
        getAddresses(cep);
        setHasChange(true);
        setCepValue(cep);
      }
    },
    [getAddresses]
  );

  useEffect(() => {
    if ((isSAT && ownerByCpfCnpj) || owner) {
      cepValue && setValue("cep", cepValue);
    }
  }, [cepValue, isSAT, owner, ownerByCpfCnpj, setValue]);

  return (
    <>
      <PageTitle label="Endereço" />
      <Form.Group as={Col} md={4} controlId="formCep">
        <FloatingLabel controlId="formCep" label="CEP">
          <Form.Control
            {...register("cep", {
              required: "O cep é obrigatório",
              validate: (value) => {
                value = value.replace(/\D/g, "");
                if (value.length !== 8) {
                  return "CEP inválido";
                }
                return true;
              },
            })}
            size="sm"
            type="text"
            as={InputMask}
            mask="99.999-999"
            maskChar={null}
            placeholder="Digite o cep"
            className="is-required"
            onChange={handleChangeCepInput}
            defaultValue={cepValue}
          />

          {errors?.cep && (
            <Form.Text className="text-danger">
              {errors?.cep?.message}
            </Form.Text>
          )}
        </FloatingLabel>
      </Form.Group>

      {isLoading && <Loading />}

      {!isLoading && (
        <>
          <Form.Group as={Col} md={4} controlId="formState">
            <FloatingLabel controlId="formState" label="Estado">
              <Form.Select
                size="sm"
                {...register("uf", { required: "O Estado é obrigatório" })}
                type="text"
                disabled={true}
                className="is-required"
                defaultValue={hasChange && addresses?.uf}
              >
                <option value="" disabled>
                  -- Selecione um Estado --
                </option>
                {ArrayEstadosCidades.estados
                  .map((estado) => estado.sigla)
                  .map((sigla) => (
                    <option key={sigla} value={sigla}>
                      {sigla}
                    </option>
                  ))}
              </Form.Select>
              {errors?.uf && (
                <Form.Text className="text-danger">
                  {errors?.uf?.message}
                </Form.Text>
              )}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md={4} controlId="formCidade">
            <FloatingLabel controlId="formCidade" label="Cidade">
              <Form.Select
                aria-label="Default select example"
                {...register("cidade", { required: "A cidade é obrigatória" })}
                size="sm"
                type="text"
                disabled={true}
                className="is-required"
                placeholder="Digite a cidade"
                defaultValue={
                  owner
                    ? owner?.cidade
                    : addresses
                    ? addresses?.localidade
                    : null
                }
              >
                <option value="">-- Selecione uma cidade --</option>
                {cidadesDoEstado.map((cidade) => (
                  <option key={cidade} value={cidade}>
                    {cidade}
                  </option>
                ))}
              </Form.Select>
              {errors?.cidade && (
                <Form.Text className="text-danger">
                  {errors?.cidade?.message}
                </Form.Text>
              )}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md={4} controlId="formBairro">
            <FloatingLabel controlId="formBairro" label="Bairro">
              <Form.Control
                {...register("bairro")}
                size="sm"
                type="text"
                disabled={true}
                placeholder="Digite o bairro"
                defaultValue={
                  owner
                    ? owner?.bairro
                    : isSAT
                    ? ownerByCpfCnpj?.bairro && ownerByCpfCnpj?.bairro
                    : null
                }
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md={8} controlId="formAddress">
            <FloatingLabel controlId="formAddress" label="Endereço">
              <Form.Control
                {...register("endereco")}
                size="sm"
                type="text"
                disabled={true}
                placeholder="Digite o endereço"
                defaultValue={
                  owner
                    ? owner?.endereco
                    : isSAT
                    ? ownerByCpfCnpj?.endereco && ownerByCpfCnpj?.endereco
                    : null
                }
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md={4} controlId="formNumber">
            <FloatingLabel controlId="formNumber" label="Número">
              <Form.Control
                {...register("numero", { required: "O número é obrigatório" })}
                size="sm"
                type="text"
                placeholder="Número"
                className="is-required"
                as={InputMask}
                mask="99999999"
                maskChar={null}
                defaultValue={
                  owner
                    ? owner?.numero
                    : ownerByCpfCnpj && isSAT && ownerByCpfCnpj?.numero
                }
              />
              {errors?.numero && (
                <Form.Text className="text-danger">
                  {errors?.numero?.message}
                </Form.Text>
              )}
            </FloatingLabel>
          </Form.Group>

          <Form.Group
            as={Col}
            md={8}
            controlId="formComplement"
            className="mb-md-3"
          >
            <FloatingLabel controlId="formComplement" label="Complemento">
              <Form.Control
                {...register("complemento")}
                size="sm"
                type="text"
                placeholder="Digite o complemento"
                defaultValue={owner && owner?.complemento}
              />
            </FloatingLabel>
          </Form.Group>
        </>
      )}
    </>
  );
};
