import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";

export const CoverImage = styled.div`
width: 100%;
  height:auto;
  overflow-x: auto; 
  white-space: nowrap;
  padding-bottom: 70%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ModalTitle = styled(Modal.Title)`
  font-size: 1.5rem;
`;

export const DownloadButton = styled(Button)`
  border: none;
  background-color: transparent;
  color: var(--color-gray-15);
  transition: transform 300ms;
  font-size: 0.9rem;

  &:hover {
    font-size: 0.9rem;
    border: none;
    background-color: transparent;
    color: var(--color-gray-15);
    transform: scale(1.05);
    z-index: 1;
  }
`;
