import { memo, useCallback, useEffect, useState } from "react";
import Dashboard from "../../../../../Shared/layout";
import { useParams } from "react-router-dom";
import ControlDriverCard from "../components/ControlDriverCard/ControlDriverCard";
import { useDrivers } from "../../../../../hooks/DriverProvider";
import {
  BorderCard,
  Column,
  DriverRow,
  InfoBorderCard,
  InfomationItem,
  PageTitle,
} from "./styles";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { DriverInformationComparison } from "./DriverInformationComparison";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { driverInitialState } from "../../../../../Shared/Utils/constants";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ButtonLink from "../../../../../Shared/Components/ButtonLink/ButtonLink";
import moment from "moment";

const DriverSATInformations = () => {
  const [changing, setChanging] = useState(driverInitialState);
  const [hasInconsistencies, setHasInconsistencies] = useState(false);

  const { id } = useParams();

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const {
    driver,
    isLoading,
    driverSat,
    loadingSatDriver,
    getDriver,
    getDriverSat,
    updateDriver,
  } = useDrivers();

  useEffect(() => {
    getDriver(id);
  }, [getDriver, id]);

  useEffect(() => {
    driver?.id && driver?.status_cadastro !== "0r" && getDriverSat(driver?.id);
  }, [driver?.id, driver?.status_cadastro, getDriverSat]);

  const onUpdateDriver = useCallback(
    async (data) => {
      await updateDriver(driver?.id, data);
      getDriver(driver?.id);
    },
    [driver?.id, getDriver, updateDriver]
  );

  const handleInputChange = (fieldName) => {
    setChanging((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const onSubmit = useCallback(
    (data) => {
      if (data && Object.keys(data).length === 0) {
        Swal.fire({
          icon: "error",
          title: "Erro ao cadastrar inconsitência!",
          text: "Insira pelo menos um campo com inconsistência",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } else {
        const keys = Object.keys(data.campos_erros_cadastro);
        const filteredData = keys.filter((key) => changing[key]);

        const newData = {
          campos_erros_cadastro: {},
        };
        filteredData.forEach((key) => {
          newData.campos_erros_cadastro[key] = data.campos_erros_cadastro[key];
        });
        newData.status_cadastro = "7r";
        updateDriver(driver?.id, newData);
        getDriver(id);
        setChanging(driverInitialState);
        reset();
      }
    },
    [changing, driver?.id, getDriver, id, reset, updateDriver]
  );

  const handleEditDriverInconsistencies = useCallback(
    (data) => {
      Swal.fire({
        icon: "warning",
        title: "Você tem certeza absoluta?",
        html: `
      <div>
        <p>Essa ação não pode ser desfeita. Isso tornará o motorista inconsistente.</p>       
      </div>
    `,
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Salvar inconsistencias",
        cancelButtonText: "Cancelar",
        focusCancel: true,
        preConfirm: () => onSubmit(data),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
    [onSubmit]
  );

  return (
    <Dashboard title="Informações SAT">
      {(isLoading || loadingSatDriver) && <Loading />}
      {!isLoading && !loadingSatDriver && driver && (
        <div>
          <Form
            onSubmit={handleSubmit(handleEditDriverInconsistencies)}
            className="d-flex flex-column min-h-100 gap-3"
          >
            <DriverRow className="g-2">
              <Column md={3} xl={2}>
                <ControlDriverCard
                  driver={driver}
                  isSatValidation
                  hasInconsistencies={hasInconsistencies}
                />
              </Column>
              <Column md={9} xl={10}>
                <BorderCard className="p-3 d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <PageTitle className="pb-3">
                      Informações do motorista
                    </PageTitle>
                  </div>
                  <div className="mb-3">
                    <InfoBorderCard className="p-2 d-flex flex-column flex-md-row justify-content-between gap-2">
                      <div className="d-flex gap-1">
                        <InfomationItem>Status:</InfomationItem>
                        <InfomationItem
                          className={`${
                            driverSat?.liberado === "N"
                              ? "text-danger"
                              : "text-success"
                          }`}
                        >
                          {driverSat?.bloqueadoadm === "S"
                            ? "Bloqueado ADM"
                            : driverSat?.liberado === "S"
                            ? "Liberado"
                            : driverSat?.liberado === "N"
                            ? "Inativo"
                            : "--"}
                        </InfomationItem>
                      </div>
                      <div className="d-flex gap-1">
                        <InfomationItem>
                          Data da criação / atualização:
                        </InfomationItem>
                        <InfomationItem className="fw-bold">
                          {driverSat?.dataatual
                            ? moment
                                .utc(driverSat?.dataatual)
                                .format("DD/MM/YYYY")
                            : "--"}
                        </InfomationItem>
                      </div>
                    </InfoBorderCard>
                  </div>

                  <DriverInformationComparison
                    driver={driver}
                    driverSat={driverSat}
                    updateDriver={onUpdateDriver}
                    getDriver={getDriver}
                    register={register}
                    handleInputChange={handleInputChange}
                    setValue={setValue}
                    errors={errors}
                    setHasInconsistencies={setHasInconsistencies}
                  />
                  <div className="d-flex justify-content-end">
                    <ButtonLink
                      id="close-button"
                      fontSize="0.7rem"
                      link={
                        driver?.status_cadatro === "0r"
                          ? `/motoristas-listar/${driver?.id}`
                          : `/motoristas-listar/temporario/${driver?.id}`
                      }
                      label="Fechar"
                      disabled={!hasInconsistencies}
                    />
                  </div>
                </BorderCard>
              </Column>
            </DriverRow>
          </Form>
        </div>
      )}
    </Dashboard>
  );
};

export default memo(DriverSATInformations);
