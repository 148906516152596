import { Fragment, useMemo, useState } from "react";
import { Accordion } from "react-bootstrap";

import { CardBody } from "../styles";
import VehicleInformationsCard from "./VehicleInformationsCard";
import { EmptyTableMessage } from "../../../../../../../Shared/Components/EmptyTableMessage";
import ImageBase64Modal from "../../../../../../../Shared/Components/ImageModal/ImageBase64Modal";
import { VehicleFederalDetails } from "../../../../../../../Shared/Components/VehicleFederalDetails/VehicleFederalDetails";
import { TemporaryAnaliticMessage } from "../../../../../../../Shared/Components/TemporaryAnaliticMessage/TemporaryAnaliticMessage";

const VehicleInformationTab = ({
  driver,
  tempRegister,
  driverConsults,
  defaultActiveKey,
  setDefaultActiveKey,
  isloadingConsult,
  handleNewConsult,
  judicialProcess,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [imageTruck, setImageTruck] = useState("");
  const [title, setTitle] = useState("Documento");

  const handleShowModal = (imageTruck, title) => {
    setShowModal(true);
    setImageTruck(imageTruck);
    setTitle(title);
  };

  const vehicle = useMemo(
    () =>
      tempRegister &&
      tempRegister?.dados_formulario_veiculo &&
      JSON.parse(tempRegister?.dados_formulario_veiculo),
    [tempRegister]
  );

  const vehicleAttachment = useMemo(
    () =>
      tempRegister &&
      tempRegister?.anexos_veiculo &&
      JSON.parse(tempRegister?.anexos_veiculo),
    [tempRegister]
  );

  const anexoTrucks = useMemo(
    () =>
      tempRegister &&
      tempRegister?.anexos_carretas &&
      JSON.parse(tempRegister?.anexos_carretas),
    [tempRegister]
  );

  const trucks = useMemo(
    () =>
      tempRegister &&
      tempRegister?.dados_formulario_carretas &&
      JSON.parse(tempRegister?.dados_formulario_carretas),
    [tempRegister]
  );

  const vehicleConsult = useMemo(
    () =>
      driverConsults &&
      driverConsults.find((consult) => consult.posicao === "CAVALO"),
    [driverConsults]
  );

  const trucksConsult = useMemo(
    () =>
      driverConsults &&
      driverConsults.filter(
        (consult) =>
          consult.posicao === "CARRETA1" ||
          consult.posicao === "CARRETA2" ||
          consult.posicao === "CARRETA3" ||
          consult.posicao === "CARRETA4"
      ),
    [driverConsults]
  );

  const ownerForm = useMemo(
    () =>
      tempRegister?.dados_formulario_proprietario &&
      JSON.parse(tempRegister?.dados_formulario_proprietario),
    [tempRegister]
  );

  return (
    <>
      <CardBody>
        <TemporaryAnaliticMessage
          driver={driver}
          driverConsults={driverConsults}
          judicialProcess={judicialProcess}
        />
        <Accordion activeKey={defaultActiveKey}>
          {!tempRegister?.dados_formulario_motorista && (
            <div className="mt-4">
              <EmptyTableMessage label="Sem veículo cadastrado na tabela temporária para este motorista" />
            </div>
          )}
          {tempRegister?.dados_formulario_motorista && (
            <Accordion.Item eventKey="0">
              <Accordion.Header
                onClick={() =>
                  setDefaultActiveKey((prevState) =>
                    prevState !== "0" ? "0" : null
                  )
                }
              >
                Cavalo
              </Accordion.Header>
              <Accordion.Body>
                <VehicleInformationsCard
                  vehicle={vehicle}
                  ownerForm={ownerForm}
                  image={ownerForm?.foto_proprietario}
                  vehicleAttachment={vehicleAttachment}
                  ownerTenant={vehicle?.arrendamento}
                  documentImage={vehicle?.foto_crlv_cavalo}
                  handleShowModal={handleShowModal}
                  proofOfResidence={ownerForm?.foto_comprovante_residencia}
                  consult={vehicleConsult}
                  index="0"
                />

                {!isloadingConsult &&
                  driver?.status_cadastro === "5r" &&
                  driver?.processos_cadastro_concluido &&
                  vehicleConsult && (
                    <section className="mt-3 d-flex flex-column gap-1">
                      <VehicleFederalDetails
                        consult={vehicleConsult}
                        vehicleAttachment={vehicleAttachment}
                        vehicle={vehicle}
                        label="Cavalo"
                        index="0"
                        handleNewConsult={handleNewConsult}
                      />
                    </section>
                  )}
              </Accordion.Body>
            </Accordion.Item>
          )}
          {trucks &&
            Object.keys(trucks).map((truck, index) => {
              const consultItem = trucksConsult.find(
                (consultItem) => consultItem.posicao === `CARRETA${index + 1}`
              );

              return (
                <Fragment key={index}>
                  {trucks[truck]?.foto && (
                    <Accordion.Item eventKey={`${index + 1}`} key={index}>
                      <Accordion.Header
                        onClick={() =>
                          setDefaultActiveKey((prevState) =>
                            prevState !== `${index + 1}` ? `${index + 1}` : null
                          )
                        }
                      >
                        Carreta {index + 1}
                      </Accordion.Header>
                      <Accordion.Body>
                        <VehicleInformationsCard
                          truckIndex={index + 1}
                          vehicle={trucks[truck]}
                          handleShowModal={handleShowModal}
                          documentImage={trucks[truck]?.foto}
                          ownerTenant={trucks[truck]?.arrendamento}
                          vehicleAttachment={anexoTrucks[truck]}
                          consult={consultItem}
                        />
                        {consultItem &&
                          !isloadingConsult &&
                          driver?.status_cadastro === "5r" &&
                          driver?.processos_cadastro_concluido && (
                            <section className="mt-3 d-flex flex-column gap-1">
                              <VehicleFederalDetails
                                consult={consultItem}
                                label={`Carreta ${index + 1}`}
                              />
                            </section>
                          )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Fragment>
              );
            })}
        </Accordion>
      </CardBody>

      <ImageBase64Modal
        show={showModal}
        handleClose={() => setShowModal(false)}
        image={imageTruck}
        title={title}
      />
    </>
  );
};

export default VehicleInformationTab;
