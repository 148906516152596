import { Col } from "react-bootstrap";
import Dashboard from "../../../Shared/layout";
import LinkCard from "../../../Shared/Components/LinkCard";
import { memo } from "react";
import {
  LoadingListOrderIcon,
  OccurrencesIcon,
  TravelManagementRow,
  TravelsIcon,
} from "./styles";

const TravelManagement = () => {
  return (
    <Dashboard title="Gestão de viagens">
      <TravelManagementRow
        xs={1}
        md={2}
        lg={4}
        xl={6}
        id="container-cards-conf-sistema"
        className="d-flex mt-4 g-2"
      >
        <Col>
          <LinkCard
            route="/gestao-viagens/ordem-de-carregamento"
            title="Ordens de Carregamento"
            icon={<LoadingListOrderIcon />}
          />
        </Col>
        <Col>
          <LinkCard
            route="/gestao-viagens/viagens"
            title="Viagens"
            icon={<TravelsIcon />}
          />
        </Col>
        <Col>
          <LinkCard
            route="/gestao-viagens/tipos-de-ocorrencias"
            title="Tipos de ocorrências"
            icon={<OccurrencesIcon />}
          />
        </Col>
      </TravelManagementRow>
    </Dashboard>
  );
};

export default memo(TravelManagement);
