import moment from "moment";
import { OriginDestinationCardContainer } from "./styles";

import { useMemo } from "react";
import { findObjectWithHighestId } from "../../../../Shared/Utils/data";

export const OriginDestinationStatusCard = ({
  loadOrder,
  loadOrderRoute,
  isComplete,
}) => {
  const currentLoadOrderRoute = useMemo(() => {
    if (loadOrderRoute) {
      return findObjectWithHighestId(loadOrderRoute);
    }
  }, [loadOrderRoute]);

  const currentLocation = useMemo(() => {
    if (currentLoadOrderRoute) {
      const geoLocation = JSON.parse(currentLoadOrderRoute?.geo_localizacao);

      return geoLocation.endereço;
    }

    if (loadOrder?.localizacao_atual) {
      const geoLocation = JSON.parse(loadOrder?.localizacao_atual);

      return geoLocation?.geo_localizacao?.endereço;
    }

    return null;
  }, [currentLoadOrderRoute, loadOrder?.localizacao_atual]);

  return (
    <OriginDestinationCardContainer className="w-100  rounded-0 p-2 mb-4 d-flex flex-column gap-2">
      <div className={`${!currentLoadOrderRoute && "d-flex gap-1"}`}>
        <span>Última localização:</span>
        <h3>{currentLocation ?? "--"}</h3>
      </div>
      <div className="d-flex gap-1 align-items-center">
        <span>Data:</span>
        <h3>
          {currentLoadOrderRoute?.data
            ? moment(currentLoadOrderRoute.data)
                .utcOffset(-240)
                .format("DD/MM/YYYY [às] HH:mm")
            : loadOrder?.data_ultima_localizacao
            ? moment(loadOrder?.data_ultima_localizacao)
                .utcOffset(-240)
                .format("DD/MM/YYYY [às] HH:mm")
            : "--"}
        </h3>
      </div>
      {!isComplete && (
        <div className="d-flex gap-1 align-items-center">
          <span className="mb-0">Previsão duração atual:</span>
          <h3 className="mb-0">{loadOrder?.distancia_horas_atual ?? "--"}</h3>
        </div>
      )}
    </OriginDestinationCardContainer>
  );
};
