import { useForm } from "react-hook-form";
import { HiOutlineArrowPath } from "react-icons/hi2";
import { Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import OwnerAssociationForm from "../components/VehicleForms/OwnerAssociationForm";
import ActiveAndBlockForm from "../components/VehicleForms/ActiveAndBlockForm";
import UploadVehicleDocImage from "../components/VehicleForms/UploadVehicleDocImage";
import UploadOwnerVehicleDocImage from "../components/VehicleForms/UploadOwnerVehicleDocImage";
import VehicleForm from "../components/VehicleForms/VehicleForm";

import { SubmitButton } from "../../../../../Shared/Components/SubmitButton/SubmitButton";
import ButtonLink from "../../../../../Shared/Components/ButtonLink/ButtonLink";

import { useVehicles } from "../../../../../hooks/VehicleProvider";
import { memo, useEffect, useState } from "react";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";

const VehicleUpdateForm = ({
  owners,
  drivers,
  handleShowModal,
  id,
  setIsPendentingForm,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const { vehicle, isLoading, updateVehicle, getVehicle } = useVehicles();
  const navigate = useNavigate();

  const onSubmitVehicle = async (data) => {
    data.documento = data?.documento && parseFloat(data?.documento);
    data.dt_emissao_documento =
      data?.dt_emissao_documento && new Date(data.dt_emissao_documento);

    delete data.carretas;

    data.placa = data.placa.replace(/[^A-Za-z0-9]/g, "").toUpperCase();

    for (const key in data) {
      if (
        data[key] === "" ||
        data[key] === undefined ||
        data[key] === "Sem motorista"
      ) {
        delete data[key];
      }
    }

    data.bloqueado_adm = data?.bloqueado_adm === true ? "S" : "N";

    data.documento = data.documento && data.documento.toString();

    await updateVehicle(id, data, navigate);
    getVehicle(id);
    setIsEditing(false);
    setIsPendentingForm(false);
  };

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getVehicle(id);
  }, [id, getVehicle]);

  return (
    <Form onSubmit={handleSubmit(onSubmitVehicle)}>
      <Card className="text-center w-100  d-flex flex-grow-1">
        {isLoading && <Loading />}
        {!isLoading && vehicle && (
          <Card.Body className="text-start d-flex flex-column gap-2">
            <VehicleForm
              vehicle={vehicle}
              register={register}
              errors={errors}
              title="Cadastro do Cavalo"
              setValue={setValue}
              isEdit={isEditing}
            />
            <OwnerAssociationForm
              errors={errors}
              owners={owners}
              drivers={drivers}
              setValue={setValue}
              control={control}
              vehicle={vehicle}
              isEdit={isEditing}
            />
            <ActiveAndBlockForm
              register={register}
              setValue={setValue}
              vehicle={vehicle}
              isEdit={isEditing}
            />
            <div className="d-flex flex-column flex-md-row gap-2 g-md-4">
              <Form.Group>
                <UploadVehicleDocImage
                  setValue={setValue}
                  errors={errors}
                  register={register}
                  width="10.4rem"
                  height="10.4rem"
                  align="start"
                  vehicle={vehicle}
                  handleShowModal={handleShowModal}
                  isEdit={isEditing}
                />
              </Form.Group>
              <Form.Group>
                <UploadOwnerVehicleDocImage
                  setValue={setValue}
                  errors={errors}
                  width="10.4rem"
                  height="10.4rem"
                  align="start"
                  register={register}
                  vehicle={vehicle}
                  handleShowModal={handleShowModal}
                  isEdit={isEditing}
                />
              </Form.Group>
            </div>
            <div className="d-flex gap-3 mt-3 justify-content-end">
              {!isEditing && (
                <ButtonLink
                  label="Habilitar edição do cavalo"
                  fontSize="0.85rem"
                  buttoncolor="primary"
                  handleClick={() => {
                    setIsEditing(true);
                    setIsPendentingForm(true);
                  }}
                />
              )}
              {isEditing && (
                <SubmitButton
                  labelButton="Editar cavalo"
                  fontSize="0.85rem"
                  isSubmitting={isLoading}
                  icon={<HiOutlineArrowPath size={14} />}
                />
              )}
              <ButtonLink
                label="Cancelar"
                handleClick={() => {
                  setIsEditing(false);
                  setIsPendentingForm(false);
                }}
                fontSize="0.85rem"
                buttoncolor="gray"
                disabled={!isEditing}
              />
            </div>
          </Card.Body>
        )}
      </Card>
    </Form>
  );
};

export default memo(VehicleUpdateForm);
