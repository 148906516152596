import { Card } from "react-bootstrap";
import styled from "styled-components";

export const BorderCard = styled(Card)`
padding: 0.7rem;
background-color: var(--color-gray-9);
border: none;
height: 100%;

h1 {
  font-size: 0.9rem;
}

span {
  margin-left: 0.3rem;
  font-size: 0.8rem;
}
`

export const Item = styled.div`
margin-bottom: 0.5rem;
display: flex;
align-items: center;
`