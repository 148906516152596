import { Form, InputGroup } from "react-bootstrap";
import {
  AlertIcon,
  CoverImage,
  DeleteCnhButton,
  DeleteIconButton,
  ErrorMessage,
  FormControlInput,
} from "./styles";
import EmptyImageLogo from "../../../../../../Assets/Images//logo-design.png";
import { useCallback, useMemo, useState } from "react";

const UploadCompanyLogo = ({
  errors,
  company,
  className,
  width,
  height,
  paddingBottom,
  setValue,
}) => {
  const [uploadImage, setUploadImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [uploadLogoImage, setUploadLogoImage] = useState(
    company ? company?.logo : null
  );

  const [isChange, setIsChange] = useState(false);

  const handleFileChange = useCallback(
    (event) => {
      setErrorMessage("");
      const file = event.target.files[0];

      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        const reader = new FileReader();

        reader.onload = () => {
          const base64 = btoa(reader.result);
          setUploadImage(file);
          setValue("logo", "data:image/png;base64," + base64);
          setUploadLogoImage(URL.createObjectURL(file));
        };

        setIsChange(true);

        reader.readAsBinaryString(file);
      } else {
        setErrorMessage("Arquivo não suportado");
      }
    },
    [setValue]
  );

  const coverImage = useMemo(() => {
    if (company?.logo && !isChange) {
      return `url(${company?.logo?.url})`;
    }

    if (uploadLogoImage && isChange) {
      setIsChange(false);
      return `url(${uploadLogoImage})`;
    }

    if (!company?.logo && !uploadLogoImage) {
      setIsChange(false);
      return `url(${EmptyImageLogo})`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.logo, uploadLogoImage]);

  const deleteImage = () => {
    setUploadImage(null);
    setUploadLogoImage(null);
  };

  return (
    <Form.Group
      className={`${className} ${uploadImage && "justify-content-start gap-3"}`}
    >
      <div className="d-flex gap-5">
        <div>
          <CoverImage
            className="mb-1"
            width={width}
            height={height}
            paddingBottom={paddingBottom}
            coverImage={coverImage}
          />
          {errorMessage === "Arquivo não suportado" && (
            <div className="d-flex align-items-center gap-1 mt-1">
              <AlertIcon size={10} />
              <ErrorMessage>Arquivo não suportado</ErrorMessage>
            </div>
          )}
        </div>

        {!uploadImage && (
          <div className="w-100">
            <InputGroup>
              <FormControlInput
                type="file"
                id="image-cnh-file-input"
                name="image-cnh-file-input"
                onChange={handleFileChange}
              />
              {errors?.logo?.type === "required" && (
                <Form.Text className="text-danger">
                  A logo é obrigatória
                </Form.Text>
              )}
            </InputGroup>
          </div>
        )}
      </div>

      {uploadImage && (
        <div className="d-flex align-items-center text-center">
          <DeleteCnhButton
            type="button"
            className="d-flex align-items-center justify-content-start px-0 gap-1"
            onClick={deleteImage}
          >
            <label>{uploadImage?.name}</label>
            <DeleteIconButton />
          </DeleteCnhButton>
        </div>
      )}
    </Form.Group>
  );
};

export default UploadCompanyLogo;
