import { useEffect, useMemo, useState } from "react";
import { Col } from "react-bootstrap";
import moment from "moment";
import { BsPinMap, BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { FaCalendarAlt, FaTruck } from "react-icons/fa";
import { GiHandTruck } from "react-icons/gi";
import { TiLocation, TiLocationArrow } from "react-icons/ti";

import { TravelsHistoryCard } from "./TravelsHistoryCard";

import {
  BorderCard,
  InfoItem,
  TravelsHistoryRow,
  TitleContainer,
  InfoTitle,
} from "./styles";

import { sortByLastTravelDate, sortedStatus } from "../../Utils/data";
import { AlertMessage } from "../AlertMessage";
import ButtonLink from "../ButtonLink/ButtonLink";
import { EmptyTableMessage } from "../EmptyTableMessage";

export const HistoryCard = ({ driver }) => {
  const [numToShow, setNumToShow] = useState(4);
  const [travelList, setTravelList] = useState([]);

  const travelHistoryList = useMemo(() => {
    if (driver?.viagens && driver?.viagens !== '{"message":"no data"}') {
      return JSON.parse(driver?.viagens);
    } else {
      return [];
    }
  }, [driver?.viagens]);

  const registeredCompanies = useMemo(() => {
    if (driver?.empresas_cadastrado) {
      return JSON.parse(driver?.empresas_cadastrado);
    } else {
      return [];
    }
  }, [driver?.empresas_cadastrado]);

  function consolidarViagens(companies, travels) {
    const consolidado =
      companies &&
      companies.length > 0 &&
      companies.map((company) => {
        const viagensEmpresa =
          travels &&
          Array.isArray(travels) &&
          travels.length > 0 &&
          travels.filter((v) => v?.cnpj === company?.cnpj);

        const dtUltimaViagem = viagensEmpresa
          ? viagensEmpresa.reduce(
              (max, v) =>
                new Date(max) > new Date(v?.dt_viagem) ? max : v?.dt_viagem,
              "Sem registro"
            )
          : "Sem registro";

        const quantidadeViagens = viagensEmpresa ? viagensEmpresa?.length : 0;

        return {
          nomefantasia: company?.nomefantasia,
          id: company?.id,
          cnpj: company?.cnpj,
          dt_ultima_viagem: dtUltimaViagem,
          quantidade_viagens: quantidadeViagens,
          status_motorista: company?.status_motorista,
        };
      });

    consolidado.sort((a, b) => {
      if (a?.quantidade_viagens > b?.quantidade_viagens) {
        return -1;
      } else if (a?.quantidade_viagens < b?.quantidade_viagens) {
        return 1;
      } else {
        return a.nomefantasia?.localeCompare(b?.nomefantasia);
      }
    });

    setTravelList(consolidado);
  }

  useEffect(() => {
    if (
      driver?.empresas_cadastrado &&
      driver?.empresas_cadastrado !== '{"message":"no data"}'
    ) {
      return consolidarViagens(registeredCompanies, travelHistoryList);
    }
  }, [
    driver?.empresas_cadastrado,
    driver?.viagens,
    registeredCompanies,
    travelHistoryList,
  ]);

  const showData = useMemo(() => {
    return travelList && travelList.slice(0, numToShow);
  }, [numToShow, travelList]);

  const handleShowMoreLess = () => {
    if (numToShow >= travelList?.length) {
      setNumToShow(4);
    }
  };

  const currentData = sortedStatus(showData);

  const lastTravel = useMemo(() => {
    if (travelList) {
      const list = travelHistoryList.filter(
        (travel) => travel !== "Sem registro"
      );
      const sort = sortByLastTravelDate(list);
      return sort[0];
    }
    return null;
  }, [travelHistoryList, travelList]);

  return (
    <BorderCard className="w-100 card d-flex flex-column p-1">
      <div className="text-center">
        <TitleContainer className="mb-3 d-flex">
          <InfoTitle className="text-start">
            HISTÓRICO DE VIAGENS / CADASTRO FLEX CONSULTA
          </InfoTitle>
        </TitleContainer>
      </div>
      {driver?.viagens && (
        <>
          <div className="p-2">
            <div className="d-xl-flex justify-content-between p-2">
              <div className=" d-flex flex-column gap-2 mb-3">
                <div className="d-flex align-items-center gap-1 mb-2">
                  <FaCalendarAlt size={12} />
                  <InfoItem className="text-start">Última viagem:</InfoItem>
                  <InfoItem className="ms-1 fw-bold">
                    {lastTravel
                      ? moment.utc(lastTravel?.dt_viagem).format("DD/MM/YYYY")
                      : "--"}
                  </InfoItem>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <FaTruck size={12} />
                  <InfoItem className="text-start">Transportadora:</InfoItem>
                  <InfoItem className="ms-1 fw-bold">
                    {lastTravel ? lastTravel?.nomefantasia : "--"}
                  </InfoItem>
                </div>
                <div className="d-xl-flex gap-3">
                  <div className="d-flex align-items-center gap-1 mb-2 mb-xl-0">
                    <TiLocationArrow size={15} />
                    <InfoItem className="text-start">Origem:</InfoItem>
                    <InfoItem className="ms-1 fw-bold">
                      {lastTravel ? lastTravel?.cidade_origem : "--"}
                    </InfoItem>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <TiLocation size={15} />
                    <InfoItem className="text-start">Destino:</InfoItem>
                    <InfoItem className="ms-1 fw-bold">
                      {lastTravel ? lastTravel?.cidade_destino : "--"}
                    </InfoItem>
                  </div>
                </div>
                {showData.find((data) => data?.status_motorista === 2) && (
                  <AlertMessage label="Há registro de bloqueio" />
                )}
              </div>
              <div className=" d-flex flex-column gap-2 mb-3">
                <div className="d-flex align-items-center gap-1">
                  <GiHandTruck size={15} />
                  <InfoItem className="text-start">
                    Quantidade total de transportadoras:
                  </InfoItem>
                  <InfoItem className="ms-1 fw-bold">
                    {driver?.empresas_cadastrado ? travelList.length : "0"}
                  </InfoItem>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <BsPinMap size={12} />
                  <InfoItem className="text-start">Total de viagens:</InfoItem>
                  <InfoItem className="ms-1 fw-bold">
                    {driver?.viagens && !travelHistoryList?.message
                      ? travelHistoryList?.length
                      : "0"}
                  </InfoItem>
                </div>
                {showData.find((data) => data?.status_motorista === 2) && (
                  <AlertMessage label="Há registro de bloqueio" />
                )}
              </div>
            </div>
            {showData && Array.isArray(showData) && showData.length > 0 && (
              <TravelsHistoryRow xs={1} lg={2} className="g-2 mb-4">
                {currentData.map((data) => (
                  <Col key={data?.cnpj}>
                    <TravelsHistoryCard travels={data} driver={driver} />
                  </Col>
                ))}
              </TravelsHistoryRow>
            )}
            {showData?.length < registeredCompanies?.length && (
              <div className="d-flex justify-content-end mb-2">
                <ButtonLink
                  fontSize="0.7rem"
                  buttoncolor="primary"
                  handleClick={() => setNumToShow((num) => num + 5)}
                  label="Mostrar mais"
                  secondIcon={<BsPlus size={20} />}
                />
              </div>
            )}
            {numToShow >= registeredCompanies?.length && (
              <div className="text-end">
                <div className="d-flex justify-content-end mb-2">
                  <ButtonLink
                    fontSize="0.7rem"
                    buttoncolor="primary"
                    handleClick={handleShowMoreLess}
                    label="Mostrar menos"
                    secondIcon={<BiMinus size={20} />}
                    className={`${
                      registeredCompanies &&
                      registeredCompanies?.length < 4 &&
                      "d-none"
                    }`}
                  />
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end  mb-2">
              <ButtonLink
                id="more-details"
                fontSize="0.7rem"
                link={`/motoristas-listar/historico-viagens/${driver?.id}`}
                label="Consultar histórico de viagens"
                secondIcon={<IoIosArrowForward />}
              />
            </div>
          </div>
        </>
      )}
      {!driver?.viagens && <EmptyTableMessage />}
    </BorderCard>
  );
};
