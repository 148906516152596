import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest from "../Shared/Utils/Request";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export const CompanyContext = createContext({
  companies: [],
  isLoading: false,
  isSubmitting: false,
  getCompanies: () => {},
  getCompany: () => null,
  addComapany: () => null,
  deleteCompany: () => null,
  updateCompany: () => null,
});

export const useCompanies = () => {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error("useCompanys must be within CompanyProvider");
  }

  return context;
};

export const CompanyProvider = ({ children }) => {
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isSubmitting, setIsSubmiting] = useState(false);

  const getCompanies = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await ApiRequest({
        path: "empresas",
        method: "GET",
      });

      setCompanies(response?.data);
    } catch (error) {
      setCompanies([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getCompany = useCallback(async (id) => {
    try {
      setIsLoading(true);
      const response = await ApiRequest({
        path: `empresas/${id}`,
        method: "GET",
      });

      setCompany(response?.data);
    } catch (error) {
      setCompany(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const addComapany = useCallback(
    async (data, navigate) => {
      try {
        setIsSubmiting(true);

        await ApiRequest({
          path: "empresas",
          method: "POST",
          data,
        });

        window.scrollTo(0, 0);

        const response = await getCompanies();

        navigate("/empresas");

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Empresa cadastrada com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Empresa não pode ser criada",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } finally {
        setIsLoading(false);
        setIsSubmiting(false);
      }
    },
    [getCompanies]
  );

  const deleteCompany = useCallback(
    async (id) => {
      try {
        setIsSubmiting(true);

        await ApiRequest({
          path: `empresas/${id}`,
          method: "DELETE",
        });

        await getCompanies();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Empresa excluída com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `${
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            "Essa Empresa não pode ser excluída!"
          }`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } finally {
        setIsSubmiting(false);
      }
    },
    [getCompanies]
  );

  const updateCompany = useCallback(
    async (id, data, navigate) => {
      try {
        setIsSubmiting(true);

        await ApiRequest({
          path: `empresas/${id}`,
          method: "PUT",
          data,
        });

        window.scrollTo(0, 0);

        const response = await getCompanies();

        if (navigate) {
          navigate("/empresas");
        }

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Empresa atualizada com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Empresa não pode ser atualizada",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } finally {
        setIsLoading(false);
        setIsSubmiting(false);
      }
    },
    [getCompanies]
  );

  const providerValue = useMemo(
    () => ({
      companies,
      company,
      isLoading,
      isSubmitting,
      getCompanies,
      getCompany,
      addComapany,
      deleteCompany,
      updateCompany,
    }),
    [
      companies,
      company,
      isLoading,
      isSubmitting,
      getCompanies,
      getCompany,
      addComapany,
      deleteCompany,
      updateCompany,
    ]
  );

  return (
    <CompanyContext.Provider value={providerValue}>
      {children}
    </CompanyContext.Provider>
  );
};
