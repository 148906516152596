import { FloatingLabel, Form } from "react-bootstrap";
import { RegisterItemForm } from "./styles";
import { SubmitButton } from "../../../../../Shared/Components/SubmitButton/SubmitButton";
import ButtonLink from "../../../../../Shared/Components/ButtonLink/ButtonLink";
import { OccurrenceFormContainer } from "../AddOccurrence/styles";

export const OccurencesForm = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
  isSubmitting,
  currentOccurence,
}) => {
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mt-1">
      <OccurrenceFormContainer className="d-flex flex-column gap-3">
        <Form.Group controlId="formOccurrenceName">
          <FloatingLabel
            controlId="formOccurrenceName"
            label="Nome da ocorrência"
          >
            <Form.Control
              {...register("nome", {
                required:
                  !currentOccurence &&
                  "O nome do tipo de ocorrência é obrigatório",
              })}
              size="sm"
              type="text"
              className="is-required"
              placeholder="Digite o nome da categoria"
              defaultValue={currentOccurence?.nome}
            />
            {errors?.nome && (
              <Form.Text className="text-danger">
                {errors?.nome?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="formOccurrenceDescription">
          <FloatingLabel
            controlId="formOccurrenceDescription"
            label="Digite uma descrição para o tipo de ocorrência"
          >
            <RegisterItemForm
              {...register("descricao")}
              size="sm"
              type="text"
              as="textarea"
              defaultValue={currentOccurence?.descricao}
            />
            {errors?.descricao && (
              <Form.Text className="text-danger">
                {errors?.descricao.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>
      </OccurrenceFormContainer>

      <div className="d-flex gap-2 justify-content-end">
        <SubmitButton
          labelButton="Salvar"
          isSubmitting={isSubmitting}
          fontSize="0.8rem"
        />
        <ButtonLink
          label="Cancelar"
          fontSize="0.8rem"
          link="/gestao-viagens/tipos-de-ocorrencias"
        />
      </div>
    </Form>
  );
};
