import { Card, Col, Image as ImageLogo, Row } from "react-bootstrap";
import FlexLogo from "../../../../../../../Assets/Images/flex-logo.png";
import { LicenceInformationCardContainer, PageTitle } from "../../styles";
import moment from "moment";

import SimilarityCard from "../../SimilarityCard";
import { useEffect, useMemo, useState } from "react";
import { VehicleInformationCard } from "./VehicleInformationCard";
import { DriverStatusAndInformationsCard } from "../../../../../../../Shared/Components/DriverStatusAndInformationsCard/DriverStatusAndInformationsCard";

import { JudicialProcess } from "../../../../../../../Shared/Components/JudicialProcess";
import { CriminalList } from "../../../../../../../Shared/Components/CriminalList";
import { ArrestWarrantWarningCard } from "../../../../../../../Shared/Components/ArrestWarrantWarningCard.jsx";

export const RegistrationInformationAndValidationsDriverTab = ({
  driver,
  loadingBiometricValidation,
  driverValidation,
  driverConsults,
  handleOpenVehicleTab,
  loadingCnhValidation,
  judicialProcess,
  judicialProcessLoading,
}) => {
  const [biometricValidation, setBiometricValidation] = useState(
    driverValidation?.validacao_biometria?.status === "1" &&
      driverValidation?.validacao_biometria?.resposta
  );

  const [cnhValidation, setCnhValidation] = useState(
    driverValidation?.validacao_cnh?.status === "1" &&
      driverValidation?.validacao_cnh?.resposta
  );

  useEffect(() => {
    if (
      driverValidation?.validacao_biometria?.status === "1" &&
      driverValidation?.validacao_biometria?.resposta
    ) {
      setBiometricValidation(driverValidation?.validacao_biometria?.resposta);
    } else {
      setBiometricValidation(null);
    }
  }, [
    driverValidation,
    driverValidation?.validacao_biometria?.resposta,
    driverValidation?.validacao_biometria?.status,
  ]);

  useEffect(() => {
    if (
      driverValidation?.validacao_cnh?.status === "1" &&
      driverValidation?.validacao_cnh?.resposta
    ) {
      setCnhValidation(driverValidation?.validacao_cnh?.resposta);
    } else {
      setCnhValidation(null);
    }
  }, [
    driverValidation,
    driverValidation?.validacao_cnh?.resposta,
    driverValidation?.validacao_cnh?.status,
  ]);

  const driverConsult =
    driverConsults &&
    driverConsults.find((driver) => driver?.posicao === "MOTORISTA");

  const informationsDriver =
    driverConsult && driverConsult?.consulta_cpf
      ? JSON.parse(driverConsult?.consulta_cpf)
      : driverConsult?.consulta_cnpj &&
        JSON.parse(driverConsult?.consulta_cnpj);

  const vehicleConsult =
    driverConsults &&
    driverConsults.find((vehicle) => vehicle?.posicao === "CAVALO");

  const informationsVehicle = useMemo(() => {
    if (vehicleConsult) {
      if (
        vehicleConsult?.consulta_cpf &&
        vehicleConsult?.consulta_cpf !== "null"
      ) {
        return JSON.parse(vehicleConsult?.consulta_cpf);
      }
      if (
        vehicleConsult?.consulta_cnpj &&
        vehicleConsult?.consulta_cnpj !== "null"
      ) {
        return JSON.parse(vehicleConsult?.consulta_cnpj);
      }
    }
  }, [vehicleConsult]);

  const trucksConsult = useMemo(
    () =>
      driverConsults &&
      driverConsults.filter(
        (consult) =>
          consult.posicao === "CARRETA1" ||
          consult.posicao === "CARRETA2" ||
          consult.posicao === "CARRETA3" ||
          consult.posicao === "CARRETA4"
      ),
    [driverConsults]
  );

  return (
    <Card className="p-2 border-0">
      <LicenceInformationCardContainer className="d-flex align-items-center justify-content-start mb-3 mt-3">
        <ImageLogo src={FlexLogo} width={30} height={30} />
        <PageTitle className="d-flex align-items-center mb-0">
          Análise Flex Consulta - Cadastro de motorista
        </PageTitle>
      </LicenceInformationCardContainer>
      <Row xs={1} xl={2} className="mb-3 d-flex g-3">
        <Col>
          {
            <DriverStatusAndInformationsCard
              status={
                informationsDriver &&
                informationsDriver?.data &&
                informationsDriver?.data.length > 0
                  ? informationsDriver?.data[0]?.situacao_cadastral
                  : "Não informado"
              }
              date={
                driver?.dt_criacao &&
                moment.utc(driver?.dt_criacao).format("DD/MM/YYYY")
              }
              cpf={driver?.cpf}
            />
          }
        </Col>
        {driver?.veiculo && (
          <Col>
            <VehicleInformationCard
              vehicle={driver?.veiculo}
              title="Cavalo"
              handleOpenVehicleTab={handleOpenVehicleTab}
              index="0"
              consult={informationsVehicle}
            />
          </Col>
        )}
      </Row>
      <Row className="g-3 mb-1 d-flex flex-column flex-md-row mb-3">
        {driver?.veiculo?.carretas &&
          driver?.veiculo?.carretas?.length > 0 &&
          driver?.veiculo?.carretas.map((truck, index) => {
            const consultItem = trucksConsult.find(
              (consultItem) => consultItem.posicao === `CARRETA${index + 1}`
            );

            const informationsTruck =
              consultItem &&
              consultItem?.consulta_cpf &&
              consultItem?.consulta_cpf !== "null"
                ? JSON.parse(consultItem?.consulta_cpf)
                : consultItem &&
                  consultItem?.consulta_cnpj &&
                  consultItem?.consulta_cnpj !== "null" &&
                  JSON.parse(consultItem?.consulta_cnpj);

            return (
              <Col
                key={index}
                xl={
                  driver?.veiculo?.carretas.length % 2 !== 0 &&
                  driver?.veiculo?.carretas[
                    driver?.veiculo?.carretas.length - 1
                  ] === truck
                    ? 12
                    : 6
                }
              >
                <VehicleInformationCard
                  vehicle={truck}
                  handleOpenVehicleTab={handleOpenVehicleTab}
                  title={`Carreta ${index + 1}`}
                  index={`${index + 1}`}
                  consult={informationsTruck}
                />
              </Col>
            );
          })}
      </Row>

      <Row className="d-flex g-3">
        <Col xs={12}>
          <ArrestWarrantWarningCard driverConsult={driverConsult} />
        </Col>
        <Col xs={12}>
          <CriminalList driverConsult={driverConsult} />
        </Col>
        <Col xs={12}>
          <JudicialProcess
            judicialProcess={judicialProcess}
            judicialProcessLoading={judicialProcessLoading}
          />
        </Col>

        <Col xs={12}>
          <SimilarityCard
            driver={driver}
            biometricValidation={biometricValidation}
            loadingCnhValidation={loadingCnhValidation}
            cnhValidation={cnhValidation}
            loadingBiometricValidation={loadingBiometricValidation}
          />
        </Col>
      </Row>
    </Card>
  );
};
