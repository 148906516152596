import Dashboard from "../../../../../Shared/layout";
import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { BorderCard, FlexValidationRow } from "./styles";
import { Card, Col } from "react-bootstrap";
import ValidationsList from "./ValidationsList";
import CNHValidationCard from "../../../../../Shared/Components/CNHValidationCard/CNHValidationCard";
import { useDriversValidation } from "../../../../../hooks/DriverValidationProvider";
import BiometricValidationCard from "../../../../../Shared/Components/BiometricValidationCard/BiometricValidationCard";

const FlexValidation = () => {
  const { id } = useParams();

  const {
    driverValidation,
    loadingDriverValidation,
    loadingBiometricValidation,
    getDriverValidation,
  } = useDriversValidation();

  useEffect(() => {
    getDriverValidation(id);
  }, [getDriverValidation, id]);

  const biometric = useMemo(() => {
    if (driverValidation?.validacao_biometria?.status === "1") {
      return driverValidation?.validacao_biometria?.resposta;
    } else {
      return null;
    }
  }, [
    driverValidation?.validacao_biometria?.resposta,
    driverValidation?.validacao_biometria?.status,
  ]);

  const cnhValidation = useMemo(() => {
    if (driverValidation?.validacao_cnh?.status === "1") {
      return driverValidation?.validacao_cnh?.resposta;
    } else {
      return null;
    }
  }, [
    driverValidation?.validacao_cnh?.resposta,
    driverValidation?.validacao_cnh?.status,
  ]);

  return (
    <Dashboard title="Análise da validação Flex">
      {loadingDriverValidation && <Loading />}

      {!loadingDriverValidation && (
        <FlexValidationRow xs={1} xl={2} className="mt-2 g-1">
          <Col xl={6}>
            <BorderCard className="text-center w-100 card d-flex flex-column h-100">
              <Card.Body className="text-center pb-0 mb-4 d-flex flex-1 flex-column">
                <div className="mb-5">
                  <BiometricValidationCard
                    biometricValidation={biometric}
                    loadingBiometricValidation={loadingBiometricValidation}
                    driverValidation={driverValidation}
                  />
                </div>
                <CNHValidationCard
                  cnhValidation={cnhValidation}
                  loadingDriverValidation={loadingDriverValidation}
                  driverValidation={driverValidation}
                />
              </Card.Body>
            </BorderCard>
          </Col>
          <Col xl={6}>
            <BorderCard className="text-center w-100 card d-flex flex-column h-100">
              <Card.Body className="text-center pb-0 mb-4">
                <ValidationsList
                  biometric={biometric}
                  cnhValidation={cnhValidation}
                />
              </Card.Body>
            </BorderCard>
          </Col>
        </FlexValidationRow>
      )}
    </Dashboard>
  );
};

export default FlexValidation;
