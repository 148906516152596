import styled from "styled-components";

export const QrContainer = styled.div`
  width: 90vw;
  height: 90vh;
  margin: 0 auto;
  position: relative;

  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > div {
    width: 100% !important;
    left: 0 !important;
  }

  > p {
    position: absolute;
    top: 5px;
    left: 15px;
    font-size: 1.1rem;
  }

  @media (min-width: 500px) {
    width: 60vw;
    height: 60vh;

    display: flex;
    flex-direction: column-reverse;

    > p {
      position: relative;
      top: 0;
      left: 0;
    }
  }
`;
