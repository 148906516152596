import { memo, useCallback, useState } from "react";
import { Tab } from "react-bootstrap";
import Dashboard from "../../../../Shared/layout";
import { StyledTabs } from "./styles";
import { LoadOrderManagement } from "./LoadOrderManagement";
import { ClosedLoadOrders } from "./Tabs/ClosedLoadOrders";
import { CompleteLoadOrders } from "./Tabs/CompleteLoadOrders";
import { IncompleteLoadOrders } from "./Tabs/IncompleteLoadOrders";
import { LoadOrderPendingCompletion } from "./Tabs/LoadOrderPendingCompletion";
import { OffRouteLoadOrders } from "./Tabs/OffRouteLoadOrders";

const TravelLoadOrder = () => {
  const [activeKey, setActiveKey] = useState("tab1");

  const handleActiveKey = useCallback(async (value) => {
    setActiveKey(value);
    await new Promise((resolve) => setTimeout(resolve, 200));
  }, []);

  return (
    <Dashboard>
      <StyledTabs
        activeKey={activeKey}
        className="mb-1 mt-3"
        onSelect={handleActiveKey}
      >
        <Tab eventKey="tab1" title="Gestão">
          <LoadOrderManagement handleActiveKey={handleActiveKey} />
        </Tab>
        <Tab eventKey="tab2" title="Liberadas">
          <CompleteLoadOrders />
        </Tab>

        <Tab eventKey="tab3" title="Bloqueadas">
          <IncompleteLoadOrders />
        </Tab>

        <Tab eventKey="tab4" title="Pendentes de finalização">
          <LoadOrderPendingCompletion />
        </Tab>

        <Tab eventKey="tab5" title="Finalizadas no mês">
          <ClosedLoadOrders />
        </Tab>

        <Tab eventKey="tab6" title="Fora de rota">
          <OffRouteLoadOrders />
        </Tab>
      </StyledTabs>
    </Dashboard>
  );
};

export default memo(TravelLoadOrder);
