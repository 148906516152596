import styled from "styled-components";
import { FaRegFolderOpen, FaClipboard } from "react-icons/fa";
import { Card } from "react-bootstrap";

export const Container = styled.div`
  padding: 1rem;
  border: 1px dashed var(--color-gray-10);
`

export const Item = styled.div`
padding: 0;
margin-bottom: 1rem;
display: flex;
align-items: center;

h4 {
  font-size: 0.875rem;
  color: black;
  display: flex;
  margin-left: 0.2rem;
  margin-bottom:0;
  text-align: left;
}

h3 {
  font-size: 0.875rem;
  display: flex;
  margin-left: 0.3rem;
  margin-bottom:0;
  color: var(--color-gray-11);
  text-align: left;
}
`

export const FolderIcon = styled(FaRegFolderOpen)`
 color: var(--color-gray-11);
`
export const ClipboardIcon = styled(FaClipboard)`
 color: var(--color-gray-11);
`
export const SubtitleIcon = styled.span`
  font-size: 0.875rem;
  color: var(--color-gray-11);
`

export const Title = styled.h1`
  font-size: 0.875rem;
   color: var(--color-gray-15);
  font-weight: bold;
  margin-left: 0.2rem;
  margin-bottom: 10px;
`

export const H4Status = styled.h4`
  color: ${({ status }) => (status === "REGULAR" || status === "Regular" || status === "Ativa" || status === "ATIVA") ? "#07b141" : "red"}!important;
`

export const SectionContainer = styled(Card)`
background-color: #FFFAFA;
  border: none;
`

export const TitleContainer = styled.div`
  background-color: var(--color-gray-2);
  padding: 10px;
`

export const InfoTitle = styled.span`
  color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;