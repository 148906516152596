import {
  ChangingItemForm,
  ContainerWrapper,
  DeleteButton,
  DeleteText,
  InputWrapper,
  LabelTitle,
  RegisterItemForm,
} from "./styles";

const ReportInconsistencyInput = ({
  handleInputChange,
  changingItem,
  register,
  fields,
  item,
  label,
  registerItem,
  value,
}) => {
  return (
    <div className="mt-4">
      {changingItem && (
        <ContainerWrapper className="mt-4">
          <InputWrapper className="mt-3">
            <LabelTitle className="d-flex align-items-center mb-0 mt-3">
              {label}
            </LabelTitle>
            <RegisterItemForm
              className="is-required"
              label={label}
              required={changingItem}
              {...register(registerItem, fields.isRequired)}
              as="textarea"
              defaultValue={`${label} com inconsistência`}
            />
          </InputWrapper>
          <DeleteButton
            onClick={() => handleInputChange(item)}
            title="Deletar"
            className="p-0 d-flex"
          >
            <DeleteText color="red">x Cancelar</DeleteText>
          </DeleteButton>
        </ContainerWrapper>
      )}
      {!changingItem && (
        <>
          <LabelTitle className="d-flex align-items-center mb-0 mt-3">
            {label}
          </LabelTitle>
          <ChangingItemForm
            className="is-required w-100"
            label={label}
            disabled
            as="textarea"
            defaultValue={value}
          />
        </>
      )}
    </div>
  );
};

export default ReportInconsistencyInput;
