import React from "react";
import Dashboard from "../../../Shared/layout";
import { Row, Col } from "react-bootstrap";
import { EmptyTableMessage } from "../../../Shared/Components/EmptyTableMessage";
import { AiOutlineCloseCircle } from "react-icons/ai";
import LinkCard from "../../../Shared/Components/LinkCard";

import {
  CompanyIcon,
  ConfigIcon,
  PefilIcon,
  SystemSettingsRow,
  UnitIcon,
  UserIcon,
} from "./styles";

export default function Configuracoes() {
  return (
    <>
      <Dashboard title="Configurações do sistema">
        {(!localStorage.getItem("companyManagementPermissionToRead") ||
          localStorage.getItem("companyManagementPermissionToRead") ===
            "false") &&
          (!localStorage.getItem("unitManagementPermissionToRead") ||
            localStorage.getItem("unitManagementPermissionToRead") ===
              "false") &&
          (!localStorage.getItem("perfilManagementPermissionToRead") ||
            localStorage.getItem("perfilManagementPermissionToRead") ===
              "false") &&
          (!localStorage.getItem("userManagementPermissionToRead") ||
            localStorage.getItem("userManagementPermissionToRead") ===
              "false") &&
          (!localStorage.getItem(
            localStorage.getItem("accessManagementPermissionToRead")
          ) ||
            localStorage.getItem(
              localStorage.getItem(
                localStorage.getItem("accessManagementPermissionToRead")
              )
            ) === "false") && (
            <Row className="mt-4">
              <Col>
                <EmptyTableMessage
                  label="Configurações do sistema não disponíveis"
                  icon={<AiOutlineCloseCircle />}
                />
              </Col>
            </Row>
          )}
        <SystemSettingsRow
          xs={1}
          md={2}
          lg={4}
          xl={6}
          id="container-cards-conf-sistema"
          className="d-flex mt-4 g-4"
        >
          {localStorage.getItem("companyManagementPermissionToRead") &&
            localStorage.getItem("companyManagementPermissionToRead") !==
              "false" && (
              <Col>
                <LinkCard
                  route="/empresas"
                  title="Empresas"
                  icon={<CompanyIcon />}
                />
              </Col>
            )}

          {localStorage.getItem("unitManagementPermissionToRead") &&
            localStorage.getItem("unitManagementPermissionToRead") !==
              "false" && (
              <Col>
                <LinkCard
                  route="/unidades"
                  title="Unidades"
                  icon={<UnitIcon />}
                />
              </Col>
            )}

          {localStorage.getItem("perfilManagementPermissionToRead") &&
            localStorage.getItem("perfilManagementPermissionToRead") !==
              "false" && (
              <Col>
                <LinkCard
                  route="/perfis"
                  title="Perfis de usuários"
                  icon={<PefilIcon />}
                />
              </Col>
            )}

          {localStorage.getItem("userManagementPermissionToRead") &&
            localStorage.getItem("userManagementPermissionToRead") !==
              "false" && (
              <Col>
                <LinkCard
                  route="/usuarios"
                  title="Usuários"
                  icon={<UserIcon />}
                />
              </Col>
            )}

          {localStorage.getItem("accessManagementPermissionToRead") &&
            localStorage.getItem("accessManagementPermissionToRead") !==
              "false" && (
              <Col>
                <LinkCard
                  route="/configuracoes-acesso"
                  title="Configurações de acesso"
                  icon={<ConfigIcon />}
                />
              </Col>
            )}
        </SystemSettingsRow>
      </Dashboard>
    </>
  );
}
