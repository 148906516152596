import React, { useEffect, useState, useCallback, useMemo } from "react";
import Dashboard from "../../../../Shared/layout";
import { useUsers } from "../../../../hooks/UserProvider";
import { usePerfis } from "../../../../hooks/PerfilProvider";
import { useUnits } from "../../../../hooks/UnitProvider";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { useCompanies } from "../../../../hooks/CompanyProvider";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import UserList from "./UserList";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import { AlertComponent as Alert } from "../../../../Shared/Components/Alerts";
import { Col, Row } from "react-bootstrap";
import Search from "../../../../Shared/Components/Search/Search";
import {
  formatNameString,
  sortedCompanies,
  sortedLabels,
  sortedNames,
} from "../../../../Shared/Utils/data";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import { AiOutlineCloseCircle, AiOutlinePlus } from "react-icons/ai";
import Select from "react-select";
import { UserListContainer } from "./styles";

export default function UsuariosComponent() {
  const [isAllSelect, setIsAllSelect] = useState(false);
  const [companyIdSelect, setCompanyIdSelect] = useState(null);

  const {
    users,
    isLoading,
    showAlert,
    msgErro,
    variantAlert,
    isSubmitting,
    getUsers,
    getUser,
    deleteUser,
    updateUser,
  } = useUsers();

  const { perfis, getPerfis } = usePerfis();
  const { units, getUnits, getUnitsByIdCompany } = useUnits();
  const { getCompanies, companies } = useCompanies();

  const [sortedUsers, setSortedUsers] = useState([]);
  const [searchUser, setSearchUser] = useState("");

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    getPerfis();
  }, [getPerfis]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    getUnits();
  }, [getUnits]);

  useEffect(() => {
    setSortedUsers(sortedNames(users));
  }, [users]);

  const handleSearchChange = (event) => {
    setSearchUser(event && event !== "" ? event.target.value : "");
  };

  const getUsersByCompany = useCallback(
    (id) => {
      const currentCompany = companies.find((company) => company.id === id);

      const arrayDeUsuarios = users.filter(
        (user) => user.nome_empresa === currentCompany.nome_fantasia
      );

      setSortedUsers(
        arrayDeUsuarios.sort((a, b) => a.nome.localeCompare(b.nome))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companies, users]
  );

  const handleCompanySelect = useCallback(
    async (e) => {
      const companyId = parseInt(e.value);
      setCompanyIdSelect(e.label);

      if (isNaN(companyId) && e.label === "-- Todas as empresas --") {
        setIsAllSelect(false);
        getUnits();
        setSortedUsers(users.sort((a, b) => a.nome.localeCompare(b.nome)));
      }

      if (isNaN(companyId) && e.label === "Sem empresa vinculada") {
        setIsAllSelect(false);
        // getUnits();
        getUnitsByIdCompany(null);

        const filtered = users
          .map((usuario) => (usuario.nome_empresa ? null : usuario))
          .filter((usuario) => usuario);

        setSortedUsers(filtered);
      }

      if (!isNaN(companyId)) {
        setIsAllSelect(true);
        if (companyId === null) {
          getUnits();
          setSortedUsers(users.sort((a, b) => a.nome.localeCompare(b.nome)));
        } else {
          getUnitsByIdCompany(companyId);
          getUsersByCompany(companyId);
        }
      }
    },
    [getUnits, getUnitsByIdCompany, getUsersByCompany, users]
  );

  const handleUnitSelect = useCallback(
    (e) => {
      const idUnit = parseInt(e.value);

      if (isNaN(idUnit) && e.label === "Sem unidade vinculada") {
        const filtered = users
          .map((usuario) => (usuario.id_unidade ? null : usuario))
          .filter((usuario) => usuario);

        return setSortedUsers(filtered);
      }

      if (isNaN(idUnit) && e.label === "-- Todas as unidades --") {
        return setSortedUsers(
          users.sort((a, b) => a.nome.localeCompare(b.nome))
        );
      }

      if (!isNaN(idUnit)) {
        return setSortedUsers(
          users
            .filter((user) => user.id_unidade === idUnit)
            .sort((a, b) => a.nome.localeCompare(b.nome))
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users, handleCompanySelect]
  );

  const filteredUsers = Array.isArray(sortedUsers)
    ? sortedUsers.filter((user) => {
        return user.nome.toLowerCase().includes(searchUser.toLowerCase());
      })
    : [sortedUsers];

  const companiesOptions = useMemo(() => {
    const options = [
      { value: "withoutCompany", nome_fantasia: "Sem empresa vinculada" },
      ...companies.map((company) => ({
        value: company.id,
        nome_fantasia: formatNameString(company.nome_fantasia),
      })),
    ];

    return [
      { value: "allCompanies", label: "-- Todas as empresas --" },
      ...sortedCompanies(options).map((company) => ({
        value: company.value,
        label: formatNameString(company.nome_fantasia),
      })),
    ];
  }, [companies]);

  const unitsOptions = useMemo(() => {
    if (companyIdSelect === "Sem empresa vinculada") {
      return [];
    } else if (!isAllSelect) {
      const options = [
        { value: "withoutUnits", label: "Sem unidade vinculada" },
        ...units.map((unit) => ({
          value: unit.id,
          label: formatNameString(unit.nome),
        })),
      ];

      return [
        { value: "allUnits", label: "-- Todas as unidades --" },
        ...sortedLabels(options).map((unit) => ({
          value: unit.value,
          label: formatNameString(unit.label),
        })),
      ];
    } else {
      return sortedNames(units).map((unit) => ({
        value: unit.id,
        label: formatNameString(unit.nome),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllSelect, units, companyIdSelect, handleCompanySelect]);

  const handleDeleteUnit = useCallback(
    (user) => {
      Swal.fire({
        icon: "warning",
        title: "Você tem certeza absoluta?",
        html: `
      <div>
        <p>Essa ação não pode ser desfeita.</p>
        <p>Digite <strong style="background-color: red; color: white">${user?.nome}</strong> para confirmar.</p>
      </div>
    `,
        input: "text",
        inputLabel: user?.nome,
        inputPlaceholder: "Digite o nome do usuário",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Eu entendo as consequências, remover usuário",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        focusCancel: true,
        preConfirm: (value) => {
          if (value === user?.nome) {
            deleteUser(user?.id);
            getUsers();
            return true;
          } else {
            Swal.showValidationMessage(
              "O nome da unidade não corresponde. Tente novamente."
            );
            return false;
          }
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
    [deleteUser, getUsers]
  );

  return (
    <Dashboard title="Usuários">
      <Row className="mb-1 d-flex justify-content-start mt-3">
        <Col lg={8} md={12} className="d-md-flex gap-3 align-items-center">
          <Select
            options={companiesOptions}
            className="mb-3 mb-md-0"
            onChange={handleCompanySelect}
            placeholder="-- Selecionar empresas --"
          />

          <Select
            options={unitsOptions}
            onChange={handleUnitSelect}
            disabled={units.length === 0}
            placeholder="-- Selecionar unidade --"
          />
        </Col>
      </Row>
      <Row
        xs={1}
        lg={2}
        className="mb-3 d-flex justify-content-between align-items-end"
      >
        {localStorage.getItem("unitManagementPermissionToCreate") &&
          localStorage.getItem("unitManagementPermissionToCreate") !==
            "false" && (
            <Col lg={4} md={6} xl={3}>
              <ButtonLink
                label="Adicionar novo usuário"
                link="/usuarios-adicionar"
                icon={<AiOutlinePlus />}
              />
            </Col>
          )}

        <Col md={6} lg={4} xl={3} className="ps-lg-0">
          <Search
            label="Pesquisar usuário"
            searchValue={searchUser}
            handleSearchChange={handleSearchChange}
          />
        </Col>
      </Row>

      <UserListContainer>
        {isLoading && <Loading />}
        {!isLoading &&
          (!localStorage.getItem("unitManagementPermissionToRead") ||
            localStorage.getItem("unitManagementPermissionToRead") ===
              "false") && (
            <EmptyTableMessage
              label="Listagem de usuários não disponível para o seu usuário"
              icon={<AiOutlineCloseCircle />}
            />
          )}
        {!isLoading &&
          filteredUsers.length === 0 &&
          localStorage.getItem("unitManagementPermissionToRead") &&
          localStorage.getItem("unitManagementPermissionToRead") !==
            "false" && <EmptyTableMessage />}
        {!isLoading &&
          filteredUsers.length > 0 &&
          localStorage.getItem("unitManagementPermissionToRead") &&
          localStorage.getItem("unitManagementPermissionToRead") !==
            "false" && (
            <UserList
              users={filteredUsers}
              getUser={getUser}
              handleDeleteUnit={handleDeleteUnit}
              perfis={sortedNames(perfis)}
              units={sortedNames(units)}
              unitManagementPermissionToUpdate={localStorage.getItem(
                "unitManagementPermissionToUpdate"
              )}
              unitManagementPermissionToDelete={localStorage.getItem(
                "unitManagementPermissionToDelete"
              )}
              updateUser={updateUser}
            />
          )}
      </UserListContainer>

      {showAlert && (
        <Alert
          message={msgErro}
          variant={variantAlert}
          showErrorMsg={isSubmitting}
        />
      )}
    </Dashboard>
  );
}
