import { Fragment, useMemo, useState } from "react";
import moment from "moment";
import { Col, Row, Card } from "react-bootstrap";

import { LicenceInformationCardContainer, PageTitle } from "./styles";
import { VehicleInformationCard } from "./VehicleInformationCard";
import SimilarityCard from "../../SimilarityCard";
import { ErrorModal } from "../../../../../../../Shared/Components/ErrorModal";
import { Loading } from "../../../../../../../Shared/Components/Loading/Loading";
import { CriminalList } from "../../../../../../../Shared/Components/CriminalList";
import { JudicialProcess } from "../../../../../../../Shared/Components/JudicialProcess";
import { WarningCard } from "../../../../../../../Shared/Components/WarningCard/WarningCard";
import { ArrestWarrantWarningCard } from "../../../../../../../Shared/Components/ArrestWarrantWarningCard.jsx";
import { DriverStatusAndInformationsCard } from "../../../../../../../Shared/Components/DriverStatusAndInformationsCard/DriverStatusAndInformationsCard";
import { TemporaryAnaliticMessage } from "../../../../../../../Shared/Components/TemporaryAnaliticMessage/TemporaryAnaliticMessage";

export const RegistrationInformationAndValidationsTab = ({
  errors,
  tempRegister,
  cnhValidation,
  loadingConsult,
  showErrorModal,
  driverConsults,
  judicialProcess,
  isloadingConsult,
  driverValidation,
  setShowErrorModal,
  driverInformation,
  biometricValidation,
  handleOpenVehicleTab,
  judicialProcessLoading,
}) => {
  const [selectedError, setSelectedError] = useState(null);

  const temporaryDriver = useMemo(
    () =>
      tempRegister &&
      tempRegister?.dados_formulario_motorista &&
      JSON.parse(tempRegister?.dados_formulario_motorista),
    [tempRegister]
  );

  const driver =
    driverConsults &&
    driverConsults.find((driver) => driver?.posicao === "MOTORISTA");

  const informationsDriver =
    driver && driver?.consulta_cpf && driver?.consulta_cpf !== "null"
      ? JSON.parse(driver?.consulta_cpf)
      : driver &&
        driver?.consulta_cnpj &&
        driver?.consulta_cnpj !== "null" &&
        JSON.parse(driver?.consulta_cnpj);

  const vehicleConsult =
    driverConsults &&
    driverConsults.find((vehicle) => vehicle?.posicao === "CAVALO");

  const informationsVehicle =
    vehicleConsult &&
    vehicleConsult?.consulta_cpf &&
    vehicleConsult?.consulta_cpf !== "null"
      ? JSON.parse(vehicleConsult?.consulta_cpf)
      : vehicleConsult &&
        vehicleConsult?.consulta_cnpj &&
        vehicleConsult?.consulta_cnpj !== "null" &&
        JSON.parse(vehicleConsult?.consulta_cnpj);

  const trucksConsult = useMemo(
    () =>
      driverConsults &&
      driverConsults.filter(
        (consult) =>
          consult.posicao === "CARRETA1" ||
          consult.posicao === "CARRETA2" ||
          consult.posicao === "CARRETA3" ||
          consult.posicao === "CARRETA4"
      ),
    [driverConsults]
  );

  const temporaryVehicleInfos = useMemo(
    () =>
      tempRegister?.dados_formulario_veiculo &&
      JSON.parse(tempRegister?.dados_formulario_veiculo),
    [tempRegister?.dados_formulario_veiculo]
  );

  const temporaryTrucksInfos = useMemo(
    () =>
      tempRegister?.dados_formulario_carretas &&
      JSON.parse(tempRegister?.dados_formulario_carretas),
    [tempRegister?.dados_formulario_carretas]
  );

  const ownerInformations = useMemo(
    () =>
      tempRegister?.anexos_proprietario &&
      JSON.parse(tempRegister?.anexos_proprietario),
    [tempRegister?.anexos_proprietario]
  );

  const handleSeeMore = (error) => {
    setShowErrorModal(true);
    setSelectedError(error);
  };

  const vehicleAttachment = useMemo(
    () =>
      tempRegister &&
      tempRegister?.anexos_veiculo &&
      JSON.parse(tempRegister?.anexos_veiculo),
    [tempRegister]
  );

  const truckAttachment = useMemo(
    () =>
      tempRegister &&
      tempRegister?.anexos_carretas &&
      JSON.parse(tempRegister?.anexos_carretas),
    [tempRegister]
  );

  const ownerForm = useMemo(
    () =>
      tempRegister?.dados_formulario_proprietario &&
      JSON.parse(tempRegister?.dados_formulario_proprietario),
    [tempRegister]
  );

  const errorsList = useMemo(
    () => errors.filter((error) => error.status === true),
    [errors]
  );

  return (
    <Card.Body>
      <LicenceInformationCardContainer className="d-flex align-items-center justify-content-between mb-3 ps-2">
        <PageTitle className="d-flex align-items-center fw-bold">
          Pré-cadastro do motorista
        </PageTitle>

        <TemporaryAnaliticMessage
          judicialProcess={judicialProcess}
          driver={driverInformation}
          isloadingConsult={isloadingConsult}
        />
      </LicenceInformationCardContainer>

      {loadingConsult && <Loading />}

      {!isloadingConsult &&
        driverInformation?.status_cadastro !== "1r" &&
        driverInformation?.status_cadastro === "5r" &&
        driverInformation?.processos_cadastro_concluido &&
        driverConsults &&
        driverConsults?.length > 0 &&
        errorsList &&
        errorsList.length > 0 &&
        errorsList.map((err, index) => (
          <WarningCard
            label={err?.label}
            key={index}
            handleSeeMore={() => handleSeeMore(err)}
            hasButton
          />
        ))}

      {!loadingConsult && (
        <>
          <Row xs={1} xl={2} className="mb-2 d-xl-flex g-2">
            <Col>
              <DriverStatusAndInformationsCard
                status={
                  !isloadingConsult &&
                  informationsDriver?.data &&
                  informationsDriver?.data.length > 0
                    ? informationsDriver?.data[0]?.situacao_cadastral
                    : "Em análise"
                }
                date={
                  tempRegister?.createdAt &&
                  moment.utc(tempRegister?.createdAt).format("DD/MM/YYYY")
                }
                driverConsult={driver}
                ownerStatus={
                  ownerForm &&
                  !ownerForm?.cpfProprietarioIgualCpfVeiculo &&
                  ownerForm?.proprietarioIgualMotorista === "s"
                }
                cpf={driverInformation?.cpf}
              />
            </Col>

            {temporaryVehicleInfos && (
              <Col>
                <VehicleInformationCard
                  informationsVehicle={informationsVehicle}
                  temporaryVehicleInfos={temporaryVehicleInfos}
                  title="Cavalo"
                  handleOpenVehicleTab={handleOpenVehicleTab}
                  index="0"
                  driverConsults={driverConsults}
                  ownerInformations={ownerInformations}
                  vehicleAttachment={vehicleAttachment}
                  driverInformation={driverInformation}
                  isloadingConsult={isloadingConsult}
                />
              </Col>
            )}
          </Row>
          <Row className="g-2 d-flex flex-column flex-md-row">
            {temporaryTrucksInfos &&
              Object.keys(temporaryTrucksInfos).map((truck, index) => {
                const consultItem = trucksConsult.find(
                  (consultItem) => consultItem.posicao === `CARRETA${index + 1}`
                );

                const informationsTruck =
                  consultItem &&
                  consultItem?.consulta_cpf &&
                  consultItem?.consulta_cpf !== "null"
                    ? JSON.parse(consultItem?.consulta_cpf)
                    : consultItem &&
                      consultItem?.consulta_cnpj &&
                      consultItem?.consulta_cnpj !== "null" &&
                      JSON.parse(consultItem?.consulta_cnpj);

                return (
                  <Fragment key={index}>
                    {temporaryTrucksInfos[truck]?.dados_ocr && (
                      <Col
                        key={index}
                        xl={
                          Object.keys(temporaryTrucksInfos).length % 2 !== 0 &&
                          Object.keys(temporaryTrucksInfos)[
                            Object.keys(temporaryTrucksInfos).length - 1
                          ] === truck
                            ? 12
                            : 6
                        }
                      >
                        <VehicleInformationCard
                          informationsVehicle={informationsTruck}
                          temporaryVehicleInfos={temporaryTrucksInfos[truck]}
                          vehicleConsult={consultItem}
                          title={`Carreta ${index + 1}`}
                          handleOpenVehicleTab={handleOpenVehicleTab}
                          index={`${index + 1}`}
                          driverConsults={driverConsults}
                          vehicleAttachment={truckAttachment[truck]}
                          driverInformation={driverInformation}
                          isloadingConsult={isloadingConsult}
                        />
                      </Col>
                    )}
                  </Fragment>
                );
              })}
          </Row>
        </>
      )}

      {driverInformation?.status_cadastro === "5r" &&
        driverInformation?.processos_cadastro_concluido === true &&
        !isloadingConsult && (
          <Row className="g-3 mt-3">
            <Col xs={12}>
              <ArrestWarrantWarningCard driverConsult={driver} />
            </Col>
            <Col xs={12}>
              <CriminalList driverConsult={driver} />
            </Col>
            <Col xs={12}>
              <JudicialProcess
                judicialProcess={judicialProcess}
                judicialProcessLoading={judicialProcessLoading}
              />
            </Col>
            <Col>
              <SimilarityCard
                driver={temporaryDriver}
                biometricValidation={biometricValidation}
                cnhValidation={cnhValidation}
                loadingConsult={loadingConsult}
                driverValidations={driverValidation}
              />
            </Col>
          </Row>
        )}
      <ErrorModal
        show={showErrorModal}
        handleClose={() => setShowErrorModal(false)}
        error={selectedError}
      />
    </Card.Body>
  );
};
