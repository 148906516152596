import { Spinner } from "react-bootstrap";
import { useTravels } from "../../../../../hooks/TravelProvider";
import { SearchLoaderOrder } from "../SearchLoaderOrder/SearchLoaderOrder";
import TravelLoadOrderList from "../TravelLoadOrderList";
import { sortByDateDescending } from "../../../../../Shared/Utils/data";

import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";
import { useEffect, useState } from "react";
import TablePagination from "../../../../../Shared/Components/PaginationTable/PaginationTable";
import { TabContainer } from "./styles";

export const IncompleteLoadOrders = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    incompleteLoadOrders,
    totalPagesOfIncompleteLoadOrders,
    isLoading,
    getLoadOrder,
    getIncompleteLoadOrders,
  } = useTravels();

  const handlePageChange = (event) => {
    setCurrentPage(event);
  };

  useEffect(() => {
    getIncompleteLoadOrders(currentPage);
  }, [currentPage, getIncompleteLoadOrders]);

  return (
    <div className="d-flex flex-column flex-grow-1">
      <TabContainer>
        <SearchLoaderOrder getList={getIncompleteLoadOrders} />
        {isLoading && (
          <div className="d-flex justify-content-center">
            <Spinner size="sm" animation="border" variant="primary" />
          </div>
        )}
        {!isLoading &&
          Array.isArray(incompleteLoadOrders) &&
          incompleteLoadOrders.length > 0 && (
            <>
              <TravelLoadOrderList
                getLoadOrder={getLoadOrder}
                travelLoadOrderList={sortByDateDescending(incompleteLoadOrders)}
              />
            </>
          )}
        {(!incompleteLoadOrders || incompleteLoadOrders.length === 0) &&
          !isLoading && (
            <div className="mt-3">
              <EmptyTableMessage label="Não há registro de OC bloqueada" />
            </div>
          )}
      </TabContainer>
      {!isLoading &&
        Array.isArray(incompleteLoadOrders) &&
        incompleteLoadOrders.length > 0 && (
          <div className="mt-2 ps-2">
            <TablePagination
              currentPage={currentPage}
              totalPages={totalPagesOfIncompleteLoadOrders}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
    </div>
  );
};
