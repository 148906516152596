import { memo, useCallback, useEffect, useState } from "react";
import { Alert, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { FaDotCircle, FaWhatsapp } from "react-icons/fa";

import {
  InfoTitle,
  ListCTeContainer,
  ScrollSection,
  SectionContainer,
  TitleContainer,
  WhatsappButton,
} from "./styles";

import { Logs } from "./Logs";
import { ManifestEventsCard } from "./ManifestEventsCard";
import { ManifestInformations } from "./ManifestInformations";
import Dashboard from "../../../../Shared/layout";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import { useManifest } from "../../../../hooks/ManifestiesProvider";
import ImageModal from "../../../../Shared/Components/ImageModal/ImageModal";
import ApiRequest from "../../../../Shared/Utils/Request";
import { Events } from "./Events";

const MDFeDetails = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { manifest, isLoading, getManifest, postNotify } = useManifest();

  const { id } = useParams();

  useEffect(() => {
    getManifest(id);
  }, [getManifest, id]);

  const handleSendMessage = useCallback(() => {
    Swal.fire({
      icon: "warning",
      title: "Reenviar link para encerramento da MDF-e?",
      showCancelButton: true,
      confirmButtonColor: "#198754",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        postNotify(manifest?.manif_cod);
      }
    });
  }, [manifest?.manif_cod, postNotify]);

  const handleSelectImage = useCallback(async (image) => {
    try {
      setOpenModal(true);
      setIsLoadingImage(true);
      const data = {
        key: image,
        tipo: "BUCKET_CONHECIMENTOS",
      };

      const response = await ApiRequest({
        path: "get-image",
        method: "POST",
        data,
      });

      setSelectedImage(response?.data);
    } catch (error) {
      setSelectedImage(null);
    } finally {
      setIsLoadingImage(false);
    }
  }, []);

  const handleClose = useCallback(() => {
    setOpenModal(false);
    setSelectedImage(null);
  }, []);

  return (
    <Dashboard>
      {isLoading && <Loading />}
      {!isLoading && manifest && (
        <>
          {manifest?.manif_status_mdfe === "8" && (
            <Alert variant="warning">
              Erro ao encerrar MDF-e, nova tentativa a cada 30 minutos!
            </Alert>
          )}
          <ScrollSection>
            <SectionContainer className="text-start p-0 mb-3 mt-3">
              <TitleContainer className="mb-3 d-flex">
                <InfoTitle className="text-start">INFORMAÇÕES GERAIS</InfoTitle>
              </TitleContainer>
              <ManifestInformations manifest={manifest} />
            </SectionContainer>

            <SectionContainer className="text-start p-0 mb-3 mt-3">
              <TitleContainer className="mb-3 d-flex">
                <InfoTitle className="text-start">LISTA CT-e</InfoTitle>
              </TitleContainer>
              <div className="d-flex gap-1 align-items-center px-3">
                <FaDotCircle size={12} />
                <span>Total CT-e:</span>
                <strong>{manifest?.conhecimentos.length}</strong>
              </div>
              <div className="p-2">
                {(!manifest.conhecimentos ||
                  (Array.isArray(manifest.conhecimentos) &&
                    manifest.conhecimentos.length === 0)) && (
                  <div>
                    <EmptyTableMessage label="Não há registro de eventos para este MDF-e" />
                  </div>
                )}
                {Array.isArray(manifest.conhecimentos) &&
                  manifest.conhecimentos.length > 0 && (
                    <ListCTeContainer className="p-2 g-2" xs={1}>
                      {manifest.conhecimentos.map((cte) => (
                        <Col key={cte.id}>
                          <ManifestEventsCard
                            cte={cte}
                            handleSelectImage={handleSelectImage}
                          />
                        </Col>
                      ))}
                    </ListCTeContainer>
                  )}
              </div>
            </SectionContainer>

            <SectionContainer className="text-start p-0 mb-3 mt-3">
              <TitleContainer className="mb-3 d-flex">
                <InfoTitle className="text-start">EVENTOS</InfoTitle>
              </TitleContainer>
              <div className="p-2">
                <Events events={manifest?.eventos} />
              </div>
            </SectionContainer>

            <SectionContainer className="text-start p-0 mb-3 mt-3">
              <TitleContainer className="mb-3 d-flex">
                <InfoTitle className="text-start">LOGS DO PROCESSO</InfoTitle>
              </TitleContainer>
              <div className="p-2">
                <Logs
                  logs={manifest?.logs?.sort((a, b) => {
                    return new Date(b.created) - new Date(a.created);
                  })}
                />
              </div>
            </SectionContainer>
          </ScrollSection>
          <div className="d-flex gap-2 align-items-center justify-content-end p-2">
            {manifest?.manif_status_mdfe === "2" && (
              <WhatsappButton onClick={handleSendMessage}>
                <FaWhatsapp /> Reenviar whatsapp ao motorista
              </WhatsappButton>
            )}

            <ButtonLink
              fontSize="0.875rem"
              link={"/gestao-regularizacao-servico-transporte/lista"}
              label="Fechar"
            />
          </div>
        </>
      )}
      <ImageModal
        image={selectedImage}
        show={openModal}
        handleClose={handleClose}
        title="Comprovante CT-e"
        isLoadingImage={isLoadingImage}
      />
    </Dashboard>
  );
};

export default memo(MDFeDetails);
