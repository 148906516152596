import moment from "moment";
import { IoIosArrowForward } from "react-icons/io";

import { CardContainer, ItemContainer, ItemTitle, ItemValue } from "./styles";
import ButtonLink from "../ButtonLink/ButtonLink";
import { useParams } from "react-router-dom";
import { formatCnjnumber, formatNameString } from "../../Utils/data";
import { WarningCard } from "../WarningCard/WarningCard";

const Item = ({ label, value, statusColor }) => {
  return (
    <ItemContainer>
      <ItemTitle>{label}:</ItemTitle>
      <ItemValue statusColor={statusColor}>{value}</ItemValue>
    </ItemContainer>
  );
};

export const JudicialProcessCard = ({ process, data }) => {
  const { id } = useParams();

  return (
    <CardContainer hasborder="true" className="p-2 gap-2 d-flex flex-column">
      <div className="d-lg-flex justify-content-between">
        <Item
          label="Processo n°"
          value={
            process?.numeroProcessoUnico
              ? formatCnjnumber(process?.numeroProcessoUnico)
              : "--"
          }
        />
      </div>
      <div className="d-flex gap-3">
        <Item
          label="Data abertura"
          value={
            process?.dataDistribuicao
              ? moment.utc(process.dataDistribuicao).format("DD/MM/YYYY HH:mm")
              : "--"
          }
        />
        <Item label="UF" value={process?.uf ?? "--"} />
      </div>
      <Item
        label="Descrição"
        className={`${process?.tipo_processo?.atencao && "text-danger"}`}
        value={
          process?.classeProcessual?.codigoCNJ
            ? `${process?.classeProcessual?.codigoCNJ} - ${formatNameString(
                process?.classeProcessual?.nome
              )}`
            : formatNameString(process?.classeProcessual?.nome)
        }
      />

      <Item label="Atualizado em" value={data} />

      <div className="d-flex justify-content-end">
        <ButtonLink
          link={`/motoristas/processos-judiciais/${id}/${process?.numeroProcessoUnico}`}
          label="Ver mais"
          secondIcon={<IoIosArrowForward />}
          fontSize="0.7rem"
        />
      </div>

      {process?.tipo_processo?.atencao && (
        <WarningCard label="Processo requer atenção" />
      )}
    </CardContainer>
  );
};
