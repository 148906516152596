import React, { useEffect } from "react";
import Dashboard from "../../../../Shared/layout";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormPerfil from "./FormPerfil";
import { usePerfis } from "../../../../hooks/PerfilProvider";
import { useScopes } from "../../../../hooks/ScopeProvider";

export default function PerfisComponent() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const { addPerfil, isSubmitting } = usePerfis();

  const { scopes, getScopes } = useScopes();

  const onSubmit = async (data) => {
    data.escopo = parseInt(data.escopo);

    addPerfil(data, navigate);
  };

  useEffect(() => {
    getScopes();
  }, [getScopes]);

  return (
    <Dashboard title="Adicionar novo perfil">
      <FormPerfil
        handleSubmit={handleSubmit}
        register={register}
        errors={errors}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        scopes={scopes}
      />
    </Dashboard>
  );
}
