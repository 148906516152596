import { AiOutlinePlus } from "react-icons/ai";

import { PageTitle } from "./styles";
import ButtonLink from "../../../../../../Shared/Components/ButtonLink/ButtonLink";

export const AccortionTitle = ({
  handleAddTruck,
  setShowTruck,
  visibleTrucks,
}) => {
  return (
    <div className="d-flex justify-content-between align-items-start p-3">
      <PageTitle className="d-flex align-items-center mb-0">
        Cadastro das carretas
      </PageTitle>
      {Array.isArray(visibleTrucks) && visibleTrucks.length <= 3 && (
        <ButtonLink
          label="Adicionar carreta"
          icon={<AiOutlinePlus />}
          buttoncolor="primary"
          handleClick={handleAddTruck}
          setShow={setShowTruck}
        />
      )}
    </div>
  );
};
