import { Button, Card, Form, FormControl } from "react-bootstrap"
import styled from "styled-components"
import { FiAlertTriangle } from "react-icons/fi";
import { FaTrashAlt } from "react-icons/fa";

export const FormControlInput = styled(FormControl)`
font-size: 0.7rem;
display: flex;
`


export const DeleteCnhButton = styled(Button)`
  transition: transform 300ms;
  background-color: transparent;
  border: none;
  color: var(--color-gray-15);
  font-size: 0.7;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
    background-color: transparent;
    color: var(--color-gray-15);
    border: none;
  }

  label {
    color: var(--color-gray-15);
    font-size: 0.8rem;
  }
`

export const DeleteIconButton = styled(FaTrashAlt)`
color: var(--color-gray-15);
font-size: 0.8rem;

&:hover {
    background-color: transparent;
    color: var(--color-gray-15);
    border: none;
  }
`

export const FormLabelText = styled(Form.Label)`
color: var(--color-gray-12);
`
export const CoverImage = styled.div`
  background-size: cover;
  background-position: center center;
  border-radius: 8px;

  width: ${props => props.width};
  height: ${props => props.height};
  padding-bottom: ${props => props.paddingBotton};
  background-image: ${props => props.coverImage};
`

export const ImageTitle = styled.p`
font-size: 0.8rem;
color: var(--color-gray-11);

`

export const BorderCard = styled(Card)`
  &:hover {
    z-index: 1;
    box-shadow: 0 0 5px;
  }

  h3 {
    font-size: 1rem;
    font-weight: normal;

  }

  h4{
    font-size: 0.7rem;
    color: var(--color-gray-11);
  }

  h5{
    font-size: 0.7rem;
    color: var(--color-gray-12);

    span {
      font-size: 0.7rem;
    }
  }

  h6{
    font-size: 0.7rem;
    color: var(--color-gray-11);

    span {
      font-size: 0.7rem;
    }
  }
`

export const PageTitle = styled(Card.Title)`
color: var(--color-gray-12);
font-weight: normal;
font-size: 1rem;
`

export const ErrorMessage = styled.span`
font-size: 0.7rem;
color: red;
`

export const AlertIcon = styled(FiAlertTriangle)`
color: red;
`

export const Container = styled.div`

/* @media(min-width: 1200px){ */
  max-height: 65vh;
  overflow-y: auto;
/* } */
  
`;