import { ActionButton, ActionLink } from "./styles";

export const ActionIconButton = ({
  icon,
  handleClick,
  disabledButton,
  route,
  ...rest
}) => {
  return (
    <>
      {route ? (
        <ActionLink
          {...rest}
          to={route}
          className="align-items-center d-flex"
          disabledicon={disabledButton}
        >
          {icon}
        </ActionLink>
      ) : (
        <ActionButton
          {...rest}
          type="button"
          className="align-items-center d-flex"
          onClick={handleClick}
          disabledicon={disabledButton}
        >
          {icon}
        </ActionButton>
      )}
    </>
  );
};
