import { WarningCard } from "../../../../Shared/Components/WarningCard/WarningCard";

export const WarningMessageCard = ({ loadOrder }) => {
  const fieldsInconsistencies =
    loadOrder?.campos_inconsistencia &&
    JSON.parse(loadOrder?.campos_inconsistencia);

  return (
    <div>
      {fieldsInconsistencies &&
        fieldsInconsistencies.length > 0 &&
        !!fieldsInconsistencies.find((field) => field === "senha_resposta") && (
          <WarningCard label="Aguardando o motorista realizar o aceite da OC no aplicativo" />
        )}
      {fieldsInconsistencies &&
        fieldsInconsistencies.length > 0 &&
        !!fieldsInconsistencies.find((field) => field !== "senha_resposta") && (
          <WarningCard label="Existem campos incompletos ou inconsistente desta OC. Favor revisar cadastro no SAT." />
        )}
    </div>
  );
};
