/* eslint-disable no-useless-escape */
import { Col, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import ButtonLink from "../ButtonLink/ButtonLink";
import { useCallback, useEffect, useState } from "react";
import UploadImageGuideCard from "../UploadImageGuideCard";
import {
  DeleteCnhButton,
  DeleteIconButton,
  DocumentImage,
  FormControlInput,
  ModalRow,
  UploadImageButton,
} from "./styles";
import EmptyImage from "../../../Assets/Images/EmptyImage.png";
import { pdfConverter } from "../../Utils/pdfConverter";

import axios from "axios";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { replaceSpecialChars } from "../../Utils/constants";

const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

const ModalOCRImport = ({
  setValue,
  setSelectedCity,
  setSelectedState,
  obterCidadesPorSigla,
  setOCRImage,
  type,
  setDriverInfo,
  setImageCNHType,
  truck,
}) => {
  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(undefined);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseOCR, setResponseOCR] = useState([]);

  const handleValuesVehicle = useCallback(
    (data) => {
      setValue(truck ? `${truck}.foto_documento` : "foto_documento", image);

      data[0]?.campos?.placa?.valor &&
        setValue(
          truck ? `${truck}.placa` : "placa",
          data[0].campos.placa.valor
        );

      data[0]?.campos?.renavam?.valor &&
        setValue(
          truck ? `${truck}.renavam` : "renavam",
          data[0].campos.renavam.valor
        );

      data[0]?.campos?.chassi?.valor &&
        setValue(
          truck ? `${truck}.chassi` : "chassi",
          data[0].campos.chassi.valor
        );

      data[0]?.campos?.ano_fabricacao.valor &&
        setValue(
          truck ? `${truck}.ano_fabricacao` : "ano_fabricacao",
          data[0].campos.ano_fabricacao.valor
        );

      data[0]?.campos?.marca_modelo_versao?.valor &&
        setValue(
          truck ? `${truck}.modelo` : "modelo",
          data[0].campos.marca_modelo_versao.valor.split("/")?.[1]
        );

      data[0]?.campos?.marca_modelo_versao?.valor &&
        setValue(
          truck ? `${truck}.marca` : "marca",
          data[0].campos.marca_modelo_versao.valor.split("/")?.[0]
        );

      data[0]?.campos?.ano_modelo?.valor &&
        setValue(
          truck ? `${truck}.ano_modelo` : "ano_modelo",
          data[0].campos.ano_modelo.valor
        );

      data[0]?.campos?.crv?.valor &&
        setValue(
          truck ? `${truck}.documento` : "documento",
          data[0].campos.crv.valor === "***" ? null : data[0].campos.crv.valor
        );

      data[0]?.campos?.especie_tipo?.valor &&
        setValue(
          truck ? `${truck}.tipo_veiculo` : "tipo_veiculo",
          data[0].campos.especie_tipo.valor
        );

      data[1]?.campos?.eixos?.valor &&
        setValue(
          truck ? `${truck}.qtde_eixo` : "qtd_eixo",
          data[1].campos.eixos.valor
        );

      setOCRImage({ src: image, name: uploadFile.name.toString() });

      data[0]?.campos?.cor_predominante?.valor &&
        setValue(
          truck ? `${truck}.cor` : "cor",
          data[0].campos.cor_predominante.valor
        );

      if (data[1]?.campos?.local) {
        setSelectedState(data[1].campos.local.valor.slice(-2));
        setValue(
          truck ? `${truck}.estado_emplacamento` : "estado_emplacamento",
          data[1].campos.local.valor.slice(-2)
        );

        const cities = obterCidadesPorSigla(
          data[1].campos.local.valor.slice(-2)
        );
        const city = cities.find(
          (city) =>
            replaceSpecialChars(city).trim() ===
            data[1].campos.local.valor.slice(0, -2).toLowerCase().trim()
        );
        city && setSelectedCity(city);
        setValue(
          truck ? `${truck}.cidade_emplacamento` : "cidade_emplacamento",
          city
        );
      }

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Informações coletadas com sucesso.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
      setShow(false);
    },
    [
      image,
      obterCidadesPorSigla,
      setOCRImage,
      setSelectedCity,
      setSelectedState,
      setValue,
      truck,
      uploadFile?.name,
    ]
  );

  const handleValuesDriver = useCallback(
    (data) => {
      setDriverInfo({
        cnh: data[0]?.campos?.registro ? data[0].campos.registro.valor : "",
        registro: data[1]?.campos?.espelho ? data[1].campos.espelho.valor : "",
        rg: data[0]?.campos?.identidade
          ? data[0].campos.identidade.valor?.split(" ")[0]
          : "",
      });

      // setValue("foto_cnh", image);
      setValue("cpf", data[0]?.campos?.cpf ? data[0].campos.cpf.valor : "");
      setValue("nome", data[0]?.campos?.nome ? data[0].campos.nome.valor : "");
      setValue(
        "cnh",
        data[0]?.campos?.registro ? data[0].campos.registro.valor : ""
      );
      setValue(
        "categoria_cnh_mot",
        data[0]?.campos?.categoria ? data[0].campos.categoria.valor : ""
      );
      setValue(
        "dt_validade_cnh",
        data[0]?.campos?.validade?.valor
          ? data[0].campos.validade.valor?.split("/").reverse().join("-")
          : ""
      );
      setValue(
        "dt_primeira_cnh",
        data[0]?.campos?.data_1_habilitacao?.valor
          ? data[0].campos.data_1_habilitacao.valor
              .split("/")
              .reverse()
              .join("-")
          : ""
      );
      setValue(
        "rg_motorista",
        data[0]?.campos?.identidade?.valor
          ? data[0].campos.identidade.valor?.split(" ")[0]
          : ""
      );

      setValue(
        "orgao_rg",
        data[0]?.campos?.identidade?.valor
          ? data[0].campos.identidade.valor.split(" ")[1]
          : ""
      );
      setValue(
        "dt_emissao_cnh",
        data[1]?.campos?.data_emissao?.valor
          ? data[1].campos.data_emissao.valor.split("/").reverse().join("-")
          : ""
      );
      setValue(
        "numero_registro_cnh",
        data[1]?.campos?.espelho ? data[1].campos.espelho.valor : ""
      );

      setValue(
        "nacionalidade",
        data[0]?.campos?.filiacao?.valor?.split("\n")[0] === "ESTRANGEIRO"
          ? "3"
          : "1"
      );

      setValue(
        "dt_nascimento",
        data[0]?.campos?.nascimento?.valor
          ? data[0].campos.nascimento.valor.split("/").reverse().join("-")
          : data[0]?.campos?.data_local_uf_nascimento?.valor
          ? data[0]?.campos?.data_local_uf_nascimento?.valor
              .split(",")[0]
              .split("/")
              .reverse()
              .join("-")
          : ""
      );

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Informações coletadas com sucesso.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
      setShow(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [responseOCR, setValue]
  );

  const handleImportVehicle = async () => {
    if (uploadFile) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", uploadFile, uploadFile.name.toString());

      try {
        const response = await API.post(
          "api-externa/validacaocnh_crlv/api_ocr",
          formData
        );

        if (
          response.data.code_message ===
          "A requisição foi processada com sucesso."
        ) {
          setResponseOCR(response.data.data);
          setIsLoading(false);
        } else if (response.data.code === 702 || response.data.code === 701) {
          setResponseOCR([]);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "A imagem/pdf não é um CRLV válido.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            showClass: {
              popup: "swal2-noanimation",
              backdrop: "swal2-noanimation",
            },
            hideClass: {
              popup: "",
              backdrop: "",
            },
          });
        } else {
          setResponseOCR([]);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: response.data.code_message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            showClass: {
              popup: "swal2-noanimation",
              backdrop: "swal2-noanimation",
            },
            hideClass: {
              popup: "",
              backdrop: "",
            },
          });
        }
      } catch (error) {
        if (error) {
          setResponseOCR([]);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Erro ao consultar documento.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            showClass: {
              popup: "swal2-noanimation",
              backdrop: "swal2-noanimation",
            },
            hideClass: {
              popup: "",
              backdrop: "",
            },
          });
        }
      }
    } else {
      setErrors("Selecione um arquivo!");
    }
  };

  const handleImportDriver = async () => {
    if (uploadFile) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", uploadFile, uploadFile.name.toString());

      try {
        const response = await API.post(
          "api-externa/validacaocnh/api_ocr",
          formData
        );

        if (
          response.data.code_message ===
          "A requisição foi processada com sucesso."
        ) {
          setResponseOCR(response.data.data);
          setIsLoading(false);
        } else if (response.data.code === 702 || response.data.code === 701) {
          setResponseOCR([]);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "A imagem/pdf não é uma CNH válida.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            showClass: {
              popup: "swal2-noanimation",
              backdrop: "swal2-noanimation",
            },
            hideClass: {
              popup: "",
              backdrop: "",
            },
          });
        } else {
          setResponseOCR([]);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: response.data.code_message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            showClass: {
              popup: "swal2-noanimation",
              backdrop: "swal2-noanimation",
            },
            hideClass: {
              popup: "",
              backdrop: "",
            },
          });
        }
      } catch (error) {
        if (error) {
          setResponseOCR([]);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Erro ao consultar documento.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            showClass: {
              popup: "swal2-noanimation",
              backdrop: "swal2-noanimation",
            },
            hideClass: {
              popup: "",
              backdrop: "",
            },
          });
        }
      }
    } else {
      setErrors("Selecione um arquivo!");
    }
  };

  const deleteFile = () => {
    setUploadFile(null);
    setImage(null);
  };

  const handleFileChange = (event) => {
    setIsImgLoading(true);
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;

      const base64 = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      if (type === "vehicle") {
        truck
          ? setValue(
              `${truck}.foto_documento`,
              "data:" + file.type + ";base64," + base64
            )
          : setValue(
              "foto_documento",
              "data:" + file.type + ";base64," + base64
            );
      } else {
        setValue("foto_cnh", "data:" + file.type + ";base64," + base64);
      }

      setUploadFile(file);
      setImageCNHType(file.type);

      if (file.type === "application/pdf") {
        pdfConverter(file, setImage, setIsImgLoading);
      } else if (file.type === "image/jpeg" || file.type === "image/png") {
        setUploadFile(file);
        setImage("data:" + file.type + ";base64," + base64);
        setIsImgLoading(false);
      } else {
        setUploadFile(undefined);
        setImage(null);
        setIsImgLoading(false);
        setErrors("Arquivo não suportado");
      }
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (image && uploadFile) {
      setOCRImage({ src: image, name: uploadFile.name.toString() });
    }
  }, [image, uploadFile, setOCRImage]);

  useEffect(() => {
    if (type === "vehicle" && responseOCR.length > 0) {
      handleValuesVehicle(responseOCR);
    }
    if (type === "driver" && responseOCR.length > 0) {
      handleValuesDriver(responseOCR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseOCR, type]);

  return (
    <>
      <div className="pt-2 pb-2">
        <ButtonLink
          label="Importação por OCR"
          buttoncolor="primary"
          handleClick={() => setShow(true)}
        />
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Importar dados por OCR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalRow className="d-flex justify-content-around g-3 g-md-1">
            <Col
              md={5}
              className="d-flex flex-column align-items-center align-items-md-start"
            >
              <Form.Group>
                <DocumentImage>
                  {isImgLoading ? (
                    <Spinner animation="border" role="status" variant="light" />
                  ) : (
                    <img
                      src={image ?? EmptyImage}
                      alt={"Documento do veículo"}
                    />
                  )}
                </DocumentImage>

                {uploadFile && (
                  <div className="d-flex align-items-center">
                    <DeleteCnhButton
                      type="button"
                      className="d-flex align-items-center p-0 gap-1"
                      onClick={deleteFile}
                      disabled={isImgLoading || isLoading}
                    >
                      <label>{uploadFile?.name}</label>
                      <DeleteIconButton />
                    </DeleteCnhButton>
                  </div>
                )}
                {!uploadFile && (
                  <InputGroup className=" mt-1">
                    <div>
                      <UploadImageButton
                        className="rounded d-flex justify-content-center align-items-center"
                        width="10.4rem"
                      >
                        <label
                          htmlFor="file-input"
                          className="custom-file-upload"
                        >
                          Selecionar documento
                        </label>
                      </UploadImageButton>

                      {errors && (
                        <Form.Text className="text-danger">{errors}</Form.Text>
                      )}
                    </div>
                    <FormControlInput
                      type="file"
                      id="file-input"
                      name="file-input"
                      onChange={(event) => handleFileChange(event)}
                      className="d-none"
                    />
                  </InputGroup>
                )}
              </Form.Group>
            </Col>

            <Col md={7}>
              <UploadImageGuideCard
                formats="Formatos aceitos .jpeg, .png e .pdf"
                item="do documento do veículo"
                doc="do veículo"
              />
            </Col>
          </ModalRow>
        </Modal.Body>

        <Modal.Footer>
          {isLoading ? (
            <>
              <Spinner animation="border" role="status" variant="secondary" />
            </>
          ) : (
            <>
              <ButtonLink
                label="Importar"
                buttoncolor="primary"
                fontSize="0.85rem"
                handleClick={
                  type === "vehicle" ? handleImportVehicle : handleImportDriver
                }
                disabled={isImgLoading}
              />
              <ButtonLink
                label="Cancelar"
                fontSize="0.85rem"
                buttoncolor="secondary"
                handleClick={() => setShow(false)}
                disabled={isImgLoading}
              />
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalOCRImport;
