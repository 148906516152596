import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { FaArrowLeft, FaArrowRight, FaCheckCircle } from "react-icons/fa";
import { IoWarningOutline } from "react-icons/io5";
import { Col, Container, Image, Row } from "react-bootstrap";

import { BorderCard, CardList, RemoveButton, Thumbnail } from "./styles";
import "./components/Scanner/styles";
import { Button } from "./components/Button";
import { EmptyArea } from "./components/EmptyArea";
import { PageTitle } from "./components/PageTitle";
import { MultiSteps } from "./components/MultiSteps";
import { ProgressCard } from "./components/ProgressCard";
import { WebcamCapture } from "./components/WebcamCapture";
import FileUpload from "./components/FileUpload/FileUpload";

import pdfThumbnail from "../../Assets/Images/pdfIcon.png";
import { useManifest } from "../../hooks/ManifestiesProvider";
import { Loading } from "../../Shared/Components/Loading/Loading";
import { Header } from "./components/Header/Header";
import { WebcamScanner } from "./components/WebcamScanner";
import { ErrorMessageCard } from "./components/ErrorMessageCard";

const MAX_FILENAME_LENGTH = 9;

const BarcodeScanner = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [result, setResult] = useState(null);
  const [files, setFiles] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [successLabel, setSuccessLabel] = useState(
    "Leitura do QR code concluída!"
  );
  const [scanResult, setScanResult] = useState("");

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const hashValue = params.get("hash");

  const {
    hashManifest,
    isLoading,
    hasError,
    verifyBarcodeError,
    geHashManifest,
    postHasManifest,
    getVerifyBarcode,
    postManifestValidation,
    setVerifyBarcodeError,
    getVerifyNumberVehicle,
    postValidateManifestImage,
    errorValidateImage,
    setErrorValidateImage,
  } = useManifest();

  useEffect(() => {
    geHashManifest(hashValue);
  }, [hashValue, geHashManifest]);

  const onVerifyInput = async (numero_cte, placa, errorResult) => {
    if (errorResult) {
      setActiveStep(1);
    } else {
      const status = await getVerifyNumberVehicle(
        hashManifest?.manif_cod,
        numero_cte,
        placa
      );
      if (status && !status?.conhec_docs_anexados) {
        setResult(status.conhec_ctechave);
      } else {
        setActiveStep(1);
      }
    }
  };

  const onDetected = async (barcode) => {
    const status = await getVerifyBarcode(hashManifest?.manif_cod, barcode);
    if (status && !status?.conhec_docs_anexados) {
      setResult(barcode);
    } else {
      setActiveStep(1);
    }
  };

  const handleCancelScanner = (setErrorResult) => {
    setResult(null);
    setErrorResult(false);
    setVerifyBarcodeError(null);
  };

  // Funções para o componente FileUpload

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    if (photos.length === 0 && newFiles.length === 0) {
      setActiveStep(2);
    }
  };

  const renderThumbnail = (file) => {
    if (file.type === "application/pdf") {
      return <Thumbnail src={pdfThumbnail} alt={file.name} />;
    } else {
      return <Thumbnail src={URL.createObjectURL(file)} alt={file.name} />;
    }
  };

  const formatFileName = (name) => {
    if (name.length > MAX_FILENAME_LENGTH) {
      const firstPart = name.substring(0, MAX_FILENAME_LENGTH / 2);
      const secondPart = name.substring(name.length - MAX_FILENAME_LENGTH / 2);
      return `${firstPart}...${secondPart}`;
    } else {
      return name;
    }
  };

  const handleFileChange = (e) => {
    setErrorValidateImage(null);
    const selectedFiles = Array.from(e.target.files);

    setFiles([...files, ...selectedFiles]);
  };

  const handleDeletePhoto = (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);

    if (updatedPhotos.length === 0 && files.length === 0) {
      setActiveStep(2);
    }
  };

  const handleSendImages = async () => {
    if (photos.length === 0 && files.length === 0) {
      return alert("Insira pelo menos uma imagem");
    }
    if (photos.length > 0 || files.length > 0) {
      // setActiveStep(3);

      let body = {
        image: photos.length > 0 ? photos[0] : await changeFiles(files[0]),
      };

      body.image = body.image?.split("base64,")[1];

      postValidateManifestImage(body, setActiveStep);
    }
  };

  const changeFiles = useCallback((event) => {
    return new Promise((resolve, reject) => {
      const selectedFile = event;

      const reader = new FileReader();

      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const base64 = btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        if (
          selectedFile.type === "application/pdf" ||
          selectedFile.type === "image/jpeg" ||
          selectedFile.type === "image/png"
        ) {
          resolve("data:" + selectedFile.type + ";base64," + base64);
        } else {
          reject("Tipo de arquivo não suportado: " + selectedFile.type);
        }
      };

      reader.readAsArrayBuffer(selectedFile);
    });
  }, []);

  const handleSend = async () => {
    if (photos.length === 0 && files.length === 0) {
      return alert("Insira pelo menos uma imagem");
    }

    let newFiles = [];

    for (const file of files) {
      try {
        const transformedFile = await changeFiles(file);
        newFiles.push(transformedFile);
      } catch (error) {
        console.error(error);
      }
    }

    const postData = [...photos, ...newFiles];

    const documents = postData.map((item) => {
      return {
        image: item,
      };
    });

    await postHasManifest(
      result,
      {
        documentos: documents,
        conhec_hash_motorista: hashValue,
      },
      setActiveStep
    );

    setFiles([]);
    setPhotos([]);
  };

  const handleCancelSendImages = () => {
    geHashManifest(hashValue);
    setFiles([]);
    setPhotos([]);
    setActiveStep(1);
  };

  const progressIndicator = useMemo(() => {
    if (hashManifest.conhecimentos && hashManifest.conhecimentos.length > 0) {
      const index = hashManifest.conhecimentos.filter(
        (manifest) => manifest.conhec_docs_anexados.length === 0
      ).length;

      return (
        100 *
        ((hashManifest.conhecimentos.length - index) /
          hashManifest.conhecimentos.length)
      );
    }
    return 0;
  }, [hashManifest.conhecimentos]);

  useEffect(() => {
    if (
      progressIndicator === 100 &&
      hashManifest?.manif_status_mdfe &&
      (hashManifest.manif_status_mdfe === "6" ||
        hashManifest.manif_status_mdfe === "7" ||
        hashManifest.manif_status_mdfe !== "8")
    ) {
      setActiveStep(4);
    }
  }, [progressIndicator, hashManifest]);

  useEffect(() => {
    if (errorValidateImage && errorValidateImage !== "") {
      setPhotos([]);
      setFiles([]);
    }
  }, [errorValidateImage]);

  return (
    <div className="min-vh-100 d-flex flex-column p-3">
      {isLoading && <Loading />}
      {!isLoading && hasError && (
        <div>
          <Header
            progressIndicator={progressIndicator}
            hashManifest={hashManifest}
          />
          <div className="mt-5">
            <EmptyArea />
          </div>
        </div>
      )}

      {!isLoading &&
        !hasError &&
        Array.isArray(hashManifest.conhecimentos) &&
        hashManifest?.conhecimentos?.length > 0 && (
          <div>
            <Header
              progressIndicator={progressIndicator}
              hashManifest={hashManifest}
            />
            <MultiSteps size={4} currentStep={activeStep} />
            {activeStep === 1 && (
              <Container className="mt-2">
                {progressIndicator === 100 ? (
                  <ProgressCard
                    progress={progressIndicator}
                    hashManifest={hashManifest.conhecimentos}
                    width={progressIndicator < 100 ? 100 : 200}
                    height={progressIndicator < 100 ? 100 : 200}
                  />
                ) : (
                  <WebcamScanner
                    setActiveStep={setActiveStep}
                    postManifestValidation={postManifestValidation}
                    onDetected={onDetected}
                    onVerifyInput={onVerifyInput}
                    verifyBarcodeError={verifyBarcodeError}
                    handleCancelScanner={handleCancelScanner}
                    setVerifyBarcodeError={setVerifyBarcodeError}
                    successLabel={successLabel}
                    setSuccessLabel={setSuccessLabel}
                    result={result}
                    setScanResult={setScanResult}
                  />
                )}
              </Container>
            )}

            {activeStep === 2 && (
              <div>
                <PageTitle label="Adicione os comprovantes de descarga" />
                {errorValidateImage &&
                  photos.length === 0 &&
                  files.length === 0 && (
                    <ErrorMessageCard label={errorValidateImage} />
                  )}
                <Row xs={1} className="d-flex g-3">
                  <Col>
                    {photos.length === 0 && files.length === 0 && (
                      <FileUpload handleFileChange={handleFileChange} />
                    )}
                  </Col>
                  <Col>
                    {photos.length === 0 && files.length === 0 && (
                      <WebcamCapture
                        photos={photos}
                        setPhotos={setPhotos}
                        setErrorValidateImage={setErrorValidateImage}
                      />
                    )}

                    <div className="d-lg-flex justify-content-start mb-3 mt-3">
                      <div className="d-flex flex-column gap-2 w-100">
                        {files.map((file, index) => (
                          <CardList
                            key={index}
                            className="d-flex align-items-center gap-2 justify-content-between"
                          >
                            <div className="d-flex align-items-center gap-2">
                              {renderThumbnail(file)}
                              <div className="file-info d-flex align-items-center jsutify-content-between gap-2 ">
                                <span className="file-name">
                                  {formatFileName(file.name)}
                                </span>
                              </div>
                            </div>
                            <RemoveButton
                              onClick={() => handleRemoveFile(index)}
                              className="d-flex align-items-center gap-1"
                            >
                              Remover
                            </RemoveButton>
                          </CardList>
                        ))}
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2 mt-2">
                      {photos.map((photo, index) => (
                        <CardList
                          key={index}
                          className="d-flex gap-2 alig-items-center justify-content-between"
                        >
                          <Image
                            width={65}
                            height={65}
                            src={photo}
                            rounded
                            alt={`Documento ${index}`}
                          />
                          <div className="d-flex align-items-center">
                            <RemoveButton
                              onClick={() => handleDeletePhoto(index)}
                              className="d-flex align-items-center gap-2"
                            >
                              Remover
                            </RemoveButton>
                          </div>
                        </CardList>
                      ))}
                    </div>
                  </Col>
                </Row>

                <div className="d-flex justify-content-center mt-3 ">
                  <div className="d-flex justify-content-center gap-3 w-100">
                    <Button
                      backgroundColor="white"
                      border="#093c5e"
                      color="#093c5e"
                      label="Cancelar"
                      leftIcon={<FaArrowLeft />}
                      onClick={handleCancelSendImages}
                      style={{
                        maxWidth: "150px",
                        paddingBlock: "15px",
                        fontSize: "1.3rem",
                      }}
                    />
                    <Button
                      backgroundColor="#093c5e"
                      border="#093c5e"
                      color="white"
                      label="Seguir"
                      rightIcon={<FaArrowRight />}
                      onClick={handleSendImages}
                      style={{
                        maxWidth: "150px",
                        paddingBlock: "15px",
                        fontSize: "1.3rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {activeStep === 3 && (
              <div>
                <PageTitle label="Confirmar documentos" />
                <div className="p-2">
                  <div className="d-flex flex-column gap-2 mb-2">
                    {files.map((file, index) => (
                      <CardList
                        key={index}
                        className="d-flex align-items-center gap-2 justify-content-between"
                      >
                        <div className="d-flex align-items-center gap-2">
                          {renderThumbnail(file)}
                          <div className="file-info d-flex align-items-center jsutify-content-between gap-2 ">
                            <span className="file-name">
                              {formatFileName(file.name)}
                            </span>
                          </div>
                        </div>
                        <RemoveButton
                          onClick={() => {
                            handleRemoveFile(index);
                          }}
                          className="d-flex align-items-center gap-1 py-2 px-3"
                          style={{ fontSize: "1.1rem" }}
                        >
                          Remover
                        </RemoveButton>
                      </CardList>
                    ))}
                  </div>
                  <div className="d-flex flex-column gap-2">
                    {photos.map((photo, index) => (
                      <CardList
                        key={index}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <Image
                          width={60}
                          height={60}
                          src={photo}
                          rounded
                          alt={`Documento ${index}`}
                        />
                        <RemoveButton
                          onClick={() => {
                            handleDeletePhoto(index);
                          }}
                          className="d-flex align-items-center gap-2 py-2 px-3"
                          style={{ fontSize: "1.1rem" }}
                        >
                          Remover
                        </RemoveButton>
                      </CardList>
                    ))}
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <div className="d-flex justify-content-center gap-3 w-100">
                    <Button
                      backgroundColor="white"
                      border="#093c5e"
                      color="#093c5e"
                      label="Cancelar"
                      leftIcon={<FaArrowLeft />}
                      onClick={() => setActiveStep(2)}
                      style={{
                        maxWidth: "150px",
                        paddingBlock: "15px",
                        fontSize: "1.3rem",
                      }}
                    />
                    <Button
                      backgroundColor="#093c5e"
                      border="#093c5e"
                      color="white"
                      label="Confirmar"
                      rightIcon={<FaArrowRight />}
                      onClick={handleSend}
                      style={{
                        maxWidth: "160px",
                        paddingBlock: "15px",
                        fontSize: "1.3rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {activeStep === 4 && (
              <div>
                <div className="mt-2 d-flex flex-column flex-grow-1 w-100 p-3 gap-4">
                  <ProgressCard
                    progress={progressIndicator}
                    hashManifest={hashManifest.conhecimentos}
                  />
                  {progressIndicator < 100 && (
                    <Button
                      label="Enviar novos documentos para outra CT-e"
                      onClick={() => {
                        setActiveStep(1);
                        setResult(null);
                      }}
                      backgroundColor="#093c5e"
                      border="#093c5e"
                      color="white"
                      style={{
                        paddingBlock: "15px",
                        fontSize: "1.2rem",
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}

      {!isLoading && hashManifest?.conhecimentos?.length === 0 && (
        <>
          <Header
            progressIndicator={progressIndicator}
            hashManifest={hashManifest}
          />

          <div className="d-flex justify-content-center mt-5">
            <BorderCard>
              <div className="d-flex flex-column gap-3 align-items-center justify-content-center text-center">
                <IoWarningOutline color="#cb1313" size={42} />
                <span>Nenhum conhecimento encontrado para esse MDF-e!</span>
              </div>
            </BorderCard>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(BarcodeScanner);
