import { Card, Col, Row } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { BsPersonLinesFill } from "react-icons/bs";
import EmptyImage from "../../../../../../Assets/Images/EmptyImage.png";
import {
  BorderCard,
  CardBody,
  CoverImage,
  IconButton,
  IconLink,
} from "./styles";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  cpfCnpjFormat,
  formatNameString,
} from "../../../../../../Shared/Utils/data";
import { StatusCard } from "../../../../../../Shared/Components/StatusCard";

const OwnerCard = ({ owner, getOwner }) => {
  const navigate = useNavigate();

  const handleEditDriverClick = useCallback(() => {
    owner?.id && getOwner(owner?.id);

    navigate(`/proprietarios-editar/${owner?.id}`);
  }, [getOwner, navigate, owner.id]);

  return (
    <BorderCard
      className="d-flex justify-content-center text-center w-100 h-100  flex-column"
      data-testid="owner-card"
    >
      <CardBody className="gap-1">
        <Row xs={2}>
          <Col xs={3} className="h-100 px-1">
            <CoverImage emptyImage={EmptyImage} />
          </Col>
          <Col xs={9} className="px-2">
            <h3 className="text-start mb-1">
              {owner?.nome ? formatNameString(owner.nome) : "--"}
            </h3>
            <h4 className="text-start mb-2">
              {cpfCnpjFormat(owner?.cpf_cnpj)}
            </h4>
            {owner?.status !== "9" && (
              <h5 className="text-start">
                Status:
                <span
                  className={`ms-1 ${
                    owner?.bloqueado_adm === "S"
                      ? "color-red"
                      : owner?.ativo
                      ? "color-active"
                      : "color-red"
                  }`}
                >
                  {owner?.bloqueado_adm === "S"
                    ? "Bloqueado ADM"
                    : owner?.ativo
                    ? "Liberado"
                    : "Desativado"}
                </span>
              </h5>
            )}
            {owner.status === "9" && <StatusCard label="Em análise" />}
          </Col>
        </Row>
      </CardBody>
      <Card.Body className="mt-auto d-flex justify-content-start gap-1">
        <IconLink
          to={`/proprietarios-informacoes/${owner.id}`}
          className="d-flex align-items-center"
          title="Informações do proprietário"
        >
          <BsPersonLinesFill size={20} color="white" />
        </IconLink>
        <IconButton
          onClick={handleEditDriverClick}
          className="d-flex align-items-center"
          title="Editar o cadastro do proprietário"
          data-testid="owner-edit-form-button"
          disabled={owner?.status === "9"}
        >
          <BiEdit size={20} color="white" />
        </IconButton>
      </Card.Body>
    </BorderCard>
  );
};

export default OwnerCard;
