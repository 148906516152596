import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { FaSave } from "react-icons/fa";

import DriverImage from "../../../../../../Assets/Images/driver-image.svg";

// import { ApprovalAnalysis } from "./ApprovalAnalysis";
import { BorderCard, CardBody, CoverImage, StatusItem } from "./styles";
import { registrationStatus } from "../../../../../../Shared/Utils/constants";
import ButtonLink from "../../../../../../Shared/Components/ButtonLink/ButtonLink";
import {
  formatNameString,
  percentageDriverStatus,
} from "../../../../../../Shared/Utils/data";

const ControlTemporaryDriverCard = ({
  driver,
  onDelete,
  getDriver,
  driverSat,
  updateOwner,
  tempRegister,
  updateVehicle,
  driverConsults,
  judicialProcess,
  isloadingConsult,
  handleConsultBtn,
  addVehiclesAndOwners,
  updateDriversByStatus,
}) => {
  const [valueSelect, setValueSelect] = useState("");
  const navigate = useNavigate();

  const actionsList = [
    {
      id: 1,
      label: "Cadastro com inconsistência",
      value: "7r",
    },
    {
      id: 2,
      label: `${
        driver?.status_cadastro === "5r"
          ? "Aprovar cadastro"
          : "Analisar cadastro"
      }`,
      value: `${
        driver?.status_cadastro === "5r"
          ? "6r"
          : driver?.status_cadastro === "6r"
          ? "0r"
          : "2r"
      }`,
    },
    {
      id: 3,
      label: "Recusar cadastro",
      value: "3r",
    },
    {
      id: 4,
      label: "Remover pré-cadastro",
      value: "delete",
    },
  ];

  const disabledSelect =
    isloadingConsult ||
    driver?.status_cadastro === "2r" ||
    driver?.status_cadastro === "7r" ||
    (driver?.status_cadastro === "5r" &&
      driver?.processos_cadastro_concluido === false);

  const temporaryDriver =
    tempRegister?.dados_formulario_motorista &&
    JSON.parse(tempRegister?.dados_formulario_motorista);

  const onChangeStatus = useCallback(
    (event) => {
      const value = event.target.value;
      setValueSelect(value);
    },
    [setValueSelect]
  );

  const handleChangeStatus = useCallback(async () => {
    const route = true;

    if (valueSelect === driver?.status_cadastro || valueSelect === "") {
      return Swal.fire({
        icon: "warning",
        title: "Atenção!",
        text: "Escolha uma ação",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    }

    if (valueSelect === "delete") {
      return Swal.fire({
        icon: "warning",
        title: "Remover pré-cadastro",
        text: "Deseja mesmo remover esse pré-cadastro do motorista? Atenção, essa ação é irreversível",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Sim, confirmo",
        cancelButtonText: "Cancelar",
        focusCancel: true,
        preConfirm: () => {
          onDelete(driver.id);
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      });
    }

    if (valueSelect === "2r") {
      await handleConsultBtn();
    }

    const data = {
      ativo:
        driver?.status_cadastro === "5r" || driver?.status_cadastro === "6r"
          ? true
          : false,
      status_cadastro: valueSelect,
    };

    if (tempRegister?.token && (valueSelect === "5r" || valueSelect === "6r")) {
      await addVehiclesAndOwners(tempRegister, data, navigate, driver);
      if (tempRegister?.id_proprietario) {
        await updateDriversByStatus(driver.id, data, navigate, route, 1);
      }
    } else {
      updateDriversByStatus(driver.id, data, navigate, route, 1);
      getDriver(driver?.id);
    }

    if (
      valueSelect !== "0r" &&
      valueSelect !== "3r" &&
      valueSelect !== "6r" &&
      valueSelect !== "7r"
    ) {
      getDriver(driver?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    valueSelect,
    tempRegister,
    onDelete,
    handleConsultBtn,
    addVehiclesAndOwners,
    navigate,
    updateVehicle,
    updateOwner,
    updateDriversByStatus,
    getDriver,
  ]);

  const consults =
    driverConsults && driverConsults.length > 0
      ? driverConsults.map((consult) =>
          consult?.consulta_cnpj && consult?.consulta_cnpj !== "null"
            ? JSON.parse(consult?.consulta_cnpj)
            : consult &&
              consult?.consulta_cpf &&
              consult?.consulta_cpf !== "null" &&
              JSON.parse(consult?.consulta_cpf)
        )
      : [];

  const isDisabled =
    consults &&
    consults.length > 0 &&
    consults.some((consult) => {
      if (
        consult?.data?.length === 0 &&
        consult?.errors?.length === 0 &&
        consult?.code_message
      ) {
        return true;
      }

      return false;
    });

  return (
    <BorderCard className="text-center  w-100 card p-1">
      <CardBody className="text-center pb-0">
        <CoverImage
          currentDriverImage={driver?.foto_motorista?.url}
          driverImage={DriverImage}
        />
        <div className="text-center d-inline-block mt-3 mb-4">
          <h3 className="text-center mb-1">
            {formatNameString(temporaryDriver?.nome)}
          </h3>
          <h4 className="text-center mb-3">{temporaryDriver?.cpf}</h4>

          <div className="mt-1">
            <StatusItem
              status={
                driver?.status_cadastro === "1r"
                  ? driver?.bloqueio_sat
                  : driver?.status_cadastro
              }
              className="mt-1 text-center"
            >
              {driver?.status_cadastro === "2r" ||
              (driver?.status_cadastro === "5r" &&
                driver?.processos_cadastro_concluido === false) ||
              isloadingConsult ? (
                <div className="d-flex gap-1 aling-items-center justify-content-center">
                  <Spinner animation="border" variant="warning" size="sm" />
                  {`Em análise - ${percentageDriverStatus(
                    {
                      biometria: driver?.status_biometria,
                      cnh: driver?.status_cnh,
                      viagem: driver?.status_viagens,
                    },
                    driver?.processos_cadastro_concluido,
                    driverConsults,
                    judicialProcess?.concluido
                  )}`}
                </div>
              ) : (
                <>
                  {driver?.status_cadastro === "1r" &&
                  driverSat &&
                  Object.keys(driverSat).length === 0
                    ? "Pré-cadastro motorista"
                    : registrationStatus(
                        driver?.status_cadastro,
                        driver?.processos_cadastro_concluido,
                        driver?.bloqueio_sat
                      )}
                </>
              )}
            </StatusItem>
          </div>
        </div>

        <Form.Select
          size="sm"
          type="text"
          className="mb-2"
          value={valueSelect}
          onChange={(event) => onChangeStatus(event)}
          disabled={disabledSelect}
          data-testid={`select_status_driver_${driver.id}`}
          style={{ fontSize: "0.7rem" }}
        >
          <>
            {disabledSelect ? (
              <option value="">Sem ações disponíveis </option>
            ) : (
              <>
                <option value="" disabled>
                  Escolha uma ação
                </option>
                {actionsList.map((action) => (
                  <option
                    value={action?.value}
                    key={action?.id}
                    data-testid={`change_driver_${driver.id}_status_select_${action.value}`}
                    disabled={action?.id === 2 && isDisabled}
                  >
                    {action?.label}
                  </option>
                ))}
              </>
            )}
          </>
        </Form.Select>

        <ButtonLink
          icon={<FaSave color="#FFF" />}
          label="Salvar cadastro"
          className="w-100 mb-2 fw-bold"
          fontSize="0.7rem"
          buttoncolor="primary"
          handleClick={handleChangeStatus}
          disabled={disabledSelect}
        />

        {/* {(driver?.status_cadastro === "2r" ||
          driver?.status_cadastro === "5r") && (
          <ApprovalAnalysis
            driver={driver}
            isloadingConsult={isloadingConsult}
            driverConsults={driverConsults}
            judicialProcess={judicialProcess}
          />
        )} */}
      </CardBody>
    </BorderCard>
  );
};

export default ControlTemporaryDriverCard;
