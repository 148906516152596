import styled from "styled-components";

export const Item = styled.div`
 position: relative;

h2{
  font-size: 1rem;
  color: var(--color-gray-12);
  margin-bottom: 0;
}

span{
  font-weight: bold;
}


&::before {
    content: '';
    display: block;
    position: absolute;
    left: -15px; 
    top: 50%;
    transform: translateY(-50%);
    width: 7px; 
    height: 7px; 
    background-color: red; 
    border-radius: 50%;
  }
`