import { Col } from "react-bootstrap";
import Dashboard from "../../../../../Shared/layout";
import LinkCard from "../../../../../Shared/Components/LinkCard";
import { memo } from "react";
import { AddVehicleIcon, VehicleListIcon, VehicleRow } from "./styles";

const list = [
  {
    route: "/veiculos-listar",
    title: "Listar veículos",
    icon: <VehicleListIcon />,
  },
  {
    route: "/veiculos-adicionar",
    title: "Adicionar veículo",
    icon: <AddVehicleIcon />,
  },
];

const Vehicles = () => {
  return (
    <Dashboard title="Veículos">
      <VehicleRow
        xs={1}
        md={2}
        lg={4}
        xl={6}
        id="container-cards-conf-sistema"
        className="d-flex mt-4 g-2"
      >
        {list.map((item) => (
          <Col key={item.route}>
            <LinkCard route={item.route} title={item.title} icon={item.icon} />
          </Col>
        ))}
      </VehicleRow>
    </Dashboard>
  );
};

export default memo(Vehicles);
