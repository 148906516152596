import { Form } from "react-bootstrap";
import styled from "styled-components";

export const OccurrenceContainer = styled.div`
  max-height: 30vh;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;

  @media(min-width: 1400px){
    max-height: 35vh;
  }
`

export const TextArea = styled(Form.Control)`
  overflow: auto;
  height: 60px;
  border-left: 1px solid #ff0f0f;
  border-top: 0.5px solid var(--color-gray-11);
  border-bottom: 0.5px solid var(--color-gray-11);
  border-right: 0.5px solid var(--color-gray-11);
  padding: 0.3rem;
`

export const FontInputTitle = styled(Form.Label)`
  font-size: 0.8rem;
  color: var(--color-gray-15);
`

export const Container = styled.div`
max-height: 30vh;
flex: 1;
overflow-x: auto;

 @media(min-width: 425px){
  max-height: 50vh;
 }

 @media(min-width: 576px){
  max-height: 65vh;
 }

 @media(min-width: 768px){
  max-height: 66vh;
 }

 @media(min-width: 992px){
  max-height: 70vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }

 @media(min-width: 1440px){ 
  max-height: 80vh;
  }

  @media(min-width: 2440px){
  max-height: 80vh;
  }
`