import React, { useState, useRef, useCallback, useEffect } from "react";

import { FaArrowLeft, FaArrowRight, FaCamera } from "react-icons/fa";
import { TbCameraPlus } from "react-icons/tb";
import { IoIosClose, IoMdBarcode, IoMdCamera } from "react-icons/io";
import { IoClose } from "react-icons/io5";

import {
  ClearButton,
  CloseCameraButton,
  FileUploadContainer,
  ModalContent,
  ModalWrapper,
  TakePictureButton,
  TextFormArea,
} from "./styles";

import { PageTitle } from "../PageTitle";
import { ErrorMessageCard } from "../ErrorMessageCard";
import { useManifest } from "../../../../hooks/ManifestiesProvider";
import { SuccessCard } from "../SuccessCard";
import { Button } from "../Button";
import Webcam from "react-webcam";
import { processImage } from "../../../../hooks/ImageHook";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import QRcodeScanner from "../QrcodeScanner";

export const WebcamScanner = ({
  setActiveStep,
  verifyBarcodeError,
  onDetected,
  onVerifyInput,
  handleCancelScanner,
  setVerifyBarcodeError,
  successLabel,
  setSuccessLabel,
  scanResult,
  setScanResult,
  result,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [manuallyInputTheBarcode, setManuallyInputTheBarcode] = useState(false);
  const [inputValueNumber, setInputValueNumber] = useState("");
  const [inputValueVehicle, setInputValueVehicle] = useState("");

  const [scanResultError, setScanResultError] = useState("");
  const [errorScan, setErrorScan] = useState("");

  const scanner = useRef();

  const previewCanvasRef = useRef(null);
  const webcamRef = useRef(null);

  const {
    postManifestValidation,
    setErrorResult,
    errorResult,
    manifestValidation,
  } = useManifest();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleInputSubmit = useCallback(
    async (data) => {
      onVerifyInput(data?.cte_number, data?.vehicle);
      setSuccessLabel("Identificação do número e placa concluída com sucesso!");
      setManuallyInputTheBarcode(!manuallyInputTheBarcode);
    },
    [manuallyInputTheBarcode, onVerifyInput]
  );

  const handleCaptureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot({
      height: 1080,
      width: 1920,
    });
    // const base64 = await processImage(imageSrc);

    const currentResult = await postManifestValidation(imageSrc);
    onDetected(currentResult?.barcode, errorResult);
    setIsOpen(false);
  };

  const handleOpenCamera = () => {
    setScanResultError("");
    setErrorScan("");
    setSuccessLabel("Leitura do QR code concluída com sucesso!");
    setVerifyBarcodeError(false);
    setIsOpen(true);
  };

  const handleCloseCamera = () => {
    setVerifyBarcodeError(false);
    setErrorScan("");
    scanner?.current?.stop();
    setIsOpen(false);
    window.location.reload();
  };

  const onScanSuccess = (result) => {
    // console.log("Success", result);
    setErrorScan("");

    const regex = /chCTe=([^&]*)/;
    const match = result.data.match(regex);

    if (match) {
      const chCTe = match[1]; // O número chCTe capturado
      // setScanResult(chCTe);
      setScanResultError("");
      onDetected(chCTe, errorResult);
    } else {
      setScanResultError("Nenhum número CT-e encontrado no QR code.");
      setScanResult(null);
    }

    setIsOpen(false);
    scanner?.current?.stop();
  };

  const onScanFail = (error) => {
    // console.log("error", error);
    setErrorScan("QR code não encontrado.");
    setScanResultError("Não foi possível identificar o QR code!");
  };

  const handleInputClearNumber = () => {
    setInputValueNumber("");
  };

  const handleInputClearVehicle = () => {
    setInputValueVehicle("");
  };

  return (
    <div className="mb-3 w-100">
      <PageTitle label="Captura da imagem do CT-e" />
      {((!isOpen && scanResultError) || verifyBarcodeError) && (
        <ErrorMessageCard
          label={
            scanResultError
              ? scanResultError
              : verifyBarcodeError
              ? verifyBarcodeError
              : "Não foi possível fazer a leitura do QR code!"
          }
        />
      )}

      {!(result && !verifyBarcodeError) && (
        <div>
          {manuallyInputTheBarcode && (
            <Form>
              <div className="d-flex flex-column flex-md-row gap-2">
                <div className="mb-2 w-100">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <Form.Label className="m-0">
                      Digite o número do CT-e
                    </Form.Label>

                    {inputValueNumber !== "" && (
                      <div className="d-flex align-items-center justify-content-end p-0 m-0">
                        <ClearButton
                          type="button"
                          onClick={handleInputClearNumber}
                          className="py-1 px-2"
                        >
                          <IoClose size={20} /> <span>Limpar</span>
                        </ClearButton>
                      </div>
                    )}
                  </div>

                  <TextFormArea
                    {...register("cte_number", {
                      required: true,
                      pattern: /^[0-9]*$/,
                    })}
                    inputmode="numeric"
                    value={inputValueNumber?.replace(/\D/gi, "")}
                    onChange={(e) => {
                      setInputValueNumber(
                        e?.target?.value?.replace(/\D/gi, "")
                      );
                    }}
                    type="text"
                  />

                  {errors?.cte_number?.type === "required" && (
                    <Form.Text className="text-danger">
                      Esse campo é obrigatório
                    </Form.Text>
                  )}
                  {errors?.cte_number?.type === "pattern" && (
                    <Form.Text className="text-danger">
                      Digite apenas números
                    </Form.Text>
                  )}
                </div>

                <div className="mb-2 w-100">
                  <div className="d-flex align-items-end justify-content-between mb-2">
                    <Form.Label className="m-0">
                      Digite a placa do cavalo
                    </Form.Label>

                    {inputValueVehicle !== "" && (
                      <div className="d-flex justify-content-end p-0 m-0">
                        <ClearButton
                          type="button"
                          onClick={handleInputClearVehicle}
                          className="py-1 px-2"
                        >
                          <IoClose size={20} /> <span>Limpar</span>
                        </ClearButton>
                      </div>
                    )}
                  </div>
                  <TextFormArea
                    {...register("vehicle", {
                      required: true,
                    })}
                    value={inputValueVehicle?.toUpperCase()}
                    onChange={(e) => {
                      setInputValueVehicle(e?.target?.value?.toUpperCase());
                    }}
                    type="text"
                  />

                  {errors?.vehicle?.type === "required" && (
                    <Form.Text className="text-danger">
                      Esse campo é obrigatório
                    </Form.Text>
                  )}
                </div>
              </div>

              <div className="w-100 d-flex flex-grow-1">
                <Button
                  label="Enviar"
                  backgroundColor="#093c5e"
                  border="#093c5e"
                  color="white"
                  onClick={handleSubmit(handleInputSubmit)}
                />
              </div>
            </Form>
          )}

          {!manuallyInputTheBarcode &&
            !inputValueNumber &&
            !inputValueVehicle && (
              <FileUploadContainer onClick={handleOpenCamera} className="w-100">
                <label className="custom-file-upload text-center">
                  <div className="d-flex justify-content-center">
                    <TbCameraPlus size={64} />
                  </div>
                  Escanear QR code
                </label>
              </FileUploadContainer>
            )}

          <div className="mt-4 w-100 d-flex flex-grow-1 flex-1">
            <Button
              leftIcon={
                manuallyInputTheBarcode ? <IoMdCamera /> : <IoMdBarcode />
              }
              label={
                manuallyInputTheBarcode
                  ? "Escanear QR code"
                  : "Digitar número cte e placa do veículo"
              }
              backgroundColor={manuallyInputTheBarcode ? "white" : "#093c5e"}
              border="#093c5e"
              color={manuallyInputTheBarcode ? "#093c5e" : "white"}
              onClick={() => {
                setScanResultError("");
                handleCancelScanner(setErrorResult);
                setManuallyInputTheBarcode(!manuallyInputTheBarcode);
              }}
            />
          </div>

          {isOpen && (
            <ModalWrapper>
              <ModalContent>
                <QRcodeScanner
                  setScanError={setScanResultError}
                  onScanSuccess={onScanSuccess}
                  scanner={scanner}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                  onScanFail={onScanFail}
                  error={errorScan}
                />

                <CloseCameraButton>
                  <button onClick={handleCloseCamera}>
                    <IoClose />
                  </button>
                  <span className="text-white">Fechar</span>
                </CloseCameraButton>
              </ModalContent>
            </ModalWrapper>
          )}
        </div>
      )}

      {!verifyBarcodeError && result && (
        <div className="mt-2">
          <SuccessCard label={successLabel} />

          <div className="d-flex mt-5">
            <p style={{ maxWidth: "310px", wordWrap: "break-word" }}>
              Código de barras: <strong>{result}</strong>
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <div className="d-flex justify-content-center gap-3 w-100">
              <Button
                backgroundColor="white"
                border="#093c5e"
                color="#093c5e"
                label="Voltar"
                leftIcon={<FaArrowLeft />}
                onClick={() => handleCancelScanner(setErrorResult)}
                style={{
                  maxWidth: "150px",
                  paddingBlock: "15px",
                  fontSize: "1.3rem",
                }}
              />
              <Button
                backgroundColor="#093c5e"
                border="#093c5e"
                color="white"
                label="Seguir"
                rightIcon={<FaArrowRight />}
                onClick={() => setActiveStep(2)}
                style={{
                  maxWidth: "150px",
                  paddingBlock: "15px",
                  fontSize: "1.3rem",
                }}
              />
            </div>
          </div>
        </div>
      )}

      <canvas ref={previewCanvasRef} className="d-none" />
    </div>
  );
};
