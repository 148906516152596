import styled from "styled-components";

export const FileUploadContainer = styled.div`
  background-color: var(--color-blue-80);
  border: 1px solid var(--color-blue-80);
  padding: 25px;
  border-radius: 4px;
  color: white;

  label {
    font-size: 1.2rem;
  }
`



export const ListBorder = styled.div`
  border: 1px solid var(--color-gray-12);
  border-top: none !important;
  border-radius: 4px;
`

