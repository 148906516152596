import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest from "../Shared/Utils/Request";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export const UserContext = createContext({
  users: [],
  user: null,
  isLoading: false,
  isSubmitting: false,
  getUsers: () => {},
  getUser: () => null,
  addUser: () => null,
  deleteUser: () => null,
  updateUser: () => null,
  resetPassword: () => {},
});

export const useUsers = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUsers must be within UserProvider");
  }

  return context;
};

export const UserProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [isSubmitting, setIsSubmiting] = useState(false);

  const getUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      setIsSubmiting(true);

      const response = await ApiRequest({
        path: "usuarios",
        method: "GET",
      });

      setUsers(response?.data);
      return response.data;
    } catch (error) {
      setUsers([]);
    } finally {
      setIsLoading(false);
      setIsSubmiting(false);
    }
  }, []);

  const getUser = useCallback(async (id) => {
    try {
      setIsLoading(true);
      const response = await ApiRequest({
        path: `usuarios/${id}`,
        method: "GET",
      });

      setUser(response?.data);
      setIsLoading(false);

      return response?.data;
    } catch (error) {
      setUsers(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateUser = useCallback(
    async (id, data, navigate) => {
      try {
        setIsSubmiting(true);

        await ApiRequest({
          path: `usuarios/${id}`,
          method: "PUT",
          data,
        });

        const currentData = {
          perfil_id: data?.id_perfil,
        };

        data?.id_perfil &&
          (await ApiRequest({
            path: `acesso/usuario/${id}`,
            method: "POST",
            data: currentData,
          }));

        if (navigate) {
          navigate("/usuarios");
        }
        const response = await getUsers();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Usuário atualizado com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Usuário não pode ser atualizado.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } finally {
        setIsLoading(false);
        setIsSubmiting(false);
      }
    },
    [getUsers]
  );

  const deleteUser = useCallback(
    async (id) => {
      try {
        setIsSubmiting(true);

        await ApiRequest({
          path: `usuarios/${id}`,
          method: "DELETE",
        });

        const response = await getUsers();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Usuário excluído com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Usuário não pode ser excluído.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } finally {
        setIsSubmiting(false);
      }
    },
    [getUsers]
  );

  const addUser = useCallback(
    async (data, navigate) => {
      try {
        setIsSubmiting(true);

        await ApiRequest({
          path: "usuarios",
          method: "POST",
          data,
        });

        navigate("/usuarios");

        const response = await getUsers();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Usuário criado com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: error?.response?.data?.error ?? "Usuário não pode ser criado.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } finally {
        setIsLoading(false);
        setIsSubmiting(false);
      }
    },
    [getUsers]
  );

  const resetPassword = useCallback(
    async (token, data, navigate) => {
      try {
        setIsSubmiting(true);

        await ApiRequest({
          path: `resetpassword/${token}`,
          method: "POST",
          data,
        });

        const response = await getUsers();

        navigate("/");

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Senha redefinida com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `${
            error?.response?.data?.error
              ? error?.response?.data?.error
              : "Erro no servidor"
          }`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmiting(false);
      } finally {
        setIsLoading(false);
        setIsSubmiting(false);
      }
    },
    [getUsers]
  );

  const providerValue = useMemo(
    () => ({
      users,
      user,
      isLoading,
      isSubmitting,
      getUsers,
      getUser,
      addUser,
      deleteUser,
      updateUser,
      resetPassword,
    }),
    [
      users,
      user,
      isLoading,
      isSubmitting,
      getUsers,
      getUser,
      addUser,
      deleteUser,
      updateUser,
      resetPassword,
    ]
  );

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};
