import { Card, Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";

export const Item = styled.div`
  padding: 0;
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;

  h4 {
    font-size: 0.875rem;
    color: black;
    display: flex;
    margin-left: 0.2rem;
    margin-bottom: 0;
    text-align: left;

    @media (max-width: 424px) {
      font-size: 0.7rem;
    }
  }

  h3 {
    font-size: 0.875rem;
    display: flex;
    margin-bottom: 0;
    color: var(--color-gray-11);
    text-align: left;

    @media (max-width: 424px) {
      font-size: 0.7rem;
    }
  }
`;

export const InformationsSection = styled.div`
border-top: 0.5px solid var(--color-gray-10);
margin-top:1rem;
padding-top: 1rem;

h2{
  font-size: 1rem;
  color: var(--color-gray-15);
  margin-bottom: 1.3rem;
}
`

export const PageTitle = styled(Card.Title)`
 color: var(--color-gray-12);
font-weight: normal;
font-size: 1rem;
margin-bottom: 1rem;
`

export const OriginDestinationCardContainer = styled(Card)`
border: 0.2px solid var(--color-gray-10);

span{
  font-size: 0.7rem;
  color: var(--color-gray-12);
}

h3{
  margin-bottom:0;
  font-size: 0.75rem;  
}


`

export const Status = styled.h2`
margin-bottom: 0;
font-size: 0.75rem;

  color: ${props => props.status === "Atrasado" ? "#ff0f0f" : "#07b141"}
`


export const TravelsInformationsCard = styled(Card)`
  overflow-y: auto;
  min-height: 100%;
`

export const LoaderOrderStatus = styled.h4`

  color:${props => props?.status === "1"
    ? "#0071c7"
    : props?.status === "2"
      ? "#07b141"
      : props?.status === "3"
        ? "#000"
        : props?.status === "4"
          ? "#ff0f0f"
          : props?.status === "5" && "#4d4d4d"} !important;
`

export const InformationsRow = styled(Row)`

@media(min-width: 1600px){
display: flex;
}
`

export const Column = styled(Col)`

  @media(min-width: 1200px){
  overflow-x: hidden;
  max-height: 69vh;
  }
  @media(min-width: 2200px){
  max-height: 80vh;
  }

`

export const LoadOrderRow = styled(Row)`
max-height: 40vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 425px){
  max-height: 60vh;
 }

 @media(min-width: 576px){
  max-height: 70vh;
 }

 @media(min-width: 2200px){
  max-height: 81vh;
 }

`
export const BorderCard = styled.div`
border-top: 1px solid var(--color-gray-10);

margin-bottom: 15px;
`

export const BorderContainer = styled.div`
border-bottom: 0.5px solid var(--color-gray-10);
margin-bottom:1rem;
padding-bottom: 1rem;

h1{
  font-size: 1.3rem;
}
`

export const TextArea = styled(Form.Control)`
  overflow: auto;
  min-height: 150px;
  border-left: 1px solid #ff0f0f;
  border-top: 0.5px solid var(--color-gray-11);
  border-bottom: 0.5px solid var(--color-gray-11);
  border-right: 0.5px solid var(--color-gray-11);
  padding: 0.3rem;
`


export const FontInputTitle = styled(Form.Label)`
  font-size: 1rem;
  color: var(--color-gray-15);
`