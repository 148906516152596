import { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { useMap } from "react-leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";

const LeafletRoutingMachine = ({
  origin,
  destiny,
  currentAddress,
  RealTimeLocationIcon,
  show = false,
  segments,
  routesSegments,
  OriginIcon,
  isFetching,
}) => {
  const [realPosition, setRealPosition] = useState(currentAddress ?? origin);

  const map = useMap();

  useEffect(() => {
    setRealPosition(currentAddress ?? origin);
  }, [currentAddress, origin, isFetching]);

  useEffect(() => {
    currentAddress && currentAddress?.lat && currentAddress?.desvio_rota
      ? L.marker([realPosition?.lat, realPosition?.lng], {
          icon: RealTimeLocationIcon,
        })
          .addTo(map)
          .bindPopup(
            "<div><span style='color: red;'>⚠️</span> Motorista fora de rota</div>"
          )
          .openPopup()
      : L.marker([realPosition?.lat, realPosition?.lng], {
          icon: RealTimeLocationIcon,
        }).addTo(map);
  }, [
    RealTimeLocationIcon,
    currentAddress,
    map,
    realPosition?.lat,
    realPosition?.lng,
    isFetching,
  ]);

  useEffect(() => {
    routesSegments &&
      OriginIcon &&
      routesSegments.length > 1 &&
      L.marker([routesSegments[0][0], routesSegments[0][1]]).addTo(map);

    const lineOptions = {
      styles: [
        {
          color: "#55caf4",
          weight: 4,
          opacity: 0.7,
          zIndex: 9999,
        },
      ],
    };

    const routeSegmentLine = L.Routing.control({
      waypoints:
        routesSegments &&
        routesSegments.map((point) => L.latLng(point[0], point[1])),

      serviceUrl: `${process.env.REACT_APP_OSRM}${currentAddress?.lat},${currentAddress?.lng};${destiny?.lat},${destiny?.lng}?steps=true`,

      lineOptions: lineOptions,
      router: L.Routing.osrmv1({
        language: "pt-BR",
        profile: "car",
        timeout: 30 * 1000,
        alternatives: false,
        showAlternatives: false,
        routingOptions: {
          steps: true,
        },
      }),

      routeWhileDragging: false,
      addWaypoints: false,
      marker: false,
      createMarker: function () {
        return null;
      },
      draggableWaypoints: false,
      showAlternatives: false,
      instructions: false,
      show: false,
    });

    routesSegments && routesSegments.length > 1 && routeSegmentLine.addTo(map);
  }, [
    RealTimeLocationIcon,
    map,
    realPosition?.lat,
    realPosition?.lng,
    origin,
    destiny,
    currentAddress,
    show,
    segments,
    routesSegments,
    OriginIcon,
  ]);

  return null;
};
export default LeafletRoutingMachine;
