import { useNavigate, useParams } from "react-router-dom";
import Dashboard from "../../../../../Shared/layout";
import { useDrivers } from "../../../../../hooks/DriverProvider";
import { useCallback, useEffect, useState } from "react";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { Card, Form } from "react-bootstrap";

import { AlertComponent as Alert } from "../../../../../Shared/Components/Alerts";
import { useForm } from "react-hook-form";
import DriverForm from "../components/DriversForms/DriverForm";
import ImageModal from "../../../../../Shared/Components/ImageModal/ImageModal";
import ControlDriverCard from "../../../../../Pages/Dashboard/RegistrationManagement/Drivers/components/ControlDriverCard/ControlDriverCard";
import PersonalInformationForm from "../components/DriversForms/PersonalInformationForm";
import { Column, UpdateDriversRow } from "./styles";

const UpdateDrivers = () => {
  const [showImageModal, setShowImageModal] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    driver,
    isLoading,
    showAlert,
    msgErro,
    variantAlert,
    submitCount,
    isSubmitting,
    getDriver,
    updateDriver,
  } = useDrivers();

  useEffect(() => {
    getDriver(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [currentImage, setCurrentImage] = useState(driver?.foto_cnh);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (id, data) => {
    if (data.foto_cnh.length === 0) {
      delete data.foto_cnh;
    }

    for (const key in data) {
      if (
        data[key] === "" ||
        data[key] === undefined ||
        data[key] === "Selecione a nacionalidade" ||
        data[key] === "Selecione o gênero"
      ) {
        delete data[key];
      }
    }

    delete data.cpf;
    delete data.nome;

    data.dt_validade_cnh =
      data.dt_validade_cnh && new Date(data?.dt_validade_cnh).toISOString();

    data.dt_emissao_cnh =
      data.dt_emissao_cnh && new Date(data?.dt_emissao_cnh).toISOString();

    data.dt_primeira_cnh =
      data.dt_primeira_cnh && new Date(data?.dt_primeira_cnh).toISOString();

    data.dt_nascimento =
      data.dt_nascimento && new Date(data?.dt_nascimento).toISOString();

    data.bloqueado_adm = data?.bloqueado_adm === true ? "S" : "N";

    data.numero_celular = data.numero_celular.replace(/[^0-9]/g, "");
    data.cep = data.cep.replace(/\D+/g, "");

    updateDriver(id, data, navigate);
  };

  const handleShowModal = useCallback(() => {
    setShowImageModal(true);
  }, []);

  return (
    <Dashboard>
      {isLoading && <Loading />}
      {!isLoading && driver && (
        <Form
          onSubmit={handleSubmit((data) => onSubmit(driver.id, data))}
          className="mt-2"
        >
          <UpdateDriversRow
            xs={1}
            md={2}
            xl={3}
            className="d-flex justify-content-between h-100 g-2"
          >
            <Column md={3} xl={2}>
              <ControlDriverCard driver={driver} />
            </Column>
            <Column md={9} xl={10} className="d-flex flex-column gap-2">
              <Card
                className="text-center w-100 d-flex flex-column"
                data-testid="driver-form-container"
              >
                <DriverForm
                  register={register}
                  driver={driver}
                  setValue={setValue}
                  errors={errors}
                  handleShowModal={handleShowModal}
                  setCurrentImage={setCurrentImage}
                />
              </Card>
              <PersonalInformationForm
                register={register}
                driver={driver}
                isSubmitting={isSubmitting}
                setValue={setValue}
                cancelRoute="/motoristas-listar"
                errors={errors}
              />
            </Column>
          </UpdateDriversRow>
        </Form>
      )}
      {showAlert && (
        <Alert
          message={msgErro}
          variant={variantAlert}
          showErrorMsg={submitCount}
        />
      )}
      <ImageModal
        show={showImageModal}
        handleClose={() => setShowImageModal(false)}
        driver={driver}
        image={currentImage}
        title="CNH"
      />
    </Dashboard>
  );
};

export default UpdateDrivers;
