import styled from "styled-components";
import Select from "react-select";

export const Title = styled.h1`
  font-size: 0.85rem;
  color: var(--color-gray-15);
  font-weight: normal;
`;

export const CoverImage = styled.div`
  height: 4rem;
  width: 4rem;
  background-size: cover;
  background-position: center center;
  border-radius: 500px;
  background-image: ${(props) =>
    `url(${props.image})`};
`;

export const SelectOptions = styled(Select)`
  font-size: 0.7rem;
`

export const Subtitle = styled.h2`
  font-size: 0.6rem;
`;

export const Document = styled.h4`
  font-size: 0.6rem;
  color: var(--color-gray-11);
`;


export const Status = styled.h5`
  font-size: 0.6rem;
  color: var(--color-gray-12);

  span {
    font-size: 0.6rem;
  }
`;
