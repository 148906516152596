import { memo, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "sweetalert2/dist/sweetalert2.css";

import ProcessesList from "./ProcessesList";
import Dashboard from "../../../../../Shared/layout";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import Search from "../../../../../Shared/Components/Search/Search";
import TablePagination from "../../../../../Shared/Components/PaginationTable/PaginationTable";
import { sortedNames } from "../../../../../Shared/Utils/data";
import { AlertComponent as Alert } from "../../../../../Shared/Components/Alerts";
import { useDrivers } from "../../../../../hooks/DriverProvider";

const OngoingProcesses = () => {
  const [searchDriver, setSearchDriver] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const {
    driversByStatus,
    isLoading,
    showAlert,
    msgErro,
    variantAlert,
    submitCount,
    totalPages,
    getDriversByStatus,
    getTempRegister,
  } = useDrivers();

  useEffect(() => {
    getDriversByStatus(currentPage, searchDriver);
  }, [currentPage, getDriversByStatus, searchDriver]);

  const handleSearchChange = (event) => {
    setSearchDriver(event && event !== "" ? event.target.value : "");
    setCurrentPage(1);
  };

  const onReviewStatus = useMemo(() => {
    if (driversByStatus) {
      return driversByStatus.find((driver) => driver?.status_cadastro === "2r");
    }
  }, [driversByStatus]);

  const waitingForAnalysis = useMemo(() => {
    if (driversByStatus) {
      return driversByStatus.find(
        (driver) =>
          driver?.status_cadastro === "5r" &&
          driver?.processos_cadastro_concluido === false
      );
    }
  }, [driversByStatus]);

  useEffect(() => {
    if (onReviewStatus || waitingForAnalysis) {
      const interval = setInterval(() => {
        getDriversByStatus(currentPage, searchDriver);
      }, 15000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    currentPage,
    searchDriver,
    onReviewStatus,
    waitingForAnalysis,
    getDriversByStatus,
  ]);

  const filteredDrivers =
    driversByStatus &&
    driversByStatus.filter((driver) => {
      return (
        driver?.nome.toLowerCase().includes(searchDriver.toLowerCase()) ||
        driver?.cpf.toLowerCase().includes(searchDriver.toLowerCase())
      );
    });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Dashboard>
      <Row className="d-flex justify-content-end mt-1">
        <Col md={5} xl={3}>
          <Search
            label="Pesquisar motorista por nome ou CPF"
            searchValue={searchDriver}
            handleSearchChange={handleSearchChange}
          />
        </Col>
      </Row>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className="d-flex flex-column">
          {driversByStatus && (
            <ProcessesList
              isLoading={isLoading}
              ongoingProcessesList={sortedNames(filteredDrivers)}
              currentPage={currentPage}
              getTempRegister={getTempRegister}
            />
          )}
          <div className="mt-3">
            {Array.isArray(driversByStatus) && driversByStatus.length > 0 && (
              <TablePagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      )}

      {showAlert && (
        <Alert
          message={msgErro}
          variant={variantAlert}
          showErrorMsg={submitCount}
        />
      )}
    </Dashboard>
  );
};

export default memo(OngoingProcesses);
