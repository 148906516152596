import { Button, Card, ListGroup, Nav } from "react-bootstrap";
import styled from "styled-components";

export const SidebarContainer = styled.div`
  min-height: 100vh;
  padding-top: 10px;
  padding-left: 0;
  z-index: 99;
  background-color:transparent;
  transition: all 0.5s ease;
  width: ${props => props.isClosed ? "115px" : "17vw"};
  
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  flex-basis: 20%;

  span {
    font-size: 0.75rem;
    margin-right: 5px;
  }
`

export const SidebarScroll = styled.div`
 height: 90vh;
  overflow-x: hidden;
  padding-left:0;
`


export const NavbarScroll = styled(Nav)`
 height: 85vh;
  overflow-x: hidden;
`

export const HomeSection = styled.section`
position: fixed;
width: 100%;
height: 100vh;
top:0;
transition: all .5s ease;
z-index: 2;
flex-basis: 80%;
padding-right: 20px;

padding-left: ${props => props.isScreenSmall ? "0px" : props.isClose ? "115px" : "17vw"}

`

export const CloseButton = styled(Button)`
background-color: transparent;
border: transparent;

color: var(--color-blue-80);

transition: transform 300ms;

&:hover {
  background-color: transparent !important;
  border: transparent;
  transform: scale(1.01);
  z-index: 1;
  color: var(--color-blue-80);

}
`
export const ListGroupContainer = styled(ListGroup)`
border: none;
  margin-left: -0.65rem;

 :active{
  color: white;
  background-color: red;
  border-radius: 0 3rem 3rem 0;
 }

 :hover{
  border-radius: 0 3rem 3rem 0;
 }
`

export const SidebarItem = styled.span`
font-size: 3rem;
`


export const Perfil = styled.span`
  color: var(--color-gray-12);
  font-size: 0.75rem;
`;

export const CardContent = styled(Card)`
flex: 1;
border-radius: 1rem;
flex: 1;
`

export const CardContainer = styled.div`
background-color: var(--color-gray-2);
height:100%;
flex: 1;
width: 100%;
padding-right: ${props => props.isScreenSmall ? "0px" : props.isClose ? "115px" : "17vw"}

`
export const CollapseButton = styled(Button)`
&:active,
  &:focus {
    border: none;
    outline: 0;
    box-shadow: 0 0 0 0 transparent !important;
  }
`

export const Version = styled.span`
  font-size: 0.56rem;
  color: var(--color-gray-11);
`