import { convertPercentage, isNumber } from "../../Utils/data";
import { NameSimilarity, NameSimilarityItem } from "./styles";

const NameSimilarityCard = ({ item, label }) => {
  return (
    <NameSimilarity className="d-flex text-center aling-items-center gap-1 px-2 ms-1">
      <span className="text-secondary">
        {label}{" "}
        <NameSimilarityItem item={item}>
          {`${isNumber(item) ? convertPercentage(item) : 0}%`}
        </NameSimilarityItem>
      </span>
    </NameSimilarity>
  );
};

export default NameSimilarityCard;
