import styled from "styled-components";

export const GoogleMapReactContainer = styled.div`
  height: ${props => props.height};
  width: 100%;
`

export const WrapperMarker = styled.div`
 position: absolute;
  transform: translate(-50%, -100%);
`;

export const CoverIcon = styled.div`
  background-image: ${props => `url(${props.icon})`};
  background-size: cover;
  background-position: center center;
  width: ${props => props.size ? props.size : "15px"};
  height: ${props => props.size ? props.size : "15px"}
`

export const SubtitleLabel = styled.span`
  font-size: 0.85rem;
  color: var(--color-gray-15);
`

export const Wrapper = styled.div`
    position: relative;
    z-index:9999;
`;

export const CardStyles = styled.div`
    width: 400px;
    position: absolute;
    bottom: 75px;
    /* transform: translateX(-50%); */
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 20px 20px 0;
        border-color: #ffffff transparent;
        display: block;
        width: 0;
        z-index: 1;
        bottom: -20px;
        left: 51%;
        transform: translateX(-50%);
    }
`;
