import { useCallback } from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { BiBuilding, BiIdCard, BiLandscape } from "react-icons/bi";
import { BsCalendar3, BsGenderAmbiguous } from "react-icons/bs";

import { Border, Item } from "./styles";

import UploadOwnerProodOfAddress from "../components/OwnerForms/UploadOwnerProodOfAddress";
import UploadOwnerImage from "../components/OwnerForms/UploadOwnerImage";

import { allNationalities } from "../../../../../Shared/Utils/nationalities";
import { FaRegDotCircle } from "react-icons/fa";

const DocumentsSection = ({
  owner,
  handleShowOwnerImageModal,
  handleShowOwnerProofOfAddressModal,
}) => {
  const currentValue = useCallback(
    (value, constant) =>
      value ? constant.find((status) => status.value === value)?.label : "--",
    []
  );

  return (
    <Border className="p-3">
      <Row xs={1} xl={2}>
        <Col className="gap-3 h-100">
          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>RG:</h3>
            <h4>{owner?.rg ? owner?.rg : "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BiBuilding size={17} color="#093c5e" />
            <h3>Órgão expedidor do RG:</h3>
            <h4>{owner?.orgao_rg ? owner?.orgao_rg : "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BsCalendar3 size={14} color="#093c5e" />
            <h3>Data de emissão do RG:</h3>
            <h4>
              {owner?.dt_emissao_rg
                ? moment.utc(owner?.dt_emissao_rg).format("DD/MM/YYYY")
                : "--"}
            </h4>
          </Item>

          <Item className="gap-1">
            <BsGenderAmbiguous size={17} color="#093c5e" />
            <h3>Gênero:</h3>
            <h4>
              {owner?.sexo
                ? owner?.sexo === "M"
                  ? "Masculino"
                  : "Feminino"
                : "--"}
            </h4>
          </Item>

          <Item className="gap-1">
            <BiLandscape size={15} color="#093c5e" />
            <h3>País de origem:</h3>
            <h4>
              {owner?.nacionalidade
                ? currentValue(owner?.nacionalidade, allNationalities)
                : "--"}
            </h4>
          </Item>
          <Item className="gap-1">
            <FaRegDotCircle size={15} color="#093c5e" />
            <h3>Estado civil:</h3>
            <h4>{owner?.estado_civil ? owner?.estado_civil : "--"}</h4>
          </Item>
        </Col>
        <Col className="gap-3 d-md-flex h-100">
          <UploadOwnerImage
            width="10.4rem"
            height="10.4rem"
            align="start"
            owner={owner}
            hideInput={true}
            handleShowModal={handleShowOwnerImageModal}
            image={owner?.foto_proprietario}
          />
          {owner?.foto_comprovante_endereco &&
            owner?.foto_comprovante_endereco !== "" && (
              <UploadOwnerProodOfAddress
                width="10.4rem"
                height="10.4rem"
                align="start"
                owner={owner}
                hideInput={true}
                handleShowModal={handleShowOwnerProofOfAddressModal}
                image={owner?.foto_comprovante_endereco}
              />
            )}
        </Col>
      </Row>
    </Border>
  );
};

export default DocumentsSection;
