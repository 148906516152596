import styled from "styled-components";

export const BorderCard = styled.div`
  border: 2px solid var(--color-green);
  border-radius: 4px;
  padding: 15px;

  span{
    font-size: 1.3rem;
  }
`