import { BsFillPersonFill, BsTelephoneFill } from "react-icons/bs";
import { BiIdCard } from "react-icons/bi";

import { Item } from "./styles";

import {
  formatCPF,
  formatNameString,
  formatPhoneNumber,
} from "../../../../../../../Shared/Utils/data";

export const AboutInformationSection = ({ driver }) => {
  return (
    <div className="p-2">
      <Item className="gap-1">
        <BsFillPersonFill size={15} color="#093c5e" />
        <h3>Nome completo:</h3>
        <h4>{driver?.nome ? formatNameString(driver?.nome) : "--"}</h4>
      </Item>

      <Item className="gap-1">
        <BiIdCard size={17} color="#093c5e" />
        <h3>CPF:</h3>
        <h4>{driver?.cpf ? formatCPF(driver?.cpf) : "--"}</h4>
      </Item>

      <Item className="gap-1">
        <BsTelephoneFill size={14} color="#093c5e" />
        <h3>Celular</h3>
        <h4>
          {driver?.numero_celular
            ? formatPhoneNumber(driver?.numero_celular)
            : "--"}
        </h4>
      </Item>
    </div>
  );
};
