import { Card, Row } from "react-bootstrap";
import styled from "styled-components";

export const ContainerRow = styled(Row)`
  max-height: 65vh;
  overflow-x: hidden;
  
  @media(min-width: 1024px){
    max-height: 70vh;
  }
`

export const Title = styled.h1`
  font-size: 1rem;
  color: var(--color-gray-15);
`

export const Container = styled.div`
  max-height: 60vh;
  overflow-x: hidden;
`

export const Information = styled.span`
  font-size: 0.875rem;
  color: var(--color-gray-14);
`

export const ContainerCard = styled(Card)`
  border: none;
  background-color: var(--color-gray-9)
`

export const LabelCard = styled.div`
  background-color: var(--color-gray-10);
  margin-bottom: 10px;
`