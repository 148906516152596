import React, { Fragment } from "react";
import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import validator from "validator";
import { SubmitButton } from "../../../../Shared/Components/SubmitButton/SubmitButton";

import {
  formatString,
  sortedFantasyNames,
  sortedNames,
} from "../../../../Shared/Utils/data";
import { FormUserContainer } from "./styles";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";

const FormUser = ({
  handleSubmit,
  register,
  fields,
  errors,
  onSubmit,
  perfis,
  companies,
  isSubmitting,
  scopes,
  units,
  getUnitsByIdCompany,
}) => {
  const perfisWithScopes = (scope) =>
    perfis.filter((perfil) => perfil?.Escopo?.nome === scope);

  return (
    <FormUserContainer className="mt-3">
      <Form as={Row} onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Col} md={12} className="mb-4" controlId="formNome">
          <FloatingLabel controlId="formNome" label="Nome">
            <Form.Control
              type="nome"
              placeholder="Escreva o nome"
              className="is-required"
              {...register("nome", fields.nome)}
            />
            {errors?.nome?.type === "required" && (
              <Form.Text className="text-danger">
                O nome é obrigatório.
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={12} className="mb-4" controlId="formEmail">
          <FloatingLabel controlId="formEmail" label="Email">
            <Form.Control
              type="email"
              placeholder="Enter email"
              className="is-required"
              {...register("email", {
                required: "O e-mail é obrigatório",
                validate: (value) => {
                  if (value?.length > 0 && !validator.isEmail(value)) {
                    return "E-mail inválido";
                  }
                  return true;
                },
              })}
            />
            {errors?.email && (
              <Form.Text className="text-danger">
                {errors?.email?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={4} className="mb-4" controlId="formPerfil">
          <FloatingLabel controlId="formPerfil" label="Perfil">
            <Form.Select
              type="number"
              inputMode="numeric"
              className="is-required"
              {...register("id_perfil", fields.id_perfil)}
            >
              <option value={null}>Selecione o Perfil</option>
              {sortedNames(scopes).map((scope) => {
                return (
                  <Fragment key={scope?.id}>
                    <option disabled>{formatString(scope?.nome)}</option>
                    {perfisWithScopes(scope?.nome).map((perfil) => (
                      <option key={perfil.id} value={perfil?.id}>
                        {perfil?.nome}
                      </option>
                    ))}
                  </Fragment>
                );
              })}
            </Form.Select>
            {errors?.id_perfil?.type === "pattern" && (
              <Form.Text className="text-danger">
                O perfil é obrigatório.
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={4} className="mb-4" controlId="formUnidade">
          <FloatingLabel controlId="formUnidade" label="Empresa">
            <Form.Select
              aria-label="company"
              onChange={(e) => getUnitsByIdCompany(parseInt(e.target.value))}
            >
              <option>Selecione a Empresa</option>
              {sortedFantasyNames(companies).map((company) => (
                <option key={company?.id} value={company?.id}>
                  {company?.nome_fantasia}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={4} className="mb-4" controlId="formUnidade">
          <FloatingLabel controlId="formUnidade" label="Unidade">
            <Form.Select
              aria-label="unit"
              {...register("id_unidade")}
              disabled={units?.length === 0 || !units}
            >
              <option value={null}>Selecione a Unidade</option>
              {units &&
                units?.length > 0 &&
                sortedNames(units).map((unit) => (
                  <option key={unit?.id} value={unit.id}>
                    {unit?.nome}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} className="mb-4" controlId="formAtivo">
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Inativo / Ativo"
            {...register("ativo")}
          />
        </Form.Group>

        <Form.Group className="d-flex gap-2">
          <SubmitButton
            labelButton="Salvar novo usuário"
            isSubmitting={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          />
          <ButtonLink label="Cancelar" link="/usuarios" fontSize="0.7rem" />
        </Form.Group>
      </Form>
    </FormUserContainer>
  );
};

export default FormUser;
