import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { Button, Row, Col } from "react-bootstrap";
import { BiErrorAlt } from "react-icons/bi";
import { BsQuestionCircle } from "react-icons/bs";
import { MdOutlineDelete } from "react-icons/md";
import { MdLogout } from "react-icons/md";
import { GrClose } from "react-icons/gr";

function AlertComponent({ message, variant, showErrorMsg }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
    setTimeout(() => setShow(false), 3000);
  }, [showErrorMsg]);

  return (
    <Alert
      style={{ zIndex: "999999" }}
      variant={variant}
      onClose={() => setShow(false)}
      dismissible
      show={show}
    >
      {variant === "danger" && <BiErrorAlt />} {message}
    </Alert>
  );
}

function AlertQuestionComponent({
  title,
  subtitle,
  showAlert,
  cancel,
  callback,
}) {
  const [show, setShow] = useState(showAlert);
  useEffect(() => {
    setShow(showAlert);
  }, [showAlert]);

  return (
    <>
      <Alert variant={"warning"} show={show} style={{ zIndex: "999999" }}>
        <Row style={{ width: "35rem" }}>
          <Col
            md={3}
            className="d-flex justify-content-center align-items-center"
          >
            <BsQuestionCircle style={{ fontSize: "4rem" }} />
          </Col>
          <Col>
            <Row>
              <Col md={12}>
                <h5>{title}</h5>
              </Col>
              <Col md={12}>{subtitle}</Col>
              <Col md={12}>
                <Button
                  variant="danger"
                  className="w-100 mb-1"
                  onClick={callback}
                >
                  <MdOutlineDelete />
                  Confirmar
                </Button>
                <Button variant="light" className="w-100" onClick={cancel}>
                  <GrClose />
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Alert>
    </>
  );
}

function AlertQuestionComponentDesconect({
  title,
  subtitle,
  showAlert,
  cancel,
  callback,
}) {
  const [show, setShow] = useState(showAlert);
  useEffect(() => {
    setShow(showAlert);
  }, [showAlert]);

  return (
    <>
      <Alert style={{ zIndex: "4" }} variant={"warning"} show={show}>
        <Row>
          <Col
            md={3}
            className="d-flex justify-content-center align-items-center"
          >
            <BsQuestionCircle style={{ fontSize: "4rem" }} />
          </Col>
          <Col>
            <Row>
              <Col md={12}>
                <h5>{title}</h5>
              </Col>
              <Col md={12}>{subtitle}</Col>
              <Col md={12}>
                <Button
                  variant="danger"
                  className="w-100 mb-1"
                  onClick={callback}
                >
                  <MdLogout className="mx-2" />
                  Confirmar
                </Button>
                <Button variant="light" className="w-100" onClick={cancel}>
                  <GrClose className="mx-2" />
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Alert>
    </>
  );
}

export {
  AlertComponent,
  AlertQuestionComponent,
  AlertQuestionComponentDesconect,
};
