import { Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';

export const FormControl = styled(Form.Control)`
      border: none;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  z-index:1;
  &:hover {
    cursor:text;
  }
`;

export const InputControl = styled(InputGroup)`
      border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  z-index:1;
  
  &:hover {
    cursor:text;
  }

  input::placeholder {
    font-size: 0.8rem;
    z-index:1;
  }

`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--color-gray-12);

  &:hover{
    background-color: transparent;
  border: none;
  color: var(--color-gray-12);
  }


`
