import { memo, useEffect, useMemo } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Spinner } from "react-bootstrap";

import { IoMdCalendar } from "react-icons/io";
import { MdOutlineLocationCity } from "react-icons/md";
import { BsBank2, BsFillPeopleFill } from "react-icons/bs";
import { GrCircleInformation, GrUpgrade } from "react-icons/gr";
import { FaBalanceScale, FaDotCircle, FaRegDotCircle } from "react-icons/fa";

import { ItemLabel, MainContainer } from "./styles";

import Dashboard from "../../../../../Shared/layout";
import { useDriversValidation } from "../../../../../hooks/DriverValidationProvider";

import {
  formatCnjnumber,
  formatLowerCaseString,
  formatNameString,
} from "../../../../../Shared/Utils/data";

const PartiesDetais = ({ item }) => {
  return (
    <div className="d-flex gap-3 align-items-start">
      <span>{item.icon}</span>

      <div className="d-flex flex-column gap-2">
        <ItemLabel className="fw-bold">{item.title}</ItemLabel>
        {item.label.map((label, index) => (
          <ItemLabel key={index}>{formatNameString(label?.nome)}</ItemLabel>
        ))}
      </div>
    </div>
  );
};

const InformationsDetails = ({ item }) => {
  return (
    <>
      {item?.value && (
        <div className="d-flex gap-3 align-items-start">
          <span>{item.icon}</span>
          <div className="d-flex flex-column gap-1">
            <ItemLabel className="fw-bold">{item?.label ?? "--"}</ItemLabel>
            {<ItemLabel className={item?.className}>{item?.value}</ItemLabel>}
          </div>
        </div>
      )}
    </>
  );
};

const JudicialProcessDetails = () => {
  const { id, token } = useParams();

  const { judicialProcess, judicialProcessLoading, getJudicialProcessConsult } =
    useDriversValidation();

  useEffect(() => {
    getJudicialProcessConsult(id, token);
  }, [getJudicialProcessConsult, id, token]);

  const CNJThemes = useMemo(() => {
    if (
      judicialProcess &&
      judicialProcess?.resposta[0] &&
      judicialProcess?.resposta[0]?.assuntosCNJ &&
      judicialProcess?.resposta[0]?.assuntosCNJ.length > 0
    ) {
      const temas = judicialProcess?.resposta[0]?.assuntosCNJ.map((item) =>
        formatLowerCaseString(item.titulo)
      );

      const temasUnicos = temas.filter(
        (item, index) => temas.indexOf(item) === index
      );

      return temasUnicos;
    } else {
      return [];
    }
  }, [judicialProcess]);

  const endDate =
    judicialProcess?.resposta[0]?.statusPredictus?.julgamentos &&
    judicialProcess?.resposta[0]?.statusPredictus?.julgamentos.length > 0 &&
    judicialProcess?.resposta[0]?.statusPredictus?.julgamentos.reduce(
      (acc, current) => {
        if (
          !acc ||
          new Date(current.dataJulgamento) > new Date(acc.dataJulgamento)
        ) {
          return current;
        } else {
          return acc;
        }
      }
    );

  const date = useMemo(
    () =>
      judicialProcess &&
      judicialProcess?.dt_atualizacao &&
      moment(judicialProcess?.dt_atualizacao).format("DD/MM/YYYY"),
    [judicialProcess]
  );

  return (
    <Dashboard>
      <MainContainer>
        {judicialProcessLoading && (
          <div className="d-flex align-items-center justify-content-center h-100 gap-2">
            <Spinner variant="warning" />
            <span className="fst-italic">Carregando...</span>
          </div>
        )}
        {judicialProcess && !judicialProcessLoading && (
          <Container className="mt-4 gap-3 d-flex flex-column">
            <div className="d-flex gap-3">
              <span className="fst-italic fs-6">Última consulta {date}</span>
            </div>
            <Row xs={1} xl={2} className="d-flex g-3">
              <Col>
                <InformationsDetails
                  item={{
                    icon: <FaRegDotCircle />,
                    value: formatCnjnumber(
                      judicialProcess?.resposta[0]?.numeroProcessoUnico
                    ),
                    label: "Nº processo",
                  }}
                />
              </Col>
              <Col>
                <InformationsDetails
                  item={{
                    label: "Abertura",
                    icon: <IoMdCalendar />,
                    value:
                      judicialProcess?.resposta[0]?.movimentos &&
                      judicialProcess?.resposta[0]?.movimentos.length > 0 &&
                      moment
                        .utc(
                          judicialProcess?.resposta[0]?.movimentos[
                            judicialProcess?.resposta[0]?.movimentos.length - 1
                          ]?.data
                        )
                        .format("DD/MM/YYYY"),
                  }}
                />
              </Col>
            </Row>

            <Row xs={1} xl={2} className="d-flex g-3">
              <Col>
                <InformationsDetails
                  item={{
                    icon: <BsBank2 size={17.6} />,
                    value: judicialProcess?.resposta[0]?.tribunal,
                    label: "Tribunal",
                  }}
                />
              </Col>
              <Col>
                <InformationsDetails
                  item={{
                    label: "Status",
                    icon: <GrUpgrade />,
                    value:
                      judicialProcess?.resposta[0]?.statusPredictus
                        ?.statusProcesso &&
                      formatNameString(
                        judicialProcess?.resposta[0]?.statusPredictus
                          ?.statusProcesso
                      ),
                  }}
                />
              </Col>
            </Row>
            <Row xs={1} xl={2} className="d-flex g-3">
              <Col>
                <InformationsDetails
                  item={{
                    icon: <FaDotCircle />,
                    value: judicialProcess?.resposta[0]?.classeProcessual
                      ?.codigoCNJ
                      ? `${
                          judicialProcess?.resposta[0]?.classeProcessual
                            ?.codigoCNJ
                        } - ${formatNameString(
                          judicialProcess?.resposta[0]?.classeProcessual?.nome
                        )}`
                      : formatNameString(
                          judicialProcess?.resposta[0]?.classeProcessual?.nome
                        ),
                    label: "Descrição",
                    className: `${
                      judicialProcess?.tipo_processo?.atencao && "text-danger"
                    }`,
                  }}
                />
              </Col>
              <Col>
                <InformationsDetails
                  item={{
                    label: "Área",
                    icon: <GrCircleInformation />,
                    value:
                      judicialProcess?.resposta[0]?.statusPredictus
                        ?.ramoDireito &&
                      formatNameString(
                        judicialProcess?.resposta[0]?.statusPredictus
                          ?.ramoDireito
                      ),
                  }}
                />
              </Col>
            </Row>
            <Row className="d-flex g-3"></Row>
            <Row xs={1} xl={2} className="d-flex g-3">
              <Col>
                <PartiesDetais
                  item={{
                    title: "Autor",
                    icon: <BsFillPeopleFill />,
                    className: "fw-bold",
                    label: judicialProcess?.resposta[0]?.partes.filter(
                      (part) => part.polo === "ATIVO"
                    ),
                  }}
                />
              </Col>
              <Col>
                <PartiesDetais
                  item={{
                    title: "Réu",
                    icon: <BsFillPeopleFill />,
                    className: "fw-bold",
                    label: judicialProcess?.resposta[0]?.partes.filter(
                      (part) => part.polo === "PASSIVO"
                    ),
                  }}
                />
              </Col>
            </Row>
            <Row xs={1} xl={2} className="d-flex g-3">
              <Col>
                <InformationsDetails
                  item={{
                    label: "Instância",
                    icon: <GrCircleInformation />,
                    value:
                      judicialProcess?.resposta[0]?.segmento &&
                      formatNameString(judicialProcess?.resposta[0]?.segmento),
                  }}
                />
              </Col>
              <Col>
                <InformationsDetails
                  item={{
                    label: "Comarca",
                    icon: <MdOutlineLocationCity />,
                    value:
                      judicialProcess?.resposta[0]?.orgaoJulgador &&
                      formatNameString(
                        judicialProcess?.resposta[0]?.orgaoJulgador
                      ),
                  }}
                />
              </Col>
            </Row>
            <Row xs={1} xl={2} className="d-flex g-3">
              <Col>
                <InformationsDetails
                  item={{
                    label: "Motivos",
                    value: CNJThemes.join(", "),
                    icon: <FaBalanceScale size={17.6} />,
                  }}
                />
              </Col>
              <Col>
                <InformationsDetails
                  item={{
                    label: "Encerramento",
                    icon: <IoMdCalendar />,
                    value:
                      judicialProcess?.resposta[0]?.statusPredictus
                        ?.julgamentos &&
                      judicialProcess?.resposta[0]?.statusPredictus?.julgamentos
                        .length > 0 &&
                      endDate.dataJulgamento &&
                      moment.utc(endDate.dataJulgamento).format("DD/MM/YYYY"),
                  }}
                />
              </Col>
            </Row>
          </Container>
        )}
      </MainContainer>
    </Dashboard>
  );
};

export default memo(JudicialProcessDetails);
