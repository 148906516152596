import { Button, Card, Form, FormControl } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import styled from "styled-components";

export const BorderRow = styled.div`
  border: 1px solid var(--color-gray-10);
  border-radius: 4px;
`

export const BorderCard = styled(Card)`
  &:hover {
    z-index: 1;
    box-shadow: 0 0 5px;
  }

  h3 {
    font-size: 1rem;
    font-weight: normal;
  }

  h4 {
    font-size: 0.7rem;
    color: var(--color-gray-11);
  }

  h5 {
    font-size: 0.7rem;
    color: var(--color-gray-12);

    span {
      font-size: 0.7rem;
    }
  }

  h6 {
    font-size: 0.7rem;
    color: var(--color-gray-11);

    span {
      font-size: 0.7rem;
    }
  }
`;

export const PageTitle = styled(Card.Title)`
  color: var(--color-gray-15);
  font-weight: normal;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

export const DeleteIconButton = styled(FaTrashAlt)`
  color: var(--color-gray-15);
  font-size: 0.9rem;
  margin-top: 4px;
`;

export const DeleteCnhButton = styled(Button)`
  transition: transform 300ms;
  background-color: transparent;
  border: none;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
    background-color: transparent;
    border: none;
  }

  label {
    color: var(--color-gray-15);
    font-size: 0.9rem;
  }
`;

export const UploadImageButton = styled.div`
  background-color: transparent !important;
  width: ${(props) => (props.width ? props.width : "7rem")};
  padding: 3px;
  border-left: 2px solid red;
  border-top: 1px solid var(--color-gray-15);
  border-right: 1px solid var(--color-gray-15);
  border-bottom: 1px solid var(--color-gray-15);
 

  font-size: 0.8rem;
  transition: transform 300ms;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    color: black;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 0.7rem;
  color: red;
`;

export const AlertIcon = styled(FiAlertTriangle)`
  color: red;
`;
export const ShowModalButton = styled(Button)`
  border: none;
  background-color: transparent;
  color: var(--color-gray-15);
  font-size: 0.8rem;
  transition: transform 300ms;

  &:hover {
    border: none;
    background-color: transparent;
    color: var(--color-gray-15);
    transform: scale(1.05);
    z-index: 1;
  }

  &:active,
  &:focus {
    background-color: transparent !important;
  }
`;

export const CoverImage = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding-bottom: ${(props) => props.paddingBottom};

  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center center;
`;

export const ImageTitle = styled.p`
  font-size: 0.8rem;
  color: var(--color-gray-15);
`;

export const FormControlInput = styled(FormControl)`
  font-size: 0.7rem;
  display: flex;
`;

export const FormLabelText = styled(Form.Label)`
  color: var(--color-gray-12);
  font-size: 0.9rem;
`;

export const PdfLoadingContainer = styled.div`
  background-color: var(--color-gray-11);
  color: var(--color-gray-10);
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.h1`
  color: var(--color-gray-15);
  font-weight: normal;
  font-size: 1.1rem;
`;