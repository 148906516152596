import { IoWarningOutline } from "react-icons/io5";
import { SeeMoreButton, WarningCardBorder, WarningLabel } from "./styles";
import { IoIosArrowForward } from "react-icons/io";

export const WarningCard = ({ label, handleSeeMore, hasButton, noBorder }) => {
  return (
    <WarningCardBorder
      onClick={handleSeeMore}
      className={`rounded p-1 mb-2 w-100 d-xl-flex justify-content-between align-items-center ${
        noBorder && "border-0"
      }`}
    >
      <div className="gap-1 d-flex align-items-center">
        <WarningLabel className="gap-1 d-flex align-items-center">
          <IoWarningOutline color="red" size={15} />
          <span>{label}</span>
        </WarningLabel>
      </div>
      {hasButton && (
        <SeeMoreButton>
          Ver mais <IoIosArrowForward />
        </SeeMoreButton>
      )}
    </WarningCardBorder>
  );
};
