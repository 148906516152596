import { Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";



export const PageTitle = styled(Card.Title)`
color: var(--color-gray-12);
font-weight: normal;
font-size: 1rem;
margin-bottom: 1rem;
`

export const Item = styled.div`
padding: 0;
margin-bottom: 1.3rem;
display: flex;
align-items: center;

h4 {
  font-size: 1rem;
  color: black;
  display: flex;
  margin-left: 0.2rem;
  margin-bottom:0;
  text-align: left;

  @media (max-width: 425px) {
    font-size: 0.875rem;
  }
}

h3 {
  font-size: 1rem;
  display: flex;
  margin-bottom:0;
  color: var(--color-blue-90);
  text-align: left;

  @media (max-width: 425px) {
    font-size: 0.875rem;
  }
}
`

export const Column = styled(Col)`

  /* @media(min-width: 768px){
  overflow-x: hidden;
  max-height: 70vh;
  }
  @media(min-width: 2200px){
  max-height: 74vh;
} */
  
`

export const OwnerInformationsRow = styled(Row)`
overflow-x: hidden;
 max-height: 50vh;
flex: 1;


@media(min-width: 475px){
  max-height: 60vh;
}
@media(min-width: 576px){
  max-height: 70vh;
}
@media(min-width: 768px){
  max-height: 70vh;
}
@media(min-width: 2200px){
  max-height: 75vh;
}
 
`

export const Border = styled.div`
border-top: 1px solid var(--color-gray-10);
padding-top: 15px;
`

export const SectionContainer = styled(Card)`
background-color: #FFFAFA;
  border: none;
`

export const TitleContainer = styled.div`
  background-color: var(--color-gray-2);
  padding: 10px;
`

export const InfoTitle = styled.span`
  color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;