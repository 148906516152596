import styled, { keyframes } from "styled-components";

export const Steps = styled.div`
  display: flex;
  justify-content: center;
  
  margin-top: 0.25rem;
`;

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  background-color: ${({ activeStep }) => activeStep ? "var(--color-green)" : "var(--color-blue-70)"};
  color: white;
  font-weight: bold;
  transition: all 0.3s ease;
  animation: ${({ isRotete }) => isRotete && rotate} 1s linear;
  `;

export const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: var(--color-gray-11);
  margin-top: 1.5rem; 
  margin-bottom: 1.5rem; 
  margin-right: 0.25rem;
  margin-left: 0.25rem;
`;