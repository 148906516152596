import { Accordion } from "react-bootstrap";

import TrucksInformationsForm from "./TrucksInformationsForm";

const TrucksInformations = ({
  activeKey,
  control,
  drivers,
  errors,
  handleActiveTruckClick,
  handleDeleteData,
  owners,
  register,
  setValue,
  showTruck,
  vehicle,
  visibleTrucks,
}) => {
  return (
    <>
      <Accordion activeKey={activeKey}>
        {Array.isArray(visibleTrucks) &&
          visibleTrucks?.length > 0 &&
          visibleTrucks.map((visibleTruck) => (
            <Accordion.Item
              eventKey={visibleTruck}
              key={visibleTruck}
              id={`accordion-item-${visibleTruck}`}
            >
              <TrucksInformationsForm
                control={control}
                drivers={drivers}
                errors={errors}
                handleActiveTruckClick={handleActiveTruckClick}
                handleDeleteData={handleDeleteData}
                index={visibleTruck}
                owners={owners}
                register={register}
                setValue={setValue}
                showTruck={showTruck}
                vehicle={vehicle}
              />
            </Accordion.Item>
          ))}
      </Accordion>
    </>
  );
};

export default TrucksInformations;
