import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest from "../Shared/Utils/Request";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export const DriverValidationContext = createContext({
  hasError: false,
  hasBiometricError: false,
  validationsDrivers: [],
  driverValidation: null,
  cnhValidationMessage: "",
  loadingCnhValidation: false,
  loadingDriverValidation: false,
  loadingBiometricValidation: false,
  judicialProcess: null,
  judicialProcessLoading: false,
  postCnhValidation: () => null,
  getDriverValidation: () => null,
  setBiometricMessage: () => null,
  setCnhValidationMessage: () => null,
  postBiometricValidation: () => null,
  postJudicialProcessConsult: () => null,
  getJudicialProcessConsult: () => null,
});

export const useDriversValidation = () => {
  const context = useContext(DriverValidationContext);

  if (!context) {
    throw new Error(
      "useDriverValidation must be within DriverValidationProvider"
    );
  }

  return context;
};

export const DriverValidationProvider = ({ children }) => {
  const [driverValidation, setDriverValidation] = useState(null);
  const [loadingDriverValidation, setLoadingDriverValidation] = useState(false);
  const [loadingCnhValidation, setLoadingCnhValidation] = useState(false);

  const [biometricMessage, setBiometricMessage] = useState(
    "Nenhum registro encontrado"
  );

  const [cnhValidationMessage, setCnhValidationMessage] = useState(null);

  const [loadingBiometricValidation, setLoadingBiometricValidation] =
    useState(false);

  const [hasError, setHasError] = useState(false);

  const [judicialProcess, setJudicialProcess] = useState(null);
  const [judicialProcessLoading, setJudicialProcessLoading] = useState(false);

  const getDriverValidation = useCallback(async (id) => {
    try {
      setHasError(false);
      setLoadingDriverValidation(true);

      const response = await ApiRequest({
        path: `validacoes/motorista/${id}`,
        method: "GET",
      });

      if (response?.data?.validacao_biometria?.status === "2") {
        const message = "Erro na validação da biometria";

        setBiometricMessage(message);
      }

      if (response?.data?.validacao_cnh?.status === "2") {
        const message = "Erro na validação da CNH";

        setCnhValidationMessage(message);
      }
      setDriverValidation(response?.data);
    } catch (error) {
      setDriverValidation([]);
    } finally {
      setLoadingDriverValidation(false);
    }
  }, []);

  const postCnhValidation = useCallback(async (id) => {
    try {
      setLoadingCnhValidation(true);

      await ApiRequest({
        path: `validacao/cnh/${id}`,
        method: "POST",
      });

      const responseValidate = await ApiRequest({
        path: `validacoes/motorista/${id}`,
        method: "GET",
      });

      let validate = responseValidate?.data;

      let validationStatus = validate?.validacao_cnh?.concluido ?? false;

      const checkValidation = async () => {
        const responseValidate = await ApiRequest({
          path: `validacoes/motorista/${id}`,
          method: "GET",
        });

        const validate = responseValidate?.data;
        validationStatus = validate?.validacao_cnh?.concluido ?? false;
      };

      while (validationStatus === false) {
        await checkValidation();
        await new Promise((resolve) => setTimeout(resolve, 20000));
      }

      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Validação da CNH realizada com sucesso",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      }, 250);
    } catch (error) {
      window.scrollTo(0, 0);

      setCnhValidationMessage("Erro na validação da CNH");

      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: "Erro na validação da CNH",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    } finally {
      setLoadingCnhValidation(false);
    }
  }, []);

  const postBiometricValidation = useCallback(async (id) => {
    try {
      setLoadingBiometricValidation(true);

      await ApiRequest({
        path: `validacao/biometria/${id}`,
        method: "POST",
      });

      const responseValidate = await ApiRequest({
        path: `validacoes/motorista/${id}`,
        method: "GET",
      });

      let validate = responseValidate?.data;

      let validationStatus = validate?.validacao_biometria?.concluido ?? false;

      const checkValidation = async () => {
        const responseValidate = await ApiRequest({
          path: `validacoes/motorista/${id}`,
          method: "GET",
        });

        const validate = responseValidate?.data;
        validationStatus = validate?.validacao_biometria?.concluido ?? false;
      };

      while (validationStatus === false) {
        await checkValidation();
        await new Promise((resolve) => setTimeout(resolve, 20000));
      }

      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Validação da biometria realizada com sucesso",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      }, 250);
    } catch (error) {
      window.scrollTo(0, 0);

      setBiometricMessage("Erro na validação da biometria");

      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: "Erro na validação da biometria",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    } finally {
      setLoadingBiometricValidation(false);
    }
  }, []);

  const getJudicialProcessConsult = useCallback(
    async (id, numeroProcessoUnico) => {
      try {
        setJudicialProcessLoading(true);

        const response = await ApiRequest({
          path: numeroProcessoUnico
            ? `/processos_judiciais/motorista/${id}?numero_processo=${numeroProcessoUnico}`
            : `/processos_judiciais/motorista/${id}`,
          method: "GET",
        });

        setJudicialProcess(response?.data);
      } catch (error) {
        setJudicialProcess([]);
      } finally {
        setJudicialProcessLoading(false);
      }
    },
    []
  );

  const postJudicialProcessConsult = useCallback(
    async (id) => {
      try {
        setJudicialProcessLoading(true);

        await ApiRequest({
          path: `/processos_judiciais/motorista/${id}`,
          method: "POST",
        });

        await getJudicialProcessConsult(id);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Erro ao realizar a consulta judicial",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setJudicialProcessLoading(false);
      }
    },
    [getJudicialProcessConsult]
  );

  const providerValue = useMemo(
    () => ({
      hasError,
      biometricMessage,
      driverValidation,
      loadingCnhValidation,
      cnhValidationMessage,
      loadingDriverValidation,
      loadingBiometricValidation,
      judicialProcess,
      judicialProcessLoading,
      postCnhValidation,
      getDriverValidation,
      setBiometricMessage,
      postBiometricValidation,
      setCnhValidationMessage,
      postJudicialProcessConsult,
      getJudicialProcessConsult,
    }),
    [
      hasError,
      biometricMessage,
      driverValidation,
      loadingCnhValidation,
      cnhValidationMessage,
      loadingDriverValidation,
      loadingBiometricValidation,
      judicialProcess,
      judicialProcessLoading,
      postCnhValidation,
      getDriverValidation,
      postBiometricValidation,
      postJudicialProcessConsult,
      getJudicialProcessConsult,
    ]
  );

  return (
    <DriverValidationContext.Provider value={providerValue}>
      {children}
    </DriverValidationContext.Provider>
  );
};
