import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { BorderCard, Item } from "./styles";

const UploadImageGuideCard = ({
  item,
  doc = "de habilitação (frente e verso da CNH)",
  formats = "Formatos aceitos .jpeg, .png e .pdf",
}) => {
  return (
    <BorderCard className="gap-1">
      <h1 className="d-flex justify-content-center text-center">
        Orientações para envio da imagem {item}:
      </h1>
      <Item>
        <div className="d-flex align-items-center">
          <AiOutlineCheckCircle size={17} color="#6dd786" />
        </div>
        <span>Enviar imagem aberta do documento {doc}</span>
      </Item>
      <Item>
        <div className="d-flex align-items-center">
          <AiOutlineCheckCircle size={17} color="#6dd786" />
        </div>
        <span>{formats}</span>
      </Item>

      <Item>
        <div className="d-flex align-items-center">
          <AiOutlineCheckCircle size={17} color="#6dd786" />
        </div>
        <span>Enviar apenas 1 imagem</span>
      </Item>
      <Item>
        <div className="d-flex align-items-center">
          <AiOutlineCloseCircle size={17} color="#ff0f0f" />
        </div>
        <span>Não enviar foto pessoal</span>
      </Item>
      <Item>
        <div className="d-flex align-items-center">
          <AiOutlineCloseCircle size={17} color="#ff0f0f" />
        </div>
        <span>Não enviar selfie</span>
      </Item>
      <Item>
        <div className="d-flex align-items-center">
          <AiOutlineCloseCircle size={17} color="#ff0f0f" />
        </div>
        <span>Não enviar arquivos de texto</span>
      </Item>
    </BorderCard>
  );
};

export default UploadImageGuideCard;
