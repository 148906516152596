import { useCallback } from "react";
import Select from "react-select";
import { formatString } from "../../../../Shared/Utils/data";

const PerfilSelectInput = ({ scopes, getPerfisByScope, getPerfis }) => {
  const handlePerfilSelect = useCallback(
    (e) => {
      const value = e.value;
      if (value === null) {
        getPerfis();
      } else {
        getPerfisByScope(e.value);
      }
    },
    [getPerfis, getPerfisByScope]
  );

  const options = [
    { value: null, label: "Todos os escopos" },
    ...scopes.map((scope) => ({
      value: scope.nome,
      label: formatString(scope.nome),
    })),
  ];

  return (
    <Select
      options={options}
      onChange={handlePerfilSelect}
      placeholder="-- Selecionar escopos --"
    />
  );
};

export default PerfilSelectInput;
