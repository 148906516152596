import { Col, Form, Row } from "react-bootstrap";
import UploadOwnerDocImage from "../../RegistrationManagement/Vehicles/components/VehicleForms/UploadOwnerDocImage";
import { BorderContainer, FontInputTitle, TextArea } from "./styles";
import { useForm } from "react-hook-form";
import { SubmitButton } from "../../../../Shared/Components/SubmitButton/SubmitButton";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";

export const ValidateLoadingOrderClosure = ({
  loadOrder,
  handleCallModal,
  editLoadOrder,
}) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    (data, status) => {
      const newDate = {
        id: loadOrder?.id,
        status: status === "approve" ? "2" : "8",
        justificativa_finalizacao: data.justificativa_finalizacao,
      };
      editLoadOrder(loadOrder.id, newDate, navigate, true);
    },
    [editLoadOrder, loadOrder.id, navigate]
  );

  return (
    <BorderContainer>
      <h1>Finalizar ordem de carregamento</h1>
      <Row>
        <Col xs={4} md={loadOrder.status === "10" ? 3 : 4}>
          {loadOrder?.documentos_finalizacao && (
            <div className="d-flex">
              <UploadOwnerDocImage
                title="Comprovante de entrega"
                image={loadOrder?.documentos_finalizacao[0]}
                handleShowModal={handleCallModal}
              />
            </div>
          )}
        </Col>
        <Col
          xs={12}
          md={loadOrder.status === "10" ? 9 : 8}
          className="mt-2 mt-md-0"
        >
          {loadOrder?.status === "10" && (
            <Form onSubmit={handleSubmit((data) => onSubmit(data, "approve"))}>
              <Form.Group className="mb-3">
                <FontInputTitle className="w-100">
                  Inserir justificativa
                </FontInputTitle>
                <TextArea
                  className="is-required w-100 rounded"
                  label="Insira uma nova ocorrência"
                  as="textarea"
                  {...register("justificativa_finalizacao", {
                    required: "A justificativa é obrigatória",
                  })}
                />
                {errors?.justificativa_finalizacao && (
                  <Form.Text className="text-danger">
                    {errors?.justificativa_finalizacao?.message}
                  </Form.Text>
                )}
              </Form.Group>

              <div className="d-flex justify-content-end gap-2">
                <SubmitButton labelButton="Aprovar encerramento da OC" />
                <ButtonLink
                  label="Reprovar encerramento da OC"
                  fontSize="0.7rem"
                  buttoncolor="secondary"
                  handleClick={handleSubmit((data) => onSubmit(data, "reject"))}
                />
              </div>
            </Form>
          )}
          {loadOrder.status === "2" && (
            <div>
              <FontInputTitle className="w-100 mt-2">
                Justificativa
              </FontInputTitle>
              <span>{loadOrder?.justificativa_finalizacao}</span>
            </div>
          )}
        </Col>
      </Row>
    </BorderContainer>
  );
};
