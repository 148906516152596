import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const ActionButton = styled(Button)`
background-color: transparent !important;
border: ${props => props?.disabledicon ? "transparent" : "1px solid #b0b0b0"} !important;
padding: 3px;
border-radius: 3px;
box-shadow: ${props => props?.disabledicon ? "none" : "0.5px 0.5px 0.5px #b0b0b0"} !important;

transition: transform 300ms;

&:hover{
  transform: scale(1.1);
    z-index: 1;
}
`
export const ActionLink = styled(Link)`
background-color: transparent !important;
border: ${props => props?.disabledicon ? "transparent" : "1px solid #b0b0b0"} !important;
padding: 3px;
border-radius: 3px;
box-shadow: ${props => props?.disabledicon ? "none" : "0.5px 0.5px 0.5px #b0b0b0"} !important;

transition: transform 300ms;

&:hover{
  transform: scale(1.1);
    z-index: 1;
}
`