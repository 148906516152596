import moment from "moment";
import { Table } from "react-bootstrap";

import {
  ProcessesTableTitle,
  TableItem,
  TableTitle,
  TravelHistoryTableContainer,
} from "./styles";

import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";

import {
  cnpjCpfFormat,
  formatNameString,
} from "../../../../../Shared/Utils/data";

const TravelHistoryTable = ({ isLoading, company, travelHistoryList }) => {
  return (
    <TravelHistoryTableContainer className="mt-4 pe-2">
      <Table hover size="sm" className="border rounded mb-0">
        <thead>
          <ProcessesTableTitle>
            {!company && (
              <>
                <TableTitle className="ps-3">Empresa</TableTitle>
                <TableTitle>CPF/CNPJ</TableTitle>
              </>
            )}
            <TableTitle className="ps-3">Data</TableTitle>
            <TableTitle className="ps-3">Origem</TableTitle>
            <TableTitle className="ps-3">Destino</TableTitle>
            <TableTitle className="ps-3">Mercadoria</TableTitle>
            <TableTitle className="ps-3">Placa</TableTitle>
          </ProcessesTableTitle>
        </thead>
        <tbody>
          {isLoading && <Loading />}
          {!isLoading &&
            travelHistoryList &&
            Array.isArray(travelHistoryList) &&
            travelHistoryList?.length > 0 &&
            travelHistoryList.map((travel, index) => (
              <tr key={index}>
                {!company && (
                  <>
                    <TableItem className="px-3">
                      {travel?.nomefantasia}
                    </TableItem>
                    <TableItem>
                      {travel?.cpf_cnpj_prop
                        ? cnpjCpfFormat(travel?.cpf_cnpj_prop)
                        : "--"}
                    </TableItem>
                  </>
                )}
                <TableItem className="px-3">
                  {moment.utc(travel?.dt_viagem).format("DD/MM/YYYY")}
                </TableItem>
                <TableItem className="px-3">
                  {travel?.cidade_origem
                    ? formatNameString(travel?.cidade_origem)
                    : "--"}
                </TableItem>
                <TableItem className="px-3">
                  {travel?.cidade_destino
                    ? formatNameString(travel?.cidade_destino)
                    : "--"}
                </TableItem>
                <TableItem className="px-3">
                  {travel?.mercadoria
                    ? formatNameString(travel?.mercadoria)
                    : "--"}
                </TableItem>
                <TableItem className="px-3">{travel?.placa ?? "--"}</TableItem>
              </tr>
            ))}
        </tbody>
      </Table>
      {!isLoading &&
        (!travelHistoryList ||
          (Array.isArray(travelHistoryList) &&
            travelHistoryList.length === 0)) && (
          <div className="mt-2">
            <EmptyTableMessage />
          </div>
        )}
    </TravelHistoryTableContainer>
  );
};

export default TravelHistoryTable;
