import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";

import { BsPlus } from "react-icons/bs";
import { AiOutlineMessage } from "react-icons/ai";

import { OccurencesCard } from "./OccurrencesCard";
import { RealTimeLocationList } from "./RealTimeLocationList";

import {
  Column,
  PageTitle,
  RealTimeLocationRow,
  TotalOccurrences,
} from "./styled";

import { OriginDestinationCard } from "../DetailedLoadingOrder/OriginDestinationCard";
import { OriginDestinationStatusCard } from "../DetailedLoadingOrder/OriginDestinationStatusCard";
import { BasicInformationsCard } from "../DetailedLoadingOrder/components/BasicInformationsCard/BasicInformationsCard";

import Dashboard from "../../../../Shared/layout";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";

import {
  clearGeoLoactionsDuplicates,
  extractRealTimeData,
  sortByDateAscending,
} from "../../../../Shared/Utils/data";

import { useVehicles } from "../../../../hooks/VehicleProvider";
import { useTravels } from "../../../../hooks/TravelProvider";
import { useDrivers } from "../../../../hooks/DriverProvider";
import { HistoryTravelMap } from "../../../../Shared/Components/LeafletMaps/HistoryTravelMap";

export const CompletedTravelHistory = () => {
  const [occurrenceAmount, setOccurrenceAmount] = useState(3);
  const [originAddress, setOriginAddress] = useState(null);
  const [destinyAddress, setDestinyAddress] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [distanceTimePredictions, setDistanceTimePredictions] = useState({});

  const { token } = useParams();
  const { driver, getDriver, isLoading } = useDrivers();
  const { vehicle, getVehicle } = useVehicles();

  const {
    occurrences,
    isLoadingOccurrence,
    loadOrder,
    loadOrderRoute,
    loadOrderAddressLoading,
    getOccurrences,
    getLoadOrder,
    getLoadOrderRoutes,
    getLoadOrderAddress,
    getLoadOrderByGeometryAddress,
  } = useTravels();

  useEffect(() => {
    token && getLoadOrder(token);
  }, [getLoadOrder, token]);

  useEffect(() => {
    loadOrder && getOccurrences(loadOrder?.id);
  }, [getLoadOrder, getOccurrences, loadOrder]);

  useEffect(() => {
    loadOrder && getLoadOrderRoutes(loadOrder?.id);
  }, [getLoadOrderRoutes, loadOrder, loadOrder?.id]);

  useEffect(() => {
    if (loadOrder?.motorista_id) {
      getDriver(loadOrder?.motorista_id);
    }
  }, [loadOrder?.motorista_id, getDriver]);

  useEffect(() => {
    if (loadOrder?.veiculo_id) {
      getVehicle(loadOrder?.veiculo_id);
    }
  }, [loadOrder?.veiculo_id, getVehicle]);

  const handleSeeMoreOccurrences = useCallback(() => {
    setOccurrenceAmount((state) => state + 5);
  }, []);

  const handleOriginAddress = useCallback(async () => {
    if (loadOrder?.latitude_coleta && loadOrder?.longitude_coleta) {
      setOriginAddress({
        lat: loadOrder?.latitude_coleta,
        lng: loadOrder?.longitude_coleta,
      });
      return;
    }

    if (loadOrder?.nome_cidade_coleta) {
      const position = await getLoadOrderAddress(
        `${loadOrder?.nome_cidade_coleta},${loadOrder?.uf_coleta},Brasil`
      );

      setOriginAddress({
        lat: position.length > 0 ? position[0]?.lat : position?.lat,
        lng: position.length > 0 ? position[0]?.lon : position?.lon,
      });
    }
  }, [
    loadOrder?.latitude_coleta,
    loadOrder?.longitude_coleta,
    loadOrder?.nome_cidade_coleta,
    loadOrder?.uf_coleta,
    getLoadOrderAddress,
  ]);

  useEffect(() => {
    loadOrder && handleOriginAddress();
  }, [loadOrder, handleOriginAddress]);

  const handleDestinyAddress = useCallback(async () => {
    if (loadOrder?.latitude_entrega && loadOrder?.longitude_entrega) {
      setDestinyAddress({
        lat: loadOrder?.latitude_entrega,
        lng: loadOrder?.longitude_entrega,
      });
      return;
    }

    if (loadOrder?.nome_cidade_entrega) {
      const position = await getLoadOrderAddress(
        `${loadOrder?.nome_cidade_entrega},${loadOrder?.uf_entrega},Brasil`
      );

      setDestinyAddress({
        lat: position.length > 0 ? position[0]?.lat : position?.lat,
        lng: position.length > 0 ? position[0]?.lon : position?.lon,
      });
    }
  }, [
    loadOrder?.latitude_entrega,
    loadOrder?.longitude_entrega,
    loadOrder?.nome_cidade_entrega,
    loadOrder?.uf_entrega,
    getLoadOrderAddress,
  ]);

  useEffect(() => {
    loadOrder && handleDestinyAddress();
  }, [loadOrder, handleDestinyAddress]);

  const findCurrentAddress = useCallback(async () => {
    if (loadOrder?.localizacao_atual) {
      const currentLocation = JSON.parse(loadOrder?.localizacao_atual);

      return setCurrentAddress({
        lat: currentLocation?.geo_localizacao?.latitude,
        lng: currentLocation?.geo_localizacao?.longitude,
        desvio_rota: loadOrder?.desvio_rota ?? false,
      });
    }

    if (loadOrderRoute && loadOrderRoute.length > 0) {
      const currentLocation = loadOrderRoute[0];

      return setCurrentAddress(extractRealTimeData(currentLocation));
    }

    const position = await getLoadOrderAddress(
      `${loadOrder?.nome_cidade_coleta},${loadOrder?.uf_coleta},Brasil`
    );

    return setCurrentAddress({
      lat: position.length > 0 ? position[0]?.lat : position?.lat,
      lng: position.length > 0 ? position[0]?.lon : position?.lon,
      desvio_rota: loadOrder?.desvio_rota ?? false,
    });
  }, [
    getLoadOrderAddress,
    loadOrder?.desvio_rota,
    loadOrder?.localizacao_atual,
    loadOrder?.nome_cidade_coleta,
    loadOrder?.uf_coleta,
    loadOrderRoute,
  ]);

  useEffect(() => {
    loadOrder?.id && getLoadOrderRoutes(loadOrder?.id);
    findCurrentAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDistance = useCallback(async () => {
    if (originAddress && destinyAddress) {
      const address = {
        currentLocation: originAddress,
        destinyLocation: destinyAddress,
      };

      const response = await getLoadOrderByGeometryAddress(address);

      response?.routes[0]?.legs[0] &&
        setDistanceTimePredictions(response?.routes[0]?.legs[0]);
    }

    if (originAddress && originAddress) {
      const address = {
        currentLocation: originAddress,
        destinyLocation: destinyAddress,
      };

      const response = await getLoadOrderByGeometryAddress(address);

      response?.routes[0]?.legs[0] &&
        setDistanceTimePredictions(response?.routes[0]?.legs[0]);
    }
  }, [destinyAddress, getLoadOrderByGeometryAddress, originAddress]);

  useEffect(() => {
    handleDistance();
  }, [handleDistance]);

  const routesSegments = useMemo(() => {
    if (loadOrderRoute) {
      const newList = sortByDateAscending(loadOrderRoute);

      const list = clearGeoLoactionsDuplicates(newList);

      return list.map((objeto) => {
        const { latitude, longitude, endereço } = JSON.parse(
          objeto.geo_localizacao
        );
        return [latitude, longitude, endereço];
      });
    }
  }, [loadOrderRoute]);

  return (
    <Dashboard title="Localização em tempo real">
      {(isLoading || loadOrderAddressLoading || isLoadingOccurrence) && (
        <Loading />
      )}
      {!isLoading &&
        loadOrder &&
        !loadOrderAddressLoading &&
        !isLoadingOccurrence && (
          <RealTimeLocationRow className="g-1 mt-2">
            <Column xl={8}>
              {!currentAddress && (
                <div className="d-flex align-items-center justify-content-center h-100 gap-2">
                  <Spinner variant="warning" />
                  <span className="fst-italic">Calculando rota...</span>
                </div>
              )}
              <Card className="d-flex min-h-100 w-100 p-3 gap-3">
                {originAddress && destinyAddress && currentAddress?.lat && (
                  <>
                    <HistoryTravelMap
                      height="50vh"
                      origin={originAddress}
                      destiny={destinyAddress}
                      route={loadOrder?.rota_selecionada}
                      loadOrder={loadOrder}
                      showMore
                      showSubtitle
                      routesSegments={routesSegments}
                    />
                  </>
                )}

                {Array.isArray(loadOrderRoute) && loadOrderRoute.length > 0 && (
                  <RealTimeLocationList
                    loadOrderRoute={loadOrderRoute}
                    token={loadOrder?.token}
                  />
                )}
              </Card>
            </Column>
            <Column xl={4}>
              <Card className="d-flex min-h-100 w-100 p-3 gap-2">
                <div className="d-flex aling-items-center justify-content-around mb-2">
                  <BasicInformationsCard title="Motorista" itemList={driver} />
                  <BasicInformationsCard title="Veículos" itemList={vehicle} />
                </div>

                <OriginDestinationCard
                  loadOrder={loadOrder}
                  distanceTimePredictions={distanceTimePredictions}
                />
                {loadOrderRoute && loadOrderRoute.length > 0 && (
                  <OriginDestinationStatusCard
                    loadOrder={loadOrder}
                    loadOrderRoute={loadOrderRoute}
                    isComplete
                  />
                )}
                <div>
                  <div className="d-flex align-items-end justify-content-between px-1 mb-2 mt-2">
                    <PageTitle>Ocorrências</PageTitle>
                    <TotalOccurrences>
                      Total ({occurrences ? occurrences.length : 0})
                    </TotalOccurrences>
                  </div>
                  {isLoadingOccurrence && <Loading />}
                  {!isLoadingOccurrence &&
                    loadOrder?.token &&
                    Array.isArray(occurrences) &&
                    occurrences.length === 0 && (
                      <EmptyTableMessage label="Sem ocorrências registradas" />
                    )}
                  {!isLoadingOccurrence &&
                    loadOrder?.token &&
                    Array.isArray(occurrences) &&
                    occurrences.length > 0 && (
                      <div className="d-flex gap-3 flex-column">
                        <OccurencesCard
                          occurrences={occurrences}
                          occurrenceAmount={occurrenceAmount}
                          loadOrder={loadOrder}
                          heightSize="100"
                        />
                        {occurrenceAmount < occurrences.length && (
                          <div className="d-flex justify-content-end">
                            <ButtonLink
                              label="Ver mais"
                              secondIcon={<BsPlus />}
                              fontSize="0.7rem"
                              handleClick={handleSeeMoreOccurrences}
                              buttoncolor="primary"
                            />
                          </div>
                        )}
                      </div>
                    )}
                </div>
                {loadOrder?.token && (
                  <div className="d-flex flex-column gap-2">
                    {loadOrder?.status !== "2" && (
                      <ButtonLink
                        fontSize="0.7rem"
                        link={`/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/percurso/enviar-ocorrencia/${loadOrder?.token}`}
                        label="Conversar com o motorista"
                        buttoncolor="success"
                        icon={<AiOutlineMessage />}
                      />
                    )}
                    <ButtonLink
                      fontSize="0.7rem"
                      link={`/gestao-viagens/ordem-de-carregamento/${loadOrder?.token}`}
                      label="Fechar"
                    />
                  </div>
                )}
              </Card>
            </Column>
          </RealTimeLocationRow>
        )}
    </Dashboard>
  );
};
