import { useEffect } from "react";
import L from "leaflet";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { useMap } from "react-leaflet";

const LeafletRoutingMachineHistory = ({
  origin,
  destiny,
  OriginIcon,
  routesSegments,
}) => {
  const map = useMap();

  useEffect(() => {
    const lineOptions = {
      styles: [
        {
          color: "#55caf4",
          weight: 6,
          opacity: 0.7,
          zIndex: 9999,
        },
      ],
    };

    const routeSegmentLine = L.Routing.control({
      waypoints:
        routesSegments &&
        routesSegments.map((point) => L.latLng(point[0], point[1])),
      serviceUrl: `${process.env.REACT_APP_OSRM}${origin.lat},${origin.lng};${destiny.lat},${destiny.lng}?steps=true`,
      lineOptions: lineOptions,
      showAlternatives: false,
      alternatives: false,
      router: L.Routing.osrmv1({
        language: "pt-BR",
        profile: "car",
        timeout: 30 * 1000,
        alternatives: false,
        showAlternatives: false,
      }),
      routeWhileDragging: false,
      addWaypoints: false,
      marker: false,
      createMarker: function () {
        return null;
      },
      draggableWaypoints: false,
      instructions: false,
      show: false,
    });

    routesSegments && routesSegments.length > 1 && routeSegmentLine.addTo(map);
  }, [map, origin, destiny, OriginIcon, routesSegments]);

  return null;
};

export default LeafletRoutingMachineHistory;
