import { FiAlertTriangle } from "react-icons/fi";
import { ErrorContainer } from "./styles";

export const ErrorMessageCard = ({ label }) => {
  return (
    <ErrorContainer className="mt-2 mb-2">
      <FiAlertTriangle />
      <span className="ms-1">{label}</span>
    </ErrorContainer>
  );
};
