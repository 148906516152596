import { createGlobalStyle } from "styled-components";

//Inserir media screen: Remover funcionalidade de print
export const GlobalStyle = createGlobalStyle`
:root {
    --color-black: "#000000";
    --color-gray-1: rgba(128, 128, 128, 0.2);
    --color-gray-2: #DEE9F3;
    --color-gray-3: #f6f6f6;
    --color-gray-4: #FFF;
    --color-gray-9: rgba(247, 244, 244, 0.5);
    --color-gray-10: #dadadb;
    --color-gray-11: #b0b0b0;
    --color-gray-12:#5f5f5f;
    --color-gray-13: #dae0e4;
    --color-gray-15: #4d4d4d;
    --color-gray-20: #858585;


    --color-blue-20: #eefaff;
    --color-blue-30:#55caf4;
    --color-blue-60: #0071c7;
    --color-blue-70: #2185D0;
    --color-blue-80: #0e5a8c;
    --color-blue-90: #093c5e;

    --color-soft-red: #b94a45;
    --color-red: #ff0f0f;
    --color-danger: #7e0000;

    --color-green-10: #64e69c;
    --color-green: #07b141;
    --color-active: #6dd786;
    --color-green-50: #5bff47;

    --bs-accordion-btn-color: #dadadb !important;

    --color-gray-4: #535353;
    --color-blue-50: #2778c4;
    --color-gray-90: #212529;
    --color-red-30: #f27474a6;
    --color-red-50: #cb1313;
    --color-yellow-30: #ffc107;
    --color-red-20: #f27474;
    --color-gray-70: #198754;

    }


    @media print {
      *{ display: none;}
    }
    html, body, #root {
        min-height: 100vh;
        max-width: 100vw;
        -webkit-font-smoothing: antialiased;
    }

    .customCursor {
  cursor: pointer;
}

.modal-90w .modal-dialog{
  width: 90vw;
  max-width: 90vw;
}


h1 {
  font-size: 40px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}


a {
  color: #3a3a3a !important;
  font-size: 14px;
}

.link-simple {
  text-decoration: none;
}

.breadcrumbs li a,
.breadcrumbs li {
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #acacac;
}

.alert {
  position: absolute !important;
  right: 1rem;
  top: 1rem;
}

.form-floating label {
  color: rgb(153, 153, 153);
}

.form-group > label {
  bottom: 34px;
  left: 15px;
  position: relative;
  background-color: white;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.2s;
  pointer-events: none;
}

.form-control:focus ~ label {
  bottom: 55px;
}

.form-control:valid ~ label {
  bottom: 55px;
}

.form-control.is-required {
  border-left: 2px solid red;
}

.form-select.is-required {
  border-left: 2px solid red;
}

/* Primário */
.btn-blue-dark {
  background-color: #093c5e !important;
  border-color: #093c5e !important;
  color: white !important;
}

.btn-outline-primary {
  background-color: white !important;
  border-color: var(--color-blue-80) !important;
  color: var(--color-blue-80) !important;
}

.btn-background-blue-dark {
  background-color: var(--color-blue-80) !important;
  border-color: var(--color-blue-80) !important;
  color: white !important;
}

.btn-flat-blue-dark {
  color: var(--color-blue-80)!important;
  border-color: var(--color-blue-80)!important;
  background-color: white !important;
}

.color-blue-dark {
  color: #093c5e;
}

.btn-blue-regular {
  background-color: var(--color-blue-80);
  border-color: var(--color-blue-80) !important;
  color: white !important;
}

.btn-blue-light {
  background-color: #7aaccd;
  border-color: #7aaccd !important;
  color: white !important;
}

/* Secundário */
.btn-black {
  background-color: #1e1e1e !important;
  border-color: #1e1e1e !important;
  color: white !important;
}

.btn-gray-dark {
  background-color: #3a3a3a !important;
  border-color: #3a3a3a !important;
  color: white !important;
}

.btn-gray-background-dark {
  background-color: transparent !important;
  border-color: #c4c4c4 !important;
  color: #565656 !important;
}

.btn-gray-regular {
  background-color: #565656;
  border-color: #565656 !important;
  color: white !important;
}

.color-gray {
  color: var(--color-gray-15);
}

.btn-light-gray {
  background-color: #f2f2f2;
  border: #f2f2f2;
  color: #3a3a3a;
}

.color-active {
  color: var(--color-active);
}

.color-soft-red {
  color: var(--color-soft-red);
}

.color-disabled {
  color: var(--color-red);
}

.color-red {
  color: red;
}

.color-danger {
  color: var(--color-danger);
}

.btn-light-primary {
  background-color: #2185d0;
}

th {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.4px;
  /* height: 40px; */
  vertical-align: middle;
  color: #565656;
  line-height: 16px;
}

td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #1e1e1e;
  /* height: 40px; */
  vertical-align: middle;
}

.legend-paginations {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #acacac;
}

.speedometer {
  height: 200px;
}

.g-recaptcha {
    transform:scale(0.77);
    transform-origin:0 0;
}


button.accordion-button[aria-expanded="true"]{
  background-color: var(--color-blue-70);
  color: white;
  padding: 0.5rem;
}

button.accordion-button[aria-expanded="false"]{
   color: var(--color-gray-12); 
  padding: 0.5rem;

}

#react-select-221-placeholder{
  color: var(--color-gray-15);
}

[id^="react-select-219-option"]{
  background-color: transparent !important;
}

.custom-modal-90w{
  width: 70vw;
  height: 50vh;
  margin-left: 15%;
  padding-top: 5%;
}

.custom-modal-100w{
  width: 100vw;
  height: 100vh;
  margin: 0 !important;
  padding-top: 5%;
}


.css-vowb4s-MenuPortal {
  z-index: 99999 !important;
  position: fixed;
}

.leaflet-control-container .leaflet-routing-container-hide {
    display: none;
}

/* Aplica estilos em todos os filhos, exceto no primeiro, com as classes .leaflet-routing-container, .leaflet-bar e .leaflet-control dentro de um elemento com as classes .leaflet-top e .leaflet-right */
.leaflet-top.leaflet-right  > :not(:first-child) {
  display: none;
}

interactive.viewport canvas, video {
  position: relative !important;
  height: 100%;
  width: 100%;
}

`;
