import EmptyImageLogo from "../../../Assets/Images/logo-design.png";
import { Cover } from "./styles";

export const CoverImage = ({ value, width, height }) => {
  return (
    <Cover
      className="mb-1 mx-auto"
      width={width}
      height={height}
      logo={value?.logo?.url}
      emptyImageLogo={EmptyImageLogo}
    />
  );
};
