import styled from "styled-components";

export const CompanyTableTitle = styled.tr`
th {
  font-size: 0.85rem;
  font-weight: bold;
}
`

export const CompanyTableContainer = styled.div`
max-height: 35vh;
flex: 1;
overflow-x: auto;

 @media(min-width: 375px){
  max-height: 40vh;
 }

 @media(min-width: 425px){
  max-height: 50vh;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }

  @media(min-width: 2440px){
  max-height: 70vh;
  }
`