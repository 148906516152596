import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest from "../Shared/Utils/Request";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export const OccurrenceContext = createContext({
  occurrencesTypes: [],
  occurrence: null,
  totalPages: 1,
  isLoadingOccurrencesTypes: false,
  getOccurrencesTypes: () => [],
  getOccurrence: () => [],
  editOccurrence: () => null,
  deleteDriver: () => null,
  addOccurrence: () => null,
});

export const useOccurrences = () => {
  const context = useContext(OccurrenceContext);

  if (!context) {
    throw new Error("useOccurrences must be within OccurrenceProvider");
  }

  return context;
};

export const OccurrenceProvider = ({ children }) => {
  const [isLoadingOccurrencesTypes, setIsLoadingOccurrencesTypes] =
    useState(false);
  const [occurrencesTypes, setOccurrencesTypes] = useState([]);
  const [occurrence, setOccurrence] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  const getOccurrencesTypes = useCallback(async (currentPage) => {
    try {
      setIsLoadingOccurrencesTypes(true);

      const params = {
        perPage: 15,
        page: currentPage,
      };

      const response = await ApiRequest({
        path: "contra-senha/tipo-ocorrencia",
        method: "GET",
        params,
      });

      setTotalPages(response?.data?.total);
      setIsLoadingOccurrencesTypes(false);

      setOccurrencesTypes(response?.data?.data);
    } catch (error) {
      setOccurrencesTypes([]);
    } finally {
      setIsLoadingOccurrencesTypes(false);
    }
  }, []);

  const getOccurrence = useCallback(async (id) => {
    try {
      setIsLoadingOccurrencesTypes(true);

      const response = await ApiRequest({
        path: `contra-senha/tipo-ocorrencia/${id}`,
        method: "GET",
      });

      setOccurrence(response?.data);
    } catch (error) {
      setOccurrence([]);
    } finally {
      setIsLoadingOccurrencesTypes(false);
    }
  }, []);

  const editOccurrence = useCallback(async (id, data, navigate) => {
    try {
      setIsLoadingOccurrencesTypes(true);

      const response = await ApiRequest({
        path: `contra-senha/tipo-ocorrencia/${id}`,
        method: "PUT",
        data,
      });

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Tipo de ocorrência editada com sucesso!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });

      navigate("/gestao-viagens/tipos-de-ocorrencias");

      setIsLoadingOccurrencesTypes(false);
      return response?.data;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: error?.response?.data?.error
          ? error?.response?.data?.error
          : "Erro ao editar esse tipo de ocorrência",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    } finally {
      setIsLoadingOccurrencesTypes(false);
    }
  }, []);

  const addOccurrence = useCallback(
    async (data, navigation) => {
      try {
        setIsLoadingOccurrencesTypes(true);

        await ApiRequest({
          path: "contra-senha/tipo-ocorrencia",
          method: "POST",
          data: data,
        });

        const response = getOccurrencesTypes();

        window.scrollTo(0, 0);

        navigation("/gestao-viagens/tipos-de-ocorrencias");

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Tipo de ocorrência criado com sucesso",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsLoadingOccurrencesTypes(false);

        return response?.data;
      } catch (error) {
        window.scrollTo(0, 0);

        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Erro ao criar tipo de ocorrência",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setIsLoadingOccurrencesTypes(false);
      }
    },
    [getOccurrencesTypes]
  );

  const deleteDriver = useCallback(
    async (id) => {
      try {
        await ApiRequest({
          path: `contra-senha/tipo-ocorrencia/${id}`,
          method: "DELETE",
        });

        window.scrollTo(0, 0);

        const response = await getOccurrencesTypes();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "O tipo de ocorrência foi excluído com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: error.response?.data?.error
            ? error.response?.data?.error
            : "Erro ao excluir tipo de ocorrência",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
      }
    },
    [getOccurrencesTypes]
  );

  const providerValue = useMemo(
    () => ({
      occurrencesTypes,
      occurrence,
      totalPages,
      isLoadingOccurrencesTypes,
      getOccurrencesTypes,
      getOccurrence,
      editOccurrence,
      deleteDriver,
      addOccurrence,
    }),
    [
      occurrencesTypes,
      occurrence,
      totalPages,
      isLoadingOccurrencesTypes,
      getOccurrencesTypes,
      getOccurrence,
      editOccurrence,
      deleteDriver,
      addOccurrence,
    ]
  );

  return (
    <OccurrenceContext.Provider value={providerValue}>
      {children}
    </OccurrenceContext.Provider>
  );
};
