import { Card, Tab, Tabs } from "react-bootstrap";
import { FaRegDotCircle } from "react-icons/fa";
import styled from "styled-components";

export const PageTitle = styled(Card.Title)`
color: var(--color-gray-12);
font-weight: normal;
font-size: 1rem;
margin-bottom: 1rem;
`

export const CardContainer = styled(Card)`

@media(min-width: 1200px){
  max-height: 70vh;
  overflow-y: auto;
}
  
`;

export const CardBodyContainer = styled(Card.Body)`

@media(min-width: 1200px){
  max-height: 60vh;
  overflow-y: auto;
}
  
`;

export const ProcessesTableTitle = styled.tr`
th {
  font-size: 0.80rem;
  font-weight: bold;
}
`

export const TableItem = styled.td`
font-size: 0.75rem;
color: var(--color-gray-12);
`
export const TableTitle = styled.th`
font-size: 0.65rem;
color: var(--color-gray-15);
font-weight: 700;
`


const getColorBasedOnStatus = (status) => {
  switch (status) {
    case "0":
      return "#535353";
    case "1":
      return "#2778c4";
    case "2":
      return "#212529";
    case "3":
      return "#f27474a6";
    case "4":
      return "#cb1313";
    case "5":
      return "#ffc107";
    case "6":
      return "#f27474";
    case "7":
      return "#f27474";
    case "8":
      return "#198754";
    case "60":
      return "#198754";
    default:
      return "#000000";
  }
};

export const FaRegDotCircleIcon = styled(FaRegDotCircle)`
  color: ${(props) => getColorBasedOnStatus(props.status)};
`;


export const TravelLoadOrderContainer = styled.div`
  overflow: auto;
  max-height: 20vh;

  @media(min-width: 425px){
    max-height: 35vh;
  }

  @media(min-width: 576px){
    max-height: 40vh;
  }
  

  @media(min-width: 1800px){
    max-height: 60vh;
  }

  
`
export const DashboardContainer = styled.div`
  overflow: auto;
  max-height: 20vh;

  @media(min-width: 425px){
    max-height: 35vh;
  }

  @media(min-width: 576px){
    max-height: 40vh;
  }
  @media(min-width:768px){
    max-height: 50vh;
  }
  

  @media(min-width: 1800px){
    max-height: 60vh;
  }

  
`

export const StyledTabs = styled(Tabs)`

  .nav-link {
    color: black; 
    font-size: 0.9rem;
  }

  .nav-link.active {
    color: inherit; 
  }

  .nav-link:not(.active) {
    color: var(--color-blue-80)
  }
`;

export const TravelLoadOrderTab = styled(Tab)`
max-height: 20vh;
overflow: hidden;
`

export const LoadOrderManagementCard = styled.div`
  border-radius: 6px;
  padding: 1rem;
`

export const LoadOrderManagementTitle = styled.h1`
  font-weight: bold;
  color: var(--color-blue-80);
  font-size: 1.125rem;
`

export const TotalOCLabel = styled.span`
  color: white;
  font-size: 0.875rem;
  font-weight: bold;
`
export const StatusOCLabel = styled.span`
  color: ${({ status }) => status === "Em curso" ? "#07b141" : status === "Finalizadas mês atual" ? "white" : status === "Bloqueadas" ? "#f27474" : "yellow"};
  font-size: 0.875rem;
  font-weight: bold;
`

export const CardButton = styled.button`
  background-color: transparent;
  border: none;
`

export const ItemCard = styled(Card)`
   background-color: var(--color-blue-80);
   padding: 10px;
  border: none;

  transition: transform 300ms;
 &:hover {
    transform: scale(1.05);
    z-index: 1;
  }
`