import { Col, Row } from "react-bootstrap";
import styled from "styled-components";


export const Column = styled(Col)`
  /* overflow-x: auto;
  max-height: 100%; */
  `

export const UpdateDriversRow = styled(Row)`
  max-height: 45vh;
flex: 1;
overflow-x: auto;

 @media(min-width: 425px){
  max-height: 65vh;
 }

 @media(min-width: 992px){
  overflow-x: auto;
  max-height: 70vh;
  }

  @media(min-width: 1700px){ 
  max-height: 75vh;
  }

  @media(min-width: 2400px){ 
  max-height: 100%;
  }

  

  
  `