import { useMemo } from "react";
import moment from "moment/moment";
import { Spinner } from "react-bootstrap";
import { IoWarningOutline } from "react-icons/io5";

import {
  Container,
  FolderIcon,
  Item,
  SubtitleIcon,
  Title,
  H4Status,
  ItemTitle,
  ItemSubtitle,
  TitleContainer,
  InfoTitle,
} from "./styles";

import { VehicleFines } from "./VehicleFines";
import { DetranDetailsModal } from "./DetranDetailsModal";
import { VehicleAutuacaoTable } from "./VehicleAutuacaoTable ";
import { VehicleRestrictionTable } from "./VehicleRestrictionTable";

import { WarningCard } from "../WarningCard/WarningCard";
import { cnpjCpfFormat } from "../../Utils/data";

export const VehicleFederalDetails = ({
  consult,
  label,
  vehicle,
  vehicleAttachment,
}) => {
  const cnpjInformations = useMemo(
    () =>
      consult && consult?.consulta_cnpj && consult?.consulta_cnpj !== "null"
        ? JSON.parse(consult?.consulta_cnpj)
        : consult &&
          consult?.consulta_cpf &&
          consult?.consulta_cpf !== "null" &&
          JSON.parse(consult?.consulta_cpf),
    [consult]
  );

  const ownerAnttConsult = useMemo(
    () =>
      consult &&
      consult?.consulta_antt_transportador &&
      JSON.parse(consult?.consulta_antt_transportador),
    [consult]
  );

  const vehicleAnttConsult = useMemo(
    () =>
      consult &&
      consult?.consulta_antt_veiculo &&
      JSON.parse(consult?.consulta_antt_veiculo),
    [consult]
  );

  const nissConsult = useMemo(
    () =>
      consult &&
      consult?.consulta_cnis &&
      consult?.consulta_cnis !== "null" &&
      JSON.parse(consult?.consulta_cnis),
    [consult]
  );

  const anttError = useMemo(
    () =>
      ownerAnttConsult &&
      ownerAnttConsult?.data &&
      ownerAnttConsult?.data.length > 0 &&
      vehicleAnttConsult &&
      vehicleAnttConsult?.data &&
      vehicleAnttConsult?.data.length > 0 &&
      ownerAnttConsult?.data[0]?.rntrc !== vehicleAnttConsult?.data[0]?.rntrc,
    [ownerAnttConsult, vehicleAnttConsult]
  );

  const detranConsult = useMemo(() => {
    if (consult && consult?.consulta_detran) {
      return JSON.parse(consult?.consulta_detran);
    }
    return null;
  }, [consult]);

  return (
    <Container hasborder="true">
      <TitleContainer className="mb-3 d-flex">
        <InfoTitle className="text-start">CONSULTAS</InfoTitle>
      </TitleContainer>
      <div className="p-1">
        {!consult && (
          <div className="p-2">
            <Container className="p-2">
              <Title>
                Consulta {consult?.consulta_cnpj ? "CNPJ" : "CPF"} Proprietário
              </Title>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <FolderIcon size={22} />
                <SubtitleIcon>Pesquisa não realizada</SubtitleIcon>
              </div>
            </Container>
          </div>
        )}
        {cnpjInformations && cnpjInformations?.err && (
          <div className="p-2">
            <Container className="p-2">
              <Title className="mb-3">Consulta CNPJ / CPF Proprietário</Title>

              <div className="d-flex gap-2 align-items-center justify-content-center">
                <IoWarningOutline size={22} color="red" />
                <SubtitleIcon>{cnpjInformations?.message}</SubtitleIcon>
              </div>
            </Container>
          </div>
        )}
        {consult && !cnpjInformations && (
          <div className="p-2">
            <Container className="p-2">
              <Title className="mb-3">Consulta CNPJ / CPF Proprietário</Title>

              <div className="d-flex gap-2 align-items-center justify-content-center">
                <IoWarningOutline size={22} color="red" />
                <SubtitleIcon>
                  Nenhuma informação encontrada para este CPF/CNPJ
                </SubtitleIcon>
              </div>
            </Container>
          </div>
        )}
        {consult &&
          cnpjInformations &&
          cnpjInformations?.data?.length === 0 &&
          cnpjInformations?.errors?.length === 0 &&
          cnpjInformations?.code_message && (
            <div className="p-2">
              <Container className="p-2">
                <Title className="mb-3">Consulta CNPJ / CPF Proprietário</Title>

                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <IoWarningOutline size={22} color="red" />
                  <SubtitleIcon>{cnpjInformations?.code_message}</SubtitleIcon>
                </div>
              </Container>
            </div>
          )}
        {consult &&
          cnpjInformations &&
          cnpjInformations?.data &&
          cnpjInformations?.data?.length === 0 &&
          cnpjInformations?.errors?.length > 0 && (
            <div className="p-2">
              <Container className="p-2">
                <Title className="mb-3">Consulta CNPJ / CPF Proprietário</Title>

                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <IoWarningOutline size={22} color="red" />
                  <SubtitleIcon>
                    Erro: {cnpjInformations?.errors[0]}
                  </SubtitleIcon>
                </div>
              </Container>
            </div>
          )}
        {consult && cnpjInformations && cnpjInformations?.data?.length > 0 && (
          <div className="p-2">
            <Container className="p-2">
              <Title>
                Consulta CPF / CNPJ do proprietário na Receita Federal
              </Title>
              <>
                {(cnpjInformations?.data[0]?.cnpj ||
                  cnpjInformations?.data[0]?.cpf) && (
                  <Item>
                    <ItemTitle>CPF / CNPJ:</ItemTitle>
                    <ItemSubtitle
                      status={cnpjInformations?.data[0]?.situacao_cadastral}
                    >
                      {cnpjInformations?.data[0]?.cnpj
                        ? cnpjCpfFormat(cnpjInformations?.data[0]?.cnpj)
                        : cnpjInformations?.data[0]?.cpf
                        ? cnpjCpfFormat(cnpjInformations?.data[0]?.cpf)
                        : "--"}
                    </ItemSubtitle>
                  </Item>
                )}
                <Item>
                  <ItemTitle>
                    Situação do CPF/CNPJ do proprietário na Receita Federal:
                  </ItemTitle>
                  <H4Status
                    status={cnpjInformations?.data[0]?.situacao_cadastral}
                  >
                    {cnpjInformations?.data[0]?.situacao_cadastral ?? "--"}
                  </H4Status>
                </Item>
                <Item>
                  <ItemTitle>Data/Hora da consulta:</ItemTitle>
                  <ItemSubtitle>
                    {cnpjInformations?.data[0]?.consulta_datahora
                      ? moment
                          .utc(cnpjInformations?.data[0]?.consulta_datahora)
                          .format("DD/MM/YYYY")
                      : "--"}
                  </ItemSubtitle>
                </Item>
                <Item>
                  <ItemTitle>Data inscrição:</ItemTitle>
                  <ItemSubtitle>
                    {cnpjInformations?.data[0]?.data_inscricao
                      ? moment
                          .utc(cnpjInformations?.data[0]?.data_inscricao)
                          .format("DD/MM/YYYY")
                      : "--"}
                  </ItemSubtitle>
                </Item>
              </>
            </Container>
          </div>
        )}

        {consult &&
          ownerAnttConsult &&
          ownerAnttConsult?.data?.length === 0 &&
          ownerAnttConsult?.errors?.length === 0 &&
          ownerAnttConsult?.code_message && (
            <div className="p-2">
              <Container className="mt-3 p-2">
                <Title className="mb-3">ANTT Transportador</Title>

                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <IoWarningOutline size={22} color="red" />
                  <SubtitleIcon>{ownerAnttConsult?.code_message}</SubtitleIcon>
                </div>
              </Container>
            </div>
          )}

        <div className="d-xl-flex gap-3 justify-content-between">
          {consult &&
            ownerAnttConsult &&
            ownerAnttConsult?.data &&
            ownerAnttConsult?.data?.length > 0 &&
            ownerAnttConsult?.errors?.length === 0 && (
              <div className="p-2 w-100">
                <Container className="mt-3 w-100 p-2">
                  <Title className="mb-3">Consulta ANTT do proprietário</Title>

                  <Item>
                    <ItemTitle>Transportador:</ItemTitle>
                    <ItemSubtitle>
                      {ownerAnttConsult?.data[0]?.transportador ?? "--"}
                    </ItemSubtitle>
                  </Item>
                  <Item>
                    <ItemTitle>Situação cadastral:</ItemTitle>
                    <H4Status status={ownerAnttConsult?.data[0]?.situacao}>
                      {ownerAnttConsult?.data[0]?.situacao ?? "--"}
                    </H4Status>
                  </Item>
                  <Item>
                    <ItemTitle>Data de abertura:</ItemTitle>
                    <ItemSubtitle>
                      {ownerAnttConsult?.data[0]?.abertura_data ?? "--"}
                    </ItemSubtitle>
                  </Item>
                  <Item>
                    <ItemTitle>RNTRC:</ItemTitle>
                    <ItemSubtitle>
                      {ownerAnttConsult?.data[0]?.rntrc ?? "--"}
                    </ItemSubtitle>
                  </Item>
                </Container>
              </div>
            )}

          {consult &&
            vehicleAnttConsult &&
            vehicleAnttConsult?.data &&
            vehicleAnttConsult?.data?.length > 0 &&
            vehicleAnttConsult?.errors?.length === 0 && (
              <div className="p-2  w-100 flex-1 ">
                <Container className="mt-3 p-2">
                  <Title className="mb-3">
                    Consulta ANTT{" "}
                    {label === "CAVALO" || label === "Cavalo" ? "do" : "da"}{" "}
                    {label.toLowerCase()}
                  </Title>
                  <Item>
                    <ItemTitle>Transportador:</ItemTitle>
                    <ItemSubtitle>
                      {vehicleAnttConsult?.data[0]?.transportador ?? "--"}
                    </ItemSubtitle>
                  </Item>

                  <Item>
                    <ItemTitle>Situação cadastral:</ItemTitle>
                    <H4Status status={vehicleAnttConsult?.data[0]?.situacao}>
                      {vehicleAnttConsult?.data[0]?.situacao ?? "--"}
                    </H4Status>
                  </Item>
                  <Item>
                    <ItemTitle>Data de abertura:</ItemTitle>
                    <ItemSubtitle>
                      {vehicleAnttConsult?.data[0]?.abertura_data ?? "--"}
                    </ItemSubtitle>
                  </Item>
                  <Item>
                    <ItemTitle>RNTRC:</ItemTitle>
                    <ItemSubtitle>
                      {vehicleAnttConsult?.data[0]?.rntrc ?? "--"}
                    </ItemSubtitle>
                  </Item>
                </Container>
              </div>
            )}
        </div>
        {anttError && (
          <div className="mt-3">
            <WarningCard
              label={`A ANTT do proprietário é divergente da ANTT ${
                label === "CAVALO" ? "do" : "da"
              } ${label}`}
            />
          </div>
        )}

        {consult &&
          nissConsult &&
          nissConsult?.data &&
          nissConsult?.data.length > 0 && (
            <div className="p-2">
              <Container className="mt-3 p-2">
                <Title className="mb-3">NIS Proprietário</Title>
                <>
                  {cnpjInformations &&
                    cnpjInformations?.data &&
                    cnpjInformations?.data.length > 0 && (
                      <Item>
                        <ItemTitle>CPF Proprietário:</ItemTitle>
                        <ItemSubtitle>
                          {cnpjInformations?.data[0]?.cpf ?? "--"}
                        </ItemSubtitle>
                      </Item>
                    )}
                  <Item>
                    <ItemTitle>NIS Proprietário:</ItemTitle>
                    {nissConsult?.data[0]?.nit === "00000000000" ? (
                      <div className="d-flex gap-1 align-items-center">
                        <Spinner
                          animation="border"
                          variant="secondary"
                          size="sm"
                        />
                        <ItemSubtitle theme>Consultando o NIS...</ItemSubtitle>
                      </div>
                    ) : (
                      <H4Status status="ATIVO">
                        {nissConsult?.data[0]?.nit ?? "--"}
                      </H4Status>
                    )}
                  </Item>
                  {nissConsult?.header?.requested_at && (
                    <Item>
                      <ItemTitle>Data e hora da consulta:</ItemTitle>
                      <ItemSubtitle>
                        {nissConsult &&
                          nissConsult?.header?.requested_at &&
                          moment
                            .utc(nissConsult?.header?.requested_at)
                            .format("DD/MM/YYYY HH:mm")}
                      </ItemSubtitle>
                    </Item>
                  )}
                </>
              </Container>
            </div>
          )}
        {consult &&
          nissConsult &&
          nissConsult?.data &&
          nissConsult?.data.length === 0 &&
          nissConsult?.errors &&
          nissConsult?.errors.length > 0 && (
            <div className="p-2">
              <Container className="mt-3 p-2">
                <Title className="mb-3">NIS Proprietário</Title>

                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <IoWarningOutline size={22} color="red" />
                  <SubtitleIcon>{nissConsult?.errors[0]}</SubtitleIcon>
                </div>
              </Container>
            </div>
          )}

        <div className="p-2">
          <Container className="mt-3 p-2">
            <Title>Consulta veículo</Title>
            <div className="d-flex flex-column gap-3">
              {detranConsult && detranConsult?.err && (
                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <IoWarningOutline size={22} color="red" />
                  <SubtitleIcon>
                    {detranConsult?.message ?? "Erro na consulta ao detran"}
                  </SubtitleIcon>
                </div>
              )}
              {detranConsult &&
                Array.isArray(detranConsult?.errors) &&
                detranConsult?.errors.length === 1 && (
                  <div className="d-flex gap-2 align-items-center justify-content-center">
                    <IoWarningOutline size={22} color="red" />
                    <SubtitleIcon>
                      {detranConsult?.errors[0] ?? "Erro na consulta ao detran"}
                    </SubtitleIcon>
                  </div>
                )}
              {detranConsult &&
                Array.isArray(detranConsult?.data) &&
                detranConsult?.data.length > 0 && (
                  <div className="d-flex flex-column gap-2">
                    <DetranDetailsModal
                      vehicle={vehicle}
                      consult={detranConsult}
                      vehicleAttachment={vehicleAttachment}
                    />
                    <VehicleRestrictionTable detranConsult={detranConsult} />
                    <VehicleAutuacaoTable detranConsult={detranConsult} />
                    <VehicleFines detranConsult={detranConsult} />
                  </div>
                )}
            </div>
          </Container>
        </div>
      </div>
    </Container>
  );
};
