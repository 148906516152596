import styled from "styled-components";

export const ContainerStatusCard = styled.div`
  background-color: var(--color-yellow-30);
  border-radius: 4px;

  span{
    font-size: 0.875rem;
    color: var(--color-gray-15);
    font-weight: bold;
  }
`