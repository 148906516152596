import { Form } from "react-bootstrap";
import { ActionButton, TableItem } from "./styles";
import { ContactCard } from "./components/ContactCard";
import { AiOutlineWhatsApp } from "react-icons/ai";
import Config from "../../../../../Shared/Utils/config";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useCallback, useState } from "react";

import { IoMdSave } from "react-icons/io";
import { whatsappFormat } from "../../../../../Shared/Utils/data";

const ActionsOptions = ({
  driver,
  handleSubmit,
  register,
  handleChangeStatus,
  listStatusConsult,
  isloadingConsult,
}) => {
  const [stateValue, setStateValue] = useState("");

  const whatsappLink = `${Config.social.whatsapp}/${whatsappFormat(
    driver?.numero_celular
  )}`;

  const actionsList = [
    {
      id: 1,
      label: "Cadastro com inconsistência",
      value: "7r",
    },
    {
      id: 2,
      label: `${
        driver?.status_cadastro === "5r"
          ? "Aprovar cadastro"
          : "Analisar cadastro"
      }`,
      value: `${
        driver?.status_cadastro === "5r"
          ? "6r"
          : driver?.status_cadastro === "6r"
          ? "0r"
          : "2r"
      }`,
    },
    {
      id: 3,
      label: "Recusar cadastro",
      value: "3r",
    },
    {
      id: 4,
      label: "Remover pré-cadastro",
      value: "delete",
    },
  ];

  const onChangeStatus = useCallback((event) => {
    const value = event.target.value;
    setStateValue(value);
  }, []);

  const disabledStatus =
    isloadingConsult ||
    driver?.status_cadastro === "2r" ||
    driver?.status_cadastro === "7r" ||
    (driver?.status_cadastro === "5r" &&
      driver?.processos_cadastro_concluido === false);

  const isDisabled =
    listStatusConsult &&
    listStatusConsult.length > 0 &&
    listStatusConsult.find((consult) => {
      if (
        consult?.data?.length === 0 &&
        consult?.errors?.length === 0 &&
        consult?.code_message
      ) {
        return true;
      }

      return false;
    });

  return (
    <TableItem className="px-2 pe-md-3 h-100 text-center">
      <Form className={`d-flex gap-3 justify-content-between`}>
        <Form.Select
          {...register("status_cadastro")}
          size="sm"
          type="text"
          className="is-required"
          value={stateValue}
          onChange={(event) => onChangeStatus(event)}
          disabled={disabledStatus}
          data-testid={`select_status_driver_${driver.id}`}
        >
          <>
            {disabledStatus ? (
              <option value="">-- Sem ações disponíveis --</option>
            ) : (
              <>
                <option value="" disabled>
                  -- Escolha uma ação --
                </option>
                {actionsList.map((action) => (
                  <option
                    value={action?.value}
                    key={action?.id}
                    data-testid={`change_driver_${driver.id}_status_select_${action.value}`}
                    disabled={action?.id === 2 && isDisabled}
                  >
                    {action?.label}
                  </option>
                ))}
              </>
            )}
          </>
        </Form.Select>
        <Form.Group className="d-flex gap-2 justify-content-end align-items-center text-end">
          <ActionButton
            type="button"
            title="Salvar"
            data-testid={`salve_status_button_${driver.id}`}
            disabled={disabledStatus}
            className="align-items-center d-flex"
            onClick={handleSubmit(() => handleChangeStatus(driver, stateValue))}
            style={{
              border: disabledStatus
                ? "transparent !important"
                : "1px solid #b0b0b0",
              boxShadow: disabledStatus
                ? "none !important"
                : "0.5px 0.5px 0.5px #b0b0b0",
            }}
          >
            <IoMdSave
              size={15}
              color={disabledStatus ? "transparent" : "#2185D0"}
            />
          </ActionButton>

          <ContactCard
            icon={<HiOutlineInformationCircle size={15} color="#0e5a8c" />}
            route={
              driver?.status_cadastro === "7r"
                ? `/motoristas-informacoes/${driver?.id}`
                : `/motoristas-listar/temporario/${driver.id}`
            }
          />

          <ContactCard
            icon={<AiOutlineWhatsApp color="#18980e" />}
            route={whatsappLink}
            target="_blank"
          />
        </Form.Group>
      </Form>
    </TableItem>
  );
};

export default ActionsOptions;
