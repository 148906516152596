import { Image, Modal, Ratio } from "react-bootstrap";
import { ModalTitle } from "./styles";
import { AiOutlineDownload } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Loading } from "../Loading/Loading";

const ImageModal = ({
  image,
  show,
  handleClose,
  title,
  isLoadingImage = false,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="custom-modal-90w"
      animation
      fullscreen
      centered
    >
      <Modal.Header closeButton>
        <ModalTitle>{title}</ModalTitle>
      </Modal.Header>

      {isLoadingImage && <Loading />}

      {image && !isLoadingImage && (
        <Modal.Body>
          <div className="p-3">
            {image?.type === "application/pdf" ? (
              <div style={{ width: "100%", height: "auto" }}>
                <Ratio aspectRatio="1x1">
                  <embed type="application/pdf" src={image?.url} />
                </Ratio>
              </div>
            ) : (
              <div style={{ width: "500px" }}>
                <Image src={image?.url} fluid />
              </div>
            )}
          </div>
        </Modal.Body>
      )}

      <Modal.Footer className="d-flex justify-content-center">
        <Link
          to={image?.url}
          target="_blank"
          download="cnh.jpeg"
          className="d-flex align-items-center"
        >
          <AiOutlineDownload />
          <span className="ms-1">Download</span>
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
