import moment from "moment";
import { Col, Form, Row } from "react-bootstrap";

import { FaRegIdCard } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { BsCalendar3 } from "react-icons/bs";

import { Item } from "./styles";
import UploadCnhImageInput from "../components/DriversForms/UploadCnhImageInput";

const CNHValidationSection = ({
  driver,
  reportInconsistency,
  handleInputChange,
  changing,
  handleShowModal,
}) => {
  const isExpired =
    driver?.dt_validade_cnh && new Date(driver?.dt_validade_cnh) < new Date();

  return (
    <Row xl={2} className="d-flex p-2">
      <Col xl={7}>
        <div id="cnh-flex-consulta-validations">
          <Item className="gap-1">
            {reportInconsistency && (
              <Form.Check
                size="sm"
                type="switch"
                id={`default-switch-cnh`}
                checked={changing?.cnh}
                active={changing.cnh}
                onChange={() => handleInputChange("cnh")}
              />
            )}
            <FaRegIdCard size={15} color="#093c5e" />
            <h3>CNH:</h3>
            <h4>{driver?.cnh ? driver?.cnh : "--"}</h4>
          </Item>

          <Item className="gap-1 mt-4">
            {reportInconsistency && (
              <Form.Check
                size="sm"
                type="switch"
                id={`default-switch-numero_registro_cnh`}
                checked={changing?.numero_registro_cnh}
                active={changing.numero_registro_cnh}
                onChange={() => handleInputChange("numero_registro_cnh")}
              />
            )}
            <FaRegIdCard size={15} color="#093c5e" />
            <h3>Número de registro da CNH:</h3>
            <h4>
              {driver?.numero_registro_cnh ? driver?.numero_registro_cnh : "--"}
            </h4>
          </Item>

          <Item className="gap-1 mt-4">
            {reportInconsistency && (
              <Form.Check
                size="sm"
                type="switch"
                id={`default-switch-categoria_cnh_mot`}
                checked={changing?.categoria_cnh_mot}
                onChange={() => handleInputChange("categoria_cnh_mot")}
              />
            )}
            <BiCategory size={17} color="#093c5e" />
            <h3>Categoria CNH:</h3>
            <h4>
              {driver?.categoria_cnh_mot ? driver?.categoria_cnh_mot : "--"}
            </h4>
          </Item>
          <Item className="gap-1 mt-4">
            {reportInconsistency && (
              <Form.Check
                size="sm"
                type="switch"
                id={`default-switch-dt_validade_cnh`}
                checked={changing?.dt_validade_cnh}
                onChange={() => handleInputChange("dt_validade_cnh")}
              />
            )}
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Validade CNH:</h3>
            <h4 className={`${isExpired ? "text-danger" : "text-success"}`}>
              {driver?.dt_validade_cnh
                ? moment.utc(driver?.dt_validade_cnh).format("DD/MM/YYYY")
                : "--"}
            </h4>
          </Item>
          <Item className="gap-1 mt-4">
            {reportInconsistency && (
              <Form.Check
                size="sm"
                type="switch"
                id={`default-switch-dt_emissao_cnh`}
                checked={changing?.dt_emissao_cnh}
                onChange={() => handleInputChange("dt_emissao_cnh")}
              />
            )}
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Emissão CNH:</h3>
            <h4>
              {driver?.dt_emissao_cnh
                ? moment.utc(driver?.dt_emissao_cnh).format("DD/MM/YYYY")
                : "--"}
            </h4>
          </Item>
          <Item className="gap-1 mt-4">
            {reportInconsistency && (
              <Form.Check
                size="sm"
                type="switch"
                id={`default-switch-dt_primeira_cnh`}
                checked={changing?.dt_primeira_cnh}
                onChange={() => handleInputChange("dt_primeira_cnh")}
              />
            )}
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Primeira CNH:</h3>
            <h4>
              {driver?.dt_primeira_cnh
                ? moment.utc(driver?.dt_primeira_cnh).format("DD/MM/YYYY")
                : "--"}
            </h4>
          </Item>
        </div>
      </Col>
      <Col xl={5} className="py-4 pb-1 d-flex gap-1">
        {reportInconsistency && (
          <Form.Check
            size="sm"
            type="switch"
            id={`default-switch-foto_cnh`}
            checked={changing?.foto_cnh}
            onChange={() => handleInputChange("foto_cnh")}
          />
        )}
        <UploadCnhImageInput
          driver={driver}
          width="160px"
          paddingBottom={`10px`}
          height="160px"
          hideInput={true}
          handleShowModal={handleShowModal}
          className="w-100"
          align="start"
        />
      </Col>
    </Row>
  );
};

export default CNHValidationSection;
