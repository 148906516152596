export const sortedNames = (items) =>
  items.sort((a, b) => a.nome.localeCompare(b.nome));

export const sortedLabels = (items) =>
  items.sort((a, b) => a.label.localeCompare(b.label));

export const sortedCompanies = (companies) =>
  companies.sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));

export const sortedDrivers = (items) =>
  items.sort((a, b) => a.nome_mot.localeCompare(b.nome_mot));

export const sortByLastTravelDate = (items) =>
  items.sort((a, b) => b?.dt_viagem.localeCompare(a?.dt_viagem));

export const sortedVehicle = (items) =>
  items.sort((a, b) => a.placa_cavalo.localeCompare(b.placa_cavalo));

export const sortedByDescription = (colors) => {
  const filteredColors = colors.filter(
    (color) => color.descricao.trim() !== ""
  );

  return filteredColors.sort((a, b) => a.descricao.localeCompare(b.descricao));
};

export const sortByDateDescending = (occurrences) => {
  return occurrences.sort((a, b) => new Date(b.data) - new Date(a.data));
};
export const sortByDateAscending = (occurrences) => {
  return occurrences.sort((a, b) => new Date(a.data) - new Date(b.data));
};

export const sortedFantasyNames = (items) =>
  items.sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));

export const sortedStatus = (items) =>
  items.sort((a, b) => {
    const order = {
      2: 3,
      1: 2,
      0: 1,
    };

    const orderA = order[a.status_motorista] || 0;
    const orderB = order[b.status_motorista] || 0;

    return orderB - orderA; // ordem decrescente com base na ordem definida
  });

export const sortedTravelsQuantities = (items) =>
  items.sort((a, b) =>
    a.quantidade_viagens.localeCompare(b.quantidade_viagens)
  );

export const onlyNumbers = (str) => str.replace(/\D/g, "");

export const formatCPF = (cpf) => {
  const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
  return cpf.replace(cpfRegex, "$1.$2.$3-$4");
};

export const formatCellPhoneNumber = (number) => {
  const celRegex = /^(\d{2})(\d{4,5})(\d{4})$/;
  return number.replace(celRegex, "($1) $2-$3");
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
  if (match) {
    const prefix = match[1];
    const middle = match[2].length === 4 ? match[2] : match[2].substring(0, 5);
    const suffix = match[3];
    return `(${prefix}) ${middle}-${suffix}`;
  }
  return null;
};

export const formatRHNumber = (RHNumberString) => {
  const cleaned = ("" + RHNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{1})$/);
  if (match) {
    return match[1] + "." + match[2] + "." + match[3] + "-" + match[4];
  }
  return null;
};

export const formatCEP = (cep) => {
  if (!cep) return "";
  cep = cep.replace(/\D/g, "");
  cep = cep.padStart(8, "0");
  return `${cep.substr(0, 5)}-${cep.substr(5)}`;
};

export const formatRG = (numberRG) => {
  numberRG = numberRG.replace(/\D/g, "");

  numberRG = numberRG.replace(/^(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");

  return numberRG;
};

export const alphabets = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const alphabetList = [
  { value: null, label: "Limpar seleção" },
  ...alphabets.map((alphabet) => ({
    value: alphabet,
    label: alphabet,
  })),
];

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const maskCNH = (cnh) => {
  const cnhSize = cnh.length;
  const lastsDigits = cnh.substring(cnhSize - 3, cnhSize);
  return "*********" + lastsDigits;
};

export const mascararCPF = (cpf) => {
  const cpfSize = cpf.length;
  const firstDigits = cpf.substring(0, 3);
  const middleDigits = "*".repeat(3);
  const finalDigits = cpf.substring(cpfSize - 2, cpfSize);
  return `${firstDigits}.${middleDigits}.${middleDigits}-${finalDigits}`;
};

export const convertPercentage = (value) => {
  return parseFloat((value * 100).toFixed(2));
};

export const isCpfValid = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, "");

  if (cpf.length !== 11) {
    return false;
  }

  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let remainder = 11 - (sum % 11);
  let digit = remainder > 9 ? 0 : remainder;

  if (parseInt(cpf.charAt(9)) !== digit) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  remainder = 11 - (sum % 11);
  digit = remainder > 9 ? 0 : remainder;

  if (parseInt(cpf.charAt(10)) !== digit) {
    return false;
  }

  return true;
};

export const verificaLetras = (valor) => {
  var regex = /[a-zA-Z]/;

  if (regex.test(valor)) {
    return true;
  } else {
    return false;
  }
};

export const isCpfOrCnpjValid = (number) => {
  let cleanedNumber = number.replace(/[^\d]+/g, "");

  if (cleanedNumber.length === 11) {
    if (/^(\d)\1+$/.test(cleanedNumber)) {
      return false;
    }

    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cleanedNumber.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    let digit = remainder > 9 ? 0 : remainder;

    if (parseInt(cleanedNumber.charAt(9)) !== digit) {
      return false;
    }

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cleanedNumber.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    digit = remainder > 9 ? 0 : remainder;

    if (parseInt(cleanedNumber.charAt(10)) !== digit) {
      return false;
    }

    return true;
  } else if (cleanedNumber.length === 14) {
    let sum = 0;
    let weight = 2;
    let length = cleanedNumber.length - 2;

    for (let i = length; i >= 0; i--) {
      sum += parseInt(cleanedNumber.charAt(i)) * weight;
      weight++;
      if (weight > 9) {
        weight = 2;
      }
    }

    let remainder = sum % 11;
    let digit = remainder < 2 ? 0 : 11 - remainder;

    if (parseInt(cleanedNumber.charAt(length + 1)) !== digit) {
      return false;
    }

    sum = 0;
    weight = 2;
    length++;

    for (let i = length; i >= 0; i--) {
      sum += parseInt(cleanedNumber.charAt(i)) * weight;
      weight++;
      if (weight > 9) {
        weight = 2;
      }
    }

    remainder = sum % 11;
    digit = remainder < 2 ? 0 : 11 - remainder;

    if (parseInt(cleanedNumber.charAt(length + 1)) !== digit) {
      return false;
    }

    return true;
  }

  return false;
};

export const formatString = (string) => {
  const parts = string.split("_");
  const formattedParts = parts.map(
    (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  );
  const formattedString = formattedParts.join(" ");

  return formattedString;
};

export const isNumber = (value) => {
  return typeof value === "number";
};

export const percentageDriverStatus = (
  objeto,
  status,
  driverConsults,
  judicialProcessStatus
) => {
  const allCompleted =
    driverConsults &&
    driverConsults.length > 0 &&
    driverConsults.every(
      (consult) =>
        consult.concluido &&
        !consult.processamento_antecedentes_criminais &&
        !consult.processamento_mandados_prisao
    );

  const chaves = Object.keys(objeto);
  const itensNaoNulos = status
    ? chaves.filter((chave) => objeto[chave] !== null).length
    : 0;
  const judicialProcessValue = judicialProcessStatus === true ? 1 : 0;

  if (allCompleted) {
    return `${itensNaoNulos + judicialProcessValue + 1}/5`;
  }
  return `${itensNaoNulos + judicialProcessValue}/5`;
};

export const cpfCnpjFormat = (document) => {
  const numbers = document.replace(/\D/g, "");

  const digitCount = numbers.length;
  if (digitCount === 11) {
    return numbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (digitCount === 14) {
    return numbers.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    return;
  }
};

export const removerCaracteresNaoAlfabeticos = (str) => {
  str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  str = str.replace(/-/g, "");

  str = str.replace(/ç/g, "c");

  str = str.replace(/Ç/g, "C");

  str = str.replace(/[^a-zA-Z\s]/g, "");

  return str;
};

export const formatCpfCnpj = (value) => {
  const digits = value.replace(/\D/g, "");
  if (digits.length === 11) {
    const firstBlock = digits.slice(0, 3);
    const secondBlock = digits.slice(3, 6);
    const thirdBlock = digits.slice(6, 9);
    const fourthBlock = digits.slice(9);
    return `${firstBlock}.${secondBlock}.${thirdBlock}-${fourthBlock}`;
  } else if (digits.length === 14) {
    const firstBlock = digits.slice(0, 2);
    const secondBlock = digits.slice(2, 5);
    const thirdBlock = digits.slice(5, 8);
    const fourthBlock = digits.slice(8, 12);
    const fifthBlock = digits.slice(12);
    return `${firstBlock}.${secondBlock}.${thirdBlock}/${fourthBlock}-${fifthBlock}`;
  } else {
    return value;
  }
};

export const formatLicensePlate = (plate) => {
  const letters = plate.slice(0, 3);
  const numbers = plate.slice(3);

  return `${letters}-${numbers}`;
};

export const findObjectWithHighestId = (array) => {
  if (array.length === 0) {
    return null;
  }

  let highestIdObject = array[0];
  for (let i = 1; i < array.length; i++) {
    if (array[i].id > highestIdObject.id) {
      highestIdObject = array[i];
    }
  }

  return highestIdObject;
};

export const extractRealTimeData = (data) => {
  if (data?.geo_localizacao) {
    const geoLocalizacao = JSON.parse(data.geo_localizacao);
    const lat = geoLocalizacao.latitude;
    const lng = geoLocalizacao.longitude;

    const realTime = {
      lat: lat,
      lng: lng,
    };

    return realTime;
  }
};

export const findObjectWithLatestDate = (objects) => {
  if (!Array.isArray(objects) || objects.length === 0) {
    return null;
  }

  let latestDateObject = objects[0];

  for (let i = 1; i < objects.length; i++) {
    if (new Date(objects[i].data) > new Date(latestDateObject.data)) {
      latestDateObject = objects[i];
    }
  }

  return latestDateObject;
};

export const removeDuplicatedDescriptions = (data) => {
  const uniqueDescriptions = {};
  const result = [];

  for (const item of data) {
    const descricao = item.descricao.toLowerCase().trim();
    if (!uniqueDescriptions[descricao]) {
      uniqueDescriptions[descricao] = true;
      result.push(item);
    }
  }

  return result;
};

export const replaceVariablesInPath = (path, id, token, company) => {
  let newPath = path;

  if (id) {
    newPath = newPath.replace(":id", id);
  }

  if (token) {
    newPath = newPath.replace(":token", token);
  }

  if (company) {
    newPath = newPath.replace(":company", company);
  }

  return newPath;
};

export const firstLetterCapitalized = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const cnpjCpfFormat = (str) => {
  const value = str.replace(/\D/g, "");

  if (value.length === 11) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (value.length === 14) {
    return value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    return "--";
  }
};

export const whatsappFormat = (number) => {
  const numeroLimpo = number.replace(/\D/g, "");

  const numeroFormatado = `55${numeroLimpo}`;

  return numeroFormatado;
};

export const isCpfOrCnpf = (value) => {
  const onlyNumbers = value.replace(/\D/g, "");

  if (onlyNumbers.length === 11) {
    return true;
  } else {
    return false;
  }
};

export const firstUppercase = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const currentMonth = (dataString) => {
  const data = new Date(dataString);

  const mesData = data.getMonth();

  const mesAtual = new Date().getMonth();

  return mesData === mesAtual;
};

export const clearGeoLoactionsDuplicates = (array) => {
  const uniqueGeoLocations = new Set();

  const result = array.filter((obj) => {
    if (uniqueGeoLocations.has(obj.geo_localizacao)) {
      return false;
    }
    uniqueGeoLocations.add(obj.geo_localizacao);
    return true;
  });

  return result;
};

export const formatLowerCaseString = (str) => {
  let words = str.toLowerCase();

  return words.charAt(0).toUpperCase() + words.slice(1).toLowerCase();
};

export const formatNameString = (str) => {
  if (str) {
    let words = str.toLowerCase().split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    return words.join(" ");
  } else {
    return "--";
  }
};

export const objectWithLatestDate = (data) => {
  return data.sort(
    (a, b) => new Date(a.dataJulgamento) - new Date(b.dataJulgamento)
  );
};

export const formatCnjnumber = (number) => {
  const numberStr = String(number);

  if (numberStr.length < 16) {
    return "Invalid number format";
  }

  const parts = [
    numberStr.slice(0, 7),
    numberStr.slice(7, 9),
    numberStr.slice(9, 13),
    numberStr.slice(13, 14),
    numberStr.slice(14, 16),
    numberStr.slice(16),
  ];

  const formattedNumber = `${parts.slice(0, -1).join("-")}.${parts.slice(-1)}`;

  return formattedNumber;
};

export const formatarFrase = (frase) => {
  let fraseFormatada = frase.toLowerCase();
  fraseFormatada =
    fraseFormatada.charAt(0).toUpperCase() + fraseFormatada.slice(1);
  return fraseFormatada;
};

// export function base64ToImage(base64String) {
//   const parts = base64String.split(";base64,");
//   const type = parts[0].split(":")[1];
//   const imageData = window.atob(parts[1]);
//   const arrayBuffer = new ArrayBuffer(imageData.length);
//   const view = new Uint8Array(arrayBuffer);

//   for (let i = 0; i < imageData.length; i++) {
//     view[i] = imageData.charCodeAt(i);
//   }

//   const blob = new Blob([arrayBuffer], { type });

//   return new File([blob], "captura.jpeg", { type });
// }

export function base64ToImage(base64String) {
  // Remove a parte do tipo de dados do base64 string
  const base64Data = base64String.replace(/^data:[^;]*;base64,/, "");

  // Decodifica o base64
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Cria o blob
  const blob = new Blob([byteArray], { type: "image/jpeg" });

  // Retorna o arquivo
  return new File([blob], "captura.jpeg", { type: "image/jpeg" });
}

export const formatNumber = (numero) => {
  if (Number.isInteger(numero)) {
    return numero;
  } else {
    return parseFloat(numero.toFixed(2));
  }
};
