import { memo, useCallback, useEffect, useState } from "react";
import Dashboard from "../../../../Shared/layout";
import { Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTravels } from "../../../../hooks/TravelProvider";
import { ContainerRow, LabelCard } from "./styles";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import { Subtitle } from "../../../../Shared/Components/GoogleMapsLocator/Subtitle";
import { ChoosenRouteMap } from "../../../../Shared/Components/LeafletMaps/ChoosenRouteMap/ChoosenRouteMap";
import { MdHorizontalRule } from "react-icons/md";

const ChooseRoute = () => {
  const [originAddress, setOriginAddress] = useState(null);
  const [destinyAddress, setDestinationAddress] = useState(null);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [isChangeRoute, setIsChangeRoute] = useState(false);
  const [selectedDirection, setSelectedDirection] = useState(null);

  const { token } = useParams();
  const navigate = useNavigate();

  const {
    loadOrder,
    isLoading,
    loadOrderAddressLoading,
    getLoadOrderAddress,
    getLoadOrder,
    editLoadOrder,
  } = useTravels();

  useEffect(() => {
    token && getLoadOrder(token);
  }, [getLoadOrder, token]);

  useEffect(() => {
    if (loadOrder?.rota_selecionada) {
      navigate(`/gestao-viagens/ordem-de-carregamento/${loadOrder?.token}`);
    }
  }, [loadOrder?.rota_selecionada, loadOrder?.token, navigate]);

  const handleAddress = useCallback(async () => {
    try {
      setLoadingAddress(true);
      if (loadOrder?.latitude_coleta && loadOrder?.longitude_coleta) {
        setOriginAddress({
          lat: loadOrder?.latitude_coleta,
          lng: loadOrder?.longitude_coleta,
        });
      } else if (loadOrder?.nome_cidade_coleta && loadOrder?.uf_coleta) {
        const origin = loadOrder?.nome_cidade_coleta
          ? await getLoadOrderAddress(
              `${loadOrder?.nome_cidade_coleta},${loadOrder?.uf_coleta},Brasil`
            )
          : null;

        setOriginAddress({
          lat: origin.length > 0 ? origin[0]?.lat : origin?.lat,
          lng: origin.length > 0 ? origin[0]?.lon : origin?.lon,
        });
      }

      if (loadOrder?.latitude_entrega && loadOrder?.longitude_entrega) {
        setDestinationAddress({
          lat: loadOrder?.latitude_entrega,
          lng: loadOrder?.longitude_entrega,
        });
      } else if (loadOrder?.nome_cidade_entrega && loadOrder?.uf_entrega) {
        const destiny = loadOrder?.nome_cidade_entrega
          ? await getLoadOrderAddress(
              `${loadOrder?.nome_cidade_entrega},${loadOrder?.uf_entrega},Brasil`
            )
          : null;

        setDestinationAddress({
          lat: destiny.length > 0 ? destiny[0]?.lat : destiny?.lat,
          lng: destiny.length > 0 ? destiny[0]?.lon : destiny?.lon,
        });
      }
    } catch (error) {
      setOriginAddress(null);
      setDestinationAddress(null);
    } finally {
      setLoadingAddress(false);
    }
  }, [getLoadOrderAddress, loadOrder]);

  useEffect(() => {
    handleAddress();
  }, [handleAddress]);

  const handleSelectRoute = useCallback(async () => {
    const data = {
      rota_selecionada: selectedDirection && JSON.stringify(selectedDirection),
      status: "8",
    };

    await editLoadOrder(loadOrder?.id, data, navigate);
    getLoadOrder(token);
  }, [
    editLoadOrder,
    getLoadOrder,
    loadOrder?.id,
    navigate,
    selectedDirection,
    token,
  ]);

  return (
    <Dashboard>
      {(loadOrderAddressLoading || isLoading || loadingAddress) && <Loading />}
      {!loadOrderAddressLoading &&
        !isLoading &&
        !loadingAddress &&
        originAddress &&
        destinyAddress &&
        loadOrder && (
          <ContainerRow className="mt-1 g-1">
            <Col xl={12}>
              {!isChangeRoute && (
                <div className="d-flex">
                  <LabelCard className="d-flex justify-content-start align-items-center rounded gap-1 p-3">
                    <MdHorizontalRule color="gray" size="2rem" />
                    <span>
                      Selecione a rota desejada no mapa clicando sobre ela.
                    </span>
                  </LabelCard>
                </div>
              )}
              <ChoosenRouteMap
                origin={originAddress}
                destiny={destinyAddress}
                setSelectedDirection={setSelectedDirection}
                setIsChangeRoute={setIsChangeRoute}
                isChangeRoute={isChangeRoute}
              />
              <div className="d-md-flex gap-2 mt-2 justify-content-between p-1">
                <div className="d-flex align-items-center justify-content-start gap-2">
                  <Subtitle
                    label="Rota selecionada"
                    reactIcon={<MdHorizontalRule color="blue" size="2rem" />}
                  />
                  <Subtitle
                    label="Rota disponível"
                    reactIcon={<MdHorizontalRule color="gray" size="2rem" />}
                  />
                </div>

                <div className="d-flex gap-2">
                  <ButtonLink
                    fontSize="0.7rem"
                    buttoncolor="primary"
                    handleClick={handleSelectRoute}
                    label="Escolher esse percurso"
                    disabled={!selectedDirection}
                  />
                  <ButtonLink
                    fontSize="0.7rem"
                    link={`/gestao-viagens/ordem-de-carregamento/${loadOrder?.token}`}
                    label="Fechar"
                  />
                </div>
              </div>
            </Col>
          </ContainerRow>
        )}
      {!loadOrderAddressLoading &&
        !isLoading &&
        !loadingAddress &&
        (originAddress === null || destinyAddress === null) &&
        loadOrder && (
          <div className="mt-5">
            <EmptyTableMessage />
          </div>
        )}
    </Dashboard>
  );
};

export default memo(ChooseRoute);
