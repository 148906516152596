import { Row } from "react-bootstrap";
import styled from "styled-components";

export const VehicleListRow = styled(Row)`
max-height: 50vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 425px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 60vh;
 }

 @media(min-width: 1440px){
  max-height: 70vh;
  }

  @media(min-width: 1640px){
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  max-height: 80vh;
  }
`

export const Subtitle = styled.h2`
  font-size: 0.9rem;
  font-weight: bold,
  
`;

export const LineBorder = styled.div`
  background-color: var(--color-blue-80);
  width: 3.6rem;
  height: 0.2rem;
  margin-bottom: 1rem;
`;