import { Card, FloatingLabel, Form, Row } from "react-bootstrap";
import styled from "styled-components";

export const ScrollSection = styled.div`
  max-height: 70vh;
  flex: 1;
  overflow-x: hidden;
  padding-bottom: 25px;

  @media (min-width: 1640px) {
    max-height: 75vh;
  } 
  @media (min-width: 2000px) {
    max-height: 81vh;
  } 
  @media (min-width: 3000px) {
    max-height: 85vh;
  }  
`;


export const PasswordInputContainer = styled(FloatingLabel)`
  background-color: transparent !important;
  border: 1px solid var(--color-gray-10);
  border-radius: 7px;
  border-left: 2px solid red;
`
export const PasswordFormControl = styled(Form.Control)`
  border: none;

  &:focus{
    outline: none;
    box-shadow: none;
  }
`
export const PasswordButton = styled.button`
  background-color: transparent !important;
  border: none !important;
  color: var(--color-gray-12);
  font-size: 1.5rem;

  &:hover{
    background-color: transparent !important;
    border: none !important;
    color: var(--color-gray-15);  
  }

  &:focus{
    background-color: transparent !important;
    border: none !important;
    color: var(--color-gray-15); 
  }
 
`
export const SectionContainer = styled(Card)`
background-color: #FFFAFA;
  border: none;
`

export const TitleContainer = styled.div`
  background-color: var(--color-gray-2);
  padding: 10px;
`

export const InfoTitle = styled.span`
  color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;

export const WhatsappButton = styled.button`
  text-decoration: none;

  background-color: #25D366;
border:none;
border-radius: 4px;
color: white !important;
font-weight: bold;
font-size: 0.875rem;
padding: 7px 10px;

transition: transform 200ms;

&:focus{
  transform: scale(1.01);
}
&:hover{
  transform: scale(1.01);
}
`;




export const Thead = styled.thead`
position: sticky;
 top: 0;
 z-index: 1;
 background-color: white;
`

export const TableTitle = styled.tr`

th {
  font-size: 0.875rem;
  font-weight: bold;
  
}
`

export const Title = styled.th`
font-size: 0.65rem;
color: var(--color-gray-15);
font-weight: 700;

@media(min-width: 1440px){
  font-size: 1rem !important;
}
`

export const ListCTeContainer = styled(Row)`
  overflow-x: auto;
  max-height:45vh;
`

export const TableContainer = styled.div`
  overflow: auto;
  max-height: 20vh;

 @media(min-width: 576px){
  max-height: 25vh;
 }

 @media(min-width: 1440px){
  max-height: 30vh;
 }


`