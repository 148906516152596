import { Form } from "react-bootstrap";
import styled from "styled-components";

export const RegisterItemForm = styled(Form.Control)`
 height: 40vh;
 width: 100%;
 font-size: 1rem;
 border: 0.5px solid var(--color-gray-11);
 padding-top: 3rem;
 padding-left: 1rem;
 `
