import {
  CoverImage,
  Document,
  SelectOptions,
  Status,
  Subtitle,
  Title,
} from "./styles";
import EmptyImage from "../../../../../../Assets/Images/EmptyImage.png";
import { useCallback, useEffect, useMemo, useState } from "react";
import ButtonLink from "../../../../../../Shared/Components/ButtonLink/ButtonLink";
import { Form } from "react-bootstrap";
import { SubmitButton } from "../../../../../../Shared/Components/SubmitButton/SubmitButton";
import { BsPlus } from "react-icons/bs";

export const BindingCard = ({
  titleCard,
  list,
  loadOrder,
  getItemList,
  itemList,
  handleSubmit,
  setValue,
  isSubmitting,
  relationshipBetweenAttributes,
  editLoadOrder,
  id,
}) => {
  const [selectedImage, setSelectedImage] = useState(EmptyImage);
  const [showSelected, setShowSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [imageChange, setImageChange] = useState(false);

  const listOptions = useMemo(() => {
    if (titleCard === "Motorista") {
      if (relationshipBetweenAttributes?.motorista) {
        return [
          {
            id: relationshipBetweenAttributes?.motorista?.id,
            label: relationshipBetweenAttributes?.motorista?.nome,
            value: relationshipBetweenAttributes?.motorista?.id,
          },
        ];
      } else {
        const driver = list.map((listItem) => ({
          id: listItem.id,
          label: listItem.nome,
        }));

        return driver;
      }
    }

    if (titleCard === "Proprietário") {
      if (relationshipBetweenAttributes?.proprietario) {
        return [
          {
            id: relationshipBetweenAttributes?.proprietario?.id,
            label: relationshipBetweenAttributes?.proprietario?.nome,
            value: relationshipBetweenAttributes?.proprietario?.id,
          },
        ];
      } else {
        return list.map((listItem) => ({
          id: listItem.id,
          label: listItem.nome,
        }));
      }
    }
    if (titleCard === "Veículo") {
      if (relationshipBetweenAttributes?.veiculo) {
        return [
          {
            id: relationshipBetweenAttributes?.veiculo?.id,
            label: relationshipBetweenAttributes?.veiculo?.placa,
            value: relationshipBetweenAttributes?.veiculo?.id,
          },
        ];
      } else {
        return list.map((listItem) => ({
          id: listItem.id,
          label: listItem.placa,
        }));
      }
    }
  }, [
    list,
    relationshipBetweenAttributes?.motorista,
    relationshipBetweenAttributes?.proprietario,
    relationshipBetweenAttributes?.veiculo,
    titleCard,
  ]);

  const handleShowSelectList = useCallback(() => {
    setShowSelected(true);
  }, []);

  const handleSelectList = useCallback(
    (event) => {
      if (titleCard === "Motorista") {
        setValue("motorista_id", event.id);
      }

      if (titleCard === "Proprietário") {
        setValue("proprietario_id", event.id);
      }

      if (titleCard === "Veículo") {
        setValue("veiculo_id", event.id);
      }

      setSelectedItem(event.id);
      getItemList(event.id);

      setImageChange(true);
    },
    [getItemList, setValue, titleCard]
  );

  useEffect(() => {
    let selectedImage = EmptyImage;

    if (
      titleCard === "Motorista" &&
      imageChange &&
      itemList?.foto_motorista?.url
    ) {
      selectedImage = itemList.foto_motorista?.url;
    } else if (
      titleCard === "Proprietário" &&
      imageChange &&
      itemList?.foto_proprietario?.url
    ) {
      selectedImage = itemList.foto_proprietario?.url;
    } else if (
      titleCard === "Veículo" &&
      imageChange &&
      itemList?.foto_documento?.url
    ) {
      selectedImage = itemList.foto_documento?.url;
    }

    setSelectedImage(selectedImage);
  }, [
    titleCard,
    imageChange,
    itemList?.foto_documento,
    itemList?.foto_motorista,
    itemList?.foto_proprietario,
  ]);

  const placeholder = titleCard.toLowerCase();

  const handleCancel = () => {
    setShowSelected(false);
    getItemList(null);
  };

  return (
    <div
      className="text-center mb-2 d-flex flex-column"
      data-testid="binding-card-container"
    >
      <Title>{titleCard}</Title>
      <div className="d-flex justify-content-center mb-2">
        <CoverImage image={selectedImage} />
      </div>
      <Subtitle>
        {itemList?.nome
          ? itemList?.nome
          : itemList?.placa
          ? itemList?.placa
          : "--"}
      </Subtitle>
      {itemList && (
        <>
          <Document>{itemList?.cpf}</Document>
          <Status className="mt-auto d-flex justify-content-center">
            Status do cadastro:
            <span
              className={`ms-1 ${
                itemList?.ativo === true ? "color-active" : "color-red"
              }`}
            >
              {itemList?.ativo === true ? "Liberado" : "Desativado"}
            </span>
          </Status>
        </>
      )}
      {!showSelected && (
        <div className="d-flex justify-content-center w-100">
          <ButtonLink
            icon={<BsPlus size={20} />}
            buttoncolor="primary"
            label={`Adicionar ${placeholder}`}
            handleClick={handleShowSelectList}
            dataTestId={`button-to-select-${placeholder}`}
          />
        </div>
      )}
      {showSelected && (
        <Form
          onSubmit={handleSubmit((event) => {
            if (titleCard === "Motorista") {
              delete event.proprietario_id;
              delete event.veiculo_id;

              if (
                (!loadOrder?.campos_inconsistencia ||
                  loadOrder?.campos_inconsistencia === "[]") &&
                loadOrder?.senha_resposta &&
                loadOrder?.proprietario_id &&
                loadOrder?.veiculo_id
              ) {
                editLoadOrder(id, {
                  motorista_id: event?.motorista_id,
                  status: "9",
                });
              } else {
                editLoadOrder(id, event);
              }
            } else if (titleCard === "Proprietário") {
              delete event.motorista_id;
              delete event.veiculo_id;

              if (
                (!loadOrder?.campos_inconsistencia ||
                  loadOrder?.campos_inconsistencia === "[]") &&
                loadOrder?.senha_resposta &&
                loadOrder?.motorista_id &&
                loadOrder?.veiculo_id
              ) {
                editLoadOrder(id, {
                  proprietario_id: event?.proprietario_id,
                  status: "9",
                });
              } else {
                editLoadOrder(id, event);
              }

              editLoadOrder(id, event);
            } else if (titleCard === "Veículo") {
              delete event.proprietario_id;
              delete event.motorista_id;

              if (
                (!loadOrder?.campos_inconsistencia ||
                  loadOrder?.campos_inconsistencia === "[]") &&
                loadOrder?.senha_resposta &&
                loadOrder?.motorista_id &&
                loadOrder?.proprietario_id
              ) {
                editLoadOrder(id, {
                  veiculo_id: event?.veiculo_id,
                  status: "9",
                });
              } else {
                editLoadOrder(id, event);
              }
            }

            getItemList(null);
          })}
        >
          <div className="d-flex flex-column justify-content gap-2">
            <SelectOptions
              className="react-select mb-3"
              options={listOptions}
              onChange={handleSelectList}
              value={selectedItem}
              placeholder={`Selecionar ${placeholder}`}
              data-testid="list-select"
              name={`${
                titleCard === "Motorista"
                  ? "motorista_id"
                  : titleCard === "Proprietário"
                  ? "proprietario_id"
                  : "veiculo_id"
              }`}
            />
            <SubmitButton
              buttoncolor="primary"
              labelButton="Confirmar"
              isSubmitting={isSubmitting}
              dataTestId="binding-submitting-button"
            />
            <ButtonLink
              label="Cancelar"
              buttoncolor="gray"
              fontSize="0.7rem"
              handleClick={handleCancel}
            />
            <ButtonLink
              label={`Adicionar ${placeholder}`}
              buttoncolor="gray"
              fontSize="0.7rem"
              secondIcon={<BsPlus />}
              link={`/${
                titleCard === "Motorista"
                  ? "motoristas"
                  : titleCard === "Proprietário"
                  ? "proprietarios"
                  : "veiculos"
              }-adicionar`}
            />
          </div>
        </Form>
      )}
    </div>
  );
};
