import { useMemo } from "react";
import { Card, Form, Spinner } from "react-bootstrap";

import {
  BorderCard,
  CardBody,
  CoverImage,
  StatusContent,
  StatusItem,
} from "./styles";
import DriverImage from "../../../../../../Assets/Images/driver-image.svg";
import { registrationStatus } from "../../../../../../Shared/Utils/constants";
import ButtonLink from "../../../../../../Shared/Components/ButtonLink/ButtonLink";

import {
  convertPercentage,
  cpfCnpjFormat,
  formatNameString,
  percentageDriverStatus,
} from "../../../../../../Shared/Utils/data";
import { SubmitButton } from "../../../../../../Shared/Components/SubmitButton/SubmitButton";
import { FaSave } from "react-icons/fa";

const ControlDriverCard = ({
  driver,
  showValidationButtons,
  handleCnhValidation,
  handleBiometricValidation,
  handleClick,
  reportInconsistency,
  changing,
  handleInputChange,
  validation,
  biometric,
  isInformation,
  driverValidation,
  handleCancelClick,
  handleNewProcessConsult,
  inProcess,
  isSatValidation,
  hasInconsistencies,
  allFalse,
}) => {
  const companies = useMemo(() => {
    if (
      driver?.empresas_cadastrado &&
      driver?.empresas_cadastrado !== '{"message":"no data"}'
    ) {
      return JSON.parse(driver?.empresas_cadastrado)?.length;
    } else {
      return 0;
    }
  }, [driver?.empresas_cadastrado]);

  const travels = useMemo(() => {
    if (driver?.viagens && driver?.viagens !== '{"message":"no data"}') {
      return JSON.parse(driver?.viagens).length;
    } else {
      return 0;
    }
  }, [driver?.viagens]);

  const biometricValidation = useMemo(() => {
    if (
      !driverValidation ||
      driverValidation?.validacao_biometria?.status !== "1"
    ) {
      return null;
    } else {
      return driverValidation?.validacao_biometria?.resposta;
    }
  }, [driverValidation]);

  const biometricValue = biometricValidation?.foto_motorista?.similaridade
    ? convertPercentage(biometricValidation?.foto_motorista?.similaridade)
    : 0;

  const cnhValidation = useMemo(() => {
    if (!driverValidation || driverValidation?.validacao_cnh?.status !== "1") {
      return null;
    } else {
      return driverValidation?.validacao_cnh?.resposta;
    }
  }, [driverValidation]);

  const validationValue = cnhValidation?.foto_motorista?.similaridade
    ? convertPercentage(cnhValidation?.foto_motorista?.similaridade)
    : 0;

  const link =
    driver?.status_cadastro === "0r"
      ? `/motoristas-listar/${driver?.id}`
      : `/motoristas-listar/temporario/${driver?.id}`;

  return (
    <BorderCard className="text-center  w-100 card p-1">
      <CardBody className="text-center pb-0">
        <CoverImage
          currentDriverImage={driver?.foto_motorista?.url}
          driverImage={DriverImage}
        />
        {reportInconsistency && (
          <Form.Check
            className="mt-3"
            type="switch"
            size="sm"
            id={`default-switch-foto_motorista`}
            checked={changing?.foto_motorista}
            onChange={() => handleInputChange("foto_motorista")}
          />
        )}
        <div className="text-center d-inline-block mt-3 mb-4">
          <h3 className="text-center mb-1">{formatNameString(driver?.nome)}</h3>
          <h4 className="text-center mb-3">{cpfCnpjFormat(driver?.cpf)}</h4>
          {driver?.status_cadastro === "0r" && (
            <div className="text-center">
              <StatusContent>Status:</StatusContent>
              <StatusContent
                className={`ms-1 mt-3 ${
                  driver?.bloqueado_adm === "N" && !driver?.ativo
                    ? "color-red"
                    : driver?.bloqueado_adm === "N" && driver?.ativo
                    ? "color-active"
                    : "color-red"
                }`}
              >
                {driver?.bloqueado_adm === "S"
                  ? "Bloqueado ADM"
                  : driver?.ativo &&
                    !biometric?.foto_motorista?.similaridade &&
                    !validation?.foto_motorista?.similaridade
                  ? "Liberado"
                  : driver?.ativo &&
                    biometric?.foto_motorista?.similaridade &&
                    validation?.foto_motorista?.similaridade &&
                    (validation?.foto_motorista?.similaridade < 0.5 ||
                      biometric?.foto_motorista?.similaridade < 0.5)
                  ? "Liberado - inconsistência biometria"
                  : driver?.ativo &&
                    (validation?.foto_motorista?.similaridade > 0.5 ||
                      biometric?.foto_motorista?.similaridade > 0.5)
                  ? "Liberado"
                  : "Desativado"}
              </StatusContent>
            </div>
          )}

          {driver?.status_cadastro !== "0r" && (
            <div className="mt-1">
              <StatusItem
                status={
                  driver?.status_cadastro === "1r"
                    ? driver?.bloqueio_sat
                    : driver?.status_cadastro
                }
                className="mt-1 text-center"
              >
                {driver?.status_cadastro === "2r" ||
                (driver?.status_cadastro === "5r" &&
                  driver?.processos_cadastro_concluido === false) ? (
                  <>
                    <Spinner animation="border" variant="warning" size="sm" />
                    {` Em análise - ${percentageDriverStatus(
                      {
                        biometria: driver?.status_biometria,
                        cnh: driver?.status_cnh,
                        viagem: driver?.status_viagens,
                      },
                      driver?.processos_cadastro_concluido
                    )}`}
                  </>
                ) : (
                  <>
                    {registrationStatus(
                      driver?.status_cadastro,
                      driver?.processos_cadastro_concluido,
                      driver?.bloqueio_sat
                    )}
                  </>
                )}
              </StatusItem>
            </div>
          )}
        </div>

        {showValidationButtons &&
          (driver?.status_cadastro === "0r" ||
            driver?.status_cadastro === "6r") && (
            <div className="mb-2">
              <ButtonLink
                label="Nova validação biometria"
                handleClick={handleBiometricValidation}
                buttoncolor="primary"
                className="w-100 mb-2 fw-bold"
                disabled={inProcess}
              />
              <ButtonLink
                label="Nova validação CNH"
                handleClick={handleCnhValidation}
                buttoncolor="primary"
                className="w-100 mb-2 fw-bold"
                disabled={inProcess}
              />
              <ButtonLink
                label="Nova consulta judicial"
                handleClick={handleNewProcessConsult}
                buttoncolor="primary"
                className="w-100 mb-2 fw-bold"
                disabled={inProcess}
              />
              <ButtonLink
                label="Consultar histórico de viagens"
                link={
                  !inProcess &&
                  `/motoristas-listar/historico-viagens/${driver?.id}`
                }
                buttoncolor="primary"
                className="w-100 mb-2 fw-bold"
                disabled={inProcess}
              />
              <ButtonLink
                label="Editar informações"
                link={!inProcess && `/motoristas-editar/${driver?.id}`}
                buttoncolor="primary"
                className="w-100 fw-bold"
                disabled={inProcess}
              />
            </div>
          )}

        {!showValidationButtons && (
          <>
            <div className="d-flex flex-column gap-1">
              <ButtonLink
                label="Análise Flex consulta"
                link={(!hasInconsistencies || !reportInconsistency) && link}
                buttoncolor="primary"
                className="w-100"
                disabled={
                  hasInconsistencies ||
                  reportInconsistency ||
                  (driver?.status_cadastro === "5r" &&
                    driver?.processos_cadastro_concluido === false)
                }
              />
              {isSatValidation && driver?.status_cadastro !== "7r" && (
                <div className="d-flex flex-column gap-2">
                  <SubmitButton
                    labelButton="Salvar inconsistências"
                    disabled={!hasInconsistencies}
                  />
                  <ButtonLink
                    id="close-button"
                    fontSize="0.7rem"
                    link={
                      hasInconsistencies &&
                      (driver?.status_cadatro === "0r"
                        ? `/motoristas-listar/${driver?.id}`
                        : `/motoristas-listar/temporario/${driver?.id}`)
                    }
                    label="Cancelar"
                    disabled={!hasInconsistencies}
                  />
                </div>
              )}

              {!reportInconsistency &&
                driver?.status_cadastro === "7r" &&
                (!driver?.campos_erros_cadastro ||
                  driver?.campos_erros_cadastro === "{}") && (
                  <ButtonLink
                    label="Informar inconsistências"
                    handleClick={handleClick}
                    buttoncolor="secondary"
                    className="w-100"
                    fontSize="0.7rem"
                  />
                )}
              {reportInconsistency && driver?.status_cadastro === "7r" && (
                <Form.Group className="d-flex flex-column w-100 gap-2 mt-1 flex align-items-center justify-content-start">
                  <SubmitButton
                    icon={<FaSave color="white" />}
                    labelButton="Salvar inconsistências"
                    className="w-100"
                    disabled={allFalse}
                  />

                  <SubmitButton
                    labelButton="Cancelar"
                    onClick={handleCancelClick}
                    buttoncolor="secondary"
                    className="w-100 mb-1"
                  />
                </Form.Group>
              )}
            </div>
          </>
        )}

        {isInformation && driver && driver?.status_cadastro === "0r" && (
          <Card.Body>
            <h1>Análise Flex consulta</h1>
            <div className="mb-3">
              <h5>Total de cadastros em transportadora</h5>
              <span>{companies}</span>
            </div>
            <div className="mb-3">
              <h5>Total de viagens em transportadora</h5>
              <span className="mb-4">{travels}</span>
            </div>
            <div className="mb-3">
              <h5>Validação biometria</h5>
              <span
                className={`${
                  biometricValue > 70 ? "color-active" : "color-red"
                }`}
              >
                {`${biometricValue}%`}
              </span>
            </div>
            <div className="mb-3">
              <h5>Validação da imagem da CNH</h5>
              <span
                className={`${
                  validationValue > 70 ? "color-active" : "color-red"
                }`}
              >
                {`${validationValue}%`}
              </span>
            </div>
          </Card.Body>
        )}
      </CardBody>
    </BorderCard>
  );
};

export default ControlDriverCard;
