import FlexLogo from "../../../Assets/Images/flex-logo.png";
import { Image as ImageLogo } from "react-bootstrap";
import { Label } from "./styles";

const ValidationErrorMessage = ({ icon, label, text, hasError }) => {
  return (
    <div className="rounded gap-1 p-1 btn-light-gray h-100 d-flex flex-column">
      {hasError && (
        <div className="mb-2 mt-4 gap-1 d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center justify-content-center">
            <ImageLogo src={FlexLogo} width={35} height={35} />
            <Label className="text-start">Atenção!</Label>
          </div>
          <div className="text-center">
            <Label className="text-start">{text}</Label>
          </div>
        </div>
      )}
      <div
        className={`d-flex align-items-center justify-content-center gap-1 mt-3 mb-3 ${
          hasError ? "text-danger" : "text-secondary"
        }`}
      >
        <div className="d-flex align-items-center">{icon && icon}</div>
        <Label className="text-center">
          {label ? label : "Nenhum registro encontrado"}
        </Label>
      </div>
    </div>
  );
};

export default ValidationErrorMessage;
