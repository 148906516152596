import { useParams } from "react-router-dom";
import Dashboard from "../../../../../Shared/layout";
import { memo, useCallback, useEffect, useState } from "react";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { Col } from "react-bootstrap";
import TruckInformationsCard from "./TruckInformationsCard";
import { useVehicles } from "../../../../../hooks/VehicleProvider";
import ControlVehicleCard from "../components/ControlVehicleCard/ControlVehicleCard";
import ImageModal from "../../../../../Shared/Components/ImageModal/ImageModal";
import { useOwners } from "../../../../../hooks/OwnerProvider";
import { VehicleListRow } from "./styles";

const VehicleInformations = () => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalImage, setModalImage] = useState(null);

  const { id } = useParams();

  const { vehicle, isLoading, getVehicle } = useVehicles();
  const { owners, getOwners } = useOwners();

  useEffect(() => {
    getVehicle(id);
  }, [getVehicle, id]);

  useEffect(() => {
    getOwners();
  }, [getOwners]);

  const handleShowModal = useCallback((item, title) => {
    setShowImageModal(true);
    setModalTitle(title);
    setModalImage(item);
  }, []);

  return (
    <Dashboard title="Informações do veículo">
      {isLoading && <Loading />}
      {!isLoading && vehicle && (
        <VehicleListRow
          xs={1}
          md={2}
          xl={3}
          className="d-flex justify-content-between g-1 mt-1"
        >
          <Col md={4} xl={2}>
            <ControlVehicleCard vehicle={vehicle} />
          </Col>
          <Col md={8} xl={10}>
            <TruckInformationsCard
              vehicle={vehicle}
              handleShowModal={handleShowModal}
              owners={owners}
            />
          </Col>
        </VehicleListRow>
      )}
      <ImageModal
        show={showImageModal}
        handleClose={() => setShowImageModal(false)}
        image={modalImage}
        title={modalTitle}
      />
    </Dashboard>
  );
};

export default memo(VehicleInformations);
