import { Col, Row } from "react-bootstrap";
import { Border, Item } from "./styles";
import { BiIdCard } from "react-icons/bi";
import { BsArrowRepeat, BsCalendar3, BsTelephoneFill } from "react-icons/bs";
import moment from "moment";
import {
  formatPhoneNumber,
  isCpfOrCnpf,
} from "../../../../../Shared/Utils/data";
import { FaRegDotCircle } from "react-icons/fa";
import { useCallback } from "react";
import {
  agregadoTypes,
  taxationTypes,
} from "../../../../../Shared/Utils/constants";

const CarrierInformationSection = ({ owner }) => {
  const isCpf = isCpfOrCnpf(owner?.cpf_cnpj);

  const currentValue = useCallback(
    (value, constant) =>
      value ? constant.find((status) => status.value === value)?.label : "--",
    []
  );

  return (
    <Border className="p-3">
      <Row xs={1} xl={2}>
        <Col className="gap-3 h-100">
          {owner?.cpf_cnpj && isCpf && (
            <Item className="gap-1">
              <BiIdCard size={17} color="#093c5e" />
              <h3>CEI / PIS(INSS):</h3>
              <h4>{owner?.cei ?? "--"}</h4>
            </Item>
          )}
          <Item className="gap-1">
            <BsTelephoneFill size={14} color="#093c5e" />
            <h3>Celular</h3>
            <h4>
              {owner?.numero_celular
                ? formatPhoneNumber(owner?.numero_celular)
                : "--"}
            </h4>
          </Item>
          {owner?.tipo_tributacao && owner?.tipo_tributacao !== "" && (
            <Item className="gap-1">
              <FaRegDotCircle size={14} color="#093c5e" />
              <h3>Tipo de tributação:</h3>
              <h4>
                {owner?.tipo_tributacao
                  ? currentValue(owner?.tipo_tributacao, taxationTypes)
                  : "--"}
              </h4>
            </Item>
          )}
          {owner?.agregado && owner?.agregado !== "" && (
            <Item className="gap-1">
              <FaRegDotCircle size={14} color="#093c5e" />
              <h3>Agregado:</h3>
              <h4>
                {owner?.agregado
                  ? currentValue(owner?.agregado, agregadoTypes)
                  : "--"}
              </h4>
            </Item>
          )}
        </Col>

        <Col className="gap-3 h-100">
          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>RNTRC / ANTT:</h3>
            <h4>{owner?.rntrc ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BsCalendar3 size={14} color="#093c5e" />
            <h3>Data de validade RNTRC:</h3>
            <h4>
              {owner?.dt_validade_rntrc
                ? moment.utc(owner?.dt_validade_rntrc).format("DD/MM/YYYY")
                : "--"}
            </h4>
          </Item>

          <Item className="gap-1">
            <BsArrowRepeat size={19} color="#093c5e" />
            <h3>Status da RNTRC:</h3>
            <h4>{owner?.status_rntrc ?? "--"}</h4>
          </Item>
        </Col>
      </Row>
    </Border>
  );
};

export default CarrierInformationSection;
