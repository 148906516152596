import { useCallback, useMemo, useState } from "react";
import { get } from "lodash";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
import { Form, InputGroup, Ratio, Spinner } from "react-bootstrap";

import {
  AlertIcon,
  CoverImage,
  DeleteCnhButton,
  DeleteIconButton,
  ErrorMessage,
  FormControlInput,
  FormGroupBorder,
  ImageTitle,
  PdfLoadingContainer,
  ShowModalButton,
  UploadImageButton,
} from "./styles";

import EmptyImage from "../../../../../../../Assets/Images/EmptyImage.png";
import { pdfConverter } from "../../../../../../../Shared/Utils/pdfConverter";

const TruckUploadImage = ({
  position,
  className,
  width,
  height,
  paddingBottom,
  setValue,
  hideTitle,
  hideInput,
  handleShowModal,
  setCurrentImage,
  align = "start",
  defaultTruck,
  isEdit,
  index,
  register,
  errors,
  optional,
  OCRImage,
}) => {
  const [uploadImage, setUploadImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [imageModal, setImageModal] = useState(false);

  const [uploadLogoImage, setUploadLogoImage] = useState(
    defaultTruck?.foto_documento
  );

  const [isChange, setIsChange] = useState(false);

  const deleteImage = () => {
    setUploadImage(null);
    setUploadLogoImage(null);
    setIsChange(true);
  };

  const coverImage = useMemo(() => {
    if (OCRImage && !isChange) {
      return `url(${OCRImage?.src})`;
    }

    if (defaultTruck?.foto_documento?.url && !isChange) {
      setImageModal(defaultTruck?.foto_documento);
      return `url(${defaultTruck?.foto_documento?.url})`;
    }

    if (uploadLogoImage && isChange) {
      setIsChange(false);
      setImageModal(uploadLogoImage);
      return `url(${uploadLogoImage})`;
    }

    if (!defaultTruck?.foto_documento && !uploadLogoImage) {
      setIsChange(false);
      return `url(${EmptyImage})`;
    }

    if (isChange && !uploadLogoImage) {
      setIsChange(false);
      return `url(${EmptyImage})`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTruck?.foto_documento, uploadLogoImage, OCRImage]);

  const handleFileChange = useCallback(
    (event) => {
      setErrorMessage("");
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const arrayBuffer = e.target.result;

          const base64 = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );

          setUploadImage(file);

          if (file.type === "application/pdf") {
            setValue(
              `carreta${position}.foto_documento`,
              "data:" + file.type + ";base64," + base64
            );

            pdfConverter(file, setUploadLogoImage, setIsLoading);
          } else if (file.type === "image/jpeg" || file.type === "image/png") {
            setUploadLogoImage(URL.createObjectURL(file));

            setValue(
              `carreta${position}.foto_documento`,
              "data:" + file.type + ";base64," + base64
            );
          } else {
            setErrorMessage("Arquivo não suportado");
          }
        };

        setIsChange(true);

        reader.readAsArrayBuffer(file);
      }
    },
    [position, setValue]
  );

  return (
    <div className="d-flex h-100">
      <FormGroupBorder
        className={` h-100 ${className} ${
          uploadImage && "justify-content-start gap-3"
        }`}
      >
        {!hideTitle && (
          <ImageTitle className="text-start mb-1">CRLV carreta</ImageTitle>
        )}

        {isLoading ? (
          <PdfLoadingContainer width={width} height={height}>
            <Spinner animation="border" role="status" variant="light" />
          </PdfLoadingContainer>
        ) : (
          <>
            {coverImage === `url(${defaultTruck?.foto_documento?.url})` &&
            defaultTruck?.foto_documento?.type === "application/pdf" ? (
              <div
                style={{ width: 166, height: 166 }}
                data-testid="achando-essa-div"
              >
                <Ratio aspectRatio="1x1">
                  <embed
                    type="application/pdf"
                    src={defaultTruck?.foto_documento?.url}
                  />
                </Ratio>
              </div>
            ) : (
              <CoverImage
                width={width}
                height={height}
                paddingBottom={paddingBottom}
                coverImage={coverImage}
              />
            )}
          </>
        )}

        {handleShowModal && (
          <div className={`${align ? "text-start" : "text-end"}`}>
            <ShowModalButton
              onClick={() => handleShowModal(imageModal)}
              className="px-0"
            >
              <div className="d-flex align-items-center gap-1">
                <MdOutlineFilterCenterFocus />
                Visualizar documento veículo
              </div>
            </ShowModalButton>
          </div>
        )}

        {errorMessage === "Arquivo não suportado" && (
          <div className="d-flex align-items-center gap-1 mt-1">
            <AlertIcon size={10} />
            <ErrorMessage>Arquivo não suportado</ErrorMessage>
          </div>
        )}

        {(uploadImage || OCRImage) && (
          <div className="d-flex align-items-center mt-1">
            <DeleteCnhButton
              type="button"
              className="d-flex align-items-center p-0 gap-1"
              onClick={deleteImage}
            >
              <label className="mt-1">
                {OCRImage ? OCRImage?.name : uploadImage?.name}
              </label>
              {!OCRImage && <DeleteIconButton />}
            </DeleteCnhButton>
          </div>
        )}

        {(defaultTruck && !isEdit) ||
          (!uploadImage && !hideInput && !OCRImage && (
            <InputGroup className=" mt-1">
              <div>
                <UploadImageButton
                  className="rounded d-flex justify-content-center align-items-center"
                  width={width}
                >
                  <label
                    htmlFor={`image-truck-input-${index}`}
                    className="custom-file-upload"
                  >
                    Selecionar documento
                  </label>
                </UploadImageButton>
                {get(errors, `carreta${position}.foto_documento`) && (
                  <Form.Text className="text-danger">
                    {get(errors, `carreta${position}.foto_documento`)?.message}
                  </Form.Text>
                )}
              </div>
              <FormControlInput
                {...register(`carreta${position}.foto_documento`, {
                  required:
                    optional || defaultTruck ? false : "A foto é obrigatória",
                })}
                type="file"
                id={`image-truck-input-${index}`}
                name={`image-truck-input-${index}`}
                onChange={handleFileChange}
                className="d-none"
              />
            </InputGroup>
          ))}
      </FormGroupBorder>
    </div>
  );
};

export default TruckUploadImage;
