import styled from "styled-components";

export const StateTitle = styled.h1`
  font-size: 1rem;
  margin-bottom: 0;
`

export const TotalDrivers = styled.div`
  align-items: center;
  display: flex;
  background-color: var(--color-green);
  padding: 7px;
  border-radius: 3px;

  span {
    color: white;
    font-size: 0.7rem;
    line-height: 0.5;

  }
`

export const CardBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;

    @media (min-width: 576px){
      flex-direction: row !important;
      justify-content: space-between;
      
    }

    @media (min-width: 992px){
      flex-direction: column !important;
      gap: 1rem;
      align-items: center;
    }

    @media (min-width: 1400px){
      flex-direction: row !important;
      justify-content: space-between;
    }
`