import { Col } from "react-bootstrap";
import Dashboard from "../../../../../Shared/layout";
import LinkCard from "../../../../../Shared/Components/LinkCard";
import { AddDriverIcon, DriverListIcon, OngoingProcessesIcon } from "./styles";
import { DriverRow } from "../Driver/styles";

const list = [
  {
    route: "/motoristas-listar",
    title: "Listar motoristas",
    icon: <DriverListIcon />,
  },
  {
    route: "/motoristas-adicionar",
    title: "Adicionar motorista",
    icon: <AddDriverIcon />,
  },
  {
    route: "/motoristas/processos-em-curso",
    title: "Processos em curso",
    icon: <OngoingProcessesIcon />,
  },
];

const Drivers = () => {
  return (
    <Dashboard>
      <DriverRow
        xs={1}
        md={2}
        lg={4}
        xl={6}
        id="container-cards-conf-sistema"
        className="d-flex mt-4 g-2"
      >
        {list.map((item) => (
          <Col key={item.route}>
            <LinkCard route={item.route} title={item.title} icon={item.icon} />
          </Col>
        ))}
      </DriverRow>
    </Dashboard>
  );
};

export default Drivers;
