import { Table } from "react-bootstrap";
import {
  DeleteButton,
  EditButton,
  OccurrencesTypesThead,
  TableContainer,
  TableItem,
  TableTitle,
} from "./styles";

import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import "sweetalert2/dist/sweetalert2.css";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const OccurrencesTypesList = ({
  occurrencesTypes,
  onDelete,
  getOccurrence,
}) => {
  const navigate = useNavigate();

  const handleNavigateToEditOccurrenceType = useCallback(
    (id) => {
      getOccurrence(id);
      navigate(`/gestao-viagens/tipos-de-ocorrencias/editar/ocorrencia/${id}`);
    },
    [getOccurrence, navigate]
  );

  return (
    <TableContainer className="mt-2">
      <Table hover size="sm" className="border rounded mb-0">
        <OccurrencesTypesThead>
          <tr>
            <TableTitle className="ps-3">Nome</TableTitle>
            <TableTitle className="ps-3">Descrição</TableTitle>
            <TableTitle className="text-end pe-4">Ações</TableTitle>
          </tr>
        </OccurrencesTypesThead>

        <tbody>
          {occurrencesTypes.map((occurrenceType, index) => (
            <tr key={index}>
              <TableItem className="px-3">{occurrenceType?.nome}</TableItem>
              <TableItem className="px-3">
                {occurrenceType?.descricao}
              </TableItem>
              <TableItem className="px-3">
                <div className="d-flex justify-content-end gap-2">
                  <EditButton
                    onClick={() =>
                      handleNavigateToEditOccurrenceType(occurrenceType?.id)
                    }
                    className="p-1 d-flex align-items-center"
                    title="Editar tipo de ocorrência"
                  >
                    <FiEdit2 size={15} />
                  </EditButton>
                  <DeleteButton
                    className="p-1 d-flex aling-items-center  "
                    title="Deletar ocorrência"
                    onClick={() => onDelete(occurrenceType)}
                  >
                    <RiDeleteBinLine size={16} color="#b94a45" />
                  </DeleteButton>
                </div>
              </TableItem>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};
