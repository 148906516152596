import styled from "styled-components";

export const PaginationTabelButton = styled.button`
  color: ${props => !props.isActive && "#858585"};
  background-color: ${props => props.isActive && "#2185D0"};
  font-size: 0.6rem;
font-weight: bold;
`

export const ChangePageButton = styled.button`
  font-size:0.6rem;
`