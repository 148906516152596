import { Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const BorderCard = styled(Card)`

  h3 {
    font-size: 1rem;
  }

  h4 {
    font-size: 0.7rem;
    color: var(--color-gray-11);
  }

  h5 {
    font-size: 0.7rem;
    color: var(--color-gray-12);

    span {
      font-size: 0.7rem;
    }
  }

  h6 {
    font-size: 0.7rem;
    color: var(--color-gray-11);

    span {
      font-size: 0.7rem;
    }
  }
`;

export const Item = styled.div`
  padding: 0;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;

  span {
    color: var(--color-gray-11);
  }

  h4 {
    font-size: 1rem;
    display: flex;
    margin-left: 0.1rem;
    margin-bottom: 0;
    text-align: left;

    @media (max-width: 425px) {
      font-size: 0.65rem;
    }
  }

  h3 {
    font-size: 1rem;
    display: flex;
    margin-left: 0.3rem;
    margin-bottom: 0;
    color: var(--color-gray-11);
    text-align: left;

    @media (max-width: 425px) {
      font-size: 0.65rem;
    }
  }
`;

export const PageTitle = styled(Card.Title)`
  color: var(--color-blue-90);
  font-weight: normal;
  font-size: 1.1rem;
  margin-bottom: 0;
`;



export const Columns = styled(Col)`
@media (min-width: 768px) {
    font-size: 0.5rem;
  }
`

export const ValueItem = styled.h4`
  color: ${props => props.item && props.item === true ? "#6dd786" : "#ff0f0f"} !important;
`

export const FlexValidationRow = styled(Row)`
max-height: 40vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 425px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }

 @media(min-width: 1440px){
  max-height: 70vh;
  }

  @media(min-width: 1640px){
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  max-height: 80vh;
  }
  
`

export const ItalicH4 = styled.span`
  font-style:  italic;
`;