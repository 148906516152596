import { Card } from "react-bootstrap";
import { PageTitle } from "./styles";
import { OriginDestinationCard } from "./OriginDestinationCard";
import { OriginDestinationStatusCard } from "./OriginDestinationStatusCard";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { MdOutlineNavigation } from "react-icons/md";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import { TravelsListMap } from "../../../../Shared/Components/LeafletMaps/TravelsListMap";
import { RealTimeTravelMap } from "../../../../Shared/Components/LeafletMaps/RealTimeTravelMap";
import { useMemo } from "react";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import { FaRoute } from "react-icons/fa";

export const PathCard = ({
  loadOrder,
  originAddress,
  destinyAddress,
  loadOrderAddressLoading,
  loadOrderRoute,
  loadOrderRouteLoading,
  distanceTimePredictions,
}) => {
  const position = useMemo(() => {
    if (originAddress) {
      return [originAddress];
    }

    if (destinyAddress) {
      return [destinyAddress];
    }

    return [
      {
        lat: -15.8267,
        lng: -47.9218,
      },
    ];
  }, [destinyAddress, originAddress]);

  return (
    <Card
      className="w-100 d-flex flex-column px-1 py-3"
      style={{ minHeight: "100%" }}
    >
      <Card.Body>
        <PageTitle>Trajeto</PageTitle>
        {loadOrderAddressLoading && <Loading />}
        {!loadOrderAddressLoading && (
          <div className="mb-2">
            {!originAddress && !destinyAddress && (
              <EmptyTableMessage label="Trajeto indisponível" />
            )}
            {(!originAddress || !destinyAddress) &&
              position &&
              position.length > 0 && (
                <TravelsListMap height="30vh" positions={position} zoom={4} />
              )}
            {originAddress?.lat &&
              destinyAddress?.lat &&
              loadOrder.status !== "2" && (
                <RealTimeTravelMap
                  height="40vh"
                  origin={originAddress}
                  destiny={destinyAddress}
                  route={loadOrder?.rota_selecionada}
                />
              )}
            {loadOrder?.status !== "8" &&
              loadOrder?.status !== "2" &&
              loadOrder?.status !== "60" && (
                <div className="mt-4">
                  <ButtonLink
                    icon={<FaRoute />}
                    fontSize="0.7rem"
                    buttoncolor="success"
                    link={`/gestao-viagens/ordem-de-carregamento/escolher-rota/${loadOrder?.token}`}
                    label="Definir o trajeto"
                  />
                </div>
              )}
            {(loadOrder?.status === "8" ||
              loadOrder?.status === "2" ||
              loadOrder?.status === "60") && (
              <>
                <div className="mb-2 mt-2 ">
                  <OriginDestinationCard
                    loadOrder={loadOrder}
                    distanceTimePredictions={distanceTimePredictions}
                  />
                </div>
                {loadOrderRoute && loadOrderRoute?.length > 0 && (
                  <div className="mb-2">
                    <OriginDestinationStatusCard
                      loadOrder={loadOrder}
                      loadOrderRoute={loadOrderRoute}
                      loadOrderRouteLoading={loadOrderRouteLoading}
                    />
                  </div>
                )}
                {loadOrder.status !== "2" && (
                  <ButtonLink
                    icon={<MdOutlineNavigation />}
                    fontSize="0.7rem"
                    buttoncolor="success"
                    link={`/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/${loadOrder?.token}`}
                    label="Exibir a localização em tempo real"
                  />
                )}
                {loadOrder.status === "2" && (
                  <ButtonLink
                    icon={<MdOutlineNavigation />}
                    fontSize="0.7rem"
                    buttoncolor="success"
                    link={`/gestao-viagens/ordem-de-carregamento/log-viagem/${loadOrder?.token}`}
                    label="Exibir histórico de viagem"
                  />
                )}
                <div className="mt-2">
                  <ButtonLink
                    fontSize="0.7rem"
                    link={"/gestao-viagens/ordem-de-carregamento"}
                    label="Fechar"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
