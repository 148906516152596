import { BiIdCard } from "react-icons/bi";
import { MdInvertColors } from "react-icons/md";
import { FaCity } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import { useMemo } from "react";
import moment from "moment";
import { FiGitCommit, FiTruck } from "react-icons/fi";
import { BsArrowRepeat, BsCalendar3, BsPerson } from "react-icons/bs";
import { ContainerTitle, InfoTitle, Item, ItemBorderCard } from "./styles";
import UploadOwnerDocImage from "./UploadOwnerDocImage";
import {
  cnpjCpfFormat,
  formatNameString,
} from "../../../../../../../Shared/Utils/data";
import { ownerType } from "../../../../../../../Shared/Utils/constants";

const VehicleInformationsCard = ({
  image,
  vehicle,
  vehicleAttachment,
  documentImage,
  ownerTenant,
  handleShowModal,
  proofOfResidence,
  consult,
  index,
  ownerForm,
}) => {
  const documentExpirationDate = useMemo(() => {
    const value =
      vehicle?.dados_ocr?.data?.valor &&
      moment(vehicle?.dados_ocr?.data?.valor, "DD/MM/YYYY")
        .add(1, "years")
        .toDate();

    return value;
  }, [vehicle?.dados_ocr?.data?.valor]);

  const anttConsult =
    consult &&
    consult?.consulta_antt_veiculo &&
    JSON.parse(consult?.consulta_antt_veiculo);

  const isExpired = new Date(documentExpirationDate) < new Date();

  const ownerName = useMemo(() => {
    if (
      vehicleAttachment?.cpf_cnpj &&
      vehicleAttachment?.cpf_cnpj !== "" &&
      vehicleAttachment?.cpf_cnpj !== vehicle?.dados_ocr?.cpf_cnpj?.valor &&
      vehicleAttachment?.nome_proprietario &&
      vehicleAttachment?.nome_proprietario !== ""
    ) {
      return formatNameString(vehicleAttachment?.nome_proprietario);
    }

    if (
      vehicle?.dados_ocr?.nome?.valor &&
      vehicleAttachment?.cpf_cnpj === "" &&
      vehicleAttachment?.nome_proprietario === ""
    ) {
      return formatNameString(vehicle?.dados_ocr?.nome?.valor);
    } else {
      return "--";
    }
  }, [
    vehicleAttachment?.cpf_cnpj,
    vehicleAttachment?.nome_proprietario,
    vehicle?.dados_ocr?.cpf_cnpj?.valor,
    vehicle?.dados_ocr?.nome?.valor,
  ]);

  const cpfOwner = useMemo(() => {
    if (vehicleAttachment?.proprietario_arrendamento) {
      return vehicleAttachment?.proprietario_arrendamento;
    }

    if (vehicleAttachment?.cpf_cnpj && vehicleAttachment?.cpf_cnpj !== "") {
      return vehicleAttachment?.cpf_cnpj;
    }

    if (vehicle?.dados_ocr?.cpf_cnpj?.valor) {
      return vehicle?.dados_ocr?.cpf_cnpj?.valor;
    } else {
      return "--";
    }
  }, [
    vehicleAttachment?.cpf_cnpj,
    vehicleAttachment?.proprietario_arrendamento,
    vehicle?.dados_ocr?.cpf_cnpj?.valor,
  ]);

  return (
    <ItemBorderCard>
      <ContainerTitle className="mb-3 d-flex">
        <InfoTitle className="text-start">INFORMAÇÕES</InfoTitle>
      </ContainerTitle>
      <Row xs={1} xl={2} className="p-2 d-flex">
        <Col className="d-flex flex-column gap-3  h-100">
          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>Placa:</h3>
            <h4>{vehicle?.dados_ocr?.placa?.valor ?? "--"}</h4>
          </Item>
          {index && index === "0" && ownerForm && (
            <Item className="gap-1 align-items-start">
              <BiIdCard size={17} color="#093c5e" />
              <h3>Tipo de proprietário:</h3>

              <h4>
                {ownerForm && ownerForm?.tipoProprietario
                  ? ownerType(ownerForm?.tipoProprietario)
                  : "--"}
              </h4>
              {ownerForm?.proprietarioIgualMotorista === "s" && (
                <span
                  className={`${
                    !ownerForm?.cpfProprietarioIgualCpfVeiculo
                      ? "text-danger"
                      : "text-dark"
                  }`}
                >
                  (Motorista declarado como proprietário do veículo)
                </span>
              )}
            </Item>
          )}

          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>Renavam:</h3>
            <h4>{vehicle?.dados_ocr?.renavam?.valor ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>Chassi:</h3>
            <h4>{vehicle?.dados_ocr?.chassi?.valor ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <FiGitCommit size={15} color="#093c5e" />
            <h3>Quantidade de eixos:</h3>
            <h4>{vehicle?.dados_ocr?.eixos?.valor ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <FaCity size={15} color="#093c5e" />
            <h3>Estado de emplacamento:</h3>
            <h4>{vehicle?.dados_ocr?.estado_emplacamento?.valor ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <FaCity size={15} color="#093c5e" />
            <h3>Cidade de emplacamento:</h3>
            <h4>{vehicle?.dados_ocr?.cidade_emplacamento?.valor ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <MdInvertColors size={15} color="#093c5e" />
            <h3>Cor:</h3>
            <h4>{vehicle?.dados_ocr?.cor_predominante?.valor ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <FiTruck size={15} color="#093c5e" />
            <h3>Marca / Modelo:</h3>
            <h4>{vehicle?.dados_ocr?.marca_modelo_versao?.valor ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Ano de fabricação:</h3>
            <h4>{vehicle?.dados_ocr?.ano_fabricacao?.valor ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>RNTRC / ANTT:</h3>
            <h4>
              {anttConsult &&
              anttConsult?.data &&
              anttConsult?.data.length > 0 &&
              anttConsult?.data[0]?.rntrc
                ? anttConsult?.data[0]?.rntrc
                : "--"}
            </h4>
          </Item>

          <Item className="gap-1">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Validade ANTT:</h3>
            <h4>
              {anttConsult &&
              anttConsult?.data &&
              anttConsult?.data.length > 0 &&
              anttConsult?.data[0]?.validade_data
                ? anttConsult?.data[0]?.validade_data
                : "**"}
            </h4>
          </Item>

          <Item className="gap-1">
            <BsArrowRepeat size={15} color="#093c5e" />
            <h3>Status RNTRC:</h3>
            <h4>
              {anttConsult &&
              anttConsult?.data &&
              anttConsult?.data.length > 0 &&
              anttConsult?.data[0]?.situacao
                ? anttConsult?.data[0]?.situacao
                : "--"}
            </h4>
          </Item>
          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>Exercício da CRLV:</h3>
            <h4>{vehicle?.dados_ocr?.ano_exercicio?.valor ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Emissão do documento:</h3>
            <h4>{vehicle?.dados_ocr?.data?.valor ?? "--"}</h4>
          </Item>

          <Item className="gap-1">
            <BsCalendar3 size={15} color="#093c5e" />
            <h3>Vencimento do documento:</h3>
            <h4
              className={`fw-bold ${
                isExpired ? "text-danger" : "text-success"
              }`}
            >
              {documentExpirationDate
                ? moment.utc(documentExpirationDate).format("DD/MM/YYYY")
                : "--"}
            </h4>
          </Item>
          <Item className="gap-1">
            <BiIdCard size={17} color="#093c5e" />
            <h3>Tipo:</h3>
            <h4>
              {vehicle?.dados_ocr?.especie_tipo
                ? vehicle?.dados_ocr?.especie_tipo?.valor
                : "--"}
            </h4>
          </Item>

          <div>
            <Item>
              <h3 className="fw-bold text-dark">Proprietário arrendatário:</h3>
            </Item>
            {ownerName && ownerName !== "--" && (
              <Item className="gap-1">
                <BsPerson size={15} color="#093c5e" />
                <h3>Nome:</h3>
                <h4>{ownerName}</h4>
              </Item>
            )}

            <Item className="gap-1">
              <BiIdCard size={17} color="#093c5e" />
              <h3>CPF / CNPJ:</h3>
              <h4>
                {cpfOwner && cpfOwner !== "--" ? cnpjCpfFormat(cpfOwner) : "--"}
              </h4>
            </Item>
          </div>

          <div>
            <Item>
              <h3 className="fw-bold text-dark">Proprietário certificado:</h3>
            </Item>
            <Item className="gap-1 mb-4">
              <BsPerson size={15} color="#093c5e" />
              <h3>Nome:</h3>
              <h4>
                {vehicle?.dados_ocr?.nome?.valor
                  ? formatNameString(vehicle?.dados_ocr?.nome?.valor)
                  : "--"}
              </h4>
            </Item>
            <Item className="gap-1">
              <BiIdCard size={17} color="#093c5e" />
              <h3>CPF / CNPJ:</h3>
              <h4>
                {vehicle?.dados_ocr?.cpf_cnpj?.valor
                  ? cnpjCpfFormat(vehicle?.dados_ocr?.cpf_cnpj?.valor)
                  : "--"}
              </h4>
            </Item>
          </div>
        </Col>

        <Col>
          <Row xs={2} className="g-3">
            {image && (
              <Col className="d-flex">
                <UploadOwnerDocImage
                  image={image}
                  handleShowModal={() => handleShowModal(image, "Foto CNH")}
                  title="Foto CNH"
                />
              </Col>
            )}
            {proofOfResidence && (
              <Col className="d-flex">
                <UploadOwnerDocImage
                  title="Comprovante de residência"
                  image={proofOfResidence}
                  handleShowModal={() =>
                    handleShowModal(
                      proofOfResidence,
                      "Comprovante de residência"
                    )
                  }
                />
              </Col>
            )}
            {documentImage && (
              <Col className="d-flex">
                <UploadOwnerDocImage
                  title="Documento CRLV"
                  image={documentImage}
                  handleShowModal={() =>
                    handleShowModal(documentImage, "Documento")
                  }
                />
              </Col>
            )}
            {ownerTenant && (
              <Col className="d-flex">
                <UploadOwnerDocImage
                  title="Documento contrato arrendamento"
                  image={ownerTenant}
                  handleShowModal={() =>
                    handleShowModal(ownerTenant, "Documento certificado")
                  }
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </ItemBorderCard>
  );
};

export default VehicleInformationsCard;
