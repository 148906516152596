import styled from "styled-components";

export const ProcessesTableTitle = styled.tr`
th {
  font-size: 0.80rem;
  font-weight: bold;

  @media(min-width: 1440px){
  font-size: 0.875rem
}

@media(min-width: 2440px){
  font-size: 1rem
}
}
`

export const TableTitle = styled.th`
font-size: 0.65rem;
color: var(--color-gray-15);
font-weight: 700;

@media(min-width: 1440px){
  font-size: 0.875rem
}

@media(min-width: 2440px){
  font-size: 1rem
}
`

export const TableItem = styled.td`
font-size: 0.7rem;
color: var(--color-gray-12);

@media(min-width: 1440px){
  font-size: 0.875rem
}

@media(min-width: 2440px){
  font-size: 1rem
}
`

export const Item = styled.div`
display: flex;
align-items: center;

h3 {
  font-size: 0.875rem;
  color: var(--color-gray-11);
  margin-bottom: 0;
}

span {
  font-size: 0.875rem;
  font-weight: bold;
}
`

export const TravelHistoryTableContainer = styled.div`
  overflow-x: auto;
  flex: 1;

  @media(max-width: 612px){
  max-height: 40vh;
 }
`

export const TravelHistoryContainer = styled.div`

max-height: 40vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 425px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }

 @media(min-width: 1440px){
  overflow-x: auto;
  max-height: 70vh;
  }

  @media(min-width: 1640px){
  overflow-x: auto;
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  overflow-x: auto;
  max-height: 80vh;
  }
  

`