import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest from "../Shared/Utils/Request";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export const UnitContext = createContext({
  units: [],
  unitByIdCompany: [],
  unit: null,
  isLoading: false,
  isSubmitting: false,
  getUnits: () => {},
  getUnit: () => {},
  createNewUnit: () => null,
  deleteUnit: () => null,
  updateUnit: () => null,
  getUnitsByIdCompany: () => null,
  getUnitsCompany: () => null,
});

export const useUnits = () => {
  const context = useContext(UnitContext);

  if (!context) {
    throw new Error("useUnits must be within UnitProvider");
  }

  return context;
};

export const UnitProvider = ({ children }) => {
  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState();
  const [unitByIdCompany, setUnitByIdCompany] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getUnits = useCallback(async () => {
    try {
      setIsLoading(true);
      setIsSubmitting(true);
      const response = await ApiRequest({
        path: "unidades",
        method: "GET",
      });

      setUnits(response?.data);
    } catch (error) {
      setUnits([]);
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  }, []);

  const getUnitsByIdCompany = useCallback(async (id) => {
    if (id === null) {
      return setUnits([]);
    }
    try {
      setIsLoading(true);
      setIsSubmitting(true);
      const response = await ApiRequest({
        path: `empresas/${id}/unidades`,
        method: "GET",
      });

      setUnits(response?.data?.Unidades);
    } catch (error) {
      setUnits([]);
    } finally {
      setIsLoading(false);

      setIsSubmitting(false);
    }
  }, []);

  const getUnitsCompany = useCallback(async (id) => {
    try {
      setIsLoading(true);
      setIsSubmitting(true);

      const response = await ApiRequest({
        path: `empresas/${id}/unidades`,
        method: "GET",
      });

      setUnitByIdCompany(response?.data?.Unidades);
      return response?.data;
    } catch (error) {
      setUnitByIdCompany([]);
    } finally {
      setIsLoading(false);

      setIsSubmitting(false);
    }
  }, []);

  const getUnit = useCallback(async (id) => {
    try {
      setIsLoading(true);
      setIsSubmitting(true);

      const response = await ApiRequest({
        path: `unidades/${id}`,
        method: "GET",
      });

      setUnit(response?.data);
    } catch (error) {
      setUnits(null);
    } finally {
      setIsLoading(false);

      setIsSubmitting(false);
    }
  }, []);

  const updateUnit = useCallback(
    async (id, data, navigateTo) => {
      try {
        setIsSubmitting(true);

        await ApiRequest({
          path: `unidades/${id}`,
          method: "PUT",
          data,
        });

        if (navigateTo) {
          navigateTo("/unidades");
        }

        window.scrollTo(0, 0);

        const response = await getUnits();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Unidade atualizada com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Unidade não pode ser atualizada.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);
      } finally {
        setIsLoading(false);

        setIsSubmitting(false);
      }
    },
    [getUnits]
  );

  const deleteUnit = useCallback(
    async (id) => {
      try {
        setIsSubmitting(true);

        await ApiRequest({
          path: `unidades/${id}`,
          method: "DELETE",
        });

        const response = await getUnits();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Unidade excluída com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `${
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            "Essa Unidade não pode ser excluída!"
          }`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    },
    [getUnits]
  );

  const createNewUnit = useCallback(
    async (newUnitData, navigate) => {
      try {
        setIsSubmitting(true);

        await ApiRequest({
          path: "unidades",
          method: "POST",
          data: newUnitData,
        });

        navigate("/unidades");

        const response = await getUnits();

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Unidade criada com sucesso.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);

        return response?.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `${
            error?.response?.data?.error
              ? error?.response?.data?.error
              : "Erro ao cadastrar a Unidade"
          }`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        setIsSubmitting(false);
      } finally {
        setIsLoading(false);

        setIsSubmitting(false);
      }
    },
    [getUnits]
  );

  const providerValue = useMemo(
    () => ({
      units,
      unit,
      isLoading,
      isSubmitting,
      unitByIdCompany,
      getUnits,
      getUnit,
      createNewUnit,
      deleteUnit,
      updateUnit,
      getUnitsByIdCompany,
      getUnitsCompany,
    }),
    [
      units,
      unit,
      isLoading,
      isSubmitting,
      unitByIdCompany,
      getUnits,
      getUnit,
      createNewUnit,
      deleteUnit,
      updateUnit,
      getUnitsByIdCompany,
      getUnitsCompany,
    ]
  );

  return (
    <UnitContext.Provider value={providerValue}>
      {children}
    </UnitContext.Provider>
  );
};
