import { useEffect } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";

const TravelsListPopups = ({
  currentAddress,
  RealTimeLocationIcon,
  desvio_route,
}) => {
  const map = useMap();

  useEffect(() => {
    const marker = L.marker([currentAddress?.lat, currentAddress?.lng], {
      icon: RealTimeLocationIcon,
    }).addTo(map);

    if (desvio_route) {
      marker
        .bindPopup(
          "<div><span style='color: red;'>⚠️</span> Motorista fora de rota</div>"
        )
        .openPopup();
    }

    return () => {
      map.removeLayer(marker);
    };
  }, [RealTimeLocationIcon, map, currentAddress, desvio_route]);

  return null;
};

export default TravelsListPopups;
