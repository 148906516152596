// import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
// import { ChangePageButton, PaginationTabelButton } from "./styles";

// const TablePagination = ({ currentPage, totalPages, handlePageChange }) => {
//   const pageButtons = [];

//   const handlePreviousPage = () => {
//     if (currentPage > 1) {
//       handlePageChange(currentPage - 1);
//     }
//   };

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       handlePageChange(currentPage + 1);
//     }
//   };

//   for (let i = 1; i <= totalPages; i++) {
//     const isActive = i === currentPage;
//     pageButtons.push(
//       <li key={i} className={`page-item ${isActive ? " active" : ""}`}>
//         <PaginationTabelButton
//           data-testid="button-page-change"
//           className="page-link"
//           isActive={isActive}
//           onClick={() => handlePageChange(i)}
//           disabled={isActive}
//         >
//           {i}
//         </PaginationTabelButton>
//       </li>
//     );
//   }

//   return (
//     <nav data-testid="table-pagination-container">
//       <ul className="pagination mb-0">
//         <li className={`page-item ${currentPage === 1 ? " disabled" : ""}`}>
//           <ChangePageButton
//             className="page-link"
//             onClick={handlePreviousPage}
//             data-testid="previous-page-button"
//           >
//             <AiOutlineArrowLeft color="#858585" />
//           </ChangePageButton>
//         </li>
//         {pageButtons}
//         <li
//           className={`page-item ${
//             currentPage === totalPages ? " disabled" : ""
//           }`}
//         >
//           <ChangePageButton
//             className="page-link"
//             onClick={handleNextPage}
//             data-testid="next-page-button"
//           >
//             <AiOutlineArrowRight color="#858585" />
//           </ChangePageButton>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default TablePagination;

import React from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { ChangePageButton, PaginationTabelButton } from "./styles";

const TablePagination = ({ currentPage, totalPages, handlePageChange }) => {
  const pageButtons = [];

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  // Adicionando os botões de página anterior
  for (let i = Math.max(1, currentPage - 3); i < currentPage; i++) {
    pageButtons.push(
      <li key={i} className={`page-item`}>
        <PaginationTabelButton
          data-testid="button-page-change"
          className="page-link"
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationTabelButton>
      </li>
    );
  }

  // Adicionando o botão da página atual
  pageButtons.push(
    <li key={currentPage} className={`page-item active`}>
      <PaginationTabelButton
        data-testid="button-page-change"
        className="page-link"
        onClick={() => handlePageChange(currentPage)}
      >
        {currentPage}
      </PaginationTabelButton>
    </li>
  );

  // Adicionando os botões de página posterior
  for (
    let i = currentPage + 1;
    i <= Math.min(currentPage + 3, totalPages);
    i++
  ) {
    pageButtons.push(
      <li key={i} className={`page-item`}>
        <PaginationTabelButton
          data-testid="button-page-change"
          className="page-link"
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationTabelButton>
      </li>
    );
  }

  return (
    <nav data-testid="table-pagination-container">
      <ul className="pagination mb-0">
        <li className={`page-item ${currentPage === 1 ? " disabled" : ""}`}>
          <ChangePageButton
            className="page-link"
            onClick={handlePreviousPage}
            data-testid="previous-page-button"
          >
            <AiOutlineArrowLeft color="#858585" />
          </ChangePageButton>
        </li>
        {pageButtons}
        <li
          className={`page-item ${
            currentPage === totalPages ? " disabled" : ""
          }`}
        >
          <ChangePageButton
            className="page-link"
            onClick={handleNextPage}
            data-testid="next-page-button"
          >
            <AiOutlineArrowRight color="#858585" />
          </ChangePageButton>
        </li>
      </ul>
    </nav>
  );
};

export default TablePagination;
