import React, { memo, useCallback, useEffect, useState } from "react";

import Dashboard from "../../../../Shared/layout";
import {
  ListOfLoadingOrderCard,
  LocationIcon,
  LocationItem,
  RealTimeLocationCardContainer,
  StyledDivWithBorder,
} from "./styles";
import { useTravels } from "../../../../hooks/TravelProvider";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { BsPlus } from "react-icons/bs";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import { sortByDateDescending } from "../../../../Shared/Utils/data";
import { ArrowsClockwise } from "phosphor-react";

const ListOfLoadingOrderStages = () => {
  const [loadOrderRoutes, setLoadOrdersRoutes] = useState([]);
  const [stagesAmount, seStagesAmount] = useState(1000);
  const { token } = useParams();
  const {
    loadOrder,
    loadOrderRouteLoading,
    loadOrderRoute,
    getLoadOrderRoutes,
    getLoadOrder,
  } = useTravels();

  useEffect(() => {
    token && getLoadOrder(token);
  }, [getLoadOrder, token]);

  useEffect(() => {
    getLoadOrderRoutes(loadOrder?.id, stagesAmount);
  }, [getLoadOrderRoutes, loadOrder, stagesAmount]);

  const handleSeeMoreStages = useCallback(() => {
    seStagesAmount((state) => state + 100);
  }, []);

  useEffect(() => {
    if (loadOrderRoute) {
      const cityMap = {};

      sortByDateDescending(loadOrderRoute).forEach((item) => {
        const city = item.cidade;

        if (cityMap[city]) {
          if (new Date(item.data) < new Date(cityMap[city].min.data)) {
            cityMap[city].min = item;
          } else if (new Date(item.data) > new Date(cityMap[city].max.data)) {
            cityMap[city].max = item;
          }
        } else {
          cityMap[city] = {
            min: item,
            max: item,
            qtde_repeticoes: 1,
          };
        }
      });

      const resultArray = Object.values(cityMap)
        .map((city) => city.min)
        .concat(Object.values(cityMap).map((city) => city.max))
        .sort((a, b) => new Date(b.data) - new Date(a.data));

      setLoadOrdersRoutes(resultArray);
    }
  }, [loadOrderRoute]);

  const handleReloader = () => {
    getLoadOrderRoutes(loadOrder?.id, stagesAmount);
  };

  return (
    <Dashboard title="Registros das etapas do percurso">
      {loadOrderRouteLoading && <Loading />}
      {!loadOrderRouteLoading &&
        loadOrderRoute &&
        loadOrderRoute.length === 0 && (
          <EmptyTableMessage label="Percurso não informado" />
        )}
      {!loadOrderRouteLoading &&
        loadOrderRoute &&
        loadOrderRoute.length > 0 && (
          <ListOfLoadingOrderCard className="d-flex flex-column h-100">
            <div className="d-flex justify-content-end px-1 mb-3">
              <ButtonLink
                label="Atualizar"
                handleClick={handleReloader}
                fontSize="0.875rem"
                icon={<ArrowsClockwise size={14} />}
                color="white"
              />
            </div>
            <RealTimeLocationCardContainer className="h-100 d-flex flex-column gap-2">
              {sortByDateDescending(loadOrderRoutes).map(
                (lastLocation, index) => (
                  <>
                    {lastLocation?.cidade && lastLocation?.cidade !== "" && (
                      <div
                        key={index}
                        className="d-md-flex align-items-center justify-content-md-between mb-2 gap-1"
                      >
                        <div className="d-flex align-items-center gap-1">
                          <LocationIcon index={index} />
                          <LocationItem index={index}>
                            {moment(lastLocation?.data).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </LocationItem>
                          <LocationItem index={index}>
                            {lastLocation?.cidade
                              ? `${lastLocation?.cidade} (${lastLocation?.uf})`
                              : "--"}
                          </LocationItem>
                        </div>
                        {index === 0 && <StyledDivWithBorder />}
                        {index === 0 && (
                          <LocationItem index={index}>
                            Última etapa registrada
                          </LocationItem>
                        )}
                      </div>
                    )}
                  </>
                )
              )}
            </RealTimeLocationCardContainer>

            <div className="d-flex justify-content-end gap-2 mt-auto">
              {stagesAmount &&
                loadOrderRoute &&
                stagesAmount > 0 &&
                stagesAmount === loadOrderRoute.length && (
                  <ButtonLink
                    label="Carregar mais registros"
                    secondIcon={<BsPlus />}
                    fontSize="0.7rem"
                    handleClick={handleSeeMoreStages}
                    buttoncolor="primary"
                  />
                )}
              <ButtonLink
                label="Fechar"
                fontSize="0.7rem"
                link={`/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/${token}`}
              />
            </div>
          </ListOfLoadingOrderCard>
        )}
    </Dashboard>
  );
};

export default memo(ListOfLoadingOrderStages);
