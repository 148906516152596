import { useMemo, useState } from "react";

import { Border } from "../styles";
import ReportedInconsistencies from "../../../DriverInformations/ReportedInconsistencies";
import ImageModal from "../../../../../../../Shared/Components/ImageModal/ImageModal";
import AboutInformationSection from "../../../DriverInformations/AboutInformationSection";
import CNHValidationSection from "../../../DriverInformations/CNHValidationSection";
import PersonalInformationSection from "../../../DriverInformations/PersonalInformationSection";
import { DriverFederalDetails } from "../../../../../../../Shared/Components/DriverFederalDetails/DriverFederalDetails";

import { InfoTitle, SectionContainer, TitleContainer } from "./styles";

export const DriverInformationsTab = ({
  driver,
  isLoading,
  driverConsults,
}) => {
  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowModal = () => {
    setShowImageModal(true);
  };

  const driverConsult = useMemo(
    () =>
      driverConsults &&
      driverConsults.length > 0 &&
      driverConsults.find((driver) => driver?.posicao === "MOTORISTA"),
    [driverConsults]
  );

  return (
    <>
      <div className="p-2">
        <SectionContainer className="text-start p-0 mb-3">
          <TitleContainer className="mb-3 d-flex">
            <InfoTitle className="text-start">INFORMAÇÕES PESSOAIS</InfoTitle>
          </TitleContainer>
          <AboutInformationSection driver={driver} />
        </SectionContainer>
        <SectionContainer className="text-start p-0 mb-3">
          <TitleContainer className="mb-3 d-flex">
            <InfoTitle className="text-start">INFORMAÇÕES DA CNH</InfoTitle>
          </TitleContainer>
          <CNHValidationSection
            driver={driver}
            handleShowModal={handleShowModal}
          />
        </SectionContainer>
        <SectionContainer className="text-start p-0 mb-5">
          <TitleContainer className="mb-3 d-flex">
            <InfoTitle className="text-start">
              INFORMAÇÕES COMPLEMENTARES
            </InfoTitle>
          </TitleContainer>
          <PersonalInformationSection driver={driver} />
        </SectionContainer>
        {driver?.campos_erros_cadastro &&
          driver?.status_cadastro === "7r" &&
          driver?.campos_erros_cadastro !== "{}" && (
            <SectionContainer className="text-start p-0 mb-5">
              <TitleContainer className="mb-3 d-flex">
                <InfoTitle className="text-start">
                  INCONSISTÊNCIAS INFORMADAS
                </InfoTitle>
              </TitleContainer>
              <Border>
                <ReportedInconsistencies driver={driver} />
              </Border>
            </SectionContainer>
          )}
        {!isLoading && driverConsults && (
          <SectionContainer className="text-start p-0 mb-5">
            <TitleContainer className="mb-3 d-flex">
              <InfoTitle className="text-start">CONSULTAS</InfoTitle>
            </TitleContainer>

            <DriverFederalDetails consult={driverConsult} />
          </SectionContainer>
        )}
      </div>

      <ImageModal
        show={showImageModal}
        handleClose={() => setShowImageModal(false)}
        image={driver?.foto_cnh}
        title="CNH"
      />
    </>
  );
};
