import { memo, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { AddOwnerRow } from "./styles";

import OwnerTypes from "../components/OwnerForms/OwnersTypes";
import { CarrierRegistration } from "../components/OwnerForms/CarrierRegistration";
import OwnersPersonalInformations from "../components/OwnerForms/OwnersPersonalInformation";

import Dashboard from "../../../../../Shared/layout";
import { removerCaracteresNaoAlfabeticos } from "../../../../../Shared/Utils/data";
import ApiRequest from "../../../../../Shared/Utils/Request";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";

import { useOwners } from "../../../../../hooks/OwnerProvider";
import { PageTitle } from "../components/OwnerForms/PageTitle";

const AddOwner = () => {
  const [showForm, setShowForm] = useState(false);
  const [isCpf, setIsCpf] = useState(true);
  const [isSAT, setIsSat] = useState(false);
  const [ownerByCpfCnpj, setOwnerByCpfCnpj] = useState(null);

  const [isLoadingtOwnerByCpfCnpj, setIsLoadingtOwnerByCpfCnpj] =
    useState(false);

  const { isLoading, addOwner } = useOwners();

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm();

  const getOwnerByCpfCnpj = useCallback(
    async (cpfCnpj) => {
      try {
        setIsLoadingtOwnerByCpfCnpj(true);

        const response = await ApiRequest({
          path: `proprietarios/cpf_cnpj/${cpfCnpj}`,
          method: "GET",
        });

        if (response?.data?.cgccpf) {
          setValue("cpf_cnpj", cpfCnpj);

          setOwnerByCpfCnpj(response?.data);
        } else {
          setOwnerByCpfCnpj(null);
          reset();
          setValue("cpf_cnpj", cpfCnpj);
        }
      } catch (error) {
        setOwnerByCpfCnpj(null);
        reset();
        setValue("cpf_cnpj", cpfCnpj);
      } finally {
        setIsLoadingtOwnerByCpfCnpj(false);
      }
    },
    [reset, setValue]
  );

  const navigate = useNavigate();

  const onSubmit = (data) => {
    data.status = "9";

    data.numero_celular =
      data?.numero_celular && data?.numero_celular.replace(/[^0-9]/g, "");

    data.cep = data?.cep && data?.cep.replace(/\D+/g, "");
    data.cpf_cnpj = data?.cpf_cnpj && data?.cpf_cnpj.replace(/\D/g, "");
    data.bloqueado_adm = data?.bloqueado_adm === true ? "S" : "N";
    data.nome = data?.nome && removerCaracteresNaoAlfabeticos(data?.nome);

    // data.antt = "000";
    // data.dt_validade_antt = "000";
    data.status_rntrc = "NAOCONSTA";

    data.inscricao_estadual =
      data.inscricao_estadual === undefined
        ? "ISENTO"
        : data.inscricao_estadual;

    for (const key in data) {
      if (data[key] === "" || data[key] === undefined) {
        delete data[key];
      }
    }

    window.scrollTo(0, 0);

    addOwner(data, navigate);
  };
  return (
    <Dashboard>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="owner-add-form"
        className="mt-3"
      >
        <AddOwnerRow className="d-flex flex-column gap-2">
          <div className={`${showForm && "card text-center w-100 h-100"}`}>
            <Card.Body className="text-start d-flex flex-column gap-1">
              <PageTitle label="Informações cadastrais" />
              <OwnerTypes
                register={register}
                errors={errors}
                setShowForm={setShowForm}
                setIsCpf={setIsCpf}
              />
              {isLoadingtOwnerByCpfCnpj && <Loading />}
              {showForm && !isLoadingtOwnerByCpfCnpj && (
                <OwnersPersonalInformations
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  ownerByCpfCnpj={ownerByCpfCnpj}
                  getOwnerByCpfCnpj={getOwnerByCpfCnpj}
                  isCpf={isCpf}
                  trigger={trigger}
                  setIsSat={setIsSat}
                  isSAT={isSAT}
                />
              )}

              {showForm && (
                <CarrierRegistration
                  register={register}
                  setValue={setValue}
                  isSubmitting={isLoading}
                  ownerByCpfCnpj={ownerByCpfCnpj}
                  errors={errors}
                  isCpf={isCpf}
                  isSAT={isSAT}
                />
              )}
            </Card.Body>
          </div>
        </AddOwnerRow>
      </Form>
    </Dashboard>
  );
};

export default memo(AddOwner);
