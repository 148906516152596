import { useCallback, useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { FormLabelTitle } from "./styles";
import { get } from "lodash";
import { Controller } from "react-hook-form";
import { sortedNames } from "../../../../../../Shared/Utils/data";

const colourStyles = {
  option: (styles, { isDisabled, isSelected }) => {
    return {
      ...styles,
      fontSize: "0.8rem",

      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#2185D0"
            : "transparent"
          : undefined,
      },
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      fontSize: "0.8rem",
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontSize: "0.8rem",
    };
  },
};

const hasborder = {
  ...colourStyles,
  control: (styles) => {
    return {
      ...styles,
      borderLeft: "2px solid red",
    };
  },
};

const OwnerAssociationForm = ({
  owners,
  drivers,
  vehicle,
  errors,
  setValue,
  truck,
  optional,
  control,
  defaultTruck,
  isEdit,
}) => {
  useEffect(() => {
    if (truck && !optional) {
      setValue(`${truck}.proprietario_id`, defaultTruck?.proprietario_id);
      setValue(`${truck}.certificado`, defaultTruck?.certificado);
      setValue(
        `${truck}.proprietario_arrendatario_id`,
        defaultTruck?.proprietario_arrendatario_id
      );
    }

    if (vehicle) {
      setValue("proprietario_id", vehicle?.proprietario_id);
      setValue("certificado", vehicle?.certificado);
      setValue("motorista_id", vehicle?.motorista?.id || null);

      setValue(
        "proprietario_arrendatario_id",
        vehicle?.proprietario_arrendatario_id
      );
    }
  }, [
    defaultTruck?.certificado,
    defaultTruck?.proprietario_arrendatario_id,
    defaultTruck?.proprietario_id,
    optional,
    setValue,
    truck,
    vehicle,
  ]);

  const currentOwners = useMemo(
    () =>
      sortedNames(owners).map((owner) => ({
        value: owner?.id,
        label: owner?.nome,
      })),
    [owners]
  );

  const currentDrivers = sortedNames(drivers).map((driver) => ({
    value: driver?.id,
    label: driver?.nome,
  }));

  currentDrivers.unshift({ value: null, label: "Sem motorista" });

  const defaultOwner = useMemo(() => {
    if (defaultTruck) {
      return currentOwners.find(
        (currentOwner) => currentOwner.value === defaultTruck?.proprietario_id
      );
    }

    if (vehicle) {
      return currentOwners.find(
        (currentOwner) => currentOwner.value === vehicle?.proprietario_id
      );
    }
  }, [currentOwners, defaultTruck, vehicle]);

  const defaultArrendatarioOwner = useMemo(() => {
    if (defaultTruck) {
      const owner = defaultTruck?.proprietario_arrendatario_id
        ? currentOwners.find(
            (currentOwner) =>
              currentOwner.value === defaultTruck?.proprietario_arrendatario_id
          )
        : currentOwners.find(
            (currentOwner) =>
              currentOwner.value === defaultTruck?.proprietario_id
          );

      return owner;
    }

    if (vehicle) {
      const owner = vehicle?.proprietario_arrendatario_id
        ? currentOwners.find(
            (currentOwner) =>
              currentOwner.value === vehicle?.proprietario_arrendatario_id
          )
        : currentOwners.find(
            (currentOwner) => currentOwner.value === vehicle?.proprietario_id
          );

      return owner;
    }
  }, [currentOwners, defaultTruck, vehicle]);

  const defaultDriver = useMemo(() => {
    if (vehicle) {
      return currentDrivers.find(
        (currentDriver) => currentDriver.value === vehicle?.motorista_id
      );
    }
  }, [currentDrivers, vehicle]);

  const handleCertificateOwnerChange = useCallback(
    (event) => {
      if (truck && !optional) {
        setValue(`${truck}.certificado`, event.label);
        setValue(`${truck}.proprietario_id`, event.value);
      } else {
        setValue("certificado", event.label);
        setValue("proprietario_id", event.value);
      }
    },
    [optional, setValue, truck]
  );

  const handleArrendatarioOwnerChange = useCallback(
    (event) => {
      if (truck) {
        setValue(`${truck}.proprietario_arrendatario_id`, event.value);
      } else {
        setValue("proprietario_arrendatario_id", event.value);
      }
    },
    [setValue, truck]
  );

  const handleDriverChange = useCallback(
    (event) => {
      setValue("motorista_id", event.value);
    },
    [setValue]
  );

  return (
    <div>
      <Row className="g-2">
        {!truck && (
          <Form.Group as={Col} md={6} lg={4} controlId="formDriver">
            <FormLabelTitle className="d-flex align-items-center mb-0">
              Motorista
            </FormLabelTitle>
            <Select
              placeholder="Selecione o motorista"
              onChange={handleDriverChange}
              size="sm"
              isDisabled={(defaultTruck || vehicle) && !isEdit}
              options={currentDrivers}
              styles={colourStyles}
              defaultValue={defaultDriver}
            />
          </Form.Group>
        )}

        <Form.Group as={Col} controlId="formOwner" md={6} lg={4}>
          <FormLabelTitle className="d-flex align-items-center mb-0">
            Proprietário certificado
          </FormLabelTitle>
          <Controller
            name={truck ? `${truck}.proprietario_id` : "proprietario_id"}
            control={control}
            rules={{ required: "O proprietário certificado é obrigatório" }}
            render={() => (
              <Select
                placeholder="Selecione o proprietário certificado"
                options={currentOwners}
                isDisabled={(defaultTruck || vehicle) && !isEdit}
                styles={hasborder}
                onChange={handleCertificateOwnerChange}
                defaultValue={defaultOwner}
              />
            )}
          />
          {get(errors, truck ? `${truck}.certificado` : "certificado") && (
            <Form.Text className="text-danger">
              {
                get(errors, truck ? `${truck}.certificado` : "certificado")
                  ?.message
              }
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group
          as={Col}
          md={6}
          lg={4}
          controlId="formProprietarioArrendatario"
        >
          <FormLabelTitle className="d-flex align-items-center mb-0">
            Proprietário arrendatário
          </FormLabelTitle>
          <Controller
            name={
              truck
                ? `${truck}.proprietario_arrendatario_id`
                : "proprietario_arrendatario_id"
            }
            control={control}
            rules={{
              required: "O proprietário arrendatário é obrigatório",
            }}
            render={() => (
              <Select
                isDisabled={(defaultTruck || vehicle) && !isEdit}
                placeholder="Selecione o proprietário arrendatário"
                options={currentOwners}
                styles={hasborder}
                onChange={handleArrendatarioOwnerChange}
                defaultValue={defaultArrendatarioOwner}
              />
            )}
          />
          {get(
            errors,
            truck
              ? `${truck}.proprietario_arrendatario_id`
              : "proprietario_arrendatario_id"
          ) && (
            <Form.Text className="text-danger">
              {
                get(
                  errors,
                  truck
                    ? `${truck}.proprietario_arrendatario_id`
                    : "proprietario_arrendatario_id"
                )?.message
              }
            </Form.Text>
          )}
        </Form.Group>
      </Row>
    </div>
  );
};

export default OwnerAssociationForm;
