import { useState } from "react";
import DriverInformationTab from "./DriverInformationTab";
import { DriverEditTab } from "./DriverEditTab";

export const DriverTab = ({
  driver,
  isLoading,
  tempRegister,
  driverConsults,
  isloadingConsult,
  judicialProcess,
}) => {
  const [isEditingDriver, setIsEditingDriver] = useState(false);

  const handleUpdateDriver = () => {
    setIsEditingDriver(!isEditingDriver);
  };

  return (
    <>
      {isEditingDriver ? (
        <DriverEditTab
          tempRegister={tempRegister}
          permanetDriver={driver}
          handleUpdateDriver={handleUpdateDriver}
          setIsEditingDriver={setIsEditingDriver}
        />
      ) : (
        <DriverInformationTab
          driver={driver}
          isLoading={isLoading}
          tempRegister={tempRegister}
          driverConsults={driverConsults}
          isloadingConsult={isloadingConsult}
          handleUpdateDriver={handleUpdateDriver}
          judicialProcess={judicialProcess}
        />
      )}
    </>
  );
};
