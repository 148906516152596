import { FaCheckCircle } from "react-icons/fa";
import { BorderCard } from "./styles";

export const SuccessCard = ({ label }) => {
  return (
    <div className="d-flex justify-content-center">
      <BorderCard>
        <div className="d-flex flex-column gap-3 align-items-center justify-content-center text-center">
          <FaCheckCircle color="#07b141" size={42} />
          <span>{label}</span>
        </div>
      </BorderCard>
    </div>
  );
};
