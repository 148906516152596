let PDFJS;

(async function () {
  PDFJS = await import("pdfjs-dist/build/pdf");
  const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.entry");
  PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
})();

export const pdfConverter = (file, setImage, setIsLoading) => {
  try {
    setIsLoading(true);
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const pdfData = new Uint8Array(e.target.result);
      const pdf = await PDFJS.getDocument(pdfData).promise;

      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 5 });
      const canvas = document.createElement("canvas");
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const canvasContext = canvas.getContext("2d");
      const renderContext = {
        canvasContext,
        viewport,
      };
      await page.render(renderContext).promise;

      const imageBase64 = canvas.toDataURL("image/png", 1);
      setImage(imageBase64);
      setIsLoading(false);
    };

    fileReader.readAsArrayBuffer(file);
  } catch (error) {
    setIsLoading(false);
    console.error("Error converting PDF to image:", error);
  }
};