export const getPayload = () => {
  const local = localStorage.getItem("AUTH-REQUEST-PAYLOAD-APPROD");

  const payload = local && JSON.parse(local);
  if (!payload || payload === "") return {};
  return payload;
};

export const isAuthenticated = () => {
  const expDate = new Date(getPayload().exp).valueOf();

  return getPayload() !== null && expDate * 10000 > new Date().getTime();
};

export const getToken = () => {
  const token = localStorage.getItem("AUTH-REQUEST-TOKEN-APPROD");

  try {
    return token ? JSON.parse(token) : null;
  } catch (error) {
    console.error("Erro ao analisar o token:", error);
    return null;
  }
};

export const onlogout = () => {
  return new Promise((response) => {
    localStorage.removeItem("AUTH-REQUEST-PAYLOAD-APPROD");
    localStorage.removeItem("AUTH-REQUEST-TOKEN-APPROD");
    response();
  });
};

export const onlogin = (value) => {
  return new Promise((response) => {
    localStorage.setItem("AUTH-REQUEST-PAYLOAD-APPROD", JSON.stringify(value));

    localStorage.setItem(
      "AUTH-REQUEST-TOKEN-APPROD",
      JSON.stringify(value.token_acesso)
    );

    response();
  });
};
