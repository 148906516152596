import styled from "styled-components";

export const TabContainer = styled.div`
  overflow-x: auto;
  max-height: 20vh;
  padding: 10px;

  @media(min-width: 425px){
    max-height: 35vh;
  }

  @media(min-width: 576px){
    max-height: 40vh;
  }
  @media(min-width: 1440px){
    max-height: 57vh;
  }
  

  @media(min-width: 1800px){
    max-height: 70vh;
  }

  
`