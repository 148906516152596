import React, { memo, useEffect, useState } from "react";
import Dashboard from "../../../../Shared/layout";
import { usePerfis } from "../../../../hooks/PerfilProvider";
import { usePermissions } from "../../../../hooks/PermitionProvider";
import PermissionList from "./PermissionList";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import { Col, Row } from "react-bootstrap";
import AccessSettingsList from "./AccessSettingsList";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const AccessSettings = () => {
  const navigate = useNavigate();

  useEffect(() => {
    !(
      localStorage.getItem("accessManagementPermissionToRead") &&
      localStorage.getItem("accessManagementPermissionToRead") !== "false"
    ) && navigate("/inicio");
  });

  const { perfis, getPerfis } = usePerfis();
  const [currentScope, setCurrentScope] = useState("");

  const [currentPerfilId, setCurrentPerfilId] = useState(
    localStorage.getItem("currentPerfilId") || ""
  );

  const {
    isLoading,
    loadingPostPermition,
    perfilActions,
    permissionsByScope,
    loadingPerfilActions,
    loadingPermissionsByScope,
    getPermissionsByScope,
    getPerfilActions,
    postPermissions,
  } = usePermissions();

  useEffect(() => {
    getPerfis();
  }, [getPerfis, permissionsByScope]);

  useEffect(() => {
    if (currentScope !== "") {
      getPermissionsByScope(currentScope);
    }
  }, [currentScope, getPermissionsByScope]);

  useEffect(() => {
    if (
      currentPerfilId &&
      localStorage.getItem("perfilManagementPermissionToRead") &&
      localStorage.getItem("perfilManagementPermissionToRead") !== "false"
    ) {
      getPerfilActions(parseInt(currentPerfilId));
      localStorage.setItem("currentPerfilId", currentPerfilId);
    }
  }, [currentPerfilId, getPerfilActions, permissionsByScope]);

  return (
    <Dashboard title="Configurações de acesso">
      <Row
        xs={1}
        lg={2}
        className="d-flex align-items-center justify-content-between mb-4 gap-1 mt-4"
      >
        <Col lg={5} md={6}>
          <PermissionList
            perfis={perfis}
            setCurrentScope={setCurrentScope}
            setCurrentPerfilId={setCurrentPerfilId}
            isDisabled={
              !localStorage.getItem("perfilManagementPermissionToRead") ||
              localStorage.getItem("perfilManagementPermissionToRead") ===
                "false"
            }
          />
        </Col>
      </Row>
      {currentScope === "USUARIOS_COMUNS" && (
        <EmptyTableMessage label="Escopo não possui permissões de acesso" />
      )}
      {!isLoading &&
        !loadingPostPermition &&
        !currentScope &&
        !loadingPerfilActions &&
        !loadingPermissionsByScope && (
          <EmptyTableMessage
            label="Nenhum perfil selecionado"
            icon={<AiOutlineFolderOpen />}
          />
        )}
      {(isLoading ||
        loadingPostPermition ||
        loadingPerfilActions ||
        loadingPermissionsByScope) && <Loading />}
      <>
        {!isLoading &&
          !loadingPostPermition &&
          !loadingPerfilActions &&
          !loadingPermissionsByScope &&
          currentScope &&
          currentPerfilId && (
            <AccessSettingsList
              permissionsByScope={permissionsByScope}
              currentPerfilId={currentPerfilId}
              perfilActions={perfilActions}
              getPerfilActions={getPerfilActions}
              getPermissionsByScope={getPermissionsByScope}
              currentScope={currentScope}
              postPermissions={postPermissions}
            />
          )}
      </>
    </Dashboard>
  );
};

export default memo(AccessSettings);
