import { Col } from "react-bootstrap";
import { CardButton, ItemCard, StatusOCLabel } from "./styles";

export const LoadOrderManagementItem = ({
  amount,
  item,
  handleActiveKey,
  tab,
}) => {
  const handleClick = () => {
    handleActiveKey(tab);
  };

  return (
    <Col className="text-center d-flex gap-1 mb-3 h-100">
      <ItemCard className="h-100 w-100 align-self-stretch">
        <h1 className="text-white">{amount}</h1>
        <CardButton
          className="d-flex gap-1 align-items-center justify-content-center stretched-link mb-2"
          onClick={handleClick}
        >
          <StatusOCLabel status={item}>{item}</StatusOCLabel>
        </CardButton>
      </ItemCard>
    </Col>
  );
};
