import { Card, Row } from "react-bootstrap";
import styled from "styled-components";

export const InfoBorderCard = styled(Card)`
  border: dashed 0.6px var(--color-gray-11);
`;



export const InfoItem = styled.span`
  color: ${(props) =>
    props.status === "placa"
      ? "#0071c7"
      : (props.status === "ATIVA" || props.status === "Ativa" || props.status === "REGULAR" || props.status === "Regular")
        ? "#198754"
        : props.status === "Requer atenção"
          ? "#C3A300" : props.status === "Pré-cadastro" ? "#b0b0b0"
            : "#093c5e"};
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 0;

  text-transform: ${(props) =>
    props.status === "ATIVO" ? "capitalize" : "initial"};

`;


export const InfomationItem = styled.span`
  color: ${(props) =>
    props.status === "placa"
      ? "#0071c7"
      : props.status === "ATIVO"
        ? "#198754"
        : props.status === "Requer atenção"
          ? "#C3A300" : props.status === "BLOQUEADO" ? "red"
            : "#093c5e"};
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 0;  
`;



export const TravelsHistoryRow = styled(Row)`
  overflow-x: hidden;
  max-height: 95vh;
`;


export const TravelsHistoryCardBody = styled(Card.Body)`

  border: dashed 0.6px var(--color-gray-11);

  &:hover {
    z-index: 1;
    box-shadow: 0 0 5px var(--color-gray);
  }

  border-bottom: 1px solid var(--color-gray-10);

  a {
    text-decoration: none;
  }
`;

export const Label = styled.h1`
  font-size: 1rem;
  color: var(--color-gray-15);
  font-weight: 700;
`;

export const BorderCard = styled(Card)`
 background-color: #FFFAFA;
  border: none;
`

export const TitleContainer = styled.div`
  background-color: var(--color-gray-2);
  padding: 10px;
`

export const InfoTitle = styled.span`
  color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;