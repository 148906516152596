export function convertDate(date) {
  const prevDate = date.split("-");

  const newDate = new Date(prevDate[0], prevDate[1] - 1, prevDate[2]);

  const day = String(newDate.getDate()).padStart(2, "0");
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const year = newDate.getFullYear();

  return `${day}/${month}/${year}`;
}
