import { Button, Card, Form, FormControl } from "react-bootstrap";
import styled from "styled-components";
import { FaTrashAlt } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";

export const BorderCard = styled(Card)`
  /* transition: transform 300ms; */
  &:hover {
    /* transform: scale(1.05); */
    z-index: 1;
    box-shadow: 0 0 5px var(--color-gray);
  }

  h3 {
    font-size: 1rem;
    white-space: nowrap
  }

  h4 {
    font-size: 0.7rem;
    color: #aaaaaa;
  }

  h5 {
    font-size: 0.7rem;
    color: #5f5f5f;

    span {
      font-size: 0.7rem;
    }
  }

  h6 {
    font-size: 0.7rem;
    color: #aeaeae;

    span {
      font-size: 0.7rem;
    }
  }
`;

export const CardBody = styled(Card.Body)`
  border-bottom: 1px solid var(--color-gray-10);
`;

export const CoverImage = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 1000px;
`;
export const FormControlInput = styled(FormControl)`
  font-size: 0.7rem;
  display: flex;
`;

export const FormLabelText = styled(Form.Label)`
  color: var(--color-gray-12);
  font-size: 0.9rem;
`;

export const PageTitle = styled(Card.Title)`
  width: auto;
  color: var(--color-gray-12);
  font-weight: normal;
  font-size: 1.2rem;
  margin-bottom: 0;  
`;

export const DeleteIconButton = styled(FaTrashAlt)`
  color: var(--color-gray-15);
  font-size: 0.9rem;
  margin-top: 4px;
`;

export const DeleteCnhButton = styled(Button)`
  transition: transform 300ms;
  background-color: transparent;
  border: none;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
    background-color: transparent;
    border: none;
  }

  label {
    color: var(--color-gray-15);
    font-size: 0.9rem;
  }
`;

export const UploadImageButton = styled.div`
  background-color: #eaeced;
  width: 7rem;
  padding: 3px;
  border-left: 1px solid red;

  font-size: 0.8rem;
  transition: transform 300ms;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    color: var(--color-gray-15);
  }
`;

export const ErrorMessage = styled.span`
  font-size: 0.7rem;
  color: red;
`;

export const AlertIcon = styled(FiAlertTriangle)`
  color: red;
`;
export const ShowModalButton = styled(Button)`
  border: none;
  background-color: transparent;
  color: var(--color-gray-13);
  font-size: 0.8rem;
  transition: transform 300ms;

  &:hover {
    border: none;
    background-color: transparent;
    color: var(--color-gray-13);
    transform: scale(1.05);
    z-index: 1;
  }
`;

export const ImageTitle = styled.p`
  font-size: 0.8rem;
  color: var(--color-gray-11);
`;

export const TrucksInformationsCard = styled.div`
  @media (min-width: 1200px) {
    max-height: 70vh;
    overflow-y: auto;
  }
`;

export const CardBodyContainer = styled(Card.Body)`
  @media (min-width: 1200px) {
    max-height: 60vh;
    overflow-y: auto;
  }
`;

export const CardContainer = styled(Card)`
  @media (min-width: 1200px) {
    max-height: 70vh;
    overflow-y: auto;
  }
`;

export const FormLabelTitle = styled(Form.Label)`
  font-size: 0.7rem;
  color: var(--color-gray-11);
`;

export const Item = styled.div`
  padding: 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  h4 {
    font-size: 1rem;
    color: black;
    display: flex;
    margin-left: 0.2rem;
    margin-bottom: 0;
    text-align: left;
    white-space: ${({ title }) => title && "nowrap"};

    @media (max-width: 425px) {
      font-size: 0.6rem;
    }
  }

  h3 {
    font-size: 1rem;
    display: flex;
    margin-bottom: 0;
    color:  #093c5e;
    text-align: left;

    white-space: ${({ titlestatus }) => titlestatus && "nowrap"};

    @media (max-width: 425px) {
      font-size: 0.6rem;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  align-items: center;

  > button {
    width: auto;
    margin: auto;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > button {
      margin: 0;
    }
  }
`;

export const Border = styled.div`
border-top: 1px solid var(--color-gray-10);
padding-top: 15px;
`

export const ItemBorderCard = styled(Card)`
    background-color: #FFFAFA;
  border: none;
`



export const InfoTitle = styled.span`
  color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;

export const Container = styled.div`
background-color: var(--color-gray-2);
  padding: 10px;
`
