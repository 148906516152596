import { useState } from "react";
import {
  BlockReasonForm,
  FormLabelText,
  PageTitle,
  TitleLabel,
} from "./styles";
import { Col, Form } from "react-bootstrap";

const ActiveForm = ({ driver, register, errors, setValue }) => {
  const [ativo, setAtivo] = useState(driver?.ativo || false);

  const [admBlock, setAdmBlock] = useState(
    driver?.bloqueado_adm === "S" ? true : false
  );

  const handleAdmBlockChange = () => {
    if (admBlock === false) {
      setValue("ativo", false);
      setAtivo(false);
    }

    if (admBlock === true) {
      setValue("motivo_bloqueio", "");
    }

    setAdmBlock(!admBlock);
  };

  const handleActiveChange = () => {
    if (ativo === false) {
      setValue("bloqueado_adm", false);
      setValue("motivo_bloqueio", "");
      setAdmBlock(false);
    }

    setAtivo(!ativo);
  };

  return (
    <>
      <PageTitle className="mb-4">Status do motorista</PageTitle>
      <Form.Group
        as={Col}
        md={6}
        className="mt-4 d-flex align-items-center gap-2"
        controlId="formAtivo"
      >
        <FormLabelText className="mb-0">Motorista liberado</FormLabelText>
        <Form.Check
          {...register("ativo")}
          type="switch"
          id="custom-switch"
          data-testid="driver-active-input"
          disabled={
            driver?.status_cadastro === "2r" || driver?.status_cadastro === "7r"
          }
          checked={ativo}
          onChange={handleActiveChange}
        />
      </Form.Group>

      <Form.Group
        as={Col}
        md={6}
        className="mt-4 d-flex align-items-center gap-2"
        controlId="formAtivo"
      >
        <FormLabelText className="mb-0">Bloqueio ADM</FormLabelText>
        <Form.Check
          {...register("bloqueado_adm")}
          type="switch"
          data-testid="driver-admin-block-input"
          id="custom-switch"
          disabled={
            driver?.status_cadastro === "2r" || driver?.status_cadastro === "7r"
          }
          checked={admBlock}
          onChange={handleAdmBlockChange}
        />
      </Form.Group>

      {admBlock && (
        <>
          <TitleLabel className="w-100 mt-3">
            Informe o motivo do bloqueio
          </TitleLabel>
          <BlockReasonForm
            className="is-required w-100"
            data-testid="block-reason-form"
            label="Informe o motivo do bloqueio ADM"
            as="textarea"
            defaultValue={driver?.motivo_bloqueio}
            {...register("motivo_bloqueio", { required: !ativo && true })}
          />
          {errors?.motivo_bloqueio?.type === "required" && (
            <Form.Text className="text-danger">
              Informe um motivo para o bloqueio administrativo
            </Form.Text>
          )}
        </>
      )}
    </>
  );
};

export default ActiveForm;
