import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CiLock, CiUnlock } from "react-icons/ci";

import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Container,
  Row,
  Col,
  Form,
  Image,
  FloatingLabel,
} from "react-bootstrap";

import "./index.css";

import {
  ContainerForm,
  PasswordButton,
  PasswordFormControl,
  PasswordInputContainer,
} from "./styles";

import Logo from "../../Assets/Images/Logo.png";
import { useLogins } from "../../hooks/LoginProvider";
import { SubmitButton } from "../../Shared/Components/SubmitButton/SubmitButton";
import { Loading } from "../../Shared/Components/Loading/Loading";
import Config from "../../Shared/Utils/config";
import LoginBreadcrumb from "../../Shared/Components/BreadCrumb/LoginBreadcrumb";

const fields = {
  email: {
    required: true,
    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  },
};

export default function Login() {
  const [isVerified, setIsVerified] = useState(false);
  const [captcha, setCaptcha] = useState();
  const [labelEmail, setLabelEmail] = useState("Digite seu usuário ou e-mail");
  const [labelPwd, setLabelPwd] = useState("Digite a sua senha");

  const [validateCaptcha, setValidateCaptcha] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");

  const { isSubmitting, isLoading, postLogin } = useLogins();

  async function clearCache() {
    const flexVersion = localStorage.getItem("FlexVersion");
    const currentVersion = process.env.REACT_APP_VERSION_APP;

    if (flexVersion && flexVersion !== currentVersion) {
      if ("caches" in window) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
      }

      localStorage.setItem("FlexVersion", currentVersion);

      window.location.reload(true);
      window.history.forward(1);
    }
  }

  useEffect(() => {
    clearCache();
    const intervalId = setInterval(clearCache, 36000);
    navigate("/");

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const handleRecaptchaChange = useCallback((value) => {
    setCaptcha(value);
    setIsVerified(true);
    setValidateCaptcha(true);
  }, []);

  const onSubmit = (data) => {
    if (isVerified) {
      data.captcha = captcha;
      postLogin(data, navigate);
    } else {
      setValidateCaptcha(false);

      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: "Por favor, marque o campo de não sou robô!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });

      navigate("/");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setInputType(showPassword ? "password" : "text");
  };

  return (
    <>
      {isLoading && <Loading />}
      <Container fluid>
        {!isLoading && (
          <Row className="h-100">
            <Col
              md={7}
              className="p-1 pb-0 p-md-5 h-100"
              style={{ overflow: "hidden" }}
            >
              <ContainerForm
                onSubmit={handleSubmit(onSubmit)}
                className="mx-auto py-5"
              >
                <Image
                  id="logo-login"
                  src={Logo}
                  className="mb-5"
                  width="190rem"
                />
                <LoginBreadcrumb title="Login" />

                <Form.Group className="mb-3" controlId="formEmail">
                  <FloatingLabel controlId="formEmail" label={labelEmail}>
                    <Form.Control
                      type="text"
                      className="input-user is-required"
                      {...register("email", fields.email)}
                      onFocus={() => setLabelEmail("Usuário/E-mail")}
                      onBlur={(e) => {
                        if (e.target.value === "")
                          setLabelEmail("Digite seu usuário ou e-mail");
                      }}
                    />
                    {errors?.email?.type === "required" && (
                      <Form.Text className="text-danger">
                        O e-mail é obrigatório.
                      </Form.Text>
                    )}
                    {errors?.email?.type === "pattern" && (
                      <Form.Text className="text-danger">
                        O formato do e-mail está incorreto!
                      </Form.Text>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-2" controlId="formPwd">
                  <PasswordInputContainer
                    controlId="formPwd"
                    label={labelPwd}
                    className="d-flex"
                    placeholder="Digite a sua senha"
                    onFocus={() => setLabelPwd("Senha")}
                    onBlur={(e) => {
                      if (e.target.value === "")
                        setLabelPwd("Digite sua senha");
                    }}
                  >
                    <PasswordFormControl
                      type={inputType}
                      {...register("senha", {
                        required: "A senha é obrigatória",
                      })}
                    />
                    <PasswordButton
                      onClick={togglePasswordVisibility}
                      title={`${
                        showPassword ? "Esconder senha" : "Mostrar senha"
                      }`}
                    >
                      {showPassword ? <CiUnlock /> : <CiLock />}
                    </PasswordButton>
                  </PasswordInputContainer>
                  {errors?.senha && (
                    <Form.Text className="text-danger">
                      {errors?.senha?.message}
                    </Form.Text>
                  )}
                </Form.Group>

                <div className="d-xl-flex justify-content-between">
                  <div>
                    <ReCAPTCHA
                      sitekey={Config.captcha}
                      onChange={handleRecaptchaChange}
                    />
                    {!validateCaptcha && (
                      <Form.Text className="text-danger">
                        Por favor, marque o campo de não sou robô!
                      </Form.Text>
                    )}
                  </div>

                  <Form.Group className="mb-3 text-left">
                    <Link to={"/recuperarsenha"} className="d-inline">
                      Esqueceu a senha?
                    </Link>
                  </Form.Group>
                </div>
                <Form.Group className="mt-3">
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    labelButton="Entrar"
                    fontSize="1rem"
                  />
                </Form.Group>
              </ContainerForm>
            </Col>
            <Col className="bg-login d-none d-md-flex" md={5} />
          </Row>
        )}
      </Container>
    </>
  );
}
