import { Link } from "react-router-dom";
import { BorderCard } from "./styles";

const LinkCard = ({ route, title, icon }) => {
  return (
    <BorderCard className="shadow-none d-flex justify-content-center text-center">
      <Link to={route}>
        <div className="text-center">{icon}</div>
        <p>{title}</p>
      </Link>
    </BorderCard>
  );
};

export default LinkCard;
