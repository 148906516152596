import { Col, Row } from "react-bootstrap";
import { CardContainer, PageTitle, TitleContainer } from "./styles";

import { CriminalDetailsCard } from "./CriminalDetailsCard";
import { EmptyTableMessage } from "../EmptyTableMessage";

export const CriminalList = ({ driverConsult }) => {
  const criminalList =
    driverConsult &&
    driverConsult?.consulta_antecedentes_criminais &&
    JSON.parse(driverConsult?.consulta_antecedentes_criminais);

  return (
    <CardContainer className="border-0">
      <TitleContainer className="mb-3 d-flex">
        <PageTitle className="text-start">
          POLÍCIA FEDERAL | ANTECEDENTES CRIMINAIS
        </PageTitle>
      </TitleContainer>

      {driverConsult && !criminalList && (
        <EmptyTableMessage label="Não há registro de antecedentes criminais para este cadastro" />
      )}

      {driverConsult &&
        criminalList &&
        criminalList?.code === 612 &&
        criminalList?.code_message && (
          <EmptyTableMessage label={criminalList?.code_message} />
        )}
      {driverConsult &&
        criminalList &&
        criminalList?.err &&
        criminalList?.message && (
          <EmptyTableMessage label={criminalList?.message} />
        )}

      <Row xs={1} className="d-flex flex-column g-2 p-2">
        {criminalList?.data &&
          criminalList?.data.length > 0 &&
          criminalList?.data.map((item, index) => (
            <Col key={index}>
              <CriminalDetailsCard item={item} />
            </Col>
          ))}
      </Row>
    </CardContainer>
  );
};
