import { memo, useCallback, useEffect, useState } from "react";
import moment from "moment/moment";
import { Spinner, Table } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineWhatsApp } from "react-icons/ai";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

import {
  Circle,
  ListContainer,
  SeeMoreButton,
  TableTitle,
  Thead,
  Title,
  WhatsAppIconButton,
} from "./styles";

import { SearchCTEList } from "./SearchCTEList";
import { useManifest } from "../../../../hooks/ManifestiesProvider";

import Dashboard from "../../../../Shared/layout";
import { formatCpfCnpj, formatNameString } from "../../../../Shared/Utils/data";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import TablePagination from "../../../../Shared/Components/PaginationTable/PaginationTable";
import { manifestStatus } from "../../../../Shared/Utils/constants";

const titleList = [
  { id: 1, label: "Data" },
  { id: 2, label: "Cód. Manifesto" },
  { id: 3, label: "Status" },
  { id: 4, label: "Nome motorista" },
  { id: 5, label: "CPF motorista" },
  { id: 6, label: "Ações" },
];

const MDFeList = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { manifesties, totalPage, isLoading, getManifesties, postNotify } =
    useManifest();

  const handleSendMessage = useCallback(
    (id) => {
      Swal.fire({
        icon: "warning",
        title: "Reenviar link para encerramento da MDF-e?",
        showCancelButton: true,
        confirmButtonColor: "#198754",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        focusCancel: true,
      }).then((result) => {
        if (result.isConfirmed) {
          postNotify(id);
        }
      });
    },
    [postNotify]
  );

  useEffect(() => {
    getManifesties(currentPage);
  }, [currentPage, getManifesties]);

  const handlePageChange = (event) => {
    setCurrentPage(event);
  };

  const handleGetList = async (data) => {
    if (data) {
      const filterItems = Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== "") {
          if (key === "mot_nome") {
            acc[key] = value.toUpperCase();
          } else if (
            key === "manif_status_mdfe" &&
            value === "invalid_status"
          ) {
            delete acc[key];
          } else {
            acc[key] = value;
          }
        }
        return acc;
      }, {});

      await getManifesties(1, filterItems);
    } else {
      await getManifesties(1);
    }
  };

  return (
    <Dashboard>
      <div className="px-2">
        <SearchCTEList handleGetList={handleGetList} />
      </div>
      <div className="w-100 d-flex flex-column justify-content-between">
        <ListContainer className="mb-2">
          <Table size="sm" className="border rounded mb-0">
            <Thead>
              <TableTitle>
                {titleList.map((item) => (
                  <Title
                    key={item.id}
                    className={`${
                      item.id === titleList[0].id
                        ? "ps-3"
                        : item.id === titleList[titleList.length - 1].id &&
                          "text-center px-2 pe-md-3"
                    }`}
                  >
                    {item.label}
                  </Title>
                ))}
              </TableTitle>
            </Thead>

            {!isLoading &&
              Array.isArray(manifesties) &&
              manifesties.length > 0 && (
                <tbody>
                  {manifesties.map((item) => (
                    <tr key={item.id}>
                      <td className="ps-3">
                        {item.manif_data &&
                          moment
                            .utc(item.manif_data)
                            .format("DD/MM/YYYY HH:mm")}
                      </td>
                      <td>{item.manif_cod}</td>
                      <td>
                        <Circle status={item.manif_status_mdfe} />
                        <span>{manifestStatus(item.manif_status_mdfe)}</span>
                      </td>
                      <td>{formatNameString(item.mot_nome)}</td>
                      <td>{formatCpfCnpj(item.mot_cpf)}</td>
                      <td className="text-end px-2 pe-md-3">
                        <div className="d-flex justify-content-end gap-2">
                          {item.manif_status_mdfe === "2" && (
                            <WhatsAppIconButton
                              onClick={() => handleSendMessage(item.manif_cod)}
                              title="Reenviar link ao motorista"
                            >
                              <AiOutlineWhatsApp />
                            </WhatsAppIconButton>
                          )}
                          <SeeMoreButton
                            to={`/gestao-regularizacao-servico-transporte/detalhes/${item.id}`}
                            title="Ver mais"
                            className="d-flex align-items-center justify-content-center"
                          >
                            <span>Ver mais</span>
                            <IoIosArrowForward color="white" />
                          </SeeMoreButton>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
          </Table>
          {isLoading && (
            <div className="d-flex justify-content-center mt-5">
              <Spinner size="sm" animation="border" variant="primary" />
            </div>
          )}
          {!isLoading &&
            (!manifesties ||
              (Array.isArray(manifesties) && manifesties.length === 0)) && (
              <EmptyTableMessage label="Não há registro de CT-e" />
            )}
        </ListContainer>

        {totalPage > 1 && (
          <TablePagination
            currentPage={currentPage}
            totalPages={totalPage}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </Dashboard>
  );
};

export default memo(MDFeList);
