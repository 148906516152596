import { Card, Col, Row } from "react-bootstrap";
import { AiOutlineMessage } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import styled from "styled-components";

export const Border = styled.div` 
   display: flex;
  position: relative;
  border-left: 3px solid var(--color-blue-80);
  padding-left: 10px;

  span {
    font-size: 0.8rem;
    color: var(--color-gray-12);
  }
  

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: var(--color-blue-80);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-66%, -50%);
    z-index: 1;
  }

  `

export const Dot = styled(BsDot)`
  color:  var(--color-blue-80);
  font-size: 1.5rem;
  `

export const PageTitle = styled.h1`
color: var(--color-gray-12);
font-weight: normal;
font-size: 1rem;
margin-bottom: 0;
`

export const RealTimeLocationCard = styled(Card)`
  background-color: var(--color-blue-20);
  border: 0.1px solid var(--color-gray-10);
  max-height: 20vh;
  overflow-x: hidden;
`


export const StyledDivWithBorder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 0.7px solid var(--color-green); 
  width: 6rem;
  display: none;

  @media (min-width: 767px){
    display: flex;
  }
`;

export const LocationItem = styled.span`
  color: ${props => props.index === 0 ? "#07b141" : "#5f5f5f"};
  font-size: 0.8rem;
  display: flex;
  margin-top: 1rem;

  @media(min-width: 768px){
    margin-top: 0;
  }
`

export const LocationIcon = styled(FaMapMarkerAlt)`
  color: ${props => props.index === 0 ? "#07b141" : "#5f5f5f"};
`

export const TotalOccurrences = styled.span`
  font-size: 0.7rem;
  color: var(--color-gray-12);
  margin-bottom: 0;
`

export const OccurrencesCardContainer = styled(Card)`
  background-color: var(--color-gray-3);
  border: none;
  overflow-y: auto; 
  height: ${({ heightSize }) => `${heightSize}px`}; 
  display: flex; 
  flex-direction: column-reverse;
`

export const OccurrenceItem = styled.span`
  color: ${props => props.index === "special" ? "#07b141" : "#5f5f5f"};
  font-size: 0.9rem;
  margin-left: 5px;
`

export const OccurrenceItemIcon = styled(AiOutlineMessage)`
  color: ${props => props.index === "special" ? "#07b141" : "#5f5f5f"};
`

export const OccurrenceDate = styled.span`
  font-size: 0.7rem;
  color: var(--color-gray-11);
  margin-left: 5px;
`

export const RealTimeLocationCardContainer = styled.div`
  overflow-x: hidden;
  max-height: 25vh;
`

export const RealTimeLocationRow = styled(Row)`
max-height: 40vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 425px){
  max-height: 60vh;
 }

 @media(min-width: 576px){
  max-height: 70vh;
 }

 @media(min-width: 1800px){
  max-height: 75vh;
  }

 @media(min-width: 2200px){
  max-height: 80vh;
 }


`

export const Column = styled(Col)`
  @media(min-width: 1200px){
  overflow-x: hidden;
  max-height: 69vh;
  }

  @media(min-width: 1800px){
  max-height: 74vh;
  }

  @media(min-width: 2200px){
  max-height: 79vh;
 }

 
`