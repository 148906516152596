import styled from "styled-components";
import { Link } from "react-router-dom";


export const TableItem = styled.td`
font-size: 0.75rem;
font-weight: ${props => props.bold ? "bold" : "normal"};

color: ${props => (props?.status === 1 || props?.status === "10r")
    ? "#ff0f0f"
    : props?.status === 2
      ? "#7e0000"
      : "#4d4d4d"};

@media(min-width: 1440px){
  font-size: 0.875rem;
}
      
`;


export const TableTitle = styled.th`
font-size: 0.65rem;
color: var(--color-gray-15);
font-weight: 700;

@media(min-width: 1440px){
  font-size: 1rem !important;
}
`

export const ActionButton = styled(Link)`
background-color: transparent !important;
padding: 3px;
border-radius: 3px;
transition: transform 300ms;

&:hover{
  transform: scale(1.1);
    z-index: 1;
}
`



export const ProcessesTableTitle = styled.tr`

th {
  font-size: 0.875rem;
  font-weight: bold;
  
}
`

export const ProcessListContainer = styled.div`
  overflow: auto;
  max-height: 40vh;

 @media(min-width: 425px){
  max-height: 45vh;
 }

 @media(min-width: 576px){
  max-height: 50vh;
 }

 @media(min-width: 1640px){
  max-height: 60vh;
 }


`

export const Thead = styled.thead`
position: sticky;
 top: 0;
 z-index: 1;
 background-color: white;
`