import React, { useEffect } from "react";
import Dashboard from "../../../../Shared/layout";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormEditUser from "./FormEditUser";
import { useUsers } from "../../../../hooks/UserProvider";
import { usePerfis } from "../../../../hooks/PerfilProvider";
import { useUnits } from "../../../../hooks/UnitProvider";
import { useCompanies } from "../../../../hooks/CompanyProvider";
import { sortedFantasyNames, sortedNames } from "../../../../Shared/Utils/data";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import { useScopes } from "../../../../hooks/ScopeProvider";

const UpdateUser = () => {
  const { id } = useParams();

  const { units, getUnits } = useUnits();
  const { perfis, getPerfis } = usePerfis();
  const { companies, getCompanies } = useCompanies();
  const { scopes, getScopes } = useScopes();

  const { isLoading, isSubmitting, user, updateUser, getUser } = useUsers();

  useEffect(() => {
    getUser(id);
  }, [getUser, id]);

  useEffect(() => {
    getScopes();
  }, [getScopes]);

  useEffect(() => {
    getUnits();
  }, [getUnits]);

  useEffect(() => {
    getPerfis();
  }, [getPerfis]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (id, data) => {
    if (data.id_unidade === null) {
      updateUser(
        id,
        {
          ativo: data.ativo,
          nome: data.nome,
          id_perfil: parseInt(data.id_perfil),
        },
        navigate
      );
    } else {
      updateUser(
        id,
        {
          ativo: data.ativo,
          nome: data.nome,
          id_perfil: parseInt(data.id_perfil),
        },
        navigate
      );
    }
  };

  return (
    <Dashboard>
      {isLoading && <Loading />}

      {!isLoading && user && (
        <FormEditUser
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          perfis={sortedNames(perfis)}
          units={sortedNames(units)}
          companies={sortedFantasyNames(companies)}
          scopes={scopes}
          user={user}
        />
      )}
    </Dashboard>
  );
};

export default UpdateUser;
