import React, { useCallback, useEffect, useState } from "react";
import Dashboard from "../../../../Shared/layout";
import { useNavigate } from "react-router-dom";

import ButtonLink from "../../../../Shared/Components/ButtonLink/ButtonLink";
import { usePerfis } from "../../../../hooks/PerfilProvider";
import { PerfilList } from "./PerfilList";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import Search from "../../../../Shared/Components/Search/Search";
import { Col, Row } from "react-bootstrap";
import { useScopes } from "../../../../hooks/ScopeProvider";
import PerfilSelectInput from "./PerfilSelectInput";
import { sortedNames } from "../../../../Shared/Utils/data";
import { EmptyTableMessage } from "../../../../Shared/Components/EmptyTableMessage";
import { AiOutlineCloseCircle, AiOutlinePlus } from "react-icons/ai";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default function PerfisComponent() {
  const [searchPerfil, setSearchPerfil] = useState("");

  const navigate = useNavigate();

  const { scopes, getScopes } = useScopes();

  const {
    perfis,
    isLoadingPerfis,
    getPerfis,
    deletePerfil,
    getPerfisByScope,
    getPerfil,
  } = usePerfis();

  useEffect(() => {
    getPerfis();
  }, [getPerfis]);

  useEffect(() => {
    getScopes();
  }, [getScopes]);

  const handleSearchChange = (event) => {
    setSearchPerfil(event && event !== "" ? event.target.value : "");
  };

  const filteredPerfis = perfis.filter((perfil) => {
    return perfil.nome.toLowerCase().includes(searchPerfil.toLowerCase());
  });

  const handleDeleteCompany = useCallback(
    (perfil) => {
      Swal.fire({
        icon: "warning",
        title: "Você tem certeza absoluta?",
        html: `
      <div>
        <p>Essa ação não pode ser desfeita. Isso removerá permanentemente os dados.</p>
        <p>Digite <strong style="background-color: red; color: white">${perfil?.nome}</strong> para confirmar.</p>
      </div>
    `,
        input: "text",
        inputPlaceholder: "Digite o nome do perfil",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Eu entendo as consequências, remover perfil",
        cancelButtonText: "Cancelar",
        focusCancel: true,
        preConfirm: (value) => {
          if (value === perfil?.nome) {
            deletePerfil(perfil?.id);
            return true;
          } else {
            Swal.showValidationMessage(
              "O nome da empresa não corresponde. Tente novamente."
            );
            return false;
          }
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
    [deletePerfil]
  );

  return (
    <Dashboard title="Perfis de usuários">
      <Row className="d-lg-flex aling-items-center justify-content-start mb-1 mt-3">
        <Col md={5} lg={3}>
          <PerfilSelectInput
            scopes={sortedNames(scopes)}
            getPerfisByScope={getPerfisByScope}
            getPerfis={getPerfis}
          />
        </Col>
      </Row>

      <Row
        xs={1}
        md={2}
        className="d-lg-flex aling-items-center justify-content-between mb-4 gap-4"
      >
        <Col
          md={5}
          lg={4}
          className="d-flex align-items-end justify-content-md-start"
        >
          {localStorage.getItem("perfilManagementPermissionToCreate") &&
            localStorage.getItem("perfilManagementPermissionToCreate") !==
              "false" && (
              <ButtonLink
                label="Adicionar novo perfil"
                link="/perfis-adicionar"
                icon={<AiOutlinePlus />}
              />
            )}
        </Col>

        <Col md={5} lg={4} xl={3}>
          <Search
            label="Pesquisar perfil"
            searchValue={searchPerfil}
            handleSearchChange={handleSearchChange}
          />
        </Col>
      </Row>

      {isLoadingPerfis && <Loading />}
      {!isLoadingPerfis &&
        filteredPerfis.length > 0 &&
        localStorage.getItem("perfilManagementPermissionToRead") &&
        localStorage.getItem("perfilManagementPermissionToRead") !==
          "false" && (
          <>
            <PerfilList
              getPerfil={getPerfil}
              perfis={sortedNames(filteredPerfis)}
              onDelete={handleDeleteCompany}
              navigate={navigate}
              perfilManagementPermissionToUpdate={localStorage.getItem(
                "perfilManagementPermissionToUpdate"
              )}
              perfilManagementPermissionToDelete={localStorage.getItem(
                "perfilManagementPermissionToDelete"
              )}
            />
          </>
        )}
      {!isLoadingPerfis &&
        filteredPerfis.length === 0 &&
        localStorage.getItem("perfilManagementPermissionToRead") &&
        localStorage.getItem("perfilManagementPermissionToRead") !==
          "false" && <EmptyTableMessage />}
      {!isLoadingPerfis &&
        (!localStorage.getItem("perfilManagementPermissionToRead") ||
          localStorage.getItem("perfilManagementPermissionToRead") ===
            "false") && (
          <EmptyTableMessage
            label="Listagem de perfis não disponível para o seu usuário"
            icon={<AiOutlineCloseCircle />}
          />
        )}
    </Dashboard>
  );
}
