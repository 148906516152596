import { Details, MainDetailsBorderCard, SeemoreButton } from "./styles";
import { IoIosArrowForward } from "react-icons/io";

export const MainDetailsCard = ({ title, value, route }) => {
  return (
    <MainDetailsBorderCard className="text-center p-2 d-xl-flex flex-md-row gap-3 mb-3 align-items-center">
      <Details className="gap-1">
        <h1>{title}</h1>
        <span className="mb-0">{value}</span>
      </Details>
      <div className="d-flex align-items-center">
        <SeemoreButton to={route} className="d-flex align-items-center">
          Detalhes
          <IoIosArrowForward />
        </SeemoreButton>
      </div>
    </MainDetailsBorderCard>
  );
};
