import React from "react";
import { Form } from "react-bootstrap";

export const ActiveInput = ({ value, updateValue, disabledInput }) => {
  const handleChange = () => {
    try {
      updateValue(value.id, {
        ativo: !value?.ativo,
      });
    } catch (e) {
      console.error();
    }
  };

  return (
    <Form>
      <Form.Group>
        <Form.Check
          type="switch"
          id="custom-switch"
          title="Ativar / Desativar"
          checked={value?.ativo}
          onChange={handleChange}
          disabled={disabledInput}
        />
      </Form.Group>
    </Form>
  );
};
