import { Col, FloatingLabel, Form, Row } from "react-bootstrap";

import { useCallback, useEffect, useState } from "react";
import { anttTypes } from "../../../../../../Shared/Utils/constants";

const OwnerTypes = ({ register, owner, errors, setShowForm, setIsCpf }) => {
  const [ownerAnttType, setOwnerAnttType] = useState(owner?.tipo_antt);

  const disable = useCallback(
    (value) => {
      if (
        owner?.tipo_antt === "1" &&
        (value === "2" || value === "3" || value === "4")
      ) {
        return true;
      }

      if (
        (owner?.tipo_antt === "2" ||
          owner?.tipo_antt === "3" ||
          owner?.tipo_antt === "4") &&
        value === "1"
      ) {
        return true;
      }

      return false;
    },
    [owner?.tipo_antt]
  );

  useEffect(() => {
    if (owner?.tipo_antt) {
      if (owner?.tipo_antt === "1") {
        setIsCpf(true);
      } else {
        setIsCpf(false);
      }
    }
  }, [owner?.tipo_antt, setIsCpf]);

  const handleOnSelect = useCallback(
    (event) => {
      setOwnerAnttType(event.target.value);
      if (setShowForm) {
        if (event.target.value !== "") {
          setShowForm(true);
        } else {
          setShowForm(false);
        }

        if (event.target.value === "1") {
          setIsCpf(true);
        }
        if (
          event.target.value === "2" ||
          event.target.value === "3" ||
          event.target.value === "4"
        ) {
          setIsCpf(false);
        }
      }
    },
    [setIsCpf, setOwnerAnttType, setShowForm]
  );

  return (
    <Row className="g-2">
      <Form.Group
        as={Col}
        md={6}
        lg={4}
        controlId="formAnttTypes"
        className="mb-2"
      >
        <FloatingLabel controlId="formAnttTypes" label="Tipos de proprietários">
          <Form.Select
            {...register("tipo_antt", { required: true })}
            className="is-required"
            size="sm"
            type="text"
            value={ownerAnttType}
            onChange={handleOnSelect}
          >
            <option value={""} disabled={owner}>
              Selecione o tipo de proprietário
            </option>
            {anttTypes.map((anttType) => (
              <option
                value={anttType.value}
                key={anttType.id}
                disabled={disable(anttType.value)}
              >
                {anttType.label}
              </option>
            ))}
          </Form.Select>
          {errors?.tipo_antt?.type === "required" && (
            <Form.Text className="text-danger">
              O tipo de proprietário é obrigatório.
            </Form.Text>
          )}
        </FloatingLabel>
      </Form.Group>
    </Row>
  );
};

export default OwnerTypes;
