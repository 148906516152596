import { Form, InputGroup, Ratio, Spinner } from "react-bootstrap";
import {
  AlertIcon,
  BorderRow,
  CoverImage,
  DeleteCnhButton,
  DeleteIconButton,
  ErrorMessage,
  FormControlInput,
  ImageTitle,
  PdfLoadingContainer,
  ShowModalButton,
  UploadImageButton,
} from "./styles";

import EmptyImage from "../../../../../../Assets/Images/EmptyImage.png";
import { useCallback, useMemo, useState } from "react";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
import { pdfConverter } from "../../../../../../Shared/Utils/pdfConverter";

const UploadOwnerProodOfAddress = ({
  owner,
  className,
  width,
  height,
  paddingBottom,
  setValue,
  hideTitle,
  hideInput,
  handleShowModal,
  setCurrentImage,
  align = "start",
  errors,
  register,
  isCpf,
  widthSelect,
}) => {
  const [uploadImage, setUploadImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [uploadLogoImage, setUploadLogoImage] = useState(
    owner ? owner?.foto_comprovante_endereco : null
  );

  const [isChange, setIsChange] = useState(false);

  const deleteImage = () => {
    setUploadImage(null);
    setUploadLogoImage(null);
  };

  const coverImage = useMemo(() => {
    if (owner?.foto_comprovante_endereco?.url && !isChange) {
      setCurrentImage && setCurrentImage(owner?.foto_comprovante_endereco);
      return owner?.foto_comprovante_endereco?.url;
    }

    if (uploadLogoImage && isChange) {
      setIsChange(false);
      setCurrentImage && setCurrentImage(uploadLogoImage);
      return uploadLogoImage;
    }

    if (!owner?.foto_comprovante_endereco && !uploadLogoImage) {
      setIsChange(false);
      setCurrentImage && setCurrentImage(EmptyImage);
      return EmptyImage;
    }

    if (isChange && !uploadLogoImage) {
      setIsChange(false);
      setCurrentImage && setCurrentImage(`url(${EmptyImage})`);
      return EmptyImage;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner?.foto_comprovante_endereco, uploadLogoImage]);

  const handleFileChange = useCallback(
    (event) => {
      setErrorMessage("");
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const arrayBuffer = e.target.result;

          const base64 = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );

          setUploadImage(file);

          if (file.type === "application/pdf") {
            setValue(
              "foto_comprovante_endereco",
              "data:" + file.type + ";base64," + base64
            );

            pdfConverter(file, setUploadLogoImage, setIsLoading);
          } else if (file.type === "image/jpeg" || file.type === "image/png") {
            setUploadLogoImage(URL.createObjectURL(file));

            setValue(
              "foto_comprovante_endereco",
              "data:" + file.type + ";base64," + base64
            );
          } else {
            setErrorMessage("Arquivo não suportado");
          }
        };

        setIsChange(true);

        reader.readAsArrayBuffer(file);
      }
    },
    [setValue]
  );

  return (
    <BorderRow className="p-3">
      <Form.Group
        className={`${className} ${
          uploadImage && "justify-content-start gap-3"
        }`}
      >
        {!hideTitle && (
          <ImageTitle className="text-start mb-1">
            Comprovante de residência
          </ImageTitle>
        )}

        {isLoading ? (
          <PdfLoadingContainer width={width} height={height}>
            <Spinner animation="border" role="status" variant="light" />
          </PdfLoadingContainer>
        ) : (
          <>
            {coverImage === owner?.foto_comprovante_endereco?.url &&
            owner?.foto_comprovante_endereco?.type === "application/pdf" ? (
              <div style={{ width: 166, height: "auto" }}>
                <Ratio aspectRatio="1x1">
                  <embed
                    type="application/pdf"
                    src={owner?.foto_comprovante_endereco?.url}
                  />
                </Ratio>
              </div>
            ) : (
              <CoverImage
                width={width}
                height={height}
                paddingBottom={paddingBottom}
                image={coverImage}
              />
            )}
          </>
        )}
        {handleShowModal && coverImage !== EmptyImage && (
          <div className={`${align ? "text-start" : "text-end"}`}>
            <ShowModalButton onClick={handleShowModal} className="px-0">
              <div className="d-flex align-items-center gap-1">
                <MdOutlineFilterCenterFocus />
                Visualizar comprovante
              </div>
            </ShowModalButton>
          </div>
        )}

        {errorMessage === "Arquivo não suportado" && (
          <div className="d-flex align-items-center gap-1 mt-1">
            <AlertIcon size={10} />
            <ErrorMessage>Arquivo não suportado</ErrorMessage>
          </div>
        )}

        {uploadImage && (
          <div className="d-flex align-items-center">
            <DeleteCnhButton
              type="button"
              className="d-flex align-items-center p-0 gap-1"
              onClick={deleteImage}
            >
              <label>{uploadImage?.name}</label>
              <DeleteIconButton />
            </DeleteCnhButton>
          </div>
        )}

        {!uploadImage && !hideInput && (
          <InputGroup className=" mt-1">
            <div>
              <UploadImageButton
                className="rounded d-flex justify-content-center align-items-center"
                width={width}
              >
                <label
                  htmlFor="image-owner-proof-of-address-input"
                  className="custom-file-upload"
                >
                  Selecionar imagem
                </label>
              </UploadImageButton>
              {errors?.foto_comprovante_endereco && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors?.foto_comprovante_endereco?.message}
                </Form.Control.Feedback>
              )}
            </div>
            <FormControlInput
              {...register("foto_comprovante_endereco", {
                required:
                  owner || (!owner && !isCpf) ? false : "A foto é obrigatória",
              })}
              type="file"
              id="image-owner-proof-of-address-input"
              name="image-owner-proof-of-address-input"
              onChange={handleFileChange}
              className="d-none"
            />
          </InputGroup>
        )}
      </Form.Group>
    </BorderRow>
  );
};

export default UploadOwnerProodOfAddress;
