import { Col, Row } from "react-bootstrap";
import Dashboard from "../../../../../Shared/layout";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import StatisticCard from "../../../../../Shared/Components/StatisticCard/StatisticCard";
import Search from "../../../../../Shared/Components/Search/Search";
import TablePagination from "../../../../../Shared/Components/PaginationTable/PaginationTable";
import Select from "react-select";
import { useVehicles } from "../../../../../hooks/VehicleProvider";
import { ListLoader } from "../../../../../Shared/Components/ListLoader/ListLoader";
import { activeOptions } from "../../../../../Shared/Utils/constants";
import { EmptyTableMessage } from "../../../../../Shared/Components/EmptyTableMessage";
import Loader from "../../../../../Shared/Components/Loader/Loader";
import { LoadingContainer, VehicleListRow, VehiclesListRow } from "./styles";
import VehicleCard from "../components/VehicleCard/VehicleCard";

const VehicleList = () => {
  const [searchVehicle, setSearchVehicle] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isActive, setIsActive] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  const {
    vehicles,
    allVehicles,
    totalPages,
    isLoading,
    isLoadingAllOwners,
    getVehicles,
    getVehicle,
    getAllVehicles,
  } = useVehicles();

  useEffect(() => {
    getAllVehicles();
  }, [getAllVehicles]);

  useEffect(() => {
    if (isTyping) {
      setTimeout(() => {
        setIsTyping(false);
      }, 1000);
    }
  }, [isTyping]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (!isTyping) {
      window.scrollTo(0, 0);
      getVehicles(currentPage, searchVehicle, isActive);
    }
  }, [currentPage, getVehicles, isActive, isTyping, searchVehicle]);

  const handleSearchChange = useCallback((event) => {
    const uppercaseValue =
      event && event !== "" ? event.target.value.toUpperCase() : "";
    setSearchVehicle(uppercaseValue);
    setCurrentPage(1);
    setIsTyping(true);
  }, []);

  const handleSelectActiveStatus = useCallback(
    (event) => {
      setCurrentPage(1);

      if (event?.label === "Em análise") {
        return getVehicles(currentPage, searchVehicle, true, "9");
      }
      if (event?.label === "Liberados") {
        setIsActive(true);
        return getVehicles(currentPage, searchVehicle, true);
      }

      if (event?.label === "Desativados" || event?.label === "Bloqueado ADM") {
        setIsActive(false);
        return getVehicles(currentPage, searchVehicle, false);
      }

      if (event?.label === "Listar todos") {
        setIsActive(null);
        return getVehicles(currentPage, searchVehicle);
      }
    },
    [currentPage, getVehicles, searchVehicle]
  );

  const filteredVehicles = useMemo(() => {
    return (
      vehicles &&
      vehicles.filter((driver) => {
        const matchesSearch = driver?.placa
          .toLowerCase()
          .includes(searchVehicle.toLowerCase());
        return matchesSearch;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles, searchVehicle, handleSelectActiveStatus]);

  return (
    <Dashboard>
      <Row className="mb-2 d-flex justify-content-end">
        <Col md={5} xl={3}>
          <Search
            label="Pesquisar veículo por placa"
            searchValue={searchVehicle}
            handleSearchChange={handleSearchChange}
          />
        </Col>
      </Row>
      <VehicleListRow xs={1} md={2} className="g-3">
        <Col md={9} lg={10} className="d-flex flex-column">
          <div className="d-flex">
            <Select
              className="react-select mb-3"
              options={activeOptions}
              onChange={handleSelectActiveStatus}
              value={isActive}
              placeholder="-- Filtrar veículo --"
            />
          </div>
          {filteredVehicles.length === 0 && !isLoading && !isTyping && (
            <EmptyTableMessage />
          )}
          {(isLoading || isTyping) && <ListLoader />}
          <VehiclesListRow xs={1} md={2} xl={3} className="g-3 mb-2">
            {filteredVehicles &&
              !isTyping &&
              !isLoading &&
              Array.isArray(vehicles) &&
              filteredVehicles.map((vehicle) => (
                <Col className="d-flex" key={vehicle?.id}>
                  <VehicleCard vehicle={vehicle} getVehicle={getVehicle} />
                </Col>
              ))}
          </VehiclesListRow>
          <div className="mt-auto d-flex align-items-end">
            {!isLoading &&
              vehicles &&
              Array.isArray(vehicles) &&
              vehicles.length > 0 && (
                <TablePagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              )}
            {isLoading && (
              <LoadingContainer>
                <Loader />
              </LoadingContainer>
            )}
          </div>
        </Col>

        <Col md={3} lg={2}>
          {isLoadingAllOwners && <Loader />}
          {!isLoadingAllOwners && (
            <StatisticCard items={allVehicles} label="veículos" />
          )}
        </Col>
      </VehicleListRow>
    </Dashboard>
  );
};

export default memo(VehicleList);
