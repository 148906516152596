import { useMemo } from "react";
import { Card } from "react-bootstrap";

import { BorderCard, CoverImage } from "../../../styles";

import EmptyImage from "../../../../../../Assets/Images/EmptyImage.png";
import {
  cpfCnpjFormat,
  formatNameString,
} from "../../../../../../Shared/Utils/data";
import { StatusCard } from "../../../../../../Shared/Components/StatusCard";

const ControlOwnerCard = ({ owner }) => {
  const cpfCnpj = useMemo(() => {
    if (owner?.cpf_cnpj) {
      return cpfCnpjFormat(owner?.cpf_cnpj);
    }
  }, [owner?.cpf_cnpj]);

  return (
    <BorderCard
      className="text-center w-100 flex-1 h-100 card d-flex flex-column"
      data-testid="control-owner-card"
    >
      <Card.Body className="text-center pb-0 mb-4">
        <CoverImage emptyImage={owner?.foto_proprietario?.url ?? EmptyImage} />
        <div className="text-center d-flex flex-column mt-3 mb-4">
          <h3 className="text-center mb-1">{formatNameString(owner?.nome)}</h3>
          <h4 className="text-center mb-3">{owner?.cpf_cnpj && cpfCnpj}</h4>
          {owner.status === "9" && <StatusCard label="Em análise" />}
          {owner.status !== "9" && (
            <h5 className="text-center">
              Status:
              <span
                className={`ms-1 mt-3 ${
                  owner?.ativo === true ? "color-active" : "color-red"
                } `}
              >
                {owner?.bloqueado_adm === "S"
                  ? "Bloqueado Adm "
                  : owner?.ativo === true
                  ? "Liberado"
                  : "Desativado"}
              </span>
            </h5>
          )}
        </div>
      </Card.Body>
    </BorderCard>
  );
};

export default ControlOwnerCard;
