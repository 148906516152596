import { Card, FormControl } from "react-bootstrap";
import styled from "styled-components";



export const BorderCard = styled(Card)`
  h3 {
    font-size: 1rem;

  }

  h4{
    font-size: 0.7rem;
    color: #aaaaaa;
  }

  h5{
    font-size: 0.7rem;
    color: #5f5f5f;

    span {
      font-size: 0.7rem;
    }
  }

  h6{
    font-size: 0.7rem;
    color: #aeaeae;

    span {
      font-size: 0.7rem;
    }
  }
`

export const CardBody = styled(Card.Body)`
border-bottom: 1px solid var(--color-gray-10);
`

export const CoverImage = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 1000px;
`
export const FormControlInput = styled(FormControl)`
font-size: 0.7rem;
display: flex;
`




export const Container = styled.div`
 @media(min-width: 1440px){
  overflow-x: hidden;
  max-height: 70vh;
  }

  @media(min-width: 1640px){
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  max-height: 80vh;
  }
  
`;

export const AddOwnerRow = styled.div`

max-height: 50vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 425px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 60vh;
 }

 @media(min-width: 1440px){
  max-height: 65vh;
  }

  @media(min-width: 1640px){
  max-height: 70vh;
  }
  @media(min-width: 2440px){
  max-height: 75vh;
  }
  
`;


export const PageTitle = styled(Card.Title)`
color: var(--color-gray-12);
font-weight: normal;
font-size: 1rem;
`