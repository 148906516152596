import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { SubmitButton } from "../../../../../../Shared/Components/SubmitButton/SubmitButton";
import InputMask from "react-input-mask";
import { useCallback, useEffect, useState } from "react";
import ArrayEstadosCidades from "../../../../../../Shared/Utils/EstadosCidades/estados-cidades.json";
import { useAddresses } from "../../../../../../hooks/AddressProvider";
import { onlyNumbers } from "../../../../../../Shared/Utils/data";
import UploadCompanyLogo from "./UploadCompanyLogo";
import { Loading } from "../../../../../../Shared/Components/Loading/Loading";
import { cnpj } from "cpf-cnpj-validator";
import validator from "validator";
import ButtonLink from "../../../../../../Shared/Components/ButtonLink/ButtonLink";

export const CompanyForm = ({
  onSubmit,
  handleSubmit,
  register,
  setValue,
  errors,
  company,
  isSubmitting,
}) => {
  const [cidadesDoEstado, setCidadesDoEstado] = useState([]);
  const [newAddress, setNewAddress] = useState();
  const [hasChange, setHasChange] = useState(false);

  const { addresses, isLoading, getAddresses } = useAddresses();

  const obterCidadesPorSigla = (sigla) => {
    const estado = ArrayEstadosCidades.estados.find(
      (estado) => estado.sigla === sigla
    );

    if (estado) {
      return estado?.cidades;
    } else {
      return [];
    }
  };

  const handleChangeCepInput = useCallback(
    async (event) => {
      setHasChange(false);
      const cep = onlyNumbers(event.target.value);

      if (cep.length > 7) {
        getAddresses(cep);
        setHasChange(true);
      }
    },
    [getAddresses]
  );

  useEffect(() => {
    if (company) {
      getAddresses(company?.cep);
    }
  }, [company, getAddresses]);

  useEffect(() => {
    if (company) {
      const cidades = obterCidadesPorSigla(addresses?.uf);
      setCidadesDoEstado(cidades);

      setHasChange(true);
    }
  }, [addresses?.uf, company, getAddresses]);

  useEffect(() => {
    setNewAddress(addresses);
  }, [addresses]);

  useEffect(() => {
    if (addresses?.localidade) {
      const cidades = obterCidadesPorSigla(addresses?.uf);
      setCidadesDoEstado(cidades);
    } else {
      setCidadesDoEstado([]);
    }
  }, [addresses?.localidade, addresses?.uf]);

  useEffect(() => {
    if (hasChange === true && newAddress !== null) {
      setValue("cidade", newAddress?.localidade);
      setValue("bairro", newAddress?.bairro);
      setValue("endereco", newAddress?.logradouro);
      setValue("uf", newAddress?.uf);
    }
  }, [hasChange, newAddress, setValue]);

  return (
    <Form
      onSubmit={handleSubmit((data) => onSubmit(company?.id, data))}
      className="mt-4 px-3"
    >
      <Row className="g-3">
        <Col xs={12}>
          <UploadCompanyLogo
            errors={errors}
            company={company}
            setValue={setValue}
            width="5rem"
            height="5rem"
          />
        </Col>
        <Form.Group as={Col} xs={2} controlId="formCode">
          <FloatingLabel controlId="formCode" label="Código">
            <Form.Control
              size="sm"
              type="text"
              disabled
              defaultValue={company && company?.id}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} xs={10} controlId="formCorporateName">
          <FloatingLabel controlId="formCorporateName" label="Razão social">
            <Form.Control
              {...register("razao_social", {
                required: "A razão social é obrigatória",
              })}
              size="sm"
              type="text"
              placeholder="Digite a razão social"
              className="is-required"
              defaultValue={company && company?.razao_social}
            />
            {errors?.razao_social && (
              <Form.Text className="text-danger">
                {errors?.razao_social?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formFantasyName">
          <FloatingLabel controlId="formFantasyName" label="Nome fantasia">
            <Form.Control
              {...register("nome_fantasia", {
                required: "O nome fantasia é obrigatório",
              })}
              size="sm"
              type="text"
              placeholder="Digite o nome fantasia"
              className="is-required"
              defaultValue={company && company?.nome_fantasia}
            />
            {errors?.nome_fantasia && (
              <Form.Text className="text-danger">
                {errors?.nome_fantasia?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formCNPJ">
          <FloatingLabel controlId="formCNPJ" label="CNPJ">
            <Form.Control
              {...register("cnpj", {
                required: "O CNPJ é obrigatório",
                validate: (value) => {
                  value = value.replace(/\D/g, "");
                  return cnpj.isValid(value) || "CNPJ inválido";
                },
              })}
              size="sm"
              as={InputMask}
              mask="99.999.999/9999-99"
              type="text"
              placeholder="Digite o cnpj"
              className="is-required"
              disabled={company}
              defaultValue={company && company?.cnpj}
            />
            {errors?.cnpj && (
              <Form.Text className="text-danger">
                {errors?.cnpj?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formStateRegistration">
          <FloatingLabel
            controlId="formStateRegistration"
            label="Inscrição estadual"
          >
            <Form.Control
              {...register("inscricao_estadual", {
                required: "A inscrição estadual é obrigatória",
                validate: (value) => {
                  if (!/^[a-zA-Z0-9]+$/.test(value)) {
                    return "A inscrição estadual não pode conter caracteres especiais";
                  }

                  if (/^\d+$/.test(value)) {
                    return (
                      value.length >= 9 ||
                      "Número de Inscrição Estadual inválido"
                    );
                  } else {
                    return (
                      /^isento$|^Isento$|^ISENTO$/.test(value) ||
                      "Inscrição Estadual inválida"
                    );
                  }
                },
              })}
              size="sm"
              type="text"
              placeholder="Digite a inscrição estadual"
              maxLength={25}
              className="is-required"
              defaultValue={company && company?.inscricao_estadual}
            />
            {errors?.inscricao_estadual && (
              <Form.Text className="text-danger">
                {errors?.inscricao_estadual?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formCep">
          <FloatingLabel controlId="formCep" label="Cep">
            <Form.Control
              {...register("cep", {
                required: "O cep é obrigatório",
                validate: (value) => {
                  value = value.replace(/\D/g, "");
                  if (value.length !== 8) {
                    return "CEP inválido";
                  }
                  return true;
                },
              })}
              size="sm"
              type="text"
              placeholder="Digite o cep"
              as={InputMask}
              mask="99999-999"
              className="is-required"
              defaultValue={company && company?.cep}
              onChange={handleChangeCepInput}
            />
            {errors?.cep && (
              <Form.Text className="text-danger">
                {errors?.cep?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Form.Group as={Col} md={4} controlId="formState">
              <FloatingLabel controlId="formState" label="Estado">
                <Form.Select
                  size="sm"
                  {...register("uf")}
                  type="text"
                  disabled
                  defaultValue={newAddress?.uf}
                >
                  <option value="" disabled>
                    -- Selecione um Estado --
                  </option>
                  {ArrayEstadosCidades.estados
                    .map((estado) => estado.sigla)
                    .map((sigla) => (
                      <option key={sigla} value={sigla}>
                        {sigla}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4} controlId="formCidade">
              <FloatingLabel controlId="formCidade" label="Cidade">
                <Form.Select
                  aria-label="Default select example"
                  {...register("cidade", {
                    required: "A cidade é obrigatória",
                  })}
                  size="sm"
                  type="text"
                  disabled
                  className="is-required"
                  placeholder="Digite a cidade"
                  defaultValue={
                    company
                      ? company?.cidade
                      : addresses && addresses?.localidade
                  }
                >
                  <option value="">-- Selecione uma cidade --</option>
                  {cidadesDoEstado.map((cidade) => (
                    <option key={cidade} value={cidade}>
                      {cidade}
                    </option>
                  ))}
                </Form.Select>
                {errors?.cidade && (
                  <Form.Text className="text-danger">
                    {errors?.cidade?.message}
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4} controlId="formBairro">
              <FloatingLabel controlId="formBairro" label="Bairro">
                <Form.Control
                  {...register("bairro", {
                    required: "O bairro é obrigatório",
                  })}
                  size="sm"
                  type="text"
                  disabled
                  className="is-required"
                  placeholder="Digite o bairro"
                  defaultValue={company && company?.bairro}
                />
              </FloatingLabel>
              {errors?.bairro && (
                <Form.Text className="text-danger">
                  {errors?.bairro?.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} md={12} controlId="formAddress">
              <FloatingLabel controlId="formAddress" label="Endereço">
                <Form.Control
                  {...register("endereco", {
                    required: "O endereço é obrigatório",
                  })}
                  size="sm"
                  type="text"
                  disabled
                  className="is-required"
                  placeholder="Digite o endereço"
                  defaultValue={company && company?.endereco}
                />
                {errors?.endereco && (
                  <Form.Text className="text-danger">
                    {errors?.endereco?.message}
                  </Form.Text>
                )}
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4} controlId="formNumber">
              <FloatingLabel controlId="formNumber" label="Número">
                <Form.Control
                  {...register("numero_endereco", {
                    required: "O número é obrigatório",
                  })}
                  size="sm"
                  type="text"
                  placeholder="Número"
                  className="is-required"
                  defaultValue={company && company?.numero_endereco}
                />
              </FloatingLabel>
              {errors?.numero_endereco && (
                <Form.Text className="text-danger">
                  {errors?.numero_endereco?.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} md={8} controlId="formComplement">
              <FloatingLabel controlId="formComplement" label="Complemento">
                <Form.Control
                  {...register("complemento")}
                  size="sm"
                  type="text"
                  placeholder="Digite o complemento"
                  defaultValue={company && company?.complemento}
                />
              </FloatingLabel>
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} md={6} controlId="formEmail">
          <FloatingLabel controlId="formEmail" label="E-mail">
            <Form.Control
              {...register("email", {
                validate: (value) => {
                  if (value?.length > 0 && !validator.isEmail(value)) {
                    return "E-mail inválido";
                  }
                  return true;
                },
              })}
              size="sm"
              type="text"
              placeholder="Digite o e-mail"
              defaultValue={company && company?.email}
            />
          </FloatingLabel>
          {errors?.email && (
            <Form.Text className="text-danger">
              {errors?.email?.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6} controlId="formPhone">
          <FloatingLabel controlId="formPhone" label="Telefone">
            <Form.Control
              {...register("telefone")}
              size="sm"
              as={InputMask}
              mask="(99)9999-99999"
              maskChar={null}
              type="text"
              placeholder="Digite o telefone"
              defaultValue={company && company?.telefone}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mt-4 d-flex gap-2 mb-3">
          <SubmitButton
            labelButton="Salvar nova empresa"
            isSubmitting={isSubmitting}
          />
          <ButtonLink label="Cancelar" link="/empresas" fontSize="0.7rem" />
        </Form.Group>
      </Row>
    </Form>
  );
};
