import { Item } from "./styles";

export const DivergentStatesCard = ({
  driverState,
  vehicleState,
  truckState,
  index,
  ownerANTT,
  vehicleANTT,
}) => {
  return (
    <div>
      {driverState && vehicleState && (
        <div>
          <Item className="d-flex align-items-center gap-1 mb-3">
            <h2>UF CNH Motorista:</h2>
            <span>{driverState}</span>
          </Item>
          <Item className="d-flex align-items-center gap-1">
            <h2>UF emplacamento cavalo:</h2>
            <span>{vehicleState}</span>
          </Item>
        </div>
      )}
      {truckState && vehicleState && (
        <div>
          <Item className="d-flex align-items-center gap-1 mb-3">
            <h2>UF emplacamento carreta {index + 1}:</h2>
            <span>{truckState}</span>
          </Item>
          <Item className="d-flex align-items-center gap-1">
            <h2>UF emplacamento cavalo:</h2>
            <span>{vehicleState}</span>
          </Item>
        </div>
      )}
      {ownerANTT && vehicleANTT && (
        <div>
          <Item className="d-flex align-items-center gap-1 mb-3">
            <h2>ANTT proprietário:</h2>
            <span>{ownerANTT}</span>
          </Item>
          <Item className="d-flex align-items-center gap-1">
            <h2>ANTT veículo:</h2>
            <span>{vehicleANTT}</span>
          </Item>
        </div>
      )}
    </div>
  );
};
