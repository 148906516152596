import { Card, Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";

export const PageTitle = styled(Card.Title)`
color: var(--color-gray-12);
font-weight: normal;
font-size: 1rem;
margin-bottom: 1rem;
`

export const CardContainer = styled(Card)`

@media(min-width: 1200px){
  max-height: 100%;
  overflow-y: auto;
}
  
`;

export const CardBodyContainer = styled(Card.Body)`

@media(min-width: 1200px){
  max-height: 50vh;
  overflow-y: auto;
}
  
`;



export const TravelMonitoringByStateContainer = styled.div`
 overflow-x: hidden;
`

export const FormLabelText = styled(Form.Label)`
color: var(--color-gray-12);
font-size: 0.9rem;
`

export const TravelListRow = styled(Row)`
max-height: 40vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 375px){
  max-height: 40vh;
 }

 @media(min-width: 425px){
  max-height: 50vh;
 }

 @media(min-width: 576px){
  max-height: 65vh;
 }

 

 @media(min-width: 1440px){
  max-height: 70vh;
  }

 
  @media(min-width: 2440px){
  max-height: 80vh;
  }`

export const ColumnCard = styled(Col)`
  @media(max-width: 1200px){
    height: 100%;
  }  
`