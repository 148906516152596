import { BsArrowClockwise, BsExclamationTriangle } from "react-icons/bs";
import ButtonLink from "../ButtonLink/ButtonLink";

export const ErrorCodeMessageCard = ({ handleNewConsult, consult }) => {
  return (
    <div className="p-3">
      <div className="d-flex align-items-center gap-1 text-danger">
        <span>
          <BsExclamationTriangle /> Refaça a consulta junto à receita federal
          antes de aprovar o cadastro do motorista
        </span>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <ButtonLink
          icon={<BsArrowClockwise size={15} />}
          label="Refazer consulta"
          buttoncolor="primary"
          handleClick={() => handleNewConsult(consult?.posicao)}
          fontSize="0.875rem"
        />
      </div>
    </div>
  );
};
