import styled from "styled-components";

export const WarningCard = styled.div`
  background-color:#FFFAFA;
  border-radius: 4px;
  padding: 10px;
  span{
    font-size: 1.1rem;
  }
`

export const ErrorTitle = styled.h1`
  font-size: 1.5rem;
  color: red;
  font-weight: bold;
`