import { useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { BiError } from "react-icons/bi";
import { AiOutlineFolderOpen } from "react-icons/ai";

import { convertPercentage } from "../../Utils/data";
import ValidationErrorMessage from "../ValidationErrorMessage/ValidationErrorMessage";

import {
  CardContainer,
  ImageProbabilityTitle,
  PageTitle,
  ProbabilityValue,
  ReactSpeedmeterContainer,
  Speedmeter,
  TitleContainer,
} from "./styles";

const BiometricValidationCard = ({
  biometricValidation,
  loadingBiometricValidation,
  driverValidation,
}) => {
  const hasError =
    driverValidation &&
    driverValidation?.validacao_biometria?.concluido === true &&
    (driverValidation?.validacao_biometria?.status === "2" ||
      driverValidation?.validacao_biometria?.status === null ||
      driverValidation?.validacao_biometria?.resposta?.message);

  const errorMessage = useMemo(() => {
    if (loadingBiometricValidation) {
      return "Realizando a validação da biometria";
    }

    if (hasError && driverValidation?.validacao_biometria?.resposta) {
      if (
        driverValidation?.validacao_biometria?.resposta?.message &&
        driverValidation?.validacao_biometria?.resposta?.message ===
          "Unexpected token E in JSON at position 0"
      ) {
        return "Serviço de DENATRAN indisponível";
      }

      if (
        driverValidation?.validacao_biometria?.resposta?.message &&
        driverValidation?.validacao_biometria?.resposta?.message !==
          "Unexpected token E in JSON at position 0"
      ) {
        return driverValidation?.validacao_biometria?.resposta?.message;
      }
    } else {
      return "Erro na validação da biometria";
    }
  }, [
    driverValidation?.validacao_biometria?.resposta,
    hasError,
    loadingBiometricValidation,
  ]);

  return (
    <CardContainer className="h-100 pb-4">
      <TitleContainer>
        <PageTitle className="d-flex justify-content-start mb-0">
          BIOMETRIA FACIAL
        </PageTitle>
      </TitleContainer>

      {biometricValidation &&
        biometricValidation?.foto_motorista &&
        !loadingBiometricValidation && (
          <>
            <ReactSpeedmeterContainer className="d-none d-md-flex justify-content-center">
              <Speedmeter
                customSegmentStops={[0, 32, 85, 93, 100]}
                segmentColors={["#b94a45", "#ff0f0f", "#6dd786", "#198754"]}
                currentValueText={`${
                  biometricValidation?.foto_motorista?.similaridade
                    ? convertPercentage(
                        biometricValidation?.foto_motorista?.similaridade
                      )
                    : 0
                }%`}
                value={
                  biometricValidation?.foto_motorista?.similaridade
                    ? convertPercentage(
                        biometricValidation?.foto_motorista?.similaridade
                      )
                    : 0
                }
                maxValue={100}
                labelFontSize="0.5rem"
                needleHeightRatio={0.5}
              />
            </ReactSpeedmeterContainer>

            <ImageProbabilityTitle className="text-center">
              Probabilidade da foto:
              <ProbabilityValue
                value={biometricValidation?.foto_motorista?.similaridade}
                className="ms-1"
              >
                {biometricValidation?.foto_motorista?.similaridade
                  ? convertPercentage(
                      biometricValidation?.foto_motorista?.similaridade
                    )
                  : 0}
                %
              </ProbabilityValue>
            </ImageProbabilityTitle>

            <ImageProbabilityTitle className="text-center">
              <ProbabilityValue
                value={biometricValidation?.foto_motorista?.similaridade}
              >
                {biometricValidation?.foto_motorista?.probabilidade}
              </ProbabilityValue>
            </ImageProbabilityTitle>
          </>
        )}
      {(!biometricValidation ||
        loadingBiometricValidation ||
        !biometricValidation?.foto_motorista) && (
        <>
          <ValidationErrorMessage
            icon={
              loadingBiometricValidation ? (
                <Spinner animation="border" variant="warning" size="sm" />
              ) : hasError ? (
                <BiError size={25} />
              ) : (
                <AiOutlineFolderOpen size={25} />
              )
            }
            label={errorMessage}
            hasError={hasError && !loadingBiometricValidation}
            text={hasError && "Ocorreu um erro na validação da biometria!"}
          />
        </>
      )}
    </CardContainer>
  );
};

export default BiometricValidationCard;
