import { IoWarningOutline } from "react-icons/io5";
import { SeeMoreButton, WarningCardBorder, WarningLabel } from "./styles";
import { IoIosArrowForward } from "react-icons/io";

export const WarningLinkCard = ({ label, id, noBorder, title }) => {
  const handleSeeMore = () => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <WarningCardBorder
      onClick={handleSeeMore}
      className={`rounded p-1 mb-2 w-100 d-lg-flex justify-content-between align-items-center ${
        noBorder && "border-0"
      }`}
    >
      <div className="gap-1 d-flex align-items-center">
        <IoWarningOutline color="red" size={15} />
        <WarningLabel>{label}</WarningLabel>
      </div>
      <SeeMoreButton to={id} title={title} onClick={handleSeeMore}>
        Ver mais <IoIosArrowForward />
      </SeeMoreButton>
    </WarningCardBorder>
  );
};
