import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ButtonLinkContainer = styled(Button)`
  transition: transform 200ms;
  text-decoration: none;
  padding: 7px 10px;
  color: ${(props) => props.color && props?.color} !important;

  font-size: ${(props) => props.fontSize};
  border-color: ${(props) => props?.color ? props?.color : props?.buttoncolor && props.buttoncolor};
  border: ${(props) =>
    props?.color ? "0.6px solid #b0b0b0" : props.buttoncolor ? 0 : props.borderColor ? 0 : "0.6px solid #b0b0b0"};

  background-color: ${(props) =>
    !props.buttoncolor
      ? ""
      : props.buttoncolor === "primary"
        ? "#2185D0"
        : props.buttoncolor === "secondary"
          ? "#b94a45"
          : props.buttoncolor === "success"
            ? "#07b141"
            : props.buttoncolor === "transparent"
              ? "#FFF" : props.buttoncolor === "whiteColor" ? "#FFF"
                : props.buttoncolor === "gray" ? "#6d757d" : "#c4c4c4"};

  &:hover {
    transform: scale(1.005);
    border: ${(props) =>
    props?.color ? "0.6px solid #b0b0b0" : props.buttoncolor ? 0 : props.borderColor ? 0 : "0.6px solid #b0b0b0"};

    background-color: ${(props) =>
    !props.buttoncolor
      ? ""
      : props.buttoncolor === "primary"
        ? "#2185D0"
        : props.buttoncolor === "secondary"
          ? "#b94a45"
          : props.buttoncolor === "success"
            ? "#07b141"
            : props.buttoncolor === "transparent"
              ? "#FFF" : props.buttoncolor === "whiteColor" ? "#FFF"
                : "#c4c4c4"};
  }

  &:disabled {
    border: ${(props) =>
    props?.color ? "0.6px solid #b0b0b0" : props.buttoncolor ? 0 : props.borderColor ? 0 : "0.6px solid #b0b0b0"};

    background-color: ${(props) =>
    !props.buttoncolor
      ? ""
      : props.buttoncolor === "primary"
        ? "#2185D0"
        : props.buttoncolor === "secondary"
          ? "#b94a45"
          : props.buttoncolor === "success"
            ? "#07b141"
            : props.buttoncolor === "transparent"
              ? "#FFF" : props.buttoncolor === "whiteColor" ? "#FFF"
                : "#c4c4c4"};
  }

  &:focus {
    transform: scale(1.01);
    border: ${(props) =>
    props?.color ? "0.6px solid #b0b0b0" : props.buttoncolor ? 0 : props.borderColor ? 0 : "0.6px solid #b0b0b0"};

    background-color: ${(props) =>
    !props.buttoncolor
      ? ""
      : props.buttoncolor === "primary"
        ? "#2185D0"
        : props.buttoncolor === "secondary"
          ? "#b94a45"
          : props.buttoncolor === "success"
            ? "#07b141"
            : props.buttoncolor === "transparent"
              ? "#FFF" : props.buttoncolor === "whiteColor" ? "#FFF"
                : "#c4c4c4"};
  }
`;
