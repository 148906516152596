import { Card, FormControl } from "react-bootstrap";
import styled from "styled-components";

export const BorderCard = styled(Card)`
  h3 {
    font-size: 1rem;
  }

  h4 {
    font-size: 0.7rem;
    color: #aaaaaa;
  }

  h5 {
    font-size: 0.7rem;
    color: #5f5f5f;

    span {
      font-size: 0.7rem;
    }
  }

  h6 {
    font-size: 0.7rem;
    color: #aeaeae;

    span {
      font-size: 0.7rem;
    }
  }
`;

export const CardBody = styled(Card.Body)`
  border-bottom: 1px solid var(--color-gray-10);
`;

export const CardHeader = styled(Card.Header)`
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding-bottom: 0px;

  > span {
    width: auto;
    color: var(--color-gray-12);
    font-weight: normal;
    font-size: 1rem;
  }

  @media (min-width: 425px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const CoverImage = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 1000px;
`;
export const FormControlInput = styled(FormControl)`
  font-size: 0.7rem;
  display: flex;
`;

