import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import InputMask, { ReactInputMask } from "react-input-mask";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { PageTitle } from "./styles";
import UploadCnhImageInput from "../../../Drivers/components/DriversForms/UploadCnhImageInput";
import UploadImageGuideCard from "../../../../../../Shared/Components/UploadImageGuideCard";
import { isCpfValid } from "../../../../../../Shared/Utils/data";
import { categoriesOptions } from "../../../../../../Shared/Utils/constants";

const DriverForm = ({
  register,
  driver,
  driverByCpf,
  errors,
  setValue,
  getDriverByCpf,
  handleShowModal,
  setCurrentImage,
  OCRImage,
  setImageCNHType,
}) => {
  const [cpf, setCpf] = useState(
    driver ? driver?.cpf : driverByCpf && driverByCpf?.cpf
  );

  const handleChangeCpf = useCallback(
    (event) => {
      const cpf = event.target.value.replace(/[^0-9]/g, "");
      setCpf(cpf);

      if (cpf.length > 10) {
        setValue("cpf", cpf);
        getDriverByCpf(cpf);
      }
    },
    [getDriverByCpf, setValue]
  );

  useEffect(() => {
    if (driverByCpf && Object.keys(driverByCpf).length !== 0) {
      driverByCpf?.nome && setValue("nome", driverByCpf?.nome);

      if (driverByCpf?.cnh || driverByCpf?.prontuario) {
        setValue("cnh", driverByCpf?.cnh || driverByCpf?.prontuario);
      }

      if (driverByCpf?.categoria_cnh_mot || driverByCpf?.cathabilitacao) {
        setValue(
          "categoria_cnh_mot",
          driverByCpf?.categoria_cnh_mot || driverByCpf?.cathabilitacao
        );
      }

      driverByCpf?.validadecarteira &&
        setValue(
          "dt_validade_cnh",
          moment(driverByCpf?.validadecarteira).format("YYYY-MM-DD")
        );

      driverByCpf?.dataemissaocnh &&
        setValue(
          "dt_emissao_cnh",
          moment(driverByCpf?.dataemissaocnh).format("YYYY-MM-DD")
        );

      driverByCpf?.dataprimeiracnh &&
        setValue(
          "dt_primeira_cnh",
          moment(driverByCpf?.dataprimeiracnh).format("YYYY-MM-DD")
        );
    }
  }, [driverByCpf, setValue, driver]);

  return (
    <Card.Body className="text-start">
      <Row xs={1} md={2} className="g-2 mb-4">
        <Form.Group as={Col} md={4} controlId="change-driver-cpf-input">
          <FloatingLabel controlId="change-driver-cpf-input" label="CPF">
            <Form.Control
              {...register("cpf", {
                required: driver ? false : "O CPF é obrigatório",
                validate: (value) => {
                  value = !driver && value.replace(/\D/g, "");
                  if (!driver && value.length !== 11) {
                    return "CPF inválido";
                  }
                  if (!driver && !isCpfValid(value)) {
                    return "CPF inválido";
                  }
                  return true;
                },
              })}
              size="sm"
              as={ReactInputMask}
              mask={driver ? null : "999.999.999-99"}
              maskChar={null}
              type="text"
              disabled={driver}
              className="is-required"
              placeholder="Digite o CPF"
              defaultValue={cpf}
              onChange={handleChangeCpf}
              data-testid="change-driver-cpf-input"
            />
            {errors?.cpf && (
              <Form.Text className="text-danger">
                {errors.cpf.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={8} controlId="formTelefone">
          <FloatingLabel controlId="formTelefone" label="Celular">
            <Form.Control
              {...register("numero_celular", {
                required: "O número de celular é obrigatório",
                validate: (value) => {
                  value = value.replace(/\D/g, "");
                  if (value.length !== 11) {
                    return "Número de celular inválido";
                  }
                  return true;
                },
              })}
              data-testid="driver-phone-number-input"
              size="sm"
              type="text"
              as={InputMask}
              mask="(99)99999-9999"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              className="is-required"
              placeholder="Digite o celular"
              defaultValue={
                driver
                  ? driver?.numero_celular
                  : driverByCpf && driverByCpf?.celular
              }
            />
            {errors?.numero_celular && (
              <Form.Text className="text-danger">
                {errors?.numero_celular?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={12} controlId="formNome">
          <FloatingLabel controlId="formNome" label="Nome completo">
            <Form.Control
              {...register("nome", {
                required: driver ? false : "O nome é obrigatório",
                validate: (value) => {
                  if (!/^[a-zA-Z ]+$/.test(value)) {
                    return "Nome inválido. Use apenas letras sem acento.";
                  }
                  return true;
                },
              })}
              data-testid="driver-name-input"
              size="sm"
              type="text"
              disabled={driver}
              placeholder="Digite o nome completo"
              className="is-required"
              defaultValue={
                driver ? driver?.nome : driverByCpf?.nome && driverByCpf?.nome
              }
            />
            {errors?.nome && (
              <Form.Text className="text-danger">
                {errors?.nome?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>
      </Row>

      <PageTitle className="mb-4">Informações CNH</PageTitle>

      <Row className="g-2">
        <Form.Group as={Col} xs={6} controlId="formCNH">
          <FloatingLabel controlId="formCNH" label="CNH">
            <Form.Control
              {...register("cnh", {
                required: "A CNH é obrigatória",
                validate: (value) => {
                  value = value.replace(/\D/g, "");
                  if (value.length !== 11) {
                    return "Número de CNH inválido";
                  }
                  return true;
                },
              })}
              data-testid="driver-cnh-input"
              as={InputMask}
              mask="99999999999"
              maskChar={null}
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              size="sm"
              type="text"
              placeholder="Escreva a CNH"
              className="is-required"
              defaultValue={
                driver
                  ? driver?.cnh
                  : driverByCpf
                  ? driverByCpf?.cnh || driverByCpf?.prontuario
                  : ""
              }
            />
            {errors?.cnh && (
              <Form.Text className="text-danger">
                {errors?.cnh?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} xs={6} controlId="formRegistroCNH">
          <FloatingLabel
            controlId="formRegistroCNH"
            label="Número de registro da CNH"
          >
            <Form.Control
              {...register("numero_registro_cnh", {
                required: "O número de registro é obrigatório",
              })}
              data-testid="driver-cnh-register-number-input"
              as={InputMask}
              mask="99999999999"
              maskChar={null}
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              size="sm"
              type="text"
              placeholder="Escreva o número de registro da CNH"
              className="is-required"
              defaultValue={driver?.numero_registro_cnh}
            />
            {errors?.numero_registro_cnh && (
              <Form.Text className="text-danger">
                {errors?.numero_registro_cnh?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} xs={12} md={6} controlId="formLicenceCategory">
          <FloatingLabel controlId="formLicenceCategory" label="Categoria CNH">
            <Form.Select
              {...register("categoria_cnh_mot", {
                required: "A categoria é obrigatória",
                validate: (value) =>
                  value !== "Selecione a categoria" ||
                  "Selecione uma categoria",
              })}
              data-testid="driver-cnh-category-input"
              size="sm"
              type="text"
              className="is-required"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              defaultValue={
                driver
                  ? driver?.categoria_cnh_mot
                  : driverByCpf &&
                    (driverByCpf?.categoria_cnh_mot ||
                      driverByCpf?.cathabilitacao)
              }
            >
              <option>Selecione a categoria</option>
              {categoriesOptions.map((category) => (
                <option value={category?.label} key={category?.id}>
                  {category?.label}
                </option>
              ))}
            </Form.Select>
            {errors?.categoria_cnh_mot && (
              <Form.Text className="text-danger">
                {errors?.categoria_cnh_mot?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} xs={12} md={6} controlId="formValidateCNH">
          <FloatingLabel controlId="formValidateCNH" label="Validade CNH">
            <Form.Control
              {...register("dt_validade_cnh", {
                required: "A data de validade é obrigatória",
              })}
              data-testid="driver-cnh-validate-input"
              size="sm"
              type="date"
              className="is-required"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              defaultValue={
                driver
                  ? moment.utc(driver?.dt_validade_cnh).format("YYYY-MM-DD")
                  : driverByCpf &&
                    moment
                      .utc(driverByCpf?.validadecarteira)
                      .format("YYYY-MM-DD")
              }
            />
            {errors?.dt_validade_cnh && (
              <Form.Text className="text-danger">
                {errors?.dt_validade_cnh?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} xs={12} md={6} controlId="formEmissaoCNH">
          <FloatingLabel controlId="formEmissaoCNH" label="Emissão CNH">
            <Form.Control
              {...register("dt_emissao_cnh", {
                required: "A data de emissão é obrigatória",
              })}
              data-testid="driver-cnh-emission-input"
              size="sm"
              type="date"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              className="is-required"
              defaultValue={
                driver
                  ? moment.utc(driver?.dt_emissao_cnh).format("YYYY-MM-DD")
                  : driverByCpf &&
                    moment.utc(driverByCpf?.dataemissaocnh).format("YYYY-MM-DD")
              }
            />
            {errors?.dt_emissao_cnh && (
              <Form.Text className="text-danger">
                {errors?.dt_emissao_cnh?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} xs={12} md={6} controlId="formFirstCNH">
          <FloatingLabel controlId="formFirstCNH" label="Primeira CNH">
            <Form.Control
              {...register("dt_primeira_cnh", {
                required: "A data da primeira CNH é obrigatória",
              })}
              data-testid="driver-cnh-first-date-input"
              size="sm"
              type="date"
              className="is-required"
              disabled={
                driver?.status_cadastro === "2r" ||
                driver?.status_cadastro === "7r"
              }
              defaultValue={
                driver
                  ? moment.utc(driver?.dt_primeira_cnh).format("YYYY-MM-DD")
                  : driverByCpf &&
                    moment
                      .utc(driverByCpf?.dataprimeiracnh)
                      .format("YYYY-MM-DD")
              }
            />
            {errors?.dt_primeira_cnh && (
              <Form.Text className="text-danger">
                {errors?.dt_primeira_cnh?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Col xs={6} className="mt-3">
          <UploadCnhImageInput
            setValue={setValue}
            errors={errors}
            driver={driver}
            width="10.4rem"
            height="10.4rem"
            handleShowModal={handleShowModal}
            setCurrentImage={setCurrentImage}
            align="start"
            register={register}
            OCRImage={OCRImage}
            setImageCNHType={setImageCNHType}
          />
        </Col>
        <Col md={6} className="mt-3">
          <UploadImageGuideCard />
        </Col>
      </Row>
    </Card.Body>
  );
};

export default DriverForm;
