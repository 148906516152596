import { Card, Col, Row, Tabs } from "react-bootstrap";
import ReactSpeedometer from "react-d3-speedometer";
import { RiInformationLine } from "react-icons/ri";
import styled from "styled-components";

export const CardContainer = styled(Card)`
  background-color: #FFFAFA;
  border: none;
`

export const TitleContainer = styled.div`
  background-color: var(--color-gray-2);
  padding: 10px;


`

export const BorderCard = styled(Card)`
  min-height: 100%;


  h3 {
    font-size: 1rem;
    font-weight: normal;
  }

  h4 {
    font-size: 0.7rem;
    color: var(--color-gray-11);
  }

  h5 {
    font-size: 0.7rem;
    color: var(--color-gray-12);

    span {
      font-size: 0.7rem;
    }
  }

  h6 {
    font-size: 0.6rem;
    color: var(--color-gray-11);

    span {
      font-size: 0.6rem;
    }
  }
`;

export const Item = styled.div`
  padding: 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  h4 {
    font-size: 1rem;
    color: black;
    display: flex;
    margin-left: 0.2rem;
    margin-bottom: 0;
    text-align: left;
   
  }

  h3 {
    font-size: 1rem;
    display: flex;
    margin-bottom: 0;
    color: var(--color-blue-90);
    text-align: left;
  
  }

  span {
    font-size: 1rem;
    color: var(--color-green);
  
  }
`;

export const InformationIcon = styled(RiInformationLine)`
  color: var(--color-blue-70);
  size: 1rem;
`;

export const ImageProbabilityTitle = styled.h1`
  font-size: 0.75rem;
`;

export const PageTitle = styled(Card.Title)`
color:var(--color-blue-90);
  font-weight: bold;
  font-size: 1rem;
`;

export const LicenceInformationCardContainer = styled.div`
  margin-left: -0.45rem;
`;

export const TravelsHistoryRow = styled(Row)`
  overflow-x: hidden;
  max-height: 95vh;
`;

export const ReactSpeedmeterContainer = styled.div`
  height: 12.5rem;
`;

export const Speedmeter = styled(ReactSpeedometer)`
  height: 200px;
`;

export const ProbabilityValue = styled.span`
  color: ${(props) => (props.value > 0.5 ? "#07b141" : "#FF5A5F")} !important;
`;

export const TravelsHistoryBorderCard = styled(Card)`
  transition: transform 300ms;
  &:hover {
    transform: scale(1.01);
    z-index: 1;
    box-shadow: 0 0 5px var(--color-gray);
  }

  a {
    text-decoration: none;
  }

  h6 {
    font-size: 0.6rem;
    color: var(--color-gray-11);

    span {
      font-size: 0.6rem;
    }
  }
`;

export const TravelsHistoryCardBody = styled(Card.Body)`
  transition: transform 300ms;
  &:hover {
    z-index: 1;
    box-shadow: 0 0 5px var(--color-gray);
  }

  border-bottom: 1px solid var(--color-gray-10);

  a {
    text-decoration: none;
  }
`;

export const Title = styled.h1`
  font-size: 0.7rem;
  color: var(--color-gray-15);
  font-weight: 700;
`;

export const Token = styled.span`
  font-size: 0.7rem;
  font-weight: normal;
`;

export const Column = styled(Col)`
padding-bottom: 10px;
`;

export const DriverRow = styled(Row)`

  max-height: 70vh;
  flex: 1;
  overflow-x: hidden;

  @media (min-width: 1640px) {
    max-height: 75vh;
  } 
  @media (min-width: 2000px) {
    max-height: 81vh;
  } 
  @media (min-width: 3000px) {
    max-height: 85vh;
  } 

 
`;

export const InfoTitle = styled.span`
  color: var(--color-gray-15);
  font-weight: normal;
  font-size: 0.875rem;
  margin-bottom: 6px;
`;

export const InfoItem = styled.span`
  color: ${(props) =>
    props.status === "placa"
      ? "#0071c7"
      : props.status === "ATIVO"
        ? "#198754"
        : props.status === "Requer atenção"
          ? "#C3A300"
          : "#4d4d4d"};
  font-weight: normal;
  font-size: 0.875rem;
  margin-bottom: 0;

  text-transform: ${(props) =>
    props.status === "ATIVO" ? "capitalize" : "initial"};

`;

export const InfomationItem = styled.span`
  color: ${(props) =>
    props.status === "placa"
      ? "#0071c7"
      : props.status === "ATIVO"
        ? "#198754"
        : props.status === "Requer atenção"
          ? "#C3A300"
          : "#4d4d4d"};
  font-weight: normal;
  font-size: 0.65rem;
  margin-bottom: 0;

  @media (min-width: 2000px) {
    font-size: 0.75rem;
  }
`;

export const InfoBorderCard = styled(Card)`
  border: dashed 0.6px var(--color-gray-11);
`;

export const InfoImg = styled.div`
  max-height: ${(props) =>
    props.type === "truck"
      ? "30px"
      : props.type === "vehicle"
        ? "35px"
        : "35px"};

  max-width: ${(props) =>
    props.type === "truck"
      ? "30px"
      : props.type === "vehicle"
        ? "35px"
        : "35px"};

  > img {
    object-fit: contain;
    width: 95%;
    height: 95%;
  }

  @media (min-width: 768px) {
    max-height: ${(props) =>
    props.type === "truck"
      ? "38px"
      : props.types === "vehicle"
        ? "50px"
        : "50px"};

    max-width: ${(props) =>
    props.type === "truck"
      ? "38px"
      : props.type === "vehicle"
        ? "50px"
        : "50px"};

    margin: ${(props) => (props.type === "truck" ? "9.5px 0px" : "0px")};
  }

  @media (min-width: 1024px) {
    max-height: ${(props) =>
    props.type === "truck"
      ? "44px"
      : props.types === "vehicle"
        ? "50px"
        : "50px"};
    max-width: ${(props) =>
    props.type === "truck"
      ? "44px"
      : props.type === "vehicle"
        ? "50px"
        : "50px"};
  }
`;

export const DetailsBtn = styled.button`
  width: fit-content;
  padding: 0;
  border: none;
  background: none;
  background-color: transparent;

  color: #4d4d4d;
  font-weight: normal;
  font-size: 0.55rem;
  text-decoration: underline;
  margin-bottom: 0;

  @media (min-width: 425px) {
    font-size: 0.65rem;
  }

  @media (min-width: 1024px) {
    font-size: 0.8rem;
  }
`;

export const CloseDetailsBtn = styled.button`
  max-width: 179px;
  padding: 5px 35px;
  border: 1px solid #4d4d4d;
  color: #4d4d4d;
  background-color: transparent;
  border-radius: 4px;
  font-size: 0.93rem;
  line-height: 20px;
`;

export const PageType = styled.p`
  color: #040404cc;
  font-size: 0.93rem;
`;

export const StyledTabs = styled(Tabs)`

  .nav-link {
    color: black; 
    font-size: 0.9rem;
  }

  .nav-link.active {
    color: inherit; 
  }

  .nav-link:not(.active) {
    color: var(--color-blue-80)
  }
`;

export const Label = styled.span`
  font-size: 1rem;
`;

export const SimilaryCard = styled.div`
  border: 1px solid var(--color-gray-2);
  padding: 10px;
  border-radius: 4px;
`