import styled from "styled-components";
import { Row } from "react-bootstrap";
import { HiViewList } from "react-icons/hi";

export const ListIcon = styled(HiViewList)`
margin-bottom: 1rem;
margin-left: auto;
font-size: 2rem
`


export const RowContainer = styled(Row)`
  max-height: 70vh;
  flex: 1;
  overflow-x: hidden;

  @media (min-width: 1640px) {
    max-height: 75vh;
  } 
  @media (min-width: 2000px) {
    max-height: 81vh;
  } 
  @media (min-width: 3000px) {
    max-height: 85vh;
  }  
`;