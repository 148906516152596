import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormLabelText } from "./styles";

const ActiveAndBlockForm = ({
  vehicle,
  register,
  setValue,
  truck,
  defaultTruck,
  isEdit,
}) => {
  const [ativo, setAtivo] = useState(
    defaultTruck ? defaultTruck?.ativo : vehicle ? vehicle?.ativo : false
  );

  const [admBlock, setAdmBlock] = useState(
    vehicle && vehicle?.bloqueado_adm === "S" ? true : false
  );

  const handleAdmBlockChange = () => {
    if (admBlock === false) {
      if (truck) {
        setValue(`${truck}.ativo`, false);
      } else {
        setValue("ativo", false);
      }

      setAtivo(false);
    }

    setAdmBlock(!admBlock);
  };

  const handleActiveChange = () => {
    if (ativo === false) {
      setValue("bloqueado_adm", false);

      setAdmBlock(false);
    }

    setAtivo(!ativo);
  };

  return (
    <Row className="text-start g-3 mt-1">
      <Form.Group
        as={Col}
        md={3}
        xl={2}
        className="d-flex align-items-center gap-2 mb-3"
        controlId="formAtivo"
      >
        <FormLabelText className="mb-0">Liberado</FormLabelText>
        <Form.Check
          {...register(truck ? `${truck}.ativo` : "ativo")}
          type="switch"
          id="custom-switch"
          disabled={(defaultTruck || vehicle) && !isEdit}
          checked={ativo}
          onChange={handleActiveChange}
        />
      </Form.Group>

      {!truck && (
        <Form.Group
          as={Col}
          md={3}
          xl={2}
          className="d-flex align-items-center gap-2 mb-4"
          controlId="formAtivo"
        >
          <FormLabelText className="mb-0">Bloqueio ADM</FormLabelText>
          <Form.Check
            {...register("bloqueado_adm")}
            type="switch"
            disabled={(defaultTruck || vehicle) && !isEdit}
            id="custom-switch"
            checked={admBlock}
            onChange={handleAdmBlockChange}
          />
        </Form.Group>
      )}
    </Row>
  );
};

export default ActiveAndBlockForm;
