import { Button, Card, Form, Row } from "react-bootstrap";
import { HiOutlineTruck, HiOutlineUserGroup, HiUserGroup } from "react-icons/hi";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const BorderCard = styled(Card)`
min-height: 100%;

  h3 {
    font-size: 1rem;
    font-weight: normal;

  }

  h4{
    font-size: 0.7rem;
    color: var(--color-gray-11);
  }

  h5{
    font-size: 0.7rem;
    color: var(--color-gray-12);

    span {
      font-size: 0.7rem;
    }
  }

  h6{
    font-size: 0.6rem;
    color: var(--color-gray-11);

    span {
      font-size: 0.6rem;
    }
  }
`

export const CardBody = styled(Card.Body)`
border-bottom: 1px solid var(--color-gray-10);
`

export const CoverImage = styled.div`
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  background-image: ${props => `url(${props.emptyImage})`};
  background-size: cover;
  background-position: center center;
  border-radius: 1000px;
`

export const IconLink = styled(Link)`
background-color: var(--color-blue-70);
border-radius: 3px;
padding: 3px;
 transition: transform 300ms;
 &:hover {
    transform: scale(1.1);
    z-index: 1;
  }
  
`

export const IconButton = styled(Button)`
background-color: var(--color-blue-70);
border-radius: 3px;
padding: 3px;
 transition: transform 300ms;
 &:hover {
background-color: var(--color-blue-70);
    transform: scale(1.1);
    z-index: 1;
  }
  
`

export const StatisticDriverItem = styled.h3`
color: var(--color-blue-80);
font-size: 1rem;

@media (min-width: 2400px) {
    font-size: 1.5rem;
  }
`

export const StatisticDriverValue = styled.h1`
color: var(--color-gray-11);
font-size: 2rem;
`

export const PageTitle = styled(Card.Title)`
color: var(--color-gray-12);
font-weight: normal;
font-size: 1rem;
`

export const FormLabel = styled(Form.Label)`
color: var(--color-gray-15);
font-weight: normal;
font-size: 0.7rem;
`

export const DriverIcon = styled(HiOutlineUserGroup)`
font-size: 2rem;
margin-bottom: 1rem;
margin-left: auto;
`
export const OwnerIcon = styled(HiUserGroup)`
font-size: 2rem;
margin-bottom: 1rem;
margin-left: auto;
`
export const VehicleIcon = styled(HiOutlineTruck)`
font-size: 2rem;
margin-bottom: 1rem;
margin-left: auto;
`

export const RegistrationManagementRow = styled(Row)`
 max-height: 50vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 425px){
  max-height: 65vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }

 @media(min-width: 1440px){
 
  max-height: 80vh;
  }

  @media(min-width: 1640px){

  max-height: 75vh;
  }
  @media(min-width: 2440px){

  max-height: 80vh;
  }
`