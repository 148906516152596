import { Form, Table } from "react-bootstrap";
import { useCallback } from "react";
import { AccessListContainer, TableTitle } from "./styles";

const AccessSettingsList = ({
  permissionsByScope,
  currentPerfilId,
  perfilActions,
  getPerfilActions,
  getPermissionsByScope,
  currentScope,
  postPermissions,
}) => {
  const removePermission = (action, crud) => {
    const acoesArray = action ? JSON.parse(action.acoes) : [];

    const index = acoesArray.indexOf(crud);
    if (index !== -1) {
      acoesArray.splice(index, 1);
    }

    return acoesArray;
  };

  const addPermission = (action, crud) => {
    const acoesArray = action ? JSON.parse(action.acoes) : [];
    if (acoesArray.indexOf(crud) === -1) {
      acoesArray.push(crud);
    }

    return acoesArray;
  };

  const action = useCallback(
    (permission) => {
      return perfilActions?.length > 0 || perfilActions !== "[]"
        ? perfilActions.find((action) => action.id_permissao === permission.id)
        : true;
    },
    [perfilActions]
  );

  const currentAction = useCallback(
    (permission, crud) => {
      return perfilActions?.length > 0 ||
        perfilActions !== "[]" ||
        perfilActions !== undefined
        ? action(permission)?.acoes !== undefined
          ? JSON.parse(action(permission)?.acoes).includes(crud)
          : false
        : false;
    },
    [action, perfilActions]
  );

  const handleChange = async (permission, crud) => {
    const current = currentAction(permission, crud);
    const acoesRequest = current
      ? removePermission(action(permission), crud)
      : addPermission(action(permission), crud);

    postPermissions({
      id_permissao: permission?.id,
      id_perfil: currentPerfilId,
      acoes: acoesRequest,
    });

    setTimeout(() => {
      getPerfilActions(currentPerfilId);
      getPermissionsByScope(currentScope);
    }, 100);
  };

  return (
    <AccessListContainer data-testid="access-settings-list-container">
      <Form>
        {permissionsByScope.length > 0 && (
          <Table hover className="border mb-0">
            <thead>
              <TableTitle>
                <th className="px-3">Permissões</th>
                <th className="text-center">Acessar</th>
                <th className="text-center">Inserir</th>
                <th className="text-center">Alterar</th>
                <th className="text-center">Remover</th>
              </TableTitle>
            </thead>
            <tbody>
              {Array.isArray(permissionsByScope) &&
                permissionsByScope.length > 0 &&
                permissionsByScope.map((permission) => (
                  <tr key={permission.id}>
                    <td className="px-3">{permission?.label}</td>
                    <td className="text-center">
                      <Form.Group>
                        <Form.Check
                          type="switch"
                          data-testid={`access-${permission.id}-R`}
                          defaultChecked={currentAction(permission, "R")}
                          onChange={() => handleChange(permission, "R")}
                          disabled={
                            !localStorage.getItem(
                              "accessManagementPermissionToUpdate"
                            ) ||
                            localStorage.getItem(
                              "accessManagementPermissionToUpdate"
                            ) === "false"
                          }
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group>
                        <Form.Check
                          type="switch"
                          data-testid={`access-${permission.id}-C`}
                          defaultChecked={currentAction(permission, "C")}
                          onChange={() => handleChange(permission, "C")}
                          disabled={
                            !localStorage.getItem(
                              "accessManagementPermissionToUpdate"
                            ) ||
                            localStorage.getItem(
                              "accessManagementPermissionToUpdate"
                            ) === "false"
                          }
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group>
                        <Form.Check
                          type="switch"
                          data-testid={`access-${permission.id}-U`}
                          defaultChecked={currentAction(permission, "U")}
                          onChange={() => handleChange(permission, "U")}
                          disabled={
                            !localStorage.getItem(
                              "accessManagementPermissionToUpdate"
                            ) ||
                            localStorage.getItem(
                              "accessManagementPermissionToUpdate"
                            ) === "false"
                          }
                        />
                      </Form.Group>
                    </td>
                    <td className="text-center">
                      <Form.Group>
                        <Form.Check
                          type="switch"
                          data-testid={`access-${permission.id}-D`}
                          defaultChecked={currentAction(permission, "D")}
                          onChange={() => handleChange(permission, "D")}
                          disabled={
                            !localStorage.getItem(
                              "accessManagementPermissionToUpdate"
                            ) ||
                            localStorage.getItem(
                              "accessManagementPermissionToUpdate"
                            ) === "false"
                          }
                        />
                      </Form.Group>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </Form>
    </AccessListContainer>
  );
};

export default AccessSettingsList;
