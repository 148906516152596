import React, { useCallback } from "react";
import { Table } from "react-bootstrap";
import { FiEdit2 } from "react-icons/fi";

import UserImage from "../../../../Assets/Images/user.png";
import { useNavigate } from "react-router-dom";
import { ImageContent, UserContainer, UsersTableTitle } from "./styles";
import { ActiveInput } from "../../../../Shared/Components/ActiveInput/ActiveInput";
import { ActionIconButton } from "../../../../Shared/Components/ActionIconButton";
import { FaTrashAlt } from "react-icons/fa";

const UserList = ({
  users,
  getUser,
  updateUser,
  perfis,
  handleDeleteUnit,
  unitManagementPermissionToUpdate,
  unitManagementPermissionToDelete,
}) => {
  const navigateTo = useNavigate();

  const handleEditUnit = useCallback(
    async (id) => {
      getUser(id);

      return navigateTo(`/usuarios-atualizar/${id}`);
    },
    [navigateTo, getUser]
  );

  return (
    <UserContainer>
      <Table hover size="sm" className="border rounded mb-0">
        <thead>
          <UsersTableTitle>
            <th className="px-3">Foto</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Perfil</th>
            <th className="text-center">Inativo / Ativo</th>
            {((unitManagementPermissionToUpdate &&
              unitManagementPermissionToUpdate !== "false") ||
              (unitManagementPermissionToDelete &&
                unitManagementPermissionToDelete !== "false")) && (
              <th className="text-center">Ações</th>
            )}
          </UsersTableTitle>
        </thead>
        <tbody>
          {users.length > 0 &&
            users.map((user) => (
              <tr key={user.id}>
                <td className="text-center">
                  <ImageContent
                    className="px-3"
                    src={user.foto ? user.foto : UserImage}
                  />
                </td>
                <td>{user.nome}</td>
                <td>{user.email}</td>
                <td>
                  {perfis.find((perfil) => perfil.id === user.id_perfil)?.nome}
                </td>
                <td className="text-center">
                  <ActiveInput
                    value={user}
                    updateValue={updateUser}
                    disabledInput={
                      !unitManagementPermissionToUpdate ||
                      unitManagementPermissionToUpdate === "false"
                    }
                  />
                </td>
                <td>
                  <div className="d-flex justify-content-center gap-1">
                    {unitManagementPermissionToUpdate &&
                      unitManagementPermissionToUpdate !== "false" && (
                        <ActionIconButton
                          title="Editar"
                          handleClick={() => handleEditUnit(user.id)}
                          icon={<FiEdit2 size={15} color="#5f5f5f" />}
                        />
                      )}
                    {unitManagementPermissionToDelete && (
                      <ActionIconButton
                        title="Deletar"
                        handleClick={() => handleDeleteUnit(user)}
                        icon={<FaTrashAlt size={13} color="#cb1313" />}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </UserContainer>
  );
};

export default UserList;
