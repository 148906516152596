import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { ComeBackButton, LinkButton, Title } from "./styles";

const LoginBreadcrumb = ({ title }) => {
  return (
    <div className="px-1 mb-4">
      <div className="d-flex">
        {title !== "Login" && (
          <LinkButton
            to={"/"}
            size="sm"
            className="d-flex align-items-center gap-1"
          >
            <IoIosArrowBack />
            <ComeBackButton>{"Voltar para login"}</ComeBackButton>
          </LinkButton>
        )}
      </div>
      {<Title className="color-blue-dark">{title}</Title>}
    </div>
  );
};

export default LoginBreadcrumb;
