import { Image } from "react-bootstrap";
import rodovivaLogo from "../../../../Assets/Images/Logo.png";
import { Alert } from "./styles";
import { useEffect } from "react";

export const Header = ({ progressIndicator, hashManifest }) => {
  const amount =
    hashManifest &&
    hashManifest?.conhecimentos &&
    hashManifest?.conhecimentos.length > 0
      ? hashManifest?.conhecimentos.filter(
          (manifest) => manifest?.conhec_docs_anexados.length !== 0
        ).length
      : 0;

  return (
    <div className="pt-3 mb-2 d-flex justify-content-between gap-1 align-items-center">
      <Image id="logo-login" src={rodovivaLogo} width="70rem" />

      {hashManifest &&
        hashManifest?.conhecimentos &&
        hashManifest?.conhecimentos.length > 0 && (
          <div className="d-flex">
            {amount === hashManifest?.conhecimentos.length ? (
              <Alert
                variant={
                  progressIndicator < 30
                    ? "danger"
                    : progressIndicator < 70
                    ? "warning"
                    : "success"
                }
              >
                Concluído
              </Alert>
            ) : (
              <Alert
                variant={
                  progressIndicator < 30
                    ? "danger"
                    : progressIndicator < 70
                    ? "warning"
                    : "success"
                }
              >
                {amount !== hashManifest?.conhecimentos.length && amount > 1
                  ? "Documentos"
                  : "Documento"}{" "}
                {amount} / {hashManifest?.conhecimentos.length}
              </Alert>
            )}
          </div>
        )}
    </div>
  );
};
