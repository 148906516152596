import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { FormLabelText } from "./styles";
import {
  agregadoTypes,
  statusRNTRC,
  taxationTypes,
} from "../../../../../../Shared/Utils/constants";
import { SubmitButton } from "../../../../../../Shared/Components/SubmitButton/SubmitButton";
import ButtonLink from "../../../../../../Shared/Components/ButtonLink/ButtonLink";
import ReactInputMask from "react-input-mask";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { PageTitle } from "./PageTitle";

export const CarrierRegistration = ({
  owner,
  ownerByCpfCnpj,
  register,
  isSubmitting,
  setValue,
  errors,
  isCpf,
  isSAT,
}) => {
  const [ativo, setAtivo] = useState(owner?.ativo ? owner?.ativo : false);
  const [cellphoneNumber, setCellfoneNumber] = useState("");
  const [isCellphone, setIsCellphone] = useState(false);
  const [rntrc, setRntrc] = useState("");
  const [taxationType, setTaxationType] = useState("");
  const [agregado, setAgregado] = useState("");
  const [cei, setCei] = useState("");

  const [admBlock, setAdmBlock] = useState(
    owner?.bloqueado_adm === "S" ? true : false
  );

  const currentTaxationType = useMemo(
    () =>
      owner?.tipo_tributacao
        ? owner?.tipo_tributacao
        : ownerByCpfCnpj?.tipotributacao &&
          isSAT &&
          ownerByCpfCnpj?.tipotributacao,
    [isSAT, owner?.tipo_tributacao, ownerByCpfCnpj?.tipotributacao]
  );

  const currentRntrc = useMemo(
    () =>
      owner?.rntrc
        ? owner?.rntrc
        : ownerByCpfCnpj?.rntrc && isSAT && ownerByCpfCnpj?.rntrc,
    [isSAT, owner?.rntrc, ownerByCpfCnpj?.rntrc]
  );

  const statusRntrcValue = useMemo(() => {
    if (owner?.status_rntrc) {
      return owner?.status_rntrc;
    }

    if (ownerByCpfCnpj?.statusrntrc && isSAT) {
      if (ownerByCpfCnpj?.statusrntrc === statusRNTRC[0]?.value) {
        return statusRNTRC[0]?.value;
      }

      if (ownerByCpfCnpj?.statusrntrc === statusRNTRC[1]?.value) {
        return statusRNTRC[1]?.value;
      }

      if (ownerByCpfCnpj?.statusrntrc === statusRNTRC[2]?.value) {
        return statusRNTRC[2]?.value;
      }

      if (ownerByCpfCnpj?.statusrntrc === statusRNTRC[3]?.value) {
        return statusRNTRC[3]?.value;
      }
    }
  }, [isSAT, owner?.status_rntrc, ownerByCpfCnpj?.statusrntrc]);

  const foneNumber = useMemo(() => {
    if (owner?.numero_celular) {
      if (owner?.numero_celular === 11) {
        setIsCellphone(true);
      }

      if (owner?.numero_celular === 10) {
        setIsCellphone(false);
      }

      return owner?.numero_celular;
    }

    if (isSAT && ownerByCpfCnpj?.celular) {
      let number = ownerByCpfCnpj?.celular;

      if (ownerByCpfCnpj?.celular[0] === "0") {
        number = number.substring(1);
      }

      if (number.length <= 10) {
        setIsCellphone(false);
        return number;
      }

      if (number.length > 10) {
        setIsCellphone(true);
        return number;
      }
    }

    return;
  }, [isSAT, owner?.numero_celular, ownerByCpfCnpj?.celular]);

  const currentAgregado = useMemo(
    () =>
      owner?.agregado
        ? owner?.agregado
        : ownerByCpfCnpj?.agregado && isSAT && ownerByCpfCnpj?.agregado,
    [isSAT, owner?.agregado, ownerByCpfCnpj?.agregado]
  );

  const currentCei = useMemo(
    () =>
      owner?.cei
        ? owner?.cei
        : ownerByCpfCnpj?.cei && isSAT && ownerByCpfCnpj?.cei,
    [isSAT, owner?.cei, ownerByCpfCnpj?.cei]
  );

  useEffect(() => {
    if (currentTaxationType) {
      setTaxationType(currentTaxationType);
    }
  }, [currentTaxationType]);

  useEffect(() => {
    if (currentRntrc) {
      setRntrc(currentRntrc);
    }
  }, [currentRntrc]);

  useEffect(() => {
    if (foneNumber) {
      setCellfoneNumber(foneNumber);
    }
  }, [foneNumber]);

  useEffect(() => {
    if (currentAgregado) {
      setAgregado(currentAgregado);
    }
  }, [currentAgregado]);

  useEffect(() => {
    if (currentCei) {
      setCei(currentCei);
    }
  }, [currentCei]);

  const handleChangeRntrc = useCallback(
    (event) => {
      setValue("rntrc", event.target.value);
      setRntrc(event.target.value);
    },
    [setValue]
  );

  const handleAdmBlockChange = () => {
    if (admBlock === false) {
      setValue("ativo", false);
      setAtivo(false);
    }

    setAdmBlock(!admBlock);
  };

  const handleActiveChange = () => {
    if (ativo === false) {
      setValue("bloqueado_adm", false);
      setAdmBlock(false);
    }

    setAtivo(!ativo);
  };

  const handleChangeTaxationType = useCallback(
    (event) => setTaxationType(event.target.value),
    []
  );

  const handleChangePhoneNumber = useCallback(
    (event) => {
      if (event.target.value.length > 13) {
        setIsCellphone(true);
      }
      setValue("numero_celular", event.target.value);
      setCellfoneNumber(event.target.value);
    },
    [setValue]
  );

  const handleChangeAgregado = useCallback(
    (event) => setAgregado(event.target.value),
    []
  );

  const handleChangeCei = useCallback(
    (event) => setCei(event.target.value),
    []
  );

  useEffect(() => {
    if ((isSAT && ownerByCpfCnpj) || owner) {
      rntrc && setValue("rntrc", rntrc);
      cellphoneNumber && setValue("numero_celular", cellphoneNumber);
      taxationType && setValue("tipo_tributacao", taxationType);
      agregado && setValue("agregado", agregado);
      agregado && setValue("cei", cei);
    }
  }, [
    agregado,
    cei,
    cellphoneNumber,
    isSAT,
    owner,
    ownerByCpfCnpj,
    rntrc,
    setValue,
    statusRntrcValue,
    taxationType,
  ]);
  return (
    <>
      <PageTitle label="Informações complementares" />
      <Row xs={1} className="g-2 mb-4 mt-2">
        <Form.Group as={Col} md={12} controlId="formRntrc">
          <FloatingLabel controlId="formRntrc" label="RNTRC / ANTT">
            <Form.Control
              {...register("rntrc", {
                required: isCpf ? false : "A RNTRC / ANTT é obrigatória",
              })}
              size="sm"
              type="text"
              as={ReactInputMask}
              mask="999999999999999999999999999999"
              maskChar={null}
              className={!isCpf && "is-required"}
              onChange={handleChangeRntrc}
              placeholder="Digite o RNTRC / ANTT"
              value={rntrc}
            />
            {errors?.rntrc && (
              <Form.Text className="text-danger">
                {errors?.rntrc?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formValidadeRNTRC">
          <FloatingLabel
            controlId="formValidadeRNTRC"
            label="Data de validade do RNTRC"
          >
            <Form.Control
              {...register("dt_validade_rntrc", {
                required: isCpf
                  ? false
                  : "A data de validade da RNTRC é obrigatória.",
              })}
              size="sm"
              type="date"
              className={!isCpf && "is-required"}
              defaultValue={
                owner?.dt_validade_rntrc &&
                moment.utc(owner?.dt_validade_rntrc).format("YYYY-MM-DD")
              }
            />
            {errors?.dt_validade_rntrc && (
              <Form.Text className="text-danger">
                {errors?.dt_validade_rntrc?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formStatusRNTRC">
          <FloatingLabel controlId="formStatusRNTRC" label="Status RNTRC">
            <Form.Select
              {...register("status_rntrc", {
                required: isCpf ? false : "O status da RNTRC é obrigatório",
              })}
              size="sm"
              type="text"
              className={!isCpf && "is-required"}
              defaultValue={statusRntrcValue}
            >
              <option value="">Selecione o status RNTRC</option>
              {statusRNTRC.map((statusRNTRC) => (
                <option value={statusRNTRC.value} key={statusRNTRC.id}>
                  {statusRNTRC.label}
                </option>
              ))}
            </Form.Select>
            {errors?.status_rntrc && (
              <Form.Text className="text-danger">
                {errors?.status_rntrc?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formCei">
          <FloatingLabel controlId="formCei" label="CEI / PIS(INSS)">
            <Form.Control
              {...register("cei", {
                required: isCpf ? "O CEI / PIS(INSS) é obrigatório" : false,
              })}
              size="sm"
              type="text"
              className={isCpf && "is-required"}
              onChange={handleChangeCei}
              placeholder="Digite o CEI / PIS(INSS)"
              value={
                owner?.cei
                  ? owner?.cei
                  : isSAT
                  ? ownerByCpfCnpj?.cei && ownerByCpfCnpj?.cei
                  : cei
              }
            />
            {errors?.cei && (
              <Form.Text className="text-danger">
                {errors?.cei?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formTelefone">
          <FloatingLabel controlId="formTelefone" label="Celular">
            <Form.Control
              {...register("numero_celular", {
                required: "O número do celular é obrigatório",
              })}
              size="sm"
              type="text"
              className="is-required"
              as={ReactInputMask}
              mask={`${isCellphone ? "(99)99999-9999" : "(99)9999-99999"}`}
              maskChar={null}
              placeholder="Digite o celular"
              onChange={handleChangePhoneNumber}
              value={cellphoneNumber}
            />
            {errors?.numero_celular && (
              <Form.Text className="text-danger">
                {errors?.numero_celular?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formTipoTributacao">
          <FloatingLabel
            controlId="formTipoTributacao"
            label="Tipo de Tributação"
          >
            <Form.Select
              {...register("tipo_tributacao", {
                required: isCpf ? false : "O tipo de tributação é obrigatório",
              })}
              size="sm"
              type="text"
              onChange={handleChangeTaxationType}
              className={!isCpf && "is-required"}
              value={taxationType}
            >
              <option value="">Selecione o tipo de tributo</option>
              {taxationTypes.map((taxationType) => (
                <option value={taxationType.value} key={taxationType.id}>
                  {taxationType.label}
                </option>
              ))}
            </Form.Select>
            {errors?.tipo_tributacao && (
              <Form.Text className="text-danger">
                {errors?.tipo_tributacao?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="formAgregado">
          <FloatingLabel controlId="formAgregado" label="Agregado">
            <Form.Select
              {...register("agregado", {
                required: isCpf ? false : "O agregado é obrigatório",
              })}
              size="sm"
              className={!isCpf && "is-required"}
              onChange={handleChangeAgregado}
              value={agregado}
            >
              <option value="">Selecione o agregado</option>
              {agregadoTypes.map((agregadoType) => (
                <option value={agregadoType.value} key={agregadoType.id}>
                  {agregadoType.label}
                </option>
              ))}
            </Form.Select>
            {errors?.agregado && (
              <Form.Text className="text-danger">
                {errors?.agregado?.message}
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>

        <div className=" text-start mt-4 d-flex flex-column gap-2">
          <PageTitle label="Status do cadastro" />
          <div className="d-md-flex gap-2 align-items-center">
            <Form.Group
              className="d-flex align-items-center gap-2"
              controlId="formAtivo"
            >
              <FormLabelText className="mb-0">Liberado</FormLabelText>
              <Form.Check
                type="switch"
                id="custom-switch"
                {...register("ativo")}
                checked={ativo}
                onChange={handleActiveChange}
              />
            </Form.Group>
            <Form.Group
              className="d-flex align-items-center gap-2"
              controlId="formAtivo"
            >
              <FormLabelText className="mb-0">Bloqueio ADM</FormLabelText>
              <Form.Check
                {...register("bloqueado_adm")}
                type="switch"
                id="custom-switch"
                checked={admBlock}
                onChange={handleAdmBlockChange}
              />
            </Form.Group>
          </div>
        </div>
      </Row>

      {errors && Object.keys(errors).length > 0 && (
        <Form.Text className="text-danger">
          Por favor, corrija os erros antes de enviar o formulário.
        </Form.Text>
      )}

      <Form.Group className="d-flex gap-3 mt-4">
        <SubmitButton
          labelButton="Salvar"
          isSubmitting={isSubmitting}
          fontSize="0.85rem"
        />
        <ButtonLink label="Cancelar" link="/proprietarios" fontSize="0.85rem" />
      </Form.Group>
    </>
  );
};
