import { Form } from "react-bootstrap";
import { Item } from "./styles";
import { BsFillPersonFill, BsTelephoneFill } from "react-icons/bs";
import { BiIdCard } from "react-icons/bi";
import { formatCPF, formatPhoneNumber } from "../../../../../Shared/Utils/data";

const AboutInformationSection = ({
  reportInconsistency,
  handleInputChange,
  driver,
  changing,
}) => {
  return (
    <div className="d-flex flex-column gap-2 mb-2 p-2">
      <Item className="gap-1">
        {reportInconsistency && (
          <Form.Check
            type="switch"
            size="sm"
            id={`default-switch-name`}
            checked={changing?.nome}
            onChange={() => handleInputChange("nome")}
          />
        )}
        <BsFillPersonFill size={15} color="#093c5e" />
        <h3>Nome completo:</h3>
        <h4>{driver?.nome ? driver?.nome : "--"}</h4>
      </Item>

      <Item className="gap-1">
        <BiIdCard size={17} color="#093c5e" />
        <h3>CPF:</h3>
        <h4>{driver?.cpf ? formatCPF(driver?.cpf) : "--"}</h4>
      </Item>

      <Item className="gap-1 mb-0">
        {reportInconsistency && (
          <Form.Check
            size="sm"
            type="switch"
            id={`default-switch-numero_celular`}
            checked={changing?.numero_celular}
            onChange={() => handleInputChange("numero_celular")}
          />
        )}
        <BsTelephoneFill size={14} color="#093c5e" />
        <h3>Celular</h3>
        <h4>
          {driver?.numero_celular
            ? formatPhoneNumber(driver?.numero_celular)
            : "--"}
        </h4>
      </Item>
    </div>
  );
};

export default AboutInformationSection;
