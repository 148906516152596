import { Modal, Ratio } from "react-bootstrap";
import { CoverImage, ModalTitle } from "./styles";
import { AiOutlineDownload } from "react-icons/ai";
import { Link } from "react-router-dom";

const ImageBase64Modal = ({ image, show, handleClose, title }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="custom-modal-90w"
      animation
      fullscreen
      centered
    >
      <Modal.Header closeButton>
        <ModalTitle>{title}</ModalTitle>
      </Modal.Header>

      {image && (
        <Modal.Body>
          <div className="p-1">
            {image.startsWith("data:application/pdf") ? (
              <div
                style={{ width: "100%", height: "auto" }}
                data-testid="achando-essa-div"
              >
                <Ratio aspectRatio="1x1">
                  <embed type="application/pdf" src={image} />
                </Ratio>
              </div>
            ) : (
              <CoverImage image={image} />
            )}
          </div>
        </Modal.Body>
      )}

      <Modal.Footer className="d-flex justify-content-center">
        <Link
          to={image}
          target="_blank"
          download="cnh.jpeg"
          className="d-flex align-items-center"
        >
          <AiOutlineDownload />
          <span className="ms-1">Download</span>
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageBase64Modal;
