import { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Spinner } from "react-bootstrap";

import { LoadOrderManagementItem } from "./LoadOrderManagementItem";

import {
  DashboardContainer,
  LoadOrderManagementCard,
  LoadOrderManagementTitle,
} from "./styles";

import ApiRequest from "../../../../Shared/Utils/Request";
import { currentMonth } from "../../../../Shared/Utils/data";

export const LoadOrderManagement = ({ handleActiveKey }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadOrders, setLoadOrders] = useState([]);

  const getLoadOrders = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: "contra-senha",
        method: "GET",
        params: {
          perPage: 10000,
        },
      });

      setLoadOrders(response?.data?.data);
    } catch (error) {
      setLoadOrders([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getLoadOrders();
  }, [getLoadOrders]);

  const list = useMemo(
    () => [
      {
        tab: "tab2",
        amount: loadOrders
          ? loadOrders.filter(
              (order) => order.status === "8" || order.status === "60"
            ).length
          : 0,
        label: "Em curso",
      },
      {
        tab: "tab2",
        amount: loadOrders
          ? loadOrders.filter((order) => order.status === "9").length
          : 0,
        label: "Pendentes de Rotas",
      },
      {
        tab: "tab3",
        amount: loadOrders
          ? loadOrders.filter((order) => order.status === "6").length
          : 0,
        label: "Bloqueadas",
      },
      {
        tab: "tab4",
        amount: loadOrders
          ? loadOrders.filter((order) => order.status === "10").length
          : 0,
        label: "Pendente finalização",
      },
      {
        tab: "tab5",
        amount: loadOrders
          ? loadOrders.filter(
              (order) =>
                order.status === "2" &&
                currentMonth(order?.data_ultima_localizacao)
            ).length
          : 0,
        label: "Finalizadas mês atual",
      },
      {
        tab: "tab6",
        amount: loadOrders
          ? loadOrders.filter((order) => order?.desvio_rota === true).length
          : 0,
        label: "Fora de rota",
      },
    ],
    [loadOrders]
  );

  return (
    <>
      {isLoading && (
        <div className="d-flex align-items-center justify-content-center mt-5 gap-2">
          <Spinner variant="warning" />
        </div>
      )}
      {loadOrders && !isLoading && (
        <DashboardContainer className="h-100">
          <div className="mt-2">
            <LoadOrderManagementCard className="h-100">
              <LoadOrderManagementTitle className="mb-3">
                Visão Geral das OCs
              </LoadOrderManagementTitle>
              <Row
                xs={1}
                md={3}
                xxl={4}
                className="d-md-flex justify-content-center align-items-center g-3"
              >
                {list.map((item, index) => (
                  <LoadOrderManagementItem
                    amount={item.amount}
                    item={item.label}
                    tab={item.tab}
                    handleActiveKey={handleActiveKey}
                    key={index}
                  />
                ))}
              </Row>
            </LoadOrderManagementCard>
          </div>
        </DashboardContainer>
      )}
    </>
  );
};
