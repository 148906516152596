import { Image } from "react-bootstrap";
import styled from "styled-components";

export const UsersTableTitle = styled.tr`
th {
  font-size: 0.85rem;
  font-weight: bold;
}
`

export const UserListContainer = styled.div`
max-height: 30vh;
flex: 1;
overflow: auto;

 @media(min-width: 425px){
  max-height:35vh ;
 }
 @media(min-width: 576px){
  max-height:40vh ;
 }

 @media(min-width: 768px){
  max-height: 50vh;
 }

  @media(min-width: 2440px){
 
  max-height: 60vh;
  }
`
export const FormUserContainer = styled.div`
max-height: 45vh;
flex: 1;
overflow-x: hidden;

 @media(min-width: 425px){
  max-height:55vh ;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 65vh;
 }

 @media(min-width: 1440px){
 
  max-height: 80vh;
  }

  @media(min-width: 1640px){
 
  max-height: 75vh;
  }
  @media(min-width: 2440px){
 
  max-height: 80vh;
  }
`
export const UserContainer = styled.div`

flex: 1;
/* overflow: hidden; */

`

export const ImageContent = styled(Image)`
  height: 25px;
`