import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { FaDotCircle } from "react-icons/fa";

import { Item, TravelHistoryContainer } from "./styles";
import TravelHistoryTable from "./TravelHistoryTable";
import { useDrivers } from "../../../../../hooks/DriverProvider";
import Dashboard from "../../../../../Shared/layout";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import { SearchLoaderOrder } from "../../../../../Shared/Components/TravelHistorySearch/TravelHistorySearch";
import { formatNameString } from "../../../../../Shared/Utils/data";

const TravelHistory = () => {
  const [list, setList] = useState(null);
  const [filterDate, setFilterDate] = useState(null);

  const { id } = useParams();

  const { driver, isLoading, getDriver } = useDrivers();

  useEffect(() => {
    getDriver(id);
  }, [getDriver, id]);

  const travelHistoryList = useMemo(() => {
    if (driver?.viagens && driver?.viagens !== '{"message":"no data"}') {
      return JSON.parse(driver?.viagens);
    } else {
      return null;
    }
  }, [driver?.viagens]);

  const handleGetList = (data) => {
    if (data) {
      setFilterDate({
        data_inicio: data?.data_inicio
          ? new Date(data?.data_inicio).toISOString()
          : null,
        data_fim: data?.data_fim
          ? new Date(data?.data_fim).toISOString()
          : null,
      });

      const filterItems = Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== "" && key !== "data_inicio" && key !== "data_fim") {
          acc[key] = value;
        }
        return acc;
      }, {});

      setList(filterItems);
    } else {
      setList(null);
      setFilterDate(null);
    }
  };

  const filteredList = list
    ? travelHistoryList.filter((item) => {
        return (
          Object.entries(list).every(([key, value]) => {
            return item[key].toLowerCase().includes(value.toLowerCase());
          }) &&
          (!filterDate?.data_inicio ||
            new Date(item.dt_viagem) >= new Date(filterDate?.data_inicio)) &&
          (!filterDate?.data_fim ||
            new Date(item.dt_viagem) <= new Date(filterDate?.data_fim))
        );
      })
    : filterDate
    ? travelHistoryList.filter((item) => {
        return (
          (!filterDate?.data_inicio ||
            new Date(item.dt_viagem) >= new Date(filterDate?.data_inicio)) &&
          (!filterDate?.data_fim ||
            new Date(item.dt_viagem) <= new Date(filterDate?.data_fim))
        );
      })
    : travelHistoryList;

  return (
    <Dashboard title="Histórico de viagens sdsdd">
      {isLoading && <Loading />}

      {!isLoading && driver && (
        <TravelHistoryContainer className="mt-3">
          <div className="px-2">
            <SearchLoaderOrder handleGetList={handleGetList} />
          </div>
          <Item className="d-flex gap-1 align-items-center">
            <FaDotCircle size={10} />
            <h3>Motorista:</h3>
            <span>{formatNameString(driver?.nome)}</span>
          </Item>

          <TravelHistoryTable
            isLoading={isLoading}
            travelHistoryList={filteredList}
          />
        </TravelHistoryContainer>
      )}
    </Dashboard>
  );
};

export default TravelHistory;
