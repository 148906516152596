import React from "react";
import Routes from "./Routes";
import { UnitProvider } from "./hooks/UnitProvider";
import { CompanyProvider } from "./hooks/CompanyProvider";
import { UserProvider } from "./hooks/UserProvider";
import { PerfilProvider } from "./hooks/PerfilProvider";
import { PermitionProvider } from "./hooks/PermitionProvider";
import { LoginProvider } from "./hooks/LoginProvider";
import { ScopeProvider } from "./hooks/ScopeProvider";
import { AddressProvider } from "./hooks/AddressProvider";
import { DriverProvider } from "./hooks/DriverProvider";
import { GlobalStyle } from "./styles/global";
import { OwnerProvider } from "./hooks/OwnerProvider";
import { VehicleProvider } from "./hooks/VehicleProvider";
import { TravelProvider } from "./hooks/TravelProvider";
import { OccurrenceProvider } from "./hooks/OccurrenceTypeProvider";
import { RoutesProvider } from "./hooks/RoutesProvider";
import { DriverValidationProvider } from "./hooks/DriverValidationProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorsProvider } from "./hooks/ErrorsProvider";
import { ManifestProvider } from "./hooks/ManifestiesProvider";

export default function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <LoginProvider>
        <RoutesProvider>
          <PerfilProvider>
            <UserProvider>
              <AddressProvider>
                <ScopeProvider>
                  <UnitProvider>
                    <CompanyProvider>
                      <PermitionProvider>
                        <DriverProvider>
                          <DriverValidationProvider>
                            <OwnerProvider>
                              <VehicleProvider>
                                <TravelProvider>
                                  <OccurrenceProvider>
                                    <ManifestProvider>
                                      <ErrorsProvider>
                                        <GlobalStyle />
                                        <Routes />
                                      </ErrorsProvider>
                                    </ManifestProvider>
                                  </OccurrenceProvider>
                                </TravelProvider>
                              </VehicleProvider>
                            </OwnerProvider>
                          </DriverValidationProvider>
                        </DriverProvider>
                      </PermitionProvider>
                    </CompanyProvider>
                  </UnitProvider>
                </ScopeProvider>
              </AddressProvider>
            </UserProvider>
          </PerfilProvider>
        </RoutesProvider>
      </LoginProvider>
    </QueryClientProvider>
  );
}
