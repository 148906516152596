import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

import { DriverContainer } from "./styles";

import Dashboard from "../../../../../Shared/layout";
import ButtonLink from "../../../../../Shared/Components/ButtonLink/ButtonLink";
import ImageModal from "../../../../../Shared/Components/ImageModal/ImageModal";

import { useDrivers } from "../../../../../hooks/DriverProvider";
import { useOwners } from "../../../../../hooks/OwnerProvider";
import VehicleUpdateForm from "./VehicleUpdateForm";
import { TrucksUpdatesForm } from "./TrucksUpdatesForm";
import { HiOutlineArrowLeft } from "react-icons/hi";

const UpdateVehicle = () => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [isPendentingForm, setIsPendentingForm] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const { owners, getOwners } = useOwners();
  const { drivers, getDrivers } = useDrivers();

  const [currentImage, setCurrentImage] = useState(null);

  const handleBack = () => {
    if (isPendentingForm) {
      Swal.fire({
        title: "Atenção!",
        html: `
        <div>
          <p>Você possui alterações não salvas.</p>
          <p>Tem certeza que deseja sair?</p>
        </div>
      `,
        showCancelButton: true,
        confirmButtonColor: "#093c5e",
        confirmButtonText: "Voltar à página anterior",
        cancelButtonText: "Retornar ao formulário",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/veiculos-listar");
        }
      });
    } else {
      navigate("/veiculos-listar");
    }
  };

  useEffect(() => {
    getOwners();
    getDrivers();
  }, [getDrivers, getOwners]);

  const handleShowModal = useCallback((image) => {
    setCurrentImage(image);
    setShowImageModal(true);
  }, []);

  return (
    <Dashboard title="Editar veículo">
      <DriverContainer
        className="gap-3 d-flex flex-column mt-1"
        id="vehicle-row-form"
      >
        <VehicleUpdateForm
          owners={owners}
          drivers={drivers}
          handleShowModal={handleShowModal}
          id={id}
          setIsPendentingForm={setIsPendentingForm}
        />

        <TrucksUpdatesForm
          id={id}
          owners={owners}
          drivers={drivers}
          setIsPendentingForm={setIsPendentingForm}
          handleShowModal={handleShowModal}
        />
        <div className="d-flex gap-3 p-3">
          <ButtonLink
            label="Voltar"
            handleClick={handleBack}
            fontSize="0.85rem"
            buttoncolor="primary"
            icon={<HiOutlineArrowLeft size={14} />}
          />
        </div>
      </DriverContainer>

      <ImageModal
        show={showImageModal}
        handleClose={() => setShowImageModal(false)}
        image={currentImage}
        title="CNH"
      />
    </Dashboard>
  );
};

export default memo(UpdateVehicle);
