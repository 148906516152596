import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest, { ApiGeoCode } from "../Shared/Utils/Request";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export const TravelContext = createContext({
  isLoading: false,
  isLoadingOccurrence: false,
  totalPages: 1,
  totalPagesOfIncompleteLoadOrders: 1,
  loadOrders: [],
  loadOrder: null,
  occurrences: [],
  loadOrderAddress: [],
  loadOrderAddressLoading: false,
  incompleteLoadOrders: [],
  incompleteLoadOrdersLoading: false,
  activeLoadOrder: [],
  activeLoadOrderLoading: false,
  loadOrderRoute: [],
  loadOrderRouteLoading: false,
  activesLoadOrders: [],
  activesLoadOrdersLoading: false,
  completeLoadOrders: [],
  completeLoadOrdersLoading: false,
  loadOrderByGeometryAddress: [],
  loadOrderByGeometryAddressLoading: false,
  relationshipBetweenAttributes: null,
  isLoadingRelationshipBetweenAttributes: false,
  getLoadOrders: () => {},
  getLoadOrder: () => {},
  getOccurrences: () => null,
  getLoadOrderAddress: () => null,
  getIncompleteLoadOrders: () => null,
  editLoadOrder: () => null,
  getActivesLoadOrder: () => null,
  getLoadOrderRoutes: () => null,
  getActivesLoadOrders: () => null,
  getCompleteLoadOrders: () => null,
  getLoadOrderByGeometryAddress: () => null,
  getRelationshipBetweenAttributes: () => null,
  addOccurences: () => null,
  getLoadOrdersPendingCompletion: () => null,
  getClosedLoadOrders: () => null,
  getOffRouteLoadOrders: () => null,
});

export const useTravels = () => {
  const context = useContext(TravelContext);

  if (!context) {
    throw new Error("useTravels must be within TravelProvider");
  }

  return context;
};

export const TravelProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOccurrence, setIsLoadingOcurrence] = useState(false);
  const [loadOrders, setLoadOrders] = useState([]);
  const [incompleteLoadOrders, setIncompleteLoadOrders] = useState([]);
  const [incompleteLoadOrdersLoading, setIncompleteLoadOrdersLoading] =
    useState(false);
  const [loadOrder, setLoadOrder] = useState(null);
  const [occurrences, setOccurrences] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [totalPagesOfIncompleteLoadOrders, setTotalPagesOIncompleteLoadOrders] =
    useState(1);
  const [loadOrderAddress, setLoadOrderAddress] = useState([]);
  const [loadOrderAddressLoading, setLoadOrderAddressLoading] = useState(false);

  const [activeLoadOrder, setActiveLoadOrder] = useState([]);
  const [activeLoadOrderLoading, setActiveLoadOrderLoading] = useState(false);

  const [loadOrderRoute, setLoadRoute] = useState([]);
  const [loadOrderRouteLoading, setLoadOrderRouteLoading] = useState(false);

  const [activesLoadOrders, setActiveLoadOrders] = useState([]);
  const [activesLoadOrdersLoading, setActiveLoadOrdersLoading] =
    useState(false);

  //OCs completas: status 8 ou 60
  const [completeLoadOrders, setCompleteLoadOrders] = useState([]);
  const [closedLoadOrders, setClosedLoadOrders] = useState([]);
  const [closeLoadOrderTotalPage, setCloseLoadOrderTotalPage] = useState(1);

  const [completeLoadOrdersLoading, setCompleteLoadOrdersLoading] =
    useState(false);

  const [completeLoadOrdersTotalPages, setCompleteLoadOrdersTotalPages] =
    useState(1);

  //

  const [loadOrderByGeometryAddress, setLoadOrderByGeometryAddress] = useState(
    []
  );
  const [
    loadOrderByGeometryAddressLoading,
    setLoadOrderByGeometryAddressLoading,
  ] = useState(false);

  const [relationshipBetweenAttributes, setRelationshipBetweenAttributes] =
    useState({});

  const [
    isLoadingRelationshipBetweenAttributes,
    setIsLoadingRelationshipBetweenAttributes,
  ] = useState(false);

  const [loadOrderPendingCompletion, setLoadOrderPendingCompletion] =
    useState(null);

  const [offRoute, setOffRoute] = useState(null);
  const [offRouteTotalPage, setOffRouteTotalPage] = useState(false);

  const getRelationshipBetweenAttributes = useCallback(async (id) => {
    try {
      setIsLoadingRelationshipBetweenAttributes(true);

      const response = await ApiRequest({
        path: `/contra-senha/relacao/${id}`,
        method: "GET",
      });

      setRelationshipBetweenAttributes(response?.data);
    } catch (error) {
      setRelationshipBetweenAttributes(null);
    } finally {
      setIsLoadingRelationshipBetweenAttributes(false);
    }
  }, []);

  const getLoadOrders = useCallback(async (currentPage) => {
    try {
      setIsLoading(true);

      const params = {
        perPage: 10,
        page: currentPage,
      };

      const response = await ApiRequest({
        path: "contra-senha",
        method: "GET",
        params,
      });

      setTotalPages(response?.data?.total);
      setIsLoading(false);

      setLoadOrders(response?.data?.data);
    } catch (error) {
      setLoadOrders([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getLoadOrdersPendingCompletion = useCallback(
    async (currentPage = 1) => {
      try {
        setIsLoading(true);

        const params = {
          perPage: 20,
          page: currentPage,
          metodo: "pendente_finalizacao",
        };

        const response = await ApiRequest({
          path: "contra-senha",
          method: "GET",
          params,
        });

        setIsLoading(false);

        setLoadOrderPendingCompletion(response?.data?.data);
      } catch (error) {
        setLoadOrderPendingCompletion([]);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const getClosedLoadOrders = useCallback(async (currentPage) => {
    try {
      setIsLoading(true);

      const params = {
        perPage: 20,
        page: currentPage,
        metodo: "finalizadas",
      };

      const response = await ApiRequest({
        path: "contra-senha",
        method: "GET",
        params,
      });

      setIsLoading(false);
      setCloseLoadOrderTotalPage(response?.data?.total);

      setClosedLoadOrders(response?.data?.data);
    } catch (error) {
      setClosedLoadOrders([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getCompleteLoadOrders = useCallback(
    async (currentPage = 1, newData) => {
      try {
        setCompleteLoadOrdersLoading(true);

        const params = {
          perPage: 20,
          page: currentPage,
          metodo: "completas",
          ...newData,
        };

        const response = await ApiRequest({
          path: "contra-senha",
          method: "GET",
          params,
        });

        setCompleteLoadOrdersTotalPages(response?.data?.total);

        setCompleteLoadOrders(response?.data?.data);
      } catch (error) {
        setCompleteLoadOrders([]);
      } finally {
        setCompleteLoadOrdersLoading(false);
      }
    },
    []
  );

  const getOffRouteLoadOrders = useCallback(async (currentPage = 1) => {
    try {
      setIsLoading(true);

      const params = {
        perPage: 20,
        page: currentPage,
        metodo: "desvio_rota",
      };

      const response = await ApiRequest({
        path: "contra-senha",
        method: "GET",
        params,
      });

      setOffRouteTotalPage(response?.data?.total);

      setOffRoute(response?.data?.data);
    } catch (error) {
      setOffRoute([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getLoadOrder = useCallback(async (token) => {
    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: `contra-senha/token/${token}`,
        method: "GET",
      });

      setLoadOrder(response?.data);

      setIsLoading(false);
    } catch (error) {
      setLoadOrder(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getIncompleteLoadOrders = useCallback(async (currentPage = 1) => {
    try {
      setIncompleteLoadOrdersLoading(true);

      const params = {
        perPage: 20,
        page: currentPage,
        metodo: "incompleta",
      };

      const response = await ApiRequest({
        path: "contra-senha",
        method: "GET",
        params,
      });

      setTotalPagesOIncompleteLoadOrders(response?.data?.total);

      setIncompleteLoadOrders(response?.data?.data);
    } catch (error) {
      setIncompleteLoadOrders([]);
    } finally {
      setIncompleteLoadOrdersLoading(false);
    }
  }, []);

  const getActivesLoadOrder = useCallback(async () => {
    try {
      setActiveLoadOrderLoading(true);

      const response = await ApiRequest({
        path: "contra-senha-ativas",
        method: "GET",
      });

      setActiveLoadOrder(response?.data?.data);
    } catch (error) {
      setActiveLoadOrder(null);
    } finally {
      setActiveLoadOrderLoading(false);
    }
  }, []);

  const getLoadOrderRoutes = useCallback(async (id, perPage = 10000000000) => {
    try {
      setLoadOrderRouteLoading(true);

      const params = {
        perPage: perPage,
      };

      const response = await ApiRequest({
        path: `contra-senha/percurso/${id}`,
        method: "GET",
        params,
      });

      setLoadRoute(response?.data?.data);
    } catch (error) {
      setOccurrences([]);
    } finally {
      setLoadOrderRouteLoading(false);
    }
  }, []);

  const getOccurrences = useCallback(async (id) => {
    try {
      setIsLoadingOcurrence(true);
      const params = {
        orderby: "desc",
      };

      const response = await ApiRequest({
        path: `contra-senha/ocorrencia/${id}`,
        method: "GET",
        params,
      });

      setIsLoadingOcurrence(false);

      setOccurrences(response?.data?.data);
    } catch (error) {
      setOccurrences([]);
    } finally {
      setIsLoadingOcurrence(false);
    }
  }, []);

  const addOccurences = useCallback(
    async (id, data) => {
      try {
        setIsLoadingOcurrence(true);

        await ApiRequest({
          path: `contra-senha/ocorrencia/${id}`,
          method: "POST",
          data,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Ocorrência registrada",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });

        getOccurrences(id);
        setIsLoadingOcurrence(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: error.response?.data?.error
            ? error.response?.data?.error
            : "Erro ao registrar ocorrência",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          hideClass: {
            popup: "",
            backdrop: "",
          },
        });
      } finally {
        setIsLoadingOcurrence(false);
      }
    },
    [getOccurrences]
  );

  const getLoadOrderAddress = useCallback(async (address) => {
    try {
      setLoadOrderAddressLoading(true);

      const response = await ApiGeoCode.get(`/search?q=${address}&format=json`);

      setLoadOrderAddress(response?.data);
      setLoadOrderAddressLoading(false);

      return response?.data;
    } catch (error) {
      setLoadOrderAddress([]);
    } finally {
      setLoadOrderAddressLoading(false);
    }
  }, []);

  const getLoadOrderByGeometryAddress = useCallback(async (address) => {
    try {
      setLoadOrderByGeometryAddressLoading(true);

      const response = await ApiRequest({
        path: `/contra-senha/google-destino`,
        method: "POST",
        data: {
          origin: `${address?.currentLocation?.lat},${address?.currentLocation?.lng}`,
          destination: `${address?.destinyLocation?.lat},${address?.destinyLocation?.lng}`,
        },
      });

      setLoadOrderByGeometryAddressLoading(false);
      setLoadOrderByGeometryAddress(response?.data);

      return response?.data;
    } catch (error) {
      setLoadOrderByGeometryAddress([]);
    } finally {
      setLoadOrderByGeometryAddressLoading(false);
    }
  }, []);

  const getActivesLoadOrders = useCallback(async () => {
    try {
      setActiveLoadOrdersLoading(true);

      const response = await ApiRequest({
        path: "/contra-senha-ativas",
        method: "GET",
      });

      setActiveLoadOrders(response?.data?.data);
    } catch (error) {
      setActiveLoadOrders([]);
    } finally {
      setActiveLoadOrdersLoading(false);
    }
  }, []);

  const editLoadOrder = useCallback(async (id, data, navigate, route) => {
    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: `contra-senha/id/${id}`,
        method: "PUT",
        data,
      });

      setLoadOrder(response?.data);

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Associação realizada com sucesso",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });

      if (navigate && !route) {
        navigate(
          `/gestao-viagens/ordem-de-carregamento/localizacao-tempo-real/${response?.data?.token}`
        );
      }
      if (navigate && route) {
        navigate("/gestao-viagens/ordem-de-carregamento");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: error?.response?.data?.error
          ? error?.response?.data?.error
          : "Essa associação não pode ser feita",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const providerValue = useMemo(
    () => ({
      isLoading,
      loadOrders,
      totalPages,
      loadOrder,
      occurrences,
      isLoadingOccurrence,
      loadOrderAddress,
      loadOrderAddressLoading,
      incompleteLoadOrders,
      totalPagesOfIncompleteLoadOrders,
      incompleteLoadOrdersLoading,
      activeLoadOrder,
      activeLoadOrderLoading,
      loadOrderRoute,
      loadOrderRouteLoading,
      activesLoadOrders,
      activesLoadOrdersLoading,
      completeLoadOrders,
      completeLoadOrdersLoading,
      loadOrderByGeometryAddress,
      loadOrderByGeometryAddressLoading,
      relationshipBetweenAttributes,
      loadOrderPendingCompletion,
      isLoadingRelationshipBetweenAttributes,
      completeLoadOrdersTotalPages,
      closedLoadOrders,
      closeLoadOrderTotalPage,
      offRoute,
      offRouteTotalPage,
      getLoadOrders,
      getLoadOrder,
      getOccurrences,
      getLoadOrderAddress,
      getIncompleteLoadOrders,
      editLoadOrder,
      getActivesLoadOrder,
      getLoadOrderRoutes,
      getActivesLoadOrders,
      getCompleteLoadOrders,
      getLoadOrderByGeometryAddress,
      getRelationshipBetweenAttributes,
      addOccurences,
      getLoadOrdersPendingCompletion,
      getClosedLoadOrders,
      getOffRouteLoadOrders,
    }),
    [
      isLoading,
      loadOrders,
      totalPages,
      loadOrder,
      occurrences,
      isLoadingOccurrence,
      loadOrderAddress,
      loadOrderAddressLoading,
      incompleteLoadOrders,
      totalPagesOfIncompleteLoadOrders,
      incompleteLoadOrdersLoading,
      activeLoadOrder,
      activeLoadOrderLoading,
      loadOrderRoute,
      loadOrderRouteLoading,
      activesLoadOrders,
      activesLoadOrdersLoading,
      completeLoadOrders,
      completeLoadOrdersLoading,
      loadOrderByGeometryAddress,
      loadOrderByGeometryAddressLoading,
      relationshipBetweenAttributes,
      loadOrderPendingCompletion,
      isLoadingRelationshipBetweenAttributes,
      completeLoadOrdersTotalPages,
      closedLoadOrders,
      closeLoadOrderTotalPage,
      offRoute,
      offRouteTotalPage,
      getLoadOrders,
      getLoadOrder,
      getOccurrences,
      getLoadOrderAddress,
      getIncompleteLoadOrders,
      editLoadOrder,
      getActivesLoadOrder,
      getLoadOrderRoutes,
      getActivesLoadOrders,
      getCompleteLoadOrders,
      getLoadOrderByGeometryAddress,
      getRelationshipBetweenAttributes,
      addOccurences,
      getLoadOrdersPendingCompletion,
      getClosedLoadOrders,
      getOffRouteLoadOrders,
    ]
  );

  return (
    <TravelContext.Provider value={providerValue}>
      {children}
    </TravelContext.Provider>
  );
};
