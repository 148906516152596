import { useParams } from "react-router-dom";
import Dashboard from "../../../../../Shared/layout";
import { memo, useCallback, useEffect, useState } from "react";

import { useOwners } from "../../../../../hooks/OwnerProvider";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import OwnerInformationsCard from "./OwnerInformationsCard";
import ImageModal from "../../../../../Shared/Components/ImageModal/ImageModal";
import ControlOwnerCard from "../components/ControlOwnerCard/ControlOwnerCard";
import { Column, OwnerInformationsRow } from "./styles";

const OwnerInformations = () => {
  const { id } = useParams();
  const [showOwnerImageModal, setShowOwnerImageModal] = useState(false);
  const [showOwnerProofOfAddressModal, setShowOwnerProofOfAddressModal] =
    useState(false);

  const { owner, isLoading, getOwner } = useOwners();

  useEffect(() => {
    getOwner(id);
  }, [getOwner, id]);

  const handleShowOwnerImageModal = useCallback(() => {
    setShowOwnerImageModal(true);
  }, []);

  const handleShowOwnerProofOfAddressModal = useCallback(() => {
    setShowOwnerProofOfAddressModal(true);
  }, []);

  return (
    <Dashboard title="Informações do proprietário">
      {isLoading && <Loading />}
      {!isLoading && owner && (
        <OwnerInformationsRow
          xs={1}
          sm={2}
          xl={3}
          className="d-flex justify-content-between g-1 mt-2 flex-1"
        >
          <Column sm={3} xl={2}>
            <ControlOwnerCard owner={owner} />
          </Column>
          <Column sm={9} xl={10}>
            <OwnerInformationsCard
              owner={owner}
              handleShowOwnerImageModal={handleShowOwnerImageModal}
              handleShowOwnerProofOfAddressModal={
                handleShowOwnerProofOfAddressModal
              }
            />
          </Column>
        </OwnerInformationsRow>
      )}
      <ImageModal
        show={showOwnerImageModal}
        handleClose={() => setShowOwnerImageModal(false)}
        image={owner?.foto_proprietario}
        title="CNH"
      />
      <ImageModal
        show={showOwnerProofOfAddressModal}
        handleClose={() => setShowOwnerProofOfAddressModal(false)}
        image={owner?.foto_comprovante_endereco}
        title="Comprovante de residência"
      />
    </Dashboard>
  );
};

export default memo(OwnerInformations);
