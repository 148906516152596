import moment from "moment";
import { EmptyTableMessage } from "../EmptyTableMessage";
import { CardContainer, Container, InfoTitle, TitleContainer } from "./styles";

const Item = ({ item }) => {
  return (
    <div className="d-flex gap-1">
      <span>{item.title}:</span>
      <strong>{item.value}</strong>
    </div>
  );
};

export const ArrestWarrantWarningCard = ({ driverConsult }) => {
  const arrestWarrant =
    driverConsult &&
    driverConsult?.consulta_mandados_prisao &&
    JSON.parse(driverConsult?.consulta_mandados_prisao);

  return (
    <Container id="arrest-warrant-warning-card">
      <TitleContainer className="mb-3 d-flex">
        <InfoTitle className="text-start">
          CONSELHO NACIONAL DE JUSTIÇA | MANDADOS DE PRISÃO
        </InfoTitle>
      </TitleContainer>
      {driverConsult && !arrestWarrant && (
        <EmptyTableMessage label="Não há registro de mandados de prisão para este cadastro" />
      )}
      {driverConsult &&
        arrestWarrant &&
        arrestWarrant?.code === 612 &&
        arrestWarrant?.code_message && (
          <EmptyTableMessage label={arrestWarrant?.code_message} />
        )}
      {driverConsult &&
        arrestWarrant &&
        arrestWarrant?.err &&
        arrestWarrant?.message && (
          <EmptyTableMessage label={arrestWarrant?.message} />
        )}
      {driverConsult &&
        arrestWarrant &&
        arrestWarrant?.code === 200 &&
        arrestWarrant?.data &&
        arrestWarrant?.data.length > 0 &&
        arrestWarrant?.data.map((info) => (
          <div className="p-2 d-flex flex-column gap-2">
            <CardContainer>
              <Item item={{ title: "Situação", value: info?.situacao }} />
              <Item item={{ title: "Tipo", value: info?.tipo }} />
              <Item item={{ title: "Espécie", value: info?.especie_prisao }} />
              <Item
                item={{
                  title: "Data de validade",
                  value:
                    info?.validade_data &&
                    moment(info?.validade_data).format("DD/MM/YYYY"),
                }}
              />
            </CardContainer>
          </div>
        ))}
    </Container>
  );
};
