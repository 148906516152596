import React, { useCallback, useEffect } from "react";
import Dashboard from "../../../../Shared/layout";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useUnits } from "../../../../hooks/UnitProvider";
import FormEditUnit from "./FormEditUnit";
import { useCompanies } from "../../../../hooks/CompanyProvider";
import { Loading } from "../../../../Shared/Components/Loading/Loading";
import { UnitContainer } from "./styles";

const fields = {
  nome: {
    required: true,
  },
  id_empresa: {
    required: true,
    pattern: /^[0-9]+$/,
  },
};

const UpdateUnit = () => {
  const { id } = useParams();

  const { companies } = useCompanies();

  const { isSubmitting, unit, isLoading, getUnit, updateUnit } = useUnits();

  useEffect(() => {
    getUnit(parseInt(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const navigateTo = useNavigate();

  const onSubmit = useCallback(
    (id, data) => {
      try {
        data.id_empresa = parseInt(data.id_empresa);
        data.ativo = Boolean(data.ativo);
        data.cep = data.cep.replace(/\D+/g, "");

        updateUnit(id, data, navigateTo);
      } catch (error) {
        console.error("erro");
      }
    },
    [navigateTo, updateUnit]
  );

  return (
    <Dashboard title="Editar unidade">
      {isLoading && <Loading />}

      {!isLoading && unit && (
        <UnitContainer className="mt-2">
          <FormEditUnit
            handleSubmit={handleSubmit}
            register={register}
            fields={fields}
            errors={errors}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            companies={companies}
            setValue={setValue}
            unit={unit}
          />
        </UnitContainer>
      )}
    </Dashboard>
  );
};

export default UpdateUnit;
