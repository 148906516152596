import { CoverImage, Document, Status, Subtitle, Title } from "./styles";

import EmptyImage from "../../../../../../Assets/Images/EmptyImage.png";
import ButtonLink from "../../../../../../Shared/Components/ButtonLink/ButtonLink";
import { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import {
  cnpjCpfFormat,
  formatNameString,
} from "../../../../../../Shared/Utils/data";

export const BasicInformationsCard = ({ title, className, itemList }) => {
  const [selectedImage, setSelectedImage] = useState(EmptyImage);

  useEffect(() => {
    let selectedImage = EmptyImage;

    if (title === "Motorista" && itemList?.foto_motorista?.url) {
      selectedImage = itemList?.foto_motorista?.url;
    } else if (title === "Proprietário" && itemList?.foto_proprietario?.url) {
      selectedImage = itemList?.foto_proprietario?.url;
    } else if (title === "Veículo" && itemList?.foto_documento?.url) {
      selectedImage = itemList?.foto_documento?.url;
    }

    setSelectedImage(selectedImage);
  }, [
    itemList?.foto_documento?.url,
    itemList?.foto_motorista?.url,
    itemList?.foto_proprietario?.url,
    title,
  ]);

  return (
    <div
      className={`d-flex flex-column text-center ${className}`}
      data-testid="basic-informations-card"
    >
      <div className="mb-2">
        <Title>{title}</Title>
        <div className="d-flex justify-content-center mb-2">
          <CoverImage image={selectedImage} />
        </div>
        <div className="d-flex justify-content-center">
          <div style={{ width: "120px" }}>
            <Subtitle>
              {itemList?.nome
                ? formatNameString(itemList?.nome)
                : itemList?.placa
                ? itemList?.placa
                : "--"}
            </Subtitle>
          </div>
        </div>
        <Document>
          {itemList?.cpf
            ? cnpjCpfFormat(itemList?.cpf)
            : itemList?.cpf_cnpj && cnpjCpfFormat(itemList?.cpf_cnpj)}
        </Document>
        {itemList && (
          <Status className="mt-auto d-flex justify-content-center">
            Status do cadastro:
            <span
              className={`ms-1 ${
                itemList?.ativo === true ? "color-active" : "color-red"
              }`}
            >
              {itemList?.ativo === true ? "Liberado" : "Desativado"}
            </span>
          </Status>
        )}
      </div>

      {itemList && (
        <div className="d-flex justify-content-center mt-auto">
          <ButtonLink
            buttoncolor="primary"
            link={
              title === "Motorista"
                ? `/motoristas-informacoes/${itemList?.id}`
                : title === "Proprietário"
                ? `/proprietarios-informacoes/${itemList?.id}`
                : `/veiculos-informacoes/${itemList?.id}`
            }
            label="Mais detalhes"
            secondIcon={<IoIosArrowForward />}
          />
        </div>
      )}
    </div>
  );
};
