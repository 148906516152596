import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useMemo } from "react";
import L from "leaflet";
import newIcon from "../../../Assets/Images/iconTruck.png";
import { Subtitle } from "./Subtitle";
import TravelsListPopups from "./TravelsListPopups";

export const TravelsListMap = ({
  height,
  positions,
  zoom = 15,
  centerPositions,
}) => {
  const defaultProps = useMemo(() => {
    return {
      center: {
        lat: centerPositions
          ? centerPositions.lat
          : positions && positions.length > 0
          ? positions[0]?.lat
          : -14.235,
        lng: centerPositions
          ? centerPositions?.lng
          : positions && positions.length > 0
          ? positions[0]?.lng
          : -51.9253,
      },
    };
  }, [positions, centerPositions]);

  let RealTimeLocationIcon = L.icon({
    iconUrl: newIcon,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  return (
    <div style={{ height: "100%", position: "relative", zIndex: "0" }}>
      <MapContainer
        center={defaultProps?.center}
        zoom={zoom}
        style={{ height: height, width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {positions &&
          positions.map((position, index) => {
            return (
              <TravelsListPopups
                key={index}
                RealTimeLocationIcon={RealTimeLocationIcon}
                currentAddress={position}
                desvio_route={position?.desvio_rota}
              />
            );
          })}
      </MapContainer>
      <div className="mt-1 mb-1">
        <Subtitle label="Localização atual" icon={newIcon} />
      </div>
    </div>
  );
};
