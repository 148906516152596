import { Button } from "react-bootstrap";
import styled from "styled-components";

export const TableContainer = styled.div`
 max-height: 20vh;
flex: 1;
overflow-x: auto;

 @media(min-width: 375px){
  max-height: 30vh;
 }

 @media(min-width: 425px){
  max-height: 50vh;
 }

 @media(min-width: 576px){
  max-height: 55vh;
  }

  @media(min-width: 1640px){
  max-height: 60vh;
  }
`

export const OccurrencesTypesThead = styled.thead`
border-bottom: 1px solid var(--color-gray-13);

th{
  padding: 0.5rem;
  font-size: 1rem;
  color: black;
}
`

const BaseTableItem = styled.td`
   font-size: 0.8rem;
  padding: 5rem;

`

export const TableItem = styled(BaseTableItem)`
  font-size: 0.875rem;
`
export const TableTitle = styled.th`

color: var(--color-gray-15);
font-weight: 700;
`
export const DeleteButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: var(--color-gray-15);
  transition: transform 200ms;


  &:hover{
  background-color: transparent;
  color: var(--color-gray-15);
  transform: scale(1.1);
    filter: brightness(1.1);

  }
`

export const EditButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: var(--color-gray-15);
  transition: transform 200ms;


  &:hover{
  background-color: transparent;
  color: var(--color-gray-15);
  transform: scale(1.1);
    filter: brightness(1.1);

  }
`

export const OccurrencesTypesContainer = styled.div`
max-height: 30vh;
flex: 1;
overflow: auto;

 @media(min-width: 375px){
  max-height: 40vh;
 }

 @media(min-width: 768px){
  max-height: 60vh;
 }

 @media(min-width: 1024px){
  max-height: 70vh;
 }
 

 @media(min-width: 1440px){
  overflow-x: auto;
  max-height: 80vh;
  }

  @media(min-width: 1640px){
  overflow-x: auto;
  max-height: 75vh;
  }
  @media(min-width: 2440px){
  overflow-x: auto;
  max-height: 80vh;
  }
`