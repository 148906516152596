import { memo, useCallback } from "react";
import Dashboard from "../../../../../Shared/layout";
import { useCompanies } from "../../../../../hooks/CompanyProvider";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container } from "./styles";
import { CompanyForm } from "../components/CompanyForm/CompanyForm";

const AddCompany = () => {
  const { isSubmitting, addComapany } = useCompanies();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = useCallback(
    (_, data) => {
      data.cep = data.cep.replace(/\D+/g, "");
      addComapany(data, navigate);
    },
    [addComapany, navigate]
  );

  return (
    <Dashboard title="Adicionar empresa">
      <Container>
        <CompanyForm
          onSubmit={handleFormSubmit}
          register={register}
          handleSubmit={handleSubmit}
          setValue={setValue}
          errors={errors}
          isSubmitting={isSubmitting}
        />
      </Container>
    </Dashboard>
  );
};

export default memo(AddCompany);
