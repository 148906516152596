import { useMemo } from "react";
import { IoWarningOutline } from "react-icons/io5";

import {
  Container,
  H4Status,
  Item,
  ItemSubtitle,
  ItemTitle,
  SubtitleIcon,
  Title,
} from "./styles";

import moment from "moment";

export const DriverFederalDetails = ({ consult }) => {
  const driverFederalInformations = useMemo(
    () =>
      consult && consult?.consulta_cpf && consult?.consulta_cpf !== "null"
        ? JSON.parse(consult?.consulta_cpf)
        : consult?.consulta_cnpj &&
          consult?.consulta_cnpj !== "null" &&
          JSON.parse(consult?.consulta_cnpj),
    [consult]
  );

  const anttInformations = useMemo(
    () =>
      consult &&
      consult?.consulta_antt_transportador &&
      JSON.parse(consult?.consulta_antt_transportador),
    [consult]
  );

  return (
    <>
      {consult && !driverFederalInformations && (
        <Container>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <IoWarningOutline size={19} color="red" />
            <SubtitleIcon>
              Nenhuma informação encontrada para este CPF
            </SubtitleIcon>
          </div>
        </Container>
      )}
      {driverFederalInformations && driverFederalInformations?.err && (
        <div className="p-2">
          <Container className="p-2">
            <Title className="mb-3">Consulta CPF motorista</Title>

            <div className="d-flex gap-2 align-items-center justify-content-center">
              <IoWarningOutline size={22} color="red" />
              <SubtitleIcon>{driverFederalInformations?.message}</SubtitleIcon>
            </div>
          </Container>
        </div>
      )}
      {driverFederalInformations?.errors &&
      driverFederalInformations?.errors.length > 0 ? (
        <Container>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <IoWarningOutline size={22} color="red" />
            <SubtitleIcon>
              Erro na consulta: {driverFederalInformations?.errors[0]}
            </SubtitleIcon>
          </div>
        </Container>
      ) : (
        <>
          {driverFederalInformations &&
            driverFederalInformations?.data?.length === 0 && (
              <Container>
                <Title className="mb-3">CPF Motorista</Title>
                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <IoWarningOutline size={22} color="red" />
                  <SubtitleIcon>Consulta incompleta</SubtitleIcon>
                </div>
              </Container>
            )}

          {driverFederalInformations &&
            driverFederalInformations?.data?.length > 0 && (
              <Container>
                <Title>
                  Consulta {consult?.consulta_cpf ? "CPF" : "CNPJ"} do motorista
                  na Receita Federal
                </Title>
                {driverFederalInformations?.data[0]?.cpf && (
                  <Item>
                    <ItemTitle>CPF:</ItemTitle>
                    <ItemSubtitle>
                      {driverFederalInformations?.data[0]?.cpf ?? "--"}
                    </ItemSubtitle>
                  </Item>
                )}
                <Item>
                  <ItemTitle>
                    Situação cadastral do{" "}
                    {consult?.consulta_cpf ? "CPF" : "CNPJ"} na Receita Federal:
                  </ItemTitle>
                  <H4Status
                    status={
                      driverFederalInformations?.data[0]?.situacao_cadastral
                    }
                  >
                    {driverFederalInformations?.data[0]?.situacao_cadastral ??
                      "--"}
                  </H4Status>
                </Item>
                {driverFederalInformations?.data[0]?.ano_obito && (
                  <Item>
                    <ItemTitle>Ano óbito:</ItemTitle>
                    <ItemSubtitle>
                      {driverFederalInformations?.data[0]?.ano_obito ?? "--"}
                    </ItemSubtitle>
                  </Item>
                )}
                <Item>
                  <ItemTitle>Data/Hora da consulta:</ItemTitle>
                  <ItemSubtitle>
                    {driverFederalInformations?.data[0]?.consulta_datahora
                      ? moment
                          .utc(
                            driverFederalInformations?.data[0]
                              ?.consulta_datahora
                          )
                          .format("DD/MM/YYYY HH:mm")
                      : "--"}
                  </ItemSubtitle>
                </Item>

                <Item>
                  <ItemTitle>Comprovante consulta:</ItemTitle>
                  <ItemSubtitle>
                    {driverFederalInformations?.data[0]?.consulta_comprovante
                      ? driverFederalInformations?.data[0]?.consulta_comprovante
                      : "--"}
                  </ItemSubtitle>
                </Item>
              </Container>
            )}

          {consult &&
            consult?.consulta_antt_transportador &&
            anttInformations?.code_message && (
              <Container className="mt-3">
                <Title className="mb-3">ANTT Transportador</Title>
                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <IoWarningOutline size={22} color="red" />
                  <SubtitleIcon>{anttInformations?.code_message}</SubtitleIcon>
                </div>
              </Container>
            )}

          {(driverFederalInformations?.consulta_cnis === null ||
            driverFederalInformations?.consulta_cnis?.data.length === 0) &&
            consult?.consulta_cpf && (
              <Container>
                <Title className="mb-3">NIS Motorista</Title>
                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <IoWarningOutline size={22} color="red" />
                  <SubtitleIcon>
                    Nenhuma informação do NIS do motorista encontrada
                  </SubtitleIcon>
                </div>
              </Container>
            )}
        </>
      )}
    </>
  );
};
