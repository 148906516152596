import { useNavigate } from "react-router-dom";
import Dashboard from "../../../../../Shared/layout";
import { useDrivers } from "../../../../../hooks/DriverProvider";
import { Form } from "react-bootstrap";

import { useForm } from "react-hook-form";
import DriverForm from "../components/DriversForms/DriverForm";
import PersonalInformationForm from "../components/DriversForms/PersonalInformationForm";
import { DriverFormRow } from "../components/DriversForms/styles";
import { BorderCard, CardHeader } from "./styles";
import ModalOCRImport from "../../../../../Shared/Components/ModalOCRImport/ModalOCRImport";

import { useCallback, useState } from "react";
import { DriverCpfApiRequest } from "../../../../../Shared/Utils/Request";
import { Loading } from "../../../../../Shared/Components/Loading/Loading";

const AddDriver = () => {
  const [driverByCpf, setDriverByCpf] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isSubmitting, addTemporaryDriver } = useDrivers();

  const [OCRImage, setOCRImage] = useState(null);
  const [driverInfo, setDriverInfo] = useState(null);
  const [imageCNHType, setImageCNHType] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const getDriverByCpf = useCallback(
    async (cpf) => {
      setIsLoading(true);
      try {
        const response = await DriverCpfApiRequest.get(
          `/motoristas/cpf/${cpf}`
        );

        if (response?.data?.erro) return setDriverByCpf(null);

        if (!response?.data?.cpf) {
          reset();
          setValue("cpf", cpf);

          setDriverByCpf(null);
        } else {
          setDriverByCpf(response?.data);
        }

        setIsLoading(false);
      } catch (error) {
        setDriverByCpf(null);
      } finally {
        setIsLoading(false);
      }
    },
    [reset, setValue]
  );

  const navigate = useNavigate();

  const onSubmit = (data) => {
    data.status_cadastro = "7r";
    data.cpf = data?.cpf && data?.cpf.replace(/[^0-9]/g, "");
    data.numero_celular =
      data?.numero_celular && data?.numero_celular.replace(/[^0-9]/g, "");
    data.cep = data?.cep && data?.cep.replace(/\D+/g, "");

    addTemporaryDriver(data, imageCNHType, navigate);
  };

  return (
    <Dashboard>
      {isLoading && <Loading />}
      {!isLoading && (
        <Form
          className="mt-2"
          onSubmit={handleSubmit(onSubmit)}
          data-testid="add-driver-form-container"
        >
          <DriverFormRow className="d-flex flex-column gap-3">
            <BorderCard
              className="text-center w-100 h-100 card d-flex flex-column"
              data-testid="driver-form-container"
            >
              <CardHeader>
                <span>Cadastro do motorista</span>
                <ModalOCRImport
                  setValue={setValue}
                  setOCRImage={setOCRImage}
                  type="driver"
                  setDriverInfo={setDriverInfo}
                  setImageCNHType={setImageCNHType}
                />
              </CardHeader>
              <DriverForm
                register={register}
                errors={errors}
                setValue={setValue}
                getDriverByCpf={getDriverByCpf}
                driverByCpf={driverByCpf}
                OCRImage={OCRImage}
                setImageCNHType={setImageCNHType}
              />
            </BorderCard>

            <PersonalInformationForm
              register={register}
              isSubmitting={isSubmitting}
              setValue={setValue}
              cancelRoute="/motoristas"
              errors={errors}
              driverByCpf={driverByCpf}
              driverInfo={driverInfo}
            />
          </DriverFormRow>
        </Form>
      )}
    </Dashboard>
  );
};

export default AddDriver;
