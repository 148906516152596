import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ApiRequest from "../Shared/Utils/Request";

export const ScopeContext = createContext({
  scopes: [],
  isLoading: false,
  getScopes: () => {},
});

export const useScopes = () => {
  const context = useContext(ScopeContext);

  if (!context) {
    throw new Error("useScopes must be within ScopeProvider");
  }

  return context;
};

export const ScopeProvider = ({ children }) => {
  const [scopes, setScopes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getScopes = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await ApiRequest({
        path: "escopos",
        method: "GET",
      });

      setScopes(response?.data);
      return response.data;
    } catch (error) {
      setScopes([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const providerValue = useMemo(
    () => ({
      scopes,
      isLoading,
      getScopes,
    }),
    [scopes, isLoading, getScopes]
  );

  return (
    <ScopeContext.Provider value={providerValue}>
      {children}
    </ScopeContext.Provider>
  );
};
