import { ownerType } from "../../Utils/constants";
import { AlertLabel, InfoItem } from "./styles";

export const DriverTypeAlertCard = ({
  ownerInformations,
  driver,
  ownerName,
}) => {
  return (
    <div>
      <div className="d-flex gap-1 mb-4 align-tems-center">
        <InfoItem>Tipo:</InfoItem>
        <InfoItem className="fw-bold">
          {ownerInformations?.tipoProprietario &&
            ownerType(ownerInformations?.tipoProprietario)}
        </InfoItem>
        {ownerInformations?.proprietarioIgualMotorista === "s" && (
          <AlertLabel
            className={`align-self-center ${
              !ownerInformations?.proprietarioVeiculoIgualMotorista
                ? "text-danger"
                : "text-dark"
            }`}
          >
            (Motorista declarado como proprietário do veículo)
          </AlertLabel>
        )}
      </div>
      <div className="d-flex gap-1 mb-4 align-tems-center">
        <InfoItem>Motorista:</InfoItem>
        <InfoItem className="fw-bold">{driver}</InfoItem>
      </div>
      <div className="d-flex gap-1 mb-2 align-tems-center">
        <InfoItem>Proprietário:</InfoItem>
        <InfoItem className="fw-bold">{ownerName}</InfoItem>
      </div>
    </div>
  );
};
