import { useCallback, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";

import { useVehicles } from "../../../../../hooks/VehicleProvider";

import { AccortionTitle } from "../components/VehicleForms/AccordionTitle";

import { Loading } from "../../../../../Shared/Components/Loading/Loading";
import TruckForm from "./TruckForm";

export const TrucksUpdatesForm = ({
  id,
  owners,
  drivers,
  handleShowModal,
  setIsPendentingForm,
}) => {
  const [showTruck, setShowTruck] = useState(0);
  const [activeKey, setActiveKey] = useState("0");
  const [nextIndex, setNextIndex] = useState(0);

  const [visibleTrucks, setVisibleTrucks] = useState(
    Array.from({ length: showTruck }, (_, i) => i)
  );

  const {
    vehicle,
    isLoading,
    isLoadingTruck,
    getVehicle,
    deleteTruck,
    addTruck,
    updateTruck,
  } = useVehicles();

  useEffect(() => {
    getVehicle(id);
  }, [getVehicle, id, updateTruck]);

  const onSubmitTruck = async (data, setIsEdit) => {
    setIsEdit(false);
    setIsPendentingForm(false);
    delete data.carretas;

    const valueData = Object.values(data);

    const newData = valueData[0];

    if (!valueData[0]?.id) {
      const positionValue = Object.keys(data);
      const position = positionValue[0].replace("carreta", "");

      newData.posicao = position ?? "1";
      newData.liberado = true;

      newData.documento = newData?.documento && parseFloat(newData?.documento);

      newData.placa =
        newData?.placa &&
        newData.placa.replace(/[^A-Za-z0-9]/g, "").toUpperCase();

      newData.bloqueado_adm = newData?.bloqueado_adm === true ? "S" : "N";
      newData.documento = newData.documento && newData.documento.toString();

      newData.proprietario_id = newData?.proprietario_arrendatario_id;
      newData.antt = "000";
      newData.dt_validade_antt = "000";
      newData.dt_vencimento_documento = "000";
      newData.status_rntrc = "NAOCONSTA";
      newData.veiculo_id = vehicle.id;

      for (const key in newData) {
        if (
          newData[key] === "" ||
          newData[key] === undefined ||
          newData[key] === "Sem motorista"
        ) {
          delete newData[key];
        }
      }

      await addTruck(newData, vehicle.id);
    } else {
      for (const key in newData) {
        if (
          newData[key] === "" ||
          newData[key] === undefined ||
          newData[key] === "Sem motorista"
        ) {
          delete newData[key];
        }
      }

      if (newData?.foto_documento && newData?.foto_documento.length === 0) {
        delete newData.foto_documento;
      }
      if (
        newData?.documentos_arrendamento &&
        newData?.documentos_arrendamento.length === 0
      ) {
        delete newData.documentos_arrendamento;
      }

      await updateTruck(newData, vehicle?.id);
      await getVehicle(vehicle.id);
    }
  };

  useEffect(() => {
    const newVisibleTrucks =
      vehicle?.carretas &&
      Array.from({ length: vehicle?.carretas.length }, (_, i) => i);

    setVisibleTrucks(newVisibleTrucks);
  }, [vehicle?.carretas, updateTruck, deleteTruck]);

  const handleAddTruck = useCallback(() => {
    const newVisibleTrucks = [...visibleTrucks, nextIndex];
    setVisibleTrucks(newVisibleTrucks);
    setShowTruck((prevState) => prevState + 1);
    setActiveKey(showTruck);
    setNextIndex((prevState) => prevState + 1);
  }, [nextIndex, showTruck, visibleTrucks]);

  useEffect(() => {
    if (vehicle?.carretas) {
      setShowTruck(vehicle?.carretas.length);
      setNextIndex(vehicle?.carretas.length);
    }
  }, [vehicle?.carretas, updateTruck]);

  const handleActiveTruckClick = useCallback(
    (eventKey) => {
      setActiveKey(eventKey === activeKey ? null : eventKey);
    },
    [activeKey]
  );

  const handleDeleteData = useCallback(
    (index, id) => {
      const updatedVisibleTrucks = visibleTrucks.filter((i) => i !== index);
      setVisibleTrucks(updatedVisibleTrucks);
      setShowTruck((prevState) => prevState - 1);
      id && vehicle?.id && deleteTruck(id, vehicle?.id);
    },
    [visibleTrucks, deleteTruck, vehicle?.id]
  );

  return (
    <div>
      <Card className="w-100  flex-grow-1">
        <AccortionTitle
          handleAddTruck={handleAddTruck}
          setShowTruck={setShowTruck}
          visibleTrucks={visibleTrucks}
        />
        <Card.Body className="text-start">
          {(isLoading || isLoadingTruck) && <Loading />}
          {!isLoadingTruck && !isLoading && (
            <Accordion activeKey={activeKey}>
              {Array.isArray(visibleTrucks) &&
                visibleTrucks?.length > 0 &&
                visibleTrucks.map((visibleTruck) => (
                  <Accordion.Item
                    eventKey={visibleTruck}
                    key={visibleTruck}
                    id={`accordion-item-${visibleTruck}`}
                  >
                    <TruckForm
                      drivers={drivers}
                      handleActiveTruckClick={handleActiveTruckClick}
                      handleDeleteData={handleDeleteData}
                      index={visibleTruck}
                      owners={owners}
                      showTruck={showTruck}
                      vehicle={vehicle}
                      addTruck={addTruck}
                      updateTruck={updateTruck}
                      setIsPendentingForm={setIsPendentingForm}
                      handleShowModal={handleShowModal}
                      onSubmitTruck={onSubmitTruck}
                    />
                  </Accordion.Item>
                ))}
            </Accordion>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
